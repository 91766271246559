// Style
import './style.scss';

import React from 'react';

import { ReportDisplayMode } from '../../../containers/Reports';
import Dimensions from './ColumnsPartMenu';
import FormatPartMenu from './FormatPartMenu';
import MappingPartMenu from './MappingPartMenu';
import MiningPartMenu from './MiningPartMenu';
import UndoPartMenu from './UndoPartMenu';

interface ILayoutMenu {
  mappingTree: NS_API.IGraph;
  cellSelected: string[];
  typeOfVisualisation: string;
  setTypeOfVisualisation: (val: string) => void;
  setCellSelected: (val: string[]) => void;
  setHistoryOpen: (val: boolean) => void;
  isUnion: boolean;
  reportDisplayMode: string | undefined;
  openDimensions: boolean;
  setOpenDimensions: React.Dispatch<React.SetStateAction<boolean>>;
  nodeOpenedAccount: NS_TREE.IMining;
  setNodeOpenedAccount: React.Dispatch<React.SetStateAction<NS_TREE.IMining>>;
  selectedFile: Array<NS_Table.IColumnHeader>;
  miningContainerRef: React.RefObject<HTMLDivElement> | null;
  openKpi: boolean;
  setOpenKpi: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

/**
 * Container used to display the top menu in a report
 *
 * @param param NS_Tabs.ITab props
 * React.FC<IViewMenu>
 */

const LayoutMenu: React.FC<ILayoutMenu> = ({
  mappingTree,
  cellSelected,
  typeOfVisualisation,
  setTypeOfVisualisation,
  setCellSelected,
  setHistoryOpen,
  isUnion,
  reportDisplayMode,
  openDimensions,
  setOpenDimensions,
  nodeOpenedAccount,
  setNodeOpenedAccount,
  selectedFile,
  miningContainerRef,
  openKpi,
  setOpenKpi,
  disabled = false,
}) => {
  return (
    <div className="editMenuContainer">
      <div className="undo itemMenu">
        <UndoPartMenu setHistoryOpen={setHistoryOpen} disabled={disabled} />
      </div>
      <div className="mining itemMenu">
        <MiningPartMenu
          nodeOpenedAccount={nodeOpenedAccount}
          setNodeOpenedAccount={setNodeOpenedAccount}
          selectedFile={selectedFile}
          miningContainerRef={miningContainerRef}
          disabledPivot={reportDisplayMode === 'CounterPart'}
          disabledCounterPart={
            disabled ||
            reportDisplayMode === ReportDisplayMode.COUNTER_PART ||
            reportDisplayMode === ReportDisplayMode.SALES_BY_CLIENT ||
            reportDisplayMode === ReportDisplayMode.PURCHASES_BY_PROVIDER
          }
        />
        <div className="title">Mining</div>
      </div>
      <div className="mapping itemMenu">
        <MappingPartMenu
          typeOfVisualisation={typeOfVisualisation}
          setTypeOfVisualisation={setTypeOfVisualisation}
          disabled={
            disabled ||
            mappingTree === undefined ||
            reportDisplayMode === ReportDisplayMode.SALES_BY_CLIENT ||
            reportDisplayMode === ReportDisplayMode.PURCHASES_BY_PROVIDER
          }
          isUnion={isUnion}
        />
        <div className="title">Mapping</div>
      </div>
      <div className="format itemMenu">
        <FormatPartMenu
          mappingTree={mappingTree}
          cellSelected={cellSelected}
          setCellSelected={setCellSelected}
          disabled={disabled}
        />
        <div className="title">Format</div>
      </div>
      <div className="dimensions itemMenu">
        <Dimensions
          openDimension={openDimensions}
          setOpenDimension={setOpenDimensions}
          setOpenKpi={setOpenKpi}
          openKpi={openKpi}
          disabled={disabled}
        />
        <div className="title">Columns</div>
      </div>
    </div>
  );
};

export default React.memo(LayoutMenu);
