// Style
import './style.scss';

import React, { useState } from 'react';

import edit from '../../../../assets/img/edit.svg';
import ColorPicker from '../../../../elements/ColorPicker';

type WsColorsOmittedProps = 'chartColors';
export type ColorOption =
  | 'primaryFill'
  | 'primaryText'
  | 'secondaryFill'
  | 'secondaryText'
  | 'headerFill'
  | 'headerText';

interface IColorsProps {
  colors: Omit<NS_Workspace.IColorOptions, WsColorsOmittedProps>;
  setColor: (type: ColorOption, color: string) => void;
}

/**
 * Component used to change color configuration
 * @oaram IColorsProps
 */

const ColorOptions: React.FC<IColorsProps> = ({ colors, setColor }) => {
  const [isPrimaryFillColorPickerOpen, setPrimaryFillColorPickerOpen] = useState<boolean>(false);
  const [isSecondaryFillColorPickerOpen, setSecondaryFillColorPickerOpen] = useState<boolean>(false);
  const [isPrimaryTextColorOpen, setPrimaryTextColorPickerOpen] = useState<boolean>(false);
  const [isSecondaryTextColorOpen, setSecondaryTextColorPickerOpen] = useState<boolean>(false);
  const [isHeaderFillOpen, setHeaderFillOpen] = useState<boolean>(false);
  const [isHeaderTextOpen, setHeaderTextOpen] = useState<boolean>(false);

  const handleRestore = () => {
    setColor('primaryFill', '#40a871ff');
    setColor('primaryText', '#ffffffff');
    setColor('secondaryFill', '#f6f7f7ff');
    setColor('secondaryText', '#5D7183ff');
    setColor('headerFill', '#f2f2f2ff');
    setColor('headerText', '#c6c8c9ff');
  };

  const toggleColorPickOpen = (type: ColorOption) => () => {
    if (type === 'primaryFill') {
      setPrimaryFillColorPickerOpen(!isPrimaryFillColorPickerOpen);
    } else if (type === 'secondaryFill') {
      setSecondaryFillColorPickerOpen(!isSecondaryFillColorPickerOpen);
    } else if (type === 'primaryText') {
      setPrimaryTextColorPickerOpen(!isPrimaryTextColorOpen);
    } else if (type === 'secondaryText') {
      setSecondaryTextColorPickerOpen(!isSecondaryTextColorOpen);
    } else if (type === 'headerFill') {
      setHeaderFillOpen(!isHeaderFillOpen);
    } else if (type === 'headerText') {
      setHeaderTextOpen(!isHeaderTextOpen);
    }
  };

  const handleChangeColorComplete = (type: ColorOption) => (color: string) => {
    setColor(type, color);
  };

  const handleClose = (type: ColorOption) => () => {
    if (type === 'primaryFill') {
      setPrimaryFillColorPickerOpen(false);
    } else if (type === 'secondaryFill') {
      setSecondaryFillColorPickerOpen(false);
    } else if (type === 'primaryText') {
      setPrimaryTextColorPickerOpen(false);
    } else if (type === 'secondaryText') {
      setSecondaryTextColorPickerOpen(false);
    } else if (type === 'headerFill') {
      setHeaderFillOpen(false);
    } else if (type === 'headerText') {
      setHeaderTextOpen(false);
    }
  };

  return (
    <div className="colorOptionContainer">
      <h3 className="optionTitle">Report Styles</h3>
      <div className="colorRestoreButton" onClick={handleRestore}>
        Restore default styles
      </div>
      <label className="optionDescription">Formatting options that apply to all Reports in the Workspace.</label>
      <h5 className="colorSubtitle">Report Header :</h5>
      <div className="headerColorContainer">
        <div className="fillColor colorChangeContainer">
          <label>Fill color</label>
          <div className="buttons">
            <button
              className="buttonContainer"
              style={{ backgroundColor: colors.headerFillColor }}
              onClick={toggleColorPickOpen('headerFill')}
            />
            <img
              src={edit}
              width="20"
              height="20"
              alt="setting"
              className="editBtn"
              onClick={toggleColorPickOpen('headerFill')}
            />
            {isHeaderFillOpen && (
              <ColorPicker
                titleText="Report Header -- Fill"
                color={colors.headerFillColor}
                handleClose={handleClose('headerFill')}
                handleChangeColor={handleChangeColorComplete('headerFill')}
              />
            )}
          </div>
        </div>
        <div className="textColor colorChangeContainer">
          <label>Text Color</label>
          <div className="buttons">
            <button
              className="buttonContainer"
              style={{ backgroundColor: colors.headerTextColor }}
              onClick={toggleColorPickOpen('headerText')}
            />
            <img
              src={edit}
              width="20"
              height="20"
              alt="setting"
              className="editBtn"
              onClick={toggleColorPickOpen('headerText')}
            />
            {isHeaderTextOpen && (
              <ColorPicker
                titleText="Report Header -- Text"
                color={colors.headerTextColor}
                handleClose={handleClose('headerText')}
                handleChangeColor={handleChangeColorComplete('headerText')}
              />
            )}
          </div>
        </div>
        <div className="colorPreview colorChangeContainer">
          <label>Preview</label>
          <div className="previewContainer">
            <p style={{ backgroundColor: colors.headerFillColor, color: colors.headerTextColor }}>RESULTAT NET</p>
          </div>
        </div>
      </div>

      <h5 className="colorSubtitle">Primary Highlighting :</h5>
      <div className="primaryColorContainer">
        <div className="fillColor colorChangeContainer">
          <label>Fill color</label>
          <div className="buttons">
            <button
              className="buttonContainer"
              style={{ backgroundColor: colors.primaryFillColor }}
              onClick={toggleColorPickOpen('primaryFill')}
            />
            <img
              src={edit}
              width="20"
              height="20"
              alt="setting"
              className="editBtn"
              onClick={toggleColorPickOpen('primaryFill')}
            />
            {isPrimaryFillColorPickerOpen && (
              <ColorPicker
                titleText="Primary Highlighting -- Fill"
                color={colors.primaryFillColor}
                handleClose={handleClose('primaryFill')}
                handleChangeColor={handleChangeColorComplete('primaryFill')}
              />
            )}
          </div>
        </div>
        <div className="textColor colorChangeContainer">
          <label>Text Color</label>
          <div className="buttons">
            <button
              className="buttonContainer"
              style={{ backgroundColor: colors.primaryTextColor }}
              onClick={toggleColorPickOpen('primaryText')}
            />
            <img
              src={edit}
              width="20"
              height="20"
              alt="setting"
              className="editBtn"
              onClick={toggleColorPickOpen('primaryText')}
            />
            {isPrimaryTextColorOpen && (
              <ColorPicker
                titleText="Primary Highlighting -- Text"
                color={colors.primaryTextColor}
                handleClose={handleClose('primaryText')}
                handleChangeColor={handleChangeColorComplete('primaryText')}
              />
            )}
          </div>
        </div>
        <div className="colorPreview colorChangeContainer">
          <label>Preview</label>
          <div className="previewContainer">
            <p style={{ backgroundColor: colors.primaryFillColor, color: colors.primaryTextColor }}>RESULTAT NET</p>
          </div>
        </div>
      </div>

      <h5 className="colorSubtitle">Secondary Highlighting :</h5>
      <div className="secondaryColorContainer">
        <div className="fillColor colorChangeContainer">
          <label>Fill color</label>
          <div className="buttons">
            <button
              className="buttonContainer"
              style={{ backgroundColor: colors.secondaryFillColor }}
              onClick={toggleColorPickOpen('secondaryFill')}
            />
            <img
              src={edit}
              width="20"
              height="20"
              alt="setting"
              className="editBtn"
              onClick={toggleColorPickOpen('secondaryFill')}
            />
            {isSecondaryFillColorPickerOpen && (
              <ColorPicker
                titleText="Secondary Highlighting -- Fill"
                color={colors.secondaryFillColor}
                handleClose={handleClose('secondaryFill')}
                handleChangeColor={handleChangeColorComplete('secondaryFill')}
              />
            )}
          </div>
        </div>
        <div className="textColor colorChangeContainer">
          <label>Text Color</label>
          <div className="buttons">
            <button
              className="buttonContainer"
              style={{ backgroundColor: colors.secondaryTextColor }}
              onClick={toggleColorPickOpen('secondaryText')}
            />
            <img
              src={edit}
              width="20"
              height="20"
              alt="setting"
              className="editBtn"
              onClick={toggleColorPickOpen('secondaryText')}
            />
            {isSecondaryTextColorOpen && (
              <ColorPicker
                titleText="Secondary Highlighting -- Text"
                color={colors.secondaryTextColor}
                handleClose={handleClose('secondaryText')}
                handleChangeColor={handleChangeColorComplete('secondaryText')}
              />
            )}
          </div>
        </div>
        <div className="colorPreview colorChangeContainer">
          <label>Preview</label>
          <div className="previewContainer">
            <p style={{ backgroundColor: colors.secondaryFillColor, color: colors.secondaryTextColor }}>EBIT</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorOptions;
