// Style
import './style.scss';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';

import BoldSVG from '../../../../assets/img/bold.svg';
import FillSVG from '../../../../assets/img/fill.svg';
import FontSVG from '../../../../assets/img/font.svg';
import ItalicSVG from '../../../../assets/img/italic.svg';
import UnderlineSVG from '../../../../assets/img/underline.svg';
import { ReportContext } from '../../../../context/ReportContext';
import ColorPicker from '../../../../elements/ColorPicker';
import ExplanationBox from '../../../../elements/ExplanationBox';
import { dataDestructuring } from '../../../../shared/helpers/tree/front.tree.util';

interface IFormatPartMenu {
  mappingTree: NS_API.IGraph;
  cellSelected: string[];
  setCellSelected: (val: string[]) => void;
  disabled?: boolean;
}

const explanations = [
  {
    title: 'Bold',
    description: 'Make your text bold.',
    posX: '24px',
  },
  {
    title: 'Underline',
    description: 'Underline your text.',
    posX: '59px',
  },
  {
    title: 'Italic',
    description: 'Italicize your text.',
    posX: '94px',
  },
  {
    title: 'Font Color',
    description: 'Change the color of your text.',
    posX: '24px',
  },
  {
    title: 'Fill Color',
    description: 'Color the background of cells to make them stand out.',
    posX: '59px',
  },
];

/**
 * Container used to chose which info you want to see
 *
 * @param param NS_Tabs.ITab props
 * React.FC<IFormatPartMenu>
 */

const FormatPartMenu: React.FC<IFormatPartMenu> = ({
  mappingTree,
  cellSelected,
  setCellSelected,
  disabled = false,
}) => {
  // reportContext
  const { setNewOperation, currentCommands, currentMetadata } = useContext(ReportContext);

  //Refs
  const hoverTimer: { current: NodeJS.Timeout | null } = useRef(null);
  //States
  const [explanationSelected, setExplanationSelected] = useState<number>(-1);
  const [pickerColorOpen, setPickerColorOpen] = useState<boolean>(false);
  const [pickerFillOpen, setPickerFillOpen] = useState<boolean>(false);
  const [selectedFillColor, setFillColor] = useState<string>('#000000ff');
  const [selectedTextColor, setTextColor] = useState<string>('#000000ff');
  const [styleOfCellSelected, setStyleOfCellSelected] = useState<string>('');

  useEffect(() => {
    if (mappingTree) {
      const newArray = dataDestructuring(mappingTree);

      const loopArray = (child: NS_API.IGraph) => {
        child.forEach((item) => {
          if (item.data.content.rowContentId === cellSelected[0] && item.data.content.rowParams) {
            setStyleOfCellSelected(item.data.content.rowParams.cssStyle);
          } else {
            if (item.data.content.rowContentType !== 'N' && item.children) {
              loopArray(item.children);
            }
          }
        });
      };
      loopArray(newArray);
    }
  }, [cellSelected, mappingTree]);

  const handleClose = (type: 'color' | 'fill') => () => {
    if (type === 'color') {
      setPickerColorOpen(false);
    } else if (type === 'fill') {
      setPickerFillOpen(false);
    }
    setCellSelected([]);
  };

  const handleChangeColorComplete = (type: 'color' | 'fill') => (color: string) => {
    changeStyle(`${type + color}`);
    if (type === 'color') {
      setTextColor(color);
    } else if (type === 'fill') {
      setFillColor(color);
    }
  };

  // Edit the style of each line of the tree.
  const changeStyle = (value: string) => {
    const newArray = dataDestructuring(mappingTree);
    const newMetadata = dataDestructuring(currentMetadata);

    const loopArray = (child: NS_API.IGraph) => {
      child.forEach((item, index) => {
        if (item.data.content.rowContentId === cellSelected[0]) {
          const needUpdate = true;
          let newCssStyle = '';
          if (child[index].data.content.rowParams.cssStyle.includes(value)) {
            newCssStyle = child[index].data.content.rowParams.cssStyle.replace(value, '');
          } else {
            if (value.includes('color')) {
              if (!child[index].data.content.rowParams.cssStyle.includes('color')) {
                newCssStyle = child[index].data.content.rowParams.cssStyle + ` ${value}`;
              } else {
                newCssStyle = child[index].data.content.rowParams.cssStyle.replace(/color#\w{8}/, value);
              }
            } else if (value.includes('fill')) {
              if (!child[index].data.content.rowParams.cssStyle.includes('fill')) {
                newCssStyle = child[index].data.content.rowParams.cssStyle + ` ${value}`;
              } else {
                newCssStyle = child[index].data.content.rowParams.cssStyle.replace(/fill#\w{8}/, value);
              }
            } else {
              if (!child[index].data.content.rowParams.cssStyle.includes(value)) {
                newCssStyle = child[index].data.content.rowParams.cssStyle + ` ${value}`;
              } else {
                newCssStyle = child[index].data.content.rowParams.cssStyle.replace(value, '');
              }
            }
          }
          if (needUpdate) {
            child[index].data.content.rowParams.cssStyle = newCssStyle;
            const metaItem = newMetadata.find(
              (el) =>
                el.conceptId ===
                `${item.data.content.rowContentId}_${item.data.content.templateCoefficient}_${item.data.content.measure}`,
            ) as NS_API.IReportMetadata;
            if (metaItem) {
              metaItem.css = newCssStyle;
            } else {
              newMetadata.push({
                conceptId: `${item.data.content.rowContentId}_${item.data.content.templateCoefficient}_${item.data.content.measure}`,
                css: newCssStyle,
              });
            }
          }
        } else if (item.data.content.rowContentType !== 'N' && item.children) {
          loopArray(item.children);
        }
      });
    };

    loopArray(newArray);
    setNewOperation(newArray, newMetadata, currentCommands);
  };

  const handleInputHovered = (index: number) => {
    hoverTimer.current = setTimeout(() => {
      setExplanationSelected(index);
    }, 1000);
  };

  const handleInputNotHovered = () => {
    if (hoverTimer.current) {
      clearTimeout(hoverTimer.current);
      setExplanationSelected(-1);
    }
  };

  return (
    <div className="formatPartContainer">
      {explanationSelected > -1 && (
        <ExplanationBox
          title={explanations[explanationSelected].title}
          description={explanations[explanationSelected].description}
          posX={explanations[explanationSelected].posX}
        />
      )}
      <div className="buttonsLine buttonLineFirst">
        <button
          className={`buttonContainer buttonContainerBold ${
            cellSelected.length && !disabled ? null : 'disabledButton'
          } ${styleOfCellSelected.includes('bold') && 'activeButton'}`}
          onMouseEnter={(_e) => handleInputHovered(0)}
          onMouseLeave={() => handleInputNotHovered()}
          disabled={disabled}
          onClick={(e) => {
            handleInputNotHovered();
            changeStyle('bold');
            e.stopPropagation();
          }}>
          <ReactSVG src={BoldSVG} className="svg-wrapper boldSVG" />
        </button>
        <button
          className={`buttonContainer buttonContainerUnderline ${
            cellSelected.length && !disabled ? null : 'disabledButton'
          } ${styleOfCellSelected.includes('underline') && 'activeButton'}`}
          onMouseEnter={(_e) => handleInputHovered(1)}
          onMouseLeave={() => handleInputNotHovered()}
          disabled={disabled}
          onClick={(e) => {
            handleInputNotHovered();
            changeStyle('underline');
            e.stopPropagation();
          }}>
          <ReactSVG src={UnderlineSVG} className="svg-wrapper underlineSVG" />
        </button>
        <button
          className={`buttonContainer buttonContainerItalic ${
            cellSelected.length && !disabled ? null : 'disabledButton'
          } ${styleOfCellSelected.includes('italic') && 'activeButton'}`}
          onMouseEnter={(_e) => handleInputHovered(2)}
          onMouseLeave={() => handleInputNotHovered()}
          disabled={disabled}
          onClick={(e) => {
            handleInputNotHovered();
            changeStyle('italic');
            e.stopPropagation();
          }}>
          <ReactSVG src={ItalicSVG} className="svg-wrapper italicSVG" />
        </button>
      </div>
      <div className="buttonsLine" onClick={(e) => e.stopPropagation()}>
        <button
          className={`buttonContainer buttonContainerColor ${
            cellSelected.length && !disabled ? null : 'disabledButton'
          }`}
          disabled={cellSelected.length && !disabled ? false : true}
          onClick={(e) => {
            e.stopPropagation();
            setPickerColorOpen(!pickerColorOpen);
            setPickerFillOpen(false);
            handleInputNotHovered();
          }}
          onMouseEnter={(_e) => handleInputHovered(3)}
          onMouseLeave={() => handleInputNotHovered()}>
          <ReactSVG src={FontSVG} className="svg-wrapper fontSVG" />
        </button>
        {pickerColorOpen && (
          <ColorPicker
            titleText="Text Color"
            handleClose={handleClose('color')}
            handleChangeColor={handleChangeColorComplete('color')}
            color={selectedTextColor}
            asyncColorChangeComplete={true}
          />
        )}
        <button
          className={`buttonContainer buttonContainerFill ${
            cellSelected.length && !disabled ? null : 'disabledButton'
          }`}
          disabled={cellSelected.length && !disabled ? false : true}
          onClick={(e) => {
            e.stopPropagation();
            setPickerFillOpen(!pickerFillOpen);
            setPickerColorOpen(false);
            handleInputNotHovered();
          }}
          onMouseEnter={(_e) => handleInputHovered(4)}
          onMouseLeave={() => handleInputNotHovered()}>
          <ReactSVG src={FillSVG} className="svg-wrapper fillSVG" />
        </button>
        {pickerFillOpen && (
          <ColorPicker
            titleText="Background Color"
            handleClose={handleClose('fill')}
            handleChangeColor={handleChangeColorComplete('fill')}
            color={selectedFillColor}
            asyncColorChangeComplete={true}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(FormatPartMenu);
