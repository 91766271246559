/**
 * BEGIN: Check Datasource Compliance with Reports
 */

import { REPORT_TEMPLATE, TemplateKey } from '../../shared/constant/datasource.consts';
import { compareLowerCase } from '../../utils/common';

export function couldGenerateCashReport(
  card: Pick<
    NS_API.IInterpretationCard,
    'eachEntryHasOneTransactionId' | 'eachTransactionIdHasAtLeastTwoEntries' | 'areAuxAccountIdentified'
  >,
): boolean {
  return card.eachEntryHasOneTransactionId === true && card.eachTransactionIdHasAtLeastTwoEntries === true;
}

export function couldGenerateClientSales(
  card: Pick<
    NS_API.IInterpretationCard,
    'eachEntryHasOneTransactionId' | 'eachTransactionIdHasAtLeastTwoEntries' | 'areAuxAccountIdentified'
  >,
  reportName: string,
  isUnion?: boolean,
): boolean {
  if (isUnion && reportName.includes(REPORT_TEMPLATE.SALES_BY_CLIENT)) {
    return false;
  }
  return (
    card.areAuxAccountIdentified === true &&
    card.eachEntryHasOneTransactionId === true &&
    card.eachTransactionIdHasAtLeastTwoEntries === true
  );
}
export function couldGeneratePurchasesBySupplier(
  card: Pick<
    NS_API.IInterpretationCard,
    'eachEntryHasOneTransactionId' | 'eachTransactionIdHasAtLeastTwoEntries' | 'areAuxAccountIdentified'
  >,
  reportName: string,
  isUnion?: boolean,
): boolean {
  if (isUnion && compareLowerCase(reportName, REPORT_TEMPLATE.PURCHASES_BY_SUPPLIER)) {
    return false;
  }
  return (
    card.areAuxAccountIdentified === true &&
    card.eachEntryHasOneTransactionId === true &&
    card.eachTransactionIdHasAtLeastTwoEntries === true
  );
}

export function couldGenerateByDefault(
  _card: Pick<
    NS_API.IInterpretationCard,
    'eachEntryHasOneTransactionId' | 'eachTransactionIdHasAtLeastTwoEntries' | 'areAuxAccountIdentified'
  >,
) {
  return true;
}
export const datasourceComplianceCheck = {
  [TemplateKey.CR]: couldGenerateCashReport,
  [TemplateKey.CS]: couldGenerateClientSales,
  [TemplateKey.PP]: couldGeneratePurchasesBySupplier,
  /* Could generate by default without check*/
  [TemplateKey.BS]: couldGenerateByDefault,
  [TemplateKey.CB]: couldGenerateByDefault,
  [TemplateKey.CF]: couldGenerateByDefault,
  [TemplateKey.CHR1]: couldGenerateByDefault,
  [TemplateKey.PL]: couldGenerateByDefault,
  [TemplateKey.PN]: couldGenerateByDefault,
};

/**
 * END: Check Datasource Compliance with Reports
 */
