// Style
import './style.scss';

import React, { useCallback, useRef, useState } from 'react';

import ExplanationBox from '../../../../elements/ExplanationBox';
import NumbersRegulator from './NumbersRegulator';

interface INumbersPartMenu {
  currencyParams: NS_Table.ICurrency;
  setCurrencyParams: React.Dispatch<React.SetStateAction<NS_Table.ICurrency>>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

const explanations = [
  {
    title: 'Increase Decimals',
    description: 'Show more decimal places for a more precise value.',
    posX: '24px',
  },
  {
    title: 'Decrease Decimals',
    description: 'Show fewer decimal places.',
    posX: '64px',
  },
  {
    title: 'Unit',
    description: 'Switch the monetary unit used to display values in the Report',
    posX: '96px',
  },
];

/**
 * Container used to display the data part of top menu in a report
 *
 * @param param NS_Tabs.ITab props
 *
 */

const NumbersPartMenu: React.FC<INumbersPartMenu> = ({
  currencyParams,
  setCurrencyParams,
  setIsEditing,
  disabled = false,
}) => {
  // Context

  //Refs
  const hoverTimer: { current: NodeJS.Timeout | null } = useRef(null);
  // States
  const [explanationSelected, setExplanationSelected] = useState<number>(-1);

  // Function
  const handleInputNotHovered = useCallback(() => {
    if (hoverTimer.current) {
      clearTimeout(hoverTimer.current);
      setExplanationSelected(-1);
    }
  }, [hoverTimer]);

  const handleInputHovered = (index: number) => () => {
    hoverTimer.current = setTimeout(() => {
      setExplanationSelected(index);
    }, 1000);
  };

  const handleFormatChange = useCallback(() => {
    setIsEditing(true);
    handleInputNotHovered();
  }, []);

  return (
    <div className="numbersContainer" onClick={handleInputNotHovered}>
      {explanationSelected > -1 && (
        <ExplanationBox
          title={explanations[explanationSelected].title}
          description={explanations[explanationSelected].description}
          posX={explanations[explanationSelected].posX}
        />
      )}
      <NumbersRegulator
        currencyParams={currencyParams}
        setCurrencyParams={setCurrencyParams}
        onUnitFormatChange={handleFormatChange}
        onCurrencyFormatChange={handleFormatChange}
        onMouseEnterIncreaseBtn={handleInputHovered(0)}
        onMouseLeaveIncreaseBtn={handleInputNotHovered}
        onMouseEnterDecreaseBtn={handleInputHovered(1)}
        onMouseLeaveDecreaseBtn={handleInputNotHovered}
        onMouseEnterUnitList={handleInputHovered(2)}
        onMouseLeaveUnitList={handleInputNotHovered}
        disabled={disabled}
      />
    </div>
  );
};

export default NumbersPartMenu;
