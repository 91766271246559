import { serviceNotification } from '../..';

export const getNotification = async () => {
  return serviceNotification.request<Array<NS_API.IReportAssemblyNotification>>({
    method: 'GET',
    url: `/notification`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
      'Content-Type': `application/json`,
    },
  });
};
