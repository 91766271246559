// GET

import { serviceWorkspace } from '../..';

export const getWorkspace = async (workspaceId: string) => {
  return serviceWorkspace.request<NS_Workspace.IWorkSpace>({
    method: 'GET',
    url: `/workspace/${workspaceId}`,
    headers: {
      'Content-Type': `application/json`,
    },
  });
};

export const getAllWorkspaces = async () => {
  return serviceWorkspace.request({
    method: 'GET',
    url: `/workspace`,
    headers: {
      'Content-Type': `application/json`,
    },
  });
};

export const getWorkspaceUsers = async (workspaceId: string) => {
  return serviceWorkspace.request({
    method: 'GET',
    url: `/workspace/${workspaceId}/users`,
    headers: {
      'Content-Type': `application/json`,
    },
  });
};

// POST

export const createWorkspace = async (name: string, description: string) => {
  return serviceWorkspace.request({
    method: 'POST',
    url: `/workspace`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: { name, description },
  });
};

// PUT

export const updateWorkspace = async (id: string, data) => {
  return serviceWorkspace.request({
    method: 'PUT',
    url: `/workspace/${id}`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: data,
  });
};

// DELETE

export const deleteWorskpace = async (id: string) => {
  return serviceWorkspace.request({
    method: 'DELETE',
    url: `/workspace/${id}`,
    headers: {},
  });
};

export const deleteWorkspacesByIds = async (ids: Array<string>) => {
  return serviceWorkspace.request({
    method: 'DELETE',
    url: `/workspace/${ids[0]}/many`,
    data: { workspaceIds: ids },
  });
};

//PATCH

export const updatePreferredColors = async (id: string, colors: string[]) => {
  return serviceWorkspace.request({
    method: 'PATCH',
    url: `/workspace/${id}/preferred-colors`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: { preferredColors: colors },
  });
};

export const updateNumberStyle = async (id: string, data: NS_Workspace.IPreferedNumbers) => {
  return serviceWorkspace.request({
    method: 'PATCH',
    url: `/workspace/${id}/number-style`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: {
      decimalSeparator: data.decimalSeparator,
      monetaryUnit: data.monetaryUnit,
    },
  });
};

/**
 *
 * @param id string
 * @param colors {primaryTextColor?: string, primaryFillColor?: string, secondaryTextColor?: string, secondaryFillColor?: string}
 * @returns
 */
export const updateGlobalColors = async (id: string, colorOption: {}) => {
  return serviceWorkspace.request({
    method: 'PATCH',
    url: `/workspace/${id}/global-colors`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: colorOption,
  });
};
