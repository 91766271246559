// Style
import './style.scss';

import React, { useCallback, useEffect, useRef } from 'react';

interface ContextMenuFields {
  name: string;
  image: any;
  imageAlt: string;
  clickAction: any;
}

interface IContextMenuProps {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  fields: Array<ContextMenuFields>;
  position: { top?: string; right?: string; bottom?: string; left?: string };
}

/**
 * Generic context menu element
 */
const GenericContextMenu: React.FC<IContextMenuProps> = ({ isOpen, setIsOpen, fields, position }) => {
  // Refs

  const ref = useRef<HTMLDivElement>(null);

  // Effects

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  // Functions

  const handleClickOutside = useCallback(
    (event) => {
      if (ref?.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    },
    // eslint-disable-next-line
    [ref.current],
  );

  return (
    <div ref={ref}>
      {isOpen && (
        <div
          className={'contextMenu'}
          style={{ top: position.top, right: position.right, left: position.left, bottom: position.bottom }}>
          {fields.map((field) => {
            return (
              <div
                key={field.name}
                className={'contextMenuElement'}
                onClick={() => {
                  field.clickAction();
                  setIsOpen(false);
                }}>
                <div className={'contextIcon'}>
                  <img src={field.image} alt={field.imageAlt} />
                </div>
                <div className={'contextAction'}>{field.name}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default GenericContextMenu;
