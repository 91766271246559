export enum REPORT_TYPE_OF_VISUALISATIONS {
  COVERAGE = 'Coverage',
  ENTRIES = 'Entries',
  ACCOUNTS = 'Accounts',
  COVERAGE_AUTO = 'Automatique Coverage',
}

export enum DIMENSION_REPORT {
  FISCAL_YEAR = 'FiscalYear',
  ENTITY = '#Entity',
}

export enum REPORT_LIST_TYPE {
  TEMPLATE = 'TEMPLATE',
  COLLECTION = 'COLLECTION',
}

export enum COLLECTION_TYPE {
  SCORF = 'scorf',
  CUSTOMER = 'customerId',
}
