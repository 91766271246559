// Style
import './style.scss';
import backgroundLogo from '../../assets/img/logo-scorf-background-grey-light.svg';

/**
 *
 */

const AccountNotActivated = () => {
  return (
    <div className="AccountNotActivatedContainer">
      <h2>Welcome to Scorf</h2>
      <p>Thank you for registering, Scorf will activate your account within 48 hours. </p>
      <a href="https://www.scorf.io/">
        <button>Go to home</button>
      </a>
      <img src={backgroundLogo} alt="logo" className="backgroundLogo" />
    </div>
  );
};

export default AccountNotActivated;
