import './style.scss';

import { FC, useCallback } from 'react';
import { ReactSVG } from 'react-svg';

import WarningSVG from '../../assets/img/info-circle-yellow-filled.svg';
import CustomModal, { BtnClassType } from '../CustomModal';

interface ICustomModalWarning {
  description: string;
  confirmLabel?: string;
  onConfirm: () => void;
}

const CustomModalWarning: FC<ICustomModalWarning> = (props) => {
  const { description, onConfirm, confirmLabel } = props;
  const noAction = useCallback(() => {
    return;
  }, []);
  const handleSVgBeforeInjection = useCallback((svg: any) => {
    svg.setAttribute('style', 'height: 100px;width: 100px');
  }, []);
  return (
    <CustomModal
      title={''}
      onCancel={noAction}
      onConfirm={noAction}
      onClose={noAction}
      confirmDisplay={true}
      cancelDisplay={true}
      confirmBtnType={BtnClassType.Cancel}
      closeIcon={false}>
      <div className="warning-container">
        <div>
          <ReactSVG src={WarningSVG} className="svg-wrapper warningSVG" beforeInjection={handleSVgBeforeInjection} />
        </div>
        <div className="warning-description">
          <p>{description}</p>
          <button className={`button--white`} onClick={onConfirm}>
            {confirmLabel ?? 'Ok'}
          </button>
        </div>
      </div>
    </CustomModal>
  );
};
export default CustomModalWarning;
