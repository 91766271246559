// Style
import './style.scss';

import DetectFileEncodingAndLanguage from 'detect-file-encoding-and-language';
import iconv from 'iconv-lite';
import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useLocation } from 'react-router-dom';
// Router
import { ReactSVG } from 'react-svg';
import { WorkBook } from 'xlsx';

import FileImportSVG from '../../assets/img/file-import-grey.svg';
import DataModal from '../../components/dataSourceComponents/DataModal';
import DataSourceTable from '../../components/dataSourceComponents/DataSourceTable';
import DataViz from '../../components/dataSourceComponents/DataViz';
import ListData from '../../components/dataSourceComponents/ListData';
// Context
import { WorkspaceContext } from '../../context/WorkspaceContext';
import CustomModalWarning from '../../elements/CustomModalWarning';
import Footer from '../../elements/Footer';
import { IUnionLine } from '../../modals/Union';
import { useAppDispatch } from '../../redux/hook';
import { changePollingTime, POLLING_DELAY } from '../../redux/notifications';
// Constant
import {
  DEFAULT_XLSX_WORKSHEET_NAME,
  IDataSourceModal,
  IDatasourceParameters,
  IDataSourceResize,
  IDatasourceState,
  IDatasourceUnion,
  IDatasourceUploadProcess,
  ModalTypes,
  states,
  SUPPORTED_FORMATS,
} from '../../shared/constant/datasource.consts';
// Tools
import { renderContent } from '../../shared/helpers/helpers';
import ToastHelper from '../../shared/helpers/toast/ToastHelper';
import { DATA_SOURCE_MESSAGE } from '../../utils/constant';
import { readFileAsArrayBuffer } from '../../utils/readFile';
import { FILE_TYPE, readSheets, readXls, readXlsWorksheets } from '../../xlsx.worker';

const Buffer = require('buffer/').Buffer;

let scorfWasm: any = null;

export const loadScorfWasm = async () => {
  if (scorfWasm) {
    return scorfWasm;
  }

  scorfWasm = await import('scorf_re_encoder');
  console.log('scorfWasm', scorfWasm);
  return scorfWasm;
};

interface ILocationProps {
  state: {
    fileId?: string;
  };
}

/**
 * Container used to display a workspace data source page
 *
 * @param param NS_Tabs.ITab props
 */

const DataSource = () => {
  const datasourceTableContainerRef = useRef<HTMLDivElement>(null);
  const {
    name,
    workspaceId,
    importedDataSources,
    fetching,
    editLoadingDataSourcesList,
    addDataSource,
    editDatasource,
    union,
  } = useContext(WorkspaceContext);
  const dispatch = useAppDispatch();
  const { state } = useLocation() as ILocationProps;
  // Resize
  const [resize, setResize] = useState<IDataSourceResize>({
    resizeHeight: -1,
    startResize: -1,
    newMousePos: -1,
  });
  // --- Modal
  const [modal, setModal] = useState<IDataSourceModal>({
    isModalConclusionOpen: false,
    isModalImportErrorOpen: false,
    isModalCreateDataSourceOpen: false,
    isModalImportDataSourceOpen: false,
    isModalDeleteDataSourceOpen: false,
    isModalSupportOpen: false,
    isModalReportCreatorOpen: false,
    openUnionModal: false,
  });
  // Params
  const [parameters, setParameters] = useState<IDatasourceParameters>({
    supportIssueDescription: '',
    isDataSourceFullScreen: false,
    leftDisposition: true,
    isImport: false,
    reasonsImportError: undefined,
    emptyList: true,
  });
  // datasources states
  const [datasourcesState, setDatasourcesState] = useState<IDatasourceState>({
    currentDatasource: undefined,
    selectedDatasource: undefined,
    deletingDatasourceIds: [],
  });
  // --- Union
  const [unionState, setUnionState] = useState<IDatasourceUnion>({
    unionLoading: false,
    backToUnionDatasource: undefined,
    unionDatasourceFileIds: [],
    isDataSourceValidated: false,
  });

  // --- Datasource upload process
  const [files, setFiles] = useState<Array<File & { isExist?: boolean }>>([]);
  const [fileStep, setFileStep] = useState<number>(1);
  const [uploadProcess, setUploadProcess] = useState<IDatasourceUploadProcess>({
    worksheets: [],
    currentUploadFile: null,
    fileUploaded: [],
  });

  // Empty list
  useLayoutEffect(() => {
    setParameters({ ...parameters, emptyList: importedDataSources.length <= 0 });
  }, [importedDataSources.length]);

  // Check if from Report
  useEffect(() => {
    if (state?.fileId && importedDataSources?.length) {
      const dataSource = importedDataSources.find((d) => d?.fileId && d.fileId === state.fileId);
      if (dataSource?.fileId) {
        setDatasourcesState({ ...datasourcesState, currentDatasource: dataSource });
        setModal({ ...modal, isModalConclusionOpen: true });
      }
    }
  }, [state]);

  useEffect(() => {
    // no files to import
    if (!parameters.isImport) {
      return;
    }
    if (!files.length) {
      setModal({ ...modal, isModalImportDataSourceOpen: false });
      setParameters({ ...parameters, isImport: false });
      setUploadProcess({ ...uploadProcess, fileUploaded: [], currentUploadFile: null });
      return;
    }
    // import finished
    if (fileStep > files.length && files.length) {
      handleCloseCreateDatasource();
      return;
    }
    // multi-files importing
    const getWorksheets = async (): Promise<Array<string> | null> => {
      if (!files[fileStep - 1]) {
        return null;
      }
      const buffer = await files[fileStep - 1].arrayBuffer();
      return readXlsWorksheets(buffer);
    };
    const parseWorksheets = async () => {
      const split = files[fileStep - 1].name.split('.');
      if (split[split.length - 1].toLowerCase() === 'csv' || split[split.length - 1].toLowerCase() === 'txt') {
        return [files[fileStep - 1].name];
      }
      const value = await getWorksheets();
      if (!value) {
        return [];
      }
      const newWorksheets: Array<string> = Array<string>();
      value.forEach((el) => newWorksheets.push(el));
      return newWorksheets;
    };
    parseWorksheets()
      .then((r) => {
        setUploadProcess({ ...uploadProcess, worksheets: r });
      })
      .catch((err) => {});
  }, [files, fileStep, parameters.isImport]);

  useEffect(() => {
    if (!parameters.isImport) return;
    if (!files[fileStep - 1]) {
      return;
    }
    const currentFile = files[fileStep - 1];
    const { currentUploadFile, worksheets } = uploadProcess;
    let _uploadProcess = { ...uploadProcess };
    if (currentUploadFile) {
      _uploadProcess = { ...uploadProcess, fileUploaded: [...uploadProcess.fileUploaded, currentUploadFile as any] };
    }
    setUploadProcess({ ..._uploadProcess, currentUploadFile: currentFile });
    if (currentFile.isExist) {
      setFileStep(fileStep + 1);
      return;
    }
    executeWorksheets(worksheets, currentFile);
  }, [uploadProcess.worksheets, parameters.isImport]);

  const executeWorksheets = useCallback(
    (worksheets: Array<string>, currentFile: File) => {
      if (worksheets?.length && worksheets.length === 1) {
        setParameters({ ...parameters, emptyList: false });
        const sheetName = worksheets[0];
        const fileIndex = importedDataSources.findIndex(
          (ds) => ds.sourceFile === currentFile.name && ds.datasourceName === sheetName,
        );
        if (fileIndex === -1) {
          uploadWorksheets(worksheets)
            .then(() => {
              setFileStep(fileStep + 1);
            })
            .catch((err) => {});
        } else {
          setFileStep(fileStep + 1);
        }
      } else if (worksheets?.length && 1 < worksheets.length) {
        let checkSheet = false;
        for (const sheetName of worksheets) {
          const fileIndex = importedDataSources.findIndex(
            (ds) => ds.sourceFile === currentFile.name && ds.datasourceName === sheetName,
          );
          if (fileIndex === -1) {
            checkSheet = true;
          }
        }
        if (checkSheet) setModal({ ...modal, isModalCreateDataSourceOpen: true });
        else {
          setFileStep(fileStep + 1);
        }
      } else {
        setModal({ ...modal, isModalCreateDataSourceOpen: worksheets.length >= 1 });
      }
    },
    [modal, parameters, importedDataSources, fileStep],
  );

  const handleAddDatasource = useCallback(
    (worksheetToAdd: string[]) => {
      setParameters({ ...parameters, emptyList: false });
      setModal({ ...modal, isModalCreateDataSourceOpen: false });
      uploadWorksheets(worksheetToAdd)
        .then(() => {
          setFileStep(fileStep + 1);
        })
        .catch((err) => {});
    },
    [fileStep, files, parameters],
  );
  const uploadWorksheets = async (worksheetNames: string[]) => {
    const parseWorksheet = async (
      index: number,
      workbook?,
    ): Promise<[Buffer, Partial<NS_API.IDatasourceInfo>] | null> => {
      if (!files[fileStep - 1]) {
        return null;
      }
      const filenameComponents = files[fileStep - 1].name.split('.');
      const filenameExtension = filenameComponents[filenameComponents.length - 1].toLowerCase();
      const isCsv = filenameExtension === 'csv' || filenameExtension === 'txt';

      const worksheetName = isCsv ? DEFAULT_XLSX_WORKSHEET_NAME : worksheetNames[index];
      // Use a web worker to process the data
      return readXls(workbook, worksheetName);
    };

    const handleWorksheetUpload = async (index, workbook?): Promise<void> => {
      const value = await parseWorksheet(index, workbook);
      if (!value || !files[fileStep - 1]) {
        return;
      }
      const split = files[fileStep - 1].name.split('.');
      const isCsv = split[split.length - 1].toLowerCase() === 'csv';
      const datasourceInfo: NS_API.IDatasourceInfo = {
        lines: value[1].lines ? value[1].lines : 0,
        extension: split[split.length - 1],
        mimeType: files[fileStep - 1].type,
        originalName: `${files[fileStep - 1].name}-${worksheetNames[index]}`,
        datasourceName: isCsv ? DEFAULT_XLSX_WORKSHEET_NAME : worksheetNames[index],
        sourceFile: files[fileStep - 1].name,
        lineBreaker: value[1].lineBreaker ? value[1].lineBreaker : '\n',
        separator: value[1].separator ? value[1].separator : ';',
        size: value[1].size ? value[1].size : 0,
      };
      const output = await addDataSource(workspaceId, datasourceInfo, value[0]);
      if (output) {
        setModal({ ...modal, isModalImportErrorOpen: true });
        setParameters({ ...parameters, reasonsImportError: output });
      }
    };

    const loopWorksheets = async (worksheetsArray) => {
      const split = files[fileStep - 1].name.split('.');
      const extension = split[split.length - 1].toLowerCase();

      const file = files[fileStep - 1];
      const mimetype = extension === 'csv' ? 'text/csv' : files[fileStep - 1].type.toLowerCase();

      // Add loading files number
      editLoadingDataSourcesList(worksheetsArray.length);

      let encoding = 'UTF-8';
      let fileAsArrayBuffer = await readFileAsArrayBuffer(file);
      if (fileAsArrayBuffer == null) {
        throw ToastHelper.error('not able to read file, got null instead');
      }
      let fileEncoded: string | null = null;
      let handleAsString = false;
      if (extension === 'csv' || extension === 'txt') {
        const detection = await DetectFileEncodingAndLanguage(file);

        if (detection.encoding !== null) {
          encoding = detection.encoding.toLowerCase();
          // let sheetjs handle ANSI/windows1252 encoding
          if (iconv.encodingExists(encoding) && encoding !== 'utf-8') {
            const scorfWasm = await loadScorfWasm();
            try {
              let fileBuffer;
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
              if (Buffer.isEncoding(encoding)) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
                console.debug(`Encode Buffer to '${encoding}'`);
                fileBuffer = Buffer.from(fileAsArrayBuffer, encoding);
              } else {
                // INFO: Try to use binary as fallback, because Buffer.from have limited support of encodings
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
                console.debug(`Encode Buffer fallback to 'binary'`);
                fileBuffer = Buffer.from(fileAsArrayBuffer, 'binary');
              }

              fileEncoded = scorfWasm.reEncodeFileToUtf8(fileBuffer, encoding);

              console.debug(`fileEncoded from Rust: ${fileEncoded}`);
            } catch (error: unknown) {
              throw ToastHelper.error((error as Error).message);
            }
          }
        }
      }

      if (extension === 'csv' || extension === 'txt') {
        if (fileAsArrayBuffer == null) {
          console.log('file that should be encoded :', fileEncoded);
          const fileEncodedBuffer = Buffer.from(fileEncoded, 'utf8');
          fileAsArrayBuffer = cleanFileFromExtraQuotes(fileEncodedBuffer);
        } else {
          fileAsArrayBuffer = cleanFileFromExtraQuotes(fileAsArrayBuffer as ArrayBuffer);
        }
        if (encoding === 'utf-16le' || encoding === 'utf-16be') {
          const decoder = new TextDecoder(encoding);
          fileAsArrayBuffer = decoder.decode(fileAsArrayBuffer);
        }
        handleAsString = true;
      }

      let workbook: WorkBook;
      try {
        workbook = await readSheets(fileAsArrayBuffer, mimetype, handleAsString ? 'string' : 'array');
      } catch (error: unknown) {
        throw ToastHelper.error((error as Error).message);
      }
      console.log(workbook.Sheets);
      for (let i = 0; i < worksheetsArray.length; i++) {
        await handleWorksheetUpload(i, workbook);
      }
    };

    return loopWorksheets(worksheetNames).catch((e) => ToastHelper.error(`Error when importing datasource ${e}`));
  };

  const cleanFileFromExtraQuotes = useCallback((buffer: ArrayBuffer): ArrayBufferLike => {
    const array = new Uint8Array(buffer);
    return array.filter((el) => el !== 34).buffer;
  }, []);

  // FIX ME https://github.com/YumcoderCom/yumgram/blob/master/config-overrides.js
  const getUpperContainerHeight = () => {
    if (parameters.isDataSourceFullScreen) {
      return window.innerHeight;
    } else if (resize.resizeHeight > 0) {
      return resize.resizeHeight;
    } else if (datasourceTableContainerRef.current) {
      return datasourceTableContainerRef.current.offsetHeight;
    } else {
      return 500;
    }
  };

  const onDropAccepted = (newFiles: Array<File>) => {
    if (!newFiles || newFiles.length === 0) {
      return;
    }
    sortFiles(newFiles)
      .then((data) => {
        setFiles(data);
      })
      .catch((err) => {});
    setModal({ ...modal, isModalImportDataSourceOpen: true });
    setParameters({ ...parameters, isImport: false });
  };

  const sortFiles = async (_files: Array<File>) => {
    const textFiles = _files.filter((f) => f.type === FILE_TYPE.TXT || f.type === FILE_TYPE.CSV);
    const otherFiles = _files.filter((f) => !(f.type === FILE_TYPE.TXT || f.type === FILE_TYPE.CSV));
    const _result: Array<File> = [...textFiles, ...otherFiles];
    return !_result?.length
      ? []
      : _result.map((fil: any) => {
          if (fil.type === FILE_TYPE.TXT || fil.type === FILE_TYPE.CSV) {
            const isExist = importedDataSources.find((ds) => ds.sourceFile === fil.name);
            fil.isExist = !!isExist;
          }
          return fil;
        });
  };

  const onDropRejected = (fileRejections: Array<FileRejection>) => {
    if (!fileRejections || fileRejections.length === 0) {
      return;
    }
    ToastHelper.error(
      `File format of ${fileRejections.map((fileRejection) => fileRejection.file.name).join(', ')} is not supported`,
    );
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: SUPPORTED_FORMATS,
  });

  const setDatasourceToState = (fileId: string, structurationStatus: states, fields: object = {}) => {
    const importedDatasource = importedDataSources.filter((ds) => ds.fileId === fileId);
    if (
      importedDatasource.length !== 0 &&
      importedDatasource[0].structurationStatus === states.PROCESSING &&
      structurationStatus === states.DEFAULT
    ) {
      ToastHelper.warn(`Something wrong when structuring, please try again.`);
    }
    const newDataSource = { ...importedDatasource[0], structurationStatus, ...fields };
    editDatasource(fileId, newDataSource);
  };

  const callUnion = useCallback(
    (
      unionMetadata: Array<IUnionLine>,
      newFileName: string | undefined,
      backToUnionId: string,
      reportsId: Array<{ name: string; id: string }>,
    ) => {
      dispatch(changePollingTime(POLLING_DELAY.executed));
      setUnionState({ ...unionState, unionLoading: true, unionDatasourceFileIds: [] });
      setModal({ ...modal, openUnionModal: false });
      union(
        workspaceId,
        unionMetadata,
        newFileName,
        () => {
          setUnionState({
            ...unionState,
            backToUnionDatasource: undefined,
            unionLoading: false,
          });
        },
        setDatasourceToState,
        backToUnionId,
        reportsId,
      );
    },
    [workspaceId, unionState],
  );

  const openUnion = (checkedDatasourceFileIds: Array<string>) => {
    const dataSourceSelected = importedDataSources.filter((dt) => checkedDatasourceFileIds.includes(dt.fileId));
    const isDSNotValidated = dataSourceSelected.find((dt) => !dt.isCardValidated);
    if (isDSNotValidated) {
      setUnionState({ ...unionState, isDataSourceValidated: true });
      return;
    }
    const dataSourcesUnionedBrut = importedDataSources.filter((dt) => dt.unionInfo);
    const dataSourcesUnioned: any = [];
    dataSourcesUnionedBrut.map((dsU) => {
      dataSourcesUnioned.push(JSON.parse(dsU.unionInfo));
    });
    let unionAlreadyExist = false;
    const dataSourceIdSelected: string[] = [];
    dataSourceSelected.forEach((ds) => {
      dataSourceIdSelected.push(ds.fileId);
    });
    dataSourcesUnioned.map((dataSourceUnioned) => {
      if (dataSourceUnioned.length === dataSourceSelected.length) {
        const numberOfSameDataSource = dataSourceUnioned.filter((dsu) =>
          dataSourceIdSelected.includes(dsu.datasourceId),
        ).length;
        if (numberOfSameDataSource === dataSourceSelected.length) {
          unionAlreadyExist = true;
          ToastHelper.warn('A union already exist with the same data sources');
          return;
        }
      }
    });

    if (dataSourceSelected.filter((dt, _index) => dt.unionInfo).length > 0) {
      ToastHelper.warn("Can't use Union with union files");
    } else if (!unionAlreadyExist) {
      setUnionDatasourceFileIds(checkedDatasourceFileIds);
      setModal({ ...modal, openUnionModal: true });
    }
  };

  const setUnionDatasourceFileIds = useCallback((checkedDatasourceFileIds: Array<string>) => {
    setUnionState({ ...unionState, unionDatasourceFileIds: checkedDatasourceFileIds });
  }, []);
  const setBackToUnionDatasource = useCallback((backToUnionDatasource: NS_Workspace.IDataSourcesFile | undefined) => {
    setUnionState({ ...unionState, backToUnionDatasource, unionLoading: true });
  }, []);

  const openDeleteDatasources = useCallback((checkedDatasourceFileIds: Array<string>) => {
    setDatasourcesState({ ...datasourcesState, deletingDatasourceIds: checkedDatasourceFileIds });

    setModal({ ...modal, isModalDeleteDataSourceOpen: true });
  }, []);

  const openDatasourceVisualization = useCallback(
    (datasource: NS_Workspace.IDataSourcesFile) => {
      const processingDsIds = importedDataSources
        .filter((ds: NS_Workspace.IDataSourcesFile) => ds.structurationStatus === states.PROCESSING)
        .map((ds: NS_Workspace.IDataSourcesFile) => ds.fileId);

      if (processingDsIds.indexOf(datasource.fileId) !== -1) {
        ToastHelper.warn('You can not visualize a Data Source being processed');
      } else if (datasource.sourceFile && !datasource.unionInfo) {
        setDatasourcesState({ ...datasourcesState, selectedDatasource: datasource });
      } else {
        ToastHelper.info('You can not visualize entries on union files');
      }
    },
    [importedDataSources],
  );

  const closeDataVisualization = useCallback(() => {
    setDatasourcesState({ ...datasourcesState, selectedDatasource: undefined });
  }, []);

  const closeUnion = useCallback(() => {
    setModal({ ...modal, openUnionModal: false });
    setUnionState({ ...unionState, backToUnionDatasource: undefined, unionLoading: false });
  }, []);

  const openModal = (type: ModalTypes) => (datasource: NS_Workspace.IDataSourcesFile) => {
    switch (type) {
      case ModalTypes.DELETE_DATASOURCE:
        setModal({ ...modal, isModalDeleteDataSourceOpen: true });
        break;
      case ModalTypes.SUPPORT:
        setModal({ ...modal, isModalSupportOpen: true });
        setParameters({ ...parameters, supportIssueDescription: 'failed to interpret' });
        break;
      case ModalTypes.REPORT_CREATOR:
        setModal({ ...modal, isModalReportCreatorOpen: true });
        break;
      case ModalTypes.CONCLUSION:
        setModal({ ...modal, isModalConclusionOpen: true });
        break;
    }
    setDatasourcesState({ ...datasourcesState, currentDatasource: datasource });
  };

  const closeModal = (type: ModalTypes) => () => {
    switch (type) {
      case ModalTypes.DELETE_DATASOURCE:
        if (
          datasourcesState.selectedDatasource &&
          datasourcesState.deletingDatasourceIds.indexOf(datasourcesState.selectedDatasource.fileId) !== -1
        ) {
          closeDataVisualization();
        }
        setDatasourcesState({ ...datasourcesState, deletingDatasourceIds: [] });
        setModal({ ...modal, isModalDeleteDataSourceOpen: false });
        break;
      case ModalTypes.SUPPORT:
        setModal({ ...modal, isModalSupportOpen: false });
        break;
      case ModalTypes.REPORT_CREATOR:
        setModal({ ...modal, isModalReportCreatorOpen: false });
        break;
      case ModalTypes.CONCLUSION:
        setModal({ ...modal, isModalConclusionOpen: false });
        break;
    }
    setDatasourcesState({ ...datasourcesState, currentDatasource: undefined });
  };

  const handleCloseCreateDatasource = useCallback(() => {
    setFileStep(1);
    setFiles([]);
    setUploadProcess({ ...uploadProcess, worksheets: [] });
    setModal({ ...modal, isModalCreateDataSourceOpen: false });
  }, []);

  const handleCancelDataSource = useCallback(() => {
    setDatasourcesState({ ...datasourcesState, currentDatasource: undefined });
    setModal({ ...modal, isModalDeleteDataSourceOpen: false });
  }, []);

  const handleDescriptionConclusion = useCallback((description: string) => {
    setModal({ ...modal, isModalSupportOpen: true });
    setParameters({ ...parameters, supportIssueDescription: description });
  }, []);

  const closeImportModal = useCallback(() => {
    setModal({ ...modal, isModalImportDataSourceOpen: false });
    setFiles([]);
    setFileStep(1);
  }, []);

  const handleNewFiles = useCallback(
    (updatedFiles: Array<File>) => {
      sortFiles(updatedFiles)
        .then((data) => {
          setFiles(data);
        })
        .catch((err) => {});
    },
    [files],
  );

  const removeFile = (file: File) => {
    if (!parameters.isImport) {
      const _filteredFiles = [...files.filter((f) => JSON.stringify(file) !== JSON.stringify(f))];
      setFiles(_filteredFiles);
    }
  };

  const handleImport = useCallback(() => {
    if (!files?.length) return;
    setParameters({ ...parameters, isImport: true, emptyList: false });
    dispatch(changePollingTime(POLLING_DELAY.executed));
  }, [files, parameters]);

  const setOpenUnionModal = useCallback((val: boolean) => {
    setModal({ ...modal, openUnionModal: val });
  }, []);

  const setIsModalImportErrorOpen = useCallback((val: boolean) => {
    setModal({ ...modal, isModalImportErrorOpen: val });
  }, []);

  const setLeftDisposition = useCallback((val: boolean) => {
    setParameters({ ...parameters, leftDisposition: val });
  }, []);

  const setIsDataSourceFullScreen = useCallback(
    (val: boolean) => {
      setParameters({ ...parameters, isDataSourceFullScreen: val });
    },
    [parameters],
  );
  const handleConfirmUnionModal = useCallback(() => {
    setUnionState({ ...unionState, isDataSourceValidated: false });
  }, [unionState]);
  return (
    <div className="containerDatasource">
      {unionState.isDataSourceValidated && (
        <CustomModalWarning
          description={DATA_SOURCE_MESSAGE.CONFIRM_DS_UNION}
          confirmLabel={DATA_SOURCE_MESSAGE.CONFIRM_DS_LABEL}
          onConfirm={handleConfirmUnionModal}
        />
      )}
      <DataModal
        files={files}
        modal={modal}
        isImport={parameters.isImport}
        fileStep={fileStep}
        supportIssueDescription={parameters.supportIssueDescription}
        currentDatasource={datasourcesState.currentDatasource}
        unionDatasourceFileIds={unionState.unionDatasourceFileIds}
        deletingDatasourceIds={datasourcesState.deletingDatasourceIds}
        backToUnionDatasource={unionState.backToUnionDatasource}
        reasonsImportError={parameters.reasonsImportError}
        worksheets={uploadProcess.worksheets}
        fileUploaded={uploadProcess.fileUploaded}
        currentUploadFile={uploadProcess.currentUploadFile}
        closeModal={closeModal}
        closeUnion={closeUnion}
        handleDescriptionConclusion={handleDescriptionConclusion}
        handleCloseCreateDatasource={handleCloseCreateDatasource}
        handleAddDatasource={handleAddDatasource}
        setOpenUnionModal={setOpenUnionModal}
        closeImportModal={closeImportModal}
        handleImport={handleImport}
        removeFile={removeFile}
        handleNewFiles={handleNewFiles}
        setFileStep={setFileStep}
        handleCancelDataSource={handleCancelDataSource}
        setIsModalImportErrorOpen={setIsModalImportErrorOpen}
        setBackToUnionDatasource={setBackToUnionDatasource}
        setDatasourceToState={setDatasourceToState}
        callUnion={callUnion}
      />
      {!fetching && !files[fileStep - 1] && parameters.emptyList && !parameters.isDataSourceFullScreen ? (
        <div {...getRootProps()} className={`missingStep stepImportFiles ${isDragReject && 'rejected'}`}>
          <ReactSVG src={FileImportSVG} className="svg-wrapper importSVG" />
          <input {...getInputProps()} />
          <h4 className="mainSentence">It looks a bit empty around here!</h4>
          <div className="secondarySentence">Import your files by dropping them here or by clicking</div>
          <p className="filesInfo">
            Supported File Types: <strong>.txt, .csv, .xls, .xlsx</strong>
          </p>
        </div>
      ) : (
        <div
          className="datasourceContent"
          onMouseDown={(e) => {
            if (resize.startResize !== -1) {
              setResize({ ...resize, newMousePos: e.pageY, startResize: e.pageY });
            }
          }}
          onMouseMove={(e) => {
            if (resize.startResize !== -1) {
              setResize({ ...resize, newMousePos: e.pageY });
            }
          }}
          onMouseUp={(e) => {
            let resizeHeight = -1;
            if (resize.startResize !== -1 && datasourceTableContainerRef.current) {
              const height =
                e.pageY - datasourceTableContainerRef.current.getBoundingClientRect().y < 225
                  ? 225
                  : e.pageY - datasourceTableContainerRef.current.getBoundingClientRect().y;
              resizeHeight = height;
            }
            setResize({ ...resize, startResize: -1, newMousePos: -1, resizeHeight });
          }}>
          {resize.newMousePos !== -1 && (
            <div
              style={{
                position: 'fixed',
                left: 0,
                top: resize.newMousePos,
                height: 3,
                width: '100%',
                backgroundColor: '#f2f2f2',
                zIndex: 130,
              }}
            />
          )}
          <div
            className="upperContainer"
            ref={datasourceTableContainerRef}
            style={{ minHeight: resize.resizeHeight > 0 ? resize.resizeHeight : 'auto', maxHeight: '50%' }}>
            {parameters.leftDisposition && (
              <ListData
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                isDragAccept={isDragAccept}
                isDragReject={isDragReject}
              />
            )}
            <div
              className={`${
                !parameters.leftDisposition && 'datasourceTableContainerModified'
              } datasourceTableContainer`}>
              <DataSourceTable
                setDatasourceToState={setDatasourceToState}
                unionLoading={unionState.unionLoading}
                openReportCreator={openModal(ModalTypes.REPORT_CREATOR)}
                openConclusionCard={openModal(ModalTypes.CONCLUSION)}
                openDatasourceVisualization={openDatasourceVisualization}
                openSupport={openModal(ModalTypes.SUPPORT)}
                openUnion={openUnion}
                openDeleteDatasources={openDeleteDatasources}
              />
            </div>
            {!parameters.leftDisposition && (
              <ListData
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                isDragAccept={isDragAccept}
                isDragReject={isDragReject}
              />
            )}
            <div
              className="dragger"
              onMouseDown={(e) => {
                setResize({ ...resize, startResize: e.clientY });
              }}
            />
          </div>

          <div
            className={`lowerContainer ${renderContent(
              parameters.isDataSourceFullScreen,
              'dataVizFullScreen',
              'dataViz',
            )}`}>
            <DataViz
              datasource={datasourcesState.selectedDatasource}
              onClose={closeDataVisualization}
              setIsDataSourceFullScreen={setIsDataSourceFullScreen}
              resizeHeight={getUpperContainerHeight()}
              isDataSourceFullScreen={parameters.isDataSourceFullScreen}
            />
          </div>
        </div>
      )}
      <Footer
        workspaceName={name + ' workspace'}
        leftDisposition={parameters.leftDisposition}
        setLeftDisposition={setLeftDisposition}
      />
    </div>
  );
};

export default DataSource;
