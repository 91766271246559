import React from 'react';
import { ReactSVG } from 'react-svg';
import AsteriskSVG from '../../../../assets/img/asterisk.svg';
import HelpSVG from '../../../../assets/img/help.svg';
import './style.scss';
import DropDownList from '../../../../elements/DropDownList';

const coeffients = [-1, 1];

interface ITemplateCoefficientPart {
  coefficient: number;
  onChange: (value: number) => void;
}

const TemplateCoefficientPart: React.FC<ITemplateCoefficientPart> = ({ coefficient, onChange }) => {
  return (
    <div className="coefficientPartContainer">
      <div className="coefficientPartHeader">
        <ReactSVG src={AsteriskSVG} className="svg-wrapper asteriskSVG" />
        Template Coefficient
        <ReactSVG src={HelpSVG} className="svg-wrapper infoSVG" />
        <div className="coefficientPartExplanation">There are some explanation.</div>
      </div>
      <div className="coefficientPartBody">
        <DropDownList selectedElements={[coefficient]} onItemClick={onChange} elements={coeffients} disabled={false} />
      </div>
    </div>
  );
};

export default TemplateCoefficientPart;
