import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';

import ClockSVG from '../../../assets/img/clock.svg';
// Context
import ExplanationBox from '../../../elements/ExplanationBox';
import EditSaveAs from '../../../modals/EditSaveAs';
import { renderContent } from '../../../shared/helpers/helpers';
// API
import { tabMode } from '../../../shared/helpers/report/reports.helper';
import ReportAlert from '../ReportAlert';

interface IReportBandeau {
  tabDisplay: string;
  setTabDisplay: (val: string) => void;
  saveTemplate: () => void;
  renderTab: () => ReactElement;
  isHistoryOpen: boolean;
  setHistoryOpen: (val: boolean) => void;
  graphName: string;
  disabledSave: boolean;
  alerts: Array<string>;
  disable: boolean;
}

const explanations = [
  {
    title: 'Save • Blocked',
    description:
      'Can’t save your changes in a generic Template Collection. \n To use Save, create a new Template Collection using Save As.',
    posX: '0px',
  },
  {
    title: 'Save',
    description: 'Save your changes in the active Template Collection.',
    posX: '0px',
  },
  {
    title: 'Save as',
    description: 'Save your changes in a new Template Collection.',
    posX: '20px',
  },
];

const tabMenu: string[] = [tabMode.MAIN, tabMode.LAYOUT];

/**
 * Container used to display a report
 *
 * @param param NS_Tabs.ITab props
 */

const ReportBandeau: React.FC<IReportBandeau> = ({
  tabDisplay,
  setTabDisplay,
  saveTemplate,
  renderTab,
  setHistoryOpen,
  isHistoryOpen,
  graphName,
  disabledSave,
  alerts,
  disable = false,
}) => {
  const [explanationSelected, setExplanationSelected] = useState<number>(-1);
  const hoverTimer: { current: NodeJS.Timeout | null } = useRef(null);
  const [isSaveAsOpen, setIsSaveAsOpen] = useState<boolean>(false);

  const handleInputHovered = (index: number) => {
    hoverTimer.current = setTimeout(() => {
      setExplanationSelected(index);
    }, 1000);
  };

  const handleInputNotHovered = () => {
    if (hoverTimer.current) {
      clearTimeout(hoverTimer.current);
      setExplanationSelected(-1);
    }
  };

  const isStandardTC = useMemo(() => {
    return graphName === 'Standard French Mapping';
  }, [graphName]);

  const handleCloseModal = useCallback(() => {
    setIsSaveAsOpen(false);
  }, []);

  return (
    <>
      {isSaveAsOpen && <EditSaveAs closeModal={handleCloseModal} saveTemplate={saveTemplate} />}
      <div className="topContainer">
        <div className="tabsContainer">
          {tabMenu.map((tab) => {
            return (
              <div
                key={tab}
                className={`tab ${tab === tabDisplay && 'active'}`}
                onClick={() => {
                  setTabDisplay(tab);
                }}>
                <div className="titleTab">
                  {tab}
                  {tab === tabDisplay && <span />}
                </div>
              </div>
            );
          })}
        </div>
        <div className="row">
          <div className="leftPart">{renderTab()}</div>
          <div className="rightPart">
            {explanationSelected > -1 && (
              <ExplanationBox
                title={explanations[explanationSelected].title}
                description={explanations[explanationSelected].description}
                posX={explanations[explanationSelected].posX}
              />
            )}
            <button
              className={`btnHistory ${isHistoryOpen && 'activated'} ${disable && 'disabled'}`}
              onClick={() => setHistoryOpen(!isHistoryOpen)}
              disabled={disable}>
              <ReactSVG src={ClockSVG} className="svg-wrapper clockSVG" />
              <span>History</span>
            </button>
            <button
              className={`btn btnSave ${renderContent(
                disabledSave || isStandardTC,
                'button--green--disabled',
                'button--green',
              )}`}
              onClick={(e) => {
                !isStandardTC && !disabledSave && saveTemplate();
                handleInputNotHovered();
              }}
              onMouseEnter={() => handleInputHovered(isStandardTC ? 0 : 1)}
              onMouseLeave={() => handleInputNotHovered()}>
              Save
            </button>
            <button
              className={`btn btnSaveAs ${renderContent(disabledSave, 'button--white--disabled', 'button--white')}`}
              onClick={(e) => {
                !disabledSave && setIsSaveAsOpen(true);
                handleInputNotHovered();
              }}
              onMouseEnter={() => handleInputHovered(2)}
              onMouseLeave={() => handleInputNotHovered()}
              disabled={disable}>
              Save as
            </button>
          </div>
        </div>
      </div>
      <ReportAlert data={alerts} />
    </>
  );
};

export default ReportBandeau;
