export const isDisabled = (isDisabledOrNot: boolean): string => {
  return isDisabledOrNot ? 'disabled' : '';
};

export const isDisable = (isDisableOrNot: boolean): string => {
  return isDisableOrNot ? 'disable' : '';
};

export const isItemSelected = (isItemSelectedOrNot: boolean): string => {
  return isItemSelectedOrNot ? 'selected' : '';
};

export const isflipImg = (isflipImgOrNot: boolean): string => {
  return isflipImgOrNot ? 'flipImg' : '';
};

export const displayImagineDragon = (isImagineDragonDisplayedOrNot: boolean): string => {
  return isImagineDragonDisplayedOrNot ? 'imagineDragons' : '';
};

export const isTextSelected = (isTextSelectedOrNot: boolean): string => {
  return isTextSelectedOrNot ? 'textSelected' : '';
};

export const isItemOpen = (isItemOpenOrNot: boolean): string => {
  return isItemOpenOrNot ? 'open' : '';
};

export const isContextActionDisabled = (isContextActionDisabledOrNot: boolean): string => {
  return isContextActionDisabledOrNot ? 'contextActionDisabled' : '';
};

export const isButtonFilterSelected = (isButtonFilterSelectedOrNot: boolean): string => {
  return isButtonFilterSelectedOrNot ? 'buttonFilterSelected' : '';
};

export const isButtonDisableElsePrimary = (isButtonDisableElsePrimaryOrNot: boolean): string => {
  return isButtonDisableElsePrimaryOrNot ? 'disablePrimary' : 'primary';
};

export const isButtonDisableElseCancel = (isButtonDisableElsePrimaryOrNot: boolean): string => {
  return isButtonDisableElsePrimaryOrNot ? 'disableCancel' : 'cancel';
};

export const isDescriptionHidden = (isDescriptionHiddenOrNot: boolean): string => {
  return isDescriptionHiddenOrNot ? 'descriptionHidden' : '';
};

export const isParentHovered = (isParentHoveredOrNot: boolean): string => {
  return isParentHoveredOrNot ? 'parentHovered' : '';
};

export const isHover = (isHoverOrNot: boolean): string => {
  return isHoverOrNot ? 'hover' : '';
};

export const isRotate = (isRotateOrNot: boolean): string => {
  return isRotateOrNot ? 'rotate' : '';
};

export const isHidden = (shouldHide) => {
  return shouldHide ? 'hidden' : '';
};

export const isHighlightedNode = (isHighlightedNodeOrNot: boolean): string => {
  return isHighlightedNodeOrNot ? 'highlightedNode' : '';
};

export const isHighlightedNodeFaded = (isHighlightedNodeFadedOrNot: boolean): string => {
  return isHighlightedNodeFadedOrNot ? 'highlightedNodeFaded' : '';
};

export const isHighlightedBorderLeft = (isHighlightedBorderLeftOrNot: boolean): string => {
  return isHighlightedBorderLeftOrNot ? 'highlightedBorderLeft' : '';
};

export const isFirstOrHoveredIndentation = (index: number, levelHoveredNode: number): string => {
  if (index === 0) {
    return 'firstIndentation';
  }
  return levelHoveredNode !== -1 && index === levelHoveredNode + 1 ? 'hoveredIndentation' : '';
};

export const isListItemReportDisabledOrSelected = (
  disabled: boolean,
  selectedType: number | undefined,
  selectedDataSrc: number,
  index: number,
  key: number,
): string => {
  if (disabled) {
    return 'listItemReportDisabled';
  }
  return selectedType === index && selectedDataSrc === key ? 'listItemReport listItemSelectedReport' : 'listItemReport';
};

export const isSelectOpen = (isSelectOpenOrNot: boolean): string => {
  return isSelectOpenOrNot ? '' : 'selectHidden';
};

export const isRedOrOrangeOrGreen = (isRed: boolean, isOrange: boolean) => {
  if (isRed) {
    return 'red';
  } else if (isOrange) {
    return 'orange';
  } else {
    return 'green';
  }
};
