import React from 'react';
import './style.scss';
interface IProgressLoaderProps {
  /**
   * Optional color hash value.
   * @default #46b78e
   */
  color?: string;
}

/**
 * Progress bar loader
 */
const ProgressLoader: React.FC<IProgressLoaderProps> = ({ color }) => {
  return (
    <div className="progressLoaderContainer">
      <div className="filler" style={{ backgroundColor: color ?? '#46b78e' }} />
    </div>
  );
};

export default ProgressLoader;
