// Style
import './style.scss';

import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
// Router
import { useNavigate } from 'react-router-dom';

import emptyReport from '../../assets/img/report-undraw.svg';
import ChartVisualisationContainer from '../../components/reportComponents/ChartVisualisationContainer';
import DataVisualisationContainer from '../../components/reportComponents/DataVisualisationContainer';
import DimensionsComponent from '../../components/reportComponents/DimensionsComponent';
import EditStatement from '../../components/reportComponents/EditStatement';
import History from '../../components/reportComponents/History';
import KpiComponent from '../../components/reportComponents/KpiComponent';
import LayoutMenu from '../../components/reportComponents/LayoutMenu';
import MainMenu from '../../components/reportComponents/MainMenu';
import PropertiesContainer from '../../components/reportComponents/PropertiesContainer';
import ReportBandeau from '../../components/reportComponents/ReportBandeau';
import TopValueComponent from '../../components/reportComponents/TopValueComponent';
import { ReportContext } from '../../context/ReportContext';
// Context
import { WorkspaceContext } from '../../context/WorkspaceContext';
import CustomModal, { BtnClassType } from '../../elements/CustomModal';
import CustomModalWarning from '../../elements/CustomModalWarning';
import Footer from '../../elements/Footer';
import ProgressLoader from '../../elements/Loaders/ProgressLoader';
import SearchComponent from '../../elements/SearchComponent';
import EditSaveResults from '../../modals/EditSaveResults';
import NavigationPrompt from '../../modals/NavigationPrompt';
import { useAppDispatch } from '../../redux/hook';
import { addNotification, changePollingTime, POLLING_DELAY } from '../../redux/notifications';
import { setTemplateCollections } from '../../redux/workspace';
import { getGraph } from '../../shared/api/graph';
import { getDatasourceCoverage, updateGraphWithCommandsList } from '../../shared/api/mapping';
// API
import {
  createNewReport,
  getReport,
  getScorfColumns,
  updateReport,
  updateReportMetadata,
} from '../../shared/api/report';
import { postReportAssembly } from '../../shared/api/reportAssembly';
import { createWorksheet } from '../../shared/api/tabs';
import { getAllTemplateCollectionsForWorkspace } from '../../shared/api/templateCollection';
import { TemplateKey } from '../../shared/constant/datasource.consts';
import { renderContent, toCurrency } from '../../shared/helpers/helpers';
import { onSaveParametersHelper } from '../../shared/helpers/mapping/commandsMetaData.helper';
import { tabMode } from '../../shared/helpers/report/reports.helper';
import StringHelper from '../../shared/helpers/string/string.helper';
import ToastHelper from '../../shared/helpers/toast/ToastHelper';
// Tools
import { Queue } from '../../shared/helpers/tree/data-structures';
import TreeHelper from '../../shared/helpers/tree/TreeHelper';
import { DATA_SOURCE_MESSAGE, REPORT_TABS_TYPE } from '../../utils/constant';
import { REPORT_TYPE_OF_VISUALISATIONS } from '../../utils/enum';

export enum ReportDisplayMode {
  SALES_BY_CLIENT = 'SalesByClient',
  PURCHASES_BY_PROVIDER = 'PurchasesByProvider',
  COUNTER_PART = 'Counterpart',
}

const getNodeAccounts = async (
  reportNode: NS_TREE.ITreeRoot<undefined>,
  reportNodeId: string,
): Promise<Array<Record<number, string>>> => {
  const selectedNode = TreeHelper.searchNodeById(reportNode, reportNodeId);
  const queue = new Queue();
  const result: Array<Record<number, string>> = [];
  queue.enqueue(selectedNode);
  while (!queue.isEmpty()) {
    const currentNode: NS_TREE.ITreeRoot<undefined> = queue.dequeue();
    if (currentNode) {
      if (currentNode.data.content.rowContentType === 'N') {
        result.push({ 0: currentNode.data.content.rowContentId, 1: currentNode.data.content.rowContentName });
      } else {
        if (currentNode.children) {
          for (const child of currentNode.children) {
            queue.enqueue(child);
          }
        }
      }
    }
  }
  return result;
};

const getNodeAccountsArr = async (
  reportNode: NS_TREE.ITreeRoot<undefined>,
  reportNodesId: string[],
): Promise<Array<Record<number, string>>[]> => {
  const results = reportNodesId.map((reportNodeId) => {
    return getNodeAccounts(reportNode, reportNodeId);
  });
  return Promise.all(results);
};

interface IReportProps {
  worksheetId: string;
}

const errorMessage = 'Something went wrong while getting standard statement';
const errorChartMessage = 'Something went wrong while getting chart';

/**
 * Container used to display a report
 *
 * @param param NS_Tabs.ITab props
 */

const Reports: React.FC<IReportProps> = ({ worksheetId }) => {
  // Context
  const {
    name,
    workspaceId,
    dataSources,
    tabs,
    saveTabs,
    newTab,
    loadingTabs,
    addLoadingTab,
    removeLoadingTab,
    setSumInFooter,
    preferredNumbers,
    reportSaving: savingTree,
    setSavingReport: setSavingTree,
    editTypeTab,
  } = useContext(WorkspaceContext);

  const {
    current,
    clearHistory,
    currentTree,
    currentCommands,
    currentMetadata,
    initiateHistory,
    setUndo,
    setRedo,
    currentListOfAccountIdsAddedFromCoverage,
    currentListOfAccountNamesAddedFromCoverage,
    setVersion,
    setNewOperation,
    reportId,
  } = useContext(ReportContext);

  const [displayingTree, setDisplayingTree] = useState<NS_API.IGraph>([]);
  // Location

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // Refs
  const miningContainerRef = useRef<HTMLDivElement>(null);
  const reportContainerRef = useRef<HTMLDivElement>(null);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const dataVisualisationContainerRef = useRef<HTMLDivElement>(null);
  const scrollPartRef = useRef<HTMLDivElement>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const reportIdRef = useRef<string>(reportId);

  // States
  const [isHistoryOpen, setHistoryOpen] = useState<boolean>(false);

  // --- Others
  const [zoomValue, setZoomValue] = useState<string>('10');
  const [leftDisposition, setLeftDisposition] = useState<boolean>(true);

  // --- Data sources
  const [selectedDataSource, setSelectedDataSource] = useState<string>('');
  const [selectedDataSourceGraph, setSelectedDataSourceGraph] = useState<string>('');
  const [graphName, setGraphName] = useState<string>('');

  const [initialDSGraph, setInitialDSGraph] = useState<string>('');
  const [isUnion, setIsUnion] = useState<boolean>(false);

  // --- Template
  const [templateList, setTemplateList] = useState<Object>();
  const [fetchingTemplateList, setFetchingTemplateList] = useState<boolean>(false);
  const [templateGroups, setTemplateGroups] = useState<{ 1: string; 2: string } | undefined>();
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');

  // --- Cumul display
  const [cumulDisplay, setCumulDisplay] = useState<boolean>(false);
  const [originalCumulDisplay, setOrinalCumulDisplay] = useState<boolean>(false);
  const [, /*stopCumul*/ setStopCumul] = useState<Array<number>>([]);

  // --- Statement data
  const [statementColumns, setStatementColumns] = useState<Array<NS_Table.IColumnHeader>>(
    Array<NS_Table.IColumnHeader>(),
  );
  const [treeStatementData, setTreeStatementData] = useState<Array<NS_TREE.ITreeRoot<undefined>>>(
    Array<NS_TREE.ITreeRoot<undefined>>(),
  );

  const [columnsDimensions, setColumnsDimensions] = useState<Array<string>>([]);
  const [originalColumnsDimensions, setOriginalColumnsDimensions] = useState<Array<string>>([]);
  const [multipleDimensions, setMultipleDimensions] = useState<boolean>(false);
  const [columnsValueNames, setColumnsValueNames] = useState<Array<string>>([]);
  const [dateHeaderType, setDateHeaderType] = useState<string>('');
  const [fiscalYears, setFiscalYears] = useState<any>();
  const [nodeIdClicked, setNodeIdClicked] = useState<string>(''); // Used in reportStaement

  const [outDated, setOutDated] = useState<boolean>(false);

  // --- Dimension
  const dateRender = useMemo(() => ['year', 'month'], []);
  const [selectedDateRender, setSelectedDateRender] = useState<number>(0);
  const [originalSelectedDateRender, setOriginalSelectedDateRender] = useState<number>(0);
  const [monthGroupedBy, setMonthGroupedBy] = useState<string>();
  const [originalMonthGroupedBy, setOriginalMonthGroupedBy] = useState<string>();

  // --- Currency
  const [currencyParams, setCurrencyParams] = useState<NS_Table.ICurrency>({ format: '0,0.00', displayUnit: 1 });
  const [isGlobalCurrency, setIsGlobalCurrency] = useState<boolean>(true);
  const [isGlobalDecimal, setIsGlobalDecimal] = useState<boolean>(true);

  // --- Edit menu
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [tabDisplay, setTabDisplay] = useState<string>(tabMode.MAIN);

  const [justSaveAs, setJustSaveAs] = useState<boolean>(false);

  // --- Graph Menu
  const [isGraphOpen, setIsGraphOpen] = useState<boolean>(false);

  // --- Properties Menu
  const [isPropertiesOpen, setIsPropertiesOpen] = useState<boolean>(false);
  const [propertiesSettingNode, setPropertiesSettingNode] = useState<NS_TREE.ITreeRoot<string>>();

  // --- Reports
  const [isReportExist, setIsReportExist] = useState<boolean>(false);
  const [reportName, setReportName] = useState<string>('');
  const [analyticParams, setAnalyticParams] = useState<Array<NS_Table.IColumnHeader>>([]);
  const [tableBodyHeight, setTableBodyHeight] = useState<number>();
  const [nameColWidth, setNameColWidth] = useState<number>();
  const [displayOpeningColumn, setDisplayOpeningColumn] = useState<boolean>(false);
  const [alerts, setAlerts] = useState<Array<string>>([]);

  // --- Initial selected states
  const [initialSelectedDataSrc, setInitialSelectedDataSrc] = useState<string>('');
  const [initialSelectedTemp, setInitialSelectedTemp] = useState<string>('');

  // --- Misc
  const [openNewWorksheetModal, setOpenNewWorksheetModal] = useState<boolean>(false);
  const [openDiscardModal, setOpenDiscardModal] = useState<boolean>(false);
  const [discard, setDiscard] = useState<boolean>(false);

  // -- Accounts List
  const [allAccountsListNames, setAllAccountsListNames] = useState<string[]>([]);
  const [allAccountsListIds, setAllAccountsListIds] = useState<string[]>([]);
  const [accountsListNames, setAccountsListNames] = useState<string[]>([]);
  const [accountsListIds, setAccountsListIds] = useState<string[]>([]);
  const [rowClicked, setRowClicked] = useState<number>(-1);

  // --- Mapping
  const [mappingTreeWithRoot, setMappingTreeWithRoot] = useState<NS_API.IGraph>([]);
  const [saveResultsOpen, setSaveResultsOpen] = useState<boolean>(false);
  const [saveResults, setSaveResults] = useState<NS_API.IEditSaveResult | undefined>();
  const [cellSelected, setCellSelected] = useState<Array<string>>([]);
  const [nameOfNodeSelected, setNameOfNodeSelected] = useState<NS_REPORT.INameSelectedForCoverageProps>({
    accountId: '',
    accountName: '',
  });
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<string>('');
  const [parentCellsInDataViz, setParentCellsInDataViz] = useState<string[]>([]);
  const [accountSelectedInReport, setAccountSelectedInReport] = useState<string>('');
  const [reportDisplayMode, setReportDisplayMode] = useState<string>();

  // -- Search

  const [searchValue, setSearchValue] = useState<string>('');
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [showResult, setShowResult] = useState<number>(-1);
  const [openInput, setOpenInput] = useState<boolean>(false);

  // -- Dimensions Show

  const [openDimensions, setOpenDimensions] = useState<boolean>(false);
  const [openKpi, setOpenKpi] = useState<boolean>(false);
  const [openTopValue, setOpenTopValue] = useState<boolean>(false);
  const [headerSelectionMode, setHeaderSelectionMode] = useState<boolean>(false);

  // Header selection
  const [selectedHeaders, setSelectedHeaders] = useState<Array<NS_Table.IColumnHeader>>([]);

  // caculated cols
  const [nodesContentIndices, setNodesContentIndices] = useState<NS_API.INodesContentIndices>();

  // Cells selection
  const [selectedCells, setSelectedCells] = useState<Array<NS_TREE.ICellSelected>>([]);
  const [selectedCellsRowNodes, setSelectedCellsRowNodes] = useState<NS_TREE.ITreeRootWithoutChild[]>([]);
  const [selectedCellsColumns, setSelectedCellsColumns] = useState<number[]>([]);
  const [selectedCellsInGroup, setSelectedCellsInGroup] = useState<NS_TREE.ICellSelected[]>([]);
  const [selectedCellsOutGroup, setSelectedCellsOutGroup] = useState<NS_TREE.ICellSelected[]>([]);
  const selectedCellsRowNodesRef = useRef<NS_TREE.ITreeRootWithoutChild[]>();
  const selectedCellsOutGroupRef = useRef<NS_TREE.ICellSelected[]>();
  const selectedCellsColumnsRef = useRef<number[]>();

  // -- Mining
  const [nodeOpenedAccount, setNodeOpenedAccount] = useState<NS_TREE.IMining>({ nodeId: '' });
  const nodeOpenedAccountRef = useRef<NS_TREE.IMining>(nodeOpenedAccount);

  // -- DataVisualisation

  const [typeOfVisualisation, setTypeOfVisualisation] = useState<string>('');

  // --- Change

  const [dimensionsChange, setDimensionsChange] = useState<boolean>(false);

  // Search bar open ref
  const openInputRef = useRef<boolean>(openInput);

  // Search Node
  const [cellFound, setCellFound] = useState<string>('');

  // Top value
  const [defaultTopValue, setDefaultTopValue] = useState<number>(10);
  const [topValue, setTopValue] = useState<number>(10);

  // Data Source
  const [isDataSourceValidated, setIsDataSourceValidated] = useState<boolean>(true);

  const getSelectedDatasource = useCallback(
    (selectedFileId: string) => {
      return dataSources.find((x) => {
        return x.fileId === selectedFileId;
      });
    },
    [dataSources],
  );

  const updateCurrencyParams = (monetaryUnit: string, decimalSeparator: number) => {
    const tempCurrencyParams: NS_Table.ICurrency = { format: '0,0', displayUnit: 1 };
    if (monetaryUnit === 'DEFAULT') {
      tempCurrencyParams.displayUnit = 1;
    } else if (monetaryUnit === 'k') {
      tempCurrencyParams.displayUnit = 1000;
    } else if (monetaryUnit === 'WORKSPACE') {
      if (preferredNumbers?.currencyParams) {
        tempCurrencyParams.displayUnit = preferredNumbers.currencyParams.displayUnit;
      }
    } else {
      tempCurrencyParams.displayUnit = 1000000;
    }
    if (decimalSeparator === -1) {
      if (preferredNumbers?.currencyParams) {
        tempCurrencyParams.format = preferredNumbers.currencyParams.format;
      }
    } else {
      for (let i = 0; i < decimalSeparator; i++) {
        if (i === 0) {
          tempCurrencyParams.format = tempCurrencyParams.format + '.0';
        } else {
          tempCurrencyParams.format = tempCurrencyParams.format + '0';
        }
      }
    }
    setCurrencyParams(tempCurrencyParams);
  };

  const checkAccountNumsOfCoverage = useCallback(
    (
      classNumbers: number[],
      datasourceCoverage: NS_API.IDatasourceAccountCoverageDTO,
      accounts: string[],
      template: string,
    ) => {
      const missingAccountClasses: Array<number> = [];
      for (const classNumber of classNumbers) {
        const numberOfAccountOfClass = new Set(
          accounts.filter((accountId) => accountId.startsWith(classNumber.toString())),
        ).size;
        if (datasourceCoverage[classNumber]) {
          const missingNumber = datasourceCoverage[classNumber].count - numberOfAccountOfClass;
          if (missingNumber > 0) {
            missingAccountClasses.push(classNumber);
          }
        }
      }

      if (missingAccountClasses.length) {
        ToastHelper.warn(
          `Please note that some of the class ${missingAccountClasses
            .map((value) => `#${value}`)
            .join(' and class ')} accounts are not included in this ${StringHelper.getTemplateFullName(
            template,
          )} report.`,
          { onClick: () => setTypeOfVisualisation('Coverage') },
        );
      }
    },
    [],
  );

  const getCoverage = useCallback(
    (datasourceId: string, template: string, treeStatement: NS_TREE.ITreeRoot<undefined>[]) => {
      getDatasourceCoverage(workspaceId, datasourceId)
        .then((res) => {
          if (res) {
            getNodeAccounts(treeStatement[0], treeStatement[0].id)
              .then((resp) => {
                const ids = resp.map((element) => element[0]);
                switch (template) {
                  case 'PL':
                    checkAccountNumsOfCoverage([6, 7], res.data.datasourceCoverage, ids, template);
                    break;
                  case 'BS':
                    checkAccountNumsOfCoverage([1, 2, 3, 4, 5], res.data.datasourceCoverage, ids, template);
                    break;
                  case 'CF':
                    checkAccountNumsOfCoverage([1, 2, 3, 4, 6, 7], res.data.datasourceCoverage, ids, template);
                    break;
                  case 'CB':
                    checkAccountNumsOfCoverage([5], res.data.datasourceCoverage, ids, template);
                    break;
                  default:
                    break;
                }
              })
              .catch((err) => {});
          } else {
            ToastHelper.error(`Failed to get datasource coverage.`);
          }
        })
        .catch((err) => {});
    },
    [workspaceId],
  );

  const initiateStates = (_typeOfVisualisation?: string) => {
    if (_typeOfVisualisation !== REPORT_TYPE_OF_VISUALISATIONS.COVERAGE_AUTO) setTypeOfVisualisation('');
    setOpenDimensions(false);
    setSelectedHeaders([]);
    setOpenKpi(false);
    setOpenTopValue(false);
    setTreeStatementData([]);
    setOpenNewWorksheetModal(false);
    setInitialSelectedTemp(selectedTemplate);
    setIsGraphOpen(false);
    setIsPropertiesOpen(false);
    setAlerts([]);
  };

  const updateReportByData = (data: NS_API.I_RD_StandardStmt) => {
    saveTabs(data._id, data.datasourceId);
    if (reportIdRef.current === data._id) {
      getCoverage(data.datasourceId, data.templateName, data.statement);
      setIsEditing(false);
      setColumnsDimensions(data.colValueDimension);
      setOriginalColumnsDimensions(data.colValueDimension);
      setColumnsValueNames(data.colValueNames);
      setStatementColumns(data.columns);
      const treeStatementDataWithFormulaCols = TreeHelper.loopTreeStatementToApplyParams(
        data.statement,
        data.nodesContent,
        data.nodesContentIndices,
      );
      setTreeStatementData(treeStatementDataWithFormulaCols);
      setNodesContentIndices(data.nodesContentIndices);
      setCumulDisplay(data.cumulDisplay);
      setOrinalCumulDisplay(data.cumulDisplay);
      setStopCumul(data.stopCumulIndexes);
      setDateHeaderType(data.dateHeaderType);
      setFiscalYears(data.fiscalYears);
      setDisplayOpeningColumn(data.displayOpeningColumn);
      data.yearOrMonth === 'year' ? setSelectedDateRender(0) : setSelectedDateRender(1);
      data.yearOrMonth === 'year' ? setOriginalSelectedDateRender(0) : setOriginalSelectedDateRender(1);
      setMonthGroupedBy(data.monthGroupedBy);
      setOriginalMonthGroupedBy(data.monthGroupedBy);
      setReportDisplayMode(data.displayMode);
      setInitialSelectedDataSrc(data.datasourceId);
      setInitialSelectedTemp(data.templateName);
      setSelectedDataSource(data.datasourceId);
      setInitialDSGraph(data.graphId);
      setSelectedDataSourceGraph(data.graphId);
      getTemplateList(data.graphId);
      setSelectedTemplate(data.templateName);
      setIsReportExist(true);
      setDefaultTopValue(data.nbTopValues ?? defaultTopValue);
      setTopValue(data.nbTopValues ?? defaultTopValue);
      setOutDated(data.outOfDate);

      if (data.monetaryUnit && data.decimalSeparator) {
        updateCurrencyParams(data.monetaryUnit, data.decimalSeparator);
      }
    }
  };

  useEffect(() => {
    reportIdRef.current = reportId;
  }, [reportId]);

  // Generate new Report
  const createReports = () => {
    const dataSourceIndex = dataSources.findIndex((ds) => ds.fileId === selectedDataSource);
    const isUnion = !!dataSources[dataSourceIndex].unionInfo;
    initiateStates();
    addLoadingTab(worksheetId);
    if (selectedTemplate === TemplateKey.CHR1) {
      newReportAssembly(worksheetId, dataSourceIndex).catch((err) => {});
      return;
    }
    createNewReport(workspaceId, {
      graphId: selectedDataSourceGraph,
      datasourceId: selectedDataSource,
      templateKey: selectedTemplate,
      worksheetId: worksheetId,
      isUnion,
    })
      .then((res) => {
        if (res) {
          const { data } = res;
          if (data.status && data.status === 'FAILED') {
            setSelectedTemplate('');
            ToastHelper.warn(data.message ?? '');
          } else {
            updateReportByData(data);
            const analyticsObj = {
              event_label: 'report_created',
              reportId: data._id,
              datasourceId: data.datasourceId,
              templateId: data.templateId,
              templateName: data.templateName,
              workspaceId: workspaceId,
              graphId: data.graphId,
              colValueDimension: JSON.stringify(data.colValueDimension),
              cumulDisplay: data.cumulDisplay,
              isUnion: data.isUnion,
            };
            setAlerts(data.alerts ?? []);
            // Add datasourceName if it isn't a union file
            if (!analyticsObj.isUnion) {
              analyticsObj['datasourceName'] = atob(data.datasourceId);
            }
            window.gtag('event', 'report_created', analyticsObj);
          }
        } else {
          ToastHelper.error(errorMessage);
        }
        removeLoadingTab(worksheetId);
      })
      .catch((e) => {
        removeLoadingTab(worksheetId);
        ToastHelper.error(errorMessage, e);
      });
    // eslint-disable-next-line
  };

  // Generate the report and save it
  const updateReports = (overwrite = false) => {
    const dataSourceIndex = dataSources.findIndex((ds) => ds.fileId === selectedDataSource);
    const isUnion = !!dataSources[dataSourceIndex].unionInfo;
    initiateStates();
    setOpenDiscardModal(false);
    setDiscard(false);
    addLoadingTab(worksheetId);
    let currencyType: string;
    if (currencyParams.displayUnit === 1) {
      currencyType = 'DEFAULT';
    } else if (currencyParams.displayUnit === 1000) {
      currencyType = 'k';
    } else {
      currencyType = 'M';
    }
    let decimalSeparator = currencyParams.format.split('.')[1]?.length;
    if (!decimalSeparator) {
      decimalSeparator = 0;
    }
    if (selectedTemplate === TemplateKey.CHR1) {
      postReportAssembly(workspaceId, worksheetId, {
        columnDimensions: columnsDimensions,
        graphId: selectedDataSourceGraph,
        datasourceId: selectedDataSource,
        templateKey: selectedTemplate,
        yearOrMonth: dateRender[selectedDateRender],
        cumulDisplay: cumulDisplay,
        worksheetId: worksheetId,
        monetaryUnit: isGlobalCurrency ? undefined : currencyType,
        decimalSeparator: isGlobalDecimal ? undefined : decimalSeparator,
        overwrite: overwrite,
        isUnion,
        nbTopValues: topValue,
        monthGroupedBy: monthGroupedBy,
      })
        .then((res) => {
          if (res?.data?.reportAssemblyId) {
            const { reportAssemblyId, message, status, worksheetName } = res.data;
            ToastHelper.success(message);
            editTypeTab(worksheetId, REPORT_TABS_TYPE.CHART_REPORT, checkWorkSheetName(worksheetName));
            dispatch(addNotification({ reportAssemblyId, status, message }));
            dispatch(changePollingTime(POLLING_DELAY.executed));
          } else {
            ToastHelper.error(errorChartMessage);
          }
          removeLoadingTab(worksheetId);
        })
        .catch((e) => {
          removeLoadingTab(worksheetId);
          ToastHelper.error(errorMessage, e);
        });
      return;
    }

    updateReport(workspaceId, {
      columnDimensions: columnsDimensions,
      graphId: selectedDataSourceGraph,
      datasourceId: selectedDataSource,
      templateKey: selectedTemplate,
      yearOrMonth: dateRender[selectedDateRender],
      cumulDisplay: cumulDisplay,
      worksheetId: worksheetId,
      monetaryUnit: isGlobalCurrency ? undefined : currencyType,
      decimalSeparator: isGlobalDecimal ? undefined : decimalSeparator,
      overwrite: overwrite,
      isUnion,
      nbTopValues: topValue,
      monthGroupedBy: monthGroupedBy,
    })
      .then((res) => {
        if (res) {
          const { data } = res;
          if (data.status && data.status === 'FAILED') {
            setSelectedTemplate('');
            ToastHelper.warn(data.message ?? '');
          } else {
            updateReportByData(data);
            clearHistory(data.statement[0].children);
            setAlerts(data.alerts ?? []);
            const analyticsObj = {
              event_label: 'report_update',
              reportId: data._id,
              datasourceId: data.datasourceId,
              templateId: data.templateId,
              templateName: data.templateName,
              workspaceId: workspaceId,
              graphId: data.graphId,
              colValueDimension: JSON.stringify(data.colValueDimension),
              cumulDisplay: data.cumulDisplay,
              isUnion: data.isUnion,
            };
            // Add datasourceName if it isn't a union file
            if (!analyticsObj.isUnion) {
              analyticsObj['datasourceName'] = atob(data.datasourceId);
            }
            window.gtag('event', 'report_update', analyticsObj);
          }
        } else {
          ToastHelper.error(errorMessage);
        }
        removeLoadingTab(worksheetId);
      })
      .catch((e) => {
        ToastHelper.error(errorMessage, e);
        removeLoadingTab(worksheetId);
      });
    // eslint-disable-next-line
  };
  const checkWorkSheetName = (workSheetName: string) => {
    if (workSheetName.includes('Chart')) return workSheetName;
    let sheetName = 'Chart';
    const chartNumber = tabs.find((tab) => {
      return tab.name.includes('Chart');
    });
    const chartName = chartNumber?.length;
    if (chartName < 0) {
      sheetName = sheetName + ' ' + chartName + 1;
    }
    return sheetName;
  };
  const updateTreeCoverageAuto = (data: NS_API.I_RD_StandardStmt) => {
    initiateStates(REPORT_TYPE_OF_VISUALISATIONS.COVERAGE_AUTO);
    addLoadingTab(worksheetId);
    updateReportByData(data);
    clearHistory(data.statement[0].children);
    const analyticsObj = {
      event_label: 'report_update',
      reportId: data._id,
      datasourceId: data.datasourceId,
      templateId: data.templateId,
      templateName: data.templateName,
      workspaceId: workspaceId,
      graphId: data.graphId,
      colValueDimension: JSON.stringify(data.colValueDimension),
      cumulDisplay: data.cumulDisplay,
      isUnion: data.isUnion,
    };
    // Add datasourceName if it isn't a union file
    if (!analyticsObj.isUnion) {
      analyticsObj['datasourceName'] = atob(data.datasourceId);
    }
    window.gtag('event', 'report_update', analyticsObj);
    removeLoadingTab(worksheetId);
  };

  const saveMetadata = () => {
    updateReportMetadata(workspaceId, worksheetId, currentMetadata)
      .then((res) => {
        if (res.data.message === 'SUCCESS') {
          ToastHelper.success(`Style saved`);
        }
      })
      .catch((e) => {
        ToastHelper.error(`Failed to save metadata`, e);
      });
  };

  // update currency without reloading report
  const updateCurrency = (cumul = false, overwrite = false) => {
    let currencyType: string;
    if (currencyParams.displayUnit === 1) {
      currencyType = 'DEFAULT';
    } else if (currencyParams.displayUnit === 1000) {
      currencyType = 'k';
    } else {
      currencyType = 'M';
    }
    let decimalSeparator = currencyParams.format.split('.')[1]?.length;
    if (!decimalSeparator) {
      decimalSeparator = 0;
    }
    setIsEditing(false);
    let islocalCurrency;
    let isLocalDecimal;
    if (currencyParams.displayUnit !== preferredNumbers.currencyParams.displayUnit) {
      islocalCurrency = false;
    } else {
      islocalCurrency = true;
    }

    if (currencyParams.format !== preferredNumbers.currencyParams.format) {
      isLocalDecimal = false;
    } else {
      isLocalDecimal = true;
    }

    const dataSourceIndex = dataSources.findIndex((ds) => ds.fileId === selectedDataSource);
    const isUnion = !!dataSources[dataSourceIndex].unionInfo;
    updateReport(workspaceId, {
      columnDimensions: columnsDimensions,
      graphId: selectedDataSourceGraph,
      datasourceId: selectedDataSource,
      templateKey: selectedTemplate,
      yearOrMonth: dateRender[selectedDateRender],
      cumulDisplay: cumul,
      worksheetId: worksheetId,
      monetaryUnit: islocalCurrency ? undefined : currencyType,
      decimalSeparator: isLocalDecimal ? undefined : decimalSeparator,
      overwrite: overwrite,
      isUnion,
      monthGroupedBy: monthGroupedBy,
    })
      .then((res) => {
        if (res) {
          const { data } = res;
          setAlerts(data.alerts ?? []);
          setCumulDisplay(data.cumulDisplay);
          setOrinalCumulDisplay(data.cumulDisplay);
          setStopCumul(data.stopCumulIndexes);
        } else {
          ToastHelper.error(errorMessage);
        }
      })
      .catch((e) => {
        ToastHelper.error(errorMessage, e);
      });
    // eslint-disable-next-line
  };

  // LayoutEffect

  useLayoutEffect(() => {
    function updateSize() {
      if (reportContainerRef.current) {
        const heightAvailable = reportContainerRef.current.clientHeight - 35;
        setTableBodyHeight(heightAvailable);
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [reportContainerRef]);

  // Effects

  useEffect(() => {
    if (currencyParams.displayUnit !== preferredNumbers.currencyParams.displayUnit) {
      setIsGlobalCurrency(false);
    } else {
      setIsGlobalCurrency(true);
    }

    if (currencyParams.format !== preferredNumbers.currencyParams.format) {
      setIsGlobalDecimal(false);
    } else {
      setIsGlobalDecimal(true);
    }
  }, [currencyParams.displayUnit, currencyParams.format]);

  useEffect(() => {
    if (tabDisplay !== tabMode.LAYOUT) {
      setNodeIdClicked('');
    }
  }, [tabDisplay]);

  // Close history/Chart/Properties panel when open a dataVisualisation
  useEffect(() => {
    if (typeOfVisualisation !== '') {
      setHistoryOpen(false);
      setIsGraphOpen(false);
      setIsPropertiesOpen(false);
      if (cellSelected.length === 0) {
        setAccountsListIds(allAccountsListIds.concat(currentListOfAccountIdsAddedFromCoverage));
        setAccountsListNames(allAccountsListNames.concat(currentListOfAccountNamesAddedFromCoverage));
      } else {
        setParentCellsInDataViz(cellSelected);
        updateAccountsList(cellSelected);
      }
    }
  }, [typeOfVisualisation]);

  useLayoutEffect(() => {
    if (isGraphOpen) {
      setTypeOfVisualisation('');
      setIsPropertiesOpen(false);
      setHistoryOpen(false);
    }
  }, [isGraphOpen]);

  useLayoutEffect(() => {
    if (isHistoryOpen) {
      setIsGraphOpen(false);
      setIsPropertiesOpen(false);
      setTypeOfVisualisation('');
    }
  }, [isHistoryOpen]);

  useLayoutEffect(() => {
    if (isPropertiesOpen) {
      setIsGraphOpen(false);
      setTypeOfVisualisation('');
      setHistoryOpen(false);
    }
  }, [isPropertiesOpen]);

  useEffect(() => {
    setDisplayingTree(currentTree);
    if (current !== undefined && mappingTreeWithRoot[0]) {
      const newMappingTreeWithRoot = {
        id: mappingTreeWithRoot[0].id,
        data: mappingTreeWithRoot[0].data,
        parent: mappingTreeWithRoot[0].parent,
        children: currentTree,
      };
      setMappingTreeWithRoot([newMappingTreeWithRoot]);
    }
  }, [currentTree]);

  useEffect(() => {
    let factor = 0;
    statementColumns.forEach((item) => {
      if (item.Header === 'value') {
        factor = parseInt(item.colParams.colDataType.format.split(',')[1]);
      }
    });
    const sumMath = selectedCells.reduce((sum, cell) => {
      if (typeof cell.value === 'number') {
        return sum + cell.value;
      } else {
        return sum;
      }
    }, 0);

    setSumInFooter({
      sum: toCurrency(currencyParams.format, factor === 0 ? sumMath : sumMath / Math.pow(10, factor)),
      count: selectedCells.length,
    });
    // eslint-disable-next-line
  }, [selectedCells]);

  useEffect(() => {
    openInputRef.current = openInput;
    const searchbarInput = document.getElementById('searchInput');
    if (searchbarInput) {
      searchbarInput.focus();
    }

    // Empty search value and search result when close search component
    if (!openInput) {
      setShowResult(-1);
      setSearchValue('');
      setCellFound('');
    }
  }, [openInput]);

  useEffect(() => {
    const handleMouseClickOutSideTable = (ev: MouseEvent) => {
      if (miningContainerRef.current && !(miningContainerRef.current as any).contains(ev.target)) {
        setNodeIdClicked('');
        nodeOpenedAccountRef.current.nodeId && setNodeOpenedAccount({ nodeId: '' });
      }
    };
    const escapeClick = (event) => {
      if (event.key === 'Escape') {
        setNodeIdClicked('');
        emptySelectedCells();
        nodeOpenedAccountRef.current.nodeId && setNodeOpenedAccount({ nodeId: '' });
        if (openInputRef.current) {
          setOpenInput(false);
        }
      }
    };
    const handleMouseClickOutSideSearchBar = (e: MouseEvent) => {
      if (searchBarRef.current && !(searchBarRef.current as any).contains(e.target)) {
        setOpenInput(false);
      }
    };
    const isCtrlAndF = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === 'f') {
        e.preventDefault();
        setOpenInput(!openInputRef.current);
      }
    };
    const isCtrlAndZ = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === 'z') {
        e.preventDefault();
        setUndo();
      }
    };
    const isCtrlAndY = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === 'y') {
        e.preventDefault();
        setRedo();
      }
    };
    document.addEventListener('keydown', isCtrlAndF);
    document.addEventListener('keydown', isCtrlAndZ);
    document.addEventListener('keydown', isCtrlAndY);
    document.addEventListener('keydown', escapeClick);
    window.addEventListener('mouseup', handleMouseClickOutSideTable);
    window.addEventListener('mouseup', handleMouseClickOutSideSearchBar);
    return () => {
      document.removeEventListener('keydown', isCtrlAndF);
      document.removeEventListener('keydown', isCtrlAndZ);
      document.removeEventListener('keydown', isCtrlAndY);
      document.removeEventListener('keydown', escapeClick);
      window.removeEventListener('mouseup', handleMouseClickOutSideSearchBar);
      window.removeEventListener('mouseup', handleMouseClickOutSideTable);
      setSumInFooter({ sum: 0, count: 0 });
    };
  }, []);

  useEffect(() => {
    if (columnsDimensions.findIndex((dim) => dim.includes('/')) > -1) {
      setMultipleDimensions(true);
    } else {
      setMultipleDimensions(false);
    }
  }, [columnsDimensions]);

  useEffect(() => {
    if (searchValue !== '' && treeStatementData && treeStatementData[0]) {
      const result = TreeHelper.searchTreeNodeIdsByKeyword(treeStatementData[0], searchValue);
      setSearchResults(result);
      if (result.length > 0) {
        setShowResult(0);
      } else {
        setShowResult(-1);
      }
    } else {
      setShowResult(-1);
      setSearchResults([]);
    }
  }, [searchValue, treeStatementData]);

  useEffect(() => {
    // When there is already a tree in undo/redo context, do not update current tree,
    // but resotre the tree from context (by using clearHisotry from ReportContext)!
    if (treeStatementData?.[0] && current === undefined) {
      setMappingTreeWithRoot(treeStatementData);
      initiateHistory(treeStatementData[0].children);
    }
  }, [treeStatementData]);

  useEffect(() => {
    let selectedReportNodeId = '';
    if (nodeIdClicked) {
      selectedReportNodeId = nodeIdClicked;
    } else if (treeStatementData?.[0]?.id) {
      selectedReportNodeId = treeStatementData[0].id;
    }
    if (selectedReportNodeId !== '' && typeOfVisualisation !== 'Coverage') {
      getNodeAccounts(treeStatementData[0], selectedReportNodeId)
        .then((resp) => {
          const names: any[] = [];
          const ids: any[] = [];
          resp.forEach((element) => {
            ids.push(element[0]);
            names.push(element[1]);
          });
          currentListOfAccountIdsAddedFromCoverage?.forEach((id, index) => {
            ids.push(id);
            names.push(currentListOfAccountNamesAddedFromCoverage[index]);
          });
          setAllAccountsListIds(ids);
          setAllAccountsListNames(names);
          setAccountsListIds(ids);
          setAccountsListNames(names);
        })
        .catch((err) => {});
    }
  }, [nodeIdClicked, treeStatementData]);

  useEffect(() => {
    if (typeOfVisualisation === 'Coverage') {
      if (cellSelected.length > 0) {
        getNodeAccountsArr(treeStatementData[0], cellSelected)
          .then((resp) => concatNodeAccountsWithCoverage(resp))
          .catch((err) => {});
      } else {
        setAccountsListIds(allAccountsListIds.concat(currentListOfAccountIdsAddedFromCoverage));
        setAccountsListNames(allAccountsListNames.concat(currentListOfAccountNamesAddedFromCoverage));
      }
    }
  }, [cellSelected, current]);

  useEffect(() => {
    if (typeOfVisualisation === 'Accounts' && parentCellsInDataViz.length) {
      updateAccountsList(parentCellsInDataViz);
    }
  }, [current]);

  useEffect(() => {
    if (rowClicked === -1 || typeOfVisualisation === '') {
      let selectedReportNodeId: string[] = [];
      if (treeStatementData?.[0]?.id) {
        selectedReportNodeId = [treeStatementData[0].id];
      }
      if (selectedReportNodeId.length > 0 && typeOfVisualisation !== 'Coverage') {
        getNodeAccountsArr(treeStatementData[0], selectedReportNodeId)
          .then((resp) => concatNodeAccountsWithCoverage(resp))
          .catch((err) => {});
      }
    }
  }, [currentListOfAccountIdsAddedFromCoverage?.length]);

  useEffect(() => {
    nodeOpenedAccountRef.current = nodeOpenedAccount;
  }, [nodeOpenedAccount]);

  useEffect(() => {
    if (nodeIdClicked && nodeIdClicked !== nodeOpenedAccountRef.current.nodeId) {
      nodeOpenedAccountRef.current.nodeId && setNodeOpenedAccount({ nodeId: '' });
    }
  }, [nodeIdClicked]);

  // Gets analytic params when workspaceId or selectedDataSource changes
  useEffect(() => {
    if (selectedDataSource) {
      getScorfColumns(workspaceId, selectedDataSource)
        .then((res) => {
          const { data } = res;
          setAnalyticParams(data);
        })
        .catch((err) => {});
      const selectedDS = dataSources.filter((ds) => ds.fileId === selectedDataSource)[0];
      if (selectedDS.unionInfo) {
        setIsUnion(true);
      } else {
        setIsUnion(false);
      }
    }
  }, [workspaceId, selectedDataSource]);

  // Check if there is a report associated to a worksheet or not. If yes, display it
  useEffect(() => {
    const reportData = tabs.find((tab) => {
      return tab.id === worksheetId;
    });

    if (reportData?.name) {
      setReportName(reportData.name);
    }

    initiateStates();
    setInitialSelectedDataSrc('');
    setSelectedDataSourceGraph('');
    setSelectedDataSource('');
    setSelectedTemplate('');
    setTabDisplay(tabMode.MAIN);
    setIsReportExist(false);

    if (reportData?.reportSaved && !loadingTabs.includes(worksheetId)) {
      if (reportData.reportSaved) {
        addLoadingTab(worksheetId);

        getReport(workspaceId, reportData.id)
          .then((res) => {
            if (res) {
              const data = res.data.report;
              updateReportByData(data);
              setAlerts(data.alerts ?? []);
            } else {
              ToastHelper.error(errorMessage);
            }
            removeLoadingTab(worksheetId);
          })
          .catch((e) => {
            removeLoadingTab(worksheetId);
            if (e.response.data.message !== 'Record not found') {
              ToastHelper.warn(errorMessage, e);
            }
          });
      } else {
        setIsReportExist(false);
        setSelectedDataSource('');
        setSelectedTemplate('');
      }
    }
    // eslint-disable-next-line
  }, [worksheetId]);

  // Get report statement
  useEffect(() => {
    // if loading or nothing selected, do nothing
    if (selectedTemplate === '' || selectedDataSource === '' || loadingTabs.includes(worksheetId)) {
      return;
    }
    // check data Source
    if (selectedTemplate && selectedDataSource) {
      const dataSource = dataSources.find((d) => d?.fileId && d.fileId === selectedDataSource);
      if (!dataSource?.isCardValidated) {
        setIsDataSourceValidated(false);
        return;
      }
    }
    // if datasource changes and one is already selected, reset templates and states
    if (selectedDataSource !== initialSelectedDataSrc && initialSelectedDataSrc !== '') {
      setInitialSelectedDataSrc('');
      setInitialSelectedTemp('');
      setSelectedTemplate('');
      return;
    }
    // if no report is created and template changes, create report
    if (selectedDataSource !== '' && selectedTemplate !== '' && !isReportExist) {
      setInitialSelectedTemp(selectedTemplate);
      setInitialSelectedDataSrc(selectedDataSource);
      createReports();
    } // if report exists and template changes, ask to update report
    else if (
      (initialSelectedTemp !== selectedTemplate ||
        (initialSelectedTemp === selectedTemplate && selectedDataSourceGraph !== initialDSGraph) ||
        initialSelectedDataSrc !== selectedDataSource) &&
      selectedTemplate !== '' &&
      isReportExist &&
      !justSaveAs
    ) {
      setOpenNewWorksheetModal(true);
    } else {
      setJustSaveAs(false);
    }
    // eslint-disable-next-line
  }, [selectedTemplate, selectedDataSource, selectedDataSourceGraph]);

  // Regenerate report when cumul display is changed
  useEffect(() => {
    if (!isEditing) {
      return;
    }
    if (
      selectedTemplate !== '' &&
      selectedDataSource !== '' &&
      !current &&
      selectedDateRender === originalSelectedDateRender
    ) {
      setInitialSelectedTemp(selectedTemplate);
      setInitialSelectedDataSrc(selectedDataSource);
      updateReports();
    } else if (current) {
      setOpenDiscardModal(true);
    }
    // eslint-disable-next-line
  }, [selectedDataSourceGraph]);

  // Regenerate report when dimensions options change
  useEffect(() => {
    if (selectedTemplate !== '' && selectedDataSource !== '' && !current) {
      updateReports();
    } else if (current) {
      setOpenDiscardModal(true);
    }
  }, [dimensionsChange]);

  useEffect(() => {
    if (!isEditing) {
      return;
    }
    if (selectedTemplate !== '' && selectedDataSource !== '' && columnsDimensions.length > 0) {
      updateCurrency(cumulDisplay);
    }
  }, [currencyParams.displayUnit, discard, currencyParams.format]);

  // If report is outdated, update it
  useEffect(() => {
    if (outDated) {
      updateReports();
    }
  }, [outDated]);

  useEffect(() => {
    selectedCellsColumnsRef.current = selectedCellsColumns;
    selectedCellsRowNodesRef.current = selectedCellsRowNodes;
    selectedCellsOutGroupRef.current = selectedCellsOutGroup;
  }, [selectedCellsColumns, selectedCellsOutGroupRef, selectedCellsRowNodesRef]);

  useEffect(() => {
    setSelectedHeaders([]);
    if (openKpi) {
      setHeaderSelectionMode(true);
    } else {
      setHeaderSelectionMode(false);
    }
  }, [openKpi]);

  // Functions
  // Click on discard then update Report
  const handleDiscard = () => {
    updateReports();
  };

  const handleCancel = useCallback(() => {
    setOpenDiscardModal(false);
    setSelectedDateRender(originalSelectedDateRender);
    setMonthGroupedBy(originalMonthGroupedBy);
    setColumnsDimensions(originalColumnsDimensions);
    setCumulDisplay(originalCumulDisplay);
    setIsEditing(false);
  }, [originalSelectedDateRender, originalMonthGroupedBy, originalColumnsDimensions, originalCumulDisplay]);

  const renderClickedId = () => {
    if (rowClicked > -1) {
      return accountsListIds[rowClicked];
    }
    return '';
  };

  const emptySelectedCells = useCallback(() => {
    if (selectedCellsColumnsRef.current && selectedCellsColumnsRef.current.length > 0) {
      setSelectedCellsColumns([]);
      setSelectedCellsRowNodes([]);
    }
    if (selectedCellsOutGroupRef.current && selectedCellsOutGroupRef.current.length > 0) {
      setSelectedCellsOutGroup([]);
    }
  }, []);

  const concatNodeAccountsWithCoverage = (nodes, setAll = false) => {
    const names: any[] = [];
    const ids: any[] = [];
    nodes.forEach((element) => {
      element.forEach((el) => {
        ids.push(el[0]);
        names.push(el[1]);
      });
    });
    currentListOfAccountIdsAddedFromCoverage?.forEach((id, index) => {
      ids.push(id);
      names.push(currentListOfAccountNamesAddedFromCoverage[index]);
    });
    setAccountsListIds(ids);
    setAccountsListNames(names);
    if (setAll) {
      setAllAccountsListIds(ids);
      setAllAccountsListNames(names);
    }
  };

  const updateAccountsList = useCallback(
    (parentNodes: string[]) => {
      const newAccountListId: string[] = [];
      const newAccountListName: string[] = [];
      const loopArrayToFindSelectedId = (child: NS_API.IGraph) => {
        if (child) {
          child.forEach((item) => {
            if (parentNodes.includes(item.data.content.rowContentId)) {
              if (item.data.content.rowContentType === 'N') {
                newAccountListId.push(item.data.content.rowContentId);
                newAccountListName.push(item.data.content.rowContentName);
              }
              if (item.data.content.rowContentType !== 'N' && item.children) {
                loopArrayToFindAllAccountsId(item.children);
              }
            } else {
              if (item.data.content.rowContentType !== 'N' && item.children) {
                loopArrayToFindSelectedId(item.children);
              }
            }
          });
        }
      };

      const loopArrayToFindAllAccountsId = (child: NS_API.IGraph) => {
        if (child) {
          child.forEach((item) => {
            if (item.data.content.rowContentType === 'N') {
              newAccountListId.push(item.data.content.rowContentId);
              newAccountListName.push(item.data.content.rowContentName);
            } else {
              if (item.data.content.rowContentType !== 'N' && item.children) {
                loopArrayToFindAllAccountsId(item.children);
              }
            }
          });
        }
      };
      loopArrayToFindSelectedId(currentTree);
      setAccountsListIds(newAccountListId);
      setAccountsListNames(newAccountListName);
    },
    [currentTree],
  );

  const handleMappingOpen = useCallback(
    (visualisationType: string) => (e) => {
      e.stopPropagation();
      setTypeOfVisualisation(visualisationType);
      updateAccountsList(cellSelected);
    },
    [cellSelected],
  );

  const handleOpenProperties = useCallback(
    (treeNode: NS_TREE.ITreeRoot<string>) => (_e) => {
      setPropertiesSettingNode(treeNode);
      setIsPropertiesOpen(true);
    },
    [],
  );

  const handleCloseProperties = useCallback(() => {
    setIsPropertiesOpen(false);
  }, []);

  const getTemplateList = useCallback((graphId: string | undefined) => {
    if (graphId) {
      setFetchingTemplateList(true);
      getGraph(workspaceId, graphId)
        .then((resp) => {
          if (resp) {
            setTemplateList(resp.data.graph.templateParams);
            setTemplateGroups(resp.data.graph.templateGroups);
            setFetchingTemplateList(false);
          }
        })
        .catch((err) => {});
    }
  }, []);

  const toggleSelectHeader = useCallback(
    (headerItem: NS_Table.IColumnHeader) => {
      const headerIndex = selectedHeaders.findIndex((item) => item.valueIndex === headerItem.valueIndex);
      if (headerSelectionMode && !headerItem.calculatedCol && headerIndex === -1) {
        setSelectedHeaders([...selectedHeaders, headerItem]);
      }
    },
    [selectedHeaders, headerSelectionMode],
  );

  const handleDeleteHeaderSelection = useCallback(() => {
    const newHeaders = [...selectedHeaders];
    newHeaders.pop();
    setSelectedHeaders(newHeaders);
  }, [selectedHeaders]);

  const newReportAssembly = (newWorksheetId: string, dataSourceIndex: any) => {
    const isUnion = !!dataSources[dataSourceIndex].unionInfo;
    return postReportAssembly(workspaceId, worksheetId, {
      graphId: selectedDataSourceGraph,
      datasourceId: selectedDataSource,
      templateKey: selectedTemplate,
      worksheetId: newWorksheetId,
      isUnion,
    })
      .then((res) => {
        if (res?.data?.reportAssemblyId) {
          const { reportAssemblyId, message, status, worksheetName } = res.data;
          editTypeTab(worksheetId, REPORT_TABS_TYPE.CHART_REPORT, checkWorkSheetName(worksheetName));
          dispatch(addNotification({ reportAssemblyId, status, message }));
          dispatch(changePollingTime(POLLING_DELAY.executed));
        } else {
          ToastHelper.error(errorChartMessage);
        }
        removeLoadingTab(worksheetId);
      })
      .catch((e) => {
        removeLoadingTab(worksheetId);
        ToastHelper.error(errorMessage, e);
      });
  };

  // Creates and opens a new worksheet
  const openNewWorksheet = useCallback(() => {
    initiateStates();

    const dataSourceIndex = dataSources.findIndex((datasource) => datasource.fileId === selectedDataSource);

    createWorksheet(workspaceId, 'Report')
      .then((res) => {
        if (res) {
          const { data } = res;
          const newWorksheetId = data.id;
          const newWorksheetName = data.name;
          addLoadingTab(newWorksheetId);
          newTab(newWorksheetId, newWorksheetName, newWorksheetId, undefined, undefined, true);
          navigate(`/${workspaceId}/reports/${newWorksheetId}`, { replace: true });
          const isChart = selectedTemplate === TemplateKey.CHR1;
          const isUnion = !!dataSources[dataSourceIndex].unionInfo;
          if (isChart) {
            newReportAssembly(newWorksheetId, dataSourceIndex).catch((err) => {});
            return;
          }
          createNewReport(workspaceId, {
            graphId: selectedDataSourceGraph,
            datasourceId: selectedDataSource,
            templateKey: selectedTemplate,
            worksheetId: newWorksheetId,
            isUnion,
          })
            .then((resp) => {
              if (resp) {
                const dataReport = resp.data;
                if (dataReport.status === 'FAILED') {
                  setSelectedTemplate('');
                  ToastHelper.error(`${dataReport.message}`);
                } else {
                  setAlerts(dataReport.alerts ?? []);
                  updateReportByData(dataReport);
                }
              } else {
                ToastHelper.error(errorMessage);
              }
              removeLoadingTab(newWorksheetId);
            })
            .catch((e) => {
              ToastHelper.error(errorMessage, e);
              removeLoadingTab(newWorksheetId);
            });
        } else {
          ToastHelper.error(errorMessage);
        }
      })
      .catch((e) => {
        ToastHelper.error(errorMessage, e);
      });
  }, [dataSources, selectedDataSource]);

  // Closes new worksheet modal
  const closeNewWorksheetModal = useCallback(() => {
    setOpenNewWorksheetModal(false);
    setSelectedDataSourceGraph(initialDSGraph);
    setSelectedTemplate(initialSelectedTemp);
    setIsEditing(false);
  }, [initialDSGraph, initialSelectedTemp]);

  // Save/Save as
  const saveTemplate = useCallback(
    (templateName?: string) => {
      if (
        selectedDataSourceGraph &&
        selectedTemplate &&
        (Object.keys(currentCommands).length || currentMetadata.length)
      ) {
        setSavingTree(true);
        const commandsToSend = {};
        if (templateName) {
          setJustSaveAs(true);
        }
        // Upload commands and the change of colors
        if (Object.keys(currentCommands).length) {
          // If there is command, then do not need to regerate report by calling updateReport()
          if (currentMetadata.length) {
            saveMetadata();
          }
          // type === collapse is used when there is a collapse or an expand.
          // It's in the command object for the undo/redo and update the tree but it's not accepted as a command for the back.
          // Because it's just a change of display
          let trueIndex = 0;
          Object.values(currentCommands).forEach((command: any) => {
            if (command.type !== 'collapse') {
              commandsToSend[trueIndex] = command;
              trueIndex++;
            }
          });
          if (Object.values(commandsToSend).length > 0) {
            const openedTreeNodeIds = TreeHelper.findOpenedTreeNodeIds(
              mappingTreeWithRoot[0] as NS_TREE.ITreeRoot<string>,
            );

            updateGraphWithCommandsList(
              workspaceId,
              selectedDataSourceGraph,
              selectedTemplate,
              commandsToSend,
              worksheetId !== '' ? worksheetId : 'mapping',
              templateName,
            )
              .then((res) => {
                setSavingTree(false);
                setTypeOfVisualisation('');

                if (res) {
                  const { data } = res;
                  getAllTemplateCollectionsForWorkspace(workspaceId)
                    .then((res) => {
                      if (res?.data) {
                        dispatch(setTemplateCollections(res.data));
                      }
                    })
                    .catch((err) => {});
                  setSaveResultsOpen(true);
                  setSaveResults(data);
                  if (data.updatedReport) {
                    const treeStatementDataWithFormulaCols = TreeHelper.loopTreeStatementToApplyParams(
                      data.updatedReport.statement,
                      data.updatedReport.nodesContent,
                      data.updatedReport.nodesContentIndices,
                      openedTreeNodeIds,
                    );
                    clearHistory(treeStatementDataWithFormulaCols[0].children);
                    setTreeStatementData(treeStatementDataWithFormulaCols);
                    setNodesContentIndices(data.updatedReport.nodesContentIndices);
                    setSelectedDataSourceGraph(data.updatedReport.graphId);
                    setInitialDSGraph(data.updatedReport.graphId);
                    setInitialSelectedDataSrc(data.updatedReport.datasourceId);
                    setInitialSelectedTemp(data.updatedReport.templateName);
                  } else {
                    setVersion(0);
                  }
                }
              })
              .catch((err) => {});
          } else {
            setSavingTree(false);
          }
        }
        // Update only colors and regenerate the report
        else if (currentMetadata.length) {
          saveMetadata();
          updateReports();
          setSavingTree(false);
        } else {
          setSavingTree(false);
        }
      }
    },
    [
      currentMetadata,
      selectedDataSourceGraph,
      selectedTemplate,
      currentCommands,
      workspaceId,
      worksheetId,
      mappingTreeWithRoot,
    ],
  );

  const confirmChangeTopValue = useCallback(() => {
    if (selectedTemplate !== '' && selectedDataSource !== '' && !current && topValue !== defaultTopValue) {
      updateReports();
    } else if (current) {
      setOpenDiscardModal(true);
    }
  }, [selectedTemplate, selectedDataSource, current, topValue, defaultTopValue]);

  const handleValidateKpi = (reportData: NS_API.I_RD_StandardStmt) => {
    updateReportByData(reportData);
    clearHistory(reportData.statement[0].children);
  };

  const toggleOpenReportSetting = useCallback(() => {
    if (!savingTree) setOpenTopValue(!openTopValue);
  }, []);

  const handleDimensionValidate = useCallback(
    (newDimensionsList: Array<string>, newYearOrMonth: string, newCumul: boolean, newMonthGroupedBy?: string) => {
      setColumnsDimensions(newDimensionsList);
      newYearOrMonth === 'year' ? setSelectedDateRender(0) : setSelectedDateRender(1);
      setCumulDisplay(newCumul);
      setMonthGroupedBy(newMonthGroupedBy);
      setDimensionsChange(!dimensionsChange);
    },
    [dimensionsChange],
  );

  const handleSaveProperties = (nodeRowContentId: string, properties: NS_API.INodeSettingProperties) => {
    onSaveParametersHelper(
      nodeRowContentId,
      displayingTree,
      currentCommands,
      setNewOperation,
      currentMetadata,
      properties,
    );
  };

  const handleSetAlert = useCallback((_alerts: Array<string>) => {
    setAlerts(_alerts);
  }, []);

  const handleDataSourceGraph = useCallback((dataSourcesGraph: string) => {
    setInitialDSGraph(dataSourcesGraph);
    setSelectedDataSourceGraph(dataSourcesGraph);
  }, []);

  const renderTab = () => {
    switch (tabDisplay) {
      case tabMode.MAIN:
        return (
          <MainMenu
            selectedDataSource={selectedDataSource}
            templateList={templateList}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            fetchingTemplateList={fetchingTemplateList}
            templateGroups={templateGroups}
            worksheetId={worksheetId}
            setSelectedDataSource={setSelectedDataSource}
            getTemplateList={getTemplateList}
            currencyParams={currencyParams}
            setCurrencyParams={setCurrencyParams}
            setIsEditing={setIsEditing}
            openInput={openInput}
            setOpenInput={setOpenInput}
            selectedDataSourceGraph={selectedDataSourceGraph}
            setSelectedDataSourceGraph={setSelectedDataSourceGraph}
            setGraphName={setGraphName}
            tableContainerRef={tableContainerRef}
            scrollPartRef={scrollPartRef}
            reportName={reportName}
            disabled={savingTree}
          />
        );
      case tabMode.LAYOUT:
        return (
          <LayoutMenu
            mappingTree={displayingTree}
            cellSelected={cellSelected}
            typeOfVisualisation={typeOfVisualisation}
            setTypeOfVisualisation={setTypeOfVisualisation}
            setCellSelected={setCellSelected}
            setHistoryOpen={setHistoryOpen}
            isUnion={isUnion}
            reportDisplayMode={reportDisplayMode}
            openDimensions={openDimensions}
            setOpenDimensions={setOpenDimensions}
            nodeOpenedAccount={nodeOpenedAccount}
            setNodeOpenedAccount={setNodeOpenedAccount}
            selectedFile={analyticParams}
            miningContainerRef={miningContainerRef}
            setOpenKpi={setOpenKpi}
            openKpi={openKpi}
            disabled={savingTree}
          />
        );
      default:
        return <></>;
    }
  };

  const handleConfirmModalWarning = useCallback(() => {
    navigate(`/${workspaceId}/dataSource`, {
      replace: true,
      state: {
        fileId: selectedDataSource,
      },
    });
  }, [workspaceId, selectedDataSource]);

  const handleHideNewWorksheetMOdal = () => {
    updateReports(true);
  };

  const handleCloseModalEdit = useCallback(() => {
    setSaveResultsOpen(false);
  }, []);

  const handleCloseTopValue = useCallback(() => {
    setOpenTopValue(false);
  }, []);

  const handleCloseKpi = useCallback(() => {
    setOpenKpi(false);
  }, []);
  return (
    <>
      {!isDataSourceValidated && (
        <CustomModalWarning
          description={DATA_SOURCE_MESSAGE.CONFIRM_DS_DESCRIPTION}
          confirmLabel={DATA_SOURCE_MESSAGE.CONFIRM_DS_LABEL}
          onConfirm={handleConfirmModalWarning}
        />
      )}
      <div
        className={`report ${!leftDisposition && 'reverse'}`}
        onClick={(_e) => {
          cellSelected.length > 0 && setCellSelected([]);
          nodeOpenedAccount.nodeId && setNodeOpenedAccount({ nodeId: '' });
          nameOfNodeSelected.accountId !== '' && setNameOfNodeSelected({ accountId: '', accountName: '' });
          setIsContextMenuOpen('');
        }}>
        {saveResultsOpen && saveResults && (
          <EditSaveResults closeModal={handleCloseModalEdit} results={saveResults} templateList={templateList} />
        )}
        {openNewWorksheetModal && (
          <CustomModal
            onConfirm={openNewWorksheet}
            onCancel={handleHideNewWorksheetMOdal}
            onClose={closeNewWorksheetModal}
            confirmLabel="Create a new worksheet"
            cancelLabel="Overwrite this report"
            title="Create a new worksheet"
            cancelBtnType={BtnClassType.Delete}>
            <h5 className="newWSModal_content">
              Do you want to overwrite this report or to open it in a new worksheet ?
            </h5>
          </CustomModal>
        )}
        {openDiscardModal && (
          <CustomModal
            onClose={handleCancel}
            onCancel={handleCancel}
            onConfirm={handleDiscard}
            title="Review Changes"
            confirmLabel="Discard"
            confirmBtnType={BtnClassType.Delete}>
            <p>You have made changes. Do you want to discard them ?</p>
          </CustomModal>
        )}
        <ReportBandeau
          tabDisplay={tabDisplay}
          setTabDisplay={setTabDisplay}
          saveTemplate={saveTemplate}
          renderTab={renderTab}
          setHistoryOpen={setHistoryOpen}
          isHistoryOpen={isHistoryOpen}
          graphName={graphName}
          disabledSave={!current || !graphName}
          alerts={alerts}
          disable={savingTree}
        />
        {openInput && (
          <SearchComponent
            hasCloseIcon={true}
            setOpenInput={setOpenInput}
            setSearchValue={setSearchValue}
            showResult={showResult}
            setShowResult={setShowResult}
            searchResults={searchResults}
            searchValue={searchValue}
            searchBarRef={searchBarRef}
          />
        )}
        {openDimensions && (
          <DimensionsComponent
            isUnion={isUnion}
            setOpenInput={setOpenDimensions}
            listDimension={analyticParams}
            listDimensionSelected={originalColumnsDimensions}
            selectedDateRender={originalSelectedDateRender}
            cumulDisplay={originalCumulDisplay}
            disabledGroupBy={false}
            disabledDimensions={
              reportDisplayMode === ReportDisplayMode.SALES_BY_CLIENT ||
              reportDisplayMode === ReportDisplayMode.PURCHASES_BY_PROVIDER
            }
            monthGroupedBy={originalMonthGroupedBy}
            onValidateChange={handleDimensionValidate}
          />
        )}
        {openTopValue && (
          <TopValueComponent
            selectedTemplate={selectedTemplate}
            topValue={topValue}
            setTopValue={setTopValue}
            defaultTopValue={defaultTopValue}
            onConfirmTopValue={confirmChangeTopValue}
            onClose={handleCloseTopValue}
          />
        )}
        {openKpi && (
          <KpiComponent
            onClose={handleCloseKpi}
            onValidate={handleValidateKpi}
            onDeleteCol={handleDeleteHeaderSelection}
            selectedCols={selectedHeaders}
            setSelectedCols={setSelectedHeaders}
            setColSelecting={setHeaderSelectionMode}
            colSelecting={headerSelectionMode}
          />
        )}
        <div className={`rightSideOverflow ${!leftDisposition && 'reverse'}`}>
          <div className={'centerMenu centerMenuExtend'} ref={reportContainerRef}>
            <div className="centerMenuBody" style={{ height: tableBodyHeight ?? 'auto' }}>
              {renderContent(loadingTabs.includes(worksheetId) || savingTree, <ProgressLoader />, null)}
              {loadingTabs.includes(worksheetId) && (
                <div className="reportSkeleton">
                  <div className="headerSkeleton"></div>
                  <div className="bodySkeleton">
                    <div className="leftPartSkeleton"></div>
                    <div className="rightPartSkeleton">
                      {Array(15)
                        .fill(0)
                        .map((_number, key) => {
                          const index = `${key}-`;
                          return (
                            <div className="lineSkeleton" key={index}>
                              <div className="columnNameSkeleton">
                                <div className="nameSkeleton"></div>
                              </div>
                              <div className="columnValueSkeleton">
                                <div className="valueSkeleton"></div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              )}
              {!isReportExist && !loadingTabs.includes(worksheetId) && (
                <div className="placeholderContainerExport">
                  <img src={emptyReport} alt="" />
                  <h3>Please configure your Report</h3>
                  <div className="textContainer">
                    <p>You need to choose which Data Source, Template Collection and Template to use in your Report.</p>
                  </div>
                </div>
              )}
              {!loadingTabs.includes(worksheetId) &&
                //Filter bar on the top page
                isReportExist &&
                treeStatementData.length > 0 && (
                  <div
                    style={{ transformOrigin: 'left top' }}
                    ref={scrollPartRef}
                    className={`reportContainer ${treeStatementData.length > 1 && 'reportContainerVertical'}`}>
                    {treeStatementData.map((tree, key) => {
                      return (
                        <EditStatement
                          worksheetId={worksheetId}
                          key={tree.id}
                          datasourceId={selectedDataSource}
                          zoomValue={zoomValue}
                          columnsDimensions={columnsDimensions}
                          columnsValueNames={columnsValueNames}
                          mappingTree={displayingTree}
                          mappingTreeWithRoot={mappingTreeWithRoot}
                          graphId={selectedDataSourceGraph}
                          cellSelected={cellSelected}
                          setCellSelected={setCellSelected}
                          header={statementColumns}
                          currencyParams={currencyParams}
                          isEditing={isEditing}
                          dateHeaderType={dateHeaderType}
                          fiscalYears={fiscalYears}
                          dateRender={dateRender}
                          selectedDateRender={selectedDateRender}
                          setNameOfNodeSelected={setNameOfNodeSelected}
                          setTypeOfVisualisation={setTypeOfVisualisation}
                          clickedId={renderClickedId()}
                          handleMappingOpen={handleMappingOpen}
                          isContextMenuOpen={isContextMenuOpen}
                          setIsContextMenuOpen={setIsContextMenuOpen}
                          multipleDimensions={multipleDimensions}
                          nameColWidth={nameColWidth}
                          setNameColWidth={setNameColWidth}
                          tableBodyHeight={tableBodyHeight}
                          nodeOpenedAccount={nodeOpenedAccount}
                          setNodeOpenedAccount={setNodeOpenedAccount}
                          setSelectedCells={setSelectedCells}
                          selectedCells={selectedCells}
                          searchResults={searchResults}
                          showResult={showResult}
                          scrollPartRef={scrollPartRef}
                          selectedTemplate={selectedTemplate}
                          displayOpeningColumn={displayOpeningColumn}
                          selectedCellsRowNodes={selectedCellsRowNodes}
                          setSelectedCellsRowNodes={setSelectedCellsRowNodes}
                          selectedCellsColumns={selectedCellsColumns}
                          setSelectedCellsColumns={setSelectedCellsColumns}
                          selectedCellsInGroup={selectedCellsInGroup}
                          setSelectedCellsInGroup={setSelectedCellsInGroup}
                          selectedCellsOutGroup={selectedCellsOutGroup}
                          setSelectedCellsOutGroup={setSelectedCellsOutGroup}
                          emptySelectedCells={emptySelectedCells}
                          setAccountSelectedInReport={setAccountSelectedInReport}
                          setCellFound={setCellFound}
                          cellFound={cellFound}
                          tableContainerRef={tableContainerRef}
                          reportDisplayMode={reportDisplayMode}
                          onClickHeader={toggleSelectHeader}
                          headerSelectionMode={headerSelectionMode}
                          selectedHeaders={selectedHeaders}
                          nodesContentIndices={nodesContentIndices}
                          reportDataSourceGraph={initialDSGraph}
                          onReportSettingClick={toggleOpenReportSetting}
                          monthGroupedBy={originalMonthGroupedBy}
                          handlePropertiesOpen={handleOpenProperties}
                          disabled={savingTree}
                          templateKey={initialSelectedTemp}
                          selectedDataSourceGraph={selectedDataSourceGraph}
                          handleDataSourceGraph={handleDataSourceGraph}
                        />
                      );
                    })}
                  </div>
                )}
            </div>
          </div>
          {typeOfVisualisation !== '' && (
            <DataVisualisationContainer
              accountIds={accountsListIds}
              accountNames={accountsListNames}
              rowClicked={rowClicked}
              setRowClicked={setRowClicked}
              typeOfVisualisation={typeOfVisualisation}
              setTypeOfVisualisation={setTypeOfVisualisation}
              selectedDataSource={getSelectedDatasource(selectedDataSource)}
              nameOfNodeSelected={nameOfNodeSelected}
              setNameOfNodeSelected={setNameOfNodeSelected}
              mappingTree={displayingTree}
              graphId={selectedDataSourceGraph}
              datasourceId={selectedDataSource}
              reportName={reportName}
              savingTree={savingTree}
              saveTemplate={saveTemplate}
              dataVisualisationContainerRef={dataVisualisationContainerRef}
              cellSelected={cellSelected}
              accountSelectedInReport={accountSelectedInReport}
              setAccountSelectedInReport={setAccountSelectedInReport}
              updateTreeCoverageAuto={updateTreeCoverageAuto}
              isUnion={isUnion}
              templateType={initialSelectedTemp}
              handleSetAlert={handleSetAlert}
            />
          )}
          {isGraphOpen && (
            <ChartVisualisationContainer
              setIsGraphOpen={setIsGraphOpen}
              reportName={reportName}
              columnsValueNames={columnsValueNames}
              cumulDisplay={cumulDisplay}
              selectedTemplate={selectedTemplate}
              isYear={dateRender[selectedDateRender] === 'year'}
            />
          )}
          {isPropertiesOpen && (
            <PropertiesContainer
              onClose={handleCloseProperties}
              reportName={reportName}
              node={propertiesSettingNode}
              onSubmit={handleSaveProperties}
            />
          )}
          {isHistoryOpen && typeOfVisualisation === '' && <History setOpen={setHistoryOpen} reportName={reportName} />}
        </div>
        <Footer
          workspaceName={name + ' workspace'}
          leftDisposition={leftDisposition}
          setLeftDisposition={setLeftDisposition}
          needZoom={true}
          zoomValue={zoomValue}
          setZoomValue={setZoomValue}
          needReverse={true}
        />
      </div>
      <NavigationPrompt />
    </>
  );
};

export default Reports;
