import { service, serviceBatchExport, serviceExport } from '../..';
import { IReportsToExport } from '../../modals/ExportSettings';

// GET
export const downloadExportedFile = async (workspaceId: string, fileName: string) => {
  return service.request({
    method: 'GET',
    baseURL: process.env.REACT_APP_AZURE_FUNCTION_EXPORT,
    url: `/blob-access/download/${workspaceId}?fileName=${fileName}`,
    responseType: 'blob',
    headers: {},
  });
};

// DELETE
export const deleteExportedFile = async (workspaceId: string, fileName: string) => {
  return service.request({
    method: 'DELETE',
    baseURL: process.env.REACT_APP_AZURE_FUNCTION_EXPORT,
    url: `/blob-access/${workspaceId}?fileName=${fileName}`,
    headers: {},
  });
};

export const generateQandAExport = async (
  workspaceId: string,
  downloadedFileName: string,
  errorCallback: (error: any) => void,
): Promise<void> => {
  return serviceExport
    .request({
      method: 'POST',
      url: `excel/qanda/${workspaceId}`,
      responseType: 'blob', // important
      headers: {},
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${downloadedFileName}.xlsx`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      errorCallback(err);
    });
};

export enum EEXPORT {
  EXPORT_MAPPING = 'EXPORT_MAPPING',
  EXPORT_REPORT = 'EXPORT_REPORT',
  EXPORT_DATASOURCE = 'EXPORT_DATASOURCE',
}

export const startExportBatch = async (
  name: string,
  workspaceId: string,
  reportsToExport: IReportsToExport[] | undefined,
  workspaceName: string,
  exportAction: EEXPORT,
  datasourceIds: string[] | undefined,
) => {
  return serviceExport.request({
    method: 'POST',
    url: `excel/${workspaceId}/generate`,
    headers: {},
    data: {
      exportAction,
      name,
      reportsToExport,
      workspaceName,
      datasourceIds,
    },
  });
};

export const getBatchStatus = async (containerName: string, workspaceId: string) => {
  return serviceBatchExport.request({
    method: 'GET',
    url: `batch/${workspaceId}/status/${containerName}`,
    headers: {},
  });
};

export const startExportMappings = async (workspaceId: string, datasourceId: string, graphId: string) => {
  return serviceExport.request({
    method: 'POST',
    url: `excel/${workspaceId}/mapping`,
    data: { datasourceId, graphId },
  });
};

export type TExportReports = {
  name: string;
  reportsToExport: Array<IReportsToExport>;
};

export const startExportReports = async (workspaceId: string, data: TExportReports) => {
  return serviceExport.request({
    method: 'POST',
    url: `excel/${workspaceId}/report`,
    data,
  });
};
