// Style
import './style.scss';

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

// Context
import { WorkspaceContext } from '../../context/WorkspaceContext';
import CustomModal, { BtnClassType } from '../../elements/CustomModal';
import * as DataSourceAPI from '../../shared/api/dataSource';
import { states } from '../../shared/constant/datasource.consts';
import { renderContent } from '../../shared/helpers/helpers';
import ToastHelper from '../../shared/helpers/toast/ToastHelper';

interface IDeleteDataSourceProps {
  datasourceIds: Array<string>;
  onClose: () => void;
  onCancel: () => void;
  setDatasourceToState: (fileId: string, structurationStatus: states, fields?: object) => void;
}

/**
 * Delete workspace confirmation modal component
 *
 * @param params IDeleteDataSourcesProps params
 */

const DeleteDataSourceModal: React.FC<IDeleteDataSourceProps> = ({
  datasourceIds,
  onClose,
  onCancel,
  setDatasourceToState,
}) => {
  // Context
  const { deleteDataSources, workspaceId, importedDataSources } = useContext(WorkspaceContext);

  // State
  const [reportsId, setReportsId] = useState<Array<{ name: string; id: string }>>([]);
  const [reportsOfDataSources, setReportsOfDataSources] = useState<Array<string>>([]);
  const [fetching, setFectching] = useState<boolean>(false);
  const [containUnionFile, setContainUnionFile] = useState<string[]>([]);
  const [point, setPoint] = useState('.');
  //functions
  const deleteDataSource = useCallback(() => {
    changeDSState(states.DELETING);
    deleteDataSources(workspaceId, datasourceIds, reportsId, () => changeDSState(states.PROCESSING));
    onClose();
  }, [workspaceId, datasourceIds, reportsId]);

  const changeDSState = (state: states) => {
    datasourceIds.forEach((fileId) => {
      setDatasourceToState(fileId, state);
    });
  };

  useEffect(() => {
    let reportsListOfDatasource: Array<string> = reportsOfDataSources;
    const unionInDs = importedDataSources.filter((ds) => ds.unionInfo && ds.unionInfo !== 'initialized');

    if (unionInDs.length > 0 && datasourceIds.length > 0) {
      const mergedFileImpacted = unionInDs.filter(
        (ds) =>
          ds.unionInfo &&
          JSON.parse(ds.unionInfo).some((dsPartOfUnion) => datasourceIds.includes(dsPartOfUnion.datasourceId)),
      );
      setContainUnionFile(mergedFileImpacted.map((mergedFile) => mergedFile.displayName));
      reportsListOfDatasource = [
        ...reportsListOfDatasource,
        ...mergedFileImpacted.map((mergedFile) => mergedFile.fileId),
      ];
    }

    datasourceIds.forEach((selectedDataSource) => {
      if (
        importedDataSources.filter((ds) => ds.fileId === selectedDataSource)[0].structurationStatus === states.GENERATED
      ) {
        reportsListOfDatasource.push(selectedDataSource); // store data source that are already generated to ask for their reports
      }
    });

    reportsListOfDatasource = [...new Set(reportsListOfDatasource)];
    setReportsOfDataSources(reportsListOfDatasource);

    if (reportsListOfDatasource.length > 0) {
      setFectching(true);
      DataSourceAPI.getDatasourceDeletionEffects(workspaceId, reportsListOfDatasource)
        .then((resp) => {
          if (resp) {
            const { data } = resp;
            const reportIds: Array<any> = [];
            data.forEach((ds) => {
              ds.worksheets.forEach((report) => reportIds.push({ id: report.id, name: report.name }));
            });
            setReportsId(reportIds);
            setFectching(false);
          }
        })
        .catch((e) => {
          ToastHelper.error(`Could not get Report by data sources Id`, e);
          setFectching(false);
        });
    }
  }, []);

  const startPointAnimation = () => {
    return setInterval(() => {
      if (point.length === 3) {
        setPoint('.');
      } else {
        setPoint(point + '.');
      }
    }, 1000);
  };

  const stopPointNotification = (interval: any) => {
    clearInterval(interval);
  };

  const renderWording = useMemo(() => {
    if (reportsId.length > 0) {
      return reportsId.map((report, index) => `${report.name} ${index + 1 === reportsId.length ? '.' : ', '}`);
    } else {
      let interval: any = null;
      if (fetching) {
        interval = startPointAnimation();
        return 'looking for associated reports' + point;
      } else {
        stopPointNotification(interval);
        return 'no reports';
      }
    }
  }, [reportsId, fetching, point]);

  const renderModalTitle = useMemo(() => {
    if (datasourceIds.length > 1) {
      return 'Delete Data sources';
    } else {
      return 'Delete Data source';
    }
  }, [datasourceIds.length]);

  return (
    <CustomModal
      title={renderModalTitle}
      onCancel={onCancel}
      onClose={onClose}
      onConfirm={deleteDataSource}
      confirmLabel="Delete Data Source"
      confirmBtnType={BtnClassType.Delete}
      confirmDisabled={fetching}
      className="deleteDatasourceModal">
      <p>
        {`Are you sure you want to delete ${renderContent(
          datasourceIds.length > 1,
          `these Data sources`,
          `this Data source`,
        )}?`}
      </p>
      {containUnionFile.length > 0 && (
        <p>
          {`It was used to create a union data source which will also be deleted : `}
          <span>
            {containUnionFile.map((name, index) => `${name} ${index + 1 === containUnionFile.length ? '.' : ', '}`)}
          </span>
        </p>
      )}

      {reportsOfDataSources.length > 0 && (
        <p>
          {`Deleting these data sources will result in the deletion of the following reports : `}
          <span>{renderWording}</span>
        </p>
      )}
    </CustomModal>
  );
};

export default DeleteDataSourceModal;
