import { ReactSVG } from 'react-svg';

import CheckSVG from '../../assets/img/check-halfcircle.svg';
import DownloadSVG from '../../assets/img/download-underline.svg';
import FileSVG from '../../assets/img/file.svg';
import HeadphoneSVG from '../../assets/img/headphone.svg';
import NewsSVG from '../../assets/img/news.svg';
import OptionSVG from '../../assets/img/option.svg';
import UserSVG from '../../assets/img/user.svg';
import { RoutesHash } from './route.consts';

// Uncomment tabs when they are ready
export const Tabs = [
  {
    name: 'Export',
    hash: RoutesHash.export,
  },
  {
    name: 'Users',
    hash: RoutesHash.users,
  },
  /* {
    name: 'Integrity Checks',
    hash: '#integrity',
  }, */
  {
    name: 'Options',
    hash: RoutesHash.options,
  },
  {
    name: 'Support',
    hash: RoutesHash.support,
  },
  {
    name: 'Template Collection',
    hash: RoutesHash.templateCol,
  },
  // {
  //   name: 'News',
  //   hash: RoutesHash.news,
  // },
  // {
  //   name: 'Documentation',
  //   hash: '#doc',
  // },
];

export const Svgs = [
  <ReactSVG src={DownloadSVG} className="svg-wrapper" key={`1-download`} />,
  <ReactSVG src={UserSVG} className="svg-wrapper" key={`2-user`} />,
  // <ReactSVG src={QuoteSVG} className="svg-wrapper" />,
  <ReactSVG src={CheckSVG} className="svg-wrapper" key={`3-check`} />,
  <ReactSVG src={OptionSVG} className="svg-wrapper" key={`4-option`} />,
  <ReactSVG src={HeadphoneSVG} className="svg-wrapper" key={`5-phone`} />,
  <ReactSVG src={FileSVG} className="svg-wrapper" key={`6-file`} />,
  <ReactSVG src={NewsSVG} className="svg-wrapper" key={`7-news`} />,
];
