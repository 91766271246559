// Style
import './style.scss';

import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
// SVG
import { ReactSVG } from 'react-svg';

import AddCircleSVG from '../../../assets/img/add-circle-white.svg';
import BackArrowSVG from '../../../assets/img/arrow-right-green.svg';
import CrossCircleSVG from '../../../assets/img/cross-circle-red.svg';
import RedCrossSVG from '../../../assets/img/cross-red.svg';
import moveImg from '../../../assets/img/move.png';
import RestoreSVG from '../../../assets/img/restore.svg';
import { ReportContext } from '../../../context/ReportContext';
import { WorkspaceContext } from '../../../context/WorkspaceContext';
import SearchComponent from '../../../elements/SearchComponent';
import { getPreviewLazy } from '../../../shared/api/dataSource';
import { getDatasourceCoverage } from '../../../shared/api/mapping';
import { deleteCols, normalizeDataRowsValues } from '../../../shared/helpers/datasource/datasource.helper';
import { findIntersection } from '../../../shared/helpers/report/reports.helper';
import ToastHelper from '../../../shared/helpers/toast/ToastHelper';
import { dataDestructuring } from '../../../shared/helpers/tree/front.tree.util';
import { REPORT_TYPE_OF_VISUALISATIONS } from '../../../utils/enum';
import AccountsList from './AccountsList';
import AccountsMapModule from './AccountsMapModule';
import AutoCoverageContainer from './AutoCoverageContainer';
import CoverageClasses from './CoverageClasses';
import DataVizMapping from './DataVizMapping';
import EntriesMapModule from './EntriesMapModule';
import MapModule from './MapModule';

export interface IColumnHeader {
  Header: string;
  InterpretedHeader: string;
  accessor: string;
  displayName: string;
  colParams: NS_Table.IColParams;
  scorfHeader: string;
  valueIndex: number | string | undefined;
  width?: number;
  ELSFilter: NS_Table.ELSFilterFields;
}

interface IDataVisualisationContainerProps {
  accountIds: string[];
  accountNames: string[];
  rowClicked: number;
  setRowClicked: React.Dispatch<React.SetStateAction<number>>;
  typeOfVisualisation: string;
  setTypeOfVisualisation: (val: string) => void;
  selectedDataSource: NS_Workspace.IDataSourcesFile;
  nameOfNodeSelected: NS_REPORT.INameSelectedForCoverageProps;
  setNameOfNodeSelected: (val: NS_REPORT.INameSelectedForCoverageProps) => void;
  mappingTree: NS_API.IGraph;
  graphId: string;
  datasourceId: string;
  reportName: string;
  savingTree: boolean;
  saveTemplate: () => void;
  dataVisualisationContainerRef: React.RefObject<HTMLDivElement>;
  cellSelected: string[];
  accountSelectedInReport: string;
  setAccountSelectedInReport: (val: string) => void;
  isUnion: boolean;
  templateType: string;
  updateTreeCoverageAuto: (data: NS_API.I_RD_StandardStmt) => void;
  handleSetAlert: (alert: Array<string>) => void;
}

interface ISortedAccount {
  id: string;
  name: string;
  originalIndex: number;
}

const sortFunction = (accountA, accountB) => {
  return parseInt(accountA.id) - parseInt(accountB.id);
};

/**
 * Container used to display a report
 *
 * @param param NS_Tabs.ITab props
 */

const DataVisualisationContainer: React.FC<IDataVisualisationContainerProps> = ({
  accountIds,
  accountNames,
  rowClicked,
  setRowClicked,
  typeOfVisualisation,
  setTypeOfVisualisation,
  selectedDataSource,
  nameOfNodeSelected,
  setNameOfNodeSelected,
  updateTreeCoverageAuto,
  mappingTree,
  graphId,
  datasourceId,
  reportName,
  savingTree,
  saveTemplate,
  dataVisualisationContainerRef,
  cellSelected,
  accountSelectedInReport,
  setAccountSelectedInReport,
  isUnion,
  templateType,
  handleSetAlert,
}) => {
  const { workspaceId, getLazyDataSourcesPreview, dataSourcesPreview, deleteDataSourcesPreview } =
    useContext(WorkspaceContext);
  const { currentAccountIdsWithMovedEntries } = useContext(ReportContext);
  const [sortedAccounts, setSortedAccounts] = useState<ISortedAccount[]>([]);
  const [accountSelected, setAccountSelected] = useState<string>('');
  const [classAccount, setClassAccount] = useState<NS_API.IDatasourceAccountCoverageDTO>();
  const [accountIdsTemp, setAccountIdsTemp] = useState<string[]>(accountIds);
  const [accountNamesTemp, setAccountNamesTemp] = useState<string[]>(accountNames);

  const [selectedDataSourceLocal, setSelectedDataSourceLocal] =
    useState<NS_Workspace.IDataSourcesFile>(selectedDataSource);
  const [loaded, setLoaded] = useState<boolean>(false);

  // -- Search
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [showResult, setShowResult] = useState<number>(-1);

  const [dataById, setDataById] = useState<Array<NS_API.IDSValue>>([]);
  const [dataFilteredByAccounts, setDataFilteredByAccounts] = useState<Array<NS_API.IDSValue>>([]);
  const [finalData, setFinalData] = useState<Array<NS_API.IDSValue>>([]);
  const [finalColumns, setFinalColumns] = useState<IColumnHeader[]>([]);

  // -- Coverage
  const [classSelected, setClassSelected] = useState<number>(0);
  const [accountSelectedForCoverage, setAccountSelectedForCoverage] = useState<
    Array<NS_REPORT.INameSelectedForCoverageProps>
  >([]);
  const [entriesSelectedForCoverage, setEntriesSelectedForCoverage] = useState<
    Array<NS_REPORT.IEntriesSelectedForCoverageProps>
  >([]);
  const [isMapOpen, setIsMapOpen] = useState<boolean>(false);
  const [isEntriesMapOpen, setIsEntriesMapOpen] = useState<boolean>(false);
  const [isAccountMapOpen, setIsAccountMapOpen] = useState<boolean>(false);

  //Entries
  const [selectedEntriesRows, setSelectedEntriesRows] = useState<Array<number>>([]);
  const [tableBodyHeight, setTableBodyHeight] = useState<number>(500);
  const [reset, setReset] = useState<boolean>(false);
  const [indexOfAccountId, setIndexOfAccountId] = useState<number>(-1);

  // LayoutEffect add useLayoutEffect to handle resize
  useLayoutEffect(() => {
    function updateSize() {
      const tableBodyH = window.innerHeight - 60 - 105 - 47 - 35 - 20 - 50 - 55; // give a dynamic height to the table Body, remove all fixed height in page
      setTableBodyHeight(tableBodyH);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => {
      window.removeEventListener('resize', updateSize);
      deleteDataSourcesPreview();
    };
  }, []);

  useEffect(() => {
    if (dataVisualisationContainerRef?.current) {
      dataVisualisationContainerRef.current.addEventListener('contextmenu', (e) => e.preventDefault());
    }
    return dataVisualisationContainerRef.current?.removeEventListener('contextmenu', (e) => e.preventDefault());
  }, []);

  useEffect(() => {
    if (typeOfVisualisation === REPORT_TYPE_OF_VISUALISATIONS.ENTRIES) {
      setClassSelected(0);
    }
  }, [typeOfVisualisation]);

  useEffect(() => {
    setIndexOfAccountId(finalColumns.findIndex((e) => e.scorfHeader === 'AccountID'));
  }, [finalColumns]);

  useEffect(() => {
    setSelectedDataSourceLocal(selectedDataSource);
  }, [selectedDataSource]);

  useEffect(() => {
    if (classSelected < 1 && !isAccountMapOpen && accountSelectedForCoverage.length === 0) {
      setAccountIdsTemp(accountIds);
    }
  }, [accountIds]);

  useEffect(() => {
    if (classSelected < 1 && !isAccountMapOpen && accountSelectedForCoverage.length === 0) {
      setAccountNamesTemp(accountNames);
    }
  }, [accountNames]);

  useEffect(() => {
    getDatasourceCoverage(workspaceId, selectedDataSource.fileId)
      .then((res) => {
        if (res) {
          setClassAccount(res.data.datasourceCoverage);
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    setIsEntriesMapOpen(false);
    setIsAccountMapOpen(false);
    setIsMapOpen(false);
  }, [typeOfVisualisation]);

  useEffect(() => {
    const groupedAccountInfos: ISortedAccount[] = [];
    accountIds.forEach((account, index) => {
      const tempAccountObj = {
        id: account,
        name: accountNames[index],
        originalIndex: index,
      };
      groupedAccountInfos.push(tempAccountObj);
    });
    groupedAccountInfos.sort((a, b) => sortFunction(a, b));
    setSortedAccounts(groupedAccountInfos);
  }, [accountIds, accountNames]);

  useEffect(() => {
    const tempSearchResult: string[] = [];
    for (const sortedAccount of sortedAccounts) {
      if (
        (sortedAccount.id.toLowerCase().includes(searchValue.toLowerCase()) ||
          sortedAccount.name.toLowerCase().includes(searchValue.toLowerCase())) &&
        searchValue !== ''
      ) {
        tempSearchResult.push(sortedAccount.name);
      }
    }
    setSearchResults(tempSearchResult);
  }, [searchValue]);

  useEffect(() => {
    if (searchResults.length === 0) {
      setAccountSelected('');
    } else {
      setAccountSelected(searchResults[showResult]);
    }
  }, [showResult, searchResults]);

  useEffect(() => {
    if (searchResults.length > 0) {
      setShowResult(0);
    } else {
      setAccountSelected('');
    }
  }, [searchResults]);

  useEffect(() => {
    if (!savingTree) {
      getData(true);
    }
  }, [savingTree, typeOfVisualisation]);

  useEffect(() => {
    if (dataSourcesPreview.data.length > 0 && dataSourcesPreview.columns.length > 0) {
      const normalizeData = normalizeDataRowsValues(dataSourcesPreview.data, dataSourcesPreview.columns);
      setDataById(normalizeData);
    }
  }, [dataSourcesPreview]);

  useEffect(() => {
    const columnsAccountIndex = dataSourcesPreview.columns.findIndex((columns) => columns.scorfHeader === 'AccountID');
    const columnsSplitIndex = dataSourcesPreview.columns.findIndex((columns) => columns.scorfHeader === '_SplitId');

    const tempDataById = dataById.filter((rowData) => {
      if (rowData[columnsAccountIndex]) {
        if (
          columnsSplitIndex !== -1 &&
          accountIds.findIndex((id) => id.split('-')[1]) > -1 &&
          rowData[columnsSplitIndex] !== ''
        ) {
          return (
            accountIds.findIndex((id) => id.split('-')[0] === rowData[columnsAccountIndex].toString().split('-')[0]) !==
              -1 && accountIds.findIndex((id) => id.split('-')[1] === rowData[columnsSplitIndex].toString()) !== -1
          );
        } else {
          return (
            accountIds.findIndex(
              (id) => id.split('-')[0] === rowData[columnsAccountIndex]?.toString().split('-')[0],
            ) !== -1
          );
        }
      }
      return false;
    });
    setDataFilteredByAccounts(tempDataById);
  }, [accountIds, dataById]);

  useEffect(() => {
    const colToRemove: number[] = [];
    dataSourcesPreview.columns.forEach((column, index) => {
      if (column.scorfHeader[0] === '_' && column.scorfHeader !== '_SplitId' && column.scorfHeader !== '_NumLine') {
        colToRemove.push(index);
      }
    });
    const tempArray = dataDestructuring(dataFilteredByAccounts);
    const tempCol = [...dataSourcesPreview.columns];
    const indexOfNumLine = dataSourcesPreview.columns.findIndex((e) => e.scorfHeader === '_NumLine');
    deleteCols(colToRemove, tempArray, tempCol);
    // To add the numLine at the end of the object, still we be invisible in dataviz or should be
    tempArray.forEach((tempLine, index) => {
      const nbOfKey = Object.keys(tempLine);
      tempLine[parseInt(nbOfKey[nbOfKey.length - 1]) + 1] = dataFilteredByAccounts[index][indexOfNumLine];
    });
    setFinalColumns(tempCol);
    setFinalData(tempArray);
    setLoaded(true);
  }, [dataFilteredByAccounts]);

  const getData = (firstCall: boolean) => {
    // Prevent entries view on union file
    if (isUnion && typeOfVisualisation === REPORT_TYPE_OF_VISUALISATIONS.ENTRIES) {
      ToastHelper.info('You can not visualize entries on union files');
      setTypeOfVisualisation('');
      return;
    }
    if (
      (selectedDataSourceLocal && selectedDataSourceLocal.continuationToken === '' && !firstCall) ||
      typeOfVisualisation === REPORT_TYPE_OF_VISUALISATIONS.ACCOUNTS ||
      typeOfVisualisation === REPORT_TYPE_OF_VISUALISATIONS.COVERAGE
    ) {
      return;
    }
    if (selectedDataSourceLocal) {
      getPreviewLazy(
        workspaceId,
        selectedDataSourceLocal.fileId,
        selectedDataSourceLocal.continuationToken === '' ? null : selectedDataSourceLocal.continuationToken,
        null,
      )
        .then((res) => {
          if (res) {
            const { data } = res;
            if (selectedDataSourceLocal) {
              setSelectedDataSourceLocal({ ...selectedDataSource, continuationToken: data.continuationToken ?? '' });
            }
            if (firstCall) {
              getLazyDataSourcesPreview(
                {
                  columns: data.data.columns,
                  data: data.data.data,
                  totalLength:
                    !data.continuationToken || data.continuationToken === '' ? data.data.data.length : data.totalLength,
                },
                false,
              );
            } else {
              getLazyDataSourcesPreview(
                {
                  columns: data.data.columns,
                  data: data.data.data,
                  totalLength:
                    !data.continuationToken || data.continuationToken === '' ? data.data.data.length : data.totalLength,
                },
                true,
              );
            }
          } else {
            console.log('error');
          }
        })
        .catch((e) => {
          console.log('catched !', e);
        });
    }
  };

  useEffect(() => {
    if (selectedDataSourceLocal.continuationToken) {
      getData(false);
    }
  }, [selectedDataSourceLocal]);

  const handleBackToCoverage = useCallback(() => {
    setTypeOfVisualisation(REPORT_TYPE_OF_VISUALISATIONS.COVERAGE);
    setAccountIdsTemp(accountIds);
    setAccountNamesTemp(accountNames);
    setClassSelected(0);
    setIsMapOpen(false);
  }, [accountIds, accountNames]);
  const handleOpenAuto = () => {
    setTypeOfVisualisation(REPORT_TYPE_OF_VISUALISATIONS.COVERAGE_AUTO);
  };
  const handleResetSelectedAccount = useCallback(() => {
    setAccountSelectedForCoverage([]);
  }, []);
  return (
    <div className="dataVisualisationContainer" ref={dataVisualisationContainerRef}>
      <div className="header">
        <div className="leftPart">
          <img
            src={RedCrossSVG}
            alt="exit"
            className="exitCross"
            onClick={(e) => {
              e.stopPropagation();
              setTypeOfVisualisation('');
              setNameOfNodeSelected({ accountId: '', accountName: '' });
            }}
          />
          {typeOfVisualisation === REPORT_TYPE_OF_VISUALISATIONS.COVERAGE && (
            <p className={'title'}>Datasource {typeOfVisualisation}</p>
          )}
          {typeOfVisualisation !== REPORT_TYPE_OF_VISUALISATIONS.COVERAGE && (
            <p className={'title'}>
              {classSelected > 0}
              {typeOfVisualisation} View
            </p>
          )}
        </div>
        <div className="name">{reportName}</div>
      </div>
      <div className="body">
        <div className="subheader">
          {classSelected > 0 && (
            <div className="backButtonContainer">
              <div className="backButton" onClick={handleBackToCoverage}>
                <ReactSVG className="svg-wrapper backArrowSVG" src={BackArrowSVG} />
                <p>Back to Coverage List</p>
              </div>
            </div>
          )}
          <div className="searchContainer">
            {typeOfVisualisation === 'Accounts' && (
              <>
                <button
                  className="clearButton"
                  style={{ display: searchValue ? 'flex' : 'none' }}
                  onClick={(e) => {
                    setSearchValue('');
                  }}>
                  Clear&nbsp;
                  <ReactSVG className="svg-wrapper crossCircleSVG" src={CrossCircleSVG} />
                </button>
                <SearchComponent
                  hasCloseIcon={false}
                  setSearchValue={setSearchValue}
                  showResult={showResult}
                  setShowResult={setShowResult}
                  searchResults={searchResults}
                  searchValue={searchValue}
                />
              </>
            )}
          </div>
          {typeOfVisualisation === REPORT_TYPE_OF_VISUALISATIONS.ACCOUNTS && classSelected === 0 && (
            <div className="buttonsContainer">
              <div
                className={`${accountSelectedForCoverage.length === 0 && 'moveBtnDisabled'} moveBtn`}
                onClick={() => {
                  accountSelectedForCoverage.length > 0 && setIsAccountMapOpen(true);
                }}>
                <p>Move</p>
                <img src={moveImg} alt="move" />
              </div>
            </div>
          )}
        </div>
        {typeOfVisualisation === REPORT_TYPE_OF_VISUALISATIONS.ACCOUNTS && (
          <AccountsList
            accountIds={accountIdsTemp}
            accountNames={accountNamesTemp}
            rowClicked={rowClicked}
            setRowClicked={setRowClicked}
            accountSelected={accountSelected}
            modeCoverage={classSelected > 0}
            accountSelectedForCoverage={accountSelectedForCoverage}
            setAccountSelectedForCoverage={setAccountSelectedForCoverage}
            setIsMapOpen={setIsMapOpen}
            cellSelected={cellSelected}
            accountSelectedInReport={accountSelectedInReport}
            setAccountSelectedInReport={setAccountSelectedInReport}
          />
        )}
        {typeOfVisualisation === REPORT_TYPE_OF_VISUALISATIONS.COVERAGE_AUTO && (
          <AutoCoverageContainer
            accountsForCoverage={accountSelectedForCoverage}
            setTypeOfVisualisation={setTypeOfVisualisation}
            selectedDataSource={selectedDataSource}
            graphId={graphId}
            templateKey={templateType}
            updateTreeCoverageAuto={updateTreeCoverageAuto}
            handleResetSelectedAccount={handleResetSelectedAccount}
            handleSetAlert={handleSetAlert}
          />
        )}

        {typeOfVisualisation !== REPORT_TYPE_OF_VISUALISATIONS.COVERAGE_AUTO &&
          accountSelectedForCoverage.length > 0 && // 1
          !isMapOpen &&
          classSelected > 0 && (
            <div className="mappingButtonContainer">
              <p>{accountSelectedForCoverage.length} Accounts selected</p>
              <div className="container-btn-mapping">
                <div className="btnMapping" onClick={handleOpenAuto}>
                  Add automatically
                  <ReactSVG className="svg-wrapper addCircleSVG" src={AddCircleSVG} />
                </div>
                <div className="btnMapping btn-mapping-manually" onClick={() => setIsMapOpen(true)}>
                  Add manually
                  <ReactSVG className="svg-wrapper addCircleSVG" src={AddCircleSVG} />
                </div>
              </div>
            </div>
          )}
        {typeOfVisualisation === REPORT_TYPE_OF_VISUALISATIONS.ENTRIES && (
          <div className="buttonsContainer">
            {findIntersection(currentAccountIdsWithMovedEntries, accountIds).length > 0 && (
              <div className="changeWarning">Save your changes to view them</div>
            )}
            <div className="action" onClick={() => setReset(true)}>
              <ReactSVG className="svg-wrapper restoreSVG" src={RestoreSVG} />
              <div className="title">Restore view</div>
            </div>
            <div
              className={`${entriesSelectedForCoverage.length === 0 && 'moveBtnDisabled'} moveBtn`}
              onClick={() => {
                entriesSelectedForCoverage.length > 0 && setIsEntriesMapOpen(true);
              }}>
              <p>Move</p>
              <img src={moveImg} alt="move" />
            </div>
          </div>
        )}
        {typeOfVisualisation === REPORT_TYPE_OF_VISUALISATIONS.ENTRIES && (
          <DataVizMapping
            finalData={finalData}
            columns={finalColumns}
            loaded={loaded}
            selectedRows={selectedEntriesRows}
            setSelectedRows={setSelectedEntriesRows}
            setEntriesSelectedForCoverage={setEntriesSelectedForCoverage}
            entriesSelectedForCoverage={entriesSelectedForCoverage}
            tableBodyHeight={tableBodyHeight}
            clearFilters={reset}
            setClearFilters={setReset}
            indexOfAccountId={indexOfAccountId}
            totalLength={finalData.length}
          />
        )}
        {typeOfVisualisation === REPORT_TYPE_OF_VISUALISATIONS.COVERAGE && (
          <CoverageClasses
            setClassSelected={setClassSelected}
            setTypeOfVisualisation={setTypeOfVisualisation}
            classAccount={classAccount}
            accountIds={accountIdsTemp}
            setAccountIds={setAccountIdsTemp}
            setAccountNames={setAccountNamesTemp}
            templateType={templateType}
          />
        )}
      </div>
      {isMapOpen && !isEntriesMapOpen && (
        <MapModule
          accountSelectedForCoverage={accountSelectedForCoverage}
          setAccountSelectedForCoverage={setAccountSelectedForCoverage}
          setIsMapOpen={setIsMapOpen}
          nameOfNodeSelected={nameOfNodeSelected}
          setNameOfNodeSelected={setNameOfNodeSelected}
          mappingTree={mappingTree}
          graphId={graphId}
          handleBackToCoverage={handleBackToCoverage}
        />
      )}
      {isEntriesMapOpen && !isMapOpen && (
        <EntriesMapModule
          entriesSelectedForCoverage={entriesSelectedForCoverage}
          setEntriesSelectedForCoverage={setEntriesSelectedForCoverage}
          setIsMapOpen={setIsEntriesMapOpen}
          nameOfNodeSelected={nameOfNodeSelected}
          setNameOfNodeSelected={setNameOfNodeSelected}
          mappingTree={mappingTree}
          datasourceId={datasourceId}
          savingTree={savingTree}
          saveTemplate={saveTemplate}
          setTypeOfVisualisation={setTypeOfVisualisation}
        />
      )}
      {isAccountMapOpen && !isMapOpen && (
        <AccountsMapModule
          accountSelectedForCoverage={accountSelectedForCoverage}
          setAccountSelectedForCoverage={setAccountSelectedForCoverage}
          setIsMapOpen={setIsAccountMapOpen}
          nameOfNodeSelected={nameOfNodeSelected}
          mappingTree={mappingTree}
        />
      )}
    </div>
  );
};

export default DataVisualisationContainer;
