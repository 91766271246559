// Create a new report

import { serviceGraph, serviceMining } from '../..';

export const getDatasourceCoverage = async (workspaceId: string, datasourceId: string) => {
  return serviceMining.request<NS_API.IFullDatasourceAccountCoverage>({
    method: 'GET',
    url: `/ds-coverage/${workspaceId}/${datasourceId}`,
    headers: {
      'Content-Type': `application/json`,
    },
  });
};

export const updateGraphWithCommandsList = async (
  workspaceId: string,
  graphId: string,
  templateKey: string,
  commands: any,
  selectedReportId?: string,
  newGraphName?: string,
) => {
  return serviceGraph.request<NS_API.IEditSaveResult>({
    method: 'POST',
    url: `/graph-command/${workspaceId}/update/${graphId}`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: {
      commands,
      templateKey,
      selectedReportId,
      newGraphName,
    },
  });
};

export const updateGraphCreateNode = async (
  workspaceId: string,
  graphId: string,
  templateKey: string,
  selectedReportId: string,
  commands: Array<NS_API.ICreateNodeCommands>,
  newGraphName: string | undefined,
) => {
  return serviceGraph.request({
    method: 'POST',
    url: `/graph-command/${workspaceId}/update/${graphId}`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: {
      commands,
      templateKey,
      selectedReportId,
      newGraphName,
    },
  });
};

export const updateGraphAutoCoverage = async (
  workspaceId: string,
  graphId: string,
  templateKey: string,
  selectedReportId: string,
  commands: any,
) => {
  return serviceGraph.request<NS_API.IAutoCoverage>({
    method: 'POST',
    url: `/graph-command/${workspaceId}/update/${graphId}`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: {
      commands,
      templateKey,
      selectedReportId,
    },
  });
};
