// Style
import './style.scss';

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { FixedSizeList as List } from 'react-window';

import Skeleton from '@mui/material/Skeleton';

import { IColumnHeader } from '../';
import DownArrowSVG from '../../../../assets/img/chevron-down-black.svg';
import ClearFilterSVG from '../../../../assets/img/filter-clear.svg';
import { WorkspaceContext } from '../../../../context/WorkspaceContext';
import Checkbox from '../../../../elements/Checkbox';
import { ELSFilter, SortMode, sortRows } from '../../../../shared/helpers/ELS-Filter';
import { toCurrency, wrappedTextDiv } from '../../../../shared/helpers/helpers';
import { smoothScroll } from '../../../../shared/helpers/report/reports.helper';
import { isItemSelected } from '../../../../shared/helpers/string/classnames.helper';
import { dataDestructuring } from '../../../../shared/helpers/tree/front.tree.util';
import CustomHeader from './CustomHeader';

interface IDataVizMappingProps {
  finalData: Array<NS_API.IDSValue>;
  columns: IColumnHeader[];
  loaded: boolean;
  selectedRows: number[];
  setSelectedRows: (val: number[]) => void;
  setEntriesSelectedForCoverage: (val: Array<NS_REPORT.IEntriesSelectedForCoverageProps>) => void;
  entriesSelectedForCoverage?: Array<NS_REPORT.IEntriesSelectedForCoverageProps>;
  tableBodyHeight: number;
  clearFilters?: boolean;
  setClearFilters?: React.Dispatch<React.SetStateAction<boolean>>;
  indexOfAccountId?: number;
  totalLength: number;
}

interface ICellValue {
  rowIndex: number;
  columnIndex: number;
  cellValues?: string;
}

/**
 * Component used to display the data visualisation
 *
 * @param param IMappingNodeProps props
 */

const countItem = (loaded: boolean, rewritedData: number) => {
  if (loaded) {
    return rewritedData;
  } else {
    return 15;
  }
};

const DataVizMapping: React.FC<IDataVizMappingProps> = ({
  finalData,
  columns,
  tableBodyHeight,
  loaded,
  selectedRows,
  setSelectedRows,
  setEntriesSelectedForCoverage,
  entriesSelectedForCoverage,
  clearFilters,
  setClearFilters,
  indexOfAccountId,
  totalLength,
}) => {
  // Context
  const { setSumInFooter } = useContext(WorkspaceContext);

  // State

  // header & col
  const [openContextHeader, setOpenContextHeader] = useState<number>(-2);
  const [widthCol, setWidthCol] = useState<number[]>([]);
  const [widthColBody, setWidthColBody] = useState<number[]>([]);
  const [currentCol, setCurrentCol] = useState<number>(-1);
  const [tableBodyWidth, setTableBodyWidth] = useState<number>(1500);
  const [keyDown, setKeyDown] = useState<Array<number | string>>([]);

  // cells selection
  const [selectedCells, setSelectedCells] = useState<ICellValue[][]>([]);
  const [startingCell, setStartingCell] = useState<ICellValue>();
  const [endingCell, setEndingCell] = useState<ICellValue>();
  const [currentGroupIndex, setCurrentGroupIndex] = useState<number>(0);
  const [mouseSelection, setMouseSelection] = useState<ICellValue | undefined>(undefined);

  // Filter & data
  const [rewritedData, setRewritedData] = useState<Array<string[]>>([]);
  const [initialData, setInitialData] = useState<Array<string[]>>([]);

  // Change/setChange to trigger effect
  const [change, setChange] = useState<boolean>(false);

  // Ref / fake ref
  const headerRowRef = useCallback(
    (node) => {
      if (node !== null) {
        setTableBodyWidth(node.offsetWidth);
      }
    },
    [finalData.length, columns.length, tableBodyHeight, widthColBody],
  ); // Fake ref to know headerRow Width

  const HeaderRef = useRef(null);
  const svgRefs = useRef<any[]>([]);
  const DataRef = useRef(null);
  const ref = useRef(null);
  const itemsRef = useRef<Array<HTMLDivElement | null>>([]);

  // Effects
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleKeyDown = (e) => {
    const newArray = keyDown;
    if (!newArray.includes(e.keyCode) || (!newArray.includes(17) && e.ctrlKey)) {
      newArray.push(e.key === 'Control' ? 17 : e.keyCode);
      setKeyDown(newArray);
    }
    if (e.keyCode === 27) {
      setEndingCell(undefined);
      setStartingCell(undefined);
      setSelectedCells([]);
      setCurrentGroupIndex(0);
    }
  };

  const handleKeyUp = (e) => {
    const newArray = keyDown;
    keyDown.forEach((item, key) => {
      if (item === e.keyCode || item === 17) {
        newArray.splice(key, 1);
      }
    });
    setKeyDown(newArray);
  };

  useEffect(() => {
    if (clearFilters && setClearFilters) {
      clearAllFilters();
      setClearFilters(false);
    }
  }, [clearFilters]);

  useEffect(() => {
    if (entriesSelectedForCoverage && entriesSelectedForCoverage.length === 0) {
      setSelectedRows([]);
    }
  }, [entriesSelectedForCoverage]);

  // Count all cells in selectedCells to sum them
  useEffect(() => {
    const sumMath = selectedCells.reduce((sum, cellGrp) => {
      const groupSum = cellGrp.reduce((sumCell, cell) => {
        if (cell.cellValues && parseFloat(cell.cellValues)) {
          return sumCell + parseFloat(cell.cellValues);
        } else {
          return sumCell;
        }
      }, 0);

      return sum + groupSum;
    }, 0);
    const count = selectedCells.reduce((sum, cellGrp) => {
      return sum + cellGrp.length;
    }, 0);
    setSumInFooter({ sum: toCurrency('0,0.00', sumMath), count: count });
    // eslint-disable-next-line
  }, [selectedCells, endingCell, currentGroupIndex, selectedCells[currentGroupIndex]]);

  useEffect(() => {
    if (loaded && finalData.length > 0 && columns.length > 0 && !selectedRows.length) {
      changeRowsData();
    }
  }, [loaded, finalData, columns]);

  useEffect(() => {
    if (finalData.length > 0 && selectedRows.length === 0) {
      changeRowsData();
    }
  }, [selectedRows.length]);

  useEffect(() => {
    // Remove multiple cells from selectedCells
    if (endingCell && selectedCells[currentGroupIndex].length > 0 && startingCell) {
      const tempGlobal = dataDestructuring(selectedCells);
      const temp = tempGlobal[currentGroupIndex].filter((cell) => {
        if (
          (cell.rowIndex >= startingCell.rowIndex && cell.rowIndex <= endingCell.rowIndex) ||
          (cell.rowIndex <= startingCell.rowIndex && cell.rowIndex >= endingCell.rowIndex)
        ) {
          if (
            (cell.columnIndex >= startingCell.columnIndex && cell.columnIndex <= endingCell.columnIndex) ||
            (cell.columnIndex <= startingCell.columnIndex && cell.columnIndex >= endingCell.columnIndex)
          ) {
            return cell;
          }
        }
        return null;
      });
      if (temp.length !== tempGlobal[currentGroupIndex].length) {
        tempGlobal[currentGroupIndex] = temp;
        setSelectedCells(tempGlobal);
      }
    }
  }, [endingCell, startingCell]);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, widthCol.length);
  }, [widthCol]);

  useEffect(() => {
    const refElement = itemsRef.current[currentCol];
    if (refElement) {
      const tempWidth = [...widthColBody];
      tempWidth[currentCol] = parseInt(refElement.style.minWidth);
      setWidthColBody(tempWidth);
      setCurrentCol(-1);
    }
  }, [currentCol]);

  // Function

  const changeRowsData = () => {
    ELSFilter.initELSFilter(columns, finalData);
    const tempArrayData: Array<string[]> = Object.keys(finalData).map((key) => finalData[key]);
    const tempWidth: number[] = [];
    columns.forEach(() => {
      tempWidth.push(170);
    });
    setInitialData(tempArrayData);
    setWidthCol(tempWidth);
    setWidthColBody(tempWidth);
  };

  const isChecked = (key: number) => {
    return selectedRows.includes(key);
  };

  const toggleCheck = (key: number) => {
    if (selectedRows.includes(key)) {
      const arr = selectedRows.filter((e) => e !== key);
      setSelectedRows(arr);
    } else {
      setSelectedRows([...selectedRows, key]);
    }
  };

  const toggleCheckAll = useCallback(
    (e) => {
      const rowValues: string[][] =
        initialData.length === rewritedData.length || rewritedData.length === 0 ? initialData : rewritedData;
      const newSelectedRows: number[] = [];
      if (selectedRows.length < rowValues.length) {
        rowValues.forEach((dataRow) => {
          newSelectedRows.push(parseInt(dataRow[dataRow.length - 1]));
        });
        setSelectedRows(newSelectedRows);
      } else {
        setSelectedRows([]);
      }
    },
    [initialData, rewritedData],
  );

  const clearAllFilters = () => {
    setRewritedData([]);
    ELSFilter.filter(columns, initialData);
    columns.forEach((column) => {
      if (column.scorfHeader === 'EntryDate') {
        delete column.ELSFilter.dateViewRoot;
      }
      column.ELSFilter.isFiltered = false;
      const temp = dataDestructuring(column.ELSFilter.selection);
      for (const key2 in temp) {
        temp[key2] = true;
      }
      column.ELSFilter.selection = dataDestructuring(temp);
    });
  };

  const clearFilter = useCallback(
    (indexColumn) => {
      if (columns[indexColumn].scorfHeader === 'EntryDate') {
        delete columns[indexColumn].ELSFilter.dateViewRoot;
      }
      columns[indexColumn].ELSFilter.isFiltered = false;
      const temp = dataDestructuring(columns[indexColumn].ELSFilter.selection);
      for (const key2 in temp) {
        temp[key2] = true;
      }
      columns[indexColumn].ELSFilter.selection = dataDestructuring(temp);
      validateFilters();
      setOpenContextHeader(-2);
    },
    [columns, initialData],
  );

  const toggleCheckHeader = (key: string, indexColumn: number, e: MouseEvent, checkAll = false) => {
    e.stopPropagation();
    const temp = dataDestructuring(columns[indexColumn].ELSFilter.selection);
    if (checkAll) {
      if (Object.values(temp).every((isTrue) => isTrue)) {
        for (const key1 in temp) {
          temp[key1] = false;
        }
      } else {
        for (const key2 in temp) {
          temp[key2] = true;
        }
      }
    } else {
      temp[key] = !temp[key];
    }

    columns[indexColumn].ELSFilter.selection = dataDestructuring(temp);
    setChange(!change);
  };

  const getFormatedValue = useCallback((value, key, format) => {
    if (isNaN(parseInt(value)) || key === 0) {
      return value;
    } else if (parseInt(value) === 0) {
      return '-';
    } else {
      return wrappedTextDiv({ width: '100%' }, value, false, false, format);
    }
  }, []);

  const validateFilters = useCallback(() => {
    const filteredRows = ELSFilter.filter(columns, initialData);
    setRewritedData(filteredRows);
    setOpenContextHeader(-2);
  }, [initialData, columns]);

  const sort = useCallback(
    (type: SortMode.ASC | SortMode.DESC, indexCol: number) => {
      const isInt =
        columns[indexCol].colParams.colDataType.dataType === 'currency' ||
        columns[indexCol].colParams.colDataType.dataType === 'number';
      if (initialData.length === rewritedData.length || rewritedData.length === 0) {
        const tempData = [...initialData];
        sortRows(tempData, indexCol.toString(), isInt, type);
        setInitialData(tempData);
      } else {
        const tempData = [...rewritedData];
        sortRows(tempData, indexCol.toString(), isInt, type);
        setRewritedData(tempData);
      }
      setOpenContextHeader(-2);
    },
    [columns],
  );

  const toggleCheckBox = (id: string, index: number, splitId: string) => (e) => {
    if (entriesSelectedForCoverage) {
      const newEntriesObject = entriesSelectedForCoverage;
      const indexOfEntry = entriesSelectedForCoverage.findIndex((e) => e.index === index);
      if (indexOfEntry === -1) {
        newEntriesObject.push({ accountId: id, index: index, splitId: splitId });
      } else {
        newEntriesObject.splice(indexOfEntry, 1);
      }
      setEntriesSelectedForCoverage(newEntriesObject);
    }
    toggleCheck(index);
    e.stopPropagation();
  };

  const resizeCol = (e, index) => {
    e.preventDefault();
    const x = resize.bind(null, index);
    window.addEventListener('mousemove', x);
    window.addEventListener('mouseup', () => {
      window.removeEventListener('mousemove', x);
      setCurrentCol(index);
    });
  };

  const resize = (index, e) => {
    const refElement = itemsRef.current[index];
    if (refElement) {
      const nextWidth = e.pageX + 15 - refElement.getBoundingClientRect().left;
      widthCol[index] = Math.max(80, nextWidth);
      refElement.style.minWidth = widthCol[index] + 'px';
    }
  };

  const simpleNavigation = (cell: ICellValue) => {
    if (endingCell) {
      setEndingCell(undefined);
    }
    if (startingCell) {
      setStartingCell(undefined);
    }
    if (currentGroupIndex !== 0) {
      setCurrentGroupIndex(0);
    }
    setSelectedCells([[cell]]);
  };

  // CallBack

  // When you click on a cell select this cell, start a new group if ctrl is pressed
  const addCell = useCallback(
    (e, indexGrp, index, rowIndex: number, columnIndex: number, cellValues: string) => {
      if (keyDown.includes(17)) {
        e.preventDefault();
      }
      setOpenContextHeader(-2);
      setEndingCell(undefined);
      setStartingCell(undefined);
      if (indexGrp !== -1 && index !== -1) {
        const temp = dataDestructuring(selectedCells);
        temp[indexGrp].splice(index, 1);
        if (e.ctrlKey) {
          setSelectedCells(temp);
        } else {
          setSelectedCells([]);
        }
      } else if (e.shiftKey && selectedCells.length !== 0) {
        const temp = dataDestructuring(selectedCells);

        const tempStartingCell = selectedCells[currentGroupIndex][0];
        const tempEndingCell = { rowIndex, columnIndex, cellValues };
        const cellsToAdd: ICellValue[] = [];

        const reverseCol = Math.sign(tempStartingCell.columnIndex - tempEndingCell.columnIndex) !== -1;
        const reverseRow = Math.sign(tempStartingCell.rowIndex - tempEndingCell.rowIndex) !== -1;

        for (
          let j = reverseCol ? tempEndingCell.columnIndex : tempStartingCell.columnIndex;
          j <= (reverseCol ? tempStartingCell.columnIndex : tempEndingCell.columnIndex);
          j++
        ) {
          for (
            let i = reverseRow ? tempEndingCell.rowIndex : tempStartingCell.rowIndex;
            i <= (reverseRow ? tempStartingCell.rowIndex : tempEndingCell.rowIndex);
            i++
          ) {
            cellsToAdd.push({ rowIndex: i, columnIndex: j });
          }
        }

        temp[currentGroupIndex] = reverseCol || reverseRow ? [...cellsToAdd].reverse() : cellsToAdd;

        setSelectedCells(temp);
        setStartingCell(
          reverseCol || reverseRow
            ? selectedCells[currentGroupIndex][selectedCells[currentGroupIndex].length - 1]
            : selectedCells[currentGroupIndex][0],
        );
        setEndingCell({ rowIndex, columnIndex, cellValues });
        return;
      } else if (e.ctrlKey && selectedCells.length !== 0) {
        setCurrentGroupIndex(currentGroupIndex + 1);
        setSelectedCells([...selectedCells, [{ rowIndex, columnIndex, cellValues }]]);
      } else {
        setSelectedCells([[{ rowIndex, columnIndex, cellValues }]]);
        setCurrentGroupIndex(0);
      }
    },
    [selectedCells],
  );

  const mouseSelectionFc = (ending: boolean, rowIndex: number, columnIndex: number, cellValues: string) => {
    if (mouseSelection && (mouseSelection.rowIndex !== rowIndex || mouseSelection.columnIndex !== columnIndex)) {
      const temp = dataDestructuring(selectedCells);
      const tempStartingCell = mouseSelection;
      const tempEndingCell = { rowIndex, columnIndex, cellValues };
      const cellsToAdd: ICellValue[] = [];

      const reverseCol = Math.sign(tempStartingCell.columnIndex - tempEndingCell.columnIndex) !== -1;
      const reverseRow = Math.sign(tempStartingCell.rowIndex - tempEndingCell.rowIndex) !== -1;

      for (
        let j = reverseCol ? tempEndingCell.columnIndex : tempStartingCell.columnIndex;
        j <= (reverseCol ? tempStartingCell.columnIndex : tempEndingCell.columnIndex);
        j++
      ) {
        for (
          let i = reverseRow ? tempEndingCell.rowIndex : tempStartingCell.rowIndex;
          i <= (reverseRow ? tempStartingCell.rowIndex : tempEndingCell.rowIndex);
          i++
        ) {
          cellsToAdd.push({ rowIndex: i, columnIndex: j });
        }
      }

      temp[currentGroupIndex] = cellsToAdd;
      setSelectedCells(temp);
      if (ending) {
        setEndingCell(tempEndingCell);
      }
    }
  };

  // When shift pressed while navigate with arrow, store new cell as endingCell and add new cell to the currentGroup inside selectedCells
  const addSelectedCell = useCallback(
    (newCell: { rowIndex: number; columnIndex: number; cellValues?: string }) => {
      const indexCell = selectedCells[currentGroupIndex].findIndex(
        (slctdCell) => slctdCell.rowIndex === newCell.rowIndex && slctdCell.columnIndex === newCell.columnIndex,
      );
      if (indexCell === -1) {
        const temp = dataDestructuring(selectedCells);
        temp[currentGroupIndex].push(newCell);
        setSelectedCells(temp);
      }
      setEndingCell(newCell);
      setStartingCell(selectedCells[currentGroupIndex][0]);
    },
    [selectedCells],
  );

  // choose which border show when a cell is selected
  const borderSelected = useCallback(
    (index, indexGrp) => {
      if (selectedCells[indexGrp].length >= 2 && index !== -1) {
        return true ? (
          <>
            {!selectedCells[indexGrp].some(
              (cell) =>
                cell.columnIndex === selectedCells[indexGrp][index].columnIndex &&
                cell.rowIndex === selectedCells[indexGrp][index].rowIndex - 1,
            ) && <div className="borderTop" />}
            {!selectedCells[indexGrp].some(
              (cell) =>
                cell.rowIndex === selectedCells[indexGrp][index].rowIndex &&
                cell.columnIndex === selectedCells[indexGrp][index].columnIndex + 1,
            ) && <div className="borderRight" />}
            {!selectedCells[indexGrp].some(
              (cell) =>
                cell.columnIndex === selectedCells[indexGrp][index].columnIndex &&
                cell.rowIndex === selectedCells[indexGrp][index].rowIndex + 1,
            ) && <div className="borderBottom" />}
            {!selectedCells[indexGrp].some(
              (cell) =>
                cell.rowIndex === selectedCells[indexGrp][index].rowIndex &&
                cell.columnIndex === selectedCells[indexGrp][index].columnIndex - 1,
            ) && <div className="borderLeft" />}
          </>
        ) : null;
      } else {
        return index !== -1 ? (
          <>
            <div className="borderTop" />
            <div className="borderBottom" />
            <div className="borderRight" />
            <div className="borderLeft" />
          </>
        ) : (
          <></>
        );
      }
    },
    [selectedCells],
  );

  // Use arrow to navigate + shift to create selection groups
  const handleKeyPressEvent = useCallback(
    (e: KeyboardEvent) => {
      if (selectedCells[currentGroupIndex] && selectedCells[currentGroupIndex].length > 0) {
        e.preventDefault();
        const slctdCell = dataDestructuring(
          endingCell ?? selectedCells[currentGroupIndex][selectedCells[currentGroupIndex].length - 1],
        );
        if (slctdCell) {
          switch (e.key) {
            case 'ArrowLeft':
              if (slctdCell.columnIndex >= 1) {
                slctdCell.columnIndex = slctdCell.columnIndex - 1;
                if (DataRef.current) {
                  smoothScroll(DataRef.current, 'left', 20, 150, 20);
                }
              }
              e.shiftKey ? addSelectedCell(slctdCell) : simpleNavigation(slctdCell);
              break;
            case 'ArrowUp':
              if (slctdCell.rowIndex >= 1) {
                slctdCell.rowIndex = slctdCell.rowIndex - 1;
                if (ref.current) {
                  smoothScroll(ref.current, 'up', 20, 35, 5);
                }
              }
              e.shiftKey ? addSelectedCell(slctdCell) : simpleNavigation(slctdCell);
              break;
            case 'ArrowRight':
              if (slctdCell.columnIndex < columns.length - 1) {
                if (DataRef.current) {
                  smoothScroll(DataRef.current, 'right', 20, 150, 20);
                }
                slctdCell.columnIndex = slctdCell.columnIndex + 1;
              }
              e.shiftKey ? addSelectedCell(slctdCell) : simpleNavigation(slctdCell);
              break;
            case 'ArrowDown':
              if (slctdCell.rowIndex < finalData.length - 1) {
                slctdCell.rowIndex = slctdCell.rowIndex + 1;
                if (ref.current) {
                  smoothScroll(ref.current, 'down', 20, 35, 5);
                }
              }
              e.shiftKey ? addSelectedCell(slctdCell) : simpleNavigation(slctdCell);
              break;
            default:
              break;
          }
        }
      }
    },
    // eslint-disable-next-line
    [selectedCells, addSelectedCell, endingCell, currentGroupIndex],
  );

  // deselect all selectedCells on click outside Table
  const handleMouseClickOutSideTable = useCallback((ev: MouseEvent) => {
    if (DataRef.current && !(DataRef.current as any).contains(ev.target)) {
      setEndingCell(undefined);
      setStartingCell(undefined);
      setSelectedCells([]);
      setCurrentGroupIndex(0);
    }
    if (
      HeaderRef.current &&
      svgRefs.current &&
      !(HeaderRef.current as any).contains(ev.target) &&
      svgRefs.current.findIndex((el) => el?.contains(ev.target)) === -1
    ) {
      setOpenContextHeader(-2);
    }
    // eslint-disable-next-line
  }, []);

  // Effect addEventListener

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPressEvent);
    window.addEventListener('mouseup', handleMouseClickOutSideTable);
    return () => {
      window.removeEventListener('keydown', handleKeyPressEvent);
      window.removeEventListener('mouseup', handleMouseClickOutSideTable);
    };
  }, [handleKeyPressEvent]);

  // Render row
  const renderRow = useCallback(
    (propsVirtList) => {
      const { index, style } = propsVirtList;
      if (!loaded) {
        return (
          <div key={index} className={`${index % 2 ? 'ListItemOdd' : 'ListItemEven'} skeleton tr`} style={style}>
            <Skeleton key={index} />
          </div>
        );
      }
      const isSplitId = columns[columns.length - 1].scorfHeader === '_SplitId';
      const rowValues: Array<string> =
        initialData.length === rewritedData.length || rewritedData.length === 0
          ? initialData[index]
          : rewritedData[index];
      return (
        <div
          key={index}
          className={`${index % 2 ? 'ListItemOdd' : 'ListItemEven'} tr ${
            isChecked(parseInt(rowValues[rowValues.length - 1])) && 'rowSelected'
          }`}
          style={style}>
          <div className="td">
            <Checkbox
              key={index}
              checked={isChecked(parseInt(rowValues[rowValues.length - 1]))}
              toggle={toggleCheckBox(
                indexOfAccountId !== undefined ? rowValues[indexOfAccountId] : '',
                parseInt(rowValues[rowValues.length - 1]),
                isSplitId ? rowValues[rowValues.length - 2] : '',
              )}
            />
          </div>
          {rowValues.map((value, key) => {
            const indexGrp = selectedCells.findIndex(
              (selectedCell) =>
                selectedCell.findIndex((slctdCell) => slctdCell.rowIndex === index && slctdCell.columnIndex === key) !==
                -1,
            );
            const indexCell =
              indexGrp !== -1
                ? selectedCells[indexGrp].findIndex(
                    (slctdCell) => slctdCell.rowIndex === index && slctdCell.columnIndex === key,
                  )
                : -1;

            const indexGrps = selectedCells.filter(
              (selectedCell) =>
                selectedCell.findIndex((slctdCell) => slctdCell.rowIndex === index && slctdCell.columnIndex === key) !==
                -1,
            ); // To know if we add a backgroundColor if a cell is multiple selected
            const tempIndexCell =
              currentGroupIndex !== 0 && selectedCells && selectedCells[currentGroupIndex]
                ? selectedCells[currentGroupIndex].findIndex(
                    (slctdCell) => slctdCell.rowIndex === index && slctdCell.columnIndex === key,
                  )
                : -2;

            if (indexCell !== -1 && selectedCells[indexGrp][indexCell].cellValues !== value) {
              const slctdCell = selectedCells[indexGrp][indexCell];
              slctdCell.cellValues = value;
              selectedCells[indexGrp].splice(indexCell, 1, slctdCell);
              setSelectedCells([...selectedCells]);
            }
            if (
              (indexCell === -1 && startingCell && endingCell) ||
              (indexCell !== -1 && tempIndexCell === -1 && startingCell && endingCell)
            ) {
              if (
                (index >= startingCell.rowIndex && index <= endingCell.rowIndex) ||
                (index <= startingCell.rowIndex && index >= endingCell.rowIndex)
              ) {
                if (
                  (key >= startingCell.columnIndex && key <= endingCell.columnIndex) ||
                  (key <= startingCell.columnIndex && key >= endingCell.columnIndex)
                ) {
                  const temp = dataDestructuring(selectedCells);
                  temp?.[currentGroupIndex]?.push({ rowIndex: index, columnIndex: key, cellValues: value });
                  temp[currentGroupIndex] && setSelectedCells(temp);
                }
              }
            }
            if (key < columns.length) {
              const keyIndex = `cell-${index}-${key}`;
              return (
                <div
                  key={keyIndex}
                  style={{ maxWidth: widthColBody[key], minWidth: widthColBody[key] }}
                  className={`${isItemSelected(indexCell !== -1)} td ${
                    isChecked(parseInt(rowValues[rowValues.length - 1])) && 'rowSelected'
                  } ${indexGrps.length > 1 && 'backgroundDark'}`}
                  onContextMenu={(e) => {
                    addCell(e, indexGrp, indexCell, index, key, value);
                  }}
                  onMouseDown={(e) => {
                    addCell(e, indexGrp, indexCell, index, key, value);
                    if (!mouseSelection) {
                      setMouseSelection({ rowIndex: index, columnIndex: key, cellValues: value });
                      mouseSelectionFc(false, index, key, value);
                    }
                  }}
                  onMouseMove={(e) => {
                    if (mouseSelection) {
                      mouseSelectionFc(false, index, key, value);
                    }
                  }}
                  onMouseUp={(e) => {
                    e.stopPropagation();
                    if (mouseSelection) {
                      setMouseSelection(undefined);
                      mouseSelectionFc(true, index, key, value);
                    }
                  }}>
                  {indexCell !== -1 && borderSelected(indexCell, indexGrp)}
                  {columns[key] && columns[key].colParams.colDataType.dataType === 'currency'
                    ? getFormatedValue(value, key, '0,0.0')
                    : value}
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      );
    },
    [selectedRows, selectedCells, widthColBody, rewritedData, initialData, columns, loaded, tableBodyWidth, endingCell],
  );

  const handleCloseHeader = (index: number) => () => {
    setOpenContextHeader(index);
  };

  return (
    <>
      <div className="dataVizContainer">
        <div className="table" ref={DataRef}>
          <div className="thead" ref={headerRowRef}>
            <div className="tr">
              <div className="th">
                <Checkbox
                  checked={selectedRows.length === (rewritedData.length > 0 ? rewritedData.length : initialData.length)}
                  toggle={toggleCheckAll}
                />
              </div>
              {columns.length !== 0 ? (
                <>
                  {columns.map((column, index) => {
                    const key = `header-${index}`;
                    return (
                      <div
                        className="th"
                        style={{ minWidth: widthCol[index], maxWidth: widthCol[index] }}
                        key={key}
                        ref={(el) => (itemsRef.current[index] = el)}>
                        <div className="left">
                          {column?.ELSFilter?.isFiltered && (
                            <ReactSVG src={ClearFilterSVG} className="svg-wrapper clearFilterSVG" />
                          )}
                          <div className="title" title={column.Header}>
                            {column.Header}
                          </div>
                        </div>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            totalLength <= finalData.length &&
                              setOpenContextHeader(openContextHeader === index ? -2 : index);
                            setSelectedCells([]);
                          }}
                          ref={(element) => !svgRefs.current.includes(element) && svgRefs.current.push(element)}
                          className="chevronHeader">
                          <ReactSVG src={DownArrowSVG} className="svg-wrapper downArrowSVG" />
                        </div>
                        {itemsRef.current && <div className="drag" onMouseDown={(e) => resizeCol(e, index)} />}
                        {openContextHeader === index && (
                          <CustomHeader
                            column={column}
                            indexColumn={index}
                            toggleCheckHeader={toggleCheckHeader}
                            validateFilters={validateFilters}
                            clearFilter={clearFilter}
                            close={handleCloseHeader(-2)}
                            sortRows={sort}
                            tableBodyHeight={tableBodyHeight}
                            HeaderRef={HeaderRef}
                          />
                        )}
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  {[...Array(10).keys()].map((index) => {
                    return (
                      <div className="th skeleton" style={{ minWidth: 170, maxWidth: 170 }} key={`header-${index}`}>
                        <Skeleton key={index} />
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>

          {finalData.length > 0 && (
            <List
              height={tableBodyHeight}
              itemCount={
                initialData.length === rewritedData.length || rewritedData.length === 0
                  ? countItem(loaded, initialData.length)
                  : countItem(loaded, rewritedData.length)
              }
              width={tableBodyWidth}
              itemSize={35}
              className="tbody"
              outerRef={ref}>
              {renderRow}
            </List>
          )}
          {finalData.length === 0 && (
            <div className="tbody" style={{ width: tableBodyWidth }}>
              {[...Array(10).keys()].map((index) => {
                return (
                  <div key={index} className={`${index % 2 ? 'ListItemOdd' : 'ListItemEven'} skeleton tr`}>
                    {[...Array(10).keys()].map((indexCell) => {
                      return indexCell !== 0 ? (
                        <Skeleton key={indexCell} style={{ width: 102, marginRight: 70 }} />
                      ) : (
                        <Skeleton key={indexCell} style={{ width: 102, marginRight: 70, marginLeft: 60 }} />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DataVizMapping;
