import { serviceTemplateCollection } from '../..';

// GET
export const getAllTemplateCollectionsForWorkspace = async (workspaceId: string) => {
  return serviceTemplateCollection.request<Array<NS_API.ITemplateCollection>>({
    method: 'GET',
    url: `/templatecollection/${workspaceId}`,
    headers: {
      'Content-Type': `application/json`,
    },
  });
};

// GET

export const getAllTemplateCollectionForCustomer = async (customerId: string, workspaceId: string) => {
  return serviceTemplateCollection.request<Array<NS_API.ITemplateCollection>>({
    method: 'GET',
    url: `/templatecollection/${workspaceId}`,
    headers: {
      'Content-Type': `application/json`,
    },
  });
};

// POST

export const copyMultipleTemplateCollectionToAnotherWorkspace = async (
  templateCollections: Array<NS_API.ITemplateCollection>,
  workspaceId: string,
) => {
  return serviceTemplateCollection.request<Array<NS_API.ITemplateCollection>>({
    method: 'POST',
    url: `/templatecollection/copyMultiple/${workspaceId}`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: templateCollections,
  });
};

// DELETE

export const deleteTemplateCollections = async (
  workspaceId: string,
  templateCollections: Array<NS_API.ITemplateCollection>,
) => {
  return serviceTemplateCollection.request({
    method: 'DELETE',
    url: `/templatecollection/${workspaceId}/delete/many`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: templateCollections,
  });
};

// PUT

export const updateTemplateCollection = async (workspaceId: string, collectionId: string, collectionName: string) => {
  return serviceTemplateCollection.request({
    method: 'PUT',
    url: `/templatecollection/${workspaceId}/${collectionId}`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: {
      collectionName,
    },
  });
};
