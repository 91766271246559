import React from 'react';

// Style
import './style.scss';
import trashIcon from '../../../assets/img/trash-red.svg';
import editIcon from '../../../assets/img/edit.svg';

interface ITGLContextMenu {
  /**
   * Is menu opened?
   */
  isOpen: boolean;
  xPos: number;
  yPos: number;
  /**
   * Delete handler
   */
  deleteFn: (index: number) => void;
  /**
   * Rename hanlder
   */
  handleRename: () => void;
  index: number;
}

/**
 * Toggle List context menu component, used to delete/rename a tab
 */
const ToggleListContextMenu: React.FC<ITGLContextMenu> = ({ isOpen, xPos, yPos, deleteFn, index, handleRename }) => {
  return (
    <div>
      {isOpen && (
        <div style={{ top: yPos, left: xPos }} className={'tglContextMenu'}>
          <div className={'contextMenuElement'} onClick={() => deleteFn(index)}>
            <div className={'contextIcon'}>
              <img src={trashIcon} alt={'Trash Icon'} className="trash" />
            </div>
            <div className={'contextAction'}>Delete</div>
          </div>
          <div className={'contextMenuElement'} onClick={() => handleRename()}>
            <div className={'contextIcon'}>
              <img src={editIcon} alt={'Edit Icon'} />
            </div>
            <div className={'contextAction'}>Rename</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ToggleListContextMenu;
