// GET

import { serviceCustomer } from '../..';

// POST

export const submitDatasourceToSupport = async (
  workspaceId: string,
  fileId: string,
  datasourceName: string,
  issueType: string,
  issueDescription: string,
) => {
  return serviceCustomer.request({
    method: 'POST',
    url: `/support/${workspaceId}`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: {
      fileId,
      datasourceName,
      issueType,
      issueDescription,
    },
  });
};

// PATCH

// DELETE
