import _ from 'lodash';

import { IReportInfo, TemplateKey } from '../../constant/datasource.consts';

export const getDatasourceColumnIndex = (
  headerIndex: Array<number>,
  scorfHeaderName: string,
  index: number,
  isTB = false,
): Array<number> => {
  switch (scorfHeaderName) {
    case 'AccountID':
      headerIndex.splice(0, 0, index);
      break;
    case 'AccountName':
      headerIndex.splice(1, 0, index);
      break;
    case 'EntryDebit':
      headerIndex.splice(2, 0, index);
      break;
    case 'EntryCredit':
      headerIndex.splice(3, 0, index);
      break;
    case 'EntryBalance':
      headerIndex.splice(4, 0, index);
      break;
    case 'EntryDescription':
      if (!isTB) {
        headerIndex.splice(5, 0, index);
      }
      break;
    default:
      break;
  }
  return headerIndex;
};

export const normalizeDataRowsValues = (data: Array<any>, columns: Array<NS_Table.IColumnHeader>) => {
  const dataClone = _.cloneDeep(data);
  columns.forEach((column) => {
    if (column.colParams.colDataType.dataType === 'currency') {
      dataClone.forEach((row) => {
        const numberOfDecimals = Number(column.colParams.colDataType.format.split(',')[1]);
        if (!isNaN(numberOfDecimals) && numberOfDecimals !== 0) {
          row[column.accessor] = row[column.accessor] / Math.pow(10, numberOfDecimals);
        }
      });
    }
  });
  return dataClone;
};

export const deleteCols = (
  colsToDelete: number[],
  tempData: NS_API.IDSValue[],
  tempCol: Array<NS_Table.IColumnHeader>,
) => {
  const colClone = _.cloneDeep(tempCol);
  for (let i = 0; i < tempData.length; i++) {
    colsToDelete.forEach((colIndex) => {
      delete tempData[i][colIndex];
    });
    tempData[i] = Object.values(tempData[i]);
  }
  colsToDelete.reverse();
  colsToDelete.forEach((col) => {
    colClone.splice(col, 1);
  });
  colClone.forEach((column, index) => {
    column.accessor = index.toString();
  });
  return colClone;
};

export const generateExportList = (): Array<IReportInfo> => {
  return [
    {
      reportName: 'Profit and Loss - Yearly',
      id: '',
      reportActivated: true,
      templateKey: TemplateKey.PL,
      yearOrMonth: 'year',
      cumulDisplay: false,
      accessible: true,
    },
    {
      reportName: 'Profit and Loss - Monthly - Flow',
      id: '',
      reportActivated: true,
      templateKey: TemplateKey.PL,
      yearOrMonth: 'month',
      cumulDisplay: false,
      accessible: true,
    },
    {
      reportName: 'Balance Sheet - Yearly',
      id: '',
      reportActivated: true,
      templateKey: TemplateKey.BS,
      yearOrMonth: 'year',
      cumulDisplay: false,
      accessible: true,
    },
    {
      reportName: 'Balance Sheet - Monthly - Cumul',
      id: '',
      reportActivated: true,
      templateKey: TemplateKey.BS,
      yearOrMonth: 'month',
      cumulDisplay: true,
      accessible: true,
    },
    {
      reportName: 'Cash Flow - Yearly',
      id: '',
      reportActivated: true,
      templateKey: TemplateKey.CF,
      yearOrMonth: 'year',
      cumulDisplay: false,
      accessible: true,
    },
    {
      reportName: 'Cash Flow - Monthly - Flow',
      id: '',
      reportActivated: true,
      templateKey: TemplateKey.CF,
      yearOrMonth: 'month',
      cumulDisplay: false,
      accessible: true,
    },
    {
      reportName: 'Cash Balance - Monthly - Flow',
      id: '',
      reportActivated: true,
      templateKey: TemplateKey.CB,
      yearOrMonth: 'month',
      accessible: true,
    },
    {
      reportName: 'Cash Report - Monthly - Flow',
      id: '',
      reportActivated: true,
      templateKey: TemplateKey.CR,
      yearOrMonth: 'month',
      accessible: true,
    },
    {
      reportName: 'Sales By Client - Yearly',
      id: '',
      reportActivated: true,
      templateKey: TemplateKey.CS,
      yearOrMonth: 'year',
      accessible: true,
    },
    {
      reportName: 'Purchases by nature',
      id: '',
      reportActivated: true,
      templateKey: TemplateKey.PN,
      yearOrMonth: 'year',
      accessible: true,
    },
    {
      reportName: 'Purchases by supplier',
      id: '',
      reportActivated: true,
      templateKey: TemplateKey.PP,
      yearOrMonth: 'year',
      accessible: true,
    },
  ];
};
