import './style.scss';

interface CardProps {
  value: string;
  title: string;
  description: string;
  color: string;
  fiscalYear?: string;
}
const CashCard = ({ value, title, description, color, fiscalYear }: CardProps) => {
  return (
    <div className="card flex flex-nowrap align-items-center">
      <div
        className="value overflow-hidden font-semibold position-relative flex align-items-center justify-content-center"
        style={{ color: color }}>
        <div className="layer position-absolute w-100 h-100" style={{ backgroundColor: color }}></div>
        {value}
      </div>
      <div className="text w-100 flex flex-column align-items-start">
        <span className="title font-medium cash-card-block-point">
          {title}{' '}
          {!!fiscalYear && (
            <span className="reference flex align-items-center">
              <span className="point cash-card-point"></span>
              {fiscalYear}
            </span>
          )}
        </span>
        <span className="description font-medium" style={{ color: color }}>
          {description}
        </span>
      </div>
    </div>
  );
};

export default CashCard;
