import './style.scss';

import { FC } from 'react';

import { ISplitReport } from '../../../containers/ChartReport';
import { getFiscalYears, TChartData } from '../../../shared/charts/utils';
import { ChartFamily } from '../../../shared/constant/datasource.consts';
import ChartBlockItem from './BlockItem';
import CashInformation from './CashInformation';

interface IChartBodyContainer {
  templateKey: ChartFamily;
  splitReports: ISplitReport;
}

const ChartBodyContainer: FC<IChartBodyContainer> = ({ templateKey, splitReports }) => {
  const chartData: Array<TChartData> = splitReports[templateKey];
  let kpiData = chartData
    .filter((c) => c?.calculatedValues?.kpi)
    .map((d: any) => {
      let fiscalYear = '';
      if (d?.fiscalYearsInfo) {
        fiscalYear = getFiscalYears(d.fiscalYearsInfo, d?.isUnion, true);
      }
      return { ...d.calculatedValues.kpi, fiscalYear };
    });
  return (
    <div className="chart-report-body">
      <div className="new-body-container">
        <CashInformation kpiData={kpiData} isLoading={!chartData?.length} templateKey={templateKey} />
        <ChartBlockItem chartData={chartData} templateKey={templateKey} />
      </div>
    </div>
  );
};

export default ChartBodyContainer;
