import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

export interface IUser {
  name: string;
  id: number | null;
}

export interface IWorkSpaceState {
  updated: boolean;
  name: string;
  color: colorsAvailable;
  news: Array<NS_Workspace.INewsCommunicationDTO>;
  collections: Array<string>;
  mappings: Array<string>;
  templateCollections: Array<NS_API.ITemplateCollection>;
}

export enum colorsAvailable {
  PRIMARY = 'primary',
  RED = 'red',
  BLUE = 'neutralBlue',
  YELLOW = 'yellow',
  GREY = 'primaryTextGrey',
}

const initialState: IWorkSpaceState = {
  updated: false,
  name: 'Workspace',
  color: colorsAvailable.PRIMARY,
  news: [],
  // Filter report components
  collections: [],
  mappings: [],
  templateCollections: [],
};

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setUserColor: (state, action: PayloadAction<colorsAvailable>) => {
      state.color = action.payload;
    },
    resetWorkspaceName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setAppNews: (state, action: PayloadAction<Array<NS_Workspace.INewsCommunicationDTO>>) => {
      state.news = action.payload;
    },
    setUpdatedAction: (state, action: PayloadAction<boolean>) => {
      state.updated = action.payload;
    },
    setCollections: (state, action: PayloadAction<Array<string>>) => {
      state.collections = action.payload;
    },
    setMappings: (state, action: PayloadAction<Array<string>>) => {
      state.mappings = action.payload;
    },
    setTemplateCollections: (state, action: PayloadAction<Array<NS_API.ITemplateCollection>>) => {
      state.templateCollections = action.payload;
    },
    updateTemplateCollections: (state, action: PayloadAction<NS_API.ITemplateCollection>) => {
      state.templateCollections = [
        ...state.templateCollections.map((template) => {
          if (action.payload.collectionId === template.collectionId) {
            return action.payload;
          }
          return template;
        }),
      ];
    },
  },
});

export const {
  setUserColor,
  resetWorkspaceName,
  setAppNews,
  setUpdatedAction,
  setCollections,
  setMappings,
  setTemplateCollections,
  updateTemplateCollections,
} = workspaceSlice.actions;

export default workspaceSlice.reducer;
