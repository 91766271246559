// Style
import './style.scss';

import React, { useCallback, useEffect, useState, useMemo } from 'react';

// Tools
import { wrappedTextDiv, getAccountingLineValue, renderContent } from '../../../../../shared/helpers/helpers';
import { formatReportValue } from '../../../../../shared/helpers/report/reports.helper';
import { dataDestructuring } from '../../../../../shared/helpers/tree/front.tree.util';
import TreeHelper from '../../../../../shared/helpers/tree/TreeHelper';

interface IMapCell {
  header: NS_Table.IColumnHeader[];
  node: NS_TREE.ITreeRoot<string | undefined>; // Current line node
  currencyParams: NS_Table.ICurrency;
  selectedCells: Array<NS_TREE.ICellSelected>;
  colIndex: number;
  setSelectedCellsColumns: (val: number[]) => void;
  setSelectedCellsRowNodes: (val: NS_TREE.ITreeRootWithoutChild[]) => void;
  selectedCellsRowNodes: NS_TREE.ITreeRootWithoutChild[];
  selectedCellsColumns: number[];
  selectedCellsOutGroup: Array<NS_TREE.ICellSelected>;
  setSelectedCellsOutGroup: (val: Array<NS_TREE.ICellSelected>) => void;
  selectedCellsInGroup: Array<NS_TREE.ICellSelected>;
  shadowOpacity: number;
  groupDirection: string;
  isAccountLine?: boolean;
  rowIndex?: number;
  treeFromNodeToOpen?: NS_API.IGraph;
  mappingTreeWithRoot: NS_API.IGraph;
  parentOpen: Array<string>;
  fetchAccountingLine: boolean;
}

/**
 * Cell of the MapRow component
 * @param param IMapCell props
 */

const MapCell: React.FC<IMapCell> = ({
  header,
  node,
  currencyParams,
  selectedCells,
  colIndex,
  setSelectedCellsRowNodes,
  setSelectedCellsColumns,
  selectedCellsRowNodes,
  selectedCellsColumns,
  selectedCellsOutGroup,
  setSelectedCellsOutGroup,
  selectedCellsInGroup,
  shadowOpacity,
  groupDirection,
  isAccountLine,
  rowIndex,
  parentOpen,
  mappingTreeWithRoot,
  treeFromNodeToOpen,
  fetchAccountingLine,
}) => {
  const [lineNode, setLineNode] = useState<NS_TREE.ITreeRootWithoutChild>(node);

  const [index, setIndex] = useState<number>(-1);
  const [belowIndex, setBelowIndex] = useState<number>(-1);
  const [aboveIndex, setAboveIndex] = useState<number>(-1);
  const [leftIndex, setLeftIndex] = useState<number>(-1);
  const [rightIndex, setRightIndex] = useState<number>(-1);

  const [formatedValue, setFormatedValue] = useState<any>(0);
  const [currency, setCurrency] = useState<boolean>(false);

  const [belowCell, setBelowCell] = useState<NS_TREE.ICellSelected | null>();
  const [aboveCell, setAboveCell] = useState<NS_TREE.ICellSelected | null>();
  const [leftCell, setLeftCell] = useState<NS_TREE.ICellSelected | null>();
  const [rightCell, setRightCell] = useState<NS_TREE.ICellSelected | null>();

  // useEffect

  useEffect(() => {
    let newLineNode;
    if (isAccountLine && treeFromNodeToOpen && rowIndex !== undefined) {
      newLineNode = {
        id: node.id,
        data: dataDestructuring(node.data),
        accLineRowIndex: rowIndex,
        lastAccLineRow: rowIndex === 0 ? true : undefined,
        firstAccLineRow: rowIndex === node.children.length - 1 ? true : undefined,
      };
      newLineNode.data.content.value = treeFromNodeToOpen.children[rowIndex].data.content.value;
    }
    setLineNode(newLineNode ?? node);
  }, [node]);

  useEffect(() => {
    const tempIndex = findNeighberInSelectedCells({
      nodeId: node.id,
      nodeType: node.data.content.rowContentType,
      colIndex: colIndex,
    });
    setIndex(tempIndex);

    if (tempIndex !== -1) {
      setAboveIndex(findNeighberInSelectedCells(aboveCell));
      setBelowIndex(findNeighberInSelectedCells(belowCell));
      setLeftIndex(findNeighberInSelectedCells(leftCell));
      setRightIndex(findNeighberInSelectedCells(rightCell));
    }
  }, [selectedCells]);

  useEffect(() => {
    if (colIndex < header.length) {
      let colDataType;
      const column = header[colIndex];
      if (column) {
        colDataType = column.colParams.colDataType;
      }
      const numeric = colDataType.dataType === 'currency' || colDataType.dataType === 'number';

      setCurrency(colDataType.dataType === 'currency');

      //format the value
      if (numeric) {
        setFormatedValue(
          formatReportValue(
            getValue().value,
            lineNode.data.content.rowContentType,
            colDataType.dataType,
            colDataType.format,
            currencyParams.displayUnit,
          ),
        );
      } else if (lineNode.data.content.rowContentType === 'I' && column.Header === 'formula') {
        setFormatedValue(0);
      }
    }
  }, [colIndex, header, lineNode, currencyParams]);

  useEffect(() => {
    const treeTemp = mappingTreeWithRoot[0] as NS_TREE.ITreeRoot<undefined>;

    let slctdCell;
    if (isAccountLine && treeFromNodeToOpen) {
      slctdCell = {
        nodeId: node.id,
        nodeType: 'N',
        colIndex: colIndex,
        accLineRowIndex: rowIndex,
        lastAccLineRow: rowIndex === 0 ? true : undefined,
        firstAccLineRow: rowIndex === treeFromNodeToOpen.children.length - 1 ? true : undefined,
      };
    } else {
      slctdCell = {
        nodeId: node.id,
        nodeType: node.data.content.rowContentType,
        colIndex: colIndex,
      };
    }
    const newAbove = TreeHelper.findNextOrPreviousNode(treeTemp, dataDestructuring(slctdCell), -1, parentOpen);
    const newBelow = TreeHelper.findNextOrPreviousNode(treeTemp, dataDestructuring(slctdCell), 1, parentOpen);
    setAboveCell(newAbove);
    setBelowCell(newBelow);
  }, [parentOpen, fetchAccountingLine]);

  useEffect(() => {
    const newLeft = {
      nodeId: node.id,
      nodeType: node.data.content.rowContentType,
      value: '',
      colIndex: colIndex - 1,
    };

    const newRight = {
      nodeId: node.id,
      nodeType: node.data.content.rowContentType,
      value: '',
      colIndex: colIndex + 1,
    };

    setLeftCell(newLeft);
    setRightCell(newRight);
  }, []);

  // Callbacks

  const getValue = useCallback(() => {
    const column = header[colIndex];
    if (isAccountLine && rowIndex !== undefined) {
      return getAccountingLineValue(column, node.children[rowIndex].data.content.value[colIndex - 1]);
    } else {
      return TreeHelper.getNodeValue(column, lineNode.data.content);
    }
  }, [rowIndex, colIndex, header, isAccountLine, lineNode]);

  const findNeighberInSelectedCells = (cell) => {
    if (cell) {
      return selectedCells.findIndex(
        (el) => el.nodeId === cell.nodeId && el.nodeType === cell.nodeType && el.colIndex === cell.colIndex,
      );
    } else {
      return -1;
    }
  };

  const renderBorderClass = useMemo(() => {
    if (index === -1) {
      return '';
    }

    const beginLine = aboveIndex === -1 ? ' borderTop' : '';
    const endLine = belowIndex === -1 ? ' borderBottom' : '';
    const beginCol = leftIndex === -1 ? ' borderLeft' : '';
    const endCol = rightIndex === -1 ? ' borderRight' : '';

    return beginLine + endLine + beginCol + endCol;
  }, [index, aboveIndex, belowIndex, leftIndex, rightIndex]);

  const firstCalculatedCol = useMemo(() => {
    const previousCol = header[colIndex - 1];
    const column = header[colIndex];
    return previousCol && !previousCol.calculatedCol && column?.calculatedCol ? true : false;
  }, [header, colIndex]);

  const handleCellClick = (e) => {
    e.preventDefault();
    const newSelectedCells = dataDestructuring(selectedCells);
    // Cell is selected
    if (index !== -1) {
      if (e.ctrlKey) {
        const indexOutGroup = selectedCellsOutGroup.findIndex(
          (el) =>
            el.nodeId === lineNode.id &&
            el.nodeType === lineNode.data.content.rowContentType &&
            el.colIndex === colIndex,
        );
        if (indexOutGroup !== -1) {
          const newOutGroup = [...selectedCellsOutGroup];
          newOutGroup.splice(indexOutGroup, 1);
          setSelectedCellsOutGroup(newOutGroup);
        } else {
          separateSelectedCellsGroupWhenClick();
        }
      } else {
        setSelectedCellsOutGroup([]);
        setSelectedCellsRowNodes([]);
        setSelectedCellsColumns([]);
      }
    }
    // Cell is not selected
    else {
      if (e.ctrlKey) {
        setSelectedCellsOutGroup(newSelectedCells);
        setSelectedCellsRowNodes([lineNode]);
        setSelectedCellsColumns([colIndex]);
      } else {
        setSelectedCellsOutGroup([]);
        setSelectedCellsRowNodes([lineNode]);
        setSelectedCellsColumns([colIndex]);
      }
    }
  };

  const separateSelectedCellsGroupWhenClick = () => {
    const newOutGroup = [...selectedCellsOutGroup];

    const indexInGroup = selectedCellsInGroup.findIndex(
      (el) =>
        el.nodeId === lineNode.id && el.nodeType === lineNode.data.content.rowContentType && el.colIndex === colIndex,
    );

    if (indexInGroup !== -1) {
      const nodeRowIndex = selectedCellsRowNodes.findIndex((row) => row.id === lineNode.id);
      if (
        (nodeRowIndex === 0 && groupDirection === 'down') ||
        (nodeRowIndex === selectedCellsRowNodes.length - 1 && groupDirection === 'up') ||
        selectedCellsRowNodes.length === 1
      ) {
        const newRows = [selectedCellsRowNodes[nodeRowIndex]];
        const separatedCells = selectedCellsInGroup.filter(
          (cell) => cell.nodeId !== selectedCellsRowNodes[nodeRowIndex].id || cell.colIndex > colIndex,
        );
        setSelectedCellsOutGroup(newOutGroup.concat(separatedCells));

        // update in group
        setSelectedCellsColumns(selectedCellsColumns.filter((col) => col < colIndex));
        setSelectedCellsRowNodes(newRows);
      } else {
        const newRows =
          groupDirection === 'up'
            ? selectedCellsRowNodes.slice(nodeRowIndex + 1)
            : selectedCellsRowNodes.slice(0, nodeRowIndex);
        const tmpSelectedCellsInGroup = [...selectedCellsInGroup];
        tmpSelectedCellsInGroup.splice(indexInGroup, 1);
        const separatedCells = tmpSelectedCellsInGroup.filter((cell) => {
          return newRows.findIndex((row) => row.id === cell.nodeId) === -1;
        });
        setSelectedCellsOutGroup(newOutGroup.concat(separatedCells));
        // update in group
        setSelectedCellsRowNodes(newRows);
      }
    }
  };

  return (
    <div
      onClick={handleCellClick}
      onContextMenu={handleCellClick}
      className={`${renderBorderClass} cell ${renderContent(firstCalculatedCol, 'dividingLeftBorder', '')}`}
      style={{
        color: 'inherit',
        boxShadow: renderContent(index !== -1, `inset 0 3em rgba(0, 0, 0, ${shadowOpacity})`, ''),
      }}>
      {wrappedTextDiv(
        { width: '100%', color: 'inherit' },
        formatedValue ? formatedValue : '-',
        false,
        false,
        formatedValue && currency ? currencyParams.format : undefined,
      )}
    </div>
  );
};

export default React.memo(MapCell);
