// Style
import './style.scss';

import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { ReactSVG } from 'react-svg';

import DownSVG from '../../assets/img/arrow-down.svg';
import closeIcon from '../../assets/img/cross-red.svg';
import MagnifyingGlassSVG from '../../assets/img/search.svg';
import { renderContent } from '../../shared/helpers/helpers';

interface ISearchComponent {
  /**
   * Display close icon?
   */
  hasCloseIcon: boolean;
  setOpenInput?: (val: boolean) => void;
  setSearchValue: (val: string) => void;
  showResult?: number;
  setShowResult?: (val: number) => void;
  /**
   * Array of result found.
   */
  searchResults?: string[];
  /**
   * Search input value.
   */
  searchValue: string;
  noShowResult?: boolean;
  /**
   * Search component contaienr reference element
   */
  searchBarRef?: React.RefObject<HTMLDivElement>;
}

/**
 * Container used to display the data part of top menu in a report
 */
const SearchComponent: React.FC<ISearchComponent> = ({
  hasCloseIcon,
  setSearchValue,
  showResult,
  setShowResult,
  searchResults,
  setOpenInput,
  searchValue,
  noShowResult,
  searchBarRef,
}) => {
  // Function
  const previous = useCallback(() => {
    if (showResult !== undefined && searchResults && setShowResult) {
      if (showResult > 0) {
        setShowResult(showResult - 1);
      } else {
        setShowResult(searchResults.length - 1);
      }
    }
  }, [showResult, searchResults]);

  const next = useCallback(() => {
    if (showResult !== undefined && showResult > -1 && searchResults && setShowResult) {
      if (showResult + 1 < searchResults.length) {
        setShowResult(showResult + 1);
      } else {
        setShowResult(0);
      }
    }
  }, [showResult, searchResults]);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      next();
    }
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const debouncedHandleChange = useMemo(() => {
    return debounce(handleChange, 500);
  }, []);

  useEffect(() => {
    return () => {
      debouncedHandleChange.cancel();
    };
  });

  const handleMagnifying = useCallback(() => {
    setOpenInput?.(true);
  }, []);

  return (
    <div
      className={`searchLine ${renderContent(hasCloseIcon, '', 'searchLineNotFixed')}`}
      ref={searchBarRef}
      data-cy="search">
      {hasCloseIcon && (
        <img
          src={closeIcon}
          data-cy="close-search"
          alt="close icon"
          onClick={() => {
            setOpenInput?.(false);
          }}
        />
      )}
      <div className="inputContainer">
        <input
          type="text"
          className="input"
          id="searchInput"
          onChange={debouncedHandleChange}
          placeholder="Type your search here..."
          onKeyDown={handleKeyDown}
        />
        <ReactSVG src={MagnifyingGlassSVG} className="svg-wrapper magnifyingGlassSVG" onClick={handleMagnifying} />
      </div>
      {!noShowResult && searchResults && searchResults.length <= 0 && (
        <div className={`results noResults ${searchValue.length === 0 && 'notSearching'}`}>
          {renderContent(searchValue.length === 0, '', 'No results')}
        </div>
      )}
      {showResult !== undefined && showResult > -1 && searchResults && searchResults.length > 0 && (
        <div className={`results`}>{`${showResult + 1} of ${searchResults.length}`}</div>
      )}
      {!noShowResult && (
        <div className={`arrowContainer ${showResult && showResult <= -1 && 'notFullOpacity'}`}>
          <ReactSVG src={DownSVG} className="svg-wrapper upSVG" onClick={previous} />
          <ReactSVG src={DownSVG} className="svg-wrapper downSVG" onClick={next} />
        </div>
      )}
    </div>
  );
};

export default SearchComponent;
