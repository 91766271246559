import logo from '../../../assets/img/logo-scorf-background-green.svg';
import TreeHelper from '../tree/TreeHelper';

export default class ChartHelper {
  static getFileName(azurePath: string): string {
    const splitted = azurePath.split('/');
    return splitted[splitted.length - 1];
  }

  static generateBSChartData(
    treeRoot: NS_TREE.ITreeRoot<undefined | string>,
    coeff: number,
    typeOfGraph: string,
    columnsValueNames: string[],
    colorOptions: NS_Workspace.IColorOptions,
  ) {
    /*
           CON1070 => BFR hors exploitation, CON1060 => BFR D'exploitation, CON1033=> Immobilisations incorporelles,
           CON1035=> Immobilisations scorporelles, CON1039=> Immobilisations en cours, CON1045=> Immobilisations financières,
           CON1059=> Fournisseurss et comptes rattachés, CON1058=> Clients et comptes rattachés, CON1046=> Actif immobilisé,
           CON1084=> Tresorerie et equivalents de tresorerie, CON1088 => Dette financiere, CON1090=> Actif net, CON1056=> Stock
        */
    const treeNodes = TreeHelper.findTreeNodesByRowContentIds(treeRoot[1], [
      'CON1070',
      'CON1060',
      'CON1033',
      'CON1035',
      'CON1039',
      'CON1045',
      'CON1059',
      'CON1058',
      'CON1046',
      'CON1070',
      'CON1084',
      'CON1088',
      'CON1090',
      'CON1056',
    ]);
    let newChartData: Array<NS_REPORT.IChartData> = [];
    // Bar chart data
    if (typeOfGraph === 'Bar Chart' && treeNodes['CON1070'] && treeNodes['CON1060']) {
      newChartData = treeNodes['CON1070'].data.content.value.slice(0, columnsValueNames.length).map((value, index) => {
        return {
          label: columnsValueNames[index],
          value: Math.abs(value) / coeff,
          secondSerieValue: Math.abs(treeNodes['CON1060'].data.content.value[index]) / coeff,
        };
      });
    }
    // Year Pie actif immobilisé
    else if (typeOfGraph === 'Actif immobilisé') {
      newChartData = [treeNodes['CON1033'], treeNodes['CON1035'], treeNodes['CON1039'], treeNodes['CON1045']]
        .filter((node) => node !== undefined)
        .map((node, index) => {
          return {
            label: node.data.content.rowContentName,
            value: node.data.content.value[1] / coeff,
            color: colorOptions.chartColors[index].slice(0, 7),
          };
        });
    }
    // Year Pie chart bfr d'exploitation
    else if (typeOfGraph === "BFR d'exploitation") {
      newChartData = [treeNodes['CON1058'], treeNodes['CON1059'], treeNodes['CON1056']]
        .filter((node) => node !== undefined)
        .map((node, index) => {
          return {
            label: node.data.content.rowContentName,
            value: node.data.content.value[1] / coeff,
            color: colorOptions.chartColors[index].slice(0, 7),
          };
        });
    }
    // Year Pie chart actif net
    else if (typeOfGraph === 'Actif net' && treeNodes['CON1090']) {
      let sum = 0;
      newChartData = [
        treeNodes['CON1046'],
        treeNodes['CON1060'],
        treeNodes['CON1070'],
        treeNodes['CON1084'],
        treeNodes['CON1088'],
      ]
        .filter((node) => node !== undefined)
        .map((node, index) => {
          sum += node.data.content.value[1];
          return {
            label: node.data.content.rowContentName,
            value: node.data.content.value[1] / coeff,
            color: colorOptions.chartColors[index].slice(0, 7),
          };
        });
      if (treeNodes['CON1090'].data.content.value[1] !== sum) {
        newChartData.push({
          label: 'Autres',
          value: (treeNodes['CON1090'].data.content.value[1] - sum) / coeff,
        });
      }
    }

    return newChartData;
  }

  static generateBSChartConfig(
    treeRoot: NS_TREE.ITreeRoot<undefined | string>,
    coeff: number,
    columnsValueNames: string[],
    selectedUnit: string,
    typeOfGraph: string,
    colorOptions: NS_Workspace.IColorOptions,
  ) {
    const chartData = ChartHelper.generateBSChartData(treeRoot, coeff, typeOfGraph, columnsValueNames, colorOptions);
    const basicConfig = {
      type: 'column2d',
      width: '100%',
      height: '500',
      dataFormat: 'json',
      dataSource: {
        chart: {
          bgImage: logo,
          bgImageDisplayMode: 'center',
          bgImacgeSale: 70,
          formatNumberScale: 0,
          numberSuffix: selectedUnit,
          sNumberSuffix: selectedUnit,
          theme: 'fusion',
          caption: typeOfGraph,
        } as any,
        data: chartData,
      } as any,
    };
    if (typeOfGraph === 'Bar Chart') {
      basicConfig.type = 'stackedcolumn2d';
      basicConfig.dataSource = {
        ...basicConfig.dataSource,
        categories: [{ category: chartData.map((info) => ({ label: info.label })) }],
        dataSet: [
          {
            seriesName: 'BFRHE',
            data: chartData.map((info) => ({ value: info.value })),
            color: colorOptions.chartColors[0].slice(0, 7),
          },
          {
            seriesName: 'BFRE',
            data: chartData.map((info) => ({ value: info.secondSerieValue })),
            color: colorOptions.chartColors[1].slice(0, 7),
          },
        ],
      };
    } else if (
      typeOfGraph === 'Actif immobilisé' ||
      typeOfGraph === "BFR d'exploitation" ||
      typeOfGraph === 'Actif net'
    ) {
      basicConfig.type = 'doughnut2d';
      basicConfig.dataSource.chart = {
        ...basicConfig.dataSource.chart,
        legendPosition: 'right',
        legendItemFontSize: '13',
        showLabels: '0',
        valuePosition: 'inside',
      };
    }
    return basicConfig;
  }

  static generatePLChartData(
    treeRoot: NS_TREE.ITreeRoot<undefined | string>,
    coeff: number,
    typeOfGraph: string,
    columnsValueNames: string[],
    colorOptions: NS_Workspace.IColorOptions,
  ) {
    /*
           CON1002 => , CON1009 => achats et charges externes, CON1010=> Impôt taxes et versements assimilés,
           CON1013=> Charges de personnel, CON1018=> Autres produits et charges, CON1005=> Produits d'exploitation
           CON1019=> EBITA, CON1021=>EBIT, CON1031=> Resultat net
        */
    const treeNodes = TreeHelper.findTreeNodesByRowContentIds(treeRoot[0], [
      'CON1002',
      'CON1009',
      'CON1010',
      'CON1013',
      'CON1018',
      'CON1005',
      'CON1019',
      'CON1021',
      'CON1031',
    ]);
    let newChartData: Array<NS_REPORT.IChartData> = [];
    // Bar chart data
    if ((typeOfGraph === 'Bar Chart' || typeOfGraph === "Chiffre d'affaire") && treeNodes['CON1002']) {
      newChartData = treeNodes['CON1002'].data.content.value.slice(0, columnsValueNames.length).map((value, index) => {
        return {
          label: columnsValueNames[index],
          value: value / coeff,
          color: colorOptions.chartColors[0].slice(0, 7),
        };
      });
    }
    // Year Pie chart data
    else if (typeOfGraph === 'Pie Chart') {
      newChartData = [treeNodes['CON1009'], treeNodes['CON1010'], treeNodes['CON1013'], treeNodes['CON1018']]
        .filter((node) => node !== undefined)
        .map((node, index) => {
          return {
            label: node.data.content.rowContentName,
            value: node.data.content.value[0] / coeff,
            color: colorOptions.chartColors[index].slice(0, 7),
          };
        });
    }
    // Year Funnel chart
    else if (
      typeOfGraph === 'Funnel Chart' &&
      treeNodes['CON1005'] &&
      treeNodes['CON1019'] &&
      treeNodes['CON1021'] &&
      treeNodes['CON1031']
    ) {
      newChartData = [treeNodes['CON1005'], treeNodes['CON1019'], treeNodes['CON1021'], treeNodes['CON1031']].map(
        (node, index) => {
          return {
            label: node.data.content.rowContentName,
            value: node.data.content.value[0] / coeff,
            color: colorOptions.chartColors[index].slice(0, 7),
          };
        },
      );
    }
    // Flow 1
    else if (typeOfGraph === 'Chart combiné' && treeNodes['CON1005'] && treeNodes['CON1019']) {
      let ebitdaCumulValue = 0;
      newChartData = treeNodes['CON1005'].data.content.value.slice(0, columnsValueNames.length).map((value, index) => {
        ebitdaCumulValue += treeNodes['CON1019'].data.content.value[index];
        return {
          label: columnsValueNames[index],
          value: value / coeff,
          secondSerieValue: Math.abs(treeNodes['CON1019'].data.content.value[index] - value) / coeff,
          thirdSerieValue: ebitdaCumulValue / coeff,
        };
      });
    }
    // Flow 2
    else if (typeOfGraph === "Produits d'exploitation" && treeNodes['CON1005']) {
      newChartData = treeNodes['CON1005'].data.content.value.slice(0, columnsValueNames.length).map((value, index) => {
        return {
          label: columnsValueNames[index],
          value: value / coeff,
        };
      });
    }
    // Flow 3
    else if (typeOfGraph === "charges d'exploitation" && treeNodes['CON1005'] && treeNodes['CON1019']) {
      newChartData = treeNodes['CON1005'].data.content.value.slice(0, columnsValueNames.length).map((value, index) => {
        return {
          label: columnsValueNames[index],
          value: Math.abs(treeNodes['CON1019'].data.content.value[index] - value) / coeff,
        };
      });
    }

    return newChartData;
  }

  static generatePLChartConfig(
    treeRoot: NS_TREE.ITreeRoot<undefined | string>,
    coeff: number,
    columnsValueNames: string[],
    selectedUnit: string,
    typeOfGraph: string,
    colorOptions: NS_Workspace.IColorOptions,
  ) {
    const chartData = ChartHelper.generatePLChartData(treeRoot, coeff, typeOfGraph, columnsValueNames, colorOptions);
    const basicConfig = {
      type: 'column2d',
      width: '100%',
      height: '500',
      dataFormat: 'json',
      dataSource: {
        chart: {
          bgImage: logo,
          bgImageDisplayMode: 'center',
          bgImacgeSale: 70,
          formatNumberScale: 0,
          numberSuffix: selectedUnit,
          sNumberSuffix: selectedUnit,
          theme: 'fusion',
        } as any,
        data: chartData,
      } as any,
    };
    if (typeOfGraph === 'Bar Chart' || typeOfGraph === "Chiffre d'affaire") {
      // Bar chart
      basicConfig.dataSource.chart = {
        ...basicConfig.dataSource.chart,
        caption: "Chiffre d'affaires",
      };
      basicConfig.type = 'column2d';
    } else if (typeOfGraph === 'Pie Chart') {
      // Pie chart
      basicConfig.type = 'doughnut2d';
      basicConfig.dataSource.chart = {
        ...basicConfig.dataSource.chart,
        legendPosition: 'right',
        caption: "Charges d'exploitation",
        valuePosition: 'inside',
        showLabels: '0',
        //minAngleForValue : "10"
      };
    } else if (typeOfGraph === 'Funnel Chart') {
      // Funnel chart
      basicConfig.type = 'funnel';
      basicConfig.dataSource.chart = {
        ...basicConfig.dataSource.chart,
        is2D: '1',
        showValues: '1',
        showLegend: '1',
        legendPosition: 'left',
        streamlineddata: '0',
        showLabelsAtCenter: '1',
        showLabels: '0',
      };
    }
    // Flow chart 1
    else if (typeOfGraph === 'Chart combiné') {
      const averageProduits = Math.ceil(chartData.reduce<number>((pre, cur) => pre + cur.value, 0) / chartData.length);
      const averageCharges = Math.ceil(
        chartData.reduce<number>((pre, cur) => pre + (cur.secondSerieValue ?? 0), 0) / chartData.length,
      );
      delete basicConfig.dataSource.data;
      basicConfig.type = 'mscombidy2d';
      basicConfig.dataSource = {
        ...basicConfig.dataSource,
        categories: [{ category: chartData.map((info) => ({ label: info.label })).concat([{ label: 'Avg' }]) }],
        dataset: [
          {
            seriesName: "Produits d'exploitation",
            data: chartData
              .map((info) => ({ value: info.value }))
              .concat([{ value: averageProduits, showValue: '1' } as any]),
            color: colorOptions.chartColors[0].slice(0, 7),
          },
          {
            seriesName: "Charges d'exploitation",
            data: chartData
              .map((info) => ({ value: info.secondSerieValue }))
              .concat([{ value: averageCharges, showValue: '1' } as any]),
            color: colorOptions.chartColors[1].slice(0, 7),
          },
          {
            seriesName: 'EBITDA cumulé',
            renderAs: 'line',
            parentYAxis: 'S',
            data: chartData.map((info, index) => ({
              value: info.thirdSerieValue,
              showValue: index === 0 || index === chartData.length - 1 ? '1' : '0',
            })),
            color: colorOptions.chartColors[2].slice(0, 7),
          },
        ],
      };
    }
    // Flow chart 2/3
    else if (typeOfGraph === "Produits d'exploitation" || typeOfGraph === "charges d'exploitation") {
      const average = Math.ceil(chartData.reduce<number>((pre, cur) => pre + cur.value, 0) / chartData.length);
      delete basicConfig.dataSource.data;
      basicConfig.type = 'mscolumn2d';
      basicConfig.dataSource = {
        ...basicConfig.dataSource,
        categories: [{ category: chartData.map((info) => ({ label: info.label })) }],
        dataset: [
          {
            seriesName: typeOfGraph,
            data: chartData.map((info) => ({ value: info.value })),
            color: colorOptions.chartColors[0].slice(0, 7),
          },
        ],
        chart: {
          ...basicConfig.dataSource.chart,
          showValues: '1',
          placeValuesInside: '1',
          rotateValues: '1',
        },
        trendlines: [
          {
            line: [
              {
                startvalue: average,
                color: colorOptions.chartColors[1].slice(0, 7),
                displayValue: `Average: ${average}${selectedUnit}`,
                valueOnRight: '1',
                thickness: '1',
                dashed: '1',
                showOnTop: '1',
              },
            ],
          },
        ],
      };
    }

    return basicConfig;
  }

  static generateCBChartData(
    treeRoot: NS_TREE.ITreeRoot<undefined | string>,
    coeff: number,
    typeOfGraph: string,
    columnsValueNames: string[],
    cumulDisplay: boolean,
  ) {
    const charData: Array<NS_REPORT.IChartData> = [];
    let lineValues: Array<number> = [];
    let average = 0;

    if (cumulDisplay) {
      lineValues = treeRoot[0].data.content.value;
    } else {
      const valuesToSum: number[] = [];
      treeRoot[0].data.content.value.forEach((value, index) => {
        if (index === 0) {
          valuesToSum.push(value);
        } else {
          valuesToSum.push(value + valuesToSum[index - 1]);
        }
      });
      lineValues = valuesToSum;
    }

    if (typeOfGraph === 'Line Chart') {
      const tmpAverage = Math.ceil(lineValues.reduce((a, b) => a + b, 0) / lineValues.length);
      lineValues.slice(0, columnsValueNames.length).forEach((value, index) => {
        charData.push({ label: columnsValueNames[index], value: value / coeff });
      });
      average = tmpAverage / coeff;
    } else if (typeOfGraph === 'Bar Chart') {
      treeRoot[0].children[0].data.content.value.slice(0, columnsValueNames.length).forEach((value, index) => {
        charData.push({
          label: columnsValueNames[index],
          value: value / -coeff,
          average: treeRoot[0].children[1].data.content.value[index] / coeff,
        });
      });
    }

    return { charData, average };
  }

  static generateCBChartConfig(
    treeRoot: NS_TREE.ITreeRoot<undefined | string>,
    cumulDisplay: boolean,
    coeff: number,
    columnsValueNames: string[],
    selectedUnit: string,
    typeOfGraph: string,
    colorOptions: NS_Workspace.IColorOptions,
  ) {
    const { charData, average } = ChartHelper.generateCBChartData(
      treeRoot,
      coeff,
      typeOfGraph,
      columnsValueNames,
      cumulDisplay,
    );
    // Line chart
    if (typeOfGraph === 'Line Chart') {
      return {
        type: 'spline',
        width: '100%',
        height: '500',
        dataFormat: 'json',
        dataSource: {
          chart: {
            bgImage: logo,
            bgImageDisplayMode: 'center',
            bgImageScale: 70,
            lineColor: colorOptions.chartColors[0].slice(0, 7),
            formatNumberScale: 0,
            numberSuffix: selectedUnit,
            theme: 'fusion',
            anchorBgColor: colorOptions.chartColors[0].slice(0, 7),
          },
          data: charData,
          trendlines: [
            {
              line: [
                {
                  startvalue: average,
                  color: colorOptions.chartColors[1].slice(0, 7),
                  displayvalue: `Average: ${average}`,
                  valueOnRight: 1,
                  thickness: 1,
                  dashed: '1',
                },
              ],
            },
          ],
        },
      };
    } else {
      // Bar chart
      return {
        type: 'mscolumn2d',
        width: '100%',
        height: '500',
        dataFormat: 'json',
        dataSource: {
          chart: {
            bgImage: logo,
            bgImageDisplayMode: 'center',
            bgImageScale: 70,
            formatNumberScale: 0,
            numberSuffix: selectedUnit,
            theme: 'fusion',
            showValues: 1,
            placeValuesInside: 1,
            rotateValues: 1,
            showLegend: 1,
          },
          categories: [{ category: charData.map((info) => ({ label: info.label })) }],
          dataset: [
            {
              seriesName: 'Average',
              data: charData.map((info) => ({ value: info.average })),
              color: colorOptions.chartColors[0].slice(0, 7),
            },
            {
              seriesName: 'Cash',
              data: charData.map((info) => ({ value: info.value })),
              color: colorOptions.chartColors[1].slice(0, 7),
            },
          ],
        },
      };
    }
  }

  static generateCFChartData(
    treeRoot: NS_TREE.ITreeRoot<undefined | string>,
    coeff: number,
    columnsValueNames: string[],
  ) {
    const treeNode = TreeHelper.findTreeNodeByRowContentId(treeRoot[0], 'CON1108');
    const newChartData: Array<NS_REPORT.IChartData> = [];
    if (treeNode) {
      treeNode.data.content.value.slice(0, columnsValueNames.length).forEach((value, index) => {
        newChartData.push({ label: columnsValueNames[index], value: value / coeff });
      });
    }
    return newChartData;
  }

  static generateCFChartConfig(
    treeRoot: NS_TREE.ITreeRoot<undefined | string>,
    coeff: number,
    columnsValueNames: string[],
    selectedUnit: string,
    colorOptions: NS_Workspace.IColorOptions,
  ) {
    const chartData = ChartHelper.generateCFChartData(treeRoot, coeff, columnsValueNames);

    return {
      type: 'spline',
      width: '100%',
      height: '500',
      dataFormat: 'json',
      dataSource: {
        chart: {
          bgImage: logo,
          bgImageDisplayMode: 'center',
          bgImageScale: 70,
          lineColor: colorOptions.chartColors[0].slice(0, 7),
          formatNumberScale: 0,
          numberSuffix: selectedUnit,
          theme: 'fusion',
          anchorBgColor: colorOptions.chartColors[0].slice(0, 7),
        },
        data: chartData,
      },
    };
  }
}
