// Style
import './style.scss';

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { WorkspaceContext } from '../../../../context/WorkspaceContext';
import ExplanationBox from '../../../../elements/ExplanationBox';
import ReportSelectList from '../../../../elements/ReportSelectList';
import { useAppDispatch, useAppSelector } from '../../../../redux/hook';
import { setCollections, setMappings } from '../../../../redux/workspace';
import { getAllTemplateCollectionsForWorkspace } from '../../../../shared/api/templateCollection';
import {
  DefaultTemplateCollectionName,
  REPORT_TEMPLATE,
  TemplateKey,
} from '../../../../shared/constant/datasource.consts';
import { compareLowerCase } from '../../../../utils/common';
import { COLLECTION_TYPE, REPORT_LIST_TYPE } from '../../../../utils/enum';
import { getUniqueListBy } from '../../../../utils/getUniqueListBy';

interface IDataPartMenu {
  selectedDataSource: string;
  templateList?: Object;
  selectedTemplate: string;
  setSelectedTemplate: React.Dispatch<React.SetStateAction<string>>;
  fetchingTemplateList: boolean;
  templateGroups?: { 1: string; 2: string };
  worksheetId: string;
  setSelectedDataSource: React.Dispatch<React.SetStateAction<string>>;
  getTemplateList: (graphId: string | undefined) => void;
  selectedDataSourceGraph: string;
  setSelectedDataSourceGraph: React.Dispatch<React.SetStateAction<string>>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setGraphName: React.Dispatch<React.SetStateAction<string>>;
  disabled?: boolean;
}

const explanations = [
  {
    title: 'Datasource',
    description: 'Select one Data source among those already interpreted.',
    posX: '0px',
  },
  {
    title: 'Template Collection',
    description: 'Select a Template Collection to draw templates from.',
    posX: '186px',
  },
  {
    title: 'Template',
    description: 'Choose the template you want to generate.',
    posX: '374px',
  },
];

/**
 * Container used to display the data part of top menu in a report
 *
 * @param param NS_Tabs.ITab props
 *
 */

const DataPartMenu: React.FC<IDataPartMenu> = ({
  selectedDataSource,
  setSelectedDataSource,
  templateList,
  selectedTemplate,
  setSelectedTemplate,
  fetchingTemplateList,
  templateGroups,
  worksheetId,
  getTemplateList,
  selectedDataSourceGraph,
  setSelectedDataSourceGraph,
  setIsEditing,
  setGraphName,
  disabled = false,
}) => {
  const dispatch = useAppDispatch();
  const { collections, mappings } = useAppSelector((state) => state.workspace);
  //Refs
  const hoverTimer: { current: NodeJS.Timeout | null } = useRef(null);
  // Context
  const { generatedDataSources, loadingTabs, workspaceId } = useContext(WorkspaceContext);
  //States
  const [explanationSelected, setExplanationSelected] = useState<number>(-1);
  const [graphIds, setGraphIds] = useState<Array<string>>([]);
  const [collectionIds, setCollectionIds] = useState<Array<string>>([]);

  const [IsDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);

  const [groupedDatasourceElements, setGroupedDatasourceElements] = useState<NS_REPORT.IGroupedSelectList>({});
  const [groupedTemplateElements, setGroupedTemplateElements] = useState<NS_REPORT.IGroupedSelectList>({});
  const init_collections = {
    scorf: [],
    customers: [],
  };
  const [groupedCollectionElements, setGroupedCollectionElements] = useState<NS_REPORT.ITypeCollections>({
    ...init_collections,
  });
  // Effects
  useEffect(() => {
    const newMappings = [] as Array<string>;
    const newCollection = [] as Array<string>;
    let newCollectionItems = { ...init_collections } as NS_REPORT.ITypeCollections;
    const newName = [] as Array<string>;
    getAllTemplateCollectionsForWorkspace(workspaceId)
      .then((res) => {
        if (res) {
          res.data.forEach((template) => {
            const collectionItem = {
              id: template.collectionId,
              name: template.collectionName,
            };
            if (template.customerId === COLLECTION_TYPE.SCORF) {
              newCollectionItems = {
                ...newCollectionItems,
                scorf: [...newCollectionItems.scorf, collectionItem],
              };
            } else {
              newCollectionItems = {
                ...newCollectionItems,
                customers: [...newCollectionItems.customers, collectionItem],
              };
            }
            newCollection.push(template.collectionId);
            newMappings.push(template.graphId);
            newName.push(template.collectionName);
          });
          setGraphIds(newMappings);
          setCollectionIds(newCollection);
          setGroupedCollectionElements(newCollectionItems);
          dispatch(setCollections(newName));
          dispatch(setMappings(newMappings));
          setGraphName(newName[newMappings.findIndex((x) => x === selectedDataSourceGraph)]);
        }
      })
      .catch((err) => {});
    getTemplateList(selectedDataSourceGraph);
    // eslint-disable-next-line
  }, [selectedDataSourceGraph]);

  useEffect(() => {
    if (templateGroups) {
      groupTemplatesList(templateGroups);
    }
  }, [templateList, templateGroups, selectedDataSource]);

  useEffect(() => {
    groupDatasourcesList();
  }, [generatedDataSources]);

  // Function
  const groupTemplatesList = (groups: { 1: string; 2: string }) => {
    if (templateList) {
      const groupedTemplates = {};
      const currentDataSource = generatedDataSources.find((el) => el.fileId === selectedDataSource);
      // FIX ME : COMPLEXE LOGICAL IN UNION DATESOURCE
      const isUnionDataSource = !!currentDataSource?.unionInfo;
      Object.entries(groups).forEach(([groupIndex, groupName]) => {
        const templateElements = Object.entries(templateList)
          .filter(([_, template]) => template.groupId === parseInt(groupIndex))
          .map(([key, template]) => {
            const item = { id: key, name: template.name };
            if (
              isUnionDataSource &&
              ((key === TemplateKey.CS && compareLowerCase(template.name, REPORT_TEMPLATE.SALES_BY_CLIENT)) ||
                (key === TemplateKey.PP && compareLowerCase(template.name, REPORT_TEMPLATE.PURCHASES_BY_SUPPLIER)) ||
                false) //key === TemplateKey.CHR1
            )
              return { ...item, isDisable: true };
            return item;
          });
        groupedTemplates[groupName] = templateElements;
      });
      setGroupedTemplateElements(groupedTemplates);
    }
  };
  const groupDatasourcesList = () => {
    const groupedDatasources = {};
    const genericDatasources: { id: string; name: string }[] = [];
    const normalDatasources: { id: string; name: string }[] = [];
    for (const datasource of generatedDataSources) {
      if (datasource['sourceFile'] === 'Scorf_Generic_Datasource') {
        genericDatasources.push({ id: datasource.fileId, name: datasource.displayName });
      } else {
        normalDatasources.push({ id: datasource.fileId, name: datasource.displayName });
      }
    }
    // INFO: DIRTY PATCH, AKA APPLYING FLEX TAPE ON LEAKING WATER TANK, app needs to have writing handling
    const normalDatasourcesUnique = getUniqueListBy(normalDatasources, 'id');

    groupedDatasources['Imported'] = normalDatasourcesUnique;
    groupedDatasources['Generic'] = genericDatasources;

    setGroupedDatasourceElements(groupedDatasources);
  };

  const setSelectedDatasourceItem = useCallback(
    (datasourceId) => {
      setSelectedDataSource(datasourceId);
      const defaultGraphIndex = collections.indexOf(DefaultTemplateCollectionName);
      setSelectedDataSourceGraph(graphIds[defaultGraphIndex]);
    },
    [collections, graphIds],
  );

  const handleSelectTemplate = useCallback((id: string) => {
    setSelectedTemplate(id);
    setIsEditing(true);
  }, []);

  const renderTemplateList = () => {
    const templateName =
      selectedTemplate && templateList && templateList[selectedTemplate] && templateList[selectedTemplate].name
        ? templateList[selectedTemplate].name
        : '';

    return (
      <ReportSelectList
        selectedItemName={templateName}
        setSelectedItem={handleSelectTemplate}
        isFetching={fetchingTemplateList}
        disabled={
          loadingTabs.includes(worksheetId) || selectedDataSource === '' || selectedDataSourceGraph === '' || disabled
        }
        setIsDropDownOpen={setIsDropDownOpen}
        groupedElements={groupedTemplateElements}
        placeholder={'Select a template'}
        type={REPORT_LIST_TYPE.TEMPLATE}
      />
    );
  };

  const renderCollections = () => {
    const _selectedTCIndex = mappings?.length ? mappings.findIndex((x) => x === selectedDataSourceGraph) : -1;
    const collectionName = collections?.length && collections[_selectedTCIndex] ? collections[_selectedTCIndex] : '';
    return (
      <ReportSelectList
        selectedItemName={collectionName}
        setSelectedItem={setCollectionItem}
        isFetching={false}
        disabled={loadingTabs.includes(worksheetId) || selectedDataSource === '' || disabled}
        setIsDropDownOpen={setIsDropDownOpen}
        groupedElements={{}}
        placeholder={'Select a collection'}
        type={REPORT_LIST_TYPE.COLLECTION}
        collections={groupedCollectionElements}
      />
    );
  };
  const setCollectionItem = (collectionId) => {
    const index = collectionIds.findIndex((c) => {
      return c === collectionId;
    });
    if (index >= graphIds.length) {
      setSelectedDataSourceGraph('');
    } else {
      setSelectedDataSourceGraph(graphIds[index]);
    }
    setSelectedTemplate('');
  };
  const renderDatasourcesList = () => {
    const datasourceIndex = generatedDataSources.findIndex((el) => el.fileId === selectedDataSource);
    return (
      <ReportSelectList
        selectedItemName={generatedDataSources[datasourceIndex]?.displayName || ''}
        setSelectedItem={setSelectedDatasourceItem}
        isFetching={false}
        disabled={loadingTabs.includes(worksheetId) || disabled}
        setIsDropDownOpen={setIsDropDownOpen}
        groupedElements={groupedDatasourceElements}
        placeholder={'Select a data source'}
      />
    );
  };

  const handleInputHovered = (index: number) => {
    if (!IsDropDownOpen) {
      hoverTimer.current = setTimeout(() => {
        setExplanationSelected(index);
      }, 1000);
    }
  };

  const handleInputNotHovered = () => {
    if (hoverTimer.current) {
      clearTimeout(hoverTimer.current);
      setExplanationSelected(-1);
    }
  };

  return (
    <div className="dataListContainer">
      {explanationSelected > -1 && (
        <ExplanationBox
          title={explanations[explanationSelected].title}
          description={explanations[explanationSelected].description}
          posX={explanations[explanationSelected].posX}
        />
      )}

      <div
        className="subItem subItemFirst"
        onMouseEnter={(e) => handleInputHovered(0)}
        onMouseLeave={() => handleInputNotHovered()}
        onClick={() => handleInputNotHovered()}>
        <div className={`smallTitle ${loadingTabs.includes(worksheetId) && 'disabled'}`}>Data sources</div>
        {renderDatasourcesList()}
      </div>
      <div
        className={`subItem subItemFirst ${
          !loadingTabs.includes(worksheetId) || (selectedDataSource !== '' && 'noHover')
        }`}
        onMouseEnter={(e) => handleInputHovered(1)}
        onMouseLeave={() => handleInputNotHovered()}
        onClick={() => handleInputNotHovered()}>
        <div className={`smallTitle ${loadingTabs.includes(worksheetId) || (selectedDataSource === '' && 'disabled')}`}>
          Template Collections
        </div>
        {renderCollections()}
      </div>
      <div
        className={`subItem ${!loadingTabs.includes(worksheetId) || (selectedDataSource !== '' && 'noHover')}`}
        onMouseEnter={(e) => handleInputHovered(2)}
        onMouseLeave={() => handleInputNotHovered()}
        onClick={() => handleInputNotHovered()}>
        <div className={`smallTitle ${loadingTabs.includes(worksheetId) || (selectedDataSource === '' && 'disabled')}`}>
          Template
        </div>
        {renderTemplateList()}
      </div>
    </div>
  );
};

export default React.memo(DataPartMenu);
