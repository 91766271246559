// Style
import './style.scss';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Doc from '../../../assets/img/doc-undraw.svg';
import { WorkspaceContext } from '../../../context/WorkspaceContext';

export interface IExportList {
  setShowTitle: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Container used to display the list of questions and answers of a workspace
 * @param param IQnAListProps
 */

const Documentation = ({ setShowTitle }) => {
  const { workspaceId } = React.useContext(WorkspaceContext);

  useEffect(() => {
    setShowTitle(false);
  }, []);

  return (
    <div className="documentationContainer">
      <div className="placeholderContainerExport">
        <img src={Doc} alt="" />
        <h3>Documentation coming soon !</h3>
        <div className="textContainer">
          <p>Scorf documentation is still in its writing process.</p>
          <p>We’re doing everything to make it available to you as soon as possible.</p>
          <p>If you’re struggling with anything, don’t hesitate to contact us by clicking on the button right below.</p>
        </div>
        <div className="btnContainers">
          <Link
            to={{
              pathname: `/${workspaceId}/home`,
              hash: '#support',
            }}>
            <button className="button--green">Go to support</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Documentation;
