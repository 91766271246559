import './style.scss';

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';

import UnionSVG from '../../../assets/img/join.svg';
import TrashSVG from '../../../assets/img/trash-red.svg';
import { WorkspaceContext } from '../../../context/WorkspaceContext';
import Checkbox from '../../../elements/Checkbox';
import SmallLoader from '../../../elements/Loaders/SmallLoader';
import { getDatasource, getDatasourceStatus, updateDatasource } from '../../../shared/api/dataSource';
import { states } from '../../../shared/constant/datasource.consts';
import { createEmptyArray, renderContent } from '../../../shared/helpers/helpers';
import ToastHelper from '../../../shared/helpers/toast/ToastHelper';
import TableRow from './TableRow';

interface IDataSourceTableProps {
  setDatasourceToState: (fileId: string, structurationStatus: states, fields?: object) => void;
  unionLoading: boolean;
  openReportCreator: (val: NS_Workspace.IDataSourcesFile) => void;
  openConclusionCard: (val: NS_Workspace.IDataSourcesFile) => void;
  openDatasourceVisualization: (val: NS_Workspace.IDataSourcesFile) => void;
  openSupport: (val: NS_Workspace.IDataSourcesFile) => void;
  openDeleteDatasources: (ids: Array<string>) => void;
  openUnion: (ids: Array<string>) => void;
}

/**
 * Component used to display all uploaded data sources and manipulate them
 *
 * @param param IDataSourceTableProps props
 */

const DataSourceTable: React.FC<IDataSourceTableProps> = ({
  setDatasourceToState,
  unionLoading,
  openReportCreator,
  openDatasourceVisualization,
  openConclusionCard,
  openSupport,
  openDeleteDatasources,
  openUnion,
}) => {
  // Context
  const {
    workspaceId,
    importedDataSources,
    editDatasource,
    addGeneratedDataSource,
    generatedDataSources,
    uploadingFilesProgression,
    loadingDataSourcesNumber,
  } = useContext(WorkspaceContext);

  const [checkedFileIds, setCheckedFileIds] = useState<Array<string>>([]);
  const [disableUnion, setDisableUnion] = useState<boolean>(true);

  useEffect(() => {
    // Update checked datasources
    const allFileIds = importedDataSources.map((dataSource) => dataSource.fileId);
    const newCheckedFileIds = checkedFileIds.filter((id) => allFileIds.indexOf(id) !== -1);
    setCheckedFileIds(newCheckedFileIds);
    importedDataSources.forEach((dataSource) => {
      if (dataSource) {
        if (dataSource.continuationToken === undefined) {
          dataSource.continuationToken = '';
        }
      }
    });
  }, [importedDataSources]);

  useEffect(() => {
    if (importedDataSources.length !== 0) {
      (async () => await callStatusApi(importedDataSources, true))();
    }
  }, []);

  useEffect(() => {
    setDisableUnion(unionLoading);
  }, [unionLoading]);

  useEffect(() => {
    if (checkedFileIds.length > 1) {
      const checkedDatasources = importedDataSources.filter((ds) => checkedFileIds.indexOf(ds.fileId) !== -1);
      const canUnion = checkedDatasources.every(
        (ds) => ds.sourceFile !== '' && ds.structurationStatus === states.GENERATED,
      );
      setDisableUnion(!canUnion);
    } else {
      setDisableUnion(true);
    }
  }, [checkedFileIds]);

  const callStatusApi = async (datasource: Array<NS_Workspace.IDataSourcesFile>, init = false) => {
    if (init && importedDataSources.filter((dataSource) => dataSource.structurationStatus !== undefined).length !== 0) {
      return;
    }
    const response = await getDatasourceStatus(
      workspaceId,
      datasource.map((ds) => ds.fileId),
    );
    const dsStatuses = response.data;
    for await (const dsStatus of dsStatuses) {
      const selectedDatasource = importedDataSources.filter(
        (dataSource) => dataSource.fileId === dsStatus.datasourceId,
      );
      if (selectedDatasource.length === 0) {
        return;
      }
      switch (dsStatus.status) {
        case 'ERROR':
          setDatasourceToState(selectedDatasource[0].fileId, states.ERROR);
          break;
        case 'GENERATED':
          {
            let datasource;
            try {
              datasource = await getDatasource(workspaceId, selectedDatasource[0].fileId);
            } catch (error: unknown) {
              console.error('Error while getting datasource', error);
              ToastHelper.error('Error while getting datasource');
            }
            setDatasourceToState(selectedDatasource[0].fileId, states.GENERATED, datasource);

            if (generatedDataSources.map((ds) => ds.fileId).includes(selectedDatasource[0].fileId) === false) {
              addGeneratedDataSource(datasource);
            }
          }

          break;
        case 'NOT STARTED':
          setDatasourceToState(selectedDatasource[0].fileId, states.DEFAULT);
          break;
        case 'PROCESSING':
        default:
          setDatasourceToState(selectedDatasource[0].fileId, states.PROCESSING);
      }
    }
  };

  const validateNameChange = useCallback(
    (oldName: string, newName: string, datasource: NS_Workspace.IDataSourcesFile) => {
      if (newName.trim() === '') {
        return;
      }
      if (
        importedDataSources.map((ds) => (ds.fileId === datasource.fileId ? '' : ds.displayName)).indexOf(newName) !== -1
      ) {
        changeDatasourceDisplayName(oldName, datasource);
        ToastHelper.error(`A Data source with name ${newName} already exists`);
        return;
      }

      updateDatasource(workspaceId, datasource.fileId, { displayName: newName.trim() })
        .then((resp) => {
          if (resp.data) {
            changeDatasourceDisplayName(newName.trim(), datasource);
            ToastHelper.success(`New data source name ${datasource.displayName}`);
          }
        })
        .catch((e) => {
          changeDatasourceDisplayName(oldName, datasource);
          ToastHelper.error(`Could not rename data source ${datasource.displayName}`, e);
        });
    },
    [workspaceId, importedDataSources],
  );

  const changeDatasourceDisplayName = (name: string, datasource: NS_Workspace.IDataSourcesFile) => {
    const newDatasource: NS_Workspace.IDataSourcesFile = { ...datasource };
    newDatasource.displayName = name;
    editDatasource(newDatasource.fileId, newDatasource);
  };

  const toggleCheckRow = useCallback(
    (datasource: NS_Workspace.IDataSourcesFile) => {
      if (checkedFileIds.indexOf(datasource.fileId) !== -1) {
        const arr = checkedFileIds.filter((id) => id !== datasource.fileId);
        setCheckedFileIds(arr);
      } else {
        setCheckedFileIds([...checkedFileIds, datasource.fileId]);
      }
    },
    [checkedFileIds],
  );

  const toggleCheckTable = useCallback(() => {
    if (checkedFileIds.length === importedDataSources.length) {
      setCheckedFileIds([]);
    } else {
      setCheckedFileIds(importedDataSources.map((ds) => ds.fileId));
    }
  }, [checkedFileIds, importedDataSources]);

  const handleClickUnion = () => {
    if (!disableUnion) {
      openUnion(checkedFileIds);
      setCheckedFileIds([]);
    }
  };

  const handleClickDelete = () => {
    openDeleteDatasources(checkedFileIds);
  };

  const renderProgressionListItem = useMemo(() => {
    const uploadingFiles: Array<Array<number | string>> = Array.from(uploadingFilesProgression);
    return uploadingFiles.map((value, index) => {
      const indexx = `${value[0]}-${index}`;
      return (
        <div key={indexx} className="progressRow progressItem">
          <div className="progressRow_cell progressRow_cell--name">
            <p title={`${value[0]}`}>{value[0]}</p>
          </div>
          <div className="progressRow_cell progressRow_cell--bar">
            <div className="percentageFilled" style={{ width: `${value[1]}%` }} />
          </div>
          <div className="progressRow_cell progressRow_cell--percentage">
            <p>{`${value[1]}%`}</p>
          </div>
        </div>
      );
    });
  }, [uploadingFilesProgression]);

  const renderDSname = useMemo(() => {
    const len = importedDataSources.length;
    return renderContent(len > 1, `Data Sources • ${len} files`, `Data Source • 1 file`);
  }, [importedDataSources]);

  const loadingRowCount = useMemo(() => {
    return loadingDataSourcesNumber - uploadingFilesProgression.size + (unionLoading ? 1 : 0);
  }, [loadingDataSourcesNumber, uploadingFilesProgression.size, unionLoading]);

  return (
    <div className="datasourceTable">
      <div className="toolHeader">
        <h4>{renderDSname}</h4>
        <div className="toolHeader_actions">
          {checkedFileIds.length > 0 && (
            <button className="toolHeader_actions--delete" onClick={handleClickDelete}>
              <span>Delete</span>
              <ReactSVG src={TrashSVG} className="svg-wrapper trashSVG" />
            </button>
          )}

          <div
            className={`toolHeader_actions--union ${renderContent(
              disableUnion,
              'toolHeader_actions--union--disabled',
              '',
            )}`}
            onClick={handleClickUnion}
            title="Please make sur your Data Sources are interpreted.">
            Union
            <ReactSVG src={UnionSVG} className="svg-wrapper unionSVG" />
          </div>
        </div>
      </div>

      <div className="tableHeader">
        <div className="tableHeader_cell tableHeader_checkbox">
          <Checkbox
            checked={checkedFileIds.length === importedDataSources.length && importedDataSources.length !== 0}
            toggle={toggleCheckTable}
          />
        </div>
        <div className="tableHeader_cell tableHeader_name">Name</div>
        <div className="tableHeader_cell tableHeader_status">Status</div>
        <div className="tableHeader_cell tableHeader_file">Source file</div>
        <div className="tableHeader_cell tableHeader_actions">Actions</div>
      </div>

      {createEmptyArray(loadingRowCount).map((_, key) => {
        const index = `${_} - ${key}`;
        return (
          <div key={index} className="loadingRow">
            <SmallLoader />
          </div>
        );
      })}
      {renderProgressionListItem}
      {importedDataSources.map((item) => {
        if (item?.datasourceName !== undefined) {
          return (
            <TableRow
              key={item.fileId}
              selected={false}
              workspaceId={workspaceId}
              checked={checkedFileIds.indexOf(item.fileId) !== -1}
              datasource={item}
              onClickSupport={openSupport}
              onClickCreateReport={openReportCreator}
              onClickCard={openConclusionCard}
              onClickSourceFile={openDatasourceVisualization}
              onClickCheckbox={toggleCheckRow}
              onValidateNameChange={validateNameChange}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default DataSourceTable;
