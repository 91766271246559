// Style
import './style.scss';

import React, { useContext, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';

import RightArrowSVG from '../../assets/img/chevron-right.svg';
import RedCrossSVG from '../../assets/img/cross-red.svg';
import ExclamationCircleSVG from '../../assets/img/info-circle-red.svg';
import explanationUnion from '../../assets/img/union-explanation.svg';
import { WorkspaceContext } from '../../context/WorkspaceContext';
// API
import * as DataSourceAPI from '../../shared/api/dataSource';
import { renderContent } from '../../shared/helpers/helpers';
import ToastHelper from '../../shared/helpers/toast/ToastHelper';

interface IUnionModalProps {
  unionDatasourceFileIds: Array<string>;
  onClose: () => void;
  handleCallUnion: (
    unionMetadata: Array<IUnionLine>,
    newFileName: string | undefined,
    backToUnion: string,
    reportsId: Array<{ name: string; id: string }>,
  ) => void;
  backToUnionDatasource?: NS_Workspace.IDataSourcesFile;
}

export interface IUnionLine {
  datasourceId: string;
  name: string;
  entityTag: string;
  entityStatut: UnionFormErrorType;
  fiscalYearTag: string;
  fiscalYearStatut: UnionFormErrorType;
}

export enum UnionFormErrorType {
  Error = -1,
  Initial = 0,
  Success = 1,
}

/**
 * Modal allowing the user to select several files that will be united by Scorf
 * The user can enter a union key for each file, which will be added in a new
 * column UnionKey after union process is over to help differentiate files
 *
 * @param param IUnionModalProps props
 */

const UnionForm: React.FC<IUnionModalProps> = ({
  unionDatasourceFileIds,
  onClose,
  handleCallUnion,
  backToUnionDatasource,
}) => {
  const { workspaceId, importedDataSources } = useContext(WorkspaceContext);

  const [filesWithTag, setFilesWithTag] = useState<Array<IUnionLine>>([]);
  const [newFileName, setNewFileName] = useState<string>(backToUnionDatasource?.datasourceName ?? '');
  const [newFileNameError, setNewFileNameError] = useState<UnionFormErrorType>(UnionFormErrorType.Initial);
  const [openExplanation, setOpenExplanation] = useState<boolean>(false);
  const [reportsId, setReportsId] = useState<Array<{ name: string; id: string }>>([]);
  const [fetching, setFectching] = useState<boolean>(false);

  useEffect(() => {
    const fileTags: Array<IUnionLine> = [];
    if (backToUnionDatasource?.unionInfo) {
      for (const file of JSON.parse(backToUnionDatasource.unionInfo)) {
        const soloDS = importedDataSources[importedDataSources.findIndex((x) => x.fileId === file.datasourceId)];
        fileTags.push({
          datasourceId: file.datasourceId,
          name: soloDS.datasourceName,
          entityTag: file.entityTag,
          fiscalYearTag: file.fiscalYearTag,
          entityStatut: UnionFormErrorType.Initial,
          fiscalYearStatut: UnionFormErrorType.Initial,
        });
      }
    } else {
      const files = importedDataSources.filter((ds) => unionDatasourceFileIds.indexOf(ds.fileId) !== -1);
      for (const file of files) {
        fileTags.push({
          datasourceId: file.fileId,
          name: file.displayName,
          entityTag: '',
          fiscalYearTag: '',
          entityStatut: UnionFormErrorType.Initial,
          fiscalYearStatut: UnionFormErrorType.Initial,
        });
      }
    }
    setFilesWithTag(fileTags);
  }, []);

  useEffect(() => {
    if (backToUnionDatasource) {
      setFectching(true);
      DataSourceAPI.getDatasourceDeletionEffects(workspaceId, [backToUnionDatasource.fileId])
        .then((resp) => {
          if (resp) {
            const { data } = resp;
            const reportIds: Array<any> = [];
            data.forEach((ds) => {
              ds.worksheets.forEach((report) => reportIds.push({ id: report.id, name: report.name }));
            });

            setReportsId(reportIds);
            setFectching(false);
          }
        })
        .catch((e) => {
          ToastHelper.error(`Could not get Report by data sources Id`, e);
          setFectching(false);
        });
    }
  }, [backToUnionDatasource]);

  const handleTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFileTags = [...filesWithTag];
    for (const f of newFileTags) {
      if (f.datasourceId === e.target.id) {
        f.entityTag = e.target.value;
        if (e.target.value.length > 0) {
          f.entityStatut = 1;
        }
      }
    }
    setFilesWithTag(newFileTags);
  };

  const handleYearTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFileTags = [...filesWithTag];
    for (const f of newFileTags) {
      if (f.datasourceId === e.target.id) {
        f.fiscalYearTag = e.target.value;
        if (e.target.value.length > 0) {
          f.fiscalYearStatut = 1;
        }
      }
    }
    setFilesWithTag(newFileTags);
  };

  const hasUnionChange = () => {
    if (backToUnionDatasource?.unionInfo) {
      const hasUnionInfoChange = JSON.parse(backToUnionDatasource.unionInfo).some(
        (ds, index) =>
          ds.entityTag !== filesWithTag[index]?.entityTag || ds.fiscalYearTag !== filesWithTag[index]?.fiscalYearTag,
      );
      const hasFileNameChange = backToUnionDatasource?.datasourceName !== newFileName;
      return hasFileNameChange || hasUnionInfoChange;
    } else {
      return true;
    }
  };

  const hasNameConflict = (name: string) => {
    return importedDataSources.some(
      (ds: NS_Workspace.IDataSourcesFile) =>
        ds.datasourceName.toLowerCase() === name.toLowerCase() || ds.displayName.toLowerCase() === name.toLowerCase(),
    );
  };

  const canSubmit = () => {
    if (
      newFileName.trim().length === 0 ||
      (!(backToUnionDatasource && backToUnionDatasource.datasourceName === newFileName) &&
        hasNameConflict(newFileName)) ||
      !hasUnionChange()
    ) {
      return false;
    }
    for (const f of filesWithTag) {
      if (f.entityTag.trim().length === 0 || f.fiscalYearTag.trim().length === 0) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = () => {
    const newFileTags = [...filesWithTag];
    for (const f of newFileTags) {
      if (f.entityTag.trim().length === 0) {
        f.entityStatut = UnionFormErrorType.Error;
      }
      if (f.fiscalYearTag.trim().length === 0) {
        f.fiscalYearStatut = UnionFormErrorType.Error;
      }
    }

    if (newFileName.trim().length === 0 || hasNameConflict(newFileName)) {
      setNewFileNameError(UnionFormErrorType.Error);
    }
    setFilesWithTag(newFileTags);
    if (!canSubmit()) {
      return;
    }
    handleCallUnion(filesWithTag, newFileName, backToUnionDatasource?.fileId ?? '', reportsId);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (hasNameConflict(event.target.value)) {
      setNewFileNameError(UnionFormErrorType.Error);
    } else {
      setNewFileNameError(UnionFormErrorType.Success);
    }
    setNewFileName(event.target.value);
  };

  const reportRender = () => {
    if (fetching) {
      return <div className={`reports`}>waiting...</div>;
    } else {
      return (
        <div
          className={`reports red existing-report-error`}
          title={`${reportsId.map((report) => (report ? report.name : '')).join(', ')} will be deleted`}>
          {reportsId.length > 0 &&
            `${reportsId.map((report) => (report ? report.name : '')).join(', ')} will be deleted`}
        </div>
      );
    }
  };

  return (
    <div className="modalBackground">
      <div className="unionModal">
        <div className="modalContainer">
          <div className="unionModalHeader">
            <h4>Merge selected Data Sources into a single new Data Source</h4>
            <div className="close" onClick={onClose}>
              <ReactSVG src={RedCrossSVG} className="svg-wrapper redCrossSVG" />
            </div>
          </div>
          <div className="unionModalBody">
            <div className="subTitle">
              1. Give selected Data sources a Union Tag and a Fiscal Year Tag to differentiate them
            </div>
            <div className="datasourcesContainer">
              <div className="headerFilesContainer">
                <p className="name">Name</p>
                <p className="unionTag">Entity Tag</p>
                <p className="unionTag">Fiscal Year Tag</p>
              </div>
              <div className="filesContainer">
                {filesWithTag.map((file, key) => {
                  const index = `${key} - ${file.name}`;
                  return (
                    <div key={index} className="file">
                      <div className="leftFile" title={file.name}>
                        <p>{file.name}</p>
                      </div>
                      <div className="unionTag" title={file.entityTag}>
                        <input
                          className={`${file.entityStatut === UnionFormErrorType.Error && 'inputError'} ${
                            file.entityStatut === UnionFormErrorType.Success && 'inputSuccess'
                          }`}
                          id={file.datasourceId}
                          value={file.entityTag}
                          onChange={handleTagChange}
                          placeholder={`Entity ${key + 1}`}
                          maxLength={30}
                        />
                      </div>
                      <div className="unionTag" title={file.fiscalYearTag}>
                        <input
                          className={`${file.fiscalYearStatut === UnionFormErrorType.Error && 'inputError'} ${
                            file.fiscalYearStatut === UnionFormErrorType.Success && 'inputSuccess'
                          }`}
                          id={file.datasourceId}
                          value={file.fiscalYearTag}
                          onChange={handleYearTagChange}
                          placeholder={new Date().getFullYear().toString()}
                          maxLength={30}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="explanation">
              <div className="row">
                <div className="right" onClick={() => setOpenExplanation(!openExplanation)}>
                  What are the Union & Fiscal Year Tags ?
                  <ReactSVG
                    src={RightArrowSVG}
                    className={`svg-wrapper rightArrowSVG ${openExplanation && 'reverse'}`}
                  />
                </div>
              </div>

              {openExplanation && (
                <div className="explanationContainer">
                  <p>A good sketch is better than a long speech : </p>
                  <img src={explanationUnion} alt="" />
                  <p>
                    The Union Tag makes it possible to retrieve information from the merged data source that would only
                    concern the first data source. <br />
                    For example, it will allow the construction of contributive reports, i.e tables for several
                    companies.If you give the same tag for different entities or fiscal periods they will be grouped by
                    this tag in the reports.
                  </p>
                </div>
              )}
            </div>
            <div className="subTitle">2. Give a name to the new Data Source</div>
            <div className="unionModalBottom">
              <div className="infoPlaceholder">
                <label>Data Source name</label>
                <div className="union-name-input">
                  <input
                    id="union-name"
                    className={`${newFileNameError === UnionFormErrorType.Error && 'inputError'} ${
                      newFileNameError === UnionFormErrorType.Success && 'inputSuccess'
                    }`}
                    defaultValue={newFileName}
                    placeholder="Merged Data Source"
                    onChange={handleNameChange}
                    maxLength={30}
                  />
                  {newFileNameError === UnionFormErrorType.Error && newFileName.length !== 0 && (
                    <div className="existing-name-error">Existing name</div>
                  )}
                  {reportsId.length > 0 && reportRender()}
                </div>
              </div>
              <div className="buttonContainer">
                <div className="warningInfo">
                  {(filesWithTag.some(
                    (file) =>
                      file.fiscalYearStatut === UnionFormErrorType.Error ||
                      file.entityStatut === UnionFormErrorType.Error,
                  ) ||
                    (newFileNameError === UnionFormErrorType.Error && newFileName.length === 0)) && (
                    <>
                      <ReactSVG src={ExclamationCircleSVG} className="svg-wrapper exclamationCircleSVG" />
                      Highlighted fields can't be empty
                    </>
                  )}
                </div>
                <button
                  className={renderContent(canSubmit(), 'button--green', 'button--green--disabled')}
                  onClick={handleSubmit}>
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnionForm;
