// Style
import './style.scss';

interface ISpinnerLoader {
  /**
   * Optional color hash value.
   * @default #46b78e
   */
  color?: string;
}

/**
 * A simple spinner Loader
 */
const SpinnerLoader: React.FC<ISpinnerLoader> = ({ color }) => {
  return <div className="spinnerLoader" id="spinnerLoader" style={{ borderColor: color ?? '#46b78e' }} />;
};

export default SpinnerLoader;
