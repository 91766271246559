import './style.scss';

import { useContext, useEffect, useMemo, useState } from 'react';
import Draggable from 'react-draggable';
import { ReactSVG } from 'react-svg';

import closeIcon from '../../../assets/img/cross-red.svg';
import DraggableSVG from '../../../assets/img/draggable.svg';
import trashIcon from '../../../assets/img/trash-red.svg';
import { ReportContext } from '../../../context/ReportContext';
import { WorkspaceContext } from '../../../context/WorkspaceContext';
import DropDownList from '../../../elements/DropDownList';
import { calculate } from '../../../shared/api/report';
import { renderContent } from '../../../shared/helpers/helpers';
import ToastHelper from '../../../shared/helpers/toast/ToastHelper';
import { sortArrayString } from '../../../utils/common';

type FormulaParameters =
  | NS_REPORT.IFormulaFromToRangeIndices
  | NS_REPORT.IFormulaArrayIndices
  | NS_REPORT.IFormulaFirstAndSecondIndex;
interface ICalculateData {
  title: string;
  group: string;
  operation: string;
  parameters: undefined | FormulaParameters;
}

export const enum CalculateOperation {
  SUM = 'sum',
  AVERAGE = 'average',
  VARIATION = 'variation',
  VARIATION_CAGR = 'cagr',
  VARIATION_DIFF = 'diff',
}

const formulas = sortArrayString(['Variation - %', 'Sum', 'Average', 'Variation - CAGR', 'Variation - Diff']);
const rangeTypes = ['Continued', 'Discontinued'];

interface IKpiComponent {
  onClose: () => void;
  onValidate: (val: NS_API.I_RD_StandardStmt) => void;
  onDeleteCol: () => void;
  selectedCols: Array<NS_Table.IColumnHeader>;
  setSelectedCols: (val: Array<NS_Table.IColumnHeader>) => void;
  colSelecting: boolean;
  setColSelecting: (val: boolean) => void;
}

const KpiComponent: React.FC<IKpiComponent> = ({
  onClose,
  onValidate,
  selectedCols,
  setSelectedCols,
  onDeleteCol,
  colSelecting,
  setColSelecting,
}) => {
  const [selectedFormula, setSelectedFormula] = useState<string>(formulas[0]);
  const [selectedRange, setSelectedRange] = useState<string>(rangeTypes[1]);
  const [confirmDisabled, setConfirmDisabled] = useState<boolean>(true);
  const { workspaceId } = useContext(WorkspaceContext);
  const { reportId } = useContext(ReportContext);

  const isVariation = useMemo(() => {
    return selectedFormula.indexOf('Variation') !== -1;
  }, [selectedFormula]);

  useEffect(() => {
    setSelectedCols([]);
    if (isVariation) {
      setSelectedRange(rangeTypes[1]);
    }
  }, [selectedRange, isVariation]);

  useEffect(() => {
    const len = selectedCols.length;
    if (isVariation || selectedRange === rangeTypes[0]) {
      if (len === 2) {
        setConfirmDisabled(false);
        setColSelecting(false);
      } else {
        setConfirmDisabled(true);
        setColSelecting(true);
      }
    } else {
      if (len === 0) {
        setColSelecting(true);
        setConfirmDisabled(true);
      } else {
        setColSelecting(true);
        setConfirmDisabled(false);
      }
    }
  }, [selectedCols]);

  const columnsValue: string = useMemo(() => {
    if (isVariation || selectedRange === rangeTypes[0]) {
      return ['Begining column', 'Ending column']
        .map((col, index) => {
          return selectedCols[index]?.displayName ?? col;
        })
        .join(renderContent(isVariation, ' ; ', ' : '));
    } else {
      return selectedCols.length === 0
        ? 'Column1 ; Column2 ; Column3...'
        : selectedCols.map((col) => col.displayName).join(' ; ');
    }
  }, [selectedCols, isVariation, selectedRange]);

  const generateCalculateParameters = () => {
    const data: ICalculateData = {
      group: 'calculation',
      title: selectedCols.map((col) => col.displayName).join(','),
      operation: '',
      parameters: undefined,
    };
    switch (selectedFormula) {
      case 'Variation - %':
        data.operation = CalculateOperation.VARIATION;
        break;
      case 'Sum':
        data.operation = CalculateOperation.SUM;
        break;
      case 'Average':
        data.operation = CalculateOperation.AVERAGE;
        break;
      case 'Variation - CAGR':
        data.operation = CalculateOperation.VARIATION_CAGR;
        break;
      case 'Variation - Diff':
        data.operation = CalculateOperation.VARIATION_DIFF;
        break;
      default:
        break;
    }

    if (selectedRange === rangeTypes[0]) {
      data.parameters = {
        fromIndex: selectedCols[0].valueIndex,
        toIndex: selectedCols[1].valueIndex,
      } as NS_REPORT.IFormulaFromToRangeIndices;
    } else {
      if (isVariation) {
        data.parameters = {
          firstIndex: selectedCols[0].valueIndex,
          secondIndex: selectedCols[1].valueIndex,
        } as NS_REPORT.IFormulaFirstAndSecondIndex;
      } else {
        data.parameters = {
          indices: selectedCols.map((col) => col.valueIndex),
        } as NS_REPORT.IFormulaArrayIndices;
      }
    }
    return data;
  };

  const handleConfirm = () => {
    const dataToSend = { calculate: [generateCalculateParameters()] };
    calculate(dataToSend, workspaceId, reportId)
      .then((res) => {
        ToastHelper.success('Column added');
        onValidate(res.data);
      })
      .catch((err) => {
        ToastHelper.error('Failed to add the column', err);
      });
  };

  return (
    <Draggable handle="#dragHandler">
      <div id="kpiContainer" className={`kpiFooter show`} data-cy="kpi">
        <div id="dragHandler">
          <ReactSVG src={DraggableSVG} className="svg-wrapper draggableSVG" />
        </div>
        <img src={closeIcon} alt="close icon" id="closeKpiBtn" data-cy="close-dimensions" onClick={onClose} />
        <div className="parameterContainer" id="formula">
          Formula
          <DropDownList elements={formulas} selectedElements={[selectedFormula]} onItemClick={setSelectedFormula} />
        </div>
        <div className="parameterContainer" id="range">
          Range
          <DropDownList
            elements={rangeTypes}
            selectedElements={[selectedRange]}
            disabled={isVariation}
            onItemClick={setSelectedRange}
          />
        </div>
        <div className="parameterContainer">
          Columns
          <input id="colsInput" value={columnsValue} readOnly={true} disabled={colSelecting} />
        </div>
        <img
          src={trashIcon}
          alt="trash icon"
          id="deleteSelectionIcon"
          data-cy="delete-selection"
          onClick={onDeleteCol}
        />
        <button
          className={renderContent(confirmDisabled, 'button--green--disabled', 'button--green')}
          id="confirmKpiButton"
          onClick={handleConfirm}
          disabled={confirmDisabled}>
          OK
        </button>
      </div>
    </Draggable>
  );
};

export default KpiComponent;
