// Style
import './style.scss';

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

// Context
import { WorkspaceContext } from '../../context/WorkspaceContext';
import CustomModal, { BtnClassType } from '../../elements/CustomModal';
import CustomModalWarning from '../../elements/CustomModalWarning';
import SmallLoader from '../../elements/Loaders/SmallLoader';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import { changePollingTime, POLLING_DELAY } from '../../redux/notifications';
// Scorf Component
import { updateDatasource, updateMetadata } from '../../shared/api/dataSource';
// API
import {
  getDatasourceCard,
  getNormalizedHeader,
  interpreteDatasource,
  updateDatasourceCard,
} from '../../shared/api/structuration';
import { EAnalytical, EAnalyticalExclude, MAP_ANALYTICAL, states } from '../../shared/constant/datasource.consts';
import { renderContent } from '../../shared/helpers/helpers';
import ToastHelper from '../../shared/helpers/toast/ToastHelper';
import { sortArrayString } from '../../utils/common';
import { DATA_SOURCE_MESSAGE } from '../../utils/constant';
// Scorf components
import Account from './Account';
import Analytical from './Analytical';
import Balance from './Balance';
import EntriesBalance from './EntriesBalance';
import FiscalYear from './FiscalYear';
import Journal from './Journal';
import Mining from './Mining';

export const NO_OPENING_JOURNAL = 'No Opening Journal';
interface IConclusionStructurationProps {
  onClose: () => void;
  setDatasourceToState: (fileId: string, structurationStatus: states, fields?: object) => void;
  setOpenSupport: (description: string) => void;
  setOpenUnionModal: (val: boolean) => void;
  setBackToUnionDatasource: (ds: NS_Workspace.IDataSourcesFile) => void;
  datasource: NS_Workspace.IDataSourcesFile;
}

interface IContextData {
  workspaceId: string;
  editDatasource: (fileId: string, dataSource) => void;
}

/**
 * This is the card to check if everything went fine with the structuration
 *
 * @param param IConclusionStructurationProps props
 */

const ConclusionStructuration: React.FC<IConclusionStructurationProps> = ({
  onClose,
  setDatasourceToState,
  setOpenSupport,
  setOpenUnionModal,
  setBackToUnionDatasource,
  datasource,
}) => {
  const dataSourceValidated = datasource.isCardValidated;
  const dispatch = useAppDispatch();
  const dataSourceInformations = useAppSelector((state) => state.datasource.informations);
  // Context
  const { workspaceId, editDatasource }: IContextData = useContext(WorkspaceContext);

  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const [fileMetaData, setFileMetaData] = useState<NS_API.IInterpretationCard>();

  const [fiscalYears, setFiscalYears] = useState<Array<number>>([]);
  const [fiscalMonth, setFiscalMonth] = useState<Array<string>>([]);
  const [isDatasourceBalanced, setIsDatasourceBalanced] = useState<boolean>(false);
  const [fiscalYearsDescription, setFiscalYearsDescription] = useState<NS_Workspace.IFiscalYearsDetailsObject>();
  const [areOpeningsIdentified, setAreOpeningsIdentified] = useState<boolean>(false);
  const [areOpeningValidated, setAreOpeningValidated] = useState<boolean>(false);
  const [openingJournal, setOpeningJournal] = useState<Array<string>>([]);
  const [checkJournalOpen, setcheckJournalOpen] = useState<boolean>(false);
  const [nonInterpretableAccountIds, setNonInterpretableAccountIds] = useState<Array<string>>([]);
  const [areAuxAccountIdentified, setAreAuxAccountIdentified] = useState<boolean>(false);
  const [eachEntryHasOneTransactionId, setEachEntryHasOneTransactionId] = useState<boolean>(false);
  const [eachTransactionIdHasAtLeastTwoEntries, setEachTransactionIdHasAtLeastTwoEntries] = useState<boolean>(false);
  const [allJournal, setAllJournal] = useState<Array<string>>([]);
  const [selectedJournals, setSelectedJournals] = useState<Array<string>>([]);

  const [datasourceBalanceDiscrepancy, setDatasourceBalanceDiscrepancy] = useState<number>(0);
  const [openingBalanceDiscrepancy, setOpeningBalanceDiscrepancy] = useState<number>(0);
  const [areOpeningsBalanced, setAreOpeningsBalanced] = useState<boolean>(false);
  const [datasourcesForUnion, setDatasourcesForUnion] = useState<NS_API.IDataSourcesForUnion>();
  const [analytics, setAnalytics] = useState<EAnalytical>(EAnalytical.NONE);
  const [numberOfDatasourceUnioned, setNumberOfDatasourceUnioned] = useState<number>(-1);

  // Effects
  useEffect(() => {
    setLoading(true);
    setFileName(datasource.displayName);
    const datasourceCard = dataSourceInformations.find(
      (information) => information.fileId === datasource.fileId && information.workspaceId === workspaceId,
    );
    if (datasourceCard?.card) {
      const dataObj = JSON.parse(datasourceCard?.card);
      formalizeData(dataObj);
    } else {
      getDatasourceCard(workspaceId, datasource.fileId)
        .then((res) => {
          if (res !== undefined) {
            const { data } = res;
            const dataObj = data.interpretationCard;
            formalizeData(dataObj);
          }
        })
        .catch((err) => {
          setLoading(false);
          ToastHelper.error(`Cannot get card information`, err);
        });
    }
  }, []);

  useEffect(() => {
    if (openingJournal.indexOf('#NONE#') !== -1) {
      setSelectedJournals([NO_OPENING_JOURNAL]);
    } else {
      setSelectedJournals(openingJournal);
    }
  }, [openingJournal, allJournal]);

  // Functions
  const getAnalytics = (analyticsData: Array<string>, counter: number, excludeNumber: number, result: string) => {
    if (analyticsData[counter]) {
      if (analyticsData[counter + 1] && analyticsData[counter + 1].includes(result)) {
        setAnalytics(EAnalytical.NONE);
        return;
      }
      const isIncludeExcludeData =
        analyticsData[counter].includes(EAnalyticalExclude.ACODE) ||
        analyticsData[counter].includes(EAnalyticalExclude.ALIB);
      return getAnalytics(analyticsData, counter + 1, isIncludeExcludeData ? excludeNumber + 1 : excludeNumber, result);
    }
    setAnalytics(MAP_ANALYTICAL[excludeNumber]);
    return excludeNumber;
  };

  const formalizeData = (dataObj: NS_API.IInterpretationCard) => {
    if (dataObj?.analyticColumns?.length) {
      const isAcodeExist = dataObj.analyticColumns.findIndex((analytic) => analytic.includes(EAnalyticalExclude.ACODE));
      if (isAcodeExist !== -1) {
        const firstAnalytics = dataObj.analyticColumns[0].split('#')[1];
        getAnalytics(dataObj.analyticColumns, 0, 0, firstAnalytics);
      }
    }
    setFiscalYearsDescription(dataObj.fiscalYearsDescription);
    setFileMetaData(dataObj);
    setDatasourcesForUnion(dataObj.datasourcesForUnion);
    if (dataObj.datasourcesForUnion) {
      setNumberOfDatasourceUnioned(Object.keys(dataObj.datasourcesForUnion).length);
    }
    if (dataObj.openingBalanceDiscrepancy) {
      setOpeningBalanceDiscrepancy(dataObj.openingBalanceDiscrepancy);
    }
    setAreOpeningsBalanced(dataObj.areOpeningsBalanced);

    const fiscal = dataObj.fiscalYears ? dataObj.fiscalYears.split(';') : '';
    if (fiscal[0]) {
      setFiscalYears(fiscal[0].split(',').map((x) => +x));
    }
    if (fiscal[1]) {
      setFiscalMonth(fiscal[1].split(','));
    }
    setIsDatasourceBalanced(dataObj.isDatasourceBalanced);
    setOpeningJournal(dataObj.openingJournal.map((val) => (val === '#NONE#' ? NO_OPENING_JOURNAL : val)));

    const sortedJournals = sortArrayString(dataObj.allJournal);
    setAllJournal([NO_OPENING_JOURNAL].concat(sortedJournals));
    setNonInterpretableAccountIds(dataObj.nonInterpretableAccountIds);
    setEachEntryHasOneTransactionId(dataObj.eachEntryHasOneTransactionId);
    setAreAuxAccountIdentified(dataObj.areAuxAccountIdentified || false);
    setEachTransactionIdHasAtLeastTwoEntries(dataObj.eachTransactionIdHasAtLeastTwoEntries);
    if (dataObj.datasourceBalanceDiscrepancy) {
      setDatasourceBalanceDiscrepancy(dataObj.datasourceBalanceDiscrepancy);
    }
    setAreOpeningsIdentified(dataObj.areOpeningsIdentified);
    if (dataObj.areOpeningValidated !== undefined) {
      setAreOpeningValidated(dataObj.areOpeningValidated);
    }

    setLoading(false);
  };

  const saveModal = () => {
    if (!dataSourceValidated) {
      // check if journal confirmed
      if (!areOpeningValidated || !areOpeningsIdentified) {
        // display modal informed to confirm
        setcheckJournalOpen(true);
        return;
      } else {
        let yearsList = '';
        fiscalYears.forEach((item, key) => {
          yearsList = `${yearsList} ${key > 0 ? ',' : null} ${item}`;
        });
        let monthList = '';
        fiscalMonth.forEach((item, key) => {
          monthList = `${monthList} ${key > 0 ? ',' : null} ${item}`;
        });
        if (
          areOpeningsIdentified !== fileMetaData?.areOpeningsIdentified ||
          areOpeningValidated !== fileMetaData?.areOpeningValidated
        ) {
          if (fileMetaData) {
            if (areOpeningsIdentified !== fileMetaData?.areOpeningsIdentified) {
              fileMetaData['areOpeningsIdentified'] = areOpeningsIdentified;
            }
            if (areOpeningValidated !== fileMetaData?.areOpeningValidated) {
              fileMetaData['areOpeningValidated'] = areOpeningValidated;
            }
          }
          updateMetadata(workspaceId, datasource.fileId, fileMetaData)
            .then(() => {
              validateDataSource();
            })
            .catch((e) => {
              ToastHelper.error(`Error while saving your changes`, e);
            });
        } else {
          // only validate data
          validateDataSource();
        }
      }
    }
  };

  // validate data Source
  const validateDataSource = () => {
    const data = { isCardValidated: true };
    updateDatasource(workspaceId, datasource.fileId, data)
      .then((res) => {
        if (res?.data) {
          onClose();
          editDatasource(datasource.fileId, { ...datasource, isCardValidated: true });
          ToastHelper.success(`Card validated`);
        } else {
          ToastHelper.error(`Failed to validate the card`);
        }
      })
      .catch((e) => {
        ToastHelper.error(`Failed to validate the card`, e);
      });
  };

  const handleAskSupportClick = useCallback((description: string) => {
    onClose();
    setOpenSupport(description);
  }, []);

  const submitJournalChange = useCallback(() => {
    dispatch(changePollingTime(POLLING_DELAY.executed));
    getNormalizedHeader(workspaceId, datasource.fileId)
      .then((res) => {
        const { data } = res;
        const journalsToSend = selectedJournals.map((journal) => (journal === NO_OPENING_JOURNAL ? '#NONE#' : journal));
        setDatasourceToState(datasource.fileId, states.PROCESSING);
        interpreteDatasource(
          datasource.datasourceName,
          datasource.workspaceId,
          datasource.fileId,
          data,
          false,
          true,
          journalsToSend,
        )
          .then(() => {
            setDatasourceToState(datasource.fileId, states.PROCESSING);
          })
          .catch((e) => {
            setDatasourceToState(datasource.fileId, states.ERROR);
            ToastHelper.error(`Error while sending normalized headers to server`, e);
          });
        onClose();
      })
      .catch((err) => {});
  }, [workspaceId, datasource, selectedJournals]);

  const validateOpening = useCallback(() => {
    const data = { interpretationCard: { areOpeningValidated: true } };
    updateDatasourceCard(workspaceId, datasource.fileId, data)
      .then((res) => {
        if (res?.data && res.data.message === 'SUCCESS') {
          setAreOpeningValidated(true);
          ToastHelper.success(`Opening validated`);
        } else {
          ToastHelper.error(`Failed to validate openings`);
        }
      })
      .catch((e) => {
        ToastHelper.error(`Failed to validate openings`, e);
      });
  }, [workspaceId, datasource]);

  const handleClickJournal = (journal: string) => {
    const journalIndex = selectedJournals.indexOf(journal);
    let newSelectedJournals = [...selectedJournals];
    if (journalIndex !== -1) {
      newSelectedJournals.splice(journalIndex, 1);
      setSelectedJournals(newSelectedJournals);
    } else {
      if (journal === NO_OPENING_JOURNAL || journal === '') {
        setSelectedJournals([journal]);
      } else {
        newSelectedJournals = newSelectedJournals.filter((val) => val !== '' && val !== NO_OPENING_JOURNAL);
        newSelectedJournals.push(journal);
        setSelectedJournals(newSelectedJournals);
      }
    }
  };

  const confirmOpeningsIdentified = useCallback(() => {
    setAreOpeningsIdentified(true);
  }, []);

  const validateBalance = useCallback(() => {
    setIsDatasourceBalanced(true);
  }, []);

  const validateEntriesBalance = useCallback(() => {
    setAreOpeningsBalanced(!areOpeningsBalanced);
  }, [areOpeningsBalanced]);

  const modalTitle = useMemo(() => {
    const unionSubTitle =
      datasourcesForUnion === undefined ? '' : `- Union of ${numberOfDatasourceUnioned} Data Sources`;
    return `${fileName}'s Interpretation Results ${unionSubTitle}`;
  }, [datasourcesForUnion, numberOfDatasourceUnioned, fileName]);

  const handleBack = useCallback(() => {
    if (datasourcesForUnion) {
      setOpenUnionModal(true);
      onClose();
      setBackToUnionDatasource(datasource);
    }
  }, [datasourcesForUnion, datasource]);

  const showBalanced: boolean = useMemo(() => {
    if ((areOpeningsBalanced || areOpeningsIdentified) && openingJournal.indexOf(NO_OPENING_JOURNAL) === -1) {
      return true;
    }
    return false;
  }, [areOpeningsIdentified, openingJournal, areOpeningsBalanced]);

  const handleConfirmModalWaring = useCallback(() => {
    setcheckJournalOpen(false);
  }, []);

  const handleConfirmModal = () => {
    saveModal();
  };
  return (
    <>
      {checkJournalOpen ? (
        <CustomModalWarning description={DATA_SOURCE_MESSAGE.CONFIRM_DS_JOURNAL} onConfirm={handleConfirmModalWaring} />
      ) : (
        <CustomModal
          title={modalTitle}
          onConfirm={handleConfirmModal}
          onCancel={handleBack}
          cancelDisplay={datasourcesForUnion === undefined}
          cancelLabel={renderContent(
            datasourcesForUnion === undefined,
            'Back to Interpretation Labels',
            'Back to Union',
          )}
          cancelBtnType={BtnClassType.Cancel}
          confirmLabel={!dataSourceValidated ? 'Validate' : 'Ok'}
          confirmDisplay={dataSourceValidated}
          onClose={onClose}
          className="conclusionStructurationModal"
          closeIcon={true}>
          {loading && <SmallLoader />}
          {!loading && (
            <>
              <Balance
                isDatasourceBalanced={isDatasourceBalanced}
                dataSourceValidated={dataSourceValidated}
                onAskSupportClick={handleAskSupportClick}
                datasourceBalanceDiscrepancy={datasourceBalanceDiscrepancy}
                onValidateBalance={validateBalance}
              />
              {analytics !== EAnalytical.NONE && <Analytical analytical={analytics} />}
              <Journal
                allJournal={allJournal}
                selectedJournals={selectedJournals}
                onSubmitJournalChange={submitJournalChange}
                onConfirmOpeningsIdentified={confirmOpeningsIdentified}
                onValidateOpening={validateOpening}
                areOpeningValidated={areOpeningValidated}
                areOpeningsIdentified={areOpeningsIdentified}
                openingJournal={openingJournal}
                datasourcesForUnion={datasourcesForUnion}
                onClickJournal={handleClickJournal}
              />
              {showBalanced && (
                <EntriesBalance
                  onAskSupportClick={handleAskSupportClick}
                  areOpeningsBalanced={areOpeningsBalanced}
                  dataSourceValidated={dataSourceValidated}
                  openingBalanceDiscrepancy={openingBalanceDiscrepancy}
                  onValidateEntriesBalance={validateEntriesBalance}
                />
              )}
              <Account
                onAskSupportClick={handleAskSupportClick}
                nonInterpretableAccountIds={nonInterpretableAccountIds}
                collectionName={datasource.collectionName}
              />
              <FiscalYear
                onAskSupportClick={handleAskSupportClick}
                datasourcesForUnion={datasourcesForUnion}
                fiscalYearsDescription={fiscalYearsDescription}
              />
              <Mining
                eachEntryHasOneTransactionId={eachEntryHasOneTransactionId}
                eachTransactionIdHasAtLeastTwoEntries={eachTransactionIdHasAtLeastTwoEntries}
                areAuxAccountIdentified={areAuxAccountIdentified}
              />
            </>
          )}
        </CustomModal>
      )}
    </>
  );
};

export default ConclusionStructuration;
