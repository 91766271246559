import { IDonutData, IInsideDataSetData, PIE_TOP_TEN_LABEL } from '../../constant/chart.consts';
import { ChartType } from '../../constant/datasource.consts';
import { currencyFormat } from '../../helpers/helpers';
import { CHART_DATA_FONT, TChartData } from '../utils';

export const PIE_EXCLUDE_DATA = {
  AUXOther: 'AUXOther',
  AUX_ALL_OTHER: 'AUX_ALL_OTHER',
};

export const CHARTS_COLORS = [
  '#e27973',
  '#40a781',
  '#7eceb0',
  '#8dbff2',
  '#bad9f7',
  '#ffe5b3',
  '#235c47',
  '#bd3629',
  '#e1f4ed',
  '#fcf2f1',
];

export const formalizeChartPieConfigs = (params: TChartData, index: number, getEvents, resetEvents) => {
  let data: Array<IInsideDataSetData> = [];
  let totals = {
    label: '',
    value: '',
    pValue: '',
  };
  let centerValue = '';
  let reference = '';
  let baseLabel = '';
  if (params?.fiscalYearsInfo) {
    reference = Object.keys(params.fiscalYearsInfo)[0];
  }
  if (params?.conceptValues?.valuesLegend) {
    const { values, valuesLegend } = params.conceptValues;
    let keys = Object.keys(valuesLegend);
    let dataToExclude = [PIE_EXCLUDE_DATA.AUXOther, PIE_EXCLUDE_DATA.AUX_ALL_OTHER];
    let conceptIdAsBase = '';
    let isOpening = !!params?.reportContentSetup?.otherParams?.displayOpening;
    if (params?.reportContentSetup?.otherParams?.conceptIdAsBase?.length) {
      dataToExclude = [params.reportContentSetup.otherParams.conceptIdAsBase, ...dataToExclude];
      conceptIdAsBase = params.reportContentSetup.otherParams.conceptIdAsBase;
      baseLabel = valuesLegend[conceptIdAsBase];
    }
    let totalOther: any = 0;
    let totalsData: any = 0;
    let colorIterate = 1;
    for (const key of keys) {
      const seriesname: string = valuesLegend[key];
      if (dataToExclude && dataToExclude[0] && dataToExclude[0] === key) {
        totals.label = seriesname;
      }
      const isDadaToExclude = dataToExclude.includes(key);
      if (isDadaToExclude) {
        if (conceptIdAsBase !== key) {
          let _data: Array<IInsideDataSetData> = [];
          for (const [_key, value] of Object.entries(values)) {
            const _v = value as Array<string>;
            if (_key === key) {
              if (_v?.length) {
                const counter = !isOpening && 1 < _v.length ? 1 : 0;
                for (let j = counter; j < _v.length; j++) {
                  totalsData = totalsData + _v[j];
                  _data = [
                    ..._data,
                    {
                      value: _v[j],
                      label: seriesname,
                      color: CHARTS_COLORS[colorIterate],
                    },
                  ];
                  colorIterate = colorIterate + 1;
                }
              }
            }
          }
          data = [...data, ..._data];
        } else {
          for (const [_key, value] of Object.entries(values)) {
            const _v = value as Array<string>;
            if (_key === key) {
              if (_v?.length) {
                const counter = !isOpening && 1 < _v.length ? 1 : 0;
                totalsData = totalsData + _v[counter];
                totals.value = `${Math.abs(parseFloat(_v[counter]))}`;
              }
            }
          }
        }
      } else {
        for (const [_key, value] of Object.entries(values)) {
          const _v = value as Array<string>;
          if (_key === key) {
            if (_v?.length) {
              const counter = !isOpening && 1 < _v.length ? 1 : 0;
              for (let j = counter; j < _v.length; j++) {
                totalOther += _v[j];
              }
            }
          }
        }
      }
    }

    data = [...data, { value: totalOther, label: PIE_TOP_TEN_LABEL, color: CHARTS_COLORS[0] }]; // Top 10: somme of other value
    // FIX ME: Clean code
    const isSameData = checkIfSameData(params?.conceptValues);
    if (isSameData) {
      data = [{ ...data[0], label: params.conceptValues.valuesLegend[conceptIdAsBase] }];
    }
    let pValue = 0;
    for (let j = 0; j < data.length; j++) {
      pValue = (data[j].value as any) + pValue;
    }
    totals.pValue = `${((totalOther * 100) / pValue).toFixed(0)}%`;
  }
  centerValue = currencyFormat('0,0.00', totals.value, 1000000).toFixed(1);
  const defaultValue: IDonutData = {
    color: '',
    id: baseLabel,
    index,
    pValue: `${centerValue}m€`,
    value: totals.label + '',
  };
  return {
    reference,
    defaultValue,
    title: params.reportTitle,
    config: {
      type: ChartType.PIE,
      dataLoadStartMessage: 'Please wait, chart is loading the data....',
      dataLoadStartMessageFontSize: '14',
      renderAt: 'chart-container',
      height: '400',
      dataFormat: 'json',
      dataSource: {
        chart: {
          bgColor: '#ffffff',
          startingAngle: '310',
          centerLabelBold: '1',
          showTooltip: '0',
          decimals: '0',
          valuePosition: 'inside',
          minAngleForValue: '75',
          labelFontColor: '#ffffff',
          enablesmartLabel: '0',
          legendPosition: 'right',
          legendItemFontSize: '13',
          pieradius: 150,
          showValues: '1',
          showLabels: 0,
          showLegend: '0',
          exportFileName: params.reportTitle,
          ...CHART_DATA_FONT,
        },
        data,
      },
      events: {
        dataPlotRollOver: function (e) {
          if (e?.data) {
            const { id, value, pValue, color } = e.data;
            const CARACT_BUG = '&#039;';
            let _id: string = id;
            if (id.includes(CARACT_BUG)) {
              _id = _id.replace(CARACT_BUG, `'`);
            }
            if (_id === totals.label) {
              _id = '';
            }
            getEvents({ id: _id, value, pValue, color, index, label: totals.label });
          }
        },
        dataPlotRollOut: function (e) {
          resetEvents({ ...defaultValue });
        },
      },
    },
  };
};

const checkIfSameData = (conceptValue: NS_API.IRAConceptValues) => {
  let isSame = false;
  if (conceptValue?.values) {
    const arrayValues: Array<Array<number>> = Object.values(conceptValue.values);
    const allEqual = (arr) =>
      arr.every((val) => {
        return val?.[0] && arr?.[0]?.[0] && val[0] === arr[0][0];
      });
    return allEqual(arrayValues);
  }
  return isSame;
};
