// Style
import './style.scss';

import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';

import RedCrossSVG from '../../../assets/img/cross-red.svg';
import { ReportContext } from '../../../context/ReportContext';
import { WorkspaceContext } from '../../../context/WorkspaceContext';
import DropDownList from '../../../elements/DropDownList';
import { UNITS_LIST } from '../../../shared/constant/chart.consts';
import ChartHelper from '../../../shared/helpers/chart/chart.helper';
import StringHelper from '../../../shared/helpers/string/string.helper';
import Chart from './Chart';

const typesOfGraph = {
  typesOfBSGraphList: {
    year: ['Bar Chart', 'Actif immobilisé', "BFR d'exploitation", 'Actif net'],
    month: ['Bar Chart'],
  },
  typesOfPLGraphList: {
    year: ['Bar Chart', 'Pie Chart', 'Funnel Chart'],
    month: ["Chiffre d'affaire", 'Chart combiné', "Produits d'exploitation", "charges d'exploitation"],
  },
  typeOfCBGraphList: ['Line Chart', 'Bar Chart'],
  typeOfCFGraphList: ['Line chart'],
};

interface IChartVisualisationContainer {
  setIsGraphOpen: (val: boolean) => void;
  reportName: string;
  columnsValueNames: Array<string>;
  cumulDisplay: boolean;
  selectedTemplate: string;
  isYear: boolean;
}

/**
 * Container used to display a report
 *
 * @param param NS_Tabs.ITab props
 */

const ChartVisualisationContainer: React.FC<IChartVisualisationContainer> = ({
  setIsGraphOpen,
  reportName,
  columnsValueNames,
  cumulDisplay,
  selectedTemplate,
  isYear,
}) => {
  const { currentTree } = useContext(ReportContext);
  const { colorOptions } = useContext(WorkspaceContext);

  const renderGraphTypes = useMemo(() => {
    switch (selectedTemplate) {
      case 'BS':
        return isYear ? typesOfGraph.typesOfBSGraphList.year : typesOfGraph.typesOfBSGraphList.month;
      case 'PL':
        return isYear ? typesOfGraph.typesOfPLGraphList.year : typesOfGraph.typesOfPLGraphList.month;
      case 'CB':
        return typesOfGraph.typeOfCBGraphList;
      case 'CF':
        return typesOfGraph.typeOfCFGraphList;
      default:
        return [];
    }
  }, [isYear, selectedTemplate]);

  const [selectedUnit, setSelectedUnit] = useState<string>(UNITS_LIST[0]);
  const [typeOfGraph, setTypeOfGraph] = useState<string>(renderGraphTypes[0]);

  const chartConfigs = useMemo(() => {
    if (currentTree) {
      const exponential = UNITS_LIST.indexOf(selectedUnit);
      const coeff = Math.pow(1000, exponential) * 100;
      switch (selectedTemplate) {
        case 'BS':
          return ChartHelper.generateBSChartConfig(
            currentTree,
            coeff,
            columnsValueNames,
            selectedUnit,
            typeOfGraph,
            colorOptions,
          );
        case 'PL':
          return ChartHelper.generatePLChartConfig(
            currentTree,
            coeff,
            columnsValueNames,
            selectedUnit,
            typeOfGraph,
            colorOptions,
          );
        case 'CB':
          return ChartHelper.generateCBChartConfig(
            currentTree,
            cumulDisplay,
            coeff,
            columnsValueNames,
            selectedUnit,
            typeOfGraph,
            colorOptions,
          );
        case 'CF':
          return ChartHelper.generateCFChartConfig(currentTree, coeff, columnsValueNames, selectedUnit, colorOptions);
        default:
          break;
      }
    }
    return {};
  }, [currentTree, selectedUnit, typeOfGraph]);

  const downloadFileName = useMemo(() => {
    return StringHelper.getTemplateFullName(selectedTemplate);
  }, [selectedTemplate]);

  const handleSelectUnitItem = useCallback((unit: any, index: number) => {
    setSelectedUnit(unit);
  }, []);
  const handleSelectTypeItem = useCallback((type: any, index: number) => {
    setTypeOfGraph(type);
  }, []);

  const handleCross = useCallback((e) => {
    e.stopPropagation();
    setIsGraphOpen(false);
  }, []);
  return (
    <div className="chartVisualisationContainer">
      <div className="chartVisualisation_header">
        <div className="chartVisualisation_header_leftPart">
          <ReactSVG src={RedCrossSVG} className="svg-wrapper redCrossSVG" onClick={handleCross} />
          <p className="title">Chart</p>
        </div>
        <div className="chartVisualisation_header_rightPart">{reportName}</div>
      </div>
      <div className="chartVisualisation_body">
        <div className="chartVisualisation_body_actions">
          <div className="graphSelection">
            <label>Type of Chart</label>
            <DropDownList
              elements={renderGraphTypes}
              selectedElements={[typeOfGraph]}
              onItemClick={handleSelectTypeItem}
              disabled={selectedTemplate === 'CF'}
            />
          </div>
          <div className="unitSelection">
            <label>Chart Unit</label>
            <DropDownList elements={UNITS_LIST} selectedElements={[selectedUnit]} onItemClick={handleSelectUnitItem} />
          </div>
        </div>
        <div className="chartContainer">
          <Chart chartConfigs={chartConfigs} fileName={downloadFileName} isFromReport={true} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(ChartVisualisationContainer);
