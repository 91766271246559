import './style.scss';

import { useMemo, useState } from 'react';

import { renderContent } from '../../../shared/helpers/helpers';
import CollapsibleItem, { BadgeTypes } from '../CollapsibleItem';

interface IAccount {
  nonInterpretableAccountIds: Array<string>;
  onAskSupportClick: (description: string) => void;
  collectionName: string;
}

const Account: React.FC<IAccount> = ({ nonInterpretableAccountIds, onAskSupportClick, collectionName }) => {
  const [displayInterpretable, setDisplayInterpretable] = useState<boolean>(false);

  const title = useMemo(() => {
    return renderContent(
      !nonInterpretableAccountIds || nonInterpretableAccountIds.length === 0,
      'All account numbers have been interpreted',
      'Some accounts have a non-interpretable number',
    );
  }, [nonInterpretableAccountIds]);

  const badgeType = useMemo(() => {
    return renderContent(
      !nonInterpretableAccountIds || nonInterpretableAccountIds.length === 0,
      BadgeTypes.VALIDATED,
      BadgeTypes.ERROR,
    );
  }, [nonInterpretableAccountIds]);

  const toggleDisplayInterpretable = () => {
    setDisplayInterpretable(!displayInterpretable);
  };

  return (
    <CollapsibleItem title={title} badgeType={badgeType}>
      <div className="accountConclusion">
        {nonInterpretableAccountIds.length === 0 && (
          <p>
            {renderContent(
              collectionName !== '',
              `All accounts have been mapped with the ${collectionName}'s Chart of Accounts.`,
              'The Data Source was not associated to a templates collection',
            )}
          </p>
        )}
        {nonInterpretableAccountIds.length > 0 && (
          <p>
            Some account numbers were not recognized, click&nbsp;
            <span className="here" onClick={toggleDisplayInterpretable}>
              here
            </span>
            &nbsp;to view the list.&nbsp;
            {displayInterpretable && (
              <div className="listUninterpretable">
                {nonInterpretableAccountIds.map((item, key) => {
                  const index = `${key} - ${item}`;
                  return <p key={index}>{item}</p>;
                })}
                {renderContent(nonInterpretableAccountIds.includes(''), 'The data range may include empty lines', '')}
              </div>
            )}
            <button
              className="askSupportBtn"
              onClick={() => onAskSupportClick('Not all account numbers have been interpreted')}>
              Support
            </button>
          </p>
        )}
      </div>
    </CollapsibleItem>
  );
};

export default Account;
