import React from 'react';
import { ReactSVG } from 'react-svg';
import LockSVG from '../../../../assets/img/lock.svg';
import HelpSVG from '../../../../assets/img/help.svg';
import ToggleButton from '../../../../elements/ToggleButton';
import { renderContent } from '../../../../shared/helpers/helpers';
import './style.scss';

interface ILockPart {
  locked: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

const LockPart: React.FC<ILockPart> = ({ locked, disabled, onChange }) => {
  return (
    <div className="lockPartContainer">
      <div className="lockPartHeader">
        <ReactSVG src={LockSVG} className="svg-wrapper lockSVG" />
        Lock
        <ReactSVG src={HelpSVG} className="svg-wrapper infoSVG" />
        <div className="lockPartExplanation">There are some explanation.</div>
      </div>
      <div className="lockPartBody">
        <ToggleButton
          label={renderContent(locked, 'Locked', 'Unlocked')}
          active={locked}
          disabled={disabled}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default LockPart;
