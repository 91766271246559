import { CustomPicker } from 'react-color';
import { Saturation, Hue, Alpha, Checkboard } from 'react-color/lib/components/common';
import SketchFields from 'react-color/lib/components/sketch/SketchFields';
import SketchPresetColors from 'react-color/lib/components/sketch/SketchPresetColors';
import redCross from '../../../assets/img/cross-red.svg';

// Style
import './style.scss';

export interface IColor {
  hex: string;
  rgb: {
    r: number;
    g: number;
    b: number;
    a: number;
  };
  hsl: {
    h: number;
    s: number;
    l: number;
    a: number;
  };
}

/**
 * A custom picker component
 *
 */

const MyPicker = ({ hsl, hex, hsv, rgb, onChange, standardColors, recentColors, onClose, titleText }) => {
  return (
    <div className="customPicker">
      <div className="colorPickerHeader">
        <span>{titleText}</span>
        <img src={redCross} alt="croix" className="closeCross" onClick={onClose} />
      </div>
      <div className="saturation">
        <Saturation hsl={hsl} hsv={hsv} hex={hex} rgb={rgb} onChange={onChange} />
      </div>
      <div className="controls">
        <div className="sliders">
          <div className="hue">
            <Hue hsl={hsl} onChange={onChange} />
          </div>
          <div className="alpha">
            <Alpha hsl={hsl} rgb={rgb} onChange={onChange} />
          </div>
        </div>
        <div className="color">
          <Checkboard hsl={hsl} rgb={rgb} onChange={onChange} />
          <div className="activeColor" style={{ backgroundColor: `${hex}` }} />
        </div>
      </div>
      <SketchFields rgb={rgb} hex={hex} onChange={onChange} disableAlpha={false} />
      <label>Standard Colors</label>
      <SketchPresetColors colors={standardColors} onClick={onChange} />
      <label>Recent Colors</label>
      <SketchPresetColors colors={recentColors} onClick={onChange} />
    </div>
  );
};

export default CustomPicker(MyPicker);
