import { serviceDatasource, serviceLazyLoad, serviceMining } from '../..';
import { IUnionLine } from '../../modals/Union';
import { executeRequest } from './common';

// PATCH
export const getDatasourceStatus = async (workspaceId: string, dataSourcesId: Array<string>) => {
  return serviceDatasource.request<Array<NS_API.IStructurationStatus>>({
    method: 'PATCH',
    url: `/datasource/${workspaceId}/status`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: {
      datasourceId: dataSourcesId,
    },
  });
};

// GET
export const getDatasource = async (
  workspaceId: string,
  datasourceId: string,
): Promise<NS_Workspace.IDataSourcesFile> => {
  const url = `/datasource/${workspaceId}/${datasourceId}`;
  const request = serviceDatasource.get(url, {
    headers: {
      'Content-Type': `application/json`,
    },
  });

  return executeRequest<NS_Workspace.IDataSourcesFile>(request);
};

export const getPreviewLazy = async (
  workspaceId: string,
  fileId: string,
  continuationToken: string | null,
  headerIndex: number | null,
) => {
  return serviceLazyLoad.request<NS_API.ILazyDataPreview>({
    method: 'GET',
    url: `/lazy-load/${workspaceId}/${fileId}/preview/lazy`,
    headers: {
      'Content-Type': `application/json`,
    },
    params: {
      format: 'json',
      continuationToken: continuationToken,
      headerIndex: headerIndex,
    },
  });
};

// POST

export const getDatasourceDeletionEffects = async (workspaceId: string, datasourceId: Array<string>) => {
  return serviceDatasource.request({
    method: 'POST',
    url: `/datasource/${workspaceId}/hardDelete/confirm`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: {
      datasourceId: datasourceId,
    },
  });
};

export const unionDatasource = async (
  unionMetadata: Array<IUnionLine>,
  newFileName: string | undefined,
  workspaceId: string,
  backToUnion: string,
) => {
  return serviceDatasource.request({
    method: 'POST',
    url: `/datasource/${workspaceId}/union`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: {
      workspaceId: workspaceId,
      unionMetadata: unionMetadata,
      unionFileName: newFileName,
      deleteUnionDatasourceId: backToUnion ? backToUnion : undefined,
    },
  });
};

export const structureDataSource = async (
  workspaceId: string,
  datasourceId: string,
  datasourceName: string,
  indiceOfErrors: number[],
  docType: string,
  collectionId: string | null,
  graphId?: string,
) => {
  return serviceDatasource.request<NS_API.IStructureFile>({
    method: 'POST',
    url: '/datasource/structure',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      workspaceId: workspaceId,
      datasourceId: datasourceId,
      datasourceName: datasourceName,
      indiceOfErrors: indiceOfErrors,
      docType: docType,
      collectionId: collectionId,
      graphId: graphId,
    },
  });
};

// PUT
export const updateDatasource = async (workspaceId: string, datasourceId: string, data: any) => {
  return serviceDatasource.request({
    method: 'PUT',
    url: `/datasource/${workspaceId}/${datasourceId}`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: data,
  });
};

export const updateMetadata = async (workspaceId: string, datasourceId: string, interpretationCard) => {
  return serviceMining.request({
    method: 'PUT',
    url: `/metadata/${workspaceId}/${datasourceId}`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: {
      interpretationCard,
    },
  });
};

// DELETE
export const deleteOneOrManyDatasource = async (workspaceId: string, fileId: Array<string>) => {
  return serviceDatasource.request({
    method: 'DELETE',
    url: `/datasource/${workspaceId}/hardDelete`,
    headers: {},
    data: {
      datasourceId: fileId,
    },
  });
};
