import React, { useMemo } from 'react';

// Style
import './style.scss';

// Icon
import SuccessSVG from '../../assets/img/check-circle-green-filled.svg';
import InfoSVG from '../../assets/img/info-circle-blue-filled.svg';
import ErrorSVG from '../../assets/img/cross-circle-red-filled.svg';
import Warning from '../../assets/img/info-circle-yellow-filled.svg';
import { TypeOptions } from 'react-toastify';

interface IToast {
  /**
   * Toast info type.
   * @type "info" | "success" | "warning" | "error" | "default"
   */
  type: TypeOptions;
  /**
   * Toast text content.
   */
  text: string;
}

/**
 * Custom toast component, wrapped and used in react-toastify
 */
const ToastComponent: React.FC<IToast> = ({ type = 'default', text }) => {
  const renderImg = useMemo(() => {
    switch (type) {
      case 'error':
        return <img src={ErrorSVG} alt="error" />;
      case 'success':
        return <img src={SuccessSVG} alt="success" />;
      case 'warning':
        return <img src={Warning} alt="warning" />;
      case 'info':
      default:
        return <img src={InfoSVG} alt="info" />;
    }
  }, []);

  return (
    <div className={`customToast`}>
      {renderImg}
      <p>
        <span>{type} -&nbsp;</span>
        {text}
      </p>
    </div>
  );
};

export default ToastComponent;
