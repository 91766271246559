// GET

import { serviceQandA, serviceWorkspace } from '../..';

export const getAllQuestions = async (workspaceId: string) => {
  return serviceQandA.request<Array<NS_Workspace.IQuestion>>({
    method: 'GET',
    url: `/qanda/${workspaceId}`,
    headers: {
      'Content-Type': `application/json`,
    },
  });
};

export const getQuestionCounter = async (workspaceId: string) => {
  return serviceWorkspace.request<{ name: string; questionCounter: number }>({
    method: 'GET',
    url: `/workspace/${workspaceId}/question/counter`,
    headers: {
      'Content-Type': `application/json`,
    },
  });
};

// POST

export const createQuestion = async (workspaceId: string, reportId: string, questionDto: NS_Workspace.IQADto) => {
  return serviceQandA.request<NS_Workspace.IQuestion>({
    method: 'POST',
    url: `/qanda/${workspaceId}/${reportId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: questionDto,
  });
};

// PATCH

export const updateQuestion = async (workspaceId: string, question: NS_Workspace.IQADto, questionId: string) => {
  return serviceQandA.request<NS_Workspace.IQuestion>({
    method: 'PATCH',
    url: `/qanda/${workspaceId}/${questionId}`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: question,
  });
};

// DELETE

export const deleteQuestion = async (workspaceId: string, questionId: string) => {
  return serviceQandA.request({
    method: 'DELETE',
    url: `/qanda/${workspaceId}/${questionId}`,
    headers: {},
  });
};
