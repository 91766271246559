import './style.scss';

// Style
import { FC } from 'react';

import { IHeaderData } from '../../../containers/ChartReport';
import { ChartFamily } from '../../../shared/constant/datasource.consts';
import useExportAll from './exportAll';

interface IChartTopContainer {
  data: IHeaderData;
  selectTemplateKey: string;
  handleSelect: (templateKey: ChartFamily) => (event: any) => void;
}

const ChartTopContainer: FC<IChartTopContainer> = ({ data, selectTemplateKey, handleSelect }) => {
  const { handleExport } = useExportAll();
  const isSelected = (value) => {
    return value === selectTemplateKey ? 'chart-tab-active' : '';
  };

  return (
    <div className="topContainer">
      <div className="topBody">
        <div className="title-block">
          {data.dataSourceName} - {data.templateCollectionName}
        </div>
        <div className="chartBlock">
          <span
            className={`${isSelected(ChartFamily.PL)} ch-header-action-button`}
            onClick={handleSelect(ChartFamily.PL)}>
            P&L
          </span>
          <span
            className={`${isSelected(ChartFamily.BS)} ch-header-action-button`}
            onClick={handleSelect(ChartFamily.BS)}>
            Balance Sheet
          </span>
          <span
            className={`${isSelected(ChartFamily.C)} ch-header-action-button`}
            onClick={handleSelect(ChartFamily.C)}>
            Cash
          </span>
        </div>
        <div className="exportAll">
          {
            <button className="button--white" onClick={handleExport}>
              Export all
            </button>
          }
        </div>
      </div>
    </div>
  );
};

export default ChartTopContainer;
