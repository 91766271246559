// Style
import './style.scss';

import React, { useEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';

import CameraSVG from '../../../../assets/img/camera.svg';
import ExplanationBox from '../../../../elements/ExplanationBox';
import SpinnerLoader from '../../../../elements/Loaders/SpinnerLoader';
import { renderContent } from '../../../../shared/helpers/helpers';
import { exportSnapshot, ExportTypes } from '../../../../shared/helpers/report/reports.helper';
import ToastHelper from '../../../../shared/helpers/toast/ToastHelper';

interface ISnapshotPartMenu {
  tableContainerRef: React.RefObject<HTMLDivElement>;
  scrollPartRef: React.RefObject<HTMLDivElement>;
  reportName: string;
  disabled?: boolean;
}

const explanations = [
  {
    title: 'Snapshot',
    description: 'Take a snapshot of your report to quickly export it as an image.',
    posX: '24px',
  },
];

/**
 * Container used to display the q&a part of top menu in a report
 *
 * @param param NS_Tabs.ITab props
 *
 */

const SnapshotPartMenu: React.FC<ISnapshotPartMenu> = ({
  tableContainerRef,
  reportName,
  scrollPartRef,
  disabled = false,
}) => {
  const hoverTimer: { current: NodeJS.Timeout | null } = useRef(null);
  const exportListRef = useRef<HTMLDivElement>(null);

  const [downloading, setDownloading] = useState<boolean>(false);
  const [isOpenExportList, setOpenExportList] = useState<boolean>(false);
  const [explanationSelected, setExplanationSelected] = useState<number>(-1);

  const handleInputHovered = (index: number) => {
    hoverTimer.current = setTimeout(() => {
      setExplanationSelected(index);
    }, 1000);
  };

  const handleInputNotHovered = () => {
    if (hoverTimer.current) {
      clearTimeout(hoverTimer.current);
      setExplanationSelected(-1);
    }
  };

  const exportReportImg = (type: ExportTypes) => () => {
    if (scrollPartRef.current) {
      scrollPartRef.current.scrollTo({ top: 0, left: 0 });
    }

    if (tableContainerRef.current) {
      setDownloading(true);
      exportSnapshot(type, reportName, tableContainerRef.current)
        .catch((e) => {
          ToastHelper.error(`Failed to download: ${e}`);
        })
        .finally(() => {
          setDownloading(false);
        });
    }
  };

  const handleClickExport = () => {
    if (!disabled) {
      setOpenExportList(true);
      handleInputNotHovered();
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (exportListRef.current && !(exportListRef.current as any).contains(e.target)) {
        setOpenExportList(false);
      }
    };
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  return (
    <div className="snapshotContainer">
      {explanationSelected > -1 && (
        <ExplanationBox
          title={explanations[explanationSelected].title}
          description={explanations[explanationSelected].description}
          posX={explanations[explanationSelected].posX}
        />
      )}
      <div className="subItem">
        <div
          id="exportButton"
          className={renderContent(disabled, 'div--disabled', '')}
          onClick={handleClickExport}
          onMouseEnter={(_e) => handleInputHovered(0)}
          onMouseLeave={() => handleInputNotHovered()}>
          <ReactSVG src={CameraSVG} className="svg-wrapper cameraSVG" />
        </div>
        {isOpenExportList && (
          <div className="exportList" ref={exportListRef}>
            {renderContent(
              downloading,
              <div className="exportList_content--downloading">
                <SpinnerLoader color="#727686" />
              </div>,
              <>
                <p className="exportListItem" onClick={exportReportImg(ExportTypes.PNG)}>
                  as PNG
                </p>
                <p className="exportListItem" onClick={exportReportImg(ExportTypes.JPEG)}>
                  as JPEG
                </p>
                <p className="exportListItem" onClick={exportReportImg(ExportTypes.PDF)}>
                  as PDF
                </p>
              </>,
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SnapshotPartMenu;
