import './style.scss';

import { FC, useCallback, useMemo, useState } from 'react';

import { formalize2DColumn, formalizeChartColumn2DConfigs } from '../../../../shared/charts/column';
import { formalizeChartFlowInFlowOut } from '../../../../shared/charts/column/flowInFlowOut';
import { formalizeStackedColumnConfigs } from '../../../../shared/charts/column/stacked';
import { formalizeStackedColumn2Configs } from '../../../../shared/charts/column/stacked/stacked2';
import { formalizeChartWaterfall } from '../../../../shared/charts/column/waterfall';
import { formalizeChartLineMedianConfigs } from '../../../../shared/charts/line';
import { formalizeChartPieConfigs } from '../../../../shared/charts/pie';
import { TChartData } from '../../../../shared/charts/utils';
import { IDonutData } from '../../../../shared/constant/chart.consts';
import { ChartDisplayType, ChartFamily } from '../../../../shared/constant/datasource.consts';
import ChartEmpty from './ChartEmpty';
import ChartWrapper from './ChartWrapper';

interface IChartBlockItem {
  chartData: Array<TChartData>;
  templateKey: ChartFamily;
}

export interface IFormalizeData {
  title: string;
  config: any;
  defaultValue?: IDonutData;
  reference?: string;
  exportLegend?: string;
}

export interface IResetPieData {
  index: number | null;
  isReset: boolean;
}

export const formalizeChartConfigs = (data: TChartData, index: number, getEvents, resetEvents): IFormalizeData => {
  switch (data.displayType) {
    case ChartDisplayType.PIE:
      return formalizeChartPieConfigs(data, index, getEvents, resetEvents);
    case ChartDisplayType.COLUMN_STACKED:
      return formalizeStackedColumnConfigs(data);
    case ChartDisplayType.COLUMN_STACKED_2:
      return formalizeStackedColumn2Configs(data);
    case ChartDisplayType.LINE_MEDIAN:
      return formalizeChartLineMedianConfigs(data);
    case ChartDisplayType.COLUMN_2D_MEDIAN:
      return formalizeChartColumn2DConfigs(data);
    case ChartDisplayType.WATERFALL:
      return formalizeChartWaterfall(data);
    case ChartDisplayType.FLOW_IN_FLOW_OUT:
      return formalizeChartFlowInFlowOut(data);
    default:
      return formalize2DColumn(data);
  }
};
const ChartBlockItem: FC<IChartBlockItem> = ({ chartData, templateKey }) => {
  const [pieData, setPieData] = useState<IDonutData>();
  const [reset, setReset] = useState<IResetPieData>({ isReset: true, index: null });
  const checkIfIsUnionNotView = (c: NS_API.IReportsAssemblyResponse & NS_API.IReportAssemblyIncludedReportData) => {
    return c?.includedReportId && c.includedReportId === '8' && !c?.reportParameters?.isUnion; // not show if not union
  };

  const chartToView = chartData.filter((c) => c.reportStatus !== 2 && !checkIfIsUnionNotView(c));

  const getEvents = useCallback((data: IDonutData) => {
    setPieData(data);
    setReset({ isReset: false, index: data.index });
  }, []);

  const resetEvents = useCallback((data: IDonutData) => {
    setReset({ isReset: true, index: data.index });
  }, []);
  const isImpairNumber = useMemo(() => {
    return { modulo: chartToView.length % 2, size: chartToView.length - 1 };
  }, [chartToView]);
  return (
    <>
      {chartToView.length === 0 ? (
        <div className="charts-wrapper flex justify-content-between">
          <ChartEmpty templateKey={templateKey} />
        </div>
      ) : (
        <div className="charts-wrapper flex justify-content-between">
          {chartToView.map((chart, index) => {
            const key = `${chart.reportTitle} - ${index}`;
            const dataConfig = formalizeChartConfigs(chart, index, getEvents, resetEvents);
            return chart.reportStatus === 0 ? (
              <ChartWrapper
                key={key}
                data={{ title: '', config: null }}
                reference=""
                isLoading={true}
                loadingDisplayType={chart?.displayType || ChartDisplayType.LINE_MEDIAN}
                isFullWidth={isImpairNumber.modulo !== 0 && isImpairNumber.size === index}
              />
            ) : (
              <ChartWrapper
                key={key}
                data={dataConfig}
                reference={dataConfig.reference ?? ''}
                pieData={pieData}
                defaultValue={dataConfig.defaultValue}
                index={index}
                reset={reset}
                isFullWidth={isImpairNumber.modulo !== 0 && isImpairNumber.size === index}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default ChartBlockItem;
