import './style.scss';

import { FC, MouseEvent, useEffect, useState } from 'react';

import Warning from '../../../assets/img/info-circle-yellow-filled.svg';
import { REPORT_ERROR_MESSAGE } from '../../../utils/constant';

interface IReportAlert {
  data?: Array<string>;
}
const ReportAlert: FC<IReportAlert> = (props) => {
  const { data } = props;
  const [alerts, setAlerts] = useState<Array<string>>([]);

  useEffect(() => {
    setAlerts(data ? data : []);
  }, [data]);

  const onClose = (key: number) => (ev: MouseEvent) => {
    if (alerts?.[key]) {
      setAlerts([...alerts.filter((a, index) => index !== key)]);
    }
  };

  const getAlertDescription = (alert: string): string => {
    const message = REPORT_ERROR_MESSAGE.find((r) => r.key == alert);
    return message?.value ?? '';
  };

  const renderRow = (alerts: Array<string>) => {
    return (
      <div className={`report-row-container ${alerts.length == 0 ? 'report-row-hide' : ''}`}>
        {alerts.length !== 0 &&
          alerts.map((a, index) => {
            const key = `${a} - ${index}`;
            return (
              <div className="report-alert-row" key={key}>
                <div className="report-row-content">
                  <div className="report-row-icon">
                    <img src={Warning} alt="success" />
                  </div>
                  {` `}
                  <div className="report-row-text">{getAlertDescription(a)}</div>
                </div>
                <div onClick={onClose(index)} className="report-alert-close">
                  x
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  return <div className="report-alert-container">{renderRow(alerts)}</div>;
};

export default ReportAlert;
