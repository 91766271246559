const Buffer = require('buffer/').Buffer;
import * as XLSX from 'xlsx';

export const FILE_TYPE = {
  TXT: 'text/plain',
  CSV: 'text/csv',
};

/**
 * Workers are used to reduce load on a web application's main thread, so that it can
 * run without having the page freezing. To use a worker we need to declare a method
 * in this file and then add it to the types.d.ts file, so that it is compatible with
 * TypeScript. Once it is done we can instantiate workers and call those methods in
 * files that need them. Once a component is unmounted the worker terminates itself.
 */

export async function readSheets(
  data: any,
  extension: string,
  dataEncodingType?: 'base64' | 'binary' | 'buffer' | 'file' | 'array' | 'string',
): Promise<XLSX.WorkBook> {
  const options = {
    cellDates: true,
    dateNF: 'yyyy-mm-dd',
    type: dataEncodingType,
    cellHTML: false,
    cellNF: true,
    raw: extension === 'text/csv' || extension === 'csv' || extension === 'text/plain',
    dense: true,
  };
  console.debug('XLSX.read option', options);
  return XLSX.read(data, options);
}

export async function readXls(
  workbook: any,
  worksheetName: string,
): Promise<[Buffer, Partial<NS_API.IDatasourceInfo>]> {
  const worksheet = workbook.Sheets[worksheetName];

  const normalizedLineBreaker = '\n';
  const normalizedSeparator = ';';

  for (const rowSheet of worksheet) {
    if (rowSheet) {
      for (const columnSheet of rowSheet) {
        if (columnSheet) {
          if (columnSheet.hasOwnProperty('w')) {
            columnSheet.w = columnSheet.w.replace(/;|\n/g, ' ');
            if (columnSheet.hasOwnProperty('z') && columnSheet.t === 'n') {
              columnSheet.z = 0;
              delete columnSheet.w;
              columnSheet.w = XLSX.utils.format_cell(columnSheet);
            }
          }
        }
      }
    }
  }

  const sheetToCsv = XLSX.utils.sheet_to_csv(worksheet, {
    FS: normalizedSeparator,
    RS: normalizedLineBreaker,
    blankrows: false,
  });

  let length = 1;
  for (let i = 0; i < sheetToCsv.length - 1; i++) {
    if (sheetToCsv[i] === '\n') {
      length++;
    }
  }

  const worksheetBuf = Buffer.from(sheetToCsv, 'utf-8');
  const datasourceInfo: Partial<NS_API.IDatasourceInfo> = {
    lines: length,
    lineBreaker: normalizedLineBreaker,
    separator: normalizedSeparator,
    size: worksheetBuf.byteLength,
  };

  return [worksheetBuf, datasourceInfo];
}

export async function readXlsWorksheets(buffer: ArrayBuffer): Promise<string[]> {
  return XLSX.read(buffer, {
    type: 'buffer',
    bookSheets: true,
  }).SheetNames;
}
