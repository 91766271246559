export const PIE_TOP_TEN_LABEL = 'TOP 10';

export const UNITS_LIST = ['€', 'K€', 'M€'];

export const NOTIFICATION_TYPE = {
  CHART_REPORT_READY: 'INCLUDED_REPORT_READY',
  DATASOURCE_PROGRESS: 'INTERPRETATION_PROGRESS',
  EXPORT_DONE: 'EXPORT_DONE',
};

export const CHARTS_TYPES = ['PL', 'BS', 'C'];

export const LINE_CHARTS_COLORS = {
  LINE: '#7eceb0',
  MARKERS: '#40a781',
};

export interface IInsideDataSetData {
  value: string;
  label?: string;
  color?: string;
  displayValue?: string;
  showValue?: number;
  totals?: number;
}
export interface IReportAssemblyNotif {
  workspaceId: string;
  reportAssemblyId: string;
  includedReportId: number;
}

export interface IDonutData {
  index: number;
  id: string;
  value: string;
  pValue: string;
  color: string;
  label?: string;
}
