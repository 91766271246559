// Style
import './style.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DragPreviewImage, useDrag, useDrop } from 'react-dnd';

import previewImage from '../../../assets/img/drag-preview-green.svg';
import { ItemTypes } from '../../../shared/constant/drapAndDrop.consts';
import { isDisable } from '../../../shared/helpers/string/classnames.helper';
import ToastHelper from '../../../shared/helpers/toast/ToastHelper';
// Scorf components
import ToggleListContextMenu from '../ToggleListContextMenu';

interface ITGLElement {
  /**
   * Tab index
   */
  index: number;
  /**
   * Tab label
   */
  label: string;
  /**
   * Index of current tab in display
   */
  selectedSheet: number;
  /**
   * Tab click handler
   */
  handleClick: () => void;
  renameFn: (name: string) => number;
  /**
   * Tab delete handler
   */
  deleteFn: (index: number) => void;
  scrollFn: () => void;
  getParentRef: () => React.RefObject<HTMLDivElement>;
  setMultiSelectedTabs: (val: number[]) => void;
  /**
   * Selected tabs' indexes
   */
  multiSelectedTabs: number[];
  /**
   * Is a static tab (which can not be renamed, deleted, etc.)?
   */
  staticTab: boolean;
  /**
   * Drop handler
   */
  dragDropComplete: (draggedTabIndex, droppedTabIndex) => void;
  /**
   * Is tab disabled
   */
  disabled?: boolean;
}

/**
 * Toggle List element component, it corresponds to one opened tab
 */
const ToggleListElement: React.FC<ITGLElement> = ({
  index,
  label,
  selectedSheet,
  handleClick,
  renameFn,
  deleteFn,
  scrollFn,
  getParentRef,
  multiSelectedTabs,
  setMultiSelectedTabs,
  staticTab,
  dragDropComplete,
  disabled = false,
}) => {
  // Refs

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const tabsRef = useRef<number[]>();
  const selectedTabRef = useRef<number>();

  // States
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(label);
  const [name, setName] = useState<string>(label);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const [xPos, setXPos] = useState<number>(0);
  const [yPos, setYPos] = useState<number>(0);

  // Drag and Drop
  const [{ isDragging }, dragger, dragPreview] = useDrag({
    item: { draggedTabIndex: index },
    type: ItemTypes.TAB,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver, dragItem }, dropper] = useDrop({
    accept: ItemTypes.TAB,
    drop: () => {
      dragDropComplete(dragItem.draggedTabIndex, index);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      dragItem: monitor.getItem() as any,
    }),
  });

  const [isDropTarget, setIsDropTarget] = useState<boolean>(false);

  // Effects

  useEffect(() => {
    if (name === label) {
      return;
    }
    if (renameFn(name) === 0) {
      setName(label);
      setInputValue(label);
    }
    // eslint-disable-next-line
  }, [name]);

  useEffect(() => {
    setName(label);
    setInputValue(label);
  }, [label]);

  useEffect(() => {
    setIsContextMenuOpen(false);
  }, [disabled]);

  useEffect(() => {
    if (isInEditMode) {
      handleFocus();
    }
    scrollFn();
    // eslint-disable-next-line
  }, [isInEditMode]);

  useEffect(() => {
    if (selectedSheet === index && ref.current) {
      const parentRef = getParentRef();
      if (!parentRef.current) {
        return;
      }
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line
  }, [selectedSheet, isInEditMode]);

  useEffect(() => {
    document.addEventListener('click', (_event) => {
      setIsContextMenuOpen(false);
    });
    return () => {
      document.removeEventListener('click', (_event) => {
        setIsContextMenuOpen(false);
      });
    };
  }, []);

  useEffect(() => {
    document.addEventListener('contextmenu', handleDocumentContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleDocumentContextMenu);
    };
  }, []);

  useEffect(() => {
    tabsRef.current = multiSelectedTabs;
  }, [multiSelectedTabs]);

  useEffect(() => {
    selectedTabRef.current = selectedSheet;
  }, [selectedSheet]);

  useEffect(() => {
    if (isDragging) {
      if (selectedTabRef.current) {
        setMultiSelectedTabs([selectedTabRef.current]);
      }
    }
  }, [isDragging]);

  useEffect(() => {
    setIsDropTarget(isOver);
  }, [isOver]);

  useEffect(() => {
    if (!staticTab) {
      dropper(ref);
    }
  }, [ref.current, staticTab]);

  // Functions

  const handleDoubleClick = () => {
    if (!staticTab) {
      setIsInEditMode(true);
    }
  };

  const handleRename = useCallback(() => {
    handleClick();
    if (!staticTab) {
      setIsInEditMode(true);
    }
  }, [staticTab]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      validateRename(inputValue.trim());
    }
    if (event.key === 'Escape') {
      setIsInEditMode(false);
    }
  };

  const handleFocus = () => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 1);
  };

  const handleBlur = () => {
    validateRename(inputValue.trim());
  };

  const validateRename = (newName: string) => {
    const isValid: boolean = newName.search(/[\*\x22:<>[\]{\}\\`'@$&#=?+%/|]/g) === -1;
    if (!newName) {
      setInputValue(name);
    } else if (isValid) {
      setName(inputValue.trim());
    } else {
      setInputValue(name);
      ToastHelper.error(`File name cannot contain any of the following characters: *":<>[]{}\\\`'@$&#=?+%/|`);
    }
    setIsInEditMode(false);
  };

  const updateMultiSelectedTabs = () => {
    if (!staticTab && tabsRef.current && index !== selectedTabRef.current) {
      const tmpTabs = [...tabsRef.current];
      const indexInTab = tmpTabs.indexOf(index);
      if (indexInTab === -1) {
        tmpTabs.push(index);
        setMultiSelectedTabs(tmpTabs);
      } else {
        tmpTabs.splice(indexInTab, 1);
        setMultiSelectedTabs(tmpTabs);
      }
    }
  };

  const handleSelection = useCallback(
    (event) => {
      if (isInEditMode || staticTab) {
        return;
      }

      if (event.shiftKey && selectedTabRef.current) {
        document.getSelection()?.removeAllRanges();
        const newSelectedTabs: number[] = [];
        if (index < selectedTabRef.current) {
          for (let i = index; i <= selectedTabRef.current; i++) {
            newSelectedTabs.push(i);
          }
        } else {
          for (let i = selectedTabRef.current; i <= index; i++) {
            newSelectedTabs.push(i);
          }
        }
        setMultiSelectedTabs(newSelectedTabs);
        event.preventDefault();
        return;
      }

      if (event.ctrlKey) {
        updateMultiSelectedTabs();
      }
      event.stopPropagation();
      event.preventDefault();
    },
    // eslint-disable-next-line
    [ref.current],
  );

  const handleContextMenu = (event) => {
    if (isInEditMode || staticTab) {
      return;
    }

    if (event.ctrlKey) {
      updateMultiSelectedTabs();
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (!tabsRef.current?.includes(index)) {
      if (selectedTabRef.current) {
        setMultiSelectedTabs([selectedTabRef.current]);
      }
    }
    event.preventDefault();
    setIsContextMenuOpen(true);
    setXPos(event.pageX);
    setYPos(event.pageY);
  };

  const handleDocumentContextMenu = (e) => {
    if (!(ref.current as any)?.contains(e.target)) {
      setIsContextMenuOpen(false);
    }
  };

  return (
    <>
      <DragPreviewImage connect={dragPreview} src={previewImage} />
      <div
        className={`toggleListElement ${staticTab && 'staticTab'} 
        ${multiSelectedTabs.includes(index) && multiSelectedTabs.length > 1 && 'multiSelected'}
        ${disabled && 'disabled'}`}
        onContextMenu={(event) => !disabled && handleContextMenu(event)}
        onClick={(event) => !disabled && handleSelection(event)}
        ref={ref}>
        <div className={'vlPlaceholder'}>
          <div className={`vl  ${isDropTarget ? 'highlightBorder' : ''}`} />
        </div>
        <div
          className={index === selectedSheet ? 'toggleListElementSelected' : 'toggleListElementDefault'}
          ref={staticTab ? null : dragger}>
          <div
            className={
              index === selectedSheet
                ? 'toggleListButton toggleListButtonSelected'
                : 'toggleListButton toggleListButtonDefault'
            }
            onClick={(e) => {
              if (!e.shiftKey && !e.ctrlKey && !disabled) {
                handleClick();
              }
            }}>
            <span className="dynamicLongSpan">{inputValue}</span>
            {isInEditMode ? (
              <input
                className={'toggleListInput'}
                ref={inputRef}
                maxLength={100}
                defaultValue={name}
                onChange={handleChange}
                onKeyDown={handleEnter}
                onBlur={handleBlur}
                onFocus={(event) => event.currentTarget.select()}
              />
            ) : (
              <div
                className={`namePlaceholder ${isDisable(disabled)}`}
                title={name}
                onDoubleClick={() => !disabled && handleDoubleClick()}>
                {name}
              </div>
            )}
          </div>
        </div>
        <ToggleListContextMenu
          isOpen={isContextMenuOpen}
          xPos={xPos}
          yPos={yPos}
          deleteFn={deleteFn}
          handleRename={handleRename}
          index={index}
        />
      </div>
    </>
  );
};

export default ToggleListElement;
