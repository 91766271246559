import React from 'react';

// Style
import './style.scss';
import { wrappedTextDiv } from '../../../../shared/helpers/helpers';
import { formatReportValue } from '../../../../shared/helpers/report/reports.helper';

import NumbersRegulator from '../../../reportComponents/MainMenu/NumbersPartMenu/NumbersRegulator';
interface INumbersOption {
  currencyParams: NS_Table.ICurrency;
  setCurrencyParams: React.Dispatch<React.SetStateAction<NS_Table.ICurrency>>;
}

/**
 * Component used to change color configuration
 * @oaram INumbersOption
 */

const NumbersOption: React.FC<INumbersOption> = ({ currencyParams, setCurrencyParams }) => {
  // Function
  const restoreDefault = () => {
    setCurrencyParams({ format: '0,0', displayUnit: 1 });
  };

  return (
    <div className="colorOptionContainer numbersContainer">
      <h3 className="optionTitle">Numbers</h3>
      <div className="numbersRestoreButton" onClick={restoreDefault}>
        Restore default styles
      </div>
      <label className="optionDescription">Formatting options that apply to all numbers in the Workspace.</label>
      <div className="row">
        <NumbersRegulator currencyParams={currencyParams} setCurrencyParams={setCurrencyParams} />
        <div className="subItem">
          <div className="smallTitle">Preview</div>
          <div className="row preview">
            {wrappedTextDiv(
              { width: '100%' },
              formatReportValue('123456789', 'x', 'currency', 'EUR, 2', currencyParams.displayUnit),
              false,
              false,
              currencyParams.format,
              'previewNumber',
            )}
            {wrappedTextDiv(
              { width: '100%' },
              formatReportValue('-123456789', 'x', 'currency', 'EUR, 2', currencyParams.displayUnit),
              false,
              false,
              currencyParams.format,
              'previewNumber',
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumbersOption;
