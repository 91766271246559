// Style
import '../style.scss';
import './style.scss';

import React, { FC } from 'react';

interface ITemplateItemProps {
  selectedItemName: string;
  setSelectedItem: (id: string) => void;
  disabled?: boolean;
  groupedElements: NS_REPORT.IGroupedSelectList;
  openDispositionDropdown: boolean;
  isFetching: boolean;
  closeDropdown: () => void;
}

const TemplateItem: FC<ITemplateItemProps> = (props) => {
  const {
    disabled,
    groupedElements,
    selectedItemName,
    openDispositionDropdown,
    isFetching,
    setSelectedItem,
    closeDropdown,
  } = props;
  const groupedElemenetsArray = Object.entries(groupedElements);
  const lastElement = groupedElemenetsArray.length - 1;
  return (
    <div
      className={`select-list-body template-select-list-body ${!openDispositionDropdown && 'select-list-hide-body'}`}>
      {openDispositionDropdown && groupedElements && isFetching ? (
        <div className="intermediateTitle">Fetching...</div>
      ) : (
        openDispositionDropdown &&
        groupedElements &&
        !isFetching && (
          <>
            {groupedElemenetsArray.map(([groupName, elementsOfGroup], index) => {
              const key = `${groupName}-${index}`;
              return (
                <React.Fragment key={key}>
                  <div className="intermediateTitle" key={groupName}>
                    {groupName}
                  </div>
                  <div className="template-report-select-list">
                    {elementsOfGroup.map((el) => (
                      <div
                        className={`element-item ${el.id === selectedItemName && 'element-active-item'}`}
                        key={el.id}
                        title={`${el.name}`}
                        onClick={() => {
                          if (!disabled && !el?.isDisable) {
                            setSelectedItem(el.id);
                            closeDropdown();
                          }
                        }}>
                        <div className={`toolBarText template-item-text-overflow ${el?.isDisable && 'div--disabled'}`}>
                          <strong>{el.id}</strong>
                        </div>
                      </div>
                    ))}
                  </div>
                  {index < lastElement && (
                    <div className="select-list-separator">
                      <div className="select-list-spacer"></div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </>
        )
      )}
    </div>
  );
};
export default TemplateItem;
