import { format } from 'date-fns';

// API
import { API_STATUS } from '../../../utils/constant';
import { updateGraphCreateNode } from '../../api/mapping';
import { getAllTemplateCollectionsForWorkspace } from '../../api/templateCollection';
import { DefaultTemplateCollectionName } from '../../constant/datasource.consts';
import { MappingCommandsParams } from '../../constant/mapping.consts';
import { GraphCommandOperationName } from '../report/reports.helper';
import ToastHelper from '../toast/ToastHelper';
import { dataDestructuring } from '../tree/front.tree.util';

const COPY_OF_DEFAULT_TEMPLATE_COLLECTION_NAME = `Copy of ${DefaultTemplateCollectionName}`;
const NOW = format(new Date(), 'dd/MM/yyyy hh:mm');
export const addParentHelper = async (
  mappingTree,
  currentCommands,
  cellSelected,
  graphId,
  workspaceId,
  setNewOperation,
  currentMetadata,
  templateKey,
  selectedReportId,
  selectedDataSourceGraph,
): Promise<{ moveExecuted: boolean; newDataSourceGraph: string }> => {
  return new Promise((resolve, reject) => {
    checkStandartCollection(workspaceId as string, selectedDataSourceGraph as string)
      .then(({ isStandard, newName }) => {
        let newGraphName: string | undefined = undefined;
        if (isStandard) {
          newGraphName = newName;
        }
        const newArray = dataDestructuring(mappingTree);
        const newCommands = dataDestructuring(currentCommands);
        // Deprecated case
        const commands: Array<NS_API.ICreateNodeCommands> = [];
        commands[0] = {
          type: GraphCommandOperationName.CREATE_NODE,
          parameters: { name: MappingCommandsParams.newParent },
        };
        if (cellSelected.length === 0 && graphId) {
          updateGraphCreateNode(workspaceId, graphId, templateKey, selectedReportId, commands, newGraphName)
            .then((res) => {
              const { data } = res;
              const { commandsExecutionStatus, status, updatedReport } = data;
              if (commandsExecutionStatus && commandsExecutionStatus[0]) {
                const { message, additionalInfo } = commandsExecutionStatus[0];
                switch (status) {
                  case API_STATUS.FAILED:
                    if (message) {
                      ToastHelper.error(message);
                    } else {
                      ToastHelper.error('Failed to create new parent');
                    }
                    resolve({ moveExecuted: false, newDataSourceGraph: '' });
                    break;
                  case API_STATUS.SUCCESS:
                    if (message) {
                      ToastHelper.success(message);
                    }
                    if (additionalInfo?.newNodeId) {
                      const { newNodeId, name } = additionalInfo;
                      newArray.push({
                        parent: '',
                        id: newNodeId,
                        data: {
                          parent: '',
                          id: newNodeId,
                          content: {
                            rowContentType: 'C',
                            rowContentName: name,
                            rowDimension: MappingCommandsParams.accountName,
                            rowContentId: newNodeId,
                            measure: '',
                            templateCoefficient: 1,
                            rowParams: {
                              opened: true,
                              cssStyle: '',
                            },
                          },
                        },
                        children: [],
                      });
                      newCommands[
                        Object.keys(newCommands).length === 0
                          ? 0
                          : parseInt(Object.keys(newCommands)[Object.keys(newCommands).length - 1]) + 1
                      ] = {
                        type: MappingCommandsParams.addParent,
                        parameters: {
                          selectedNodesIds: cellSelected,
                          newNodeId,
                        },
                      };
                      setNewOperation(newArray, currentMetadata, newCommands);
                    }
                }
              }
              let dateSourceGraph = selectedDataSourceGraph;
              if (updatedReport?.graphId) {
                dateSourceGraph = updatedReport.graphId;
              }
              resolve({ moveExecuted: true, newDataSourceGraph: dateSourceGraph });
            })
            .catch((err) => {
              ToastHelper.error('Failed to create new parent');
              resolve({ moveExecuted: false, newDataSourceGraph: '' });
            });
        }
        // One cell to add a parent
        else if (cellSelected.length === 1) {
          let parentId = '';
          let newParentId = '';
          let newChild = '';
          let parentOfSelectedNode;

          const loopArrayParent = (child: NS_API.IGraph, selected) => {
            child.forEach((item, index) => {
              if (item.id === selected) {
                parentOfSelectedNode = dataDestructuring(item);
                child[index].children.push(newChild);
              } else if (item.data.content.rowContentType !== 'N' && item.children) {
                loopArrayParent(item.children, selected);
              }
            });
          };

          const loopToFindParent = (child: NS_API.IGraph, selected) => {
            child.forEach((item) => {
              if (item.id === selected) {
                parentOfSelectedNode = dataDestructuring(item);
              } else if (item.data.content.rowContentType !== 'N' && item.children) {
                loopToFindParent(item.children, selected);
              }
            });
          };

          if (graphId) {
            updateGraphCreateNode(workspaceId, graphId, templateKey, selectedReportId, commands, newGraphName)
              .then((res) => {
                const { data } = res;
                const { commandsExecutionStatus, status, updatedReport } = data;
                if (commandsExecutionStatus && commandsExecutionStatus[0]) {
                  const { message, additionalInfo } = commandsExecutionStatus[0];
                  switch (status) {
                    case API_STATUS.FAILED:
                      if (message) {
                        ToastHelper.error(message);
                      } else {
                        ToastHelper.error('Failed to create new parent');
                      }
                      resolve({ moveExecuted: false, newDataSourceGraph: '' });
                      break;
                    case API_STATUS.SUCCESS:
                      if (message) {
                        ToastHelper.success(message);
                      }
                      if (additionalInfo?.newNodeId) {
                        const { newNodeId, name } = additionalInfo;
                        const loopArray = (child: NS_API.IGraph, selected) => {
                          child.forEach((item, index) => {
                            if (item.data.content.rowContentId === selected) {
                              if (parentId !== '' && parentId === item.parent) {
                                newChild = item;
                                loopArrayParent(mappingTree, newParentId);
                                child.splice(index, 1);
                              } else {
                                parentId = item.parent;
                                loopToFindParent(mappingTree, parentId);
                                child[index] = {
                                  parent: parentId,
                                  id: newNodeId,
                                  data: {
                                    parent: parentId,
                                    id: newNodeId,
                                    content: {
                                      rowContentType: 'C',
                                      rowContentName: name,
                                      rowDimension: MappingCommandsParams.accountName,
                                      rowContentId: newNodeId,
                                      measure: '',
                                      templateCoefficient: 1,
                                      rowParams: {
                                        opened: true,
                                        cssStyle: '',
                                        locked: true,
                                      },
                                      value: Array.from({ length: item.data.content.value.length }, () => 0),
                                    },
                                  },
                                  children: [item],
                                };
                                newParentId = newNodeId;
                                // Update selected cell's parent
                                item.parent = newParentId;

                                const childrenIdsAndNamesOfParentNodeOfSelectedNode: {} = {};
                                if (item.data.content.rowContentType === 'N' && parentOfSelectedNode) {
                                  parentOfSelectedNode.children.forEach((childOfParentOfDestinationNode) => {
                                    childrenIdsAndNamesOfParentNodeOfSelectedNode[
                                      childOfParentOfDestinationNode.data.content.rowContentId
                                    ] = childOfParentOfDestinationNode.data.content.rowContentName;
                                  });
                                }
                                if (Object.keys(childrenIdsAndNamesOfParentNodeOfSelectedNode).length > 0) {
                                  newCommands[
                                    Object.keys(newCommands).length === 0
                                      ? 0
                                      : parseInt(Object.keys(newCommands)[Object.keys(newCommands).length - 1]) + 1
                                  ] = {
                                    type: MappingCommandsParams.addParent,
                                    parameters: {
                                      selectedNodesIds: cellSelected,
                                      newNodeId: child[index].id,
                                      parentIdOfSelectedNode: parentOfSelectedNode.data.content.rowContentId,
                                      childrenIdsAndNamesOfParentNodeOfSelectedNode:
                                        childrenIdsAndNamesOfParentNodeOfSelectedNode,
                                    },
                                  };
                                } else {
                                  newCommands[
                                    Object.keys(newCommands).length === 0
                                      ? 0
                                      : parseInt(Object.keys(newCommands)[Object.keys(newCommands).length - 1]) + 1
                                  ] = {
                                    type: MappingCommandsParams.addParent,
                                    parameters: {
                                      selectedNodesIds: cellSelected,
                                      newNodeId: child[index].id,
                                    },
                                  };
                                }
                                setNewOperation(newArray, currentMetadata, newCommands);
                              }
                            } else if (item.data.content.rowContentType !== 'N' && item.children) {
                              loopArray(item.children, selected);
                            }
                          });
                        };

                        loopArray(newArray, cellSelected[0]);
                      }
                  }
                }
                let dateSourceGraph = selectedDataSourceGraph;
                if (updatedReport?.graphId) {
                  dateSourceGraph = updatedReport.graphId;
                }
                resolve({ moveExecuted: true, newDataSourceGraph: dateSourceGraph });
              })
              .catch((err) => {
                ToastHelper.error('Failed to create new parent');
                resolve({ moveExecuted: false, newDataSourceGraph: '' });
              });
          }
        }
        // Several cells to add a common parent
        else {
          let parentOfSelectedNode;
          const listOfSelectedNodesIndex: number[] = [];

          const loopToFindParent = (child: NS_API.IGraph, selected) => {
            child.forEach((item) => {
              if (item.id === selected) {
                parentOfSelectedNode = dataDestructuring(item);
              } else if (item.data.content.rowContentType !== 'N' && item.children) {
                loopToFindParent(item.children, selected);
              }
            });
          };

          const itemArray = [] as Array<NS_API.IGraph>;
          const loopArray = (child: NS_API.IGraph, selected) => {
            child.forEach((item, index) => {
              if (selected.indexOf(item.data.content.rowContentId) !== -1) {
                listOfSelectedNodesIndex.push(index);
                if (itemArray.length + 1 === selected.length && graphId) {
                  const lowestIndex: number = Math.min(...listOfSelectedNodesIndex);
                  loopToFindParent(mappingTree, item.parent);
                  updateGraphCreateNode(workspaceId, graphId, templateKey, selectedReportId, commands, newGraphName)
                    .then((res) => {
                      const { data } = res;
                      const { commandsExecutionStatus, status, updatedReport } = data;
                      if (commandsExecutionStatus && commandsExecutionStatus[0]) {
                        const { message, additionalInfo } = commandsExecutionStatus[0];
                        switch (status) {
                          case API_STATUS.FAILED:
                            if (message) {
                              ToastHelper.error(message);
                            } else {
                              ToastHelper.error('Failed to create new parent');
                            }
                            resolve({ moveExecuted: false, newDataSourceGraph: '' });
                            break;
                          case API_STATUS.SUCCESS:
                            if (message) {
                              ToastHelper.success(message);
                            }
                            if (additionalInfo?.newNodeId) {
                              const { newNodeId, name } = additionalInfo;
                              child[lowestIndex] = {
                                parent: parentOfSelectedNode.id,
                                id: newNodeId,
                                data: {
                                  parent: parentOfSelectedNode.id,
                                  id: newNodeId,
                                  content: {
                                    rowContentType: 'C',
                                    rowContentName: name,
                                    rowDimension: MappingCommandsParams.accountName,
                                    rowContentId: newNodeId,
                                    measure: '',
                                    templateCoefficient: 1,
                                    rowParams: {
                                      opened: true,
                                      cssStyle: '',
                                      locked: true,
                                    },
                                    value: Array.from({ length: item.data.content.value.length }, () => 0),
                                  },
                                },
                                children: [...itemArray, item],
                              };
                              item.parent = newNodeId;

                              itemArray.push(dataDestructuring(item));
                              itemArray.forEach((nodeToRemove) => {
                                child.forEach((son, i) => {
                                  // Change parent id
                                  nodeToRemove.parent = newNodeId;
                                  if (son.data.content.rowContentId === nodeToRemove.data.content.rowContentId) {
                                    child.splice(i, 1);
                                  }
                                });
                              });

                              // use for to go reverse and thanks to that the splice wont affect the next ones
                              // for (let i = child.length - 1; i >= 0; i--) {
                              //   itemArray.forEach((nodeToRemove) => {
                              //     console.log(nodeToRemove.data.content.rowContentName)
                              //     // Change parent id
                              //     nodeToRemove.parent = data.newNodeId;
                              //     if (child[i].data.content.rowContentId === nodeToRemove.data.content.rowContentId) {
                              //       child.splice(i, 1);
                              //     }
                              //   });
                              // }
                              const childrenIdsAndNamesOfParentNodeOfSelectedNode: {} = {};
                              if (item.data.content.rowContentType === 'N' && parentOfSelectedNode) {
                                parentOfSelectedNode.children.forEach((childOfParentOfDestinationNode) => {
                                  childrenIdsAndNamesOfParentNodeOfSelectedNode[
                                    childOfParentOfDestinationNode.data.content.rowContentId
                                  ] = childOfParentOfDestinationNode.data.content.rowContentName;
                                });
                              }
                              if (Object.keys(childrenIdsAndNamesOfParentNodeOfSelectedNode).length > 0) {
                                newCommands[
                                  Object.keys(newCommands).length === 0
                                    ? 0
                                    : parseInt(Object.keys(newCommands)[Object.keys(newCommands).length - 1]) + 1
                                ] = {
                                  type: MappingCommandsParams.addParent,
                                  parameters: {
                                    selectedNodesIds: cellSelected,
                                    newNodeId,
                                    parentIdOfSelectedNode: parentOfSelectedNode.data.content.rowContentId,
                                    childrenIdsAndNamesOfParentNodeOfSelectedNode:
                                      childrenIdsAndNamesOfParentNodeOfSelectedNode,
                                  },
                                };
                              } else {
                                newCommands[
                                  Object.keys(newCommands).length === 0
                                    ? 0
                                    : parseInt(Object.keys(newCommands)[Object.keys(newCommands).length - 1]) + 1
                                ] = {
                                  type: MappingCommandsParams.addParent,
                                  parameters: {
                                    selectedNodesIds: cellSelected,
                                    newNodeId,
                                  },
                                };
                              }
                              setNewOperation(newArray, currentMetadata, newCommands);
                            }
                        }
                      }
                      let dateSourceGraph = selectedDataSourceGraph;
                      if (updatedReport?.graphId) {
                        dateSourceGraph = updatedReport.graphId;
                      }
                      resolve({ moveExecuted: true, newDataSourceGraph: dateSourceGraph });
                    })
                    .catch((err) => {
                      ToastHelper.error('Failed to create new parent');
                      resolve({ moveExecuted: false, newDataSourceGraph: '' });
                    });
                } else {
                  itemArray.push(dataDestructuring(item));
                }
              } else if (item.data.content.rowContentType !== 'N' && item.children) {
                loopArray(item.children, selected);
              }
            });
          };
          const listId = [] as Array<string>;
          const loopArrayCheckId = (child: NS_API.IGraph, selected, parentId) => {
            child.forEach((item) => {
              if (item.data.content.rowContentId === selected) {
                listId.push(parentId);
              } else if (item.data.content.rowContentType !== 'N' && item.children) {
                loopArrayCheckId(item.children, selected, item.data.content.rowContentId);
              }
            });
          };
          cellSelected.forEach((selected) => {
            loopArrayCheckId(newArray, selected, '');
          });
          if (listId.every((val, _i, arr) => val === arr[0])) {
            loopArray(newArray, cellSelected);
            return true;
          } else {
            resolve({ moveExecuted: false, newDataSourceGraph: '' });
          }
        }
      })
      .catch((err) => {});
  });
};

export const addChildHelper = async (
  mappingTree,
  currentCommands,
  cellSelected,
  graphId,
  workspaceId,
  setNewOperation,
  currentMetadata,
  templateKey,
  selectedReportId,
  selectedDataSourceGraph,
): Promise<string> => {
  return new Promise((resolve, reject) => {
    checkStandartCollection(workspaceId as string, selectedDataSourceGraph as string)
      .then(({ isStandard, newName }) => {
        let newGraphName: string | undefined = undefined;
        if (isStandard) {
          newGraphName = newName;
        }
        const commands: Array<NS_API.ICreateNodeCommands> = [];
        commands[0] = {
          type: GraphCommandOperationName.CREATE_NODE,
          parameters: { name: MappingCommandsParams.newChild },
        };
        const newArray = dataDestructuring(mappingTree);
        const newCommands = dataDestructuring(currentCommands);
        if (cellSelected.length > 0 && cellSelected.length < 2) {
          const loopArray = (child: NS_API.IGraph, selected) => {
            child.forEach((item) => {
              if (item.data.content.rowContentId === selected && graphId) {
                updateGraphCreateNode(workspaceId, graphId, templateKey, selectedReportId, commands, newGraphName)
                  .then((res) => {
                    const { data } = res;
                    const { commandsExecutionStatus, status, updatedReport } = data;
                    if (commandsExecutionStatus && commandsExecutionStatus[0]) {
                      const { message, additionalInfo } = commandsExecutionStatus[0];
                      switch (status) {
                        case API_STATUS.FAILED:
                          if (message) {
                            ToastHelper.error(message);
                          } else {
                            ToastHelper.error('Failed to create child');
                          }
                          resolve(selectedDataSourceGraph);
                          break;
                        case API_STATUS.SUCCESS:
                          if (message) {
                            ToastHelper.success(message);
                          }
                          if (additionalInfo?.newNodeId) {
                            const { newNodeId, name } = additionalInfo;
                            item.children.unshift({
                              parent: item.id,
                              id: newNodeId,
                              data: {
                                parent: item.id,
                                id: newNodeId,
                                content: {
                                  rowContentType: 'C',
                                  rowContentName: name,
                                  rowDimension: MappingCommandsParams.accountName,
                                  rowContentId: newNodeId,
                                  measure: '',
                                  templateCoefficient: 1,
                                  rowParams: {
                                    opened: false,
                                    cssStyle: '',
                                  },
                                  value: Array.from({ length: item.data.content.value.length }, () => 0),
                                },
                              },
                              children: [],
                            });
                            item.data.content.rowParams.opened = true;
                            const childrenIdsAndNamesOfSelectedNode: {} = {};

                            item.children.forEach((childNode) => {
                              childrenIdsAndNamesOfSelectedNode[childNode.data.content.rowContentId] =
                                childNode.data.content.rowContentName;
                            });

                            newCommands[
                              Object.keys(newCommands).length === 0
                                ? 0
                                : parseInt(Object.keys(newCommands)[Object.keys(newCommands).length - 1]) + 1
                            ] = {
                              type: MappingCommandsParams.addChild,
                              parameters: {
                                selectedNodeId: selected,
                                newNodeId,
                                childrenIdsAndNamesOfSelectedNode: childrenIdsAndNamesOfSelectedNode,
                              },
                            };
                            setNewOperation(newArray, currentMetadata, newCommands);
                          }
                      }
                    }
                    let dateSourceGraph = selectedDataSourceGraph;
                    if (updatedReport?.graphId) {
                      dateSourceGraph = updatedReport.graphId;
                    }
                    resolve(dateSourceGraph);
                  })
                  .catch((err) => {
                    ToastHelper.error('Failed to create child');
                    resolve(selectedDataSourceGraph);
                  });
              } else if (item.data.content.rowContentType !== 'N' && item.children) {
                loopArray(item.children, selected);
              }
            });
          };
          loopArray(newArray, cellSelected[0]);
        }
      })
      .catch((err) => {});
  });
};

export const checkStandartCollection = async (
  workspaceId: string,
  selectedDataSourceGraph: string,
): Promise<{ isStandard: boolean; newName: string }> => {
  const result = await getAllTemplateCollectionsForWorkspace(workspaceId);
  let isStandard = false;
  const copyItemNumber = 0;
  let newName = `${COPY_OF_DEFAULT_TEMPLATE_COLLECTION_NAME} ${NOW}`;
  if (result?.data) {
    const { data } = result;
    for (const template of data) {
      if (template.graphId === selectedDataSourceGraph && template.collectionName === DefaultTemplateCollectionName) {
        newName = incrementCopyName(data, copyItemNumber, newName);
        isStandard = true;
      }
    }
  }
  return { isStandard, newName };
};

const incrementCopyName = (data: Array<NS_API.ITemplateCollection>, i: number, newName: string): string => {
  const isExist = data.find((d) => d.collectionName === newName);
  if (isExist?.collectionName) {
    const counter = i + 1;
    return incrementCopyName(data, counter, `${COPY_OF_DEFAULT_TEMPLATE_COLLECTION_NAME} ${counter} ${NOW}`);
  }
  return newName;
};
