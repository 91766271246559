// Style
import './style.scss';

import React from 'react';

import { renderContent } from '../../shared/helpers/helpers';

interface ICheckboxProps {
  /**
   * Is checked?
   */
  checked: boolean;
  /**
   * Check state change handler.
   */
  toggle: (e) => void;
}

/**
 * Custom checkbox
 */
const Checkbox: React.FC<ICheckboxProps> = ({ checked, toggle }) => {
  return (
    <div
      className={`checkbox ${renderContent(checked, 'checkbox--checked', 'checkbox--notChecked')}`}
      onMouseUp={(e) => toggle(e)}
      data-cy={`check-${checked ? 'checked' : 'notChecked'}`}>
      <div className="checkbox_checkFiller" />
    </div>
  );
};

export default Checkbox;
