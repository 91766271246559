// Style
import './style.scss';

import React, { useCallback, useContext, useEffect, useState } from 'react';
// svg
import { ReactSVG } from 'react-svg';

import CrossSVG from '../../../assets/img/cross-white.svg';
import EditSVG from '../../../assets/img/edit.svg';
import { WorkspaceContext } from '../../../context/WorkspaceContext';
import DropDownList from '../../../elements/DropDownList';
import SpinnerLoader from '../../../elements/Loaders/SpinnerLoader';
import { getWorkspaceUsers, updateWorkspace } from '../../../shared/api/workspace';
import { Roles } from '../../../shared/constant/member.consts';
import ToastHelper from '../../../shared/helpers/toast/ToastHelper';

/**
 * Component used to manage users for a workspace
 */

const MemberList: React.FC = () => {
  // Context
  const { name, workspaceId } = useContext(WorkspaceContext);
  // States

  const [edit, setEdit] = useState<number>(-1);
  const [userArray, setUserArray] = useState<Array<NS_User.IUSER>>([]);
  const [userMail, setUserMail] = useState<string>('');
  const [roleSelected, setRoleSelected] = useState<NS_User.IGROUP>('viewer');
  const [inputsError, setInputsError] = useState<string>('');
  const [usersLoading, setUsersLoading] = useState<boolean>(false);

  useEffect(() => {
    getWorkspaceUsers(workspaceId)
      .then((res) => {
        if (res.data) {
          setUserArray(res.data);
        }
      })
      .catch((e) => {
        ToastHelper.error('Failed to get users.', e);
      });
  }, []);

  // Functions

  const addMember = (e) => {
    e.preventDefault();
    if (userMail && roleSelected) {
      setUserMail('');
      setRoleSelected('viewer');
      setUsersLoading(true);

      // Update workspace
      const data = {
        updatedWorkspace: {
          name: name,
        } as NS_Workspace.IWorkspaceDtoParams,
        users: [
          {
            email: userMail,
            groupId: roleSelected,
          },
        ] as Array<NS_User.IUSER>,
      };
      updateWorkspace(workspaceId, data)
        .then((res) => {
          if (res) {
            if (edit !== -1) {
              const tempUserArray = userArray;
              tempUserArray[edit].email = userMail;
              tempUserArray[edit].groupId = roleSelected;
              setUserArray(tempUserArray);
            } else {
              setUserArray([...userArray, { email: userMail, groupId: roleSelected }]);
            }
            setEdit(-1);
            ToastHelper.success('Invitation is sent');
          } else {
            ToastHelper.error('Failed to send invitation', e);
          }
          setUsersLoading(false);
        })
        .catch((error) => {
          setUsersLoading(false);
          ToastHelper.error('Failed to send invitation', error);
        });
    }
  };

  const editMember = (member: NS_User.IUSER, index: number) => () => {
    setEdit(index);
    setUserMail(member.email);
    setRoleSelected(member.groupId);
  };

  const removeMember = (member: NS_User.IUSER) => () => {
    const data = {
      updatedWorkspace: {
        name: name,
      } as NS_Workspace.IWorkspaceDtoParams,
      users: [
        {
          email: member.email,
          groupId: member.groupId,
          delete: true,
        },
      ] as Array<NS_User.IUSER>,
    };

    // PUT
    updateWorkspace(workspaceId, data)
      .then((res) => {
        if (res) {
          setUserArray(userArray.filter((user) => user.email !== member.email));
          ToastHelper.success('User deleted');
        }
      })
      .catch((e) => {
        ToastHelper.error('Failed to delete users', e);
      });
  };

  const handleItemRoleClick = useCallback((role: any, index: number) => {
    setRoleSelected(role as NS_User.IGROUP);
    setInputsError('');
  }, []);

  return (
    <div className="moduleWrapperContainer">
      <div className="moduleWrapper memberList">
        <div className="moduleHeader">
          <div className="memberItem">User mail</div>
          <div className="memberItem">Role</div>
        </div>
        <form className="memberAddRow" onSubmit={addMember}>
          <input
            className="memberItem userMail"
            placeholder="Enter mail address here"
            type="email"
            value={userMail}
            onChange={(e) => setUserMail(e.target.value)}
            maxLength={99}
            required
          />
          <div className="right">
            <DropDownList elements={Roles} selectedElements={[roleSelected]} onItemClick={handleItemRoleClick} />
            <button className="button--green" type="submit">
              {edit === -1 ? 'Send an invitation' : 'Edit'}
            </button>
          </div>
        </form>
        {inputsError && <p className="errorMsg">{inputsError}</p>}
      </div>
      {usersLoading && <SpinnerLoader />}
      {!usersLoading && userArray.length > 0 && (
        <div className="moduleWrapper memberList">
          <div className="moduleHeader">
            <div className="memberItem">Current members</div>
          </div>
          {userArray.map((item, index) => {
            const key = `${item.email} - ${index}`;
            return (
              <div className="memberRow" key={key}>
                <div className="memberItem userMail">{item.email}</div>
                <div className="right">
                  <div className="memberItem userGroup">{item.groupId}</div>
                  <div className="icons">
                    <ReactSVG src={EditSVG} className="svg-wrapper editSVG" onClick={editMember(item, index)} />
                    <ReactSVG src={CrossSVG} className="svg-wrapper crossSVG" onClick={removeMember(item)} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MemberList;
