import { FC, useContext } from 'react';

import { WorkspaceContext } from '../../../context/WorkspaceContext';
import AskSupport, { IAskSupportIssueType } from '../../../modals/AskSupport';
import ConclusionStructuration from '../../../modals/ConclusionStructuration';
import CreateDataSource from '../../../modals/CreateDataSource';
import CreateReportsPack from '../../../modals/CreateReportsPack';
import DeleteDataSource from '../../../modals/DeleteDataSource';
import ImportError from '../../../modals/ImportError';
import UnionForm, { IUnionLine } from '../../../modals/Union';
import { IDataSourceModal, ModalTypes, states } from '../../../shared/constant/datasource.consts';
import FileUpload from '../FileUpload';

interface IDataModal {
  files: Array<File & { isExist?: boolean }>;
  modal: IDataSourceModal;
  isImport: boolean;
  fileStep: number;
  supportIssueDescription: string;
  currentDatasource?: NS_Workspace.IDataSourcesFile;
  unionDatasourceFileIds: Array<string>;
  deletingDatasourceIds: Array<string>;
  backToUnionDatasource?: NS_Workspace.IDataSourcesFile;
  reasonsImportError: any;
  worksheets: Array<string>;
  fileUploaded: Array<File>;
  currentUploadFile?: File | null;
  closeModal: (type: ModalTypes) => () => void;
  closeUnion(): void;
  handleDescriptionConclusion: (description: string) => void;
  handleCloseCreateDatasource(): void;
  handleAddDatasource: (worksheetToAdd: Array<string>) => void;
  setOpenUnionModal: (val: boolean) => void;
  closeImportModal(): void;
  handleImport(): void;
  removeFile(file: File): void;
  handleNewFiles(updatedFiles: Array<File>): void;
  setFileStep: React.Dispatch<React.SetStateAction<number>>;
  handleCancelDataSource(): void;
  setIsModalImportErrorOpen(val: boolean): void;
  setBackToUnionDatasource: (ds: NS_Workspace.IDataSourcesFile) => void;
  setDatasourceToState: (fileId: string, structurationStatus: states, fields?: object) => void;
  callUnion: (
    unionMetadata: Array<IUnionLine>,
    newFileName: string | undefined,
    backToUnion: string,
    reportsId: Array<{ name: string; id: string }>,
  ) => void;
}

const DataModal: FC<IDataModal> = ({
  files,
  modal,
  isImport,
  fileStep,
  worksheets,
  currentDatasource,
  unionDatasourceFileIds,
  deletingDatasourceIds,
  backToUnionDatasource,
  supportIssueDescription,
  reasonsImportError,
  fileUploaded,
  currentUploadFile,
  closeModal,
  closeUnion,
  callUnion,
  handleDescriptionConclusion,
  handleCloseCreateDatasource,
  setIsModalImportErrorOpen,
  setBackToUnionDatasource,
  handleCancelDataSource,
  handleAddDatasource,
  setOpenUnionModal,
  closeImportModal,
  handleImport,
  removeFile,
  handleNewFiles,
  setFileStep,
  setDatasourceToState,
}) => {
  const { importedDataSources } = useContext(WorkspaceContext);
  return (
    <>
      {modal.isModalImportDataSourceOpen && (
        <FileUpload
          files={files}
          fileUploaded={fileUploaded}
          isImport={isImport}
          currentUploadFile={currentUploadFile}
          closeModal={closeImportModal}
          setFiles={handleNewFiles}
          removeFile={removeFile}
          handleImport={handleImport}
        />
      )}
      {modal.isModalCreateDataSourceOpen && (
        <CreateDataSource
          files={files}
          fileStep={fileStep}
          fileName={files[fileStep - 1] ? files[fileStep - 1].name : 'file'}
          worksheets={worksheets}
          closeModal={handleCloseCreateDatasource}
          dataSources={importedDataSources}
          setFileStep={setFileStep}
          onAddDatasource={handleAddDatasource}
        />
      )}
      {modal.isModalDeleteDataSourceOpen && (
        <DeleteDataSource
          datasourceIds={deletingDatasourceIds}
          onCancel={handleCancelDataSource}
          onClose={closeModal(ModalTypes.DELETE_DATASOURCE)}
          setDatasourceToState={setDatasourceToState}
        />
      )}
      {modal.isModalReportCreatorOpen && currentDatasource && (
        <CreateReportsPack onClose={closeModal(ModalTypes.REPORT_CREATOR)} selectedDataSource={currentDatasource} />
      )}
      {modal.isModalImportErrorOpen && (
        <ImportError infos={reasonsImportError} setIsOpenModal={setIsModalImportErrorOpen} />
      )}
      {modal.isModalSupportOpen && currentDatasource && (
        <AskSupport
          datasource={currentDatasource}
          issueDescription={supportIssueDescription}
          onClose={closeModal(ModalTypes.SUPPORT)}
          type={IAskSupportIssueType.CARD}
        />
      )}
      {modal.openUnionModal && (
        <UnionForm
          unionDatasourceFileIds={unionDatasourceFileIds}
          handleCallUnion={callUnion}
          backToUnionDatasource={backToUnionDatasource}
          onClose={closeUnion}
        />
      )}
      {modal.isModalConclusionOpen && currentDatasource && (
        <ConclusionStructuration
          onClose={closeModal(ModalTypes.CONCLUSION)}
          setDatasourceToState={setDatasourceToState}
          setOpenUnionModal={setOpenUnionModal}
          setBackToUnionDatasource={setBackToUnionDatasource}
          setOpenSupport={handleDescriptionConclusion}
          datasource={currentDatasource}
        />
      )}
    </>
  );
};

export default DataModal;
