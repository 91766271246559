import './style.scss';

import { useMemo } from 'react';

import { renderContent } from '../../../shared/helpers/helpers';
import CollapsibleItem, { BadgeTypes } from '../CollapsibleItem';

interface IFiscalYear {
  fiscalYearsDescription?: NS_Workspace.IFiscalYearsDetailsObject;
  datasourcesForUnion?: NS_API.IDataSourcesForUnion;
  onAskSupportClick: (description: string) => void;
}

const FiscalYear: React.FC<IFiscalYear> = ({ fiscalYearsDescription, datasourcesForUnion, onAskSupportClick }) => {
  const title = useMemo(() => {
    if (fiscalYearsDescription) {
      let yearNumer = 0;
      if (datasourcesForUnion) {
        let _yearNumber: Array<string> = [];
        const unionKeys = Object.keys(fiscalYearsDescription);
        for (const unionKey of unionKeys) {
          const unionYearKeys = fiscalYearsDescription[unionKey];
          const keys = Object.keys(unionYearKeys);
          _yearNumber = [...keys, ..._yearNumber];
        }
        yearNumer = [...new Set(_yearNumber)].length;
      } else {
        yearNumer = Object.values(fiscalYearsDescription).length;
      }
      return `The Data Source covers ${yearNumer} fiscal ${yearNumer > 1 ? 'years' : 'year'}`;
    } else {
      return 'Fiscal years covered by the Data Source have not been identified';
    }
  }, [fiscalYearsDescription, datasourcesForUnion]);

  const badgeType = useMemo(() => {
    return renderContent(fiscalYearsDescription !== undefined, BadgeTypes.VALIDATED, BadgeTypes.ERROR);
  }, [fiscalYearsDescription]);

  const monthsCovered = useMemo(() => {
    let years, start, end;
    if (fiscalYearsDescription) {
      years = Object.values(fiscalYearsDescription);
      start = new Date(years[0].BoP);
      end = new Date(years[years.length - 1].EoP);
      return (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth()) + 1;
    }
    return 0;
  }, [fiscalYearsDescription]);

  return (
    <CollapsibleItem title={title} badgeType={badgeType}>
      {fiscalYearsDescription && !datasourcesForUnion && (
        <ul>
          Fiscal Years covered:
          {Object.values(fiscalYearsDescription).map((item, key) => {
            const index = `${item.name} - ${key}`;
            return (
              <li key={index}>
                <b>{item.name}</b>: from <b>{item.BoP}</b> to <b>{item.EoP}</b>
              </li>
            );
          })}
          Total months: {monthsCovered}
        </ul>
      )}
      {fiscalYearsDescription && datasourcesForUnion && (
        <table className="fiscalYearTable">
          <thead>
            <tr>
              <th></th>
              {Object.keys(fiscalYearsDescription).map((dsName) => {
                return Object.values(fiscalYearsDescription[dsName]).map((yearNumber: any, indexFY) => {
                  return (
                    <th key={dsName}>
                      <div className="thFlex">
                        <p>{renderContent(indexFY === 0, dsName, null)}</p>
                        <p>{yearNumber.name}</p>
                      </div>
                    </th>
                  );
                });
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Beginning of period</td>
              {Object.keys(fiscalYearsDescription).map((dsName, key) => {
                return Object.values(fiscalYearsDescription[dsName]).map((yearDetail, i) => {
                  const typeYearDetail: NS_Workspace.IFiscalYearDetails = yearDetail;
                  const index = `${key}-${i}`;
                  return <td key={index}>{typeYearDetail.BoP}</td>;
                });
              })}
            </tr>
            <tr>
              <td>End of period</td>
              {Object.keys(fiscalYearsDescription).map((dsName, key) => {
                return Object.values(fiscalYearsDescription[dsName]).map((yearDetail, i) => {
                  const typeYearDetail: NS_Workspace.IFiscalYearDetails = yearDetail;
                  const index = `${key}-${i}`;
                  return <td key={index}>{typeYearDetail.EoP}</td>;
                });
              })}
            </tr>
            <tr>
              <td>Number of months</td>
              {Object.keys(fiscalYearsDescription).map((dsName, key) => {
                return Object.values(fiscalYearsDescription[dsName]).map((yearDetail, i) => {
                  const typeYearDetail: NS_Workspace.IFiscalYearDetails = yearDetail;
                  const index = `${key}-${i}`;
                  return <td key={index}>{typeYearDetail.nbMonth}</td>;
                });
              })}
            </tr>
          </tbody>
        </table>
      )}
      {fiscalYearsDescription === undefined && (
        <div className="fiscalYearSupport">
          Scorf has not been able to identify the periods covered in the Data Source.
          <br />
          <br />
          Check that the date format could be recognised by algorithms:
          <br />
          For instance, the first nine days of each months should be systematically made of two numbers: 01,02,
          03...until 09.
          <br />
          <button className="askSupportBtn" onClick={() => onAskSupportClick('Years covered by the datasource?')}>
            Support
          </button>
        </div>
      )}
    </CollapsibleItem>
  );
};

export default FiscalYear;
