// Style
import React, { useCallback, useContext } from 'react';
import { ReactSVG } from 'react-svg';

import SingleBarSVG from '../../../../assets/img/bar.svg';
// SVG
import TripleBarSVG from '../../../../assets/img/bars.svg';
import SettingSVG from '../../../../assets/img/setting.svg';
import { ReportDisplayMode } from '../../../../containers/Reports';
import { ReportContext } from '../../../../context/ReportContext';
import { WorkspaceContext } from '../../../../context/WorkspaceContext';
import { renderContent } from '../../../../shared/helpers/helpers';
// Tools
import { dataDestructuring } from '../../../../shared/helpers/tree/front.tree.util';
import { disableCursorStyle } from '../../../../utils/common';

interface IEditHeader {
  disabled: boolean;
  multipleDimensions: boolean;
  zoomValue: string;
  setOpenContextMenu: (val: boolean) => void;
  openContextMenu: boolean;
  setIndent: (val: boolean) => void;
  indent: boolean;
  mappingTree: NS_API.IGraph;
  filteredCols: Array<NS_Table.IColumnHeader>;
  setCellSelected: (val: string[]) => void;
  setNameOfNodeSelected: (val: NS_REPORT.INameSelectedForCoverageProps) => void;
  widthCols: Array<number>;
  tablePosXRef: React.RefObject<HTMLDivElement>;
  setStartResize: (val: number) => void;
  setResizeCol: (val: number) => void;
  newMousePos: number;
  treeRef: React.RefObject<HTMLDivElement>;
  onClickHeader: (headerItem: NS_Table.IColumnHeader) => void;
  headerSelectionMode: boolean;
  selectedHeaders: Array<NS_Table.IColumnHeader>;
  onReportSettingClick: (e) => void;
  reportDisplayMode: string | undefined;
}

/**
 * Header component of report
 * @param param IEditHeader props
 */

const EditHeader: React.FC<IEditHeader> = ({
  multipleDimensions,
  zoomValue,
  setOpenContextMenu,
  openContextMenu,
  setIndent,
  indent,
  mappingTree,
  filteredCols,
  setCellSelected,
  setNameOfNodeSelected,
  widthCols,
  tablePosXRef,
  setStartResize,
  setResizeCol,
  newMousePos,
  treeRef,
  onClickHeader,
  headerSelectionMode,
  selectedHeaders,
  onReportSettingClick,
  reportDisplayMode,
  disabled = false,
}) => {
  const cursorDisabled = disableCursorStyle(disabled);
  const { colorOptions } = useContext(WorkspaceContext);
  const { setNewOperation, currentCommands, currentMetadata } = useContext(ReportContext);

  const canCollapse = () => {
    const canCollapseTemp: boolean[] = [];
    const loopArray = (child: NS_API.IGraph) => {
      child.forEach((item) => {
        if (!item.data.content.rowParams.locked) {
          canCollapseTemp.push(item.data.content.rowParams.opened);
        }
        if (item.data.content.rowContentType !== 'N') {
          loopArray(item.children);
        }
      });
    };
    loopArray(mappingTree);
    return canCollapseTemp.findIndex((x) => x) !== -1;
  };

  const canExpand = () => {
    const canCollapseTemp: boolean[] = [];
    const loopArray = (child: NS_API.IGraph) => {
      child.forEach((item) => {
        if (item.data.content.rowContentType !== 'N') {
          canCollapseTemp.push(item.data.content.rowParams.opened);
          loopArray(item.children);
        }
      });
    };
    loopArray(mappingTree);
    return canCollapseTemp.findIndex((x) => !x) !== -1;
  };

  const toggleCollapseAll = (collapse: boolean) => {
    const newArray = dataDestructuring(mappingTree);
    const loopArray = (child: NS_API.IGraph) => {
      child.forEach((item) => {
        if (collapse) {
          if (!item.data.content.rowParams.locked) {
            item.data.content.rowParams.opened = false;
          }
        } else {
          if (item.data.content.rowContentType === 'C') {
            item.data.content.rowParams.opened = true;
          }
        }
        if (item.data.content.rowContentType === 'C') {
          loopArray(item.children);
        }
      });
    };
    loopArray(newArray);
    setCellSelected([]);
    setNameOfNodeSelected({ accountId: '', accountName: '' });
    const newCommands = dataDestructuring(currentCommands);
    newCommands[
      Object.keys(newCommands).length === 0
        ? 0
        : parseInt(Object.keys(newCommands)[Object.keys(newCommands).length - 1]) + 1
    ] = {
      type: 'collapse',
    };
    setNewOperation(newArray, currentMetadata, newCommands);
  };

  const handleClickHeader = (headerItem: NS_Table.IColumnHeader) => () => {
    onClickHeader(headerItem);
  };

  const renderHeaderClass = useCallback(
    (headerItem: NS_Table.IColumnHeader) => {
      return `columns ${renderContent(
        selectedHeaders.findIndex((item) => item.valueIndex === headerItem.valueIndex) !== -1,
        'selected',
        '',
      )} ${renderContent(headerSelectionMode && !headerItem.calculatedCol, 'selectionnable', '')}`;
    },
    [selectedHeaders, headerSelectionMode],
  );

  const firstCalculatedCol = useCallback(
    (column: NS_Table.IColumnHeader, colIndex: number) => {
      const previousCol = filteredCols[colIndex - 1];
      return previousCol && !previousCol.calculatedCol && column.calculatedCol ? true : false;
    },
    [filteredCols],
  );

  const toggleOpenContextMenu = useCallback(() => {
    if (!disabled) setOpenContextMenu(!openContextMenu);
  }, [disabled, openContextMenu]);

  return (
    <>
      {newMousePos !== -1 && (
        <div
          className="columnResize"
          style={{
            position: 'fixed',
            left: newMousePos,
            height: `${treeRef.current && treeRef.current.offsetHeight + 40}px`,
            zIndex: 130,
          }}
        />
      )}
      <div
        className={`header ${multipleDimensions && 'multipleDimensions'}`}
        style={{ height: `calc(${multipleDimensions ? '57px' : '40px'} * ${zoomValue} / 10)` }}>
        <div className={`collapse`}>
          {openContextMenu ? (
            <ReactSVG
              src={SingleBarSVG}
              className={`svg-wrapper singleBarSVG ${cursorDisabled}`}
              onClick={toggleOpenContextMenu}
            />
          ) : (
            <ReactSVG
              src={TripleBarSVG}
              className={`svg-wrapper tripleBarSVG ${cursorDisabled}`}
              onClick={toggleOpenContextMenu}
            />
          )}
          {openContextMenu && (
            <div className="contextMenu">
              <div
                className={`row`}
                onClick={() => {
                  setIndent(!indent);
                  setOpenContextMenu(false);
                }}>
                {indent ? 'Remove Indentation' : 'Show Indentation'}
              </div>
              <div
                className={`row ${!canCollapse() && 'disable'}`}
                onClick={() => {
                  canCollapse() && toggleCollapseAll(true);
                  setOpenContextMenu(false);
                }}>
                Collapse all
              </div>
              <div
                className={`row ${!canExpand() && 'disable'}`}
                onClick={() => {
                  canExpand() && toggleCollapseAll(false);
                  setOpenContextMenu(false);
                }}>
                Expand all
              </div>
            </div>
          )}
          {(reportDisplayMode === ReportDisplayMode.SALES_BY_CLIENT ||
            reportDisplayMode === ReportDisplayMode.PURCHASES_BY_PROVIDER) && (
            <div className={`reportSettingContainer ${cursorDisabled}`}>
              <ReactSVG
                src={SettingSVG}
                className={`svg-wrapper   ${disabled ? 'settingSVGDisable' : 'settingSVG'} `}
                onClick={onReportSettingClick}
              />
            </div>
          )}
        </div>
        <div className="columnsHeader">
          {filteredCols.map((item, key) => {
            let width = widthCols[key];
            if (key === 0) {
              width = widthCols[key] + 100;
            }
            const index = `${item.displayName}${key}`;
            return (
              <div
                className={`tableHeaderCell ${renderContent(firstCalculatedCol(item, key), 'dividingLeftBorder', '')}`}
                key={index}
                style={
                  {
                    width: (width * parseInt(zoomValue)) / 10,
                    fontSize: `calc(0.875rem * ${zoomValue} / 10)`,
                    '--headerFill': colorOptions.headerFillColor,
                    '--headerText': colorOptions.headerTextColor,
                    '--primaryFill': colorOptions.primaryFillColor,
                  } as React.CSSProperties
                }>
                {multipleDimensions ? (
                  <div
                    className={renderHeaderClass(item)}
                    style={{ width: (120 * parseInt(zoomValue)) / 10 }}
                    onClick={handleClickHeader(item)}>
                    <div className="firstDim" title={item.title ?? item.displayName.split('/')[0]}>
                      {item.displayName.split('/')[0]}
                    </div>
                    <div className="secondDim" title={item.title ?? item.displayName.split('/')[1]}>
                      {item.displayName.split('/')[1]}
                    </div>
                  </div>
                ) : (
                  <div
                    className={renderHeaderClass(item)}
                    style={{ width: (120 * parseInt(zoomValue)) / 10 }}
                    onClick={handleClickHeader(item)}>
                    <div className="firstDim" title={item.title ?? item.displayName}>
                      {item.displayName}
                    </div>
                  </div>
                )}
                {key === 0 && (
                  <div
                    className="resizeReport"
                    onMouseDown={(e) => {
                      if (tablePosXRef.current) {
                        setStartResize(e.pageX);
                        setResizeCol(0);
                      }
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default React.memo(EditHeader);
