import React, { useState } from 'react';
import ToggleListPreview from './ToggleListPreview';

// Enums
import { paddleSide } from '../index';

// Style
import './style.scss';
import { ReactSVG } from 'react-svg';
import RightArrowSVG from '../../../assets/img/chevron-right.svg';
import LeftArrowSVG from '../../../assets/img/chevron-left.svg';

interface ITGLPaddle {
  /**
   * Paddle direction.
   */
  side: paddleSide;
  /**
   * Is paddle disabled?
   */
  disable: boolean;
  /**
   * Paddle click handler
   */
  onClick: () => void;
  tabs: any[];
  selectedSheet: number;
  dataSourceOffset: number;
  handleClickItem: (index: number, tab: any) => void;
}

/**
 * Component used to navigate right and left in Toggle List when it overflows due to
 * a high amount of opened reports
 */
const ToggleListPaddle: React.FC<ITGLPaddle> = ({
  side,
  onClick,
  disable,
  tabs,
  selectedSheet,
  dataSourceOffset,
  handleClickItem,
}) => {
  const [reportReportPreviewOpen, setReportPreviewOpen] = useState<boolean>(false);
  let timer: NodeJS.Timeout;

  const handleMouseDown = (e) => {
    if (e.button === 0) {
      timer = setTimeout(() => {
        setReportPreviewOpen(true);
      }, 1000);
    }
  };

  const handleMouseUp = (e) => {
    timer && clearTimeout(timer);
    if (!reportReportPreviewOpen) {
      onClick();
    }
  };

  return (
    <div
      className={`paddleDiv ${side === paddleSide.RIGHT ? 'paddleDivRight' : 'paddleDivLeft'} ${
        disable ? 'disable' : ''
      }`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}>
      {side === paddleSide.LEFT ? (
        <ReactSVG src={LeftArrowSVG} className="svg-wrapper leftArrowSVG" />
      ) : (
        <ReactSVG src={RightArrowSVG} className="svg-wrapper rightArrowSVG" />
      )}
      <ToggleListPreview
        open={reportReportPreviewOpen}
        tabs={tabs}
        setReportPreviewOpen={setReportPreviewOpen}
        selectedSheet={selectedSheet}
        dataSourceOffset={dataSourceOffset}
        handleClickItem={handleClickItem}
      />
    </div>
  );
};

export default ToggleListPaddle;
