export class Stack<T = any> extends Array<T> {
  peek() {
    return this[this.length - 1];
  }

  isEmpty() {
    return this.length === 0;
  }
}

export class Queue<T = any> extends Array<T> {
  enqueue(val) {
    this.push(val);
  }

  dequeue() {
    return this.shift();
  }

  peek() {
    return this[0];
  }

  isEmpty() {
    return this.length === 0;
  }
}
