import { serviceGraph } from '../..';

// GET
export const getGraph = async (workspaceId: string, graphId: string) => {
  return serviceGraph.request({
    // mettre type
    method: 'GET',
    url: `/graph-store/${workspaceId}/${graphId}?props=templateParams,templateGroups`,
    headers: {
      'Content-Type': `application/json`,
    },
  });
};
