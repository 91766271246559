import { format as dateFormat } from 'date-fns';
import { abs, format, number } from 'mathjs';

import { CalculateOperation } from '../../../components/reportComponents/KpiComponent';

const numeral = require('numeral');

interface StringifiedDate {
  date: string;
  time: string;
}

export default class StringHelper {
  static stringifyIsoDateToFullDate(ISODate: string): StringifiedDate {
    const date = new Date(ISODate);
    return {
      date: dateFormat(date, 'dd MMMM yyyy'),
      time: dateFormat(date, 'HH:mm'),
    };
  }

  static getFileName(azurePath: string): string {
    const splitted = azurePath.split('/');
    return splitted[splitted.length - 1];
  }

  static beautifyTableToString(table, string = '') {
    for (let r = 0; r < table.length; r++) {
      const row = table[r];
      for (let c = 0; c < row.length; c++) {
        const cell = row[c];
        string += typeof cell !== 'undefined' && cell !== null ? cell : '';
        if (c + 1 !== row.length) {
          string += '\t';
        }
      }
      if (r + 1 !== table.length) {
        string += '\n';
      }
    }
    return string;
  }

  static formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  static buildFormulaStringOfCalculationCol(value: NS_API.IFormulaValue, renamedColumns: NS_Table.IColumnHeader[]) {
    const { o: operation, p: parameters } = value;
    if (operation === CalculateOperation.VARIATION) {
      const { firstIndex, secondIndex } = parameters as NS_REPORT.IFormulaFirstAndSecondIndex;
      const firstColDisplayName = renamedColumns[firstIndex].displayName;
      const secondColDisplayName = renamedColumns[secondIndex].displayName;
      return `({${secondColDisplayName}}-{${firstColDisplayName}})/{${firstColDisplayName}}`;
    } else if (operation === CalculateOperation.VARIATION_DIFF) {
      const { firstIndex, secondIndex } = parameters as NS_REPORT.IFormulaFirstAndSecondIndex;
      const firstColDisplayName = renamedColumns[firstIndex].displayName;
      const secondColDisplayName = renamedColumns[secondIndex].displayName;
      return `{${firstColDisplayName}}-{${secondColDisplayName}}`;
    } else if (operation === CalculateOperation.VARIATION_CAGR) {
      const { firstIndex, secondIndex } = parameters as NS_REPORT.IFormulaFirstAndSecondIndex;
      const firstColDisplayName = renamedColumns[firstIndex].displayName;
      const secondColDisplayName = renamedColumns[secondIndex].displayName;
      const nbOfPeriods = secondIndex - firstIndex;
      let cagr;
      if (nbOfPeriods > 1) {
        // Imaginary number protection
        cagr =
          Number(secondColDisplayName) / Number(firstColDisplayName) < 0
            ? `${NaN}`
            : `(({${secondColDisplayName}}/{${firstColDisplayName}})^(1/${nbOfPeriods}))-1`;
      } else {
        cagr = `{${secondColDisplayName}}/{${firstColDisplayName}}-1`;
      }
      return cagr;
    } else if (parameters.hasOwnProperty('indices')) {
      return `${operation.toUpperCase()}(${(parameters as NS_REPORT.IFormulaArrayIndices).indices
        .map((index) => `{${renamedColumns[index].displayName}}`)
        .join(',')})`;
    } else if (parameters.hasOwnProperty('fromIndex') && parameters.hasOwnProperty('toIndex')) {
      const { fromIndex, toIndex } = parameters as NS_REPORT.IFormulaFromToRangeIndices;
      const fromColDisplayName = renamedColumns[fromIndex].displayName;
      const toColDisplayName = renamedColumns[toIndex].displayName;
      return `${operation.toUpperCase()}({${fromColDisplayName}}:{${toColDisplayName}})`;
    }
    return value.t;
  }

  static buildMeasureFullName(measure: string) {
    switch (measure) {
      case 'D':
        return 'Debit';
      case 'C':
        return 'Credit';
      case 'V':
        return 'Variation (flow)';
      case 'B':
        return 'Balance';
      case 'CB':
        return 'Credit Balance';
      case 'DB':
        return 'Debit Balance';
      default:
        return '';
    }
  }

  static getTemplateFullName(templateBrefName: string) {
    switch (templateBrefName) {
      case 'PL':
        return 'Profit and Loss';
      case 'BS':
        return 'Balance Sheet';
      case 'CF':
        return 'Cash Flow';
      case 'CB':
        return 'Cash Balance';
      case 'CR':
        return 'Cash Report';
      default:
        return templateBrefName;
    }
  }

  static buildHeaderLabel(colValueName: string, monthGroupedBy: string | undefined) {
    const yearString = colValueName.split('-')[0].substring(2, 5);
    const monthString = colValueName.split('-')[1];
    const seperator = monthGroupedBy ? '' : 'M';
    const monthStringWithUnity = `${seperator}${monthString}`;

    return `FY${yearString}${monthString ? monthStringWithUnity : ''}`;
  }

  static buildMeasure(measureFullName: string) {
    return measureFullName[0] ?? '';
  }

  static formatNumericalValues(value: number | string) {
    const currency = '0,0.00';

    if (!isNaN(numeral(value).format(currency))) {
      if (value < 0) {
        value = numeral(value).format(`(${currency})`);
      } else {
        value = numeral(value).format(currency);
      }
    } else {
      if (value < 0) {
        // @ts-ignore
        value = `(${format(abs(number(value)), { precision: 7, notation: 'fixed' })})`;
      } else {
        value = format(number(value), { precision: 7, notation: 'fixed' });
      }
    }

    value = value.toString().replace(/,/g, ' ');
    value = value.replace('.', ',');
    return value;
  }
}
