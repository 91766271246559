import './style.scss';

import React, { useCallback } from 'react';
import { ReactSVG } from 'react-svg';

import HelpSVG from '../../../../assets/img/help.svg';
import RulerSVG from '../../../../assets/img/ruler.svg';
import DropDownList from '../../../../elements/DropDownList';
import StringHelper from '../../../../shared/helpers/string/string.helper';

const measureTypes = ['Debit', 'Credit', 'Variation (flow)', 'Balance'];

interface IMeasurePart {
  measure: string;
  onChange: (measure: string) => void;
}

const MeasurePart: React.FC<IMeasurePart> = ({ measure, onChange }) => {
  const handleChange = useCallback((clickedMeasureFullName: string) => {
    onChange(StringHelper.buildMeasure(clickedMeasureFullName));
  }, []);

  return (
    <div className="measurePartContainer">
      <div className="measurePartHeader">
        <ReactSVG src={RulerSVG} className="svg-wrapper rulerSVG" />
        Measure
        <ReactSVG src={HelpSVG} className="svg-wrapper infoSVG" />
        <div className="measurePartExplanation">There are some explanation.</div>
      </div>
      <div className="measurePartBody">
        <DropDownList
          selectedElements={[StringHelper.buildMeasureFullName(measure)]}
          onItemClick={handleChange}
          elements={measureTypes}
        />
      </div>
    </div>
  );
};

export default MeasurePart;
