import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMappingNotification } from '../../shared/constant/mapping.consts';

interface IExportState {
  newExport: Array<IMappingNotification>;
}

const initialState: IExportState = {
  newExport: [],
};

export const exportSlice = createSlice({
  name: 'export',
  initialState,
  reducers: {
    handleExportDone: (state, action: PayloadAction<IMappingNotification>) => {
      state.newExport = [...state.newExport, action.payload];
    },
    removeExportNotification: (state, action: PayloadAction<string>) => {
      const isDatasource = state.newExport.find((data) => data.datasourceId === action.payload);
      state.newExport = isDatasource
        ? [...state.newExport.filter((data) => data.datasourceId !== action.payload)]
        : [...state.newExport.filter((data) => data.exportId !== action.payload)];
    },
  },
});

export const { handleExportDone, removeExportNotification } = exportSlice.actions;

export default exportSlice.reducer;
