import { serviceDatasource, serviceMining } from '../..';

const contentType = `application/json`;

// GET

export const getNormalizedHeaders = async (workspaceId: string) => {
  return serviceDatasource.request<Array<NS_API.INormalizedHeaders>>({
    method: 'GET',
    url: `/datasource/${workspaceId}/tagHeaders/normalizedHeaders`,
    headers: {},
    params: { format: 'json' },
  });
};

export const getNormalizedHeader = async (workspaceId: string, datasourceId: string) => {
  return serviceDatasource.request<NS_Table.ILabelsPresent>({
    method: 'GET',
    url: `/datasource/${workspaceId}/${datasourceId}/normalizedHeader`,
    headers: {},
    params: { format: 'json' },
  });
};

export const getDatasourceCard = async (workspaceId: string, datasourceId: string) => {
  return serviceMining.request<NS_API.IFileMetaData>({
    method: 'GET',
    url: `/metadata/${workspaceId}/${datasourceId}/card`,
    headers: {
      'Content-Type': contentType,
    },
  });
};

// POST

export const interpreteDatasource = async (
  datasourceName: string,
  workspaceId: string,
  datasourceId: string,
  normalizedHeader,
  firstCall: boolean,
  returnToInterpretation = false,
  forceOpeningJournal: Array<any> = [],
) => {
  const data = {
    datasourceName: datasourceName,
    workspaceId: workspaceId,
    datasourceId: datasourceId,
    normalizedHeader: normalizedHeader,
    union: firstCall,
    returnToInterpretation: returnToInterpretation ? returnToInterpretation : undefined,
  };
  if (forceOpeningJournal) {
    data['forceOpeningJournal'] = forceOpeningJournal;
  }
  return serviceDatasource.request<NS_API.IInterpretedFile>({
    method: 'POST',
    url: `/datasource/interprete`,
    headers: {
      'Content-Type': contentType,
    },
    data: data,
  });
};

// PATCH
export const updateDatasourceCard = async (workspaceId: string, datasourceId: string, data: any) => {
  return serviceMining.request({
    method: 'PATCH',
    url: `/metadata/${workspaceId}/${datasourceId}/card`,
    headers: {
      'Content-Type': contentType,
    },
    data: data,
  });
};
