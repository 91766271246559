// Style
import './style.scss';

import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
// SVG
import { ReactSVG } from 'react-svg';

import RedCrossSVG from '../../../assets/img/cross-red.svg';
import ExpandSVG from '../../../assets/img/expand.svg';
import EyeCloseSVG from '../../../assets/img/eye-closed.svg';
import grid from '../../../assets/img/grid.svg';
import RestoreSVG from '../../../assets/img/restore.svg';
import ShrinkSVG from '../../../assets/img/shrink.svg';
// Context
import { WorkspaceContext } from '../../../context/WorkspaceContext';
// Scorf component
import ProgressLoader from '../../../elements/Loaders/ProgressLoader';
import { getPreviewLazy } from '../../../shared/api/dataSource';
import { deleteCols, normalizeDataRowsValues } from '../../../shared/helpers/datasource/datasource.helper';
import { renderContent } from '../../../shared/helpers/helpers';
import ToastHelper from '../../../shared/helpers/toast/ToastHelper';
import { noAction } from '../../../utils/common';
import DataVizMapping from '../../reportComponents/DataVisualisationContainer/DataVizMapping';

interface IDataVizProps {
  datasource?: NS_Workspace.IDataSourcesFile;
  onClose: () => void;
  setIsDataSourceFullScreen(val: boolean): void;
  resizeHeight: number;
  isDataSourceFullScreen: boolean;
}

/**
 * Component used to display a preview for an imported data source
 *
 * @param param IDataVizProps props
 */

const DataViz: React.FC<IDataVizProps> = ({
  datasource,
  onClose,
  setIsDataSourceFullScreen,
  isDataSourceFullScreen,
  resizeHeight,
}) => {
  // Context
  const { dataSourcesPreview, workspaceId, importedDataSources, editDatasource, getLazyDataSourcesPreview } =
    useContext(WorkspaceContext);

  // States
  const [finalData, setFinalData] = useState<Array<NS_API.IDSValue>>([]);
  const [tableBodyHeight, setTableBodyHeight] = useState<number>(500);
  const [finalCol, setFinalCol] = useState<any[]>([]);
  const [reset, setReset] = useState<boolean>(false);

  const [fetchingFileData, setFetchingFileData] = useState<boolean>(false);

  // LayoutEffect add useLayoutEffect to handle resize
  useLayoutEffect(() => {
    function updateSize() {
      const tableBodyH = isDataSourceFullScreen ? resizeHeight - 150 : window.innerHeight - resizeHeight - 220; // give a dynamic height to the table Body, remove all fixed height in page
      setTableBodyHeight(tableBodyH);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [resizeHeight]);

  // useEffect

  useEffect(() => {
    if (dataSourcesPreview && dataSourcesPreview.columns.length !== 0) {
      const colToRemove: number[] = [];
      dataSourcesPreview.columns.forEach((column, index) => {
        if (column.scorfHeader[0] === '_' && column.scorfHeader !== '_SplitId') {
          colToRemove.push(index);
        }
      });
      const normaliszeData = normalizeDataRowsValues(dataSourcesPreview.data, dataSourcesPreview.columns);
      const normalizeCol = deleteCols(colToRemove, normaliszeData, dataSourcesPreview.columns);
      setFinalCol(normalizeCol);
      setFinalData(normaliszeData);
    }
    // eslint-disable-next-line
  }, [dataSourcesPreview]);

  useEffect(() => {
    if (datasource) {
      const updatedDatasource: NS_Workspace.IDataSourcesFile = importedDataSources.filter(
        (importedDataSource) => importedDataSource.fileId === datasource.fileId,
      )[0];
      if (updatedDatasource.continuationToken) {
        getData(updatedDatasource, false);
      }
    }
  }, [importedDataSources]);

  useEffect(() => {
    setFinalCol([]);
    setFinalData([]);
    if (datasource) {
      setFetchingFileData(true);
      getData(datasource, true);
    }
  }, [datasource]);

  const getData = (getPreviewFromDatasource: NS_Workspace.IDataSourcesFile, firstCall: boolean) => {
    if (getPreviewFromDatasource) {
      if (getPreviewFromDatasource.continuationToken === '' && !firstCall) {
        return;
      }
      getPreviewLazy(
        workspaceId,
        getPreviewFromDatasource.fileId,
        getPreviewFromDatasource.continuationToken === '' ? null : getPreviewFromDatasource.continuationToken,
        null,
      )
        .then((res) => {
          if (res) {
            const { data } = res;
            getLazyDataSourcesPreview(
              {
                columns: data.data.columns,
                data: data.data.data,
                totalLength:
                  !data.continuationToken || data.continuationToken === '' ? data.data.data.length : data.totalLength,
              },
              firstCall ? false : true,
            );
          } else {
            ToastHelper.error('error while getting data source preview');
          }
          editDatasource(getPreviewFromDatasource.fileId, {
            ...getPreviewFromDatasource,
            continuationToken: res?.data.continuationToken ? res.data.continuationToken : '',
          });
          setFetchingFileData(false);
        })
        .catch((e) => {
          ToastHelper.error('error while getting data source preview', e);
          editDatasource(getPreviewFromDatasource.fileId, { ...getPreviewFromDatasource, continuationToken: '' });
          setFetchingFileData(false);
        });
    }
  };
  const isData = finalCol.length && finalData.length;
  return (
    <div
      className="dataVizContainer"
      style={{
        position: 'relative',
        overflow: 'visible',
        width: '100%',
        height: '100%',
      }}>
      {renderContent(fetchingFileData, <ProgressLoader />, null)}
      {!fetchingFileData && !datasource ? (
        <div className="missingStep">
          <img src={grid} alt="missing step" />
          <h4 className="mainSentence">Select a Data source to view it here.</h4>
          <div className="secondarySentence">Just click on it!</div>
        </div>
      ) : null}

      {datasource && (
        <div style={{ height: '100%' }}>
          <div className="dataVizHeader">
            <div className="leftPart">
              <img
                src={RedCrossSVG}
                alt="exit"
                className="exitCross"
                onClick={() => {
                  onClose();
                  setIsDataSourceFullScreen(false);
                }}
              />
              <h4 className="dataVizTitle">{datasource.displayName}</h4>
            </div>
            <div className="dataCount">
              {finalData.length}{' '}
              {dataSourcesPreview.totalLength > finalData.length && '/' + dataSourcesPreview.totalLength} rows,{' '}
              {finalCol.length} columns{' '}
            </div>
          </div>
          <div className="dataSourceAction">
            <div
              className="action"
              onClick={() => {
                if (isData) setIsDataSourceFullScreen(!isDataSourceFullScreen);
              }}>
              {isDataSourceFullScreen ? (
                <ReactSVG src={ShrinkSVG} className="svg-wrapper shrinkSVG" />
              ) : (
                <ReactSVG src={ExpandSVG} className="svg-wrapper expandSVG" />
              )}
              <div className="title">Fullscreen</div>
            </div>
            <div className="action">
              <ReactSVG src={EyeCloseSVG} className="svg-wrapper hideSVG" />
              <div className="title">Hide empty column</div>
            </div>
            <div className="action" onClick={() => setReset(true)}>
              <ReactSVG src={RestoreSVG} className="svg-wrapper restoreSVG" />
              <div className="title">Restore view</div>
            </div>
            <div className="divider" />
          </div>
          <DataVizMapping
            finalData={finalData}
            columns={finalCol}
            loaded={true}
            selectedRows={[]}
            setSelectedRows={noAction}
            setEntriesSelectedForCoverage={noAction}
            tableBodyHeight={tableBodyHeight}
            clearFilters={reset}
            setClearFilters={setReset}
            totalLength={dataSourcesPreview.totalLength}
          />
        </div>
      )}
    </div>
  );
};

export default DataViz;
