import axios, { AxiosResponse } from 'axios';

export async function executeRequest<T>(request: Promise<AxiosResponse<T>>): Promise<T> {
  try {
    const response = await request;
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response != null) {
      console.error(error.response);
    } else if (error instanceof Error) {
      console.error(error.stack);
    }
    console.error(error as string);
    throw error;
  }
}
