// Style
import './style.scss';

import React, { useEffect, useRef, useState } from 'react';

import redCross from '../../../../../assets/img/cross-red.svg';
import { isContextActionDisabled } from '../../../../../shared/helpers/string/classnames.helper';

interface ITGLContextMenu {
  isOpen: boolean;
  xPos: string;
  yPos: string;
  addParent: () => void;
  addChild: () => void;
  simpleDeleteMappingElement: () => void;
  deepDeleteMappingElement: () => void;
  simpleDeleteMappingAccount: () => void;
  startMoveElement: () => void;
  endMoveElement: (val: string) => void;
  setIsOpen: (val: string) => void;
  moveSelected: boolean;
  severalCellSelected: boolean;
  isAnAccount: boolean;
  setTypeOfVisualisation: (val: string) => void;
  handleViewAccountsClick: (e) => void;
  handleViewEntriesClick: (e) => void;
  handlePropertiesClick: (e) => void;
  typeOfNode: string;
}

/**
 * Toggle List context menu component, used to delete a tab
 *
 * @param param ITGLContextMenu props
 */

const EditContextMenu: React.FC<ITGLContextMenu> = ({
  isOpen,
  xPos,
  yPos,
  addParent,
  addChild,
  setIsOpen,
  simpleDeleteMappingElement,
  deepDeleteMappingElement,
  simpleDeleteMappingAccount,
  startMoveElement,
  endMoveElement,
  moveSelected,
  severalCellSelected,
  isAnAccount,
  setTypeOfVisualisation,
  handleViewAccountsClick,
  handleViewEntriesClick,
  handlePropertiesClick,
  typeOfNode,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(170);
  const [windowHeight, setWindowHeight] = useState<number>(1281);
  const [nameTypeOfNode, setNameTypeOfNode] = useState<string>('');

  useEffect(() => {
    setWindowHeight(window.innerHeight);
    window.addEventListener('resize', updateSize);

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  useEffect(() => {
    switch (typeOfNode) {
      case 'N':
        setNameTypeOfNode('Account');
        break;
      case 'C':
        setNameTypeOfNode('Concept');
        break;
      default:
        setNameTypeOfNode('Concept');
    }
  }, []);

  useEffect(() => {
    // 230px : top offset of report content (height of bandeau)
    if (menuRef?.current) {
      if (parseInt(yPos) - 230 < menuRef.current.clientHeight / 2) {
        setOffset(0);
      } else if (
        parseInt(yPos) - 230 > menuRef.current.clientHeight &&
        windowHeight - parseInt(yPos) < menuRef.current.clientHeight
      ) {
        setOffset(menuRef.current.clientHeight);
      } else {
        setOffset(menuRef.current.clientHeight / 2);
      }
    } else {
      setOffset(0);
    }
  }, [windowHeight]);

  const updateSize = () => {
    setWindowHeight(window.innerHeight);
  };

  return (
    <div>
      {isOpen && (
        <div
          ref={menuRef}
          style={{ top: `${parseInt(yPos) - offset}px`, left: xPos }}
          className={`${typeOfNode === 'N' && 'tglContextMenuMappingAccount'} tglContextMenuMapping`}>
          <div className={'contextMenuTitle'}>
            <div className="leftPart">
              <div className={`${typeOfNode === 'N' && 'dotAccount'} dot`}></div>
              <div className={'contextAction'}>{nameTypeOfNode}</div>
            </div>
            <img src={redCross} alt="exit cross" onClick={() => setIsOpen('')} />
          </div>
          <div
            className={'contextMenuElement'}
            onClick={() => {
              addParent();
              setIsOpen('');
            }}>
            <div className={'contextAction'}>Add Parent</div>
          </div>
          {typeOfNode !== 'N' && (
            <div
              className={'contextMenuElement'}
              onClick={() => {
                !severalCellSelected && addChild();
                setIsOpen('');
              }}>
              <div className={`contextAction ${isContextActionDisabled(severalCellSelected || isAnAccount)}`}>
                Add Child
              </div>
            </div>
          )}
          {typeOfNode !== 'N' && (
            <div
              className={'contextMenuElement'}
              onClick={() => {
                !severalCellSelected && setTypeOfVisualisation('Coverage');
                setIsOpen('');
              }}>
              <div className={`contextAction ${isContextActionDisabled(severalCellSelected || isAnAccount)}`}>
                Add Account
              </div>
            </div>
          )}
          {typeOfNode !== 'N' && (
            <div
              className={'contextMenuElement'}
              onClick={() => {
                simpleDeleteMappingElement();
                setIsOpen('');
              }}>
              <div className={`contextAction ${isContextActionDisabled(severalCellSelected)}`}>Simple Delete</div>
            </div>
          )}
          {typeOfNode !== 'N' && (
            <div
              className={'contextMenuElement'}
              onClick={() => {
                deepDeleteMappingElement();
                setIsOpen('');
              }}>
              <div className={`contextAction ${isContextActionDisabled(severalCellSelected)}`}>Deep Delete</div>
            </div>
          )}
          {typeOfNode === 'N' && (
            <div
              className={'contextMenuElement'}
              onClick={() => {
                simpleDeleteMappingAccount();
                setIsOpen('');
              }}>
              <div className={`contextAction ${isContextActionDisabled(severalCellSelected)}`}>Delete</div>
            </div>
          )}
          {moveSelected ? (
            <div
              className={'contextMenuElement'}
              onClick={(e) => {
                !severalCellSelected && e.stopPropagation();
                setSubMenuOpen(true);
              }}>
              <div className={`contextAction ${isContextActionDisabled(severalCellSelected)}`}>
                Insert moved line {'>'}
              </div>
            </div>
          ) : (
            <div
              className={'contextMenuElement'}
              onClick={() => {
                !severalCellSelected && startMoveElement();
                setIsOpen('');
              }}>
              <div className={`contextAction ${isContextActionDisabled(severalCellSelected)}`}>Move</div>
            </div>
          )}

          <div
            className={'contextMenuElement'}
            onClick={() => {
              !severalCellSelected && setIsOpen('');
            }}>
            <div
              className={`contextAction ${isContextActionDisabled(severalCellSelected)}`}
              onClick={(e) => {
                handleViewAccountsClick(e);
                !severalCellSelected && e.stopPropagation();

                setIsOpen('');
              }}>
              View Accounts
            </div>
          </div>
          <div
            className={'contextMenuElement'}
            onClick={() => {
              !severalCellSelected && setIsOpen('');
            }}>
            <div
              className={`contextAction ${isContextActionDisabled(severalCellSelected)}`}
              onClick={(e) => {
                !severalCellSelected && handleViewEntriesClick(e);
                setIsOpen('');
              }}>
              View Entries
            </div>
          </div>
          {
            <div
              className={'contextMenuElement'}
              onClick={() => {
                !severalCellSelected && setIsOpen('');
              }}>
              <div
                className={`contextAction ${isContextActionDisabled(severalCellSelected)}`}
                onClick={(e) => {
                  !severalCellSelected && handlePropertiesClick(e);
                  setIsOpen('');
                }}>
                Properties
              </div>
            </div>
          }

          {subMenuOpen && (
            <div className="subMenu">
              <div
                className={'contextMenuElement'}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  if (typeOfNode !== 'N') {
                    endMoveElement('child');
                    setIsOpen('');
                  }
                }}>
                <div className={`contextAction ${isContextActionDisabled(typeOfNode === 'N')}`}>As a child</div>
              </div>
              <div
                className={'contextMenuElement'}
                onClick={() => {
                  endMoveElement('above');
                  setIsOpen('');
                }}>
                <div className={'contextAction'}>Above</div>
              </div>
              <div
                className={'contextMenuElement'}
                onClick={() => {
                  endMoveElement('below');
                  setIsOpen('');
                }}>
                <div className={'contextAction'}>Below</div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EditContextMenu;
