import * as XLSX from '@sheet/chart';

import { useAppSelector } from '../../../redux/hook';
import {
  createWorkbook,
  createWorksheet,
  generateArray,
  getSheetName,
  ROW_START,
  TChartData,
} from '../../../shared/charts/utils';
import { ChartType } from '../../../shared/constant/datasource.consts';
import { formalizeChartConfigs } from '../BodyContainer/BlockItem';

const useExportAll = () => {
  const reduxState = useAppSelector((state) => state);
  const { chartReports, selectedWorksheetId } = reduxState.chart;
  const { name: workspaceName } = reduxState.workspace;
  const activeChartReports = chartReports.find((ch) => ch.worksheetId === selectedWorksheetId);

  const handleExport = () => {
    if (activeChartReports) {
      const { reports, isUnion } = activeChartReports.reportAssembly;
      const { includedReportData } = activeChartReports;
      const availableChart: Array<TChartData> = reports
        .filter((r) => r.reportStatus === 1)
        .map((pl) => {
          const includedReport = includedReportData.find((ir) => ir.includedReportId === pl.includedReportId);
          const formalizeData = { ...pl, ...includedReport, isUnion } as any;
          const dataToConfig = formalizeChartConfigs(
            formalizeData,
            0,
            () => {},
            () => {},
          );
          return dataToConfig;
        }) as any;
      exportData(availableChart);
    }
  };

  const exportData = (data: Array<any>) => {
    const wb = createWorkbook();
    for (const chart of data) {
      const { title } = chart;
      let _data: any = setFormalisedData(chart);
      const formalisedData = [...generateArray(workspaceName, title), ..._data];
      const ws3: any = createWorksheet(formalisedData, false);
      const sheetName = getSheetName(title);
      XLSX.utils.book_append_sheet(wb, ws3, sheetName);
    }
    return XLSX.writeFile(wb, `${workspaceName}-Charts.xlsx`, { cellStyles: true });
  };
  return { handleExport };
};

export const setFormalisedData = (data: any) => {
  const { type } = data.config;
  if (CHART_TYPE_TO_EXPORT?.[type]) {
    return CHART_TYPE_TO_EXPORT[type](data);
  }
  return [];
};

export const exportPieChart = (data: any) => {
  const _data = data?.config?.dataSource?.data || [];
  return [
    ..._data.map((d) => {
      return [d.label, parseFloat(d.value)];
    }),
  ];
};

export const exportSplineChart = (data: any) => {
  const _data = data?.config?.dataSource?.data || [];
  const trendlines = data?.config?.dataSource?.trendlines || [];
  const tline = (trendlines?.length && trendlines[0]) || null;
  const average = tline?.line && tline.line[0] && tline.line[0].startvalue;
  return [
    ..._data.map((d) => {
      delete d.displayValue;
      return [d.label, d.value, parseFloat(average)];
    }),
  ];
};

export const exportMultipleSplineChart = (data: any) => {
  const linesDataset: any = data?.config?.dataSource?.dataset || [];
  const categories: any = data?.config?.dataSource?.categories || [];
  let dataFormalised: Array<Array<any>> = [[]];
  let iteratorColumn = 0;
  for (const { category } of categories) {
    for (const { label } of category) {
      dataFormalised[iteratorColumn] = [...dataFormalised[iteratorColumn], label];
    }
  }
  iteratorColumn = iteratorColumn + 1;
  for (const internData of linesDataset) {
    if (internData?.data?.length) {
      dataFormalised[iteratorColumn] = [...internData.data.map((d) => d.value)];
      iteratorColumn = iteratorColumn + 1;
    }
  }
  return dataFormalised;
};

export const exportColumnStackedChart = (data: any) => {
  const dataset = data?.config?.dataSource?.dataset || [];
  let _dataset: Array<any> = [];
  for (const dat of dataset) {
    if (dat?.data?.length) {
      for (let i = 0; i < dat.data.length; i++) {
        if (dat.data?.[i]) {
          const currentRow = ROW_START + i;
          if (!_dataset[currentRow]) {
            _dataset[currentRow] = [''];
          }
          const { value } = dat.data[i];
          const insideData = value ? +parseFloat(value).toFixed(0) : 0;
          _dataset[currentRow] = [..._dataset[currentRow], insideData];
        }
      }
    }
  }
  return _dataset;
};
export const exportMultipleDatasetChart = (data: any) => {
  const IOCategories: any = data?.config?.dataSource?.categories || [];
  const IODataset: any = data?.config?.dataSource?.dataset || [];
  let flowIODataFormalised: Array<Array<any>> = [[]];
  let iteratorioColumn = 0;
  let row = 0;
  for (const { category } of IOCategories) {
    for (const { label } of category) {
      if (!flowIODataFormalised[row][iteratorioColumn]) flowIODataFormalised[row][iteratorioColumn] = [];
      flowIODataFormalised[row][iteratorioColumn] = [...flowIODataFormalised[row][iteratorioColumn], label];
      iteratorioColumn = iteratorioColumn + 1;
    }
  }
  row = row + 1;
  for (const { dataset } of IODataset) {
    for (const { seriesname, data } of dataset) {
      iteratorioColumn = 0;
      flowIODataFormalised[row] = [];
      for (const dat of data) {
        if (!flowIODataFormalised[row][iteratorioColumn]) flowIODataFormalised[row][iteratorioColumn] = [];
        flowIODataFormalised[row][iteratorioColumn] = [...flowIODataFormalised[row][iteratorioColumn], dat?.value || 0];
        iteratorioColumn = iteratorioColumn + 1;
      }

      if (flowIODataFormalised[row]?.[iteratorioColumn]) flowIODataFormalised[row][iteratorioColumn] = [];
      flowIODataFormalised[row][iteratorioColumn] = [seriesname];
      row = row + 1;
    }
  }
  return flowIODataFormalised;
};

const CHART_TYPE_TO_EXPORT = {
  [ChartType.PIE]: exportPieChart,
  [ChartType.SPLINE]: exportSplineChart,
  [ChartType.MULTIPLE_SPLINE]: exportMultipleSplineChart,
  [ChartType.COLUMN_STACKED]: exportColumnStackedChart,
  [ChartType.FLOW_IN_FLOW_OUT]: exportMultipleDatasetChart,
  [ChartType.COLUMN_STACKED_2]: exportMultipleDatasetChart,
  [ChartType.COLUMN]: exportPieChart,
};

export default useExportAll;
