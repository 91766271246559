import './style.scss';

import { EAnalytical } from '../../../shared/constant/datasource.consts';
import CollapsibleItem, { BadgeTypes } from '../CollapsibleItem';

interface IAnalytical {
  analytical: EAnalytical;
}

const Analytical: React.FC<IAnalytical> = ({ analytical }) => {
  return (
    <CollapsibleItem title={`The data source includes an analytical axis`} badgeType={BadgeTypes.VALIDATED}>
      <div className="analyticalConclusion">
        {analytical === EAnalytical.ONE && `Only one column for the analytical axes has been identified`}
        {analytical === EAnalytical.TWO && `Two columns for the analytical axes have been identified`}
      </div>
    </CollapsibleItem>
  );
};

export default Analytical;
