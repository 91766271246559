// Style
import './style.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';

import DecimalDecreaseSVG from '../../../../../assets/img/decimal-decrease.svg';
import DecimalIncreaseSVG from '../../../../../assets/img/decimal-increase.svg';
import DropDownList from '../../../../../elements/DropDownList';
import { renderContent } from '../../../../../shared/helpers/helpers';

interface INumbersPartMenu {
  currencyParams: NS_Table.ICurrency;
  setCurrencyParams: React.Dispatch<React.SetStateAction<NS_Table.ICurrency>>;
  onMouseEnterIncreaseBtn?: () => void;
  onMouseLeaveIncreaseBtn?: () => void;
  onMouseEnterDecreaseBtn?: () => void;
  onMouseLeaveDecreaseBtn?: () => void;
  onMouseEnterUnitList?: () => void;
  onMouseLeaveUnitList?: () => void;
  onCurrencyFormatChange?: () => void;
  onUnitFormatChange?: () => void;
  disabled?: boolean;
}

/**
 * Regulator used to change decimal numbers
 *
 * @param param NS_Tabs.ITab props
 *
 */

const NumbersRegulator: React.FC<INumbersPartMenu> = ({
  currencyParams,
  setCurrencyParams,
  onUnitFormatChange,
  onCurrencyFormatChange,
  onMouseEnterIncreaseBtn,
  onMouseLeaveIncreaseBtn,
  onMouseEnterDecreaseBtn,
  onMouseLeaveDecreaseBtn,
  onMouseEnterUnitList,
  onMouseLeaveUnitList,
  disabled = false,
}) => {
  // States
  const currencyFormatAvailable: { name: string; value: 1 | 1000 | 1000000 }[] = [
    { name: '€', value: 1 },
    { name: 'K€', value: 1000 },
    { name: 'M€', value: 1000000 },
  ];
  const [currencyFormatSelected, setCurrencyFormatSelect] = useState<number>(0);

  // Effects
  useEffect(() => {
    setCurrencyFormatSelect(
      currencyFormatAvailable.findIndex((x) => {
        return x.value === currencyParams.displayUnit;
      }),
    );
  }, [currencyParams]);

  // Function
  const setCurrencyDisplayUnit = (index: number) => {
    setCurrencyFormatSelect(index);
    setCurrencyParams({ ...currencyParams, displayUnit: currencyFormatAvailable[index].value });
    if (onUnitFormatChange) {
      onUnitFormatChange();
    }
  };

  const setCurrencyFormat = (decimal: 1 | -1) => {
    let format = currencyParams.format;
    const idxOfDec = format.indexOf('.');

    if (decimal === 1) {
      if (idxOfDec === -1) {
        format = format + '.0';
      } else {
        const nbrDec = format.slice(idxOfDec + 1).length;
        if (nbrDec < 8) {
          format = format + '0';
        }
      }
    } else {
      if (idxOfDec !== -1) {
        const strDec = format.slice(idxOfDec, -1);
        if (strDec.length === 1) {
          format = format.slice(0, -2);
        } else {
          format = format.slice(0, -1);
        }
      }
    }
    setCurrencyParams({ ...currencyParams, format: format });
    if (onCurrencyFormatChange) {
      onCurrencyFormatChange();
    }
  };

  const handleDecimalIncrease = () => {
    if (!disabled) {
      setCurrencyFormat(1);
    }
  };

  const handleDecimalDescrease = () => {
    if (!disabled) {
      setCurrencyFormat(-1);
    }
  };

  const handleItemClick = useCallback((value: any, index: number) => {
    setCurrencyDisplayUnit(index);
  }, []);
  return (
    <div className="regulatorContainer">
      <div className="decimalPart">
        <div className="smallTitle">Decimals</div>
        <div className="toolBarSquareButtonContainer">
          <div
            className={`toolBarSquareButton ${renderContent(disabled, 'div--disabled', '')}`}
            onClick={handleDecimalIncrease}
            onMouseEnter={onMouseEnterIncreaseBtn}
            onMouseLeave={onMouseLeaveIncreaseBtn}>
            <ReactSVG src={DecimalIncreaseSVG} className="svg-wrapper decimalIncreaseSVG" />
          </div>
          <div
            className={`toolBarSquareButton ${renderContent(disabled, 'div--disabled', '')}`}
            onClick={handleDecimalDescrease}
            onMouseEnter={onMouseEnterDecreaseBtn}
            onMouseLeave={onMouseLeaveDecreaseBtn}>
            <ReactSVG src={DecimalDecreaseSVG} className="svg-wrapper decimalDecreaseSVG" />
          </div>
        </div>
      </div>
      <div className="unitPart" onMouseEnter={onMouseEnterUnitList} onMouseLeave={onMouseLeaveUnitList}>
        <div className="smallTitle">Unit</div>
        <DropDownList
          disabled={disabled}
          elements={currencyFormatAvailable.map((x) => x.name)}
          selectedElements={[currencyFormatAvailable[currencyFormatSelected].name]}
          onItemClick={handleItemClick}
        />
      </div>
    </div>
  );
};

export default NumbersRegulator;
