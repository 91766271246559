import './style.scss';

import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';

import DownArrowSVG from '../../../../assets/img/chevron-down-black.svg';
import { ReportContext } from '../../../../context/ReportContext';
import SmallLoader from '../../../../elements/Loaders/SmallLoader';
import ToggleButton from '../../../../elements/ToggleButton';
import {
  getDatasourceCoverage,
  updateGraphAutoCoverage,
  updateGraphWithCommandsList,
} from '../../../../shared/api/mapping';
import { GraphCommandOperationName } from '../../../../shared/helpers/report/reports.helper';
import ToastHelper from '../../../../shared/helpers/toast/ToastHelper';
import { API_STATUS } from '../../../../utils/constant';
import { REPORT_TYPE_OF_VISUALISATIONS } from '../../../../utils/enum';

const columns: NS_Table.IColumnHeader[] = [
  {
    Header: 'AccountID',
    accessor: '0',
    displayName: 'Accounts to add',
    InterpretedHeader: 'AccountID',
    colParams: {
      colScorf: false,
      colVisible: false,
      colDataType: {
        dataType: 'string',
        format: 'string',
      },
    },
    valueIndex: undefined,
  },
  {
    Header: 'ParentID',
    accessor: '1',
    displayName: 'Destination',
    InterpretedHeader: 'ParentID',
    colParams: {
      colScorf: false,
      colVisible: false,
      colDataType: {
        dataType: 'string',
        format: 'string',
      },
    },
    valueIndex: undefined,
  },
];

interface IAutoCoverageContainer {
  graphId: string;
  templateKey: string;
  selectedDataSource: NS_Workspace.IDataSourcesFile;
  accountsForCoverage: Array<NS_REPORT.INameSelectedForCoverageProps>;
  updateTreeCoverageAuto(data: NS_API.I_RD_StandardStmt): void;
  setTypeOfVisualisation: (val: string) => void;
  handleResetSelectedAccount: () => void;
  handleSetAlert: (message: Array<string>) => void;
}

interface IListAutoCoverageAccount {
  status: boolean;
  disable?: boolean;
  parentId: string;
  parentName: string;
}
const AutoCoverageContainer: React.FC<IAutoCoverageContainer> = (props) => {
  const {
    accountsForCoverage,
    selectedDataSource,
    graphId,
    templateKey,
    setTypeOfVisualisation,
    updateTreeCoverageAuto,
    handleResetSelectedAccount,
  } = props;
  // Context
  const { reportId } = useContext(ReportContext);
  const svgRefs = useRef<any[]>([]);
  const [list, setList] = useState<Array<NS_REPORT.INameSelectedForCoverageProps & IListAutoCoverageAccount>>([]);
  const [openContextHeader, setOpenContextHeader] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setAutoLinkAccount().catch((err) => ToastHelper.error(err));
  }, [selectedDataSource]);

  const setAutoLinkAccount = async () => {
    const { workspaceId } = selectedDataSource;
    const accountIds = accountsForCoverage.map((account) => account.accountId);
    const commands = {};
    commands[0] = {
      type: GraphCommandOperationName.AUTO_LINK_ACCOUNTS,
      parameters: { accountIds },
    };
    const result = await updateGraphAutoCoverage(workspaceId, graphId, templateKey, reportId as string, commands);
    if (result?.data) {
      if (result?.data?.commandsExecutionStatus?.[0]) {
        const { message, additionalInfo } = result.data.commandsExecutionStatus[0];
        const { status, updatedReport } = result.data;
        switch (status) {
          case API_STATUS.FAILED:
            if (message) {
              ToastHelper.error(message);
            } else {
              ToastHelper.error('Failed to link account');
            }
            setTypeOfVisualisation(REPORT_TYPE_OF_VISUALISATIONS.ACCOUNTS);
            setIsLoading(false);
            return;
          case API_STATUS.SUCCESS:
            if (message) {
              ToastHelper.success(message);
            }
            if (additionalInfo?.linkedAccountIds) {
              const { linkedAccountIds } = additionalInfo;
              const entries: any = Object.entries(linkedAccountIds);
              setList([
                ...entries.map(([key, val]) => {
                  const linkedTo = val.linkedTo;
                  return {
                    accountId: key,
                    accountName: val.name as string,
                    status: true,
                    parentId: linkedTo.id,
                    parentName: linkedTo.name,
                  };
                }),
              ]);
            }
            updateTree(updatedReport);
            break;
          case API_STATUS.PARTIALLY_EXECUTED:
            if (message) {
              ToastHelper.info(message);
            }
            if (additionalInfo) {
              const { linkedAccountIds, failedToLinkAccountIds } = additionalInfo;
              const entries: any = Object.entries(linkedAccountIds);
              const entries2: any = Object.entries(failedToLinkAccountIds);
              setList([
                ...entries
                  .map(([key, val]) => {
                    if (val?.linkedTo) {
                      const linkedTo = val.linkedTo;
                      return {
                        accountId: key,
                        accountName: val.name as string,
                        status: true,
                        parentId: linkedTo.id,
                        parentName: linkedTo.name,
                      };
                    }
                  })
                  .filter((v) => v),
                ...entries2
                  .map(([key, val]) => {
                    if (val?.linkedTo) {
                      const linkedTo = val.linkedTo;
                      return {
                        accountId: key,
                        accountName: val.name as string,
                        status: true,
                        parentId: linkedTo.id,
                        parentName: linkedTo.name,
                      };
                    }
                  })
                  .filter((v) => v),
              ]);
            }
            updateTree(updatedReport);
            break;
        }
      }
    }
    handleResetSelectedAccount();
    await getDatasourceCoverage(workspaceId, selectedDataSource.fileId).then((res) => {});
    setIsLoading(false);
  };

  const updateTree = (data: NS_API.I_RD_StandardStmt) => {
    if (data) {
      updateTreeCoverageAuto(data);
    }
  };
  const handleConfirm = () => {
    (async () => {
      // If switch set to No
      const rollbackAccounts = list
        .map((el) => {
          if (!el.status && !el.disable) {
            return el;
          }
          return null;
        })
        .filter((el) => !!el);
      if (rollbackAccounts?.length) {
        const newCommands = {};
        for (let k = 0; k < rollbackAccounts.length; k++) {
          const account = rollbackAccounts[k];
          if (account?.accountId && account?.parentId) {
            newCommands[k] = {
              type: GraphCommandOperationName.DELETE_ACCOUNTS,
              parameters: {
                accountIdsToDelete: [account.accountId],
              },
            };
          }
        }
        const { workspaceId } = selectedDataSource;
        setIsLoading(true);
        const res = await updateGraphWithCommandsList(
          workspaceId,
          graphId,
          templateKey,
          newCommands,
          reportId as string,
        );
        if (res?.data?.updatedReport) {
          const { updatedReport } = res.data;
          updateTree(updatedReport);
        }
        setIsLoading(false);
      }
      setTypeOfVisualisation('');
    })();
  };

  const handleRollbackAccount = (index: number, status: boolean) => {
    setList([
      ...list.map((el, i) => {
        if (index === i) {
          return { ...el, status };
        }
        return el;
      }),
    ]);
  };

  const handleRollAction = (disable: boolean | undefined, index: number, status: boolean) => (checked: boolean) => {
    if (!disable) {
      handleRollbackAccount(index, !status);
    }
  };

  const handleOpenHeader = (index: number) => (e) => {
    setOpenContextHeader(openContextHeader === index ? -1 : index);
  };

  const handleDownInjection = useCallback((svg: any) => {
    if (!svgRefs.current.includes(svg)) svgRefs.current.push(svg);
  }, []);

  return (
    <>
      <div className={`auto-coverage-container`}>
        <table>
          <thead>
            <tr>
              {columns.map((col, index) => {
                return (
                  <th key={`${col.displayName}-${col.accessor}`}>
                    {(index === 1 || index === 0) && (
                      <div className="td">
                        <div className="colName">{col.displayName}</div>
                        <ReactSVG
                          src={DownArrowSVG}
                          className="svg-wrapper downArrowSVG"
                          beforeInjection={handleDownInjection}
                          onClick={handleOpenHeader(index)}
                        />
                      </div>
                    )}
                  </th>
                );
              })}
              <th>
                <div className="td">
                  <div className="colName">{'Yes/No'}</div>
                </div>
              </th>
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {list.length > 0 &&
                list.map((account, index) => {
                  const key = `${account.accountName} - ${index}`;
                  return (
                    <tr key={key}>
                      <td className={`table_bodyCell--text `}>{`${account.accountId} ${account.accountName}`}</td>
                      <td className={`table_bodyCell--text `}>{`${account.parentId} ${account.parentName}`}</td>
                      <td className=" toggleButtonColumn">
                        {' '}
                        <ToggleButton
                          active={account.status}
                          disabled={!!account.disable}
                          onChange={handleRollAction(account?.disable, index, account.status)}
                          isMini={true}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>
        {isLoading && (
          <div className="loading-coverage-container">
            <div className="loading-coverage">
              <SmallLoader />
            </div>
          </div>
        )}
      </div>
      {!isLoading && (
        <div className="coverage-footer">
          <button
            className={`btn button--white`}
            onClick={handleConfirm}
            onMouseEnter={(e) => {}}
            onMouseLeave={(e) => {}}>
            Confirm
          </button>
        </div>
      )}
    </>
  );
};

export default AutoCoverageContainer;
