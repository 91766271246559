// Style
import './style.scss';

import axios from 'axios';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import redCross from '../../assets/img/cross-red.svg';
import { WorkspaceContext } from '../../context/WorkspaceContext';
import DropDownList from '../../elements/DropDownList';
import SmallLoader from '../../elements/Loaders/SmallLoader';
import { useAppSelector } from '../../redux/hook';
import { getGraph } from '../../shared/api/graph';
import { getDatasourceCard } from '../../shared/api/structuration';
import { sheetOrchestrator } from '../../shared/api/tabs';
import { DefaultTemplateCollectionName, IReportInfo, ReportsList } from '../../shared/constant/datasource.consts';
import { generateExportList } from '../../shared/helpers/datasource/datasource.helper';
import { renderContent } from '../../shared/helpers/helpers';
import ToastHelper from '../../shared/helpers/toast/ToastHelper';
import { UnionFormErrorType } from '../Union';
import { datasourceComplianceCheck } from './checkDatasourceCompliance';

interface ICreateReportsPack {
  onClose: () => void;
  selectedDataSource: NS_Workspace.IDataSourcesFile;
}

/**
 * Report Builder component
 *
 * @param params ICreateReportsPack params
 */

const CreateReportsPack: React.FC<ICreateReportsPack> = ({ onClose, selectedDataSource }) => {
  // Context
  const { workspaceId, getTabs } = useContext(WorkspaceContext);
  const reduxState = useAppSelector((state) => state);
  const { informations } = reduxState.datasource;
  const { templateCollections } = reduxState.workspace;
  // States

  const [change, setChange] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [newFileName, setNewFileName] = useState<string>(selectedDataSource.displayName.slice(0, 4));
  const [newFileNameError, setNewFileNameError] = useState<UnionFormErrorType>(UnionFormErrorType.Initial);
  const [getStatusUri, setGetStatusUri] = useState<string>('');
  const [loaderProgression, setLoaderProgression] = useState<number>(0);
  const [templates, setTemplates] = useState<Array<IReportInfo>>([]);
  const [selectedTC, setSelectedTC] = useState<string>('');
  const [loadingReports, setLoadingReports] = useState<boolean>(true);

  // Functions
  const handleSave = () => {
    if (enableSave) {
      setLoading(true);
      const reportsToCall = templates.filter((report) => report.reportActivated);
      const reportBuilderParams = reportsToCall.map((report) => {
        return {
          worksheetName: `${newFileName} - ${report.reportName}`.slice(0, 100),
          templateKey: report.templateKey,
          yearOrMonth: report.yearOrMonth,
          cumulDisplay: report.cumulDisplay,
        };
      });

      const selectedTCIndex = templateCollectionNames.indexOf(selectedTC);
      const data: NS_Workspace.ISheetOrchestrator = {
        workspaceId: selectedDataSource.workspaceId,
        datasourceId: selectedDataSource.fileId,
        graphId: templateCollections[selectedTCIndex].graphId ?? selectedDataSource.graphId,
        columnDimensions: ['FiscalYear'],
        isUnion: selectedDataSource.unionInfo ? true : false,
        reportBuilderParams,
      };

      sheetOrchestrator(data)
        .then((res) => {
          if (res) {
            const redData = res.data;
            setGetStatusUri(redData.statusQueryGetUri);
          }
        })
        .catch((err) => {});
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setNewFileNameError(UnionFormErrorType.Error);
    } else {
      setNewFileNameError(UnionFormErrorType.Success);
    }
    setNewFileName(event.target.value);
  };

  const enableSave = useMemo(() => {
    return (
      ReportsList.some((report) => report.reportActivated) &&
      newFileNameError !== UnionFormErrorType.Error &&
      newFileName.length !== 0 &&
      !loading
    );
  }, [newFileNameError, newFileName, loading]);

  const callStatusApi = () => {
    if (getStatusUri !== '') {
      axios
        .get(getStatusUri)
        .then((res) => {
          if (res) {
            const { data } = res;
            if (data.customStatus && data.customStatus.step === 'Done') {
              setGetStatusUri('');
              setTimeout(() => {
                onClose();
                setLoading(false);
              }, 1000);
              getTabs(workspaceId);
              setLoaderProgression(100);
            } else if (
              data.customStatus &&
              (data.customStatus.step === 'Generating reports' || data.customStatus.step === 'Generating worksheets')
            ) {
              if (data.customStatus.progress) {
                setLoaderProgression(data.customStatus.progress);
              }
              if (data.runtimeStatus === 'Failed') {
                onClose();
                setLoading(false);
                getTabs(workspaceId);
                if (data.output.indexOf('403') !== -1) {
                  // Permission denied
                  ToastHelper.warn(`You don't have enough permissions`);
                } else {
                  // others
                  ToastHelper.error(`Error while creating your reports`);
                }
              }
            }
          }
        })
        .catch((err) => {});
    }
  };

  const getLoaderInfo = () => {
    return `${loaderProgression}%`;
  };

  const getLoaderLabel = () => {
    if (loaderProgression === 0) {
      return (
        <>
          Starting&nbsp;
          <div className="stage">
            <div className="dot-pulse"></div>
          </div>
        </>
      );
    } else {
      return getLoaderInfo();
    }
  };

  // Effect
  const templateCollectionNames = useMemo(() => {
    return templateCollections.map((tc) => tc.collectionName);
  }, [templateCollections]);

  useEffect(() => {
    if (templateCollections?.length) {
      const standardTC = templateCollections.filter((tc) => tc.collectionName === DefaultTemplateCollectionName)[0];
      setSelectedTC(standardTC.collectionName);
    }
  }, [templateCollections]);

  useEffect(() => {
    const interval = window.setInterval(() => {
      callStatusApi();
    }, 2000);

    if (getStatusUri === '') {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [getStatusUri]);

  useEffect(() => {
    const staticReportsList = [...generateExportList()];
    async function doCheckDatasourceCompliance(this: any, graphId: string, staticReportsList: Array<IReportInfo>) {
      const isAlreadyexist = informations.find(
        (information) =>
          information.fileId === selectedDataSource.fileId &&
          information.workspaceId === workspaceId &&
          !!information.report,
      );
      if (isAlreadyexist?.report && selectedTC === DefaultTemplateCollectionName) {
        setTemplates(JSON.parse(isAlreadyexist.report));
        return;
      }
      let datasourceCard: Pick<
        NS_API.IInterpretationCard,
        'eachEntryHasOneTransactionId' | 'eachTransactionIdHasAtLeastTwoEntries' | 'areAuxAccountIdentified'
      >;
      try {
        datasourceCard = await getDataSourceCardInfo();
      } catch (error) {
        ToastHelper.error(`Cannot get card information`, error);
      }

      const getGraphOutput = await getGraph(workspaceId, graphId);
      const templateParams = getGraphOutput.data?.graph?.templateParams;
      if (!templateParams) {
        ToastHelper.error(`Failed to load Graph Template Parameters`);
      }

      const templateParamsKeysSet = new Set<string>(Object.keys(templateParams));
      let reportsToUse: Array<IReportInfo> = [];

      reportsToUse = staticReportsList.filter((value) => templateParamsKeysSet.has(value.templateKey));

      reportsToUse.forEach(
        function (_value, index, array) {
          const reportInfo = array[index];
          const reportInfoTemplateKey = reportInfo.templateKey;

          const checkResult = datasourceComplianceCheck[reportInfoTemplateKey](
            datasourceCard,
            reportInfo.reportName,
            selectedDataSource?.unionInfo,
          );

          if (checkResult === false) {
            array[index].accessible = false;
            array[index].reportActivated = false;
          }
        }.bind(this),
      );

      setTemplates(reportsToUse);
    }

    if (selectedTC === '') {
      return;
    }

    const selectedTCIndex = templateCollectionNames.indexOf(selectedTC);
    const graphId = templateCollections[selectedTCIndex].graphId ?? selectedDataSource.graphId;

    doCheckDatasourceCompliance(graphId, staticReportsList).catch((err) => {});
    setLoadingReports(false);
  }, [selectedTC]);

  const getDataSourceCardInfo = async (): Promise<
    Pick<
      NS_API.IInterpretationCard,
      'eachEntryHasOneTransactionId' | 'eachTransactionIdHasAtLeastTwoEntries' | 'areAuxAccountIdentified'
    >
  > => {
    setLoadingReports(true);
    const datasourceData = informations.find(
      (information) => information.fileId === selectedDataSource.fileId && information.workspaceId === workspaceId,
    );
    if (datasourceData?.fileId && datasourceData?.card) {
      const parseData = JSON.parse(datasourceData.card);
      return {
        eachEntryHasOneTransactionId: parseData.eachEntryHasOneTransactionId,
        eachTransactionIdHasAtLeastTwoEntries: parseData.eachTransactionIdHasAtLeastTwoEntries,
        areAuxAccountIdentified: parseData.areAuxAccountIdentified,
      };
    }
    return getDatasourceCard(workspaceId, selectedDataSource.fileId)
      .then((res) => {
        if (res) {
          const { data } = res;
          const dataObj = data.interpretationCard;
          return {
            eachEntryHasOneTransactionId: dataObj.eachEntryHasOneTransactionId,
            eachTransactionIdHasAtLeastTwoEntries: dataObj.eachTransactionIdHasAtLeastTwoEntries,
            areAuxAccountIdentified: dataObj.areAuxAccountIdentified,
          };
        }
        // throw Error(`Cannot get card information`);
      })
      .catch((err) => {
        setLoadingReports(false);
        ToastHelper.error(`Cannot get card information`, err);
        // throw Error(`Cannot get card information`);
      });
  };

  return (
    <div className="reportsCreationContainer">
      <div className="dataSourceCreationModal">
        {loading ? (
          <div className="modalBody">
            <p className="waitingMsg">One moment please...We are building your reports!</p>
            <div className="loaderLabel">{getLoaderLabel()}</div>
            <div className="loaderContainer">
              <div className="loaderBar" style={{ width: getLoaderInfo() }} />
            </div>
          </div>
        ) : (
          <>
            <div className="modalHeader">
              <strong>Reports Creator</strong>
              <div className="rightBlock">
                <img src={redCross} alt="croix" onClick={onClose} />
              </div>
            </div>
            <p>
              Please pick reports to generate from the data source <span>{selectedDataSource.displayName}</span>
            </p>
            <div className="templateCollectionContainer">
              <span title="Report generated from the Template Collection">
                Report generated from the Template Collection :{' '}
              </span>
              <DropDownList
                elements={templateCollectionNames}
                selectedElements={[selectedTC]}
                onItemClick={setSelectedTC}
              />
            </div>
            <div className="worksheetsContainer">
              {renderContent(
                loadingReports,
                <SmallLoader />,
                templates.map((report, i) => {
                  const key = `${report.reportName} - ${i}`;
                  return (
                    <div
                      className={`worksheet`}
                      key={key}
                      onClick={() => {
                        if (report.accessible) {
                          report.reportActivated = !report.reportActivated;
                          setChange(!change);
                        }
                      }}>
                      <strong>{report.reportName}</strong>
                      {renderContent(
                        report.accessible,
                        null,
                        <span className="worksheet_warning--span">
                          This report can not be generated with this datasource
                        </span>,
                      )}
                      <div
                        className={`toolBarSwitchButton ${renderContent(
                          report.accessible,
                          '',
                          'toolBarSwitchButton--disabled',
                        )}`}>
                        <div className={report.reportActivated ? 'background backgroundActive' : 'background'} />
                        <div className={report.reportActivated ? 'slider sliderActive' : 'slider'} />
                      </div>
                    </div>
                  );
                }),
              )}
            </div>
            <div className="buttons">
              <div className="infoPlaceholder">
                <label>Report prefix</label>
                <div className="union-name-input">
                  <input
                    id="union-name"
                    className={`${newFileNameError === UnionFormErrorType.Error && 'inputError'} ${
                      newFileNameError === UnionFormErrorType.Success && 'inputSuccess'
                    }`}
                    defaultValue={newFileName}
                    placeholder="Report prefix name"
                    onChange={handleNameChange}
                    maxLength={30}
                  />
                  {newFileNameError === UnionFormErrorType.Error && newFileName.length !== 0 && (
                    <div className="existing-name-error">Existing name</div>
                  )}
                </div>
              </div>
              <div>
                <button
                  className={renderContent(!enableSave, 'button--green--disabled', 'button--green')}
                  type="submit"
                  onClick={handleSave}>
                  Create Reports
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateReportsPack;
