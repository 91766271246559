// Logger with default options
import logger from 'redux-logger';

import { configureStore } from '@reduxjs/toolkit';

import chartReducer from './charts';
import datasourceReducer from './datasource';
import exportReducer from './export';
import notificationReducer from './notifications';
import workspaceReducer from './workspace';

export const store = configureStore({
  reducer: {
    workspace: workspaceReducer,
    notification: notificationReducer,
    chart: chartReducer,
    datasource: datasourceReducer,
    export: exportReducer,
  },
  middleware: (defaultMiddleWare) => defaultMiddleWare().concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
