import './style.scss';

import React from 'react';

import { isDisabled } from '../../shared/helpers/string/classnames.helper';

interface IToggleButton {
  /**
   * Is button active?
   */
  active: boolean;
  /**
   * Is button disabled?
   */
  disabled?: boolean;
  /**
   * Value change handler.
   */
  onChange: (checked: boolean) => void;
  /**
   * Button label
   */
  label?: string;
  /**
   * Mini switch
   */
  isMini?: boolean;
}

/**
 * Custom toggle Button
 */
const ToggleButton: React.FC<IToggleButton> = ({ active, disabled = false, isMini = false, onChange, label }) => {
  const toggleCheck = () => {
    if (!disabled) {
      onChange(!active);
    }
  };

  return (
    <div className="toggleButtonContainer">
      {label && <span className="toggleButtonLabel">{label}</span>}
      <div className={`${isMini && 'toggleButton-mini'}  toggleButton ${isDisabled(disabled)}`}>
        <input type="checkbox" checked={active} disabled={disabled} readOnly />
        <span className={`${isMini && 'slider-mini'} slider`} onClick={toggleCheck} />
      </div>
    </div>
  );
};

export default ToggleButton;
