import { useEffect } from 'react';

import { useInterval } from '../../elements/UseInterval';
import { fetchSelectedReport, setNewChartReady } from '../../redux/charts';
import { handleDSNotification } from '../../redux/datasource';
import { handleExportDone } from '../../redux/export';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import { changePollingTime, deleteNotification, increasePollingDelay, POLLING_DELAY } from '../../redux/notifications';
import { getNotification } from '../../shared/api/notification';
import { IReportAssemblyNotif, NOTIFICATION_TYPE } from '../../shared/constant/chart.consts';
import { IDataSourceNotification } from '../../shared/constant/datasource.consts';
import { IMappingNotification } from '../../shared/constant/mapping.consts';

const useShortPolling = () => {
  const reduxState = useAppSelector((state) => state);
  const { pollingTime: delay } = reduxState.notification;
  const { selectedWorksheetId, selectedWorkspace } = reduxState.chart;
  const dispatch = useAppDispatch();

  useEffect(() => {
    queryNotification();
  }, []);

  useInterval(async () => {
    queryNotification();
  }, delay);

  const executeChartReportNotification = (notificationData: string) => {
    if (notificationData) {
      const reportAssemblyData: IReportAssemblyNotif = JSON.parse(notificationData);
      // notification report assembly ready
      dispatch(setNewChartReady(reportAssemblyData));
      // Check and Fetch if in same workspace
      if (
        selectedWorksheetId &&
        selectedWorkspace &&
        reportAssemblyData?.workspaceId &&
        reportAssemblyData.workspaceId === selectedWorkspace
      ) {
        // check if not status in pending
        dispatch(fetchSelectedReport(true));
        dispatch(deleteNotification(reportAssemblyData.reportAssemblyId));
      }
    }
  };

  const executeDatasourceNotification = (notificationData: string) => {
    if (notificationData) {
      const datasourceData: IDataSourceNotification = JSON.parse(notificationData);
      dispatch(handleDSNotification(datasourceData));
    }
  };

  const executeExportDoneNotification = (notificationData: string) => {
    if (notificationData) {
      const exportData: IMappingNotification = JSON.parse(notificationData);
      dispatch(handleExportDone(exportData));
    }
  };

  const executeNotification = {
    [NOTIFICATION_TYPE.CHART_REPORT_READY]: executeChartReportNotification,
    [NOTIFICATION_TYPE.DATASOURCE_PROGRESS]: executeDatasourceNotification,
    [NOTIFICATION_TYPE.EXPORT_DONE]: executeExportDoneNotification,
  };

  const queryNotification = () => {
    getNotification()
      .then(({ data }) => {
        // no data
        if (data && data.length === 0 && delay !== POLLING_DELAY.default) {
          dispatch(increasePollingDelay());
        } else if (data?.length) {
          // another delay, reset to 5000 ms
          if (delay !== POLLING_DELAY.executed) {
            dispatch(changePollingTime(POLLING_DELAY.executed));
          }
          // display report chart ready
          for (const notification of data) {
            const { type, data: notificationData } = notification;
            if (notificationData && executeNotification?.[type]) {
              executeNotification[type](notificationData);
            }
          }
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
};
export default useShortPolling;
