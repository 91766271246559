// Style
import './style.scss';

import React, { useEffect, useState } from 'react';
// SVG
import { ReactSVG } from 'react-svg';

import downArrow from '../../../assets/img/chevron-down.svg';
import rightArrow from '../../../assets/img/chevron-right.svg';
import UpArrowSVG from '../../../assets/img/chevron-up.svg';
import EditSVG from '../../../assets/img/edit.svg';
import QA from '../../../assets/img/q&a.svg';
import TrashSVG from '../../../assets/img/trash-red.svg';
import { WorkspaceContext } from '../../../context/WorkspaceContext';
import LargeLoader from '../../../elements/Loaders/LargeLoader';
// API
import { deleteQuestion, getAllQuestions, updateQuestion } from '../../../shared/api/QandA';
import { setSelectedColor } from '../../../shared/helpers/qanda/qanda.helper';
import ToastHelper from '../../../shared/helpers/toast/ToastHelper';

export interface IExportList {
  setShowTitle: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddNewSheet: () => void;
  handleGotoDS: () => void;
}

/**
 * Container used to display the list of questions and answers of a workspace
 * @param param IQnAListProps
 */

const QnAList = ({ setShowTitle, handleGotoDS, handleAddNewSheet }) => {
  const { workspaceId, tabs, dataSources } = React.useContext(WorkspaceContext);

  // States

  // Loaders
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);

  // Data
  const [questions, setQuestions] = useState<Array<NS_Workspace.IQuestion>>([]);

  // Display
  const [openedRow, setOpenedRow] = useState<number>(-1);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [hiddenQuestions, setHiddenQuestion] = useState<Array<number>>([]);

  // Edit
  const [newDescription, setNewDescription] = useState<string>('');
  const [newTopic, setNewTopic] = useState<string>('');
  const [newState, setNewState] = useState<string>('');
  const [newPriority, setNewPriority] = useState<string>('');
  const [newAnswer, setNewAnswer] = useState<string>('');

  useEffect(() => {
    getQuestions();
    // eslint-disable-next-line
  }, [workspaceId]);

  useEffect(() => {
    setShowTitle(!isLoaderVisible && questions.length !== 0);
  }, [isLoaderVisible, questions.length]);

  const getQuestions = () => {
    getAllQuestions(workspaceId)
      .then((res) => {
        if (res) {
          if (res.status === 404) {
            setQuestions([]);
            setIsLoaderVisible(false);
          } else {
            setQuestions(res.data);
            setIsLoaderVisible(false);
          }
        } else {
          ToastHelper.error('Cannot get questions list');
          setIsLoaderVisible(false);
        }
      })
      .catch((err) => {
        ToastHelper.error('Cannot get questions list', err);
        setQuestions([]);
        setIsLoaderVisible(false);
      });
  };

  const getReportName = (reportId) => {
    const matchedReports = tabs.filter((report) => report.id === reportId);
    return matchedReports.length === 0 ? 'Report not found' : matchedReports[0].name;
  };

  const handleQuestionClick = (index: number) => {
    if (openedRow === index) {
      setOpenedRow(-1);
    } else {
      setOpenedRow(index);
    }
  };

  const handleDeleteClick = (question: NS_Workspace.IQuestion, index: number) => () => {
    setHiddenQuestion([...hiddenQuestions, index]);
    deleteQuestion(question.workspaceId, question.questionId)
      .then((res) => {
        if (res) {
          ToastHelper.success('Question deleted successfully');
        } else {
          setHiddenQuestion(hiddenQuestions.filter((_, i) => i !== index));
        }
      })
      .catch((err) => {
        setHiddenQuestion(hiddenQuestions.filter((_, i) => i !== index));
        ToastHelper.error('Cannot delete question', err);
      });
  };

  const handleEditClick = (index: number) => () => {
    if (index === openedRow && isEditing) {
      setIsEditing(false);
      setOpenedRow(-1);
      const question = questions[index];
      if (
        question.topic !== newTopic ||
        question.priority !== newPriority ||
        question.status !== newState ||
        question.description !== newDescription ||
        question.answer !== newAnswer
      ) {
        const updatedQuestion: NS_Workspace.IQADto = {
          topic: newTopic,
          priority: newPriority,
          status: newState,
          description: newDescription,
          answer: newAnswer,
        };
        updateQuestion(workspaceId, updatedQuestion, question.questionId)
          .then((res) => {
            if (res) {
              ToastHelper.success('Question updated successfully');
              getQuestions();
            } else {
              ToastHelper.error('Cannot update question');
            }
          })
          .catch((err) => {
            ToastHelper.error('Cannot update question', err);
          });
      }
    } else {
      setIsEditing(true);
      setOpenedRow(index);
      setNewDescription(questions[index].description);
      setNewTopic(questions[index].topic);
      setNewState(questions[index].status);
      setNewPriority(questions[index].priority);
      setNewAnswer(questions[index].answer);
    }
  };

  const handleEditProperties = (index: number) => () => {
    if (isEditing) {
      const priorities = ['high', 'medium', 'low'];
      setNewPriority(priorities[index]);
    }
  };

  const handleEditStatus = (index: number) => () => {
    if (isEditing) {
      const status = ['open', 'ongoing', 'closed'];
      setNewState(status[index]);
    }
  };

  return (
    <div className="QnAListContainer">
      {isLoaderVisible ? (
        <div className="loaderImgContainer">
          <LargeLoader />
        </div>
      ) : (
        <>
          {questions.length !== 0 && (
            <div className="QnAList">
              <table className="QnAListTable">
                <thead>
                  <tr>
                    <th></th>
                    <th>Key</th>
                    <th>State</th>
                    <th>Report</th>
                    <th>Topic</th>
                    <th>Priority</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {questions.map((question, index) => {
                    if (!hiddenQuestions.includes(index)) {
                      const indexx = `${index}-`;
                      return (
                        <React.Fragment key={question.questionId}>
                          <tr key={question.questionId}>
                            <td onClick={() => handleQuestionClick(index)} data-cy={`${question.questionId}-open`}>
                              {openedRow === index ? (
                                <img src={downArrow} alt="down arrow" />
                              ) : (
                                <img src={rightArrow} alt="right arrow" />
                              )}
                            </td>
                            <td className="questionId">#{question.questionId}</td>
                            <td>
                              {['red', 'orange', 'blue'].map((color, i) => {
                                let selectedColor = '';
                                selectedColor =
                                  isEditing && index === openedRow
                                    ? setSelectedColor(newState)
                                    : setSelectedColor(question.status);
                                const showOthers = index === openedRow && isEditing;
                                let classNames = '';
                                if (color !== selectedColor && !showOthers) {
                                  classNames = 'hidden';
                                } else if (color === selectedColor) {
                                  classNames = 'selected';
                                }

                                return (
                                  <div
                                    className={`state ${color} ${classNames}`}
                                    data-cy={`${question.questionId}-state-${color}-${classNames}`}
                                    key={color}
                                    onClick={handleEditStatus(i)}></div>
                                );
                              })}
                            </td>
                            <td className="cell_reportName" data-cy={`${question.questionId}-reportName`}>
                              {getReportName(question.reportId)}
                            </td>
                            <td className="cell_topic" data-cy={`${question.questionId}-topic`}>
                              {openedRow === index && isEditing ? (
                                <input
                                  type="text"
                                  value={newTopic}
                                  data-cy={`${question.questionId}-inputTopic`}
                                  onChange={(e) => setNewTopic(e.target.value)}
                                  className="input_topic"
                                  maxLength={99}
                                />
                              ) : (
                                question.topic
                              )}
                            </td>
                            <td>
                              {['red', 'orange', 'blue'].map((color, i) => {
                                let selectedColor = '';
                                selectedColor =
                                  isEditing && index === openedRow
                                    ? setSelectedColor(newPriority)
                                    : setSelectedColor(question.priority);

                                const showOthers = index === openedRow && isEditing;
                                let classNames = '';
                                if (color !== selectedColor && !showOthers) {
                                  classNames = 'hidden';
                                } else if (color === selectedColor) {
                                  classNames = 'selected';
                                }

                                return (
                                  <ReactSVG
                                    key={color}
                                    src={UpArrowSVG}
                                    className={`svg-wrapper proritySVG priority ${color} ${classNames}`}
                                    data-cy={`${question.questionId}-priority-${color}-${classNames}`}
                                    onClick={handleEditProperties(i)}
                                  />
                                );
                              })}
                            </td>
                            <td className="actions">
                              <ReactSVG
                                src={EditSVG}
                                className={`svg-wrapper editSVG ${openedRow === index && isEditing ? 'green' : 'grey'}`}
                                data-cy={`${question.questionId}-edit`}
                                onClick={handleEditClick(index)}
                              />
                              <ReactSVG
                                src={TrashSVG}
                                className="svg-wrapper trashSVG"
                                onClick={handleDeleteClick(question, index)}
                              />
                            </td>
                          </tr>
                          {openedRow === index && (
                            <tr key={indexx}>
                              <td colSpan={7} className="description" style={{ textAlign: 'center' }}>
                                {isEditing && (
                                  <>
                                    <div className="editQuestion">
                                      <span>Question</span>
                                      <textarea
                                        value={newDescription}
                                        onChange={(e) => setNewDescription(e.target.value)}
                                        data-cy={`${question.questionId}-edit-question`}></textarea>
                                    </div>
                                    <div className="editAnswers">
                                      <span>Answer</span>
                                      <div>
                                        <div className="editAnswerBlock">
                                          <textarea
                                            defaultValue={question.answer}
                                            onChange={(e) => setNewAnswer(e.target.value)}
                                            data-cy={`${question.questionId}-edit-answer`}></textarea>
                                          <button
                                            className="btnValidate button--green"
                                            onClick={handleEditClick(index)}>
                                            Validate changes
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {!isEditing && (
                                  <>
                                    <div className="questions">
                                      <span className="label">Question : </span>
                                      <span data-cy={`${question.questionId}-question`}>{question.description}</span>
                                    </div>
                                    <div className="answers">
                                      <span className="label">Answer : </span>
                                      <span data-cy={`${question.questionId}-answer`}>{question.answer}</span>
                                    </div>
                                  </>
                                )}
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    }
                    return null;
                  })}
                </tbody>
              </table>
            </div>
          )}
          {questions.length === 0 && (
            <>
              <div className="placeholderContainerExport">
                <img src={QA} alt="" />
                <h3>No question created yet !</h3>
                <div className="textContainer">
                  <p>You can create questions by using the Q&A tool in your Reports.</p>
                  <p>If you don’t have any Report created yet, you can do so by clicking on the button below.</p>
                </div>
                <div className="btnContainers">
                  <button className="button--green" onClick={handleGotoDS}>
                    Add a Data Source
                  </button>
                  {dataSources.length > 0 && (
                    <button className="button--white" onClick={handleAddNewSheet}>
                      Create Report
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default QnAList;
