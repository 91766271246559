// Style
import './style.scss';

import React, { useCallback, useContext } from 'react';
//
import { ReactSVG } from 'react-svg';

import LeftDispositionSVG from '../../assets/img/disposition-left.svg';
import MinusSVG from '../../assets/img/minus.svg';
import PlusSVG from '../../assets/img/plus.svg';
import { WorkspaceContext } from '../../context/WorkspaceContext';

interface IFooterProps {
  /**
   * Workspace name to display.
   */
  workspaceName: string;
  leftDisposition?: boolean;
  setLeftDisposition?: (val: boolean) => void;
  zoomValue?: string;
  setZoomValue?: (val: string) => void;
  /**
   * Contains a zoom value regulator?
   */
  needZoom?: boolean;
  /**
   * Contains disposition regulator?
   */
  needReverse?: boolean;
}

/**
 * Component used to display workspace informations on the bottom of the page
 */
const Footer: React.FC<IFooterProps> = ({
  workspaceName,
  leftDisposition,
  setLeftDisposition,
  needZoom = false,
  zoomValue,
  setZoomValue,
  needReverse = true,
}) => {
  const { sumFooter } = useContext(WorkspaceContext);

  const handlePlusZoom = useCallback(() => {
    if (zoomValue && setZoomValue)
      setZoomValue(parseInt(zoomValue) < 20 ? (parseInt(zoomValue) + 1).toString() : zoomValue);
  }, [zoomValue]);

  const handleMinusZoom = useCallback(() => {
    if (zoomValue && setZoomValue)
      setZoomValue(parseInt(zoomValue) > 1 ? (parseInt(zoomValue) - 1).toString() : zoomValue);
  }, [zoomValue]);

  const handleLeftPosition = (value: boolean) => () => {
    if (setLeftDisposition) setLeftDisposition(value);
  };

  return (
    <div className="footer">
      <div className="workspaceName">{workspaceName}</div>
      {needZoom && zoomValue && setZoomValue && (
        <div className="centerMenuFooter">
          <div className="zoomContainer">
            <ReactSVG src={MinusSVG} className="svg-wrapper minusSVG" onClick={handleMinusZoom} />
            <input
              className="custom-slider"
              type="range"
              id="zoom"
              name="zoom"
              min="1"
              max="20"
              value={zoomValue}
              onChange={(e) => setZoomValue(e.target.value)}
              style={{ zIndex: 500 }}></input>
            <ReactSVG src={PlusSVG} className="svg-wrapper plusSVG" onClick={handlePlusZoom} />
            <div className="zoomLabel">{zoomValue}0%</div>
          </div>
        </div>
      )}
      <div className="rightContent">
        {needReverse && setLeftDisposition && (
          <div className="images">
            <ReactSVG
              src={LeftDispositionSVG}
              className={`svg-wrapper leftDispositionSVG ${leftDisposition ? 'selected' : 'notSelected'}`}
              onClick={handleLeftPosition(true)}
            />
            <ReactSVG
              src={LeftDispositionSVG}
              className={`svg-wrapper rightDispositionSVG ${!leftDisposition ? 'selected' : 'notSelected'}`}
              onClick={handleLeftPosition(false)}
            />
          </div>
        )}

        {sumFooter && sumFooter.count > 0 && (
          <div className="sum">
            Count: {sumFooter.count} &nbsp; Sum: {sumFooter.sum}
          </div>
        )}
        <div className="version">{`Version ${process.env.REACT_APP_VERSION}`}</div>
      </div>
    </div>
  );
};

export default Footer;
