export enum SortMode {
  ASC = 'ASC',
  DESC = 'DESC',
}

/**
 * Sorts an array in place. This method mutates the array
 * @param rows
 * @param columnKey
 * @param isNumeric (false by default)
 * @param mode (ASC by default)
 */
export const sortRows = (
  rows: Record<string, any>[],
  columnKey: string,
  isNumeric = false,
  mode = SortMode.ASC,
): void => {
  if (mode === SortMode.ASC) {
    rows.sort((a: Record<string, any>, b: Record<string, any>) => {
      if (isNumeric) {
        return a[columnKey] - b[columnKey];
      } else if (typeof a[columnKey] === 'string' && typeof b[columnKey] === 'string') {
        return a[columnKey].localeCompare(b[columnKey]);
      } else {
        return 1;
      }
    });
  } else if (mode === SortMode.DESC) {
    rows.sort((a: Record<string, any>, b: Record<string, any>) => {
      if (isNumeric) {
        return b[columnKey] - a[columnKey];
      } else if (typeof a[columnKey] === 'string' && typeof b[columnKey] === 'string') {
        return b[columnKey].localeCompare(a[columnKey]);
      } else {
        return -1;
      }
    });
  }
};
