// Style
import './style.scss';
import Doc from '../../../assets/img/doc-undraw.svg';

/**
 * Container used to display the list of questions and answers of a workspace
 * @param param IQnAListProps
 */

const IntegrityChecks = () => {
  return (
    <div className="integrityChecksContainer">
      <div className="placeholderContainerExport">
        <img src={Doc} alt="" />
        <h3>Integrity Checks coming soon !</h3>
      </div>
    </div>
  );
};

export default IntegrityChecks;
