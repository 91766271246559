// Style
import './style.scss';

import React from 'react';
// Router
import { Route, Routes } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';

import useShortPolling from '../components/pollingComponents';
import AccountNotActivated from '../containers/AccountNotActivated';
import Maintenance from '../containers/Maintenance';
import NotFound from '../containers/NotFound';
import Timeout from '../containers/Timeout';
// Scorf containers
import Workspaces from '../containers/Workspaces';
import WorkspaceProvider from '../context/WorkspaceContext';
import AppHeader from '../elements/AppHeader';
import { RoutesEnum } from '../shared/constant/route.consts';

const Routing: React.FC = () => {
  useShortPolling();
  return (
    <div className="siteContainer">
      <CssBaseline />
      <main className="content">
        <Grid className="container">
          <Grid
            className="scrollableContainer"
            item
            xs={12}
            style={{
              height: '100%',
              maxHeight: '100%',
              flexWrap: 'wrap',
              overflow: 'hidden',
            }}>
            <Routes>
              <Route path={RoutesEnum.timeout} element={<Timeout />} />
              <Route path={RoutesEnum.notActivated} element={<AccountNotActivated />} />
              <Route path={RoutesEnum.notFound} element={<NotFound />} />
              <Route path={RoutesEnum.maintenace} element={<Maintenance />} />
              <Route
                path={RoutesEnum.dashboard}
                element={
                  <>
                    <AppHeader />
                    <Workspaces />
                  </>
                }
              />
              <Route path={RoutesEnum.workspace + '/*'} element={<WorkspaceProvider />} />
            </Routes>
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

export default Routing;
