import React from 'react';

// Style
import './style.scss';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';

// Asset
import AddFileSVG from '../../../assets/img/add-circle-green-filled.svg';
import { ReactSVG } from 'react-svg';

interface IListDataProps {
  getRootProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps;
  getInputProps: (props?: DropzoneInputProps | undefined) => DropzoneInputProps;
  isDragAccept: boolean;
  isDragReject: boolean;
}

// Scorf supported formats for uploaded files

/**
 * Component used to display all uploaded data sources and their worksheets
 *
 * @param param IListDataProps props
 */

const ListData: React.FC<IListDataProps> = ({ getRootProps, isDragAccept, isDragReject, getInputProps }) => {
  // Functions

  const getClassName = () => {
    if (isDragAccept) {
      return 'uploadZone uploadZoneAccept';
    } else if (isDragReject) {
      return 'uploadZone uploadZoneReject';
    } else {
      return 'uploadZone';
    }
  };

  return (
    <div className="listDataContainer">
      <div className="headerUpload">
        <h4>Add new Data Sources</h4>
      </div>
      <div {...getRootProps()} className={getClassName()}>
        <ReactSVG src={AddFileSVG} className="svg-wrapper addFile" />
        Click to upload or drag it here.
        <input {...getInputProps()} />
      </div>
      <div className="supportedFilesListData">
        Supported File Types: <span className="semiBold">.txt</span>, <span className="semiBold">.csv</span>,{' '}
        <span className="semiBold">.xls</span>, <span className="semiBold">.xlsx</span>
      </div>
    </div>
  );
};

export default ListData;
