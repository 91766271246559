import { ICategory, IInsideDataSet } from '../';
import { ChartType } from '../../../constant/datasource.consts';
import { CHART_DATA_FONT, formattedDisplayValue, getFiscalYears, IFiscalYearIndexes, TChartData } from '../../utils';

interface ITotalsMultipleLine {
  first: number;
  second: number;
}

export const STACKED_CHARTS_COLORS = [
  '#7eceb0',
  '#40a781',
  '#e27973',
  '#8dbff2',
  '#bad9f7',
  '#ffe5b3',
  '#235c47',
  '#bd3629',
  '#e1f4ed',
  '#fcf2f1',
];

export const STACKED_COLORS = {
  CON1089: 'bd3629',
  CON1074: 'E27973',
  CON1070: '40a781',
  CON1060: '235c47',
  CON1046: '8dbff2',
  CON1090: 'ffbb38',
  CON1072: 'FCF2F1',
};

export const formalizeStackedColumnConfigs = (params: TChartData) => {
  if (params?.isUnion && params?.fiscalYearsInfo && params.fiscalYearsInfo['similarPeriod']) {
    return formaliseMultipleStackedChart(params);
  }
  return formaliseSimpleStackedChart(params);
};

export const finalizeDatasetToPercent = (dataset: Array<IInsideDataSet>, totals: number) => {
  let percents = 0;
  for (let data of dataset) {
    if (data?.data?.[0]) {
      const { value } = data.data[0];
      const newValue = (100 * parseFloat(value)) / totals;
      percents += newValue;
      data.data[0].value = `${newValue}`;
    }
  }
  return dataset;
};

const formaliseSimpleStackedChart = (params: TChartData) => {
  let reference = '';
  let totals = 0;
  let category: Array<ICategory> = [{ label: '' }, { label: '' }];
  let dataset: Array<IInsideDataSet> = [];
  if (params?.fiscalYearsInfo) {
    reference = Object.keys(params.fiscalYearsInfo)[0];
  }

  if (params?.conceptValues?.valuesLegend) {
    const { values, valuesLegend } = params.conceptValues;
    let keys = Object.keys(valuesLegend);
    let dataToExclude = '';
    let isOpening = false;
    if (params?.reportContentSetup?.otherParams?.conceptIdAsBase?.length) {
      dataToExclude = params.reportContentSetup.otherParams.conceptIdAsBase;
    }
    if (params?.reportContentSetup?.otherParams?.displayOpening) {
      isOpening = true;
    }
    for (let k = 0; k < keys.length; k++) {
      const key = keys[k];
      const seriesname: string = valuesLegend[key];
      const color = STACKED_COLORS?.[key] ? STACKED_COLORS[key] : STACKED_CHARTS_COLORS[k];
      if (key !== dataToExclude) {
        for (const [_key, value] of Object.entries(values)) {
          const _v = value as Array<string>;
          if (_key === key) {
            if (_v?.length) {
              const counter = !isOpening && 1 < _v.length ? 1 : 0;
              for (let j = counter; j < _v.length; j++) {
                const absoluteValue = Math.abs(_v[j] as any);
                totals = totals + absoluteValue;
                const displayValue = formattedDisplayValue(_v[j]);
                dataset = [
                  ...dataset,
                  {
                    seriesname,
                    color,
                    data: [
                      {
                        value: `${Math.abs(_v[j] as any)}`,
                        displayValue: `${_v[j] != '0' ? displayValue : ''}`,
                      },
                    ],
                  },
                ];
              }
            }
          }
        }
      } else {
        for (const [_key, value] of Object.entries(values)) {
          const _v = value as Array<string>;
          if (_key === key) {
            if (_v?.length) {
              const counter = !isOpening && 1 < _v.length ? 1 : 0;
              for (let j = counter; j < _v.length; j++) {
                const displayValue = formattedDisplayValue(_v[j]);
                dataset = [
                  ...dataset,
                  {
                    seriesname,
                    color,
                    data: [
                      null,
                      {
                        value: `${totals}`,
                        displayValue: `${_v[j] != '0' ? displayValue : ''}`,
                        totals: _v[j] as any,
                      },
                    ],
                  } as any,
                ];
              }
            }
          }
        }
      }
    }
  }
  dataset = dataset.sort((a, b) => {
    const first = a?.data?.[0]?.value ? parseFloat(a.data[0].value) : -1;
    const second = b?.data?.[0]?.value ? parseFloat(b.data[0].value) : -1;
    if (first < second) {
      return 1;
    } else if (second < first) {
      return -1;
    }
    return 0;
  });
  return {
    reference,
    title: params.reportTitle,
    config: {
      type: ChartType.COLUMN_STACKED,
      dataLoadStartMessage: 'Please wait, chart is loading the data....',
      dataLoadStartMessageFontSize: '14',
      renderAt: 'chart-container',
      width: '100%',
      height: '400',
      dataFormat: 'json',
      dataSource: {
        chart: {
          subcaption: '', // Sur les décaissements: ajouter les stacks Fournisseurs - Personnel - Etats - Autres flux
          divlineAlpha: '100',
          divlineColor: '#999999',
          numberPrefix: '$',
          showxaxisline: '0',
          showyaxisline: '0',
          plotSpacePercent: '5',
          showYAxisValue: '0',
          plottooltext: '$seriesname : $displayValue',
          legendPosition: 'right',
          exportFileName: params.reportTitle,
          //Showing the Cumulative Sum of stacked data
          showValues: '1',
          minPlotHeightForValue: '20',
          valueFontColor: 'ffffff',
          ...CHART_DATA_FONT,
        },
        categories: [
          {
            category,
          },
        ],
        dataset,
      },
    },
  };
};

const formaliseMultipleStackedChart = (params: TChartData) => {
  let reference = getFiscalYears(params.fiscalYearsInfo);
  let category: Array<ICategory> = [];
  let dataset: Array<IInsideDataSet> = [];
  let nextTotals: Array<number> = [];

  if (params?.fiscalYearsInfo) {
    let dataToExclude = '';
    if (params?.reportContentSetup?.otherParams?.conceptIdAsBase?.length) {
      dataToExclude = params.reportContentSetup.otherParams.conceptIdAsBase;
    }
    const keys = Object.keys(params.fiscalYearsInfo);
    const validateKeys = keys.filter((k: any) => !isNaN(k));
    const { valuesLegend, values } = params.conceptValues;
    let legendKeys = Object.keys(valuesLegend);
    nextTotals = new Array(validateKeys.length).fill(0);
    for (let k = 0; k < validateKeys.length; k++) {
      const key: any = keys[k];
      category = [...category, { label: `${key}` }];
      const indexes: IFiscalYearIndexes = params.fiscalYearsInfo[key];
      for (let t = 0; t < legendKeys.length; t++) {
        const legendKey = legendKeys[t];
        if (legendKey !== dataToExclude) {
          let seriesname = valuesLegend[legendKey];
          const color = STACKED_COLORS?.[legendKey] ? STACKED_COLORS[legendKey] : STACKED_CHARTS_COLORS[t];
          for (const [_key, value] of Object.entries(values)) {
            const _v = value as Array<string>;
            if (_key === legendKey) {
              if (_v?.length) {
                for (let j = indexes.firstIndex; j <= indexes.lastIndex; j++) {
                  const displayValue = formattedDisplayValue(_v[j]);
                  let data = new Array(validateKeys.length).fill(null);
                  const val: any = _v[j];
                  data[k] = {
                    value: `${Math.abs(_v[j] as any)}`,
                    displayValue: `${_v[j] != '0' ? displayValue : ''}`,
                  };
                  const includeInLegend = k === 0 ? 1 : 0;
                  nextTotals[k] = nextTotals[k] + Math.abs(val);
                  dataset = [
                    ...dataset,
                    {
                      seriesname,
                      color,
                      data,
                      includeInLegend,
                    },
                  ];
                }
              }
            }
          }
        }
      }
    }
    dataset = formatMultipleLineDataset(dataset, nextTotals);
    for (let k = 0; k < validateKeys.length; k++) {
      dataset = sortDataset(dataset, k);
    }
  }
  return {
    reference,
    title: params.reportTitle,
    config: {
      type: ChartType.COLUMN_STACKED,
      dataLoadStartMessage: 'Please wait, chart is loading the data....',
      dataLoadStartMessageFontSize: '14',
      renderAt: 'chart-container',
      width: '100%',
      height: '400',
      dataFormat: 'json',
      dataSource: {
        chart: {
          subcaption: '', // Sur les décaissements: ajouter les stacks Fournisseurs - Personnel - Etats - Autres flux
          divlineAlpha: '100',
          divlineColor: '#999999',
          numberPrefix: '$',
          //showxaxisline: '0',
          showyaxisline: '0',
          plotSpacePercent: '5',
          showYAxisValue: '0',
          plottooltext: '$seriesname : $displayValue',
          legendPosition: 'right',
          exportFileName: params.reportTitle,
          //Showing the Cumulative Sum of stacked data
          showValues: '1',
          minPlotHeightForValue: '20',
          valueFontColor: 'ffffff',
          ...CHART_DATA_FONT,
        },
        categories: [
          {
            category,
          },
        ],
        dataset,
      },
    },
  };
};

const formatMultipleLineDataset = (dataset: Array<IInsideDataSet>, totals: Array<number>) => {
  return dataset.map((ds) => {
    return {
      ...ds,
      data: [
        ...ds.data.map((d, index) => {
          if (d?.value) {
            const divideValue = totals[index];
            const absoluteValue = Math.abs(d.value as any);
            const value = `${(absoluteValue * 100) / divideValue}`;
            return { ...d, value };
          }
          return d;
        }),
      ],
    };
  });
};

const sortDataset = (dataset: Array<IInsideDataSet>, k: number) => {
  return dataset.sort((a, b) => {
    const first = a?.data?.[k]?.value ? parseFloat(a.data[k].value) : -1;
    const second = b?.data?.[k]?.value ? parseFloat(b.data[k].value) : -1;
    if (first < second) {
      return 1;
    } else if (second < first) {
      return -1;
    }
    return 0;
  });
};
