// Style
import './style.scss';

import React, { useCallback, useContext, useEffect, useState } from 'react';

import { WorkspaceContext } from '../../../context/WorkspaceContext';
import Checkbox from '../../../elements/Checkbox';
import CustomModal from '../../../elements/CustomModal';
import { deleteTemplateCollections, getAllTemplateCollectionForCustomer } from '../../../shared/api/templateCollection';
import { shareTemplateCollection } from '../../../shared/api/user';
import ToastHelper from '../../../shared/helpers/toast/ToastHelper';
import { checkEmail, noAction } from '../../../utils/common';
import { COLLECTION_TYPE } from '../../../utils/enum';
import CollectionName from './CollectionName';

/**
 * Component used to display all the template collection of a customer
 */

const TemplateCol: React.FC = () => {
  // States

  const [includedTemplate, setIncludedTemplate] = useState<number[]>([]);
  const [templateInfo, setTemplateInfo] = useState<Array<NS_API.ITemplateCollection>>([]);

  const [open, setOpen] = useState({
    delete: false,
    share: false,
  });
  const [email, setEmail] = useState('');
  // Context
  const { workspaceId, customerId } = useContext(WorkspaceContext);

  useEffect(() => {
    getTemplateCollections();
  }, []);

  // Functions

  const getTemplateCollections = useCallback(() => {
    getAllTemplateCollectionForCustomer(customerId, workspaceId)
      .then((value) => {
        const result = value.data;
        result.sort((_a, b) => {
          return b.workspaceId === workspaceId ? 1 : -1;
        });
        setTemplateInfo([...result.filter((r) => r?.customerId && r.customerId !== COLLECTION_TYPE.SCORF)]);
      })
      .catch(() => {
        ToastHelper.error('Error while fetching your template collections');
      });
    setIncludedTemplate([]);
  }, [customerId, workspaceId]);

  const handleTemplateClick = (index: number) => (e) => {
    const include = includedTemplate.includes(index);
    if (!include) {
      setIncludedTemplate([index]); // not select multiple
    } else {
      const reports = includedTemplate.filter((i) => i !== index);
      setIncludedTemplate(reports);
    }
  };

  const handleModalOpen = () => {
    setOpen({ ...open, delete: true });
  };

  const handleModalClose = useCallback(() => {
    setOpen({ ...open, delete: false });
  }, []);

  const handleModalShareClose = useCallback(() => {
    setOpen({ ...open, share: false });
  }, []);

  const handleModalShareOpen = () => {
    setEmail('');
    setOpen({ ...open, share: true });
  };

  const handleConfirmShare = () => {
    (async () => {
      if (!checkEmail(email)) {
        ToastHelper.error('Please verify your email');
        return;
      }
      const data = templateInfo.filter((_v, i) => includedTemplate.indexOf(i) !== -1);
      if (data?.length) {
        const dat = data[0];
        const result = await shareTemplateCollection(email, dat.collectionName, dat.graphId, dat.countryId);
        if (result?.data) {
          const { message } = result.data;
          if (message && message === 'SUCCESS') {
            ToastHelper.success('Your Template Collection has been successfully shared');
          } else {
            ToastHelper.error('Your Template Collection has not been shared');
          }
        }
      }
      setOpen({ ...open, share: false });
    })();
  };

  const handleConfirmDelete = useCallback(() => {
    const data = templateInfo.filter((_v, i) => includedTemplate.indexOf(i) !== -1);
    deleteTemplateCollections(workspaceId, data)
      .then((res) => {
        if (res.data.status && res.data.status === 'SUCCESS') {
          setTemplateInfo([
            ...templateInfo.filter((r) => {
              if (data.findIndex((d) => d.collectionId === r.collectionId) < 0) {
                return true;
              }
              return false;
            }),
          ]);
          setIncludedTemplate([]);
          ToastHelper.success('Your Template Collection has been successfully deleted');
        } else {
          ToastHelper.error('Your Template Collection has not been deleted');
        }
      })
      .catch((e) => {
        ToastHelper.error('Template Collection - Failed to delete', e);
      });
    setOpen({ ...open, delete: false });
  }, [workspaceId, templateInfo, open]);

  const updateCollectionName = useCallback(
    (newName: string, collectionId: string) => {
      setTemplateInfo([
        ...templateInfo.map((t) => {
          if (t.collectionId === collectionId) {
            return { ...t, collectionName: newName };
          }
          return t;
        }),
      ]);
    },
    [templateInfo],
  );

  return (
    <>
      <div className="databooksContainer templateCollectionsContainer">
        {includedTemplate.length > 0 && (
          <div className="headerActionsContainer">
            <button className="btn  button--green" onClick={handleModalShareOpen}>
              Share
            </button>
            <button className="btn  button--red delete-button" onClick={handleModalOpen}>
              Delete
            </button>
          </div>
        )}
        <div className="reportsListContainer">
          <table className="reportsList templateList">
            <thead>
              <tr>
                <th></th>
                <th>Template Collection</th>
                <th>Workspace</th>
              </tr>
            </thead>
            <tbody>
              {templateInfo.length === 0 && (
                <tr>
                  <td colSpan={4} className="emptyListCell">
                    No template collection available.
                  </td>
                </tr>
              )}
              {templateInfo.map((template, index) => {
                const { collectionName, collectionId } = template;
                const key = `${collectionName} - ${index}`;
                return (
                  <tr key={key} className={`${includedTemplate.includes(index) ? 'checked' : 'notChecked'} others`}>
                    <td>
                      <Checkbox toggle={handleTemplateClick(index)} checked={includedTemplate.includes(index)} />
                    </td>
                    <td className="reportName">
                      <CollectionName
                        collectionName={collectionName}
                        collectionId={collectionId}
                        workspaceId={workspaceId as string}
                        updateCollectionName={updateCollectionName}
                      />
                    </td>
                    <td className="reportName">{template.workspaceName ?? ''}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {open.delete && (
        <CustomModal
          onClose={handleModalClose}
          onConfirm={handleConfirmDelete}
          onCancel={handleModalClose}
          title=""
          confirmLabel="OK">
          <div className="delete-main">
            <strong>Are you sure you want to delete this Template Collection?</strong>
            <p className="delete-description">
              This operation is irreversible and you will not be able to generate any more reports from this collection
              (if you shared this collection with other users, they will still have access)
            </p>
          </div>
        </CustomModal>
      )}
      {open.share && (
        <CustomModal
          onClose={handleModalShareClose}
          onConfirm={noAction}
          onCancel={handleModalShareClose}
          title="Share your Template Collection"
          cancelDisplay
          confirmDisplay>
          <p className="share-subtitle">Enter the email address of a Scorf user</p>
          <div className="share-main">
            <input
              id="input-workspacename memberItem share-input-email userMail"
              value={email}
              className="input share-input-email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="User's mail"
              autoFocus
              maxLength={99}
              required={true}
              type="email"
            />
            <button className="btn button--green" onClick={handleConfirmShare}>
              Share
            </button>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default TemplateCol;
