import '../../../base.scss';
import './style.scss';

import ContentLoader from 'react-content-loader';

const LoaderCard = (props: any) => (
  <div className="loader-wrapper w-100">
    <ContentLoader
      speed={2}
      height={89}
      viewBox="0 0 313 89"
      backgroundColor="#e8e8e8"
      foregroundColor="#f2f2f2"
      {...props}>
      <rect x="89" y="26" width="80" height="12" rx="6" fill="#F2F2F2" />
      <rect x="89" y="50" width="80" height="12" rx="6" fill="#F2F2F2" />
      <rect x="26.748" y="22" width="45.2657" height="45" rx="8" fill="#F2F2F2" />
    </ContentLoader>
  </div>
);

export default LoaderCard;
