// Style
import './style.scss';

import React, { useState, useRef } from 'react';
import ExplanationBox from '../../../../elements/ExplanationBox';

import { ReactSVG } from 'react-svg';
import MagnifyingGlasSVG from '../../../../assets/img/search.svg';
import { isItemOpen } from '../../../../shared/helpers/string/classnames.helper';
import { renderContent } from '../../../../shared/helpers/helpers';

interface ISearchPartMenu {
  openInput: boolean;
  setOpenInput: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

const explanations = [
  {
    title: 'Search',
    description: 'Find text or account number in the report.',
    posX: '24px',
  },
];

/**
 * Container used to display the data part of top menu in a report
 *
 * @param param NS_Tabs.ITab props
 * React.FC<ISearchPartMenu>
 */

const SearchPartMenu: React.FC<ISearchPartMenu> = ({ openInput, setOpenInput, disabled = false }) => {
  // Ref
  const hoverTimer: { current: NodeJS.Timeout | null } = useRef(null);

  const [explanationSelected, setExplanationSelected] = useState<number>(-1);

  // Function

  const handleInputHovered = (index: number) => {
    hoverTimer.current = setTimeout(() => {
      setExplanationSelected(index);
    }, 1000);
  };

  const handleInputNotHovered = () => {
    if (hoverTimer.current) {
      clearTimeout(hoverTimer.current);
      setExplanationSelected(-1);
    }
  };
  return (
    <div className={`searchContainer`}>
      {explanationSelected > -1 && (
        <ExplanationBox
          title={explanations[explanationSelected].title}
          description={explanations[explanationSelected].description}
          posX={explanations[explanationSelected].posX}
        />
      )}
      <div className="subItem">
        <div
          className={`${isItemOpen(openInput)} ${renderContent(disabled, 'div--disabled', '')}`}
          id="searchBtn"
          onClick={() => {
            if (!disabled) {
              setOpenInput(!openInput);
              handleInputNotHovered();
            }
          }}
          onMouseEnter={(_e) => handleInputHovered(0)}
          onMouseLeave={() => handleInputNotHovered()}>
          <ReactSVG src={MagnifyingGlasSVG} className="svg-wrapper" />
        </div>
      </div>
    </div>
  );
};

export default SearchPartMenu;
