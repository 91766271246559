import './style.scss';

import { FC, useMemo } from 'react';

import { ChartDisplayType, ChartFamily } from '../../../../../shared/constant/datasource.consts';
import ChartWrapper from '../ChartWrapper';

interface IChartEmpty {
  templateKey: string;
}

const ChartEmpty: FC<IChartEmpty> = ({ templateKey }) => {
  const data = [
    { type: ChartFamily.PL, displayType: ChartDisplayType.LINE_MEDIAN },
    { type: ChartFamily.PL, displayType: ChartDisplayType.LINE_MEDIAN },
    { type: ChartFamily.PL, displayType: ChartDisplayType.PIE },
    { type: ChartFamily.PL, displayType: ChartDisplayType.PIE },

    { type: ChartFamily.BS, displayType: ChartDisplayType.LINE_MEDIAN },
    { type: ChartFamily.BS, displayType: ChartDisplayType.LINE_MEDIAN },
    { type: ChartFamily.BS, displayType: ChartDisplayType.LINE_MEDIAN },

    { type: ChartFamily.C, displayType: ChartDisplayType.LINE_MEDIAN },
    { type: ChartFamily.C, displayType: ChartDisplayType.LINE_MEDIAN },
    { type: ChartFamily.C, displayType: ChartDisplayType.LINE_MEDIAN },
  ];

  const chartData = useMemo(() => {
    return data.filter((d) => d.type === templateKey);
  }, [templateKey]);

  return (
    <>
      {chartData.map((c, index) => {
        const key = `${c.type} - ${index}`;
        return (
          <ChartWrapper
            key={key}
            data={{ title: '', config: null }}
            reference=""
            isLoading={true}
            loadingDisplayType={c.displayType}
          />
        );
      })}
    </>
  );
};

export default ChartEmpty;
