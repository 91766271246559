import '../../base.scss';
import './style.scss';

import { FC, useEffect, useState } from 'react';

import { formalizeKpiCharts, IChartKpi } from '../../../../shared/charts/kpi';
import LoaderCard from '../BlockItem/Loader/LoaderCard';
import CashCard from './CashCard';

interface ICashInformationProps {
  isLoading: boolean;
  kpiData: Array<NS_API.IKpiReportCharts | undefined>;
  templateKey: string;
}

const CashInformation: FC<ICashInformationProps> = ({ kpiData, isLoading, templateKey }) => {
  const dataLoading = !kpiData?.length && isLoading;

  const [cards, setCards] = useState<Array<IChartKpi>>([]);
  const [newKpiLoading, setNewKpiLoading] = useState(false);

  useEffect(() => {
    setKpiData();
  }, [kpiData]);

  const setKpiData = () => {
    if (kpiData?.length) {
      setNewKpiLoading(true);
      const dataFormalized = formalizeKpiCharts(kpiData, templateKey);
      setCards([...dataFormalized]);
      setNewKpiLoading(false);
    }
  };

  return (
    <div className="card-wrapper flex align-items-start justify-content-between">
      {dataLoading || newKpiLoading ? (
        <>
          <div className="bloc-bordered w-100">
            <LoaderCard />
          </div>
          <div className="bloc-bordered w-100">
            <LoaderCard />
          </div>
          <div className="bloc-bordered w-100">
            <LoaderCard />
          </div>
          <div className="bloc-bordered w-100">
            <LoaderCard />
          </div>
        </>
      ) : (
        <>
          {cards.map(({ value, title, unity, color, fiscalYear }, index) => {
            const key = `${value} - ${index}`;
            return (
              <div key={key} className="bloc-bordered w-100">
                <CashCard
                  value={value}
                  title={title}
                  description={unity}
                  color={color ?? '#46B78E'}
                  fiscalYear={fiscalYear}
                />
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default CashInformation;
