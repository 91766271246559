import { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { v4 as uuidv4 } from 'uuid';

import { serviceImport } from '../..';
import { executeRequest } from './common';

function padStart(currentString: string, targetLength: number, padString = ' '): string {
  // @ts-expect-error: TS doesn't know this code needs to run downlevel sometimes
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (String.prototype.padStart) {
    return currentString.padStart(targetLength, padString);
  }
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  padString = padString || ' ';
  if (currentString.length > targetLength) {
    return currentString;
  } else {
    targetLength = targetLength - currentString.length;
    if (targetLength > padString.length) {
      padString += padString.repeat(targetLength / padString.length);
    }
    return `${padString.slice(0, targetLength)}${currentString}`;
  }
}

export function base64UrlEncodedEncode(content: string): string {
  return typeof process === 'object'
    ? Buffer.from(encodeURIComponent(content)).toString('base64')
    : btoa(encodeURIComponent(content));
}

export function base64encode(content: string): string {
  return typeof process === 'object' ? Buffer.from(content).toString('base64') : btoa(content);
}

export function generateBlockID(blockIDPrefix: string | unknown[], blockIndex: { toString: () => string }): string {
  // To generate a 64 bytes base64 string, source string should be 48
  const maxSourceStringLength = 48;
  // A blob can have a maximum of 100,000 uncommitted blocks at any given time
  const maxBlockIndexLength = 6;
  const maxAllowedBlockIDPrefixLength = maxSourceStringLength - maxBlockIndexLength;
  if (blockIDPrefix.length > maxAllowedBlockIDPrefixLength) {
    blockIDPrefix = blockIDPrefix.slice(0, maxAllowedBlockIDPrefixLength);
  }
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  const res = blockIDPrefix + padStart(blockIndex.toString(), maxSourceStringLength - blockIDPrefix.length, '0');
  return base64encode(res);
}

/** MSFT AZURE - Represents a single block in a block blob.  It describes the block's ID and size. */
export declare interface Block {
  /** The base64 encoded block ID. */
  name: string;
  /** The block size in bytes. */
  size: number;
}

export class BlockMetadata {
  id: number;
  blockId: string;
  index: number;
  size: number;
  private readonly remainingBytesInFile: number;

  constructor(id: number, size: number, bytesPerChunk: number) {
    this.id = id;
    this.blockId = generateBlockID(uuidv4(), id);
    this.index = id * bytesPerChunk;
    this.remainingBytesInFile = size - this.index;
    this.size = Math.min(this.remainingBytesInFile, bytesPerChunk);
  }
}

export class CreateDatasourceDto {
  workspaceId!: string;
  fileId!: string;
  datasourceName!: string;
  sourceFile!: string;
  status!: '0';
  error!: -1;
  deleted!: 0;
  structureUrl!: '';
  countryCode!: 'FR'; // TODO: replace country code then the time will come. hardcoded
  mimeType!: string;
  encodingType!: string;
  size!: number;
  lineBreaker!: string;
  lines!: number;
  separator!: string;
}

export class UploadChunkBody {
  blockMetadata!: BlockMetadata;
  chunkData!: string;
  checksumMD5!: string;
}
export class CommitDatasourceBody {
  blockIds!: string[];
  datasource!: CreateDatasourceDto;
}

export enum UploadChunkActions {
  CHECK_AVAILABILITY = 'check-availability',
  COMMIT_CHUNK_LIST = 'commit-chunk-list',
}

export class ImportAPI {
  public static async uploadChunk(workspaceId: string, filename: string, data: UploadChunkBody);
  public static async uploadChunk(workspaceId: string, filename: string, action: UploadChunkActions.CHECK_AVAILABILITY);
  public static async uploadChunk(
    workspaceId: string,
    filename: string,
    data: CommitDatasourceBody,
    action: UploadChunkActions.COMMIT_CHUNK_LIST,
  );
  public static async uploadChunk(
    arg0: string,
    arg1: string,
    arg2?: UploadChunkBody | CommitDatasourceBody | UploadChunkActions.CHECK_AVAILABILITY,
    arg3?: UploadChunkActions,
  ): Promise<boolean> {
    const workspaceId = arg0;
    const filename = base64UrlEncodedEncode(arg1);

    let axiosConfig: AxiosRequestConfig = {};
    let url;
    if (
      (arg2 as UploadChunkBody).blockMetadata !== undefined &&
      (arg2 as UploadChunkBody).chunkData !== undefined &&
      (arg2 as UploadChunkBody).checksumMD5 !== undefined &&
      arg3 === undefined
    ) {
      axiosConfig = { data: arg2 };
      url = `/import/${workspaceId}/${filename}`;
    }

    if (arg2 === UploadChunkActions.CHECK_AVAILABILITY) {
      url = `/import/${workspaceId}/${filename}?${qs.stringify({ action: arg2 })}`;
    }

    if ((arg2 as CommitDatasourceBody).blockIds !== undefined && arg3 === UploadChunkActions.COMMIT_CHUNK_LIST) {
      axiosConfig = { data: arg2 };
      url = `/import/${workspaceId}/${filename}?${qs.stringify({ action: arg3 })}`;
    }
    const request = serviceImport.put(url, axiosConfig);

    return executeRequest<boolean>(request);
  }

  public static async getUncommittedChunksList(workspaceId: string, filename: string): Promise<Block[]> {
    const filenameEncoded = base64UrlEncodedEncode(filename);
    const url = `/import/${workspaceId}/${filenameEncoded}`;
    const request = serviceImport.get(url);

    return executeRequest<Block[]>(request);
  }
}

/**
 * Verifies every line has the same number of columns and format numbers
 * @param fileStr
 *
 * @returns if the check is passed, and the reasons why it has not passed if needed.
 */
export function checkColumnsConsistencyAndFormatNumbers(
  fileStr: string,
  separatorToUse: string,
): {
  success: boolean;
  messages: string[];
  fileStr: string;
} {
  const lines = fileStr.split('\n');

  const columns = lines[0].split(separatorToUse).length;

  let passed = true;

  const messages: string[] = [];
  const numberRegex = new RegExp(/^[+-]?(?:0|[1-9]\d*)(?:\.\d+)?(?:[eE][+-]?\d+)?$/);

  for (let i = 0; i < lines.length; i++) {
    const currentColumns = lines[i].split(separatorToUse);

    for (let j = 0; j < currentColumns.length; j++) {
      const cell = currentColumns[j];

      const match = numberRegex.test(cell);
      if (match) {
        currentColumns[j] = Number(cell).toString();
      }
    }
    const currentColumnsLength = currentColumns.length;

    if (
      (currentColumnsLength !== columns && i !== lines.length - 1) ||
      (currentColumnsLength !== 1 && i === lines.length - 1)
    ) {
      messages.push(`The line ${i + 1} has ${currentColumnsLength} columns, expected ${columns} columns.`);
      passed = false;
    }
    lines[i] = currentColumns.join(separatorToUse);
  }
  return {
    success: passed,
    messages: messages,
    fileStr: lines.join('\n'),
  };
}
