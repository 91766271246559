import './editCellDropZone.scss';

import React, { useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes } from '../../../../../shared/constant/drapAndDrop.consts';

interface IEditCellDropZone {
  id: string;
  setCellSelected: (val: Array<string>) => void;
  level: number;
  setIsOver: (val: boolean) => void;
  backgroundColor: string;
  children?: React.ReactNode;
}

/**
 *
 *
 * @param param IHeaderAssignProps props
 */

const EditCellDropZone: React.FC<IEditCellDropZone> = ({
  id,
  setCellSelected,
  level,
  children,
  setIsOver,
  backgroundColor,
}) => {
  // States

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.NODE,
    drop: (item: any, _monitor) => {
      if (item && item.data.content.rowContentId !== id && !nodeIsChild(id, item)) {
        setCellSelected([id]);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      item: monitor.getItem() as any,
      isDrop: monitor.didDrop(),
    }),
  });
  // Effects

  useEffect(() => {
    setIsOver(isOver);
  }, [isOver]);

  const nodeIsChild = (idArg, node) => {
    let queue = node.children;
    queue.forEach((child) => {
      if (child.data.content.rowContentId !== idArg) {
        queue = queue.concat(child.children);
      }
    });
    return false;
  };

  return (
    <div
      ref={drop}
      className="editCell_wrapper--draggable"
      style={{ width: `calc(100% - 60px - ${25 * level}px)`, backgroundColor: backgroundColor }}>
      {children}
    </div>
  );
};

export default EditCellDropZone;
