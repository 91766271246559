const gtagScript = (REACT_APP_APP_URL) => {
  const GA_MEASUREMENT_ID = REACT_APP_APP_URL === 'https://app.scorf.io' ? 'G-XM5QXTE7P3' : 'G-VFL22MWZHS';
  window.GA_MEASUREMENT_ID = GA_MEASUREMENT_ID;
  const scriptSource = document.createElement('script');
  scriptSource.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
  scriptSource.async = true;

  document.head.appendChild(scriptSource);
  const script = document.createElement('script');

  script.innerText = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    window.gtag = gtag;
    gtag('js', new Date());
    //gtag('config', '${GA_MEASUREMENT_ID}', { send_page_view: false });
    `;

  document.head.appendChild(script);

  return () => {
    document.head.removeChild(scriptSource);
    document.head.removeChild(script);
  };
};

export default gtagScript;
