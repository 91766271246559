import {
  AuthenticationResult,
  BrowserCacheLocation,
  Configuration,
  EventMessage,
  EventType,
  IPublicClientApplication,
  ProtocolMode,
  PublicClientApplication,
  RedirectRequest,
} from '@azure/msal-browser';
import { AzureCloudInstance, LogLevel } from '@azure/msal-common';

// Config object to be passed to Msal on creation
const PolicySignUpSignIn = process.env.REACT_APP_SIGN_IN_POLICY as string; //'B2C_1A_signup_signin';
const PolicyResetPassword = process.env.REACT_APP_RESET_POLICY as string; //'B2C_1A_PasswordReset';

const Tenant = process.env.REACT_APP_AD_TENANT as string; //'devauthscf1.onmicrosoft.com';
const AzureADB2CHostname = process.env.REACT_APP_INSTANCE as string; //'devauthscf1.b2clogin.com';
const AuthorityBase = `${AzureADB2CHostname}/${Tenant}/`;
const Authority = `${AuthorityBase}${PolicySignUpSignIn}`;
const AuthorityPasswordReset = `${AuthorityBase}${PolicyResetPassword}`;
const tokenRenewalOffsetSeconds = 120;

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_APPLICATION_ID as string,
    authority: Authority,
    knownAuthorities: [Authority, AuthorityPasswordReset],
    redirectUri: process.env.REACT_APP_REDIRECT_URI as string,
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    protocolMode: ProtocolMode.AAD,
    azureCloudOptions: {
      azureCloudInstance: AzureCloudInstance.None,
      tenant: Tenant,
    },
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
  },
  system: {
    tokenRenewalOffsetSeconds,
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      logLevel: Number(process.env.REACT_APP_MSAL_LOG_LEVEL),
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: [
    'openid',
    ...(process.env.REACT_APP_SCOPES ?? '').split(','),
    process.env.REACT_APP_APPLICATION_ID as string,
  ],
};

export const passwordResetRequest: RedirectRequest = {
  scopes: [
    'openid',
    ...(process.env.REACT_APP_SCOPES ?? '').split(','),
    process.env.REACT_APP_APPLICATION_ID as string,
  ],
  authority: AuthorityPasswordReset,
};

export const acquireTokenRequest = {
  scopes: [
    'openid',
    ...(process.env.REACT_APP_SCOPES ?? '').split(','),
    process.env.REACT_APP_APPLICATION_ID as string,
  ],
};

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('event.eventType', event.eventType);
  }

  // const customerId = accounts[0].idTokenClaims[provess.env.REACT_APP_AD_CUSTOMERID_CLAIM];
  // When trying to login, call API to check the user is already connected

  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;

    msalInstance.setActiveAccount(account);

    // new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve(true);
    //   }, 3000);
    // }).then((res) => {
    //   const isConnected = res as boolean;
    //   if (isConnected) {
    //     // User aleady connected
    //     console.error('Current account already connected');
    //   } else {
    //     msalInstance.setActiveAccount(account);
    //   }
    // });
  }

  if (event.eventType === EventType.LOGIN_FAILURE) {
    console.error('Login error, contact your administrator');
  }
});

export const doLogout = async (instance: IPublicClientApplication): Promise<void> => {
  const account = instance.getAllAccounts()[0];
  instance.setActiveAccount(null);

  window.sessionStorage.clear();

  await instance.logoutRedirect({
    account,
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  });
};
