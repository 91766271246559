import './style.scss';

import React, { useCallback } from 'react';

import CustomModal from '../../elements/CustomModal';

interface IImportError {
  infos: any;
  setIsOpenModal: (val: boolean) => void;
}

/**
 * Component used to display the result of each edit command
 */

const ImportError: React.FC<IImportError> = ({ infos, setIsOpenModal }) => {
  const handleClose = useCallback(() => {
    setIsOpenModal(false);
  }, []);

  return (
    <CustomModal
      onClose={handleClose}
      onConfirm={handleClose}
      onCancel={handleClose}
      title={`Something went wrong... Please try again`}
      confirmLabel="OK"
      className="importErrorModal">
      <p>Looks like something went wrong while importing your data source.</p>
      <p>Here are the details of what exactly prevented your data source’s import :</p>
      <div className="infoListContainer">
        {infos.map((reason, key: number) => {
          const index = `${key} - ${reason}`;
          return (
            <div className="reasonContainer" key={index}>
              <p>{reason}</p>
            </div>
          );
        })}
      </div>
    </CustomModal>
  );
};

export default ImportError;
