import { useMemo, useState } from 'react';
import { renderContent } from '../../../shared/helpers/helpers';
import CollapsibleItem, { BadgeTypes } from '../CollapsibleItem';
import RedCrossSVG from '../../../assets/img/cross-red.svg';
import GreenCheckSVG from '../../../assets/img/check-green.svg';
import './style.scss';
import { ReactSVG } from 'react-svg';

interface IFiscalYear {
  eachEntryHasOneTransactionId: boolean;
  eachTransactionIdHasAtLeastTwoEntries: boolean;
  areAuxAccountIdentified: boolean;
}

const FiscalYear: React.FC<IFiscalYear> = ({
  eachEntryHasOneTransactionId,
  eachTransactionIdHasAtLeastTwoEntries,
  areAuxAccountIdentified,
}) => {
  const [isOpenTransactionExplanation, setOpenTransactionExplanation] = useState<boolean>(false);
  const [isOpenAuxExplanation, setOpenAuxExplanation] = useState<boolean>(false);

  const title = useMemo(() => {
    return renderContent(
      eachEntryHasOneTransactionId && eachTransactionIdHasAtLeastTwoEntries && areAuxAccountIdentified,
      'All the mining features can be used with this Data Source',
      'Not all the mining features can be used with this Data Source',
    );
  }, [eachEntryHasOneTransactionId, eachTransactionIdHasAtLeastTwoEntries, areAuxAccountIdentified]);

  const badgeType = useMemo(() => {
    return renderContent(
      eachEntryHasOneTransactionId && eachTransactionIdHasAtLeastTwoEntries && areAuxAccountIdentified,
      BadgeTypes.VALIDATED,
      BadgeTypes.WARNING,
    );
  }, [eachEntryHasOneTransactionId, eachTransactionIdHasAtLeastTwoEntries, areAuxAccountIdentified]);

  const toggleOpenExplanation = (type: 'auxAccount' | 'transaction') => () => {
    if (type === 'auxAccount' && !areAuxAccountIdentified) {
      setOpenAuxExplanation(!isOpenAuxExplanation);
    } else if (type === 'transaction' && !(eachEntryHasOneTransactionId && eachTransactionIdHasAtLeastTwoEntries)) {
      setOpenTransactionExplanation(!isOpenTransactionExplanation);
    }
  };

  return (
    <CollapsibleItem title={title} badgeType={badgeType}>
      <div id="transactionIdsStatus">
        <ReactSVG
          title="see details..."
          onClick={toggleOpenExplanation('transaction')}
          src={renderContent(
            eachEntryHasOneTransactionId && eachTransactionIdHasAtLeastTwoEntries,
            GreenCheckSVG,
            RedCrossSVG,
          )}
          className="svg-wrapper miningStatusSVG"
        />
        <b>Transaction ids are properly identified.</b>
      </div>
      {isOpenTransactionExplanation && (
        <div className="explanation">
          {renderContent(
            eachEntryHasOneTransactionId,
            <span>Each Entry has one Transaction ID.</span>,
            <span>
              Entries have <strong>NOT</strong> one Transaction ID.
            </span>,
          )}
          <br />
          {renderContent(
            eachTransactionIdHasAtLeastTwoEntries,
            <span>Each Transaction ID has at least two Entries.</span>,
            <span>
              Transactions have <strong>NOT</strong> at least two Entries.
            </span>,
          )}
        </div>
      )}
      <div id="auxiliaryAccountsStatus">
        <ReactSVG
          title="see details..."
          onClick={toggleOpenExplanation('auxAccount')}
          src={renderContent(areAuxAccountIdentified, GreenCheckSVG, RedCrossSVG)}
          className="svg-wrapper miningStatusSVG"
        />
        <b>Auxiliary accounts are properly identified.</b>
      </div>
      {isOpenAuxExplanation && (
        <div className="explanation">
          <span>TransactionId, CompAuxNum and CompAuxLib must be properly tagged to use this feature.</span>
        </div>
      )}
    </CollapsibleItem>
  );
};

export default FiscalYear;
