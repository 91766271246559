import { serviceReportAssembly } from '../..';

export const postReportAssembly = async (workspaceId: string, worksheetId: string, data: NS_API.I_SD_StandardStmt) => {
  return serviceReportAssembly.request<NS_API.IReportAssemblyResponse>({
    method: 'POST',
    url: `/report-assembly-store/${workspaceId}/${worksheetId}`,
    headers: {
      'Content-Type': `application/json`,
    },
    data,
  });
};

export const getReportAssembly = async (workspaceId: string, worksheetId: string) => {
  return serviceReportAssembly.request<NS_API.IGetReportAssemblyResponse>({
    method: 'GET',
    url: `/report-assembly-store/${workspaceId}/${worksheetId}`,
    headers: {
      'Content-Type': `application/json`,
    },
  });
};
