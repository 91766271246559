import { useState } from 'react';
import { renderContent } from '../../../shared/helpers/helpers';
import rightArrow from '../../../assets/img/chevron-right.svg';
import { ReactSVG } from 'react-svg';
import CheckSVG from '../../../assets/img/check-circle-green-filled.svg';
import CrossSVG from '../../../assets/img/cross-white.svg';
import ExclamationSVG from '../../../assets/img/info-white.svg';
import './style.scss';

export enum BadgeTypes {
  VALIDATED = 'validated',
  ERROR = 'error',
  WARNING = 'warning',
}

interface ICollapsibleItem {
  title: string;
  badgeType: BadgeTypes;
  children?: React.ReactNode;
}

const CollapsibleItem: React.FC<ICollapsibleItem> = ({ title, badgeType, children }) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const renderBadge = () => {
    let badgeName = '';
    let badge;
    switch (badgeType) {
      case BadgeTypes.ERROR:
        badgeName = 'mainContent_rightPart_badge--red';
        badge = <ReactSVG src={CrossSVG} className="svg-wrapper crossSVG" />;
        break;
      case BadgeTypes.VALIDATED:
        badgeName = 'mainContent_rightPart_badge--green';
        badge = <ReactSVG src={CheckSVG} className="svg-wrapper checkSVG" />;
        break;
      case BadgeTypes.WARNING:
      default:
        badgeName = 'mainContent_rightPart_badge--yellow';
        badge = <ReactSVG src={ExclamationSVG} className="svg-wrapper exclamationSVG" />;
    }
    return <div className={`mainContent_rightPart_badge ${badgeName}`}>{badge}</div>;
  };

  return (
    <div
      className={`collapsibleContainer ${renderContent(
        open,
        'collapsibleContainer--opened',
        'collapsibleContainer--closed',
      )}`}>
      <div className="mainContent" onClick={toggleOpen}>
        <div className="mainContent_leftPart">
          <div className="mainContent_leftPart_icon">
            <img src={rightArrow} alt="arrow" />
          </div>
          <div className="mainContent_leftPart_label">{title}</div>
        </div>
        <div className={`mainContent_rightPart`}>{renderBadge()}</div>
      </div>
      <div className={`subContent ${renderContent(open, 'subContent--visible', 'subContent--hidden')}`}>{children}</div>
    </div>
  );
};

export default CollapsibleItem;
