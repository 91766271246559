// Style
import './style.scss';

import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { ReportDisplayMode } from '../../../containers/Reports';
// Context
import { ReportContext } from '../../../context/ReportContext';
import { WorkspaceContext } from '../../../context/WorkspaceContext';
import { getAllTemplateCollectionsForWorkspace } from '../../../shared/api/templateCollection';
import {
  RequiredColumnsCounterparts,
  TransactionColumnsCounterparts,
} from '../../../shared/constant/accountingfile.consts';
import { addChildHelper, addParentHelper } from '../../../shared/helpers/mapping/commandsAdds.helper';
import {
  deepDeleteElement,
  simpleDeleteAccount,
  simpleDeleteConcept,
} from '../../../shared/helpers/mapping/commandsDelete.helper';
import { onLockedHelper, onRenameHelper } from '../../../shared/helpers/mapping/commandsMetaData.helper';
import { endMoveElementHelper, startMoveElementHelper } from '../../../shared/helpers/mapping/commandsMove.helper';
import {
  checkIfAllValuesArePresent,
  checkIfOneValueIsPresent,
  smoothScroll,
} from '../../../shared/helpers/report/reports.helper';
import StringHelper from '../../../shared/helpers/string/string.helper';
import ToastHelper from '../../../shared/helpers/toast/ToastHelper';
import { dataDestructuring } from '../../../shared/helpers/tree/front.tree.util';
// API
import TreeHelper from '../../../shared/helpers/tree/TreeHelper';
import EditHeader from './EditHeader';
import EditTree from './EditTree';

const INCREASE_COL_WIDTH = 100;
const tableHeader: Array<NS_LIST.IItem<number>> = [
  { id: 'row', value: 100 },
  { id: 'rowContentName', value: 300 },
  { id: 'value', value: 120 },
  { id: 'formula', value: 150 },
  { id: 'childOrder', value: 100 },
  { id: 'rowContentId', value: 100 },
  { id: 'rowContentType', value: 100 },
  { id: 'accountIdRoot', value: 100 },
  { id: 'measure', value: 100 },
  { id: 'indexOfDatasourceLines', value: 100 },
  { id: 'rowContentChildren', value: 100 },
  { id: 'originCoefficient', value: 100 },
  { id: 'templateCoefficient', value: 100 },
].reverse();
interface IEditStatementProps {
  worksheetId: string;
  selectedDataSourceGraph: string;
  templateKey: string;
  disabled: boolean;
  columnsValueNames: Array<string>;
  columnsDimensions: Array<string>;
  mappingTree: NS_API.IGraph;
  mappingTreeWithRoot: NS_API.IGraph;
  graphId: string;
  datasourceId: string;
  setCellSelected: (val: string[]) => void;
  cellSelected: string[];
  header: Array<NS_Table.IColumnHeader>;
  currencyParams: NS_Table.ICurrency;
  isEditing: boolean;
  fiscalYears: any;
  dateHeaderType: string;
  dateRender: Array<string>;
  selectedDateRender: number;
  setNameOfNodeSelected: (val: NS_REPORT.INameSelectedForCoverageProps) => void;
  setTypeOfVisualisation: (val: string) => void;
  clickedId: string;
  handleMappingOpen: (typeOfVisualisation: string) => (e) => void;
  isContextMenuOpen: string;
  setIsContextMenuOpen: (val: string) => void;
  multipleDimensions: boolean;
  nameColWidth: number | undefined;
  setNameColWidth: (val: number) => void;
  tableBodyHeight: number | undefined;
  zoomValue: string;
  nodeOpenedAccount: NS_TREE.IMining;
  setNodeOpenedAccount: React.Dispatch<React.SetStateAction<NS_TREE.IMining>>;
  setSelectedCells: (val: Array<NS_TREE.ICellSelected>) => void;
  selectedCells: Array<NS_TREE.ICellSelected>;
  showResult: number;
  searchResults: string[];
  scrollPartRef: React.RefObject<HTMLDivElement>;
  selectedTemplate: string;
  displayOpeningColumn: boolean;
  selectedCellsRowNodes: NS_TREE.ITreeRootWithoutChild[];
  selectedCellsInGroup: NS_TREE.ICellSelected[];
  selectedCellsColumns: number[];
  selectedCellsOutGroup: NS_TREE.ICellSelected[];
  setSelectedCellsRowNodes: (val: NS_TREE.ITreeRootWithoutChild[]) => void;
  setSelectedCellsColumns: (val: number[]) => void;
  setSelectedCellsInGroup: (val: NS_TREE.ICellSelected[]) => void;
  setSelectedCellsOutGroup: (val: NS_TREE.ICellSelected[]) => void;
  emptySelectedCells: () => void;
  setAccountSelectedInReport: (val: string) => void;
  setCellFound: (val: string) => void;
  cellFound: string;
  tableContainerRef: React.RefObject<HTMLDivElement>;
  reportDisplayMode: string | undefined;
  onClickHeader: (headerItem: NS_Table.IColumnHeader) => void;
  selectedHeaders: Array<NS_Table.IColumnHeader>;
  headerSelectionMode: boolean;
  nodesContentIndices: NS_API.INodesContentIndices | undefined;
  reportDataSourceGraph: string;
  onReportSettingClick: (e) => void;
  monthGroupedBy?: string;
  handlePropertiesOpen: (treeNode: NS_TREE.ITreeRoot<string>) => (e) => void;
  handleDataSourceGraph: (id: string) => void;
}
/**
 * Container used to display a mapping page *
 * @param param IMapping props
 */
const EditStatement: React.FC<IEditStatementProps> = ({
  worksheetId,
  selectedDataSourceGraph,
  templateKey,
  disabled = false,
  datasourceId,
  columnsValueNames,
  columnsDimensions,
  mappingTree,
  graphId,
  mappingTreeWithRoot,
  cellSelected,
  setCellSelected,
  setNameOfNodeSelected,
  header,
  currencyParams,
  isEditing,
  fiscalYears,
  dateHeaderType,
  dateRender,
  selectedDateRender,
  setTypeOfVisualisation,
  clickedId,
  handleMappingOpen,
  isContextMenuOpen,
  setIsContextMenuOpen,
  multipleDimensions,
  nameColWidth,
  setNameColWidth,
  tableBodyHeight,
  zoomValue,
  nodeOpenedAccount,
  setNodeOpenedAccount,
  setSelectedCells,
  selectedCells,
  showResult,
  searchResults,
  scrollPartRef,
  selectedTemplate,
  displayOpeningColumn,
  selectedCellsOutGroup,
  setSelectedCellsOutGroup,
  selectedCellsInGroup,
  setSelectedCellsInGroup,
  selectedCellsRowNodes,
  setSelectedCellsRowNodes,
  selectedCellsColumns,
  setSelectedCellsColumns,
  emptySelectedCells,
  setAccountSelectedInReport,
  setCellFound,
  cellFound,
  tableContainerRef,
  reportDisplayMode,
  onClickHeader,
  headerSelectionMode,
  selectedHeaders,
  nodesContentIndices,
  reportDataSourceGraph,
  onReportSettingClick,
  monthGroupedBy,
  handlePropertiesOpen,
  handleDataSourceGraph,
}) => {
  // Context
  const { dataSources, workspaceId, colorOptions, addLoadingTab, removeLoadingTab } = useContext(WorkspaceContext);
  const { setNewOperation, currentCommands, currentMetadata, reportId } = useContext(ReportContext);

  const tableRef = useRef<HTMLTableSectionElement>(null);
  const tree = useRef<any>(null);
  const countLineRef = useRef<HTMLDivElement>(null);
  const cellSelectedRef = useRef<string[]>(cellSelected);

  // global
  const [openContextMenu, setOpenContextMenu] = useState<boolean>(false);
  //Tree
  const [numberOfLines, setNumberOfLines] = useState<number>(0);
  const [hoveredNodeId, setHoveredNodeId] = useState<string>('');
  const [parentOpen, setParentOpen] = useState<Array<string>>([]);
  const [isCompatibleToCounterParts, setIsCompatibleToCounterParts] = useState<boolean>(false);
  const [scrollToNode, setScrollToNode] = useState<NS_TREE.ITreeRoot<string | undefined>>();
  const [indent, setIndent] = useState<boolean>(true);

  // cells
  const [cellToBeMoved, setCellToBeMoved] = useState<string>('');
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [newMousePos, setNewMousePos] = useState<number>(-1);

  const [startResize, setStartResize] = useState<number>(-1);
  const [resizeCol, setResizeCol] = useState<number>(-1);
  const [previousSelectedId, setPreviousSelectedId] = useState<string>('');
  const [hideLineNum, setHideLineNum] = useState<number>(-1);

  const [groupDirection, setGroupDirection] = useState<string>('');
  const [reportGraphExist, setReportGraphExist] = useState<boolean>(false);

  useEffect(() => {
    getAllTemplateCollectionsForWorkspace(workspaceId)
      .then((res) => {
        if (res) {
          const graphIndex = res.data.findIndex((graph) => graph.graphId === reportDataSourceGraph);
          setReportGraphExist(graphIndex !== -1);
        } else {
          setReportGraphExist(false);
        }
      })
      .catch((err) => {});
  }, [reportDataSourceGraph]);

  // Find a nodes path from root to the node
  const findPathToNode = useCallback(
    (id: string, path: NS_TREE.ITreeRoot<string | undefined>[], node: NS_TREE.ITreeRoot<string | undefined>) => {
      path.push(node);

      if (node.data.id === id) {
        return path;
      }
      if (node.children) {
        for (const child of node.children) {
          if (findPathToNode(id, path, child)) {
            return path;
          } else {
            path.pop();
          }
        }
      }
      return false;
    },
    [],
  );

  // Define the number of line by watching which child is visible etc
  useLayoutEffect(() => {
    calculateNumberOfLines();
  }, [mappingTree, zoomValue, tableBodyHeight, multipleDimensions, cellSelected]);

  useEffect(() => {
    cellSelectedRef.current = cellSelected;
  }, [cellSelected]);

  useEffect(() => {
    const newSelectedCells: NS_TREE.ICellSelected[] = [];
    for (const rowNode of selectedCellsRowNodes) {
      for (const col of selectedCellsColumns) {
        newSelectedCells.push({
          nodeId: rowNode.id,
          nodeType: rowNode.data.content.rowContentType,
          value: rowNode.data.content.value[col - 1],
          colIndex: col,
          lastAccLineRow: rowNode.lastAccLineRow,
          firstAccLineRow: rowNode.firstAccLineRow,
          accLineRowIndex: rowNode.accLineRowIndex,
        });
      }
    }
    setSelectedCellsInGroup(newSelectedCells);
  }, [selectedCellsColumns, selectedCellsRowNodes]);

  useEffect(() => {
    const allSelectedCells = selectedCellsOutGroup.concat(selectedCellsInGroup);
    setSelectedCells(allSelectedCells);
  }, [selectedCellsInGroup, selectedCellsOutGroup]);

  useEffect(() => {
    if (dataSources.filter((ds) => ds.fileId === datasourceId)[0]) {
      const interpretedHeaders = dataSources.filter((ds) => ds.fileId === datasourceId)[0].scorfHeader?.split(',');
      const tempCounterPartsCompatible =
        checkIfAllValuesArePresent(RequiredColumnsCounterparts, interpretedHeaders) &&
        checkIfOneValueIsPresent(TransactionColumnsCounterparts, interpretedHeaders);
      setIsCompatibleToCounterParts(tempCounterPartsCompatible);
    }
  }, [dataSources, datasourceId]);

  // Define the number of line by watching which child is visible etc
  useEffect(() => {
    if (mappingTree?.[0]) {
      // Close old account and open another
      if (clickedId !== '') {
        loopToClose(previousSelectedId);
        loopToOpen(clickedId);
        setPreviousSelectedId(clickedId);
        setCellSelected([clickedId]);
        setTimeout(() => {
          document
            .getElementById('highlightedRow')
            ?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }, 200);
      }
    }
  }, [clickedId]);

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseClickOutSideTable);
    return () => {
      window.removeEventListener('mouseup', handleMouseClickOutSideTable);
    };
  }, []);

  const handleMouseClickOutSideTable = (ev: MouseEvent) => {
    if (tableRef.current && !(tableRef.current as any).contains(ev.target)) {
      emptySelectedCells();
    }
    // eslint-disable-next-line
  };

  const loopToClose = (selectedId: string) => {
    let parentId: string = selectedId;
    if (parentId !== '') {
      const loopArrayToFindPreviousClickedId = (child: NS_API.IGraph) => {
        child.forEach((item) => {
          if (
            !(
              item.data.content.rowContentType === 'C' &&
              (item.data.content.rowParams.locked === true || item.data.content.rowParams.opened === false) &&
              (parentId === item.data.content.rowContentId || parentId === item.id)
            )
          ) {
            if (parentId === item.data.content.rowContentId || parentId === item.id) {
              parentId = item.data.parent;
              item.data.content.rowParams.opened = false;
              loopArrayToFindPreviousClickedId(mappingTree);
            } else if (item.children && item.data.content.rowContentType === 'C') {
              loopArrayToFindPreviousClickedId(item.children);
            }
          }
        });
      };
      loopArrayToFindPreviousClickedId(mappingTree);
    }
  };

  const loopToOpen = (selectedId: string) => {
    let parentId: string = selectedId;
    const loopArrayToFindHoveredId = (child: NS_API.IGraph) => {
      child.forEach((item) => {
        if (
          !(
            item.data.content.rowParams.opened === true &&
            (parentId === item.data.content.rowContentId || parentId === item.id)
          )
        ) {
          if (parentId === item.data.content.rowContentId || parentId === item.id) {
            parentId = item.data.parent;
            // Only open concept
            if (item.data.content.rowContentId !== selectedId && item.data.content.rowContentType === 'C') {
              item.data.content.rowParams.opened = true;
            }
            loopArrayToFindHoveredId(mappingTree);
          } else {
            if (item.children && item.data.content.rowContentType === 'C') {
              loopArrayToFindHoveredId(item.children);
            }
          }
        }
      });
    };

    loopArrayToFindHoveredId(mappingTree);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPressEvent);
    return () => {
      window.removeEventListener('keydown', handleKeyPressEvent);
    };
  }, [selectedCells]);

  const calculateNumberOfLines = useCallback(() => {
    if (mappingTree?.[0] && tableBodyHeight) {
      let newNumber = mappingTree.length;
      const loopArray = (child: NS_API.IGraph) => {
        if (child.length > 0) {
          newNumber += child.reduce((sum, _child) => {
            if (_child.children && _child.data.content.rowParams.opened) {
              return sum + _child.children.length;
            } else {
              return sum;
            }
          }, 0);
        }
        child.forEach((_child) => {
          if (_child.children && _child.data.content.rowParams.opened) {
            loopArray(_child.children);
          }
        });
      };
      const tmpNumber = Math.trunc(
        ((tableBodyHeight / parseInt(zoomValue)) * 10 - (multipleDimensions ? 57 : 40)) / 35,
      );
      loopArray(mappingTree);
      setNumberOfLines(Math.max(tmpNumber, newNumber));
    }
  }, [mappingTree, tableBodyHeight, zoomValue, multipleDimensions]);

  const getDateFormated = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', { timeZone: 'UTC', month: 'short', year: '2-digit' })
      .format(date)
      .replace(/\s/g, '-');
  };

  const isNotAN = (date: Date) => {
    return date instanceof Date && !isNaN(date.valueOf());
  };

  const renameScorfCol = (fc, idxVal) => {
    let renamedColumns: Array<NS_Table.IColumnHeader> = [];

    const tempColumnsDimensions = columnsDimensions[0].split('/');

    if (tempColumnsDimensions.includes('EntryDate') && !isEditing) {
      // Date column
      const dateIndex = tempColumnsDimensions.findIndex((dimensions) => dimensions === 'EntryDate');

      renamedColumns = columnsValueNames.map((name, idx) => {
        const value = { ...fc[idxVal] };
        const dimensionsArr = name.split('/');
        let date = dimensionsArr[dateIndex];

        const dateFormat = new Date(date);

        if (dateRender[selectedDateRender] === 'month') {
          if (isNotAN(dateFormat)) {
            date = getDateFormated(dateFormat);
          } else if (fiscalYears[date.split('-')[0]]) {
            const BoPDate = new Date(fiscalYears[date.split('-')[0]].BoP);
            BoPDate.setMonth(BoPDate.getMonth() - 1); // Get previous month for AN
            date = getDateFormated(BoPDate);
          } else {
            date = StringHelper.buildHeaderLabel(date, monthGroupedBy);
          }
        } else if (dateHeaderType !== 'Period' && fiscalYears) {
          if (isNotAN(dateFormat) && fiscalYears[date]) {
            const EoPDate = new Date(fiscalYears[date].EoP);
            date = getDateFormated(EoPDate);
          } else if (fiscalYears[date.split('-')[0]]) {
            const BoPDate = new Date(fiscalYears[date.split('-')[0]].BoP);
            BoPDate.setMonth(BoPDate.getMonth() - 1); // Get previous month for AN
            date = getDateFormated(BoPDate);
          } else {
            date = StringHelper.buildHeaderLabel(date, monthGroupedBy);
          }
        } else {
          date = StringHelper.buildHeaderLabel(date, monthGroupedBy);
        }

        dimensionsArr.splice(dateIndex, 1, date);
        if (multipleDimensions && idx !== 0) {
          const previousDim1 = columnsValueNames[idx - 1].split('/');
          if (previousDim1[0] === name.split('/')[0]) {
            dimensionsArr.splice(0, 1, '');
          }
        }
        const displayName = dimensionsArr.join('/');
        value.displayName = displayName;
        value.valueIndex = idx;
        return value;
      });
    } else {
      renamedColumns = columnsValueNames.map((name, idx) => {
        const value = { ...fc[idxVal] };
        const dimensionsArr = name.split('/');
        if (multipleDimensions && idx !== 0) {
          const previousDim1 = columnsValueNames[idx - 1].split('/');
          if (previousDim1[0] === name.split('/')[0]) {
            dimensionsArr.splice(0, 1, '');
          }
        }
        value.displayName = dimensionsArr.join('/');
        value.valueIndex = idx;
        return value;
      });
    }
    return renamedColumns;
  };

  const addCalculatedCols = (renamedColumns: NS_Table.IColumnHeader[]) => {
    const valueColsCount = renamedColumns.length;
    if (nodesContentIndices?.calculation) {
      return nodesContentIndices.calculation.values.map((value, index) => {
        const formulaString = StringHelper.buildFormulaStringOfCalculationCol(value, renamedColumns);
        const col = {
          ...header.filter((item) => item.Header === 'value')[0],
          displayName: value.t,
          title: formulaString,
          valueIndex: index + valueColsCount,
          calculatedCol: true,
        } as NS_Table.IColumnHeader;

        if (value.o === 'variation' || value.o === 'cagr') {
          col.colParams = {
            colVisible: true,
            colScorf: true,
            colDataType: {
              dataType: 'number',
              format: '%0,2',
            },
          };
        }
        return col;
      });
    }
    return [];
  };

  const filteredCols = useMemo(() => {
    //show only visible cols
    const fc = header
      .filter((item) => item.colParams.colVisible)
      .sort((a, b) => {
        const aIdx = tableHeader.findIndex((item) => item.id === a.Header);
        const bIdx = tableHeader.findIndex((item) => item.id === b.Header);
        return bIdx - aIdx;
      });

    //map values
    const idxVal = fc.findIndex((col) => col.Header === 'value');
    const renamedColumns = renameScorfCol(fc, idxVal);
    // Add calculated column
    const formulaCols = addCalculatedCols(renamedColumns);
    fc.splice(idxVal, 1, ...renamedColumns.concat(formulaCols));

    return fc;
  }, [header, tableHeader, multipleDimensions, nodesContentIndices]);

  const widthCols = useMemo(() => {
    const colsWidth = filteredCols.map((col) => {
      return tableHeader.reduce((a, b) => {
        return b.id === col.Header ? a + b.value : a;
      }, 0);
    });
    colsWidth[0] = nameColWidth ?? colsWidth[0];
    if (!nameColWidth) {
      setNameColWidth(colsWidth[0]);
    }
    return colsWidth;
  }, [filteredCols, nameColWidth]);

  useEffect(() => {
    if (searchResults.length > 0 && searchResults[showResult] !== '') {
      const treeTemp = mappingTreeWithRoot[0] as NS_TREE.ITreeRoot<undefined>;
      const path = findPathToNode(searchResults[showResult], [], treeTemp);
      if (path && path.length !== 0) {
        path.reverse();
        const node = path[0];
        path.shift();

        path.forEach((path2) => {
          path2.data.content.rowParams.opened = true;
        });
        setCellFound(node.data.id);
        setScrollToNode(node);
      }
    }
  }, [showResult, searchResults]);

  useEffect(() => {
    if ((cellSelected[0] !== '' || searchResults[showResult] !== '') && tree && scrollToNode) {
      const nodeElement = document.getElementById(scrollToNode.data.id);
      if (nodeElement) {
        setTimeout(() => {
          nodeElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }, 200); // wait for the animation of expanding rows to finish
      }
    }
    // eslint-disable-next-line
  }, [scrollToNode]);

  useEffect(() => {
    updateOpenParent(false);
  }, [mappingTree]);

  const updateOpenParent = useCallback(
    (isChanged: boolean, isOpen?: boolean) => {
      if (isChanged) {
        let currentNameColWidth = nameColWidth ?? 0;
        currentNameColWidth = isOpen
          ? currentNameColWidth + INCREASE_COL_WIDTH
          : currentNameColWidth - INCREASE_COL_WIDTH;
        setNameColWidth(currentNameColWidth);
      }
      const visibleNode: string[] = [];
      const loopArray = (child: NS_API.IGraph) => {
        child.forEach((item) => {
          if (item.data.content.rowParams.opened) {
            visibleNode.push(item.data.content.rowContentId);
            visibleNode.push(item.id);
          }
          if (item.data.content.rowContentType !== 'N') {
            loopArray(item.children);
          }
        });
      };
      mappingTree && loopArray(mappingTree);
      setParentOpen(visibleNode);
    },
    [mappingTree, nameColWidth],
  );

  const handleShiftKeyAndUpOrDown = useCallback(
    (treeTemp, cursorCell) => {
      const shouldRevert = selectedCellsRowNodes.findIndex((el) => el.id === cursorCell.nodeId) !== -1;

      const id =
        cursorCell.accLineRowIndex !== undefined
          ? cursorCell.nodeId.slice(0, -(cursorCell.accLineRowIndex + '').length)
          : cursorCell.nodeId;

      const accountNode = TreeHelper.searchNodeById(treeTemp, id);
      let nextNode;
      // Next row is an entry line
      if (cursorCell.accLineRowIndex !== undefined && accountNode) {
        nextNode = accountNode.children[cursorCell.accLineRowIndex];
        nextNode['id'] = cursorCell.nodeId;
        nextNode.data.content['rowContentType'] = 'N';
        nextNode['accLineRowIndex'] = cursorCell.accLineRowIndex;
        nextNode['lastAccLineRow'] = cursorCell.accLineRowIndex === 0 ? true : cursorCell.lastAccLineRow;
        nextNode['firstAccLineRow'] =
          cursorCell.accLineRowIndex === accountNode.children.length - 1 ? true : cursorCell.firstAccLineRow;

        if (shouldRevert) {
          setSelectedCellsRowNodes(selectedCellsRowNodes.slice(0, -1));
        } else {
          setSelectedCellsRowNodes([...selectedCellsRowNodes, nextNode]);
        }
      } else {
        if (shouldRevert) {
          setSelectedCellsRowNodes(selectedCellsRowNodes.slice(0, -1));
        } else {
          const newNode = TreeHelper.searchNodeById(treeTemp, cursorCell.nodeId);
          if (newNode) {
            setSelectedCellsRowNodes([...selectedCellsRowNodes, newNode]);
          }
        }
      }
    },
    [selectedCellsRowNodes],
  );

  const handleShiftKeyLeftOrRight = useCallback((cursorCell, direction, columnsGroup) => {
    const updateSelectedCellsColumns = (col: number) => {
      const colIndex = columnsGroup.indexOf(col);
      const newSelectedCellsColums = [...columnsGroup];
      if (colIndex === -1) {
        newSelectedCellsColums.push(col);
      } else {
        newSelectedCellsColums.splice(colIndex, 1);
      }
      setSelectedCellsColumns(newSelectedCellsColums);
    };

    const move = direction === 'ArrowLeft' ? 1 : -1;
    if (columnsGroup.indexOf(cursorCell.colIndex) !== -1) {
      updateSelectedCellsColumns(cursorCell.colIndex + move);
    } else {
      updateSelectedCellsColumns(cursorCell.colIndex);
    }
  }, []);

  const resetSelectedGroup = (treeTemp, cursorCell) => {
    const accNodeId =
      cursorCell.accLineRowIndex !== undefined
        ? cursorCell.nodeId.slice(0, -(cursorCell.accLineRowIndex + '').length)
        : cursorCell.nodeId;
    const accountNode = TreeHelper.searchNodeById(treeTemp, accNodeId);

    // Next row is an entry line
    if (cursorCell.accLineRowIndex !== undefined && accountNode) {
      const nextNode = accountNode.children[cursorCell.accLineRowIndex];
      nextNode['id'] = cursorCell.nodeId;
      nextNode.data.content['rowContentType'] = 'N';
      nextNode['accLineRowIndex'] = cursorCell.accLineRowIndex;
      nextNode['lastAccLineRow'] = cursorCell.accLineRowIndex === 0 ? true : cursorCell.lastAccLineRow;
      nextNode['firstAccLineRow'] =
        cursorCell.accLineRowIndex === accountNode.children.length - 1 ? true : cursorCell.firstAccLineRow;
      setSelectedCellsRowNodes([nextNode]);
      setSelectedCellsColumns([cursorCell.colIndex]);
      setSelectedCellsOutGroup([]);
    } else if (accountNode) {
      setSelectedCellsRowNodes([accountNode]);
      setSelectedCellsColumns([cursorCell.colIndex]);
      setSelectedCellsOutGroup([]);
    }
  };

  const handleKeyPressEvent = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setCellToBeMoved('');
        if (cellSelectedRef?.current?.length) {
          setCellSelected([]);
        }
        setNameOfNodeSelected({ accountId: '', accountName: '' });
        setIsContextMenuOpen('');
      } else if (selectedCellsInGroup.length > 0) {
        e.preventDefault();
        const treeTemp = mappingTreeWithRoot[0] as NS_TREE.ITreeRoot<undefined>;
        const slctdCell = dataDestructuring(selectedCellsInGroup[selectedCellsInGroup.length - 1]);
        if (slctdCell) {
          switch (e.key) {
            case 'ArrowLeft': //row navigation left
              if (slctdCell.colIndex <= 1) {
                break;
              }
              if (slctdCell.nodeType !== 'T') {
                slctdCell.colIndex = slctdCell.colIndex - 1;
              }
              if (scrollPartRef.current) {
                smoothScroll(scrollPartRef.current, 'left', 20, 120, 20);
              }
              slctdCell.valueUpdated = true;
              if (e.shiftKey) {
                handleShiftKeyLeftOrRight(slctdCell, 'ArrowLeft', selectedCellsColumns);
              } else {
                resetSelectedGroup(treeTemp, slctdCell);
              }
              break;
            case 'ArrowUp': //row navigation up
              if (treeTemp && TreeHelper.findNextOrPreviousNode(treeTemp, slctdCell, -1, parentOpen)) {
                if (scrollPartRef.current) {
                  smoothScroll(scrollPartRef.current, 'up', 20, 35, 5);
                }
                if (e.shiftKey) {
                  if (selectedCellsRowNodes.length === 1) {
                    setGroupDirection('up');
                  }
                  handleShiftKeyAndUpOrDown(treeTemp, slctdCell);
                } else {
                  resetSelectedGroup(treeTemp, slctdCell);
                  setGroupDirection('');
                }
              }
              break;
            case 'ArrowRight': //row navigation right
              if (slctdCell.colIndex >= filteredCols.length - 1) {
                break;
              }
              if (slctdCell.nodeType !== 'T') {
                slctdCell.colIndex = slctdCell.colIndex + 1;
              }
              if (scrollPartRef.current) {
                smoothScroll(scrollPartRef.current, 'right', 20, 120, 20);
              }
              slctdCell.valueUpdated = true;
              if (e.shiftKey) {
                handleShiftKeyLeftOrRight(slctdCell, 'ArrowRight', selectedCellsColumns);
              } else {
                resetSelectedGroup(treeTemp, slctdCell);
              }
              break;
            case 'ArrowDown': //row navigation down
              if (treeTemp) {
                if (TreeHelper.findNextOrPreviousNode(treeTemp, slctdCell, 1, parentOpen)) {
                  if (e.shiftKey) {
                    if (selectedCellsRowNodes.length === 1) {
                      setGroupDirection('down');
                    }
                    handleShiftKeyAndUpOrDown(treeTemp, slctdCell);
                  } else {
                    resetSelectedGroup(treeTemp, slctdCell);
                    setGroupDirection('');
                  }
                  if (scrollPartRef.current) {
                    smoothScroll(scrollPartRef.current, 'down', 20, 35, 5);
                  }
                }
              }
              break;
            default:
              break;
          }
        }
      }
    },
    // eslint-disable-next-line
    [selectedCellsInGroup, mappingTreeWithRoot, filteredCols, selectedCellsColumns, selectedCellsRowNodes, parentOpen],
  );

  const unCollapse = useCallback(
    (id) => {
      const newArray = dataDestructuring(mappingTree);
      const loopArray = (child: NS_API.IGraph) => {
        child.forEach((item) => {
          if (item.id === id && item.children.length > 0) {
            item.children.forEach((element) => {
              element.rowParams.opened = true;
            });
            setCellSelected([item.children[0]]);
          } else {
            if (item.data.content.rowContentType !== 'N') {
              loopArray(item.children);
            }
          }
        });
      };
      loopArray(newArray);
      const newCommands = dataDestructuring(currentCommands);
      newCommands[
        Object.keys(newCommands).length === 0
          ? 0
          : parseInt(Object.keys(newCommands)[Object.keys(newCommands).length - 1]) + 1
      ] = {
        type: 'collapse',
      };
      setNewOperation(newArray, currentMetadata, newCommands);
    },
    [mappingTree, currentCommands, currentMetadata],
  );

  const onLocked = useCallback(
    (id: string) => {
      onLockedHelper(id, mappingTree, currentCommands, setNewOperation, currentMetadata);
    },
    [mappingTree, currentCommands, setNewOperation, currentMetadata],
  );

  const onRename = useCallback(
    (newName: string, id: string) => {
      onRenameHelper(newName, id, mappingTree, setNewOperation, currentMetadata, currentCommands);
    },
    [mappingTree, setNewOperation, currentMetadata, currentCommands],
  );

  const addParent = () => {
    (async () => {
      addLoadingTab(worksheetId);
      const { moveExecuted, newDataSourceGraph } = await addParentHelper(
        mappingTree,
        currentCommands,
        cellSelected,
        graphId,
        workspaceId,
        setNewOperation,
        currentMetadata,
        templateKey,
        reportId,
        selectedDataSourceGraph,
      );
      if (moveExecuted === false) {
        ToastHelper.error('Cannot add a parent to concepts of different levels');
      } else {
        if (newDataSourceGraph && newDataSourceGraph != selectedDataSourceGraph) {
          handleDataSourceGraph(newDataSourceGraph);
        }
      }
      removeLoadingTab(worksheetId);
    })();
  };

  const addChild = useCallback(() => {
    addLoadingTab(worksheetId);
    addChildHelper(
      mappingTree,
      currentCommands,
      cellSelected,
      graphId,
      workspaceId,
      setNewOperation,
      currentMetadata,
      templateKey,
      reportId,
      selectedDataSourceGraph,
    )
      .then((newDataSourceGraph) => {
        if (newDataSourceGraph && newDataSourceGraph != selectedDataSourceGraph) {
          handleDataSourceGraph(newDataSourceGraph);
        }
        removeLoadingTab(worksheetId);
      })
      .catch((err) => {});
  }, [mappingTree, currentCommands, cellSelected, graphId, workspaceId, setNewOperation, currentMetadata]);

  // When you want to delete an element but not its children
  const simpleDeleteMappingElement = useCallback(() => {
    simpleDeleteConcept(
      mappingTree,
      cellSelected,
      currentCommands,
      setCellSelected,
      setNameOfNodeSelected,
      setNewOperation,
      currentMetadata,
    );
  }, [mappingTree, cellSelected, currentCommands, setNewOperation, currentMetadata]);

  // When you want to delete an element but not its children
  const simpleDeleteMappingAccount = useCallback(() => {
    simpleDeleteAccount(
      mappingTree,
      cellSelected,
      currentCommands,
      setCellSelected,
      setNameOfNodeSelected,
      setNewOperation,
      currentMetadata,
    );
  }, [mappingTree, cellSelected, currentCommands, setNewOperation, currentMetadata]);

  // Delete an element and all its legacy
  const deepDeleteMappingElement = useCallback(() => {
    deepDeleteElement(
      mappingTree,
      cellSelected,
      currentCommands,
      setCellSelected,
      setNameOfNodeSelected,
      setNewOperation,
      currentMetadata,
    );
  }, [mappingTree, cellSelected, currentCommands, setNewOperation, currentMetadata]);

  //Will store the line that will be moved
  const startMoveElement = useCallback(() => {
    startMoveElementHelper(setCellToBeMoved, cellSelected);
  }, [setCellToBeMoved, cellSelected]);

  // create command after drag & drop
  useEffect(() => {
    if (cellToBeMoved && cellSelected.length > 0 && isDragging) {
      endMoveElement('below');
      setCellSelected([]);
      setNameOfNodeSelected({ accountId: '', accountName: '' });
      setIsDragging(false);
    }
  }, [cellSelected]);

  // will move the element previously selected
  const endMoveElement = useCallback(
    (position) => {
      endMoveElementHelper(
        position,
        cellSelected,
        setCellToBeMoved,
        cellToBeMoved,
        mappingTree,
        currentCommands,
        setNewOperation,
        currentMetadata,
      );
    },
    [cellSelected, cellToBeMoved, mappingTree, currentCommands, setNewOperation, currentMetadata],
  );

  const handleMouseMove = (e) => {
    if (!(countLineRef.current as any).contains(e.target)) {
      const zoomValueInDecimal = parseInt(zoomValue) / 10;
      // 190: Height of menu, 40(57): Height of header
      const offsetTop = 190 + (multipleDimensions ? 57 : 40) * zoomValueInDecimal;
      // 35: Line height
      const lineHeight = 35 * zoomValueInDecimal;
      const scrollTop = scrollPartRef.current?.scrollTop ?? 0;
      // Cursor not in coutline, 5: Height of drop area
      if (e.pageX > 60 && (e.pageY + scrollTop - offsetTop) % lineHeight < lineHeight) {
        // 35: Height of line
        const currentNum = Math.floor((e.pageY + scrollTop - offsetTop) / lineHeight);
        setHideLineNum(currentNum);
      } else {
        setHideLineNum(-1);
      }
    } else {
      setHideLineNum(-1);
    }

    if (resizeCol !== -1 && tableContainerRef.current) {
      setNewMousePos(e.pageX);
    }
  };

  return (
    <div
      className={`editStatementContainer`}
      ref={tableContainerRef}
      id="tableContainer"
      onClick={() => {
        setIsContextMenuOpen('');
      }}
      onMouseMove={handleMouseMove}
      onMouseUp={(e) => {
        if (resizeCol !== -1) {
          const diffPos = startResize - e.pageX;
          const newPos = widthCols[0] - diffPos;
          setNameColWidth(newPos);
          setNewMousePos(-1);
          setResizeCol(-1);
          setStartResize(-1);
        }
      }}
      onMouseLeave={() => setHideLineNum(-1)}>
      <EditHeader
        disabled={disabled}
        multipleDimensions={multipleDimensions}
        zoomValue={zoomValue}
        setOpenContextMenu={setOpenContextMenu}
        openContextMenu={openContextMenu}
        setIndent={setIndent}
        indent={indent}
        mappingTree={mappingTree}
        filteredCols={filteredCols}
        setCellSelected={setCellSelected}
        setNameOfNodeSelected={setNameOfNodeSelected}
        widthCols={widthCols}
        tablePosXRef={tableContainerRef}
        setStartResize={setStartResize}
        setResizeCol={setResizeCol}
        treeRef={tree}
        newMousePos={newMousePos}
        onClickHeader={onClickHeader}
        selectedHeaders={selectedHeaders}
        headerSelectionMode={headerSelectionMode}
        onReportSettingClick={onReportSettingClick}
        reportDisplayMode={reportDisplayMode}
      />
      <div ref={tableRef} className={`body ${multipleDimensions && 'multipleDimensions'}`} id="bodyContainer">
        <div
          className="treeContainer"
          id="treeContainer"
          ref={tree}
          style={
            {
              '--primaryFill': colorOptions.primaryFillColor,
              '--secondaryFill': colorOptions.secondaryFillColor,
              '--primaryText': colorOptions.primaryTextColor,
              '--secondaryText': colorOptions.secondaryTextColor,
            } as React.CSSProperties
          }>
          <div className="countLine" ref={countLineRef}>
            {Array(numberOfLines)
              .fill(0)
              .map((_number, key) => {
                const index = `${key}-`;
                return (
                  <div
                    className={`line ${key === hideLineNum && 'hide'}`}
                    key={index}
                    style={{ height: `calc(35px * ${zoomValue} / 10)`, fontSize: `calc(12px * ${zoomValue} / 10)` }}>
                    {key + 1}
                  </div>
                );
              })}
          </div>
          <div className="tableBorder"></div>
          {mappingTree && (
            <EditTree
              disabled={disabled}
              originalHeader={header}
              datasourceId={datasourceId}
              zoomValue={zoomValue}
              level={0}
              tree={mappingTree}
              treeFromNodeToOpen={mappingTreeWithRoot[0]}
              setCellSelected={setCellSelected}
              setNameOfNodeSelected={setNameOfNodeSelected}
              cellSelected={cellSelected}
              cellToBeMoved={cellToBeMoved}
              setCellToBeMoved={setCellToBeMoved}
              addParent={addParent}
              addChild={addChild}
              simpleDeleteMappingElement={simpleDeleteMappingElement}
              deepDeleteMappingElement={deepDeleteMappingElement}
              simpleDeleteMappingAccount={simpleDeleteMappingAccount}
              startMoveElement={startMoveElement}
              endMoveElement={endMoveElement}
              onRename={onRename}
              onLocked={onLocked}
              hoveredNodeId={hoveredNodeId}
              setHoveredNodeId={setHoveredNodeId}
              levelHoveredNode={-1}
              unCollapse={unCollapse}
              isContextMenuOpen={isContextMenuOpen}
              setIsContextMenuOpen={setIsContextMenuOpen}
              setIsDragging={setIsDragging}
              isDraggingState={isDragging}
              header={filteredCols}
              currencyParams={currencyParams}
              setTypeOfVisualisation={setTypeOfVisualisation}
              handleMappingOpen={handleMappingOpen}
              nameWidth={widthCols[0]}
              isCompatibleToCounterParts={isCompatibleToCounterParts}
              nodeOpenedAccount={nodeOpenedAccount}
              setNodeOpenedAccount={setNodeOpenedAccount}
              setSelectedCellsValues={setSelectedCells}
              selectedCellsValues={selectedCells}
              indent={indent}
              calculateNumberOfLines={calculateNumberOfLines}
              selectedTemplate={selectedTemplate}
              columnsDimensions={columnsDimensions}
              displayOpeningColumn={displayOpeningColumn}
              setSelectedCellsRowNodes={setSelectedCellsRowNodes}
              setSelectedCellsColumns={setSelectedCellsColumns}
              selectedCellsRowNodes={selectedCellsRowNodes}
              selectedCellsColumns={selectedCellsColumns}
              parentOpen={parentOpen}
              mappingTreeWithRoot={mappingTreeWithRoot}
              setSelectedCellsOutGroup={setSelectedCellsOutGroup}
              selectedCellsOutGroup={selectedCellsOutGroup}
              selectedCellsInGroup={selectedCellsInGroup}
              groupDirection={groupDirection}
              updateOpenParent={updateOpenParent}
              setAccountSelectedInReport={setAccountSelectedInReport}
              cellFound={cellFound}
              editable={
                reportDisplayMode !== ReportDisplayMode.SALES_BY_CLIENT &&
                reportDisplayMode !== ReportDisplayMode.PURCHASES_BY_PROVIDER &&
                reportGraphExist
              }
              handlePropertiesOpen={handlePropertiesOpen}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditStatement;
