export enum RoutesEnum {
  dashboard = '/',
  workspace = '/:workspaceId',
  homeSub = '/home',
  dataSourceSub = '/dataSource',
  reportsSub = 'reports/:worksheetId',

  mappingEdit = '/:worksheetId/mapping/:reportId',
  mapping = '/:worksheetId/mapping',
  notFound = '/404',
  notActivated = '/notActivated',
  timeout = '/timeout',
  maintenace = '/maintenance',
}

export enum RoutesHash {
  export = '#export',
  users = '#users',
  qanda = '#q&a',
  options = '#options',
  support = '#support',
  templateCol = '#templateCol',
  news = '#news',
}
