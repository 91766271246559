import './style.scss';

import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';

import rightArrow from '../../assets/img/chevron-right.svg';
import RedCrossSVG from '../../assets/img/cross-red.svg';
import { renderContent } from '../../shared/helpers/helpers';

interface IDropdownList {
  /**
   * All selectable elements
   */
  elements: Array<any>;
  /**
   * Current seleced elements
   */
  selectedElements: Array<any>;
  /**
   * Element select handler
   */
  onItemClick: (value: any, index: number) => void;
  /**
   * Is select disabled
   * @default false
   */
  disabled?: boolean;
  /**
   * Is multiple options can be seleted
   */
  multipleSelect?: boolean;
  /**
   * Select container's max height, if true, the overflow content is scrollable
   */
  maxHeight?: number;
  /**
   * Prefix label of selected element
   */
  selectedItemLabelPrefix?: string;
  /**
   * Text if not item selected
   */
  placeholder?: string;
  /**
   * Custom selected element component
   */
  customSelectedElmentsLabelComponent?: (text: string) => ReactElement;
  /**
   * Custom option component
   */
  customOptionLabelComponent?: (text: string) => ReactElement;
}

/**
 * A custom select list
 */
const DropDownList: React.FC<IDropdownList> = ({
  elements,
  selectedElements,
  onItemClick,
  disabled = false,
  multipleSelect = false,
  maxHeight,
  selectedItemLabelPrefix = '',
  customSelectedElmentsLabelComponent,
  customOptionLabelComponent,
  placeholder = 'Nothing selected',
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [openDispositionDropdown, setOpenDispositionDropdown] = useState<boolean>(false);

  useEffect(() => {
    const handleEscapePress = (e) => {
      if (e.key === 'Escape') {
        setOpenDispositionDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleEscapePress);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleEscapePress);
    };
  }, []);

  const handleClickOutside = useCallback(
    (event) => {
      if (!(ref.current as any).contains(event.target)) {
        setOpenDispositionDropdown(false);
      }
    },
    [ref.current],
  );

  const handleOpenDispositionDropDown = () => {
    if (!disabled) {
      setOpenDispositionDropdown(!openDispositionDropdown);
    }
  };

  const handleClickItem = (item: any, index: number) => () => {
    onItemClick(item, index);
    if (!multipleSelect) {
      setOpenDispositionDropdown(false);
    }
  };

  const renderSelectedElementsLabelComponent = useMemo(() => {
    const labelText = `${selectedItemLabelPrefix}${renderContent(
      selectedElements.length > 0 && selectedElements[0] !== undefined,
      selectedElements.join(', '),
      placeholder,
    )}`;
    if (customSelectedElmentsLabelComponent !== undefined) {
      return customSelectedElmentsLabelComponent(labelText);
    }
    return <div className="toolBarText">{labelText}</div>;
  }, [selectedItemLabelPrefix, selectedElements, customSelectedElmentsLabelComponent]);

  const renderOptionLabelComponent = useCallback(
    (labelText: string) => {
      if (customOptionLabelComponent !== undefined) {
        return customOptionLabelComponent(labelText);
      }
      return <p className="dropDownList_option_label">{labelText}</p>;
    },
    [customOptionLabelComponent],
  );

  return (
    <div
      className={`dropDownListContainer ${renderContent(
        disabled,
        'dropDownListContainer--disabled',
        '',
      )} ${renderContent(openDispositionDropdown, 'dropDownListContainer--opened', '')}`}
      ref={ref}>
      <div
        className="dropDownList_selectedElements"
        title={selectedElements.join(', ')}
        onClick={handleOpenDispositionDropDown}>
        {renderSelectedElementsLabelComponent}
        <ReactSVG src={rightArrow} className={`svg-wrapper rightArrow`} />
      </div>

      {openDispositionDropdown && (
        <div
          className="dropDownList_optionsContainer"
          style={{ maxHeight: renderContent(maxHeight !== undefined, maxHeight, 'none') }}>
          {elements.map((value, index) => (
            <div
              className={`dropDownList_option ${renderContent(
                selectedElements.indexOf(value) !== -1,
                'dropDownList_option--selected',
                '',
              )} ${renderContent(value === '', 'dropDownList_option--blank', '')}`}
              key={value}
              title={value}
              onClick={handleClickItem(value, index)}>
              {renderOptionLabelComponent(value)}
              {multipleSelect &&
                renderContent(
                  selectedElements.indexOf(value) !== -1,
                  <ReactSVG src={RedCrossSVG} className="svg-wrapper dropDownList_option_redCross" />,
                  '',
                )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDownList;
