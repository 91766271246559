// Style
import './style.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
// SVG
import { ReactSVG } from 'react-svg';

import CheckboxSVG from '../../../../assets/img/checkbox.svg';
import DownArrowSVG from '../../../../assets/img/chevron-down-black.svg';
import { ELSFilter, SortMode, sortRows } from '../../../../shared/helpers/ELS-Filter';
import { renderContent } from '../../../../shared/helpers/helpers';
import { dataDestructuring } from '../../../../shared/helpers/tree/front.tree.util';
import CustomHeader from '../DataVizMapping/CustomHeader';

interface IAccountsListProps {
  accountIds: string[];
  accountNames: string[];
  rowClicked: number;
  setRowClicked: React.Dispatch<React.SetStateAction<number>>;
  accountSelected: string;
  modeCoverage: boolean;
  accountSelectedForCoverage: Array<NS_REPORT.INameSelectedForCoverageProps>;
  setAccountSelectedForCoverage: (val: Array<NS_REPORT.INameSelectedForCoverageProps>) => void;
  setIsMapOpen: (val: boolean) => void;
  cellSelected: string[];
  accountSelectedInReport: string;
  setAccountSelectedInReport: (val: string) => void;
}

interface ISortedAccount {
  0: string;
  1: string;
  originalIndex: number;
}

const columns: NS_Table.IColumnHeader[] = [
  {
    Header: 'AccountID',
    accessor: '0',
    displayName: 'AccountID',
    InterpretedHeader: 'AccountID',
    colParams: {
      colScorf: false,
      colVisible: false,
      colDataType: {
        dataType: 'number',
        format: 'string',
      },
    },
    valueIndex: undefined,
  },
  {
    Header: 'AccountName',
    accessor: '1',
    displayName: 'AccountName',
    InterpretedHeader: 'AccountName',
    colParams: {
      colScorf: false,
      colVisible: false,
      colDataType: {
        dataType: 'number',
        format: 'string',
      },
    },
    valueIndex: undefined,
  },
];

const sortFunction = (accountA, accountB) => {
  return parseInt(accountA[0]) - parseInt(accountB[0]);
};
/**
 * Container used to display a report
 *
 * @param param NS_Tabs.ITab props
 */

const AccountsList: React.FC<IAccountsListProps> = ({
  accountIds,
  accountNames,
  rowClicked, // clicked item used for searching in report
  setRowClicked,
  accountSelected, // focus item from search result
  modeCoverage,
  accountSelectedForCoverage,
  setAccountSelectedForCoverage,
  cellSelected,
  accountSelectedInReport,
  setAccountSelectedInReport,
}) => {
  // States
  const [sortedAccounts, setSortedAccounts] = useState<ISortedAccount[]>();
  const [initialData, setInitialData] = useState<ISortedAccount[]>();
  const [openContextHeader, setOpenContextHeader] = useState<number>(-1);
  const [accountClickedFromReport, setAccountClickedFromReport] = useState<number>(-1);
  const [change, setChange] = useState<boolean>(false);

  // Ref
  const tbodyRef = useRef<HTMLTableSectionElement>(null);
  const HeaderRef = useRef(null);
  const svgRefs = useRef<any[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const sortAcc = () => {
    const groupedAccountInfos: ISortedAccount[] = [];
    accountIds.forEach((account, index) => {
      if (!!accountNames[index] && accountNames[index] !== 'null') {
        const tempAccountObj = {
          0: account,
          1: accountNames[index],
          originalIndex: index,
        };
        groupedAccountInfos.push(tempAccountObj);
      }
    });
    return groupedAccountInfos.sort((a, b) => sortFunction(a, b));
  };

  useEffect(() => {
    const sorted = sortAcc();
    setSortedAccounts(sorted);
    setInitialData(sorted);
    ELSFilter.initELSFilter(columns, sorted);
  }, [accountIds, accountNames]);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('trSelected')?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }, 200);
  }, [accountSelected]);

  useEffect(() => {
    if (accountSelectedInReport && sortedAccounts) {
      for (const account of sortedAccounts) {
        if (account[0] === accountSelectedInReport) {
          setAccountClickedFromReport(account.originalIndex);
        }
      }
    } else {
      setAccountClickedFromReport(-1);
    }
  }, [accountSelectedInReport]);

  useEffect(() => {
    if (!cellSelected.length) {
      setAccountSelectedInReport('');
    }
  }, [cellSelected]);

  useEffect(() => {
    setTimeout(() => {
      const accountsClickedFromReport = document.getElementsByClassName('accountSelectedInReport');
      accountsClickedFromReport[0]?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }, 200);
  }, [accountClickedFromReport]);

  useEffect(() => {
    document.addEventListener('keyup', handleKeyup);
    return () => {
      document.removeEventListener('keyup', handleKeyup);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  const handleKeyup = (e) => {
    if (e.code === 'Escape') {
      setRowClicked(-1);
      setOpenContextHeader(-1);
    }
  };

  const handleClickOutside = (e) => {
    if (tbodyRef.current && !(tbodyRef.current as any).contains(e.target)) {
      setRowClicked(-1);
    }
    if (
      HeaderRef.current &&
      svgRefs.current &&
      !(HeaderRef.current as any).contains(e.target) &&
      svgRefs.current.findIndex((el) => el?.contains(e.target)) === -1
    ) {
      setOpenContextHeader(-1);
    }
  };

  const handleLineClick = (index: number) => {
    setAccountSelectedInReport('');
    setRowClicked(index);
  };

  const toggleCheckbox = (id: string, name: string) => {
    const newArray = [...accountSelectedForCoverage];
    const indexOfSelectedAccount = newArray.findIndex((e) => e.accountId === id);
    if (indexOfSelectedAccount > -1) {
      newArray.splice(indexOfSelectedAccount, 1);
    } else {
      newArray.push({ accountId: id, accountName: name });
    }
    setAccountSelectedForCoverage(newArray);
    setChange(!change);
  };

  const selectAllLine = () => {
    const newArray: NS_REPORT.INameSelectedForCoverageProps[] = [];
    if (sortedAccounts) {
      if (accountSelectedForCoverage.length < sortedAccounts.length) {
        sortedAccounts.forEach((account, _index) => {
          newArray.push({ accountId: account[0], accountName: account[1] });
        });
      }
    }
    setAccountSelectedForCoverage(newArray);
  };

  const sort = (type: SortMode.ASC | SortMode.DESC, col: number) => {
    const isInt = col === 0;
    if (sortedAccounts) {
      const tempData = [...sortedAccounts];
      sortRows(tempData, col.toString(), isInt, type);
      setSortedAccounts(tempData);
      setOpenContextHeader(-1);
    }
  };

  const clearFilter = useCallback(
    (indexColumn) => {
      console.log({ columns, initialData, ELSFilter });
      if (columns?.[indexColumn]?.ELSFilter) {
        columns[indexColumn].ELSFilter!.isFiltered = false;
        const temp = dataDestructuring(columns[indexColumn].ELSFilter!.selection);
        for (const key2 in temp) {
          temp[key2] = true;
        }
        columns[indexColumn].ELSFilter!.selection = dataDestructuring(temp);
        validateFilters();
        setOpenContextHeader(-2);
      }
    },
    [columns, initialData, ELSFilter],
  );

  const validateFilters = useCallback(() => {
    if (initialData) {
      const filteredRows = ELSFilter.filter(columns, initialData);
      setSortedAccounts(filteredRows);
      setOpenContextHeader(-1);
    }
  }, [columns, initialData, ELSFilter]);

  const toggleCheckHeader = useCallback(
    (key: string, indexColumn: number, e: MouseEvent, checkAll = false) => {
      e.stopPropagation();
      if (columns?.[indexColumn]?.ELSFilter) {
        const temp = dataDestructuring(columns[indexColumn].ELSFilter!.selection);
        if (checkAll) {
          if (Object.values(temp).every((isTrue) => isTrue)) {
            for (const key1 in temp) {
              temp[key1] = false;
            }
          } else {
            for (const key2 in temp) {
              temp[key2] = true;
            }
          }
        } else {
          temp[key] = !temp[key];
        }

        columns[indexColumn].ELSFilter!.selection = dataDestructuring(temp);
        setChange(!change);
      }
    },
    [columns, ELSFilter, change],
  );

  const handleContextHeader = (index: number) => () => {
    setOpenContextHeader(index);
  };

  const handleDownArrow = useCallback(
    (svg: any) => {
      if (!svgRefs.current.includes(svg)) svgRefs.current.push(svg);
    },
    [svgRefs],
  );
  return (
    <div className={`accountsListContainer`} ref={containerRef}>
      <table>
        <thead>
          <tr>
            <th align="center" className="checkBoxColumn">
              <div
                className={`${
                  sortedAccounts && accountSelectedForCoverage.length === sortedAccounts.length && 'checkBoxChecked'
                } checkBox`}
                onClick={() => selectAllLine()}>
                <ReactSVG className="svg-wrapper checkSVG" src={CheckboxSVG} />
              </div>
            </th>
            {columns.map((col, index) => {
              return (
                <th align="left" key={`${col.displayName}-${col.accessor}`}>
                  {(index === 1 || index === 0) && (
                    <div className="td">
                      <div className="colName">{col.displayName}</div>
                      <ReactSVG
                        src={DownArrowSVG}
                        className="svg-wrapper downArrowSVG"
                        beforeInjection={handleDownArrow}
                        onClick={handleContextHeader(openContextHeader === index ? -1 : index)}
                      />
                      {openContextHeader === index && (
                        <CustomHeader
                          //@ts-ignore
                          column={col}
                          indexColumn={index}
                          toggleCheckHeader={toggleCheckHeader}
                          validateFilters={validateFilters}
                          clearFilter={clearFilter}
                          close={handleContextHeader(-1)}
                          sortRows={sort}
                          // take accountsContainerList height as max-height to avoid pbm on small screen
                          tableBodyHeight={containerRef.current ? containerRef.current.offsetHeight - 20 : 500}
                          HeaderRef={HeaderRef}
                        />
                      )}
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody ref={tbodyRef}>
          {sortedAccounts?.map((account, index) => {
            const key = `${index} - ${account.originalIndex}`;
            return (
              <tr
                key={key}
                id={`${accountSelected === account[1] && 'trSelected'}`}
                className={`${rowClicked === account.originalIndex && 'trSelected'} ${
                  accountClickedFromReport === account.originalIndex && 'accountSelectedInReport'
                }`}
                onClick={() => {
                  !modeCoverage && handleLineClick(account.originalIndex);
                  setOpenContextHeader(-1);
                }}>
                <td align="center" className="checkBoxColumn">
                  <div
                    className={`${
                      accountSelectedForCoverage.findIndex((e) => e.accountId === account[0]) > -1 && 'checkBoxChecked'
                    } checkBox`}
                    onClick={(e) => {
                      toggleCheckbox(account[0], account[1]);
                      e.stopPropagation();
                    }}>
                    <ReactSVG className="svg-wrapper checkSVG" src={CheckboxSVG} />
                  </div>
                </td>
                <td
                  className={`table_bodyCell--text ${renderContent(
                    rowClicked === account.originalIndex,
                    'table_bodyCell--text--green',
                    '',
                  )}`}>
                  {account[0]}
                </td>
                <td
                  className={`table_bodyCell--text ${renderContent(
                    rowClicked === account.originalIndex,
                    'table_bodyCell--text--green',
                    '',
                  )}`}>
                  {account[1]}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AccountsList;
