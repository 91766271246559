import React from 'react';

// Router
import { useNavigate } from 'react-router-dom';
import { RoutesEnum } from '../../shared/constant/route.consts';

// Style
import './style.scss';

/**
 * 404 page container
 */

const NotFound: React.FC = () => {
  // History

  const navigate = useNavigate();

  return (
    <div className={'notFoundRoot'}>
      <p>404</p>
      <div className={'content'}>
        Something went wrong... Sorry, we couldn't find this page.
        <div className={'goHome'} onClick={() => navigate(RoutesEnum.dashboard)}>
          Go to home
        </div>
      </div>
    </div>
  );
};

export default NotFound;
