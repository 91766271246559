import { dataDestructuring } from '../tree/front.tree.util';
import { MappingCommandsParams } from '../../constant/mapping.consts';
import ToastHelper from '../toast/ToastHelper';

//Will store the line that will be moved
export const startMoveElementHelper = (setCellToBeMoved, cellSelected) => {
  setCellToBeMoved(cellSelected[0]);
};

export const moveCommandHelper = (
  index,
  elementToBeMoved,
  newCommands,
  commandsKeys,
  childrenIdsAndNamesOfParentNodeOfSelectedNode,
  parentOfDestinationNode,
  childrenIdsAndNamesOfDestinationNode,
  cellSelected,
) => {
  if (elementToBeMoved.data.content.rowContentType === 'N') {
    const destinationNodeId = parentOfDestinationNode.data
      ? parentOfDestinationNode.data.content.rowContentId
      : parentOfDestinationNode;

    if (elementToBeMoved.data.parent === destinationNodeId) {
      // Move as sibling
      newCommands[commandsKeys.length === 0 ? 0 : parseInt(commandsKeys[commandsKeys.length - 1]) + 1] = {
        type: MappingCommandsParams.moveAccount,
        parameters: {
          selectedNodeId: elementToBeMoved.data.content.rowContentId,
          parentIdOfSelectedNode: elementToBeMoved.data.parent.split('-')[0],
          childrenIdsAndNamesOfParentNodeOfSelectedNode: childrenIdsAndNamesOfParentNodeOfSelectedNode,
          parentIdOfDestinationNode: elementToBeMoved.parent.split('-')[0],
          destinationNodeId: cellSelected[0],
          atPosition: index,
        },
      };
    } else {
      // Move as child + move as sibling
      const parameters = {
        selectedNodeId: elementToBeMoved.data.content.rowContentId,
        parentIdOfSelectedNode: elementToBeMoved.data.parent.split('-')[0],
        childrenIdsAndNamesOfParentNodeOfSelectedNode: childrenIdsAndNamesOfParentNodeOfSelectedNode,
        parentIdOfDestinationNode: elementToBeMoved.parent.split('-')[0],
        destinationNodeId: cellSelected[0],
        atPosition: index,
      };
      //childrenIdsAndNamesOfDestinationNode: childrenIdsAndNamesOfDestinationNode,
      if (Object.keys(childrenIdsAndNamesOfDestinationNode).length) {
        parameters['childrenIdsAndNamesOfDestinationNode'] = childrenIdsAndNamesOfDestinationNode;
      }

      newCommands[commandsKeys.length === 0 ? 0 : parseInt(commandsKeys[commandsKeys.length - 1]) + 1] = {
        type: MappingCommandsParams.moveAccount,
        parameters: parameters,
      };
    }
  } else {
    newCommands[commandsKeys.length === 0 ? 0 : parseInt(commandsKeys[commandsKeys.length - 1]) + 1] = {
      type: MappingCommandsParams.move,
      parameters: {
        moveAs: MappingCommandsParams.moveAsSibling,
        originNodeId: elementToBeMoved.data.content.rowContentId,
        destinationNodeId: cellSelected[0],
        atPosition: index,
        destinationNodeParentId: elementToBeMoved.parent.split('-')[0],
      },
    };
  }
};

// will move the element previously selected
export const endMoveElementHelper = (
  position,
  cellSelected,
  setCellToBeMoved,
  cellToBeMoved,
  mappingTree,
  currentCommands,
  setNewOperation,
  currentMetadata,
) => {
  let elementToBeMoved; // Node to selected to be moved
  let elementMoved = false;
  let parentOfElementToBeMoved;
  let parentOfDestinationNode;
  let elementToRestore;
  let positionToRestore;
  const newCommands = dataDestructuring(currentCommands);

  const loopArrayToRestoreElement = (child: NS_API.IGraph) => {
    if (child.length === 0) {
      child.push(elementToRestore);
    } else {
      child.forEach((item) => {
        if (item.parent === elementToRestore.parent) {
          child.splice(positionToRestore, 0, elementToRestore);
        } else if (item.id === elementToRestore.parent) {
          item.children.splice(positionToRestore, 0, elementToRestore);
        } else if (item.children && item.data.content.rowContentType !== 'N') {
          loopArrayToRestoreElement(item.children);
        }
      });
    }
  };

  const loopArrayToGetElement = (child: NS_API.IGraph) => {
    child.forEach((item, index) => {
      item.children.forEach((itemChild) => {
        if (itemChild.data.content.rowContentId === cellToBeMoved) {
          parentOfElementToBeMoved = item;
        }
      });
      if (item.data.content.rowContentId === cellToBeMoved) {
        elementToBeMoved = item;
        elementToRestore = item;
        positionToRestore = index;
        child.splice(index, 1);
      } else {
        if (item.children && item.data.content.rowContentType !== 'N') {
          loopArrayToGetElement(item.children);
        }
      }
    });
  };

  const loopArrayToPutElement = (child: NS_API.IGraph) => {
    if (elementMoved) {
      return;
    }
    child.forEach((item, index) => {
      if (position === MappingCommandsParams.moveBelow || position === MappingCommandsParams.moveAbove) {
        child.forEach((itemChild) => {
          if (itemChild.data.content.rowContentId === cellSelected[0] && elementToBeMoved) {
            if (elementToBeMoved.data.parent.split('-')[0] === item.data.content.rowContentId) {
              item.children.push(elementToBeMoved);
              parentOfDestinationNode = dataDestructuring(item);
              item.children.splice(item.children.length - 1, 1);
            } else {
              if (itemChild.data.parent === 'R01') {
                parentOfDestinationNode = {
                  id: 'R01',
                  children: [...dataDestructuring(mappingTree), elementToBeMoved],
                  data: { content: { rowContentId: 'R01' } },
                };
              } else {
                parentOfDestinationNode = dataDestructuring(item);
              }
            }
          }
        });
      }
      if (item.data.content.rowContentId === cellSelected[0] && !elementMoved) {
        let commandsKeys = Object.keys(newCommands);

        const childrenIdsAndNamesOfParentNodeOfSelectedNode: {} = {};
        childrenIdsAndNamesOfParentNodeOfSelectedNode[elementToBeMoved.data.content.rowContentId] =
          elementToBeMoved.data.content.rowContentName;

        if (parentOfElementToBeMoved) {
          parentOfElementToBeMoved.children.forEach((childOfParentToBeMoved) => {
            childrenIdsAndNamesOfParentNodeOfSelectedNode[`${childOfParentToBeMoved.data.content.rowContentId}`] =
              childOfParentToBeMoved.data.content.rowContentName;
          });
        }

        const childrenIdsAndNamesOfDestinationNode: {} = {};
        if (position === MappingCommandsParams.moveBelow || position === MappingCommandsParams.moveAbove) {
          // parentOfDestinationNode is not correct here
          if (parentOfDestinationNode) {
            if (parentOfDestinationNode.data.content.rowContentType !== 'N') {
              parentOfDestinationNode.children.forEach((childOfParentOfDestinationNode) => {
                childrenIdsAndNamesOfDestinationNode[childOfParentOfDestinationNode.data.content.rowContentId] =
                  childOfParentOfDestinationNode.data.content.rowContentName;
              });
            } else {
              // destination is a leaf concept
              child.forEach((childOfParentOfDestinationNode) => {
                childrenIdsAndNamesOfDestinationNode[childOfParentOfDestinationNode.data.content.rowContentId] =
                  childOfParentOfDestinationNode.data.content.rowContentName;
              });
            }
          }
        } else {
          if (item.children) {
            item.children.forEach((itemChild) => {
              childrenIdsAndNamesOfDestinationNode[itemChild.data.content.rowContentId] =
                itemChild.data.content.rowContentName;
            });
          }
        }
        if (
          (position === MappingCommandsParams.moveBelow || position === MappingCommandsParams.moveAbove) &&
          elementToBeMoved.data.content.rowContentType === 'N'
        ) {
          parentOfDestinationNode = item.parent;
          elementToBeMoved.parent = parentOfDestinationNode;
        }
        if (
          elementToBeMoved.parent !== item.parent &&
          elementToBeMoved.data.content.rowContentType !== 'N' &&
          position !== MappingCommandsParams.moveAsChild
        ) {
          newCommands[commandsKeys.length === 0 ? 0 : parseInt(commandsKeys[commandsKeys.length - 1]) + 1] = {
            type: MappingCommandsParams.move,
            parameters: {
              moveAs: MappingCommandsParams.moveAsChild,
              originNodeId: elementToBeMoved.data.content.rowContentId,
              destinationNodeId: item.parent.split('-')[0],
              childrenIdsAndNamesOfDestinationNode: childrenIdsAndNamesOfDestinationNode,
            },
          };
          commandsKeys = Object.keys(newCommands);
          setNewOperation(newArray, currentMetadata, newCommands);
        }

        if (position === MappingCommandsParams.moveBelow) {
          elementToBeMoved.parent = item.parent;
          child.splice(index, 0, elementToBeMoved);

          moveCommandHelper(
            MappingCommandsParams.moveBelow,
            elementToBeMoved,
            newCommands,
            commandsKeys,
            childrenIdsAndNamesOfParentNodeOfSelectedNode,
            parentOfDestinationNode,
            childrenIdsAndNamesOfDestinationNode,
            cellSelected,
          );

          elementMoved = true;
        }
        if (position === MappingCommandsParams.moveAbove) {
          elementToBeMoved.parent = item.parent;
          child.splice(index + 1, 0, elementToBeMoved);

          moveCommandHelper(
            MappingCommandsParams.moveAbove,
            elementToBeMoved,
            newCommands,
            commandsKeys,
            childrenIdsAndNamesOfParentNodeOfSelectedNode,
            parentOfDestinationNode,
            childrenIdsAndNamesOfDestinationNode,
            cellSelected,
          );

          elementMoved = true;
        }

        if (position === MappingCommandsParams.moveAsChild) {
          elementToBeMoved.parent = item.id;
          item.children.unshift(elementToBeMoved);
          if (elementToBeMoved.data.content.rowContentType === 'N') {
            // Simple move as child
            newCommands[commandsKeys.length === 0 ? 0 : parseInt(commandsKeys[commandsKeys.length - 1]) + 1] = {
              type: MappingCommandsParams.moveAccount,
              parameters: {
                selectedNodeId: elementToBeMoved.data.content.rowContentId,
                parentIdOfSelectedNode: elementToBeMoved.data.parent.split('-')[0],
                childrenIdsAndNamesOfParentNodeOfSelectedNode: childrenIdsAndNamesOfParentNodeOfSelectedNode,
                destinationNodeId: cellSelected[0],
                childrenIdsAndNamesOfDestinationNode: childrenIdsAndNamesOfDestinationNode,
              },
            };
          } else {
            newCommands[commandsKeys.length === 0 ? 0 : parseInt(commandsKeys[commandsKeys.length - 1]) + 1] = {
              type: MappingCommandsParams.move,
              parameters: {
                moveAs: MappingCommandsParams.moveAsChild,
                originNodeId: elementToBeMoved.data.content.rowContentId,
                destinationNodeId: cellSelected[0],
                childrenIdsAndNamesOfDestinationNode: childrenIdsAndNamesOfDestinationNode,
              },
            };
          }
          elementMoved = true;
        }
      } else if (item.children && item.data.content.rowContentType !== 'N') {
        loopArrayToPutElement(item.children);
      }
    });
  };

  const newArray = dataDestructuring(mappingTree);
  loopArrayToGetElement(newArray);
  loopArrayToPutElement(newArray);
  setCellToBeMoved('');
  if (elementMoved) {
    setNewOperation(newArray, currentMetadata, newCommands);
  } else {
    ToastHelper.error("You can't move a parent node into its child");
  }
};
