import { ICategory, IDataset, IInsideDataSet } from '../..';
import { IInsideDataSetData } from '../../../../constant/chart.consts';
import { ChartType } from '../../../../constant/datasource.consts';
import { CHART_DATA_FONT, formattedDisplayValue, TChartData } from '../../../utils';

const STACK = {
  LEFT: 'stack_left',
  RIGHT: 'stack_right',
};

export const STACKED_CHARTS_COLORS = [
  '#7eceb0',
  '#40a781',
  '#e27973',
  '#8dbff2',
  '#bad9f7',
  '#ffe5b3',
  '#235c47',
  '#bd3629',
  '#e1f4ed',
  '#fcf2f1',
];

export const STACKED_COLORS = {
  CON1064: 'ffe5b3',
  CON1063: 'E27973',
  CON1059: 'bd3629',
  CON1069: '8dbff2',
  CON1058: '235c47',
  CON1056: '7eceb0',
};

export const formalizeStackedColumn2Configs = (params: TChartData) => {
  if (params.isUnion) {
    return multipleStackedColumn(params);
  }
  let reference = '';
  let dataset: Array<IDataset> = [
    { dataset: [] }, // left
    { dataset: [] }, // right
  ];
  let stack_left: Array<string> = [];
  let stack_right: Array<string> = [];
  let isOpening = !!params?.reportContentSetup?.otherParams?.displayOpening;
  let category: Array<ICategory> = [];
  if (params?.fiscalYearsInfo) {
    reference = Object.keys(params.fiscalYearsInfo)[0];
  }
  const beginData = isOpening ? 0 : 1;
  // headers
  if (params?.conceptValues?.header) {
    const { header } = params.conceptValues;
    for (let k = beginData; k < header.length; k++) {
      const label = params.conceptValues.header[k];
      category = [...category, { label }];
    }
  }

  if (params?.reportContentSetup?.otherParams) {
    stack_left = params.reportContentSetup.otherParams[STACK.LEFT];
    stack_right = params.reportContentSetup.otherParams[STACK.RIGHT];
  }
  if (params?.conceptValues?.valuesLegend) {
    const { values, valuesLegend } = params.conceptValues;
    let keys = Object.keys(valuesLegend);
    for (let k = 0; k < keys.length; k++) {
      const key = keys[k];
      const seriesname: string = valuesLegend[key];
      let _data: Array<IInsideDataSetData> = [];
      for (const [_key, value] of Object.entries(values)) {
        const _v = value as Array<string>;
        if (_key === key) {
          if (_v?.length) {
            for (let j = beginData; j < _v.length; j++) {
              _data = [
                ..._data,
                {
                  value: _v[j],
                  label: seriesname,
                  displayValue: formattedDisplayValue(_v[j]),
                },
              ];
            }
          }
        }
      }
      const color = STACKED_COLORS[key] ? STACKED_COLORS[key] : STACKED_CHARTS_COLORS[k];
      const insideDataset: Array<IInsideDataSet> = [{ seriesname, data: _data, color }];
      const isLeft = stack_left.includes(key);
      const isRight = stack_right.includes(key);
      if (isLeft) {
        dataset[0].dataset = [...dataset[0].dataset, ...insideDataset];
      } else if (isRight) {
        dataset[1].dataset = [...dataset[1].dataset, ...insideDataset];
      }
    }
  }
  return {
    reference,
    title: params.reportTitle,
    config: {
      type: ChartType.COLUMN_STACKED_2,
      dataLoadStartMessage: 'Please wait, chart is loading the data....',
      dataLoadStartMessageFontSize: '14',
      renderAt: 'chart-container',
      width: '100%',
      height: '400',
      dataFormat: 'json',
      dataSource: {
        chart: {
          plottooltext: '$seriesname : $displayValue',
          numberPrefix: '€',
          showxaxisline: '1',
          showyaxisline: '1',
          showSecondaryLimits: '0',
          showDivLineSecondaryValue: '0',
          ...CHART_DATA_FONT,
        },
        categories: [
          {
            category,
          },
        ],
        dataset,
      },
    },
  };
};

export const multipleStackedColumn = (params: TChartData) => {
  let reference = '';
  let dataset: Array<IDataset> = [
    { dataset: [] }, // left
    { dataset: [] }, // right
  ];
  let stack_left: Array<string> = [];
  let stack_right: Array<string> = [];
  let category: Array<ICategory> = [];
  let isOpening = !!params?.reportContentSetup?.otherParams?.displayOpening;
  let beginIterator = 0;
  if (params?.reportContentSetup?.otherParams) {
    stack_left = params.reportContentSetup.otherParams[STACK.LEFT];
    stack_right = params.reportContentSetup.otherParams[STACK.RIGHT];
  }
  // headers
  if (params?.conceptValues?.header) {
    const { header } = params.conceptValues;
    const headers = Object.values(header);
    const firstHeader = headers?.[0]?.toLowerCase();
    beginIterator = isOpening || !firstHeader.includes('opening') ? 0 : 1;
    for (let k = beginIterator; k < header.length; k++) {
      const label = params.conceptValues.header[k];
      category = [...category, { label }];
    }
  }
  if (params?.fiscalYearsInfo) {
    reference = Object.keys(params.fiscalYearsInfo)[0];
    if (params?.conceptValues?.valuesLegend) {
      const { values, valuesLegend } = params.conceptValues;
      let legendKeys = Object.keys(valuesLegend);
      for (let k = 0; k < legendKeys.length; k++) {
        const legendKey = legendKeys[k];
        const seriesname: string = valuesLegend[legendKey];
        let _data: Array<IInsideDataSetData> = [];
        for (const [_key, value] of Object.entries(values)) {
          const _v = value as Array<string>;
          if (_key === legendKey) {
            if (_v?.length) {
              for (let i = beginIterator; i < _v.length; i++) {
                _data = [
                  ..._data,
                  {
                    value: _v[i],
                    label: seriesname,
                    displayValue: formattedDisplayValue(_v[i]),
                  },
                ];
              }
            }
          }
        }
        const color = STACKED_COLORS[legendKey] ? STACKED_COLORS[legendKey] : STACKED_CHARTS_COLORS[k];
        const insideDataset: Array<IInsideDataSet> = [{ seriesname, data: _data, color }];
        const isLeft = stack_left.includes(legendKey);
        const isRight = stack_right.includes(legendKey);
        if (isLeft) {
          dataset[0].dataset = [...dataset[0].dataset, ...insideDataset];
        } else if (isRight) {
          dataset[1].dataset = [...dataset[1].dataset, ...insideDataset];
        }
      }
    }
  }
  return {
    reference,
    title: params.reportTitle,
    config: {
      type: ChartType.COLUMN_STACKED_2,
      dataLoadStartMessage: 'Please wait, chart is loading the data....',
      dataLoadStartMessageFontSize: '14',
      renderAt: 'chart-container',
      width: '100%',
      height: '400',
      dataFormat: 'json',
      dataSource: {
        chart: {
          plottooltext: '$seriesname : $displayValue',
          numberPrefix: '€',
          showxaxisline: '1',
          showyaxisline: '1',
          showSecondaryLimits: '0',
          showDivLineSecondaryValue: '0',
          exportFileName: params.reportTitle,
          ...CHART_DATA_FONT,
        },
        categories: [
          {
            category,
          },
        ],
        dataset,
      },
    },
  };
};
