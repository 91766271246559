// Style
import './style.scss';

import React, { useCallback, useState } from 'react';

import DropDownList from '../../../elements/DropDownList';
import SearchComponent from '../../../elements/SearchComponent';
import { WorkspaceTypes } from '../../../shared/constant/workspaces.consts';
import { FILTER_LABEL } from '../../../utils/constant';

interface IWorkspaceFilterBarProps {
  addWorkspaceClicked: () => void;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  onFilter: (wsType: string) => void;
  deleteButton?: boolean;
  searchResults: Array<any>;
  onDelete: () => void;
  selectAllFilter: () => void;
  isAllSelected?: boolean;
}

/**
 * Workspace header filter bar component
 */

// Used when you have a list of data like with the left menu of data source
const WorkspaceFilterBar: React.FC<IWorkspaceFilterBarProps> = ({
  addWorkspaceClicked,
  searchValue,
  searchResults,
  setSearchValue,
  selectAllFilter,
  onFilter,
  deleteButton,
  onDelete,
  isAllSelected,
}) => {
  // States
  const [selectedType, setSelectedType] = useState<string>(WorkspaceTypes[0]);

  const handleSelect = useCallback((type: string) => {
    setSelectedType(type);
    onFilter(type);
  }, []);

  return (
    <div className="workspaceFitlerBarContainer">
      <div className="buttonsPart">
        <button id="addWorkspaceBtn" className="button--green" onClick={addWorkspaceClicked}>
          {FILTER_LABEL.CREATE}
        </button>
        {deleteButton && (
          <button id="deleteWorkspaceBtn" className="button--red" onClick={onDelete}>
            {FILTER_LABEL.DELETE}
          </button>
        )}
      </div>

      <div className="filterPart">
        <SearchComponent
          hasCloseIcon={false}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          noShowResult={true}
        />
        {0 < searchResults.length && (
          <button
            id="addWorkspaceBtn"
            className={`select-all-filter button--white ${isAllSelected && 'select-all-border'}`}
            onClick={selectAllFilter}>
            {isAllSelected ? FILTER_LABEL.DESELECT_ALL : FILTER_LABEL.SELECT_ALL}
          </button>
        )}
        <DropDownList
          elements={WorkspaceTypes}
          selectedElements={[selectedType]}
          onItemClick={handleSelect}
          selectedItemLabelPrefix="Workspace: "
        />
      </div>
    </div>
  );
};

export default WorkspaceFilterBar;
