// Style
import './style.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';

import rightArrow from '../../assets/img/chevron-right.svg';
import CollectionItem from '../../components/reportComponents/MainMenu/DataPartMenu/CollectionItem';
import TemplateItem from '../../components/reportComponents/MainMenu/DataPartMenu/TemplateItem';
import { isDisabled, isItemOpen } from '../../shared/helpers/string/classnames.helper';
import { refactName } from '../../utils/common';
import { REPORT_LIST_TYPE } from '../../utils/enum';

interface IReportSelectListProps {
  selectedItemName: string;
  setSelectedItem: (id: string) => void;
  disabled?: boolean;
  isFetching: boolean;
  placeholder?: string;
  setIsDropDownOpen?: (val: boolean) => void;
  groupedElements: NS_REPORT.IGroupedSelectList;
  type?: string;
  collections?: NS_REPORT.ITypeCollections;
}

/**
 * Select list container with grouped items
 *
 * @param param IReportSelectListProps props
 */

const ReportSelectList: React.FC<IReportSelectListProps> = ({
  selectedItemName,
  setSelectedItem,
  disabled = false,
  isFetching,
  setIsDropDownOpen,
  groupedElements,
  placeholder = 'Nothing selected',
  type,
  collections,
}) => {
  // Refs
  const ref = useRef<HTMLDivElement>(null);

  // States
  const [openDispositionDropdown, setOpenDispositionDropdown] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleEscapePress);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleEscapePress);
    };
  }, []);

  useEffect(() => {
    if (disabled) {
      closeDropdown();
    }
  }, [disabled]);

  useEffect(() => {
    if (setIsDropDownOpen) {
      setIsDropDownOpen(openDispositionDropdown);
    }
  }, [openDispositionDropdown]);

  const handleClickOutside = useCallback(
    (event) => {
      if (!(ref.current as any).contains(event.target)) {
        closeDropdown();
      }
    },
    // eslint-disable-next-line
    [ref.current],
  );

  const handleEscapePress = (e) => {
    if (e.key === 'Escape') {
      closeDropdown();
    }
  };

  const handleOpenDispositionDropDown = () => {
    if (!disabled) {
      setOpenDispositionDropdown(!openDispositionDropdown);
    }
  };
  const groupedElemenetsArray = Object.entries(groupedElements);
  const lastElement = groupedElemenetsArray.length - 1;

  const closeDropdown = useCallback(() => {
    setOpenDispositionDropdown(false);
  }, []);
  const renderDefault = () => {
    return (
      <div className={`select-list-body ${!openDispositionDropdown && 'select-list-hide-body'}`}>
        {openDispositionDropdown && groupedElements && isFetching ? (
          <>
            <div className="intermediateTitle">Fetching...</div>
          </>
        ) : (
          openDispositionDropdown &&
          groupedElements &&
          !isFetching && (
            <>
              {groupedElemenetsArray.map(([groupName, elementsOfGroup], index) => {
                const key = `${groupName}-${index}`;
                return (
                  <React.Fragment key={key}>
                    <div className="intermediateTitle" key={groupName}>
                      {groupName}
                    </div>
                    {elementsOfGroup.map((el) => (
                      <div
                        className={`listElement ${el.id === selectedItemName && 'active'}`}
                        key={el.id}
                        title={`${el.name}`}
                        onClick={() => {
                          if (!disabled && !el?.isDisable) {
                            setSelectedItem(el.id);
                            closeDropdown();
                          }
                        }}>
                        <div className={`toolBarText ${el?.isDisable && 'div--disabled'}`}>{el.name}</div>
                      </div>
                    ))}
                    {index < lastElement && (
                      <div className="select-list-separator">
                        <div className="select-list-spacer"></div>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </>
          )
        )}
      </div>
    );
  };

  const renderTemplate = () => {
    return (
      <TemplateItem
        selectedItemName={selectedItemName}
        setSelectedItem={setSelectedItem}
        disabled={disabled}
        groupedElements={groupedElements}
        isFetching={isFetching}
        openDispositionDropdown={openDispositionDropdown}
        closeDropdown={closeDropdown}
      />
    );
  };

  const renderCollection = () => {
    return (
      <CollectionItem
        collections={collections}
        selectedItemName={selectedItemName}
        setSelectedItem={setSelectedItem}
        disabled={disabled}
        isFetching={isFetching}
        openDispositionDropdown={openDispositionDropdown}
        closeDropdown={closeDropdown}
      />
    );
  };
  const isDefault = !type || (type && type !== REPORT_LIST_TYPE.TEMPLATE && type !== REPORT_LIST_TYPE.COLLECTION);
  return (
    <div ref={ref} className={`groupingSelectList ${isDisabled(disabled)} ${isItemOpen(openDispositionDropdown)}`}>
      <div className="select-list-header">
        {selectedItemName === '' ? (
          <div className="selectedElement" key={-1} onClick={handleOpenDispositionDropDown}>
            <div className="toolBarText">{placeholder}</div>
            <ReactSVG src={rightArrow} className={`svg-wrapper rightArrow`} />
          </div>
        ) : (
          <div className="selectedElement" onClick={handleOpenDispositionDropDown}>
            <div className="toolBarText" title={selectedItemName}>
              {refactName(selectedItemName)}
            </div>
            <ReactSVG src={rightArrow} className={`svg-wrapper rightArrow`} />
          </div>
        )}
      </div>
      {type && type === REPORT_LIST_TYPE.TEMPLATE && renderTemplate()}
      {type && type === REPORT_LIST_TYPE.COLLECTION && renderCollection()}
      {isDefault && renderDefault()}
    </div>
  );
};

export default ReportSelectList;
