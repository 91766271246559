// Style
import './style.scss';

import React, { useCallback, useContext } from 'react';
import { ReactSVG } from 'react-svg';

import RedCrossSVG from '../../../assets/img/cross-red.svg';
import RestoreSVG from '../../../assets/img/undo.svg';
import { ReportContext } from '../../../context/ReportContext';
import { GraphCommandOperationName } from '../../../shared/helpers/report/reports.helper';

interface IHistory {
  setOpen: (val: boolean) => void;
  reportName: string;
}

/**
 * Container used to chose which version of tree you want
 *
 * interface IHistory {}
 * @param param NS_Tabs.ITab props
 * React.FC<IHistory>
 */

const History: React.FC<IHistory> = ({ setOpen, reportName }) => {
  const { timeline, current, setVersion } = useContext(ReportContext);

  const handleOnHistory = (historyVersion) => () => {
    setVersion(historyVersion);
  };

  const getAccountName = (accountId: string, index) => {
    let accountName = accountId;
    const loopArray = (child: NS_API.IGraph) => {
      child.forEach((item) => {
        if (accountId === item.data.content.rowContentId) {
          accountName = `${item.data.content.rowContentName}`;
        } else if (item.data.content.rowContentType !== 'N' && item.children) {
          loopArray(item.children);
        }
      });
    };
    loopArray(timeline[index].tree);
    return accountName;
  };

  const getParentNameOfAccount = (accountId: string, index) => {
    let accountName = accountId;
    const loopArray = (child: NS_API.IGraph) => {
      child.children.forEach((item) => {
        if (accountId === item.data.content.rowContentId) {
          accountName = `${child.data.content.rowContentName}`;
        } else if (item.data.content.rowContentType !== 'N' && item.children) {
          loopArray(item);
        }
      });
    };
    timeline[index].tree.forEach((tree) => {
      loopArray(tree);
    });
    return accountName;
  };

  const createMessageCommande = (commands, metadatas, key) => {
    if (
      (key > 1 && Object.keys(timeline[key - 1].commands).length === Object.keys(commands).length) ||
      (key === 1 && metadatas.length > 0)
    ) {
      let metadata;
      if (metadatas[0]) {
        metadata = metadatas[Object.keys(metadatas)[Object.keys(metadatas).length - 1]];
      } else {
        metadata = { type: '' };
      }
      return `Changed style of ${metadata.conceptId}`;
    } else {
      let command;
      if (commands[0]) {
        command = commands[Object.keys(commands)[Object.keys(commands).length - 1]];
      } else {
        command = { type: '' };
      }
      switch (command.type) {
        case 'add-child':
          return `Added a child to "${getAccountName(command.parameters.selectedNodeId, key)}"`;
        case 'add-parent':
          let listChild = '';
          command.parameters.selectedNodesIds.forEach((account, index) => {
            if (command.parameters.selectedNodesIds.length - 1 === index) {
              listChild += `${getAccountName(account, key)}`;
            } else {
              listChild += `${getAccountName(account, key)}, `;
            }
          });
          return `Added a parent to "${listChild}"`;
        case 'add-account':
          let listAccount = '';
          if (command.parameters.selectedAccounts.length > 1) {
            command.parameters.selectedAccounts.forEach((account, index) => {
              if (command.parameters.selectedAccounts.length - 1 === index) {
                listAccount += `${account.accountName}`;
              } else {
                listAccount += `${account.accountName}, `;
              }
            });
          } else {
            listAccount = command.parameters.selectedAccounts[0].accountName;
          }
          return `Added account "${listAccount}" to "${getAccountName(command.parameters.destinationNodeId, key)}"`;
        case 'deep-delete':
          return `Deleted '${getAccountName(command.parameters.selectedNodeId, key - 1)}' and all its children`;
        case 'simple-delete':
          return `Deleted '${getAccountName(command.parameters.selectedNodeId, key - 1)}'`;
        case GraphCommandOperationName.DELETE_ACCOUNTS:
          let listAccountToDelete = '';
          command.parameters.accountIdsToDelete.forEach((account, index) => {
            if (index === command.parameters.accountIdsToDelete.length - 1) {
              listAccountToDelete += getAccountName(account, key - 1);
            } else {
              listAccountToDelete += `${getAccountName(account, key - 1)}, `;
            }
          });
          return `Deleted ${listAccountToDelete}`;
        case 'move':
          let destinationName = '';
          if (command.parameters.moveAs === 'sibling') {
            destinationName = getParentNameOfAccount(command.parameters.destinationNodeId, key);
          } else {
            destinationName = getAccountName(command.parameters.destinationNodeId, key);
          }
          return `Moved "${getAccountName(command.parameters.originNodeId, key)}" to "${destinationName}"`;
        case 'move-account':
          return `Moved account "${getAccountName(command.parameters.selectedNodeId, key)}" to "${getAccountName(
            command.parameters.destinationNodeId,
            key,
          )}"`;
        case 'move-account-entries':
          return `Moved entries of "${getAccountName(command.parameters.accountNodeId, key)}" to "${getAccountName(
            command.parameters.destinationNodeId,
            key,
          )}"`;
        case 'update-metadata':
          if (command.parameters.metadata.name) {
            return `Changed "${getAccountName(command.parameters.selectedNodeId, key - 1)}" to "${
              command.parameters.metadata.name
            }"`;
          } else {
            return `Change properties of "${getAccountName(command.parameters.selectedNodeId, key)}"`;
          }
        case 'collapse':
          return 'noDisplay';
        default:
          return 'Initial Report';
      }
    }
  };

  const displayListCommands = () => {
    let indexDisplayed = 0;
    return timeline.map((version, index) => {
      if (createMessageCommande(version.commands, version.metadata, index) !== 'noDisplay') {
        indexDisplayed++;
        const key = index + 's';
        return (
          <div
            className={`actionRecord ${indexDisplayed % 2 === 0 ? 'odd' : 'even'} ${index > current && 'deprecated'}`}
            key={key}>
            <span className="leftPart">
              {indexDisplayed} - {createMessageCommande(version.commands, version.metadata, index)}
            </span>
            <div className="rightPart" onClick={handleOnHistory(index)}>
              <span>Restore this version</span>
              <ReactSVG src={RestoreSVG} className="svg-wrapper restoreSVG" />
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  const handleCross = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <div className="historyContainer">
      <div className="header">
        <ReactSVG src={RedCrossSVG} className="svg-wrapper redCrossSVG" onClick={handleCross} />
        <p className={'title'}>History</p>
        <p className="reportName">{reportName}</p>
      </div>
      <div className="body">
        <p className="description">List of all changes made on your Report.</p>
        {current >= 0 && <div className="historyTable">{displayListCommands()}</div>}
      </div>
    </div>
  );
};

export default History;
