import './style.scss';

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';

import CopySVG from '../../../../assets/img/copy.svg';
import ReloadSVG from '../../../../assets/img/reload.svg';
import SupportSVG from '../../../../assets/img/support.svg';
import TrashSVG from '../../../../assets/img/trash-red.svg';
import UnionSVG from '../../../../assets/img/union.svg';
import { WorkspaceContext } from '../../../../context/WorkspaceContext';
import Checkbox from '../../../../elements/Checkbox';
import CustomModalWarning from '../../../../elements/CustomModalWarning';
import { useAppSelector } from '../../../../redux/hook';
import { getDatasource } from '../../../../shared/api/dataSource';
import { INTERPRETATION_DONE, states } from '../../../../shared/constant/datasource.consts';
import { renderContent } from '../../../../shared/helpers/helpers';
import ToastHelper from '../../../../shared/helpers/toast/ToastHelper';
import { DATA_SOURCE_MESSAGE } from '../../../../utils/constant';
import useDatasourceInformation from './datasourceInformation';

interface ITableRow {
  selected: boolean;
  checked: boolean;
  datasource: NS_Workspace.IDataSourcesFile;
  workspaceId: string;
  onClickSupport: (val: NS_Workspace.IDataSourcesFile) => void;
  onClickCreateReport: (val: NS_Workspace.IDataSourcesFile) => void;
  onClickCard: (val: NS_Workspace.IDataSourcesFile) => void;
  onClickSourceFile: (val: NS_Workspace.IDataSourcesFile) => void;
  onClickCheckbox: (val: NS_Workspace.IDataSourcesFile) => void;
  onValidateNameChange: (oldName: string, newName: string, ds: NS_Workspace.IDataSourcesFile) => void;
}

interface IDSInterpreted {
  isDone: boolean;
  isError?: boolean;
  message?: string;
}

const TableRow: React.FC<ITableRow> = ({
  selected,
  datasource,
  checked,
  workspaceId,
  onClickSupport,
  onClickCreateReport,
  onClickCard,
  onClickSourceFile,
  onClickCheckbox,
  onValidateNameChange,
}) => {
  // Context
  const { addGeneratedDataSource, editDatasource } = useContext(WorkspaceContext);

  const notifications = useAppSelector((state) => state.datasource.notifications);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [displayingFileName, setDisplayingFileName] = useState<string>('');
  const [isDataSourceValidated, setIsDataSourceValidated] = useState<boolean>(false);
  const { informationLoading } = useDatasourceInformation({ datasource }) as any;

  const [dSInterpreted, setDSInterpreted] = useState<IDSInterpreted>({
    message: '',
    isDone: false,
    isError: false,
  });

  useEffect(() => {
    setDisplayingFileName(datasource.displayName);
  }, [editMode]);

  useEffect(() => {
    if (datasource) {
      if (datasource?.structurationStatus) {
        if (datasource.structurationStatus === states.GENERATED && datasource.status === `${states.GENERATED}`) {
          setDSInterpreted({
            isDone: true,
          });
        } else if (
          datasource.structurationStatus === states.PROCESSING ||
          datasource.status === `${states.PROCESSING}`
        ) {
          setDSInterpreted({ isDone: false });
        }
      }
    }
  }, [datasource]);

  useEffect(() => {
    if (notifications?.length && datasource?.fileId) {
      const currentDSNotif = notifications.filter((n) => n.datasourceId === datasource.fileId);
      if (currentDSNotif?.length) {
        const isDone = currentDSNotif.find(
          (n) => n?.notificationMessage && n.notificationMessage === INTERPRETATION_DONE,
        );
        const isError = currentDSNotif.find((n) => n?.errorMessage && 0 < n.errorMessage.length);
        if (isDone) {
          setDSInterpreted({
            isDone: true,
          });
          getFinalizeDatasource(datasource).catch((err) => ToastHelper.error(err));
        } else if (isError) {
          setDSInterpreted({
            isDone: true,
            isError: true,
            message: isError?.errorMessage ?? 'Error while getting datasource',
          });
        } else if (currentDSNotif?.length && currentDSNotif?.[currentDSNotif.length - 1]?.notificationMessage) {
          setDSInterpreted({
            isDone: false,
            message: currentDSNotif[currentDSNotif.length - 1].notificationMessage,
          });
        }
      }
    }
  }, [notifications]);

  const getFinalizeDatasource = async (datasource: NS_Workspace.IDataSourcesFile) => {
    const newDatasourceInfo = await getDatasource(workspaceId, datasource.fileId);
    addGeneratedDataSource(newDatasourceInfo);
    editDatasource(newDatasourceInfo.fileId, { ...newDatasourceInfo, structurationStatus: states.GENERATED });
    return newDatasourceInfo;
  };

  const handleClickOutside = useCallback(() => {
    setEditMode(false);
  }, []);

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      onValidateNameChange(datasource.displayName, displayingFileName, datasource);
      datasource.displayName = displayingFileName;
      setEditMode(false);
    }
    if (event.key === 'Escape') {
      setEditMode(false);
    }
  };

  const toggleCheckbox = () => {
    onClickCheckbox(datasource);
  };

  const handleNameChange = useCallback((e) => {
    setDisplayingFileName(e.target.value);
  }, []);

  const handleClickSourceFile = () => {
    onClickSourceFile(datasource);
  };

  const handleClickCreateReport = () => {
    if (!datasource.isCardValidated) {
      setIsDataSourceValidated(true);
      return;
    }
    onClickCreateReport(datasource);
  };

  const handleClickCard = () => {
    onClickCard(datasource);
  };

  const handleClickSupport = () => {
    onClickSupport(datasource);
  };

  const renderBadgeColorClass = useMemo(() => {
    const statusValidated = 'tableRow_status_badge--green';
    const statusError = 'tableRow_status_badge--red';
    if (dSInterpreted.isError) {
      return statusError;
    }
    if (dSInterpreted?.isDone) {
      return statusValidated;
    }
    switch (datasource.structurationStatus) {
      case states.DEFAULT:
        return 'tableRow_status_badge--yellow';
      case states.PROCESSING:
        return 'tableRow_status_badge--blue';
      case states.ERROR:
        return statusError;
      case states.GENERATED:
      default:
        return statusValidated;
    }
  }, [datasource, dSInterpreted]);

  const renderActionPart = () => {
    const loading = (
      <div className="actionState actionState--processing">
        Processing...
        <ReactSVG src={ReloadSVG} className="svg-wrapper reloadSVG" />
      </div>
    );
    if (datasource.structurationStatus === undefined) {
      return loading;
    }
    const error = (
      <div className="actionState actionState--error">
        <p>{dSInterpreted?.message ?? 'Please try again or contact support'}</p>
        <button className="action_btn action_btn--support button--white" onClick={handleClickSupport}>
          Support
          <ReactSVG src={SupportSVG} className="svg-wrapper supportSVG" />
        </button>
      </div>
    );
    switch (datasource.structurationStatus) {
      case states.ERROR:
        return error;
      case states.DELETING:
        return (
          <div className="actionState actionState--processing">
            DELETING...
            <ReactSVG src={TrashSVG} className="svg-wrapper trashSVG" />
          </div>
        );
    }
    const interpreted = (
      <>
        {' '}
        <button
          className={`action_btn action_btn--card button--white ${
            !datasource.isCardValidated ? 'card_not_validated' : ''
          } action_btn_card`} // check if datasource validated
          onClick={handleClickCard}
          data-cy={`action-card-${datasource.displayName}`}
          disabled={informationLoading}>
          Card
        </button>
        <div
          className={`reportCreatorButton ${informationLoading && 'div--disabled'}`}
          onClick={handleClickCreateReport}>
          <ReactSVG src={CopySVG} className="svg-wrapper copySVG" />
        </div>
      </>
    );
    const isError = dSInterpreted?.isDone && dSInterpreted?.isError;
    const isFinish = dSInterpreted?.isDone && !dSInterpreted.isError;
    const isPending = !dSInterpreted?.isDone && dSInterpreted?.message;
    const message = (
      <div className="actionState actionState--processing">
        {dSInterpreted?.message ?? 'Processing ...'}
        {!dSInterpreted.isError && <ReactSVG src={ReloadSVG} className="svg-wrapper reloadSVG" />}
      </div>
    );

    if (isPending) {
      return message;
    }

    if (isError) {
      return error;
    }

    if (isFinish) {
      return interpreted;
    }
    switch (datasource.structurationStatus) {
      case states.PROCESSING:
        return loading;
      case states.GENERATED:
        return interpreted;
    }
  };

  const handleConfirmModal = useCallback(() => {
    setIsDataSourceValidated(false);
    onClickCard(datasource);
  }, []);

  return (
    <>
      {isDataSourceValidated && (
        <CustomModalWarning
          description={DATA_SOURCE_MESSAGE.CONFIRM_DS_DESCRIPTION}
          confirmLabel={DATA_SOURCE_MESSAGE.CONFIRM_DS_LABEL}
          onConfirm={handleConfirmModal}
        />
      )}
      <div
        className={`tableRow ${renderContent(selected, 'tableRow--selected', '')}`}
        data-cy={`${datasource.displayName}-line`}>
        <div
          className={`tableRow_cell tableRow_checkbox ${renderContent(checked, 'tableRow_checkbox--checked', '')}`}
          data-cy={`${datasource.displayName}-checkbox`}>
          <Checkbox checked={checked} toggle={toggleCheckbox} />
        </div>

        {renderContent(
          editMode,
          <input
            className={`tableRow_cell tableRow_name--edit ${renderContent(
              displayingFileName === '',
              'tableRow_name--edit--error',
              '',
            )}`}
            value={displayingFileName}
            autoFocus
            onKeyDown={handleEnter}
            onChange={handleNameChange}
            maxLength={30}
            onBlur={handleClickOutside}
          />,
          <div
            className={`tableRow_cell tableRow_name--read ${
              datasource.structurationStatus === states.PROCESSING && 'div--disabled'
            }`}
            onDoubleClick={(_e) => {
              if (datasource.structurationStatus !== states.PROCESSING) setEditMode(true);
            }}>
            <p title={datasource.displayName}>{datasource.displayName}</p>
          </div>,
        )}

        <div className="tableRow_cell tableRow_status">
          <div className={`tableRow_status_badge ${renderBadgeColorClass}`} />
        </div>

        <div className="tableRow_cell tableRow_file">
          <div className="tableRow_file_content" title={datasource.sourceFile} onClick={handleClickSourceFile}>
            {renderContent(
              datasource.unionInfo !== undefined,
              <>
                <ReactSVG src={UnionSVG} className="svg-wrapper unionSVG" />
                <span>Union File</span>
              </>,
              datasource.sourceFile,
            )}
          </div>
        </div>

        <div className="tableRow_cell tableRow_actions">{renderActionPart()}</div>
      </div>
    </>
  );
};

export default TableRow;
