// Style
import './style.scss';

import React, { useState, useEffect, useContext } from 'react';

import { ReportContext } from '../../../../context/ReportContext';
import { MappingCommandsParams } from '../../../../shared/constant/mapping.consts';
import { dataDestructuring } from '../../../../shared/helpers/tree/front.tree.util';

import RedCrossSVG from '../../../../assets/img/cross-red.svg';
import ToastHelper from '../../../../shared/helpers/toast/ToastHelper';

interface IEntriesMapModuleProps {
  entriesSelectedForCoverage: Array<NS_REPORT.IEntriesSelectedForCoverageProps>;
  setEntriesSelectedForCoverage: (val: Array<NS_REPORT.IEntriesSelectedForCoverageProps>) => void;
  setIsMapOpen: (val: boolean) => void;
  nameOfNodeSelected: NS_REPORT.INameSelectedForCoverageProps;
  setNameOfNodeSelected: (val: NS_REPORT.INameSelectedForCoverageProps) => void;
  mappingTree: NS_API.IGraph;
  datasourceId: string;
  savingTree: boolean;
  saveTemplate: () => void;
  setTypeOfVisualisation: (val: string) => void;
}

interface IEntriesByAccount {
  [accountId: string]: number[];
}

/**
 * Container used to display a report
 *
 * @param param NS_Tabs.ITab props
 */

const EntriesMapModule: React.FC<IEntriesMapModuleProps> = ({
  entriesSelectedForCoverage,
  setEntriesSelectedForCoverage,
  setIsMapOpen,
  nameOfNodeSelected,
  setNameOfNodeSelected,
  mappingTree,
  datasourceId,
  savingTree,
  saveTemplate,
  setTypeOfVisualisation,
}) => {
  const [entriesByAccountId, setEntriesByAccountId] = useState<IEntriesByAccount>({});
  const [confirmMove, setConfirmMove] = useState<boolean>(false);

  const { setNewOperation, currentCommands, currentMetadata, currentAccountIdsWithMovedEntries } =
    useContext(ReportContext);

  useEffect(() => {
    const newEntriesByAccount: IEntriesByAccount = {};
    entriesSelectedForCoverage.forEach((entry) => {
      if (entry.splitId !== '') {
        if (Object.keys(newEntriesByAccount).includes(`${entry.accountId}-${entry.splitId}`)) {
          newEntriesByAccount[`${entry.accountId}-${entry.splitId}`].push(entry.index);
        } else {
          if (entry.splitId) {
            newEntriesByAccount[`${entry.accountId}-${entry.splitId}`] = [entry.index];
          } else {
            newEntriesByAccount[entry.accountId] = [entry.index];
          }
        }
      } else {
        if (Object.keys(newEntriesByAccount).includes(`${entry.accountId}`)) {
          newEntriesByAccount[`${entry.accountId}`].push(entry.index);
        } else {
          newEntriesByAccount[entry.accountId] = [entry.index];
        }
      }
    });
    setEntriesByAccountId(newEntriesByAccount);
  }, [entriesSelectedForCoverage.length]);

  useEffect(() => {
    if (confirmMove && nameOfNodeSelected.accountId !== '') {
      const newCommands = dataDestructuring(currentCommands);
      let accountAlreadyMove = false;
      for (const value of Object.values(newCommands)) {
        const newValue: any = value as any;
        if (newValue.type === 'move-account-entries') {
          if (
            entriesSelectedForCoverage.findIndex(
              (e) => (e.splitId ? `${e.accountId}-${e.splitId}` : e.accountId) === newValue.parameters.accountNodeId,
            ) > -1
          ) {
            accountAlreadyMove = true;
          }
        }
      }
      if (accountAlreadyMove) {
        saveTemplate();
      } else {
        endMoveElement();
      }
    }
  }, [confirmMove, nameOfNodeSelected]);

  useEffect(() => {
    if (confirmMove && !savingTree) {
      endMoveElement();
    }
  }, [savingTree]);

  // will move the element previously selected
  const endMoveElement = () => {
    let elementToBeMoved;
    let elementMoved = '';
    let parentOfElementToBeMoved;
    let elementToRestore;
    let positionToRestore;
    const newCommands = dataDestructuring(currentCommands);
    const newAccountIdsWithMovedEntries = [...currentAccountIdsWithMovedEntries];

    const loopArrayToRestoreElement = (child: NS_API.IGraph) => {
      if (child.length === 0) {
        child.push(elementToRestore);
      } else {
        child.forEach((item) => {
          if (item.parent === elementToRestore.parent) {
            child.splice(positionToRestore, 0, elementToRestore);
          } else if (item.id === elementToRestore.parent) {
            item.children.splice(positionToRestore, 0, elementToRestore);
          } else if (item.data.content.rowContentType !== 'N' && item.children) {
            loopArrayToRestoreElement(item.children);
          }
        });
      }
    };
    const loopArrayToGetElement = (child: NS_API.IGraph, accountId) => {
      child.forEach((item, index) => {
        item.children.forEach((itemChild) => {
          if (itemChild.data.content.rowContentId === accountId) {
            parentOfElementToBeMoved = item;
          }
        });
        if (item.data.content.rowContentId === accountId) {
          elementToBeMoved = item;
          elementToBeMoved.data.content.value = [0];
          elementToRestore = item;
          positionToRestore = index;
          newAccountIdsWithMovedEntries.push(accountId);
        } else if (item.data.content.rowContentType !== 'N' && item.children) {
          loopArrayToGetElement(item.children, accountId);
        }
      });
    };
    const loopArrayToPutElement = (child: NS_API.IGraph, accountId: string, listOfEntries, nodeSelected) => {
      if (elementMoved === accountId) {
        return;
      }
      child.forEach((item) => {
        if (item.data.content.rowContentId === nodeSelected.accountId && elementMoved !== accountId) {
          const commandsKeys = Object.keys(newCommands);
          const childrenIdsAndNamesOfParentNodeOfSelectedNode: {} = {};
          childrenIdsAndNamesOfParentNodeOfSelectedNode[elementToBeMoved.data.content.rowContentId] =
            elementToBeMoved.data.content.rowContentName;

          if (parentOfElementToBeMoved) {
            parentOfElementToBeMoved.children.forEach((childOfParentToBeMoved) => {
              childrenIdsAndNamesOfParentNodeOfSelectedNode[`${childOfParentToBeMoved.data.content.rowContentId}`] =
                childOfParentToBeMoved.data.content.rowContentName;
            });
          }

          const childrenIdsAndNamesOfDestinationNode: {} = {};
          item.children.forEach((itemChild) => {
            childrenIdsAndNamesOfDestinationNode[itemChild.data.content.rowContentId] =
              itemChild.data.content.rowContentName;
          });
          newCommands[commandsKeys.length === 0 ? 0 : parseInt(commandsKeys[commandsKeys.length - 1]) + 1] = {
            type: MappingCommandsParams.moveEntries,
            parameters: {
              accountEntriesToMove: listOfEntries,
              accountNodeId: elementToBeMoved.data.content.rowContentId,
              parentAccountNodeId: parentOfElementToBeMoved.data.content.rowContentId,
              idsAndNamesOfChildrenOfParentAccountNode: childrenIdsAndNamesOfParentNodeOfSelectedNode,
              destinationNodeId: nodeSelected.accountId,
              idsAndNamesOfChildrenOfDestinationNode: childrenIdsAndNamesOfDestinationNode,
              datasourceId: datasourceId,
            },
          };
          newAccountIdsWithMovedEntries.push(nodeSelected.accountId);
          elementMoved = accountId;
        } else if (item.data.content.rowContentType !== 'N' && item.children) {
          loopArrayToPutElement(item.children, accountId, listOfEntries, nodeSelected);
        }
      });
    };
    const lastKey = Object.keys(entriesByAccountId);
    const newArray = dataDestructuring(mappingTree);
    for (const [key, value] of Object.entries(entriesByAccountId)) {
      loopArrayToGetElement(newArray, key);
      loopArrayToPutElement(newArray, key, value, nameOfNodeSelected);
      if (lastKey[lastKey.length - 1] === key) {
        setNewOperation(newArray, currentMetadata, newCommands, newAccountIdsWithMovedEntries);
        ToastHelper.success('Entries succesfully moved');
        setEntriesSelectedForCoverage([]);
        setNameOfNodeSelected({ accountId: '', accountName: '' });
        setConfirmMove(false);
        setTypeOfVisualisation('');
      }
    }
  };

  return (
    <div className="entriesMapModuleContainer">
      <div className="header">
        <div className="leftPart">
          <img src={RedCrossSVG} alt="exit" onClick={() => setIsMapOpen(false)} className="exitCross" />
          Move
        </div>
        <div className="rightPart">
          {entriesSelectedForCoverage.length} {entriesSelectedForCoverage.length > 1 ? 'Entries' : 'Entry'} selected
        </div>
      </div>
      <div className="body">
        <div className="rightPart">
          <div
            className={`${
              (nameOfNodeSelected.accountId === '' || !entriesSelectedForCoverage.length) && 'contentEmpty'
            } content`}>
            <p className={'label'}>Add selected entries to</p>
            <div className="selectedField">
              <p>
                {nameOfNodeSelected.accountId === ''
                  ? 'Select a field on the left table'
                  : nameOfNodeSelected.accountName}
              </p>
            </div>
            <button
              onClick={(e) => {
                if (nameOfNodeSelected.accountId !== '' && entriesSelectedForCoverage.length) {
                  setConfirmMove(true);
                  e.stopPropagation();
                }
              }}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntriesMapModule;
