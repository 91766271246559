import '../../../base.scss';
import './style.scss';

import ContentLoader from 'react-content-loader';

const Loader = (props: any) => (
  <div className="loader-wrapper w-100">
    <ContentLoader speed={2} viewBox="0 0 662 315" backgroundColor="#e8e8e8" foregroundColor="#f2f2f2" {...props}>
      <rect x="28" y="23" width="348" height="12" rx="6" fill="#F2F2F2" />
      <rect x="88" y="279" width="348" height="12" rx="6" fill="#F2F2F2" />
      <circle cx="622" cy="30" r="13.5" />
      <g clip-path="url(#clip0_116_21208)">
        <rect x="622" y="13" width="27" height="27" rx="13.5" fill="#F2F2F2" />
      </g>
      <rect x="88.5" y="154.5" width="348" height="1" rx="6" fill="#F2F2F2" />
      <rect x="88.5" y="235.5" width="348" height="1" rx="6" fill="#F2F2F2" />
      <rect x="88.5" y="54" width="1" height="182" rx="6" fill="#F2F2F2" />
      <defs>
        <clipPath id="clip0_116_21208">
          <rect width="27" height="27" fill="white" transform="translate(622 13)" />
        </clipPath>
      </defs>
    </ContentLoader>
  </div>
);

export default Loader;
