// Style
import './style.scss';

import React, { useCallback, useEffect, useState } from 'react';

import { WorkspaceContext } from '../../context/WorkspaceContext';
import CustomModal from '../../elements/CustomModal';

interface IExportSettings {
  closeModal: () => void;
  handleSave: (fileName: string, reportsToExport: IReportsToExport[]) => void;
  reports: NS_Workspace.ISheet[];
  waitingAPI: boolean;
}

interface IReportsToExportOptions {
  includeDatasourceLines: boolean;
  includeGraph: boolean;
}

export interface IReportsToExport {
  reportId: string;
  options: IReportsToExportOptions;
}

/**
 * Component used to configure an exported file
 */

const ExportSettings: React.FC<IExportSettings> = ({ closeModal, handleSave, reports, waitingAPI }) => {
  const initialReportsToExport = reports.map((report) => ({
    reportId: report.id ?? '',
    options: {
      includeDatasourceLines: false,
      includeGraph: false,
    },
    worksheetName: report.name,
  }));

  const initialFileName = reports.length === 1 ? reports[0].name.substring(0, 30) : '';

  // States
  const [errorName, setErrorName] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>(initialFileName);
  const [reportsToExport, setReportsToExport] = useState<IReportsToExport[]>(initialReportsToExport);

  // Context
  const { exportedFiles } = React.useContext(WorkspaceContext);

  // UseEffect

  useEffect(() => {
    if (isFileAlreadyExist(initialFileName + '.xlsx')) {
      setErrorName('A report with same name already exists!');
      setError(true);
    }
  }, []);

  useEffect(() => {
    // Prevent '? = & # / + %' when using http params
    const isValid: boolean = fileName.search(/[\*\x22:<>[\]{\}\\`'@$&#=?+%/|]/g) === -1;
    if (!fileName) {
      setErrorName('Please fill the file name field.');
      setError(true);
    } else if (!isValid) {
      setErrorName(`File name cannot contain any of the following characters: *":<>[]{}\\\`'@$&#=?+%/|`);
      setError(true);
    } else if (isFileAlreadyExist(fileName + '.xlsx')) {
      setErrorName('A report with same name already exists!');
      setError(true);
    } else {
      setError(false);
    }
  }, [fileName]);

  // Functions

  const isFileAlreadyExist = (filename) => {
    const namesArr = exportedFiles.map((el) => el.fileName);
    return namesArr.indexOf(filename) > -1;
  };

  const handleFileNameChange = (e) => {
    if (fileName.length >= 30 && e.target.value.length > 30) {
      setErrorName('The file name must be less than 30 characters!');
      setError(true);
    } else {
      setFileName(e.target.value);
    }
  };

  const handleConfirmModal = useCallback(() => {
    handleSave(fileName, reportsToExport);
  }, [fileName, reportsToExport]);

  const handleIncludeChange = (index: number, dataType: 'datasource' | 'chart') => () => {
    const newExports = [...reportsToExport];
    if (dataType === 'datasource') {
      newExports[index].options.includeDatasourceLines = !newExports[index].options.includeDatasourceLines;
    } else {
      newExports[index].options.includeGraph = !newExports[index].options.includeGraph;
    }
    setReportsToExport(newExports);
  };

  return (
    <CustomModal
      onConfirm={handleConfirmModal}
      onCancel={closeModal}
      onClose={closeModal}
      confirmLabel="OK"
      confirmDisabled={error || waitingAPI}
      confirmLoader={waitingAPI}
      title="Export settings"
      className="exportSettingModal">
      <>
        <div className="row">
          <label htmlFor="fileName">File name : </label>
          <input
            type="text"
            name="fileName"
            value={fileName}
            placeholder="Type here"
            onChange={handleFileNameChange}
            className={`${error && 'redBorders'}`}
          />
        </div>
        <table>
          <thead>
            <tr>
              <th className="noBg"></th>
              <th colSpan={2}>Include</th>
            </tr>
            <tr>
              <th>Report</th>
              <th>Entries lines</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report, index) => {
              const includeDatasourceLines = reportsToExport[index].options.includeDatasourceLines;
              const key = `${report.name} - ${index}`;
              return (
                <tr key={key}>
                  <td>{report.name}</td>
                  <td>
                    <div className="toolBarSwitchButton" onClick={handleIncludeChange(index, 'datasource')}>
                      <div className={includeDatasourceLines ? 'background backgroundActive' : 'background'} />
                      <div className={includeDatasourceLines ? 'slider sliderActive' : 'slider'} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {error && <p className="errorMsg">{errorName}</p>}
      </>
    </CustomModal>
  );
};

export default ExportSettings;
