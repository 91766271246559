// Style
import './style.scss';

import React, { useRef, useState } from 'react';

import accountImg from '../../../../assets/img/accounts.png';
import coverageImg from '../../../../assets/img/coverage.png';
import entriesImg from '../../../../assets/img/entries.png';
import ExplanationBox from '../../../../elements/ExplanationBox';
import ToastHelper from '../../../../shared/helpers/toast/ToastHelper';

interface IMappingPartMenu {
  typeOfVisualisation: string;
  setTypeOfVisualisation: (val: string) => void;
  disabled: boolean;
  isUnion: boolean;
}

const explanations = [
  {
    title: 'Accounts',
    description: 'View the accounts that are included in the selected item of the active report.  ',
    posX: '24px',
  },
  {
    title: 'Entries',
    description: 'View the entries that are included in the selected item of the active report.',
    posX: '130px',
  },
  {
    title: 'Coverage',
    description:
      'Display accounts or entries of the data source that are not included in the active report. Useful to add new accounts or entries to the active report.  ',
    posX: '24px',
  },
];

/**
 * Container used to chose which info you want to see
 *
 * @param param NS_Tabs.ITab props
 * React.FC<IMappingPartMenu>
 */

const MappingPartMenu: React.FC<IMappingPartMenu> = ({
  typeOfVisualisation,
  setTypeOfVisualisation,
  disabled,
  isUnion,
}) => {
  //Refs
  const hoverTimer: { current: NodeJS.Timeout | null } = useRef(null);

  //States
  const [explanationSelected, setExplanationSelected] = useState<number>(-1);

  const handleInputHovered = (index: number) => {
    hoverTimer.current = setTimeout(() => {
      setExplanationSelected(index);
    }, 1000);
  };

  const handleInputNotHovered = () => {
    if (hoverTimer.current) {
      clearTimeout(hoverTimer.current);
      setExplanationSelected(-1);
    }
  };

  return (
    <div className="mappingPartContainer">
      {explanationSelected > -1 && (
        <ExplanationBox
          title={explanations[explanationSelected].title}
          description={explanations[explanationSelected].description}
          posX={explanations[explanationSelected].posX}
        />
      )}
      <div className="itemContainer itemContainerFirst">
        <button
          className={`${disabled && 'disabledButton'} btn buttonContainer`}
          onClick={(e) => {
            !disabled && setTypeOfVisualisation(typeOfVisualisation !== 'Accounts' ? 'Accounts' : '');
            handleInputNotHovered();
            e.stopPropagation();
          }}
          onMouseEnter={(_e) => handleInputHovered(0)}
          onMouseLeave={() => handleInputNotHovered()}>
          <img src={accountImg} alt="account" />
        </button>
        <p className={`label ${disabled && 'labelDisabled'}`}>Accounts</p>
      </div>
      <div className="itemContainer">
        <button
          className={`${disabled && 'disabledButton'} btn buttonContainer`}
          onClick={(e) => {
            if (!isUnion) {
              !disabled && setTypeOfVisualisation(typeOfVisualisation !== 'Entries' ? 'Entries' : '');
            } else {
              ToastHelper.info('You can not visualize entries on union files');
            }
            handleInputNotHovered();
            e.stopPropagation();
          }}
          onMouseEnter={(_e) => handleInputHovered(1)}
          onMouseLeave={() => handleInputNotHovered()}>
          <img src={entriesImg} alt="entries" />
        </button>
        <p className={`label ${disabled && 'labelDisabled'}`}>Entries</p>
      </div>
      <div className="itemContainer">
        <button
          className={`${disabled && 'disabledButton'} btn buttonContainer`}
          onClick={(e) => {
            !disabled && setTypeOfVisualisation(typeOfVisualisation !== 'Coverage' ? 'Coverage' : '');
            handleInputNotHovered();
          }}
          onMouseEnter={(_e) => handleInputHovered(2)}
          onMouseLeave={() => handleInputNotHovered()}>
          <img src={coverageImg} alt="coverage" />
        </button>
        <p className={`label ${disabled && 'labelDisabled'}`}>Coverage</p>
      </div>
    </div>
  );
};

export default MappingPartMenu;
