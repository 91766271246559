/**
 * Evaluates a string comparing it with a rule.
 * I.E. str: "hello world", rule: "\*world". It will return *true* since we are
 * trying to find anything (\*) ending with "world"
 * @param strToEval The string to evaluate
 * @param rule The rule to compare. It can contains the wildcard * that means *anything*
 */
export const matchStringWildcard = (strToEval: string, query: string, ignoreCase = true): boolean => {
  // escape points
  const rule = query.replace(/\./g, `\\.`);
  const regEx = new RegExp(rule.includes('*') ? `^${rule.replace(/\*/g, '.*')}$` : rule, ignoreCase ? 'gi' : 'g');
  return regEx.test(strToEval);
};
