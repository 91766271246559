import { IInsideDataSetData } from '../../../constant/chart.consts';
import { ChartType } from '../../../constant/datasource.consts';
import { CHART_DATA_FONT, formattedDisplayValue, TChartData } from '../../utils';

export const WATERFALL_COLORS = {
  POSITIVE: '#7eceb0',
  NEGATIVE: '#e27973',
  TOTALS: '#4899ea',
};

export const formalizeChartWaterfall = (params: TChartData) => {
  let data: Array<IInsideDataSetData> = [];
  const enum ExcludeData {
    FIRST = 'baseConceptFirstValue',
    END = 'baseConceptLastValue',
  }
  let reference = '';
  if (params?.fiscalYearsInfo) {
    reference = Object.keys(params.fiscalYearsInfo)[0];
  }

  if (params?.calculatedValues?.values?.simpleEffect) {
    const values = params.calculatedValues.values.simpleEffect;
    if (values[ExcludeData.FIRST]) {
      data = [
        {
          label: 'Trésorerie - Ouverture',
          value: values[ExcludeData.FIRST][0],
          displayValue: formattedDisplayValue(values[ExcludeData.FIRST][0]),
          color: WATERFALL_COLORS.TOTALS,
        },
      ];
    }
  }
  if (params?.calculatedValues?.valuesLegend) {
    const valueLegends = params.calculatedValues.valuesLegend;
    for (const [_key, _value] of Object.entries(valueLegends)) {
      if (!(_key === ExcludeData.FIRST || _key === ExcludeData.END)) {
        const values = params?.calculatedValues?.values?.simpleEffect;
        if (values?.[_key]) {
          let displayValue = formattedDisplayValue(values[_key][0]);
          data = [...data, { label: _value, value: values[_key][0], displayValue }];
        }
      }
    }
  }
  if (params?.calculatedValues?.values?.simpleEffect) {
    const values = params.calculatedValues.values.simpleEffect;
    if (values[ExcludeData.END]) {
      data = [
        ...data,
        {
          label: 'Trésorerie - Clôture',
          value: values[ExcludeData.END][0],
          issum: '1',
          displayValue: formattedDisplayValue(values[ExcludeData.END][0]),
          color: WATERFALL_COLORS.TOTALS,
        } as any,
      ];
    }
  }
  return {
    reference,
    title: params.reportTitle,
    config: {
      type: ChartType.WATERFALL,
      dataLoadStartMessage: 'Please wait, chart is loading the data....',
      dataLoadStartMessageFontSize: '14',
      renderAt: 'chart-container',
      width: '100%',
      height: '400',
      dataFormat: 'json',
      dataSource: {
        chart: {
          subcaption: '',
          showConnectors: '0',
          positiveColor: WATERFALL_COLORS.POSITIVE,
          negativeColor: WATERFALL_COLORS.NEGATIVE,
          numberPrefix: '€',
          plottooltext: '$label : $displayValue',
          showxaxisline: '1',
          showyaxisline: '1',
          exportFileName: params.reportTitle,
          //Not show sum at the end
          showSumAtEnd: '0',
          ...CHART_DATA_FONT,
        },
        data,
      },
    },
  };
};
