import '../../../base.scss';
import './style.scss';

import { useCallback, useEffect, useRef, useState } from 'react';

import { IFormalizeData, IResetPieData } from '../';
import { useAppSelector } from '../../../../../redux/hook';
import { exportBarColumn } from '../../../../../shared/charts/column/export';
import { exportFlowInOut } from '../../../../../shared/charts/column/flowInFlowOut/export';
import { createStackedBarPlot } from '../../../../../shared/charts/column/stacked/export';
import { createWaterfallPlot } from '../../../../../shared/charts/column/waterfall/export';
import { createLinePlot } from '../../../../../shared/charts/line/export';
import { CHARTS_COLORS } from '../../../../../shared/charts/pie';
import { createPiePlot } from '../../../../../shared/charts/pie/export';
import { IDonutData } from '../../../../../shared/constant/chart.consts';
import { ChartDisplayType, ChartType } from '../../../../../shared/constant/datasource.consts';
import { exportSnapshot, ExportTypes } from '../../../../../shared/helpers/report/reports.helper';
import ToastHelper from '../../../../../shared/helpers/toast/ToastHelper';
import Chart from '../../../../reportComponents/ChartVisualisationContainer/Chart';
import Loader from '../Loader';
import LoaderDonut from '../Loader/LoaderDonut';

interface ChartWrapperProps {
  data: IFormalizeData;
  reference: string;
  isLoading?: boolean;
  loadingDisplayType?: string;
  pieData?: IDonutData;
  defaultValue?: IDonutData;
  index?: number;
  reset?: IResetPieData;
  isFullWidth?: boolean;
}

const ChartWrapper = ({
  data,
  reference,
  isLoading,
  loadingDisplayType,
  pieData: pieDataProvider,
  index,
  reset,
  defaultValue,
  isFullWidth,
}: ChartWrapperProps) => {
  const { title, config } = data;
  const { name: workspaceName } = useAppSelector((state) => state.workspace);
  const isChartFullWidth = !(
    config?.type &&
    (config.type === ChartType.PIE || config.type === ChartType.PIE_TWO || config.type === ChartType.COLUMN_STACKED)
  );
  const FIX_ME = false;

  const [pieData, setPieData] = useState(defaultValue);
  const [fullWidth, setFullWidth] = useState(isFullWidth ?? false);

  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (reset?.isReset && reset.index !== null && reset.index !== undefined && index === reset.index) {
      setPieData(defaultValue);
    }
  }, [reset, defaultValue]);

  useEffect(() => {
    if (
      !reset?.isReset &&
      pieDataProvider &&
      pieDataProvider.index !== null &&
      pieDataProvider.index !== undefined &&
      index === pieDataProvider.index
    ) {
      setPieData(pieDataProvider);
    }
  }, [pieDataProvider]);

  const handleExport = useCallback(() => {
    const _data: any = data?.config?.dataSource?.data || [];
    const dataLength = _data?.length || 0;
    switch (config.type) {
      case ChartType.PIE:
        return createPiePlot(
          workspaceName,
          _data,
          data.title,
          dataLength - 1,
          { r: 7, c: 1 },
          { r: 7, c: 0 },
          CHARTS_COLORS,
        );
      case ChartType.SPLINE:
        const trendlines = data?.config?.dataSource?.trendlines || [];
        const tline = (trendlines?.length && trendlines[0]) || null;
        const average = tline?.line && tline.line[0] && tline.line[0].startvalue;
        const dataWithAverage = [
          ..._data.map((d) => {
            delete d.displayValue;
            return [d.value, d.label, parseFloat(average)];
          }),
        ];
        return createLinePlot(
          workspaceName,
          dataWithAverage,
          data?.exportLegend ?? '',
          data.title,
          { r: 7, c: 1 },
          { r: 7, c: 0 },
        );
      case ChartType.MULTIPLE_SPLINE:
        const linesDataset: any = data?.config?.dataSource?.dataset || [];
        const categories: any = data?.config?.dataSource?.categories || [];
        return createLinePlot(
          workspaceName,
          { linesDataset, categories },
          data?.exportLegend ?? '',
          data.title,
          { r: 7, c: 1 },
          { r: 7, c: 0 },
          true,
        );
      case ChartType.COLUMN_STACKED:
        const _dataset: any = data?.config?.dataSource?.dataset || [];
        return createStackedBarPlot(workspaceName, _dataset, data.title);
      case ChartType.WATERFALL:
        return createWaterfallPlot(
          workspaceName,
          _data,
          data.title,
          dataLength - 1,
          { r: 7, c: 1 },
          { r: 7, c: 0 },
          CHARTS_COLORS,
        );
      case ChartType.COLUMN:
        return exportBarColumn(workspaceName, title, _data);
      case ChartType.FLOW_IN_FLOW_OUT:
      case ChartType.COLUMN_STACKED_2:
        return exportFlowInOut(workspaceName, data, title);
      default:
        if (chartRef.current) {
          exportSnapshot(ExportTypes.PNG, title, chartRef.current, true).catch((e) => {
            ToastHelper.error(`Failed to export chart: ${e}`);
          });
        }
    }
  }, [chartRef, title]);

  const renderLoading = () => {
    if (loadingDisplayType === ChartDisplayType.PIE) {
      return <LoaderDonut />;
    } else {
      return <Loader />;
    }
  };

  const renderChart = () => {
    if (config.type === ChartType.PIE) {
      return (
        <div className="donut-wrapper flex align-items-center">
          <div
            className="donut-content-wrapper flex flex-column justify-content-end "
            style={{ flex: '0 0 40%', padding: '8px' }}>
            <span className="value" style={{ color: pieData?.color ? pieData.color : '#5D63B0' }}>
              {pieData?.pValue}
            </span>
            <span className="label font-regular"> {pieData?.id}</span>
            {pieData?.label && <span className="label font-regular"> {pieData?.label}</span>}
          </div>
          <Chart chartConfigs={config} fileName="test" newChartRef={chartRef} />
        </div>
      );
    }
    return <Chart chartConfigs={config} fileName="test" newChartRef={chartRef} />;
  };

  const handleFullWidth = useCallback(() => {
    setFullWidth(!fullWidth);
  }, [fullWidth]);

  return (
    <>
      <div className={`bloc-bordered chart-wrapper position-relative overflow-hidden  ${fullWidth && 'full-width'}`}>
        {!isLoading && (
          <div className="title font-semibold w-100 flex flex-nowrap align-items-center">
            <div className=" flex flex-nowrap align-items-center">
              <span className="title font-medium cash-card-block-point">
                {title}
                {!!reference && (
                  <span className="reference flex align-items-center">
                    <span className="point cash-card-point"></span>
                    {reference}
                  </span>
                )}
              </span>
            </div>

            <div className="chart-wrapper-menu ml-auto flex align-items-center justify-content-center">
              {
                // FIX ME
              }
              {FIX_ME && (
                <div className="custom-select">
                  <select className="font-regular">
                    <option value="">Please select</option>
                    <option value="">Charge salariale</option>
                    <option value="">Salaire</option>
                    <option value="">Chiffre d'affaire</option>
                  </select>
                </div>
              )}
              {FIX_ME && (
                <button
                  type="button"
                  className="btn btn-dropdown border-none position-relative flex align-items-center justify-content-center">
                  <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="16" cy="2" r="2" fill="#D9D9D9" />
                    <circle cx="9" cy="2" r="2" fill="#D9D9D9" />
                    <circle cx="2" cy="2" r="2" fill="#D9D9D9" />
                  </svg>
                  <div className="dropdown-content flex-column position-absolute">
                    <span>Option</span>
                    <span>Option</span>
                    <span>Option</span>
                  </div>
                </button>
              )}

              <button
                type="button"
                className="btn btn-export flex align-items-center justify-content-center"
                onClick={handleExport}>
                <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_116_18780)">
                    <path
                      d="M0.584375 3.91563L3.9125 0.5875C4.29063 0.210687 4.79688 0 5.32812 0H10C11.1047 0 12 0.895313 12 2L11.9998 14C11.9998 15.1047 11.1045 16 9.9998 16H2C0.895312 16 0 15.1047 0 14V5.32812C0 4.79688 0.209375 4.29063 0.584375 3.91563ZM5 1.065C4.86009 1.11394 4.72919 1.18519 4.62125 1.29309L1.29313 4.62122C1.18437 4.72813 1.1125 4.85938 1.06563 5H4.5C4.775 5 5 4.775 5 4.5V1.065ZM1 14C1 14.5513 1.44875 15 2 15H10C10.5512 15 11 14.5513 11 14V2C11 1.44875 10.5512 1 10 1H6V4.5C6 5.32812 5.32812 6 4.5 6H1V14ZM6 9.62813L7.34062 7.7125C7.49881 7.48594 7.81125 7.43125 8.03688 7.58944C8.26344 7.74762 8.31812 8.05975 8.15994 8.28569L6.60938 10.5L8.15937 12.7125C8.31756 12.9386 8.26288 13.2506 8.03631 13.4087C7.95 13.4719 7.85 13.5 7.77812 13.5C7.62041 13.5 7.465 13.4258 7.36812 13.2866L6 11.3719L4.65969 13.2866C4.59062 13.425 4.43437 13.5 4.25 13.5C4.15088 13.5 4.05078 13.4707 3.96387 13.4097C3.73731 13.2515 3.68262 12.9393 3.84081 12.7134L5.39062 10.5L3.84125 8.28656C3.68306 8.0605 3.73775 7.74844 3.96431 7.59031C4.19088 7.43219 4.50244 7.48681 4.66056 7.71338L6 9.62813Z"
                      fill="#96979A"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_116_18780">
                      <rect width="12" height="16" fill="white" transform="matrix(-1 0 0 1 12 0)" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
          </div>
        )}
        {/* <div className="chartForceWidth" style={{ display: 'flex' }}>
          <div style={{ maxWidth: '60%' }}>{isLoading ? renderLoading() : renderChart()}</div>

          <div style={{ flexGrow: '1', backgroundColor: 'red' }}></div>
        </div> */}
        <div className="body-chart-top"> {isLoading ? renderLoading() : renderChart()}</div>
        {!isLoading && isChartFullWidth && (
          <button type="button" className="btn-resize position-absolute" onClick={handleFullWidth}>
            <div className="layer position-absolute"></div>
          </button>
        )}
      </div>
    </>
  );
};

export default ChartWrapper;
