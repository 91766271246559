import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDataSourceInformation, IDataSourceNotification } from '../../shared/constant/datasource.consts';

interface IDatasourceState {
  notifications: Array<IDataSourceNotification>;
  informations: Array<IDataSourceInformation>;
}

const initialState: IDatasourceState = {
  notifications: [],
  informations: [],
};

export const datasourceSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    handleDSNotification: (state, action: PayloadAction<IDataSourceNotification>) => {
      state.notifications = [...state.notifications, action.payload];
    },
    deleteDSNotification: (state, action: PayloadAction<string>) => {
      state.notifications = [...state.notifications.filter((l) => l.datasourceId !== action.payload)];
    },
    setDataSourceInformations: (state, action: PayloadAction<IDataSourceInformation>) => {
      const information = state.informations.find((information) => information.fileId === action.payload.fileId);
      if (!information?.fileId) {
        state.informations = [...state.informations, action.payload];
      } else {
        state.informations = [
          ...state.informations.map((information) => {
            if (
              information.fileId === action.payload.fileId &&
              action.payload.workspaceId === information.workspaceId
            ) {
              return {
                ...information,
                card: action.payload.card || information.card,
                report: action.payload.report || information.report,
              };
            }
            return information;
          }),
        ];
      }
    },
  },
});

export const { handleDSNotification, deleteDSNotification, setDataSourceInformations } = datasourceSlice.actions;

export default datasourceSlice.reducer;
