/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import 'react-toastify/dist/ReactToastify.css';

import { ReactElement, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { InteractionType } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';

import { passwordResetRequest } from './authConfig';
import { store } from './redux/root';
import Routing from './routing';
import gtagScript from './scripts/gtagScript';
import ToastHelper from './shared/helpers/toast/ToastHelper';
import { CustomNavigationClient } from './utils/NavigationClient';

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
gtagScript(process.env.REACT_APP_APP_URL as string);

export function AppWithAuthentication(): ReactElement<any, any> {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  const { error, login } = useMsalAuthentication(InteractionType.Redirect, {
    prompt: 'login',
  });

  useEffect(() => {
    if (error && error.errorMessage.indexOf('AADB2C90118') > -1) {
      login(InteractionType.Redirect, passwordResetRequest).catch((err) => ToastHelper.error(err));
    }
  }, [isAuthenticated, inProgress]);

  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  instance.setNavigationClient(navigationClient);

  return (
    <>
      <AuthenticatedTemplate>
        <Provider store={store}>
          <DndProvider backend={HTML5Backend}>
            <Routing />
          </DndProvider>
        </Provider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div>Signing in...</div>
      </UnauthenticatedTemplate>
    </>
  );
}

export function App(): ReactElement<any, any> {
  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <Routing />
      </DndProvider>
    </Provider>
  );
}

export default AppWithAuthentication;
