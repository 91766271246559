export interface IWorkSpaceState {
  updated: boolean;
  fetching: boolean;
  name: string;
  customerId: string;
  workspaceId: string;
  dataSources: Array<NS_Workspace.IDataSourcesFile>;
  generatedDataSources: Array<NS_Workspace.IDataSourcesFile>;
  importedDataSources: Array<NS_Workspace.IDataSourcesFile>;
  worksheetPreview: NS_API.IWorksheetSelected;
  selectedFile: NS_Workspace.IFileContent;
  modalPreview: NS_API.IDataPreview;
  tabs: Array<NS_Workspace.ISheet>;
  reportsOrder: Array<any>;
  exportedFiles: Array<NS_Workspace.IExportedFile>;
  dataSourcesPreview: NS_API.IDataPreview;
  reportSave: Array<NS_Workspace.IReportSave>;
  loadingDataSourcesNumber: number;
  loadingTabs: string[];
  containersToCheck: string[];
  colorOptions: NS_Workspace.IColorOptions;
  preferredColors: string[];
  sumFooter: NS_Workspace.ISumFooter;
  preferredNumbers: NS_Workspace.IPreferedNumbers;
  tabDelete: boolean;
  uploadingFilesProgression: Map<string, number>;
  reportSaving: boolean;
}

export interface Action {
  type: string;
  payload?: any;
}

export const actions = {
  // Workspace
  SET_WORKSPACE: 'SET_WORKSPACE',
  SET_WORKSPACEID: 'SET_WORKSPACEID',
  // Datasource
  ADD_DATASOURCE: 'ADD_DATASOURCE',
  EDIT_DATASOURCE: 'EDIT_DATASOURCE',
  DELETE_DATASOURCE: 'DELETE_DATASOURCE',
  SET_DATA_SOURCES_PREVIEW: 'SET_DATA_SOURCES_PREVIEW',
  ADD_DATA_SOURCES_PREVIEW: 'ADD_DATA_SOURCES_PREVIEW',
  DELETE_DATA_SOURCES_PREVIEW: 'DELETE_DATA_SOURCES_PREVIEW',
  EDIT_LOADING_DATASOURCES_LIST: 'EDIT_LOADING_DATASOURCES_LIST',
  SET_GENERATED_DATASOURCES: 'SET_GENERATED_DATASOURCES',
  ADD_GENERATED_DATASOURCE: 'ADD_GENERATED_DATASOURCE',
  DELETE_GENERATED_DATASOURCE: 'DELETE_GENERATED_DATASOURCE',
  // Reports
  GET_TABS: 'GET_TABS',
  SET_TABS_SAVE: 'SET_TABS_SAVE',
  ADD_TAB: 'ADD_TAB',
  RENAME_TAB: 'RENAME_TAB',
  TYPE_TAB: 'TYPE_TAB',
  DELETE_TABS: 'DELETE_TABS',
  ADD_LOADING_TAB: 'ADD_LOADING_TAB',
  REMOVE_LOADING_TAB: 'REMOVE_LOADING_TAB',
  UPDATE_TAB_ORDER: 'UPDATE_TAB_ORDER',
  SET_REPORT_SAVING: 'SET_REPORT_SAVING',
  // Exports
  DELETE_EXPORTED_FILE: 'DELETE_EXPORTED_FILE',
  ADD_EXPORTED_FILE: 'ADD_EXPORTED_FILE',
  ADD_CONTAINER_TO_CHECK: 'ADD_CONTAINER_TO_CHECK',
  REMOVE_CONTAINER_TO_CHECK: 'REMOVE_CONTAINER_TO_CHECK',
  // Modal
  SET_MODAL_PREVIEW: 'SET_MODAL_PREVIEW',
  ADD_MODAL_PREVIEW: 'ADD_MODAL_PREVIEW',
  // Options
  SET_OPTION_COLORS: 'SET_OPTION_COLORS',
  SET_PREFERRED_COLORS: 'SET_PREFERRED_COLORS',
  SET_PREFERRED_NUMBERS: 'SET_PREFERRED_NUMBERS',
  COUNT_SUM_FOOTER: 'COUNT_SUM_FOOTER',
  RESET_DELETE_TABS: 'RESET_DELETE_TABS',
  UPDATE_UPLOADING_FILES: 'UPDATE_UPLOADING_FILES',
};

export const initialState: IWorkSpaceState = {
  updated: false,
  fetching: false,
  customerId: 'customerId',
  name: 'Workspace',
  workspaceId: '',
  dataSources: [],
  generatedDataSources: [],
  importedDataSources: [],
  dataSourcesPreview: {
    columns: [],
    data: [],
    totalLength: 0,
  },
  worksheetPreview: {
    datasourceInfo: {
      workspaceId: '',
      fileId: '',
      datasourceName: '',
      fileName: '',
      status: '',
      error: 0,
      profileId: '',
      customerId: '',
      fileType: '',
      mimeType: '',
      encodingType: '',
      worksheets: '',
      worksheetNumber: 0,
      size: 0,
    },
    message: '',
    status: '',
    data: {
      columns: [],
      data: [],
      totalLength: 0,
    },
  },
  selectedFile: {
    data: [],
    columns: [],
    id: '',
  },
  modalPreview: {
    columns: [],
    data: [],
    totalLength: 0,
  },
  tabs: [],
  reportsOrder: [],
  exportedFiles: [],
  reportSave: [],
  loadingDataSourcesNumber: 0,
  loadingTabs: [],
  containersToCheck: [],
  colorOptions: {
    primaryFillColor: '',
    secondaryFillColor: '',
    primaryTextColor: '',
    secondaryTextColor: '',
    headerFillColor: '',
    headerTextColor: '',
    chartColors: [],
  },
  preferredColors: [],
  preferredNumbers: {
    monetaryUnit: 'DEFAULT',
    decimalSeparator: 0,
    currencyParams: { format: '0,0', displayUnit: 1 },
  },
  sumFooter: {
    count: 0,
    sum: '0.00',
  },
  tabDelete: false,
  uploadingFilesProgression: new Map(),
  reportSaving: false, // on saving loading
};

const workspaceReducer: Reducer<IWorkSpaceState, Action> = (state, action) => {
  switch (action.type) {
    case actions.SET_WORKSPACE:
      const { name, dataSources, reports, reportsOrder, workspaceId, exportedFiles, colors, numberStyle, customerId } =
        action.payload;
      const preferredColors = colors?.preferredColors ?? [];
      const colorOptions = {
        primaryFillColor: colors?.primaryFillColor ?? '#40a871ff',
        secondaryFillColor: colors?.secondaryFillColor ?? '#f6f7f7ff',
        primaryTextColor: colors?.primaryTextColor ?? '#ffffffff',
        secondaryTextColor: colors?.secondaryTextColor ?? '#5D7183ff',
        headerFillColor: colors?.headerFillColor ?? '#f2f2f2ff',
        headerTextColor: colors?.headerTextColor ?? '#c6c8c9ff',
        chartColors: colors?.chartColors ?? ['#46b78eff', '#96979aff', '#8eb4e3ff', '#c6c8c9ff', '#f2dcdbff'],
      };
      const tempCurrencyParams: NS_Table.ICurrency = { format: '0,0', displayUnit: 1 };
      const preferredNumbers: NS_Workspace.IPreferedNumbers = {
        monetaryUnit: 'DEFAULT',
        decimalSeparator: 0,
        currencyParams: { format: '0,0', displayUnit: 1 },
      };

      if (numberStyle) {
        if (numberStyle.monetaryUnit === 'DEFAULT') {
          tempCurrencyParams.displayUnit = 1;
        } else if (numberStyle.monetaryUnit === 'k') {
          tempCurrencyParams.displayUnit = 1000;
        } else {
          tempCurrencyParams.displayUnit = 1000000;
        }

        for (let i = 0; i < numberStyle.decimalSeparator; i++) {
          if (i === 0) {
            tempCurrencyParams.format = tempCurrencyParams.format + '.0';
          } else {
            tempCurrencyParams.format = tempCurrencyParams.format + '0';
          }
        }

        preferredNumbers.monetaryUnit = numberStyle.monetaryUnit;
        preferredNumbers.decimalSeparator = numberStyle.decimalSeparator;
        preferredNumbers.currencyParams = tempCurrencyParams;
      }
      return {
        ...state,
        name,
        customerId,
        dataSources,
        generatedDataSources: dataSources.filter((ds) => ds.status === '4'),
        tabs: reports,
        reportsOrder,
        workspaceId,
        exportedFiles,
        preferredColors,
        colorOptions,
        preferredNumbers,
      };
    case actions.SET_WORKSPACEID:
      return { ...state, workspaceId: action.payload };
    default:
      return state;
  }
};

const datasourceReducer: Reducer<IWorkSpaceState, Action> = (state, action) => {
  switch (action.type) {
    case actions.ADD_DATASOURCE: {
      return { ...state, dataSources: [action.payload, ...state.dataSources] };
    }
    case actions.EDIT_DATASOURCE:
      return {
        ...state,
        dataSources: state.dataSources.map((item) => {
          return item.fileId === action.payload.fileId ? action.payload.dataSource : item;
        }),
        generatedDataSources: state.generatedDataSources.map((item) => {
          return item.fileId === action.payload.fileId ? action.payload.dataSource : item;
        }),
        importedDataSources: state.importedDataSources.map((item) => {
          return item.fileId === action.payload.fileId ? action.payload.dataSource : item;
        }),
      };
    case actions.COUNT_SUM_FOOTER:
      return {
        ...state,
        sumFooter: action.payload,
      };
    case actions.DELETE_DATASOURCE:
      const newDataSources = state.dataSources.filter((value) => {
        return !action.payload.includes(value.fileId);
      });
      const newGeneratedDataSources = state.generatedDataSources.filter((value) => {
        return !action.payload.includes(value.fileId);
      });
      const newImportedDataSources = state.importedDataSources.filter((value) => {
        return !action.payload.includes(value.fileId);
      });
      return {
        ...state,
        dataSources: newDataSources,
        generatedDataSources: newGeneratedDataSources,
        importedDataSources: newImportedDataSources,
      };
    case actions.EDIT_LOADING_DATASOURCES_LIST:
      return { ...state, loadingDataSourcesNumber: state.loadingDataSourcesNumber + action.payload };
    case actions.SET_DATA_SOURCES_PREVIEW:
      return {
        ...state,
        dataSourcesPreview: {
          columns: action.payload.columns,
          data: action.payload.data,
          totalLength: action.payload.totalLength,
        },
      };
    case actions.ADD_DATA_SOURCES_PREVIEW:
      return {
        ...state,
        dataSourcesPreview: {
          ...state.dataSourcesPreview,
          data: state.dataSourcesPreview.data.concat(action.payload.data),
        },
      };
    case actions.DELETE_DATA_SOURCES_PREVIEW:
      return {
        ...state,
        dataSourcesPreview: {
          columns: [],
          data: [],
          totalLength: 0,
        },
      };
    case actions.SET_GENERATED_DATASOURCES:
      return { ...state, generatedDataSources: action.payload };
    case actions.ADD_GENERATED_DATASOURCE:
      return { ...state, generatedDataSources: [action.payload, ...state.generatedDataSources] };
    case actions.DELETE_GENERATED_DATASOURCE:
      return {
        ...state,
        generatedDataSources: state.generatedDataSources.filter((genDS) => genDS.fileId !== action.payload),
      };
    case actions.UPDATE_UPLOADING_FILES:
      const { fileName, percentage } = action.payload;
      const newMap = new Map(state.uploadingFilesProgression);
      if (percentage < 0) {
        newMap.delete(fileName);
      } else {
        newMap.set(fileName, percentage);
      }
      return {
        ...state,
        uploadingFilesProgression: newMap,
      };
    default:
      return state;
  }
};

const reportReducer: Reducer<IWorkSpaceState, Action> = (state, action) => {
  switch (action.type) {
    case actions.GET_TABS:
      return {
        ...state,
        tabs: action.payload,
      };
    case actions.SET_TABS_SAVE:
      return {
        ...state,
        tabs: state.tabs.map((tab) =>
          tab.id === action.payload.reportId
            ? { ...tab, id: action.payload.reportId, reportSaved: true, datasourceId: action.payload.datasourceId }
            : tab,
        ),
      };
    case actions.ADD_TAB:
      return { ...state, tabs: [...state.tabs, action.payload] };
    case actions.RENAME_TAB:
      const renamedTabs = state.tabs.map((tab) => {
        if (tab.id === action.payload.id) {
          return { ...tab, name: action.payload.name };
        }
        return tab;
      });
      return { ...state, tabs: renamedTabs };
    case actions.TYPE_TAB:
      const typeTabs = state.tabs.map((tab) => {
        if (tab.id === action.payload.id) {
          return { ...tab, type: action.payload.type, name: action.payload.name };
        }
        return tab;
      });
      return { ...state, tabs: typeTabs };
    case actions.DELETE_TABS:
      const newTabs = state.tabs.filter((value) => {
        return !action.payload.includes(value.id);
      });
      return { ...state, tabs: newTabs, tabDelete: true };
    case actions.RESET_DELETE_TABS:
      return { ...state, tabDelete: false };
    case actions.ADD_LOADING_TAB:
      return { ...state, loadingTabs: [...state.loadingTabs, action.payload] };
    case actions.REMOVE_LOADING_TAB:
      return { ...state, loadingTabs: state.loadingTabs.filter((id) => id !== action.payload) };
    case actions.UPDATE_TAB_ORDER:
      const data = action.payload;
      return { ...state, reportsOrder: data.reportsOrder, tabs: data.reports };
    case actions.SET_REPORT_SAVING:
      return { ...state, reportSaving: action.payload as boolean };
    default:
      return state;
  }
};

const exportReducer: Reducer<IWorkSpaceState, Action> = (state, action) => {
  switch (action.type) {
    case actions.ADD_EXPORTED_FILE:
      if (state.exportedFiles.map((file) => file.fileName).indexOf(action.payload.fileName) !== -1) {
        return state;
      }
      return { ...state, exportedFiles: [...state.exportedFiles, action.payload] };
    case actions.DELETE_EXPORTED_FILE:
      const newFiles = state.exportedFiles.filter((value) => {
        return value.fileName !== action.payload;
      });
      return { ...state, exportedFiles: newFiles };
    case actions.ADD_CONTAINER_TO_CHECK:
      return { ...state, containersToCheck: [...state.containersToCheck, action.payload] };
    case actions.REMOVE_CONTAINER_TO_CHECK:
      return { ...state, containersToCheck: state.containersToCheck.filter((id) => id !== action.payload) };
    default:
      return state;
  }
};

const modalPreviewReducer: Reducer<IWorkSpaceState, Action> = (state, action) => {
  switch (action.type) {
    case actions.SET_MODAL_PREVIEW:
      return {
        ...state,
        modalPreview: {
          columns: action.payload.columns,
          data: action.payload.data,
          totalLength: action.payload.totalLength,
        },
      };
    case actions.ADD_MODAL_PREVIEW:
      return {
        ...state,
        modalPreview: { ...state.modalPreview, data: state.modalPreview.data.concat(action.payload.data) },
      };
    // Colors
    default:
      return state;
  }
};

const optionReducer: Reducer<IWorkSpaceState, Action> = (state, action) => {
  switch (action.type) {
    case actions.SET_OPTION_COLORS:
      return {
        ...state,
        colorOptions: {
          primaryFillColor: action.payload.primaryFillColor,
          secondaryFillColor: action.payload.secondaryFillColor,
          primaryTextColor: action.payload.primaryTextColor,
          secondaryTextColor: action.payload.secondaryTextColor,
          headerFillColor: action.payload.headerFillColor,
          headerTextColor: action.payload.headerTextColor,
          chartColors: action.payload.chartColors,
        },
      };
    case actions.SET_PREFERRED_COLORS:
      return {
        ...state,
        preferredColors: action.payload,
      };
    case actions.SET_PREFERRED_NUMBERS:
      return {
        ...state,
        preferredNumbers: action.payload,
      };
    default:
      return state;
  }
};

export const workspaceContextReducer: Reducer<IWorkSpaceState, Action> = (state, action) => {
  switch (action.type) {
    // workspace
    case actions.SET_WORKSPACE:
    case actions.SET_WORKSPACEID:
      return workspaceReducer(state, action);
    // Datasource
    case actions.ADD_DATASOURCE:
    case actions.EDIT_DATASOURCE:
    case actions.COUNT_SUM_FOOTER:
    case actions.DELETE_DATASOURCE:
    case actions.EDIT_LOADING_DATASOURCES_LIST:
    case actions.SET_DATA_SOURCES_PREVIEW:
    case actions.ADD_DATA_SOURCES_PREVIEW:
    case actions.DELETE_DATA_SOURCES_PREVIEW:
    case actions.SET_GENERATED_DATASOURCES:
    case actions.ADD_GENERATED_DATASOURCE:
    case actions.DELETE_GENERATED_DATASOURCE:
    case actions.UPDATE_UPLOADING_FILES:
      return datasourceReducer(state, action);
    // report
    case actions.GET_TABS:
    case actions.SET_TABS_SAVE:
    case actions.ADD_TAB:
    case actions.RENAME_TAB:
    case actions.DELETE_TABS:
    case actions.RESET_DELETE_TABS:
    case actions.ADD_LOADING_TAB:
    case actions.REMOVE_LOADING_TAB:
    case actions.UPDATE_TAB_ORDER:
    case actions.SET_REPORT_SAVING:
    case actions.TYPE_TAB:
      return reportReducer(state, action);
    // export
    case actions.ADD_EXPORTED_FILE:
    case actions.DELETE_EXPORTED_FILE:
    case actions.ADD_CONTAINER_TO_CHECK:
    case actions.REMOVE_CONTAINER_TO_CHECK:
      return exportReducer(state, action);
    // modalPreview
    case actions.SET_MODAL_PREVIEW:
    case actions.ADD_MODAL_PREVIEW:
      return modalPreviewReducer(state, action);
    // option
    case actions.SET_OPTION_COLORS:
    case actions.SET_PREFERRED_COLORS:
    case actions.SET_PREFERRED_NUMBERS:
      return optionReducer(state, action);
    default:
      return state;
  }
};
