import _ from 'lodash';

import { Queue } from './data-structures';
import { TreeNode } from './tree-node';

export default class TreeHelper {
  static getNodeValue(column: NS_Table.IColumnHeader, row: NS_TREE.IDataNodeContent) {
    let value: string | number,
      separator = false;
    if (column.valueIndex !== undefined) {
      value = row[column.Header][column.valueIndex];
    } else {
      if (column.Header === 'formula' && column.colParams.colFormula) {
        if (String(column.colParams.colFormula.index) === '0') {
          separator = true;
        }
        value = row.calculated[column.colParams.colFormula.index];
      } else {
        value = row[column.Header];
      }
    }
    return { value, separator };
  }

  static findNextOrPreviousNode(
    root: NS_TREE.ITreeRoot<undefined | string>,
    cell: NS_TREE.ICellSelected,
    step: -1 | 1,
    parentOpen: any,
  ): NS_TREE.ICellSelected | null {
    const accNodeId =
      cell.accLineRowIndex !== undefined ? cell.nodeId.slice(0, -(cell.accLineRowIndex + '').length) : cell.nodeId;
    const node = TreeHelper.searchNodeById(root, accNodeId);
    if (!node) {
      return null;
    }
    // Search above node
    if (step === -1) {
      //go up (looking for the previous node)
      if (parentOpen.includes(node.data.id) && node.children.length && node.data.content.rowContentType !== 'N') {
        //go to the first child
        cell.nodeId = node.children[0].id;
        cell.value = node.children[0].data.content.value[cell.colIndex - 1];
        cell.nodeType = node.children[0].data.content.rowContentType;
        return cell;
      } else {
        if (node.data.content.rowContentType === 'N' && parentOpen.includes(node.data.id) && !cell.firstAccLineRow) {
          // accounting lines
          if (cell.accLineRowIndex === undefined) {
            //go for the first time on the accouting lines
            cell.lastAccLineRow = true;
            cell.valueUpdated = true;
            cell.accLineRowIndex = 0;
            cell.nodeId = accNodeId + '0';
            if (node.children.length === 1) {
              cell.firstAccLineRow = true;
            }
          } else {
            //go up througt the accounting lines
            cell.lastAccLineRow = undefined;
            cell.accLineRowIndex = cell.accLineRowIndex + 1;
            cell.nodeId = accNodeId + cell.accLineRowIndex;
            cell.valueUpdated = true;
          }
          return cell;
        } else {
          // browse the tree looking for the previous node
          let parent = node.parent ? TreeHelper.searchNodeById(root, node.parent) : root;
          if (parent) {
            let index = 0;
            for (; index < parent.children.length; index++) {
              const child = parent.children[index];
              if (child.id === node.id) {
                break;
              }
            }
            // looking for direct parent
            if (index < parent.children.length - 1) {
              cell.nodeId = parent.children[index + 1].id;
              cell.value = parent.children[index + 1].data.content.value[cell.colIndex - 1];
              cell.nodeType = parent.children[index + 1].data.content.rowContentType;
              cell.accLineRowIndex = undefined;
              cell.valueUpdated = undefined;
              cell.lastAccLineRow = undefined;
              cell.firstAccLineRow = undefined;

              return {
                ...cell,
                accLineRowIndex: undefined,
                valueUpdated: false,
                lastAccLineRow: undefined,
                firstAccLineRow: undefined,
              };
            } else {
              // loop in parent of parents to find THE good one
              let grandParent: NS_TREE.ITreeRoot<string | undefined> | null = parent;
              while (grandParent) {
                grandParent = grandParent.parent ? TreeHelper.searchNodeById(root, grandParent.parent) : root;
                if (grandParent && grandParent.children.length) {
                  const idxLastChild = grandParent.children.length - 1;
                  if (grandParent.children[idxLastChild].id === parent.id) {
                    parent = grandParent;
                    continue;
                  } else {
                    break;
                  }
                }
              }
              if (grandParent) {
                let idx = 0;
                for (; idx < grandParent.children.length; idx++) {
                  const child = grandParent.children[idx];
                  if (child.id === parent.id) {
                    break;
                  }
                }
                if (idx < grandParent.children.length - 1) {
                  cell.nodeId = grandParent.children[idx + 1].id;
                  cell.value = grandParent.children[idx + 1].data.content.value[cell.colIndex - 1];
                  cell.nodeType = grandParent.children[idx + 1].data.content.rowContentType;
                  cell.accLineRowIndex = undefined;
                  cell.valueUpdated = undefined;
                  cell.lastAccLineRow = undefined;
                  cell.firstAccLineRow = undefined;

                  return {
                    ...cell,
                    accLineRowIndex: undefined,
                    valueUpdated: false,
                    lastAccLineRow: undefined,
                    firstAccLineRow: undefined,
                  };
                }
              }
            }
          }
        }
        return null;
      }
    }
    // Search below node
    else {
      //go down in the accouting line of the node
      if (cell.accLineRowIndex !== undefined) {
        if (cell.lastAccLineRow && cell.firstAccLineRow) {
          cell.accLineRowIndex = undefined;
          cell.valueUpdated = true;
          cell.lastAccLineRow = undefined;
          cell.firstAccLineRow = undefined;
          cell.nodeId = accNodeId;
        } else if (cell.lastAccLineRow) {
          cell.accLineRowIndex = undefined;
          cell.valueUpdated = true;
          cell.lastAccLineRow = undefined;
          cell.nodeId = accNodeId;
        } else if (cell.firstAccLineRow) {
          cell.firstAccLineRow = undefined;
          cell.accLineRowIndex = cell.accLineRowIndex - 1;
          cell.nodeId = accNodeId + cell.accLineRowIndex;
          cell.valueUpdated = true;
        } else {
          cell.accLineRowIndex = cell.accLineRowIndex - 1;
          cell.nodeId = accNodeId + cell.accLineRowIndex;
          cell.valueUpdated = true;
        }
        return cell;
      }

      //go down in the tree (looking for the next node)
      const parent = node.parent ? TreeHelper.searchNodeById(root, node.parent) : root;

      if (parent) {
        //get the index of the node in parent "childs xD"
        let index = parent.children.length - 1;
        for (; index > 0; index--) {
          const child = parent.children[index];
          if (child.id === node.id) {
            break;
          }
        }
        if (index > 0) {
          //go through all the open node (visible===true) of the next node
          let nextNode = parent.children[index - 1];
          while (
            parentOpen.includes(nextNode.data.id) &&
            nextNode.children.length > 0 &&
            nextNode.data.content.rowContentType !== 'N'
          ) {
            nextNode = nextNode.children[nextNode.children.length - 1];
          }
          cell.nodeId = nextNode.id;
          cell.value = nextNode.data.content.value[cell.colIndex - 1];
          cell.nodeType = nextNode.data.content.rowContentType;
          //if this node type is N and it's open select the first accounting line
          if (nextNode.data.content.rowContentType === 'N' && parentOpen.includes(nextNode.data.id)) {
            cell.firstAccLineRow = true;
            cell.valueUpdated = true;
            cell.accLineRowIndex = nextNode.children.length - 1;
            cell.nodeId = cell.nodeId + `${nextNode.children.length - 1}`;
            if (nextNode.children.length === 1) {
              cell.lastAccLineRow = true;
            }
          }
          return cell;
        } else {
          //the node has the index 0 => the next node is the parent
          if (parent.parent) {
            cell.nodeId = parent.id;
            cell.value = parent.data.content.value[cell.colIndex - 1];
            cell.nodeType = parent.data.content.rowContentType;
            return cell;
          }
        }
      }
      return null;
    }
  }

  /**
   * Search function using BFS algorithm. Find a node by id
   * if exists and returns it, returning null if no match.
   * @param root The root node representing the tree
   * @param idSearched Node ID to search
   */
  static searchNodeById(
    root: NS_TREE.ITreeRoot<undefined | string>,
    idSearched: string,
  ): NS_TREE.ITreeRoot<undefined | string> | null {
    const queue = new Queue<NS_TREE.ITreeRoot<undefined | string>>();
    queue.enqueue(root);
    while (!queue.isEmpty()) {
      const current = queue.dequeue();
      if (current) {
        if (current.id === idSearched || current.data.content.rowContentId === idSearched) {
          return current;
        }
        if (current.children) {
          for (const child of current.children) {
            queue.enqueue(child);
          }
        }
      } else {
        return null;
      }
    }
    return null;
  }

  /**
   * Search a node by a property value
   * @param root Tree root node
   * @param key content property's key
   * @param value content property's value
   */
  static searchNodeByDataContentProperty(
    root: NS_TREE.ITreeRoot<undefined | string>,
    key: string,
    value: string,
  ): NS_TREE.ITreeRoot<undefined | string> | null {
    const queue = new Queue<NS_TREE.ITreeRoot<undefined | string>>();
    queue.enqueue(root);
    while (!queue.isEmpty()) {
      const current = queue.dequeue();
      if (current) {
        if (current.data.content[key] === value) {
          return current;
        }
        for (const child of current.children) {
          queue.enqueue(child);
        }
      } else {
        return null;
      }
    }
    return null;
  }

  /**
   * Adds a node to the children list of the
   * node target.
   * @param root The root node representing the tree
   * @param newNode Node to add as child
   * @param parentId Node target
   */
  static addNodeToTree(root: TreeNode, newNode: TreeNode, parentId: string): TreeNode {
    // necessary or we do it before don't needing a parentId param?
    newNode.parent = parentId;

    const parent = this.searchNodeById(root, parentId);
    if (parent) {
      this.addChild(parent, newNode);
    }
    return newNode;
  }

  /**
   * Deletes a node from the tree. The deleted node's
   * children will be linked to deleted node's parent.
   * It returns the deleted node.
   * @param root The root node representing the tree
   * @param idToDelete Node ID to delete
   */
  static deleteNodeFromTree(
    root: NS_TREE.ITreeRoot<undefined>,
    idToDelete: string,
    // attachChildrenToNodeParent = false
  ): NS_TREE.ITreeRoot<undefined | string> | null {
    const nodeToDelete = this.searchNodeById(root, idToDelete);

    if (nodeToDelete) {
      const parentNode = this.searchNodeById(root, nodeToDelete.parent as string);

      if (parentNode) {
        // if (attachChildrenToNodeParent) {
        //   // mes enfants, je pars... now your grandpa will be your parent
        //   for (const child of nodeToDelete.children) {
        //     child.parent = nodeToDelete.parent as string;
        //     this.addChild(parentNode, child);
        //   }
        // }

        // delete means to delete the link with his parent
        _.remove(parentNode.children, {
          id: nodeToDelete.id,
        });

        this.resetChildOrder(parentNode);
      }
    }
    return nodeToDelete;
  }

  /**
   * Deletes a node from the tree and unlinks also
   * the deleted node children. It returns the
   * deleted node.
   * @param root The root node representing the tree
   * @param idToDelete Node ID to delete
   */
  static deleteBranchFromTree(
    root: NS_TREE.ITreeRoot<undefined>,
    idToDelete: string,
    calculateBalance = false,
  ): NS_TREE.ITreeRoot<undefined | string> | null {
    const nodeToDelete = TreeHelper.searchNodeById(root, idToDelete) as NS_TREE.ITreeRoot<string> | null;

    if (nodeToDelete) {
      const parentNode = TreeHelper.searchNodeById(root, nodeToDelete.parent);

      if (parentNode) {
        // delete means to delete the link with his parent also
        _.remove(parentNode.children, {
          id: nodeToDelete.id,
        });

        this.resetChildOrder(parentNode);

        if (calculateBalance) {
          // recalculate values from parent to root here
          this._calculateBalanceRecursive(root, parentNode);
        }
      }
    }

    return nodeToDelete;
  }

  /**
   * Get the index of a node on parent's children list
   * @param root Root node
   * @param target Target node to get his index on parent's children list
   */
  static indexOfNodeInChildrenList(root: NS_TREE.ITreeRoot<undefined>, target: string) {
    const targetNode = this.searchNodeById(root, target);
    if (targetNode) {
      if (targetNode.parent) {
        const parentNode = this.searchNodeById(root, targetNode.parent);
        if (parentNode) {
          return parentNode.children.findIndex((c) => c.id === targetNode.id);
        }
      }
      return 0;
    }
    return -1;
  }

  /**
   * Perform deleteBranchFromTree to the node to move,
   * and links it to a new parent.
   * @param root The root node representing the tree
   * @param idToMove Target node's id (the one we want to move)
   * @param idNewParent New parent node's id
   * @param index helper index object when drag and drop
   */
  static moveBranchFromTree(
    root: NS_TREE.ITreeRoot<undefined>,
    idToMove: string,
    idNewParent: string,
    index?: { neighbour: number; node: number },
  ): NS_TREE.ITreeRoot<undefined | string> | null {
    const newParent = this.searchNodeById(root, idNewParent);
    const footerNode = this.searchNodeByDataContentProperty(root, 'rowContentId', 'TXTFOOT');
    if (newParent && footerNode) {
      // safe drop
      const newParentPathToRoot = this.nodePathToRoot(root, newParent);
      if (newParentPathToRoot.includes(idToMove) || newParent.id === idToMove) {
        return null;
      }

      const preMoveNode = this.searchNodeById(root, idToMove);

      if (preMoveNode) {
        const oldParent = this.searchNodeById(root, preMoveNode.parent as string);
        if (oldParent) {
          // TXTFOOT rule
          if (
            (oldParent.id === footerNode.id && oldParent.id !== newParent.id) ||
            (oldParent.id !== footerNode.id && newParent.id === footerNode.id) ||
            this.nodePathToRoot(root, newParent).includes(footerNode.id)
          ) {
            return null;
          }
          const branchToMove = this.deleteBranchFromTree(root, idToMove);
          if (branchToMove) {
            //after removing the branch the the children array length has changed
            if (typeof index !== 'undefined' && newParent.id === branchToMove.parent && index.node > index.neighbour) {
              index.node = index.node - 1;
            }

            branchToMove.parent = newParent.id;
            this.addChild(newParent, branchToMove, index?.node);

            // outline level
            const brother = newParent.children.find((child) => child.id !== branchToMove.id);

            branchToMove.data.content.rowParams.outlineLevel = brother
              ? brother.data.content.rowParams.outlineLevel
              : newParent.data.content.rowParams.outlineLevel + 1;

            this.applyOutlineLevelToBranch(root, branchToMove);
            this._calculateBalanceRecursive(root, oldParent);
            this._calculateBalanceRecursive(root, newParent);
          }
          return branchToMove;
        }
      }
    }
    return null;
  }

  /**
   * Searches the node by ID and edits his node.data.content
   * object using the ES6 function Object.assign(target, propertiesToAssign)
   * @param root The root node representing the tree
   * @param idNodeToModify Node ID of node to be modified
   * @param contentToModify The content to be overwritten
   */
  static editNodeContentFromTree(
    root: NS_TREE.ITreeRoot<undefined>,
    idNodeToModify: string,
    contentToModify: NS_TREE.IDataNodeContent,
  ): NS_TREE.ITreeRoot<undefined | string> | null {
    const nodeToEdit = this.searchNodeById(root, idNodeToModify);

    if (nodeToEdit) {
      const newContent: NS_TREE.IDataNodeContent = {
        ...contentToModify,
        value: [...nodeToEdit.data.content.value],
        calculated: [...nodeToEdit.data.content.calculated],
      };
      Object.assign(nodeToEdit.data.content, newContent);
    }

    return nodeToEdit;
  }

  /**
   *
   * @param nodeRoot Tree's root node
   * @param nodeFrom Node to start the recursive call
   * @param commonParentIdToStop DEPRECATED. A node id to stop the recursive call
   */
  static _calculateBalanceRecursive(
    nodeRoot: NS_TREE.ITreeRoot<undefined | string>,
    nodeFrom: NS_TREE.ITreeRoot<undefined | string>,
    commonParentIdToStop?: string,
  ): void {
    this.calculateNodeBalance(nodeFrom);
    // next parent
    const next = this.searchNodeById(nodeRoot, nodeFrom.parent as string);
    if (next && ['A', 'C'].includes(next.data.content.rowContentType)) {
      this._calculateBalanceRecursive(nodeRoot, next, commonParentIdToStop);
    }
  }

  /**
   * Calculates the balance of a node performing a SUM of all
   * corresponding values of his children.
   * @param node Node to calculate his balance
   */
  static calculateNodeBalance(node: NS_TREE.ITreeRoot<undefined | string>): void {
    // note: node.data.content.value it's an array of values

    if (node.data.content.rowContentType === 'A' || node.data.content.rowContentType === 'C') {
      const valueLength = node.data.content.value.length;
      // base array
      const parentNewValues = Array.from({ length: valueLength }, () => 0);

      for (const child of node.children) {
        for (let i = 0; i < parentNewValues.length; i++) {
          parentNewValues[i] += child.data.content.value[i];
        }
      }
      node.data.content.value = parentNewValues;
    }
  }

  /**
   * Add a child and trigger effects like resetChildOrder
   * @param parent Target TreeNode
   * @param newChild Child TreeNode
   * @param index Optional. the index that the node must adopt in the children list
   */
  static addChild(
    parent: NS_TREE.ITreeRoot<undefined | string>,
    newChild: NS_TREE.ITreeRoot<string | undefined>,
    index?: number,
  ): void {
    if (typeof index !== 'undefined') {
      parent.children.splice(index, 0, newChild as NS_TREE.ITreeRoot<string>);
    } else {
      parent.children.unshift(newChild as NS_TREE.ITreeRoot<string>);
    }
    this.resetChildOrder(parent);
  }

  /**
   * Obtain the parent's outlinelevel number and adds 1
   * @param node Node target
   * @param parent Parent node
   */
  static obtainOutlineLevelFromParent(
    node: NS_TREE.ITreeRoot<undefined | string>,
    parent: NS_TREE.ITreeRoot<string | undefined>,
  ): void {
    node.data.content.rowParams.outlineLevel =
      typeof parent.data.content.rowParams.outlineLevel === 'number'
        ? parent.data.content.rowParams.outlineLevel + 1
        : 0;
  }

  /**
   * Update outline level iteratively starting from a branch
   * @param root Root node
   * @param branch Node representing the branch
   */
  static applyOutlineLevelToBranch(
    root: NS_TREE.ITreeRoot<undefined | string>,
    branch: NS_TREE.ITreeRoot<undefined | string>,
  ): void {
    const queue = new Queue<NS_TREE.ITreeRoot<undefined | string>>();
    for (const child of branch.children) {
      queue.enqueue(child);
    }
    while (!queue.isEmpty()) {
      const current = queue.dequeue();
      if (current) {
        if (current.parent) {
          const parentNode = this.searchNodeById(root, current.parent);
          if (parentNode) {
            this.obtainOutlineLevelFromParent(current, parentNode);
          }
        } else {
          current.data.content.rowParams.outlineLevel = 1;
        }
        for (const child of current.children) {
          queue.enqueue(child);
        }
      }
    }
  }

  /**
   * Resets the childOrder property to the nodes contained by the children
   * list of a parent.
   * @param parent Parent node which children list will be updated
   */
  static resetChildOrder(parent: NS_TREE.ITreeRoot<undefined | string>): void {
    for (let i = 0; i < parent.children.length; i++) {
      parent.children[i].data.content.childOrder = i;
    }
  }

  /**
   * Find a common node in the path to the root between two nodes,
   * so the worst/default case is returning the root node.
   * @param root Root node
   * @param nodeA Node A
   * @param nodeB Node B
   */
  static commonParent(
    root: NS_TREE.ITreeRoot<undefined | string>,
    nodeA: NS_TREE.ITreeRoot<undefined | string>,
    nodeB: NS_TREE.ITreeRoot<undefined | string>,
  ): NS_TREE.ITreeRoot<undefined | string> | null {
    const idPathToRootA = this.nodePathToRoot(root, nodeA);
    const idPathToRootB = this.nodePathToRoot(root, nodeB);

    // find first element in common between two lists
    for (const idA of idPathToRootA) {
      for (const idB of idPathToRootB) {
        if (idA === idB) {
          return this.searchNodeById(root, idA);
        }
      }
    }
    return null;
  }

  /**
   * Traces the path to the root of a node, returning a list of ids
   * @param root Root node
   * @param target Target node
   */
  static nodePathToRoot(
    root: NS_TREE.ITreeRoot<undefined | string>,
    target: NS_TREE.ITreeRoot<undefined | string>,
  ): Array<string> {
    const path: string[] = [];

    let curr = this.searchNodeById(root, target.parent as string);
    while (curr) {
      path.push(curr.id);
      curr = this.searchNodeById(root, curr.parent as string);
    }
    return path;
  }

  /**
   * Find tree node by rowContentId
   * @param root
   * @param rowContentId
   */
  static findTreeNodeByRowContentId(
    root: NS_TREE.ITreeRoot<undefined | string>,
    rowContentId: string,
  ): NS_TREE.ITreeRoot<undefined | string> {
    let res: any = null;
    const loopTree = (node: NS_TREE.ITreeRoot<undefined | string>) => {
      if (!node) {
        return;
      }

      if (node.data && node.data.content.rowContentId === rowContentId) {
        res = node;
      } else {
        if (node.data.content.rowContentType !== 'N' && node.children) {
          for (const child of node.children) {
            loopTree(child);
          }
        }
      }
    };
    loopTree(root);
    return res;
  }

  /**
   * Find tree nodes by rowContentIds
   * @param root
   * @param rowContentIds
   */
  static findTreeNodesByRowContentIds(
    root: NS_TREE.ITreeRoot<undefined | string>,
    rowContentIds: Array<string>,
  ): { [rowContentId: string]: NS_TREE.ITreeRoot<undefined | string> } {
    const res = {};
    const loopTree = (node: NS_TREE.ITreeRoot<undefined | string>) => {
      if (!node) {
        return;
      }
      if (node.data && rowContentIds.indexOf(node.data.content.rowContentId) !== -1) {
        res[node.data.content.rowContentId] = node;
      }

      if (node.data.content.rowContentType !== 'N' && node.children && Object.keys(res).length < rowContentIds.length) {
        for (const child of node.children) {
          loopTree(child);
        }
      }
    };
    loopTree(root);
    return res;
  }

  /**
   * Find opened tree nodes
   * @param root
   */
  static findOpenedTreeNodeIds(root: NS_TREE.ITreeRoot<undefined | string>): Array<string> {
    const res: Array<string> = [];
    const loopTree = (node: NS_TREE.ITreeRoot<undefined | string>) => {
      if (!node) {
        return;
      }

      if (node.data && node.data.content.rowParams.opened) {
        res.push(node.data.content.rowContentId);
      }

      if (node.data.content.rowContentType !== 'N' && node.children && node.data.content.rowParams.opened) {
        for (const child of node.children) {
          loopTree(child);
        }
      }
    };
    loopTree(root);
    return res;
  }

  /**
   * By looping statement, push calculated values in nodes of report, update nodes' open status
   * @param root
   * @param nodesContent
   * @param nodesContentIndices
   */
  static loopTreeStatementToApplyParams(
    statement,
    nodesContent: NS_API.INodesContent,
    nodesContentIndices: NS_API.INodesContentIndices,
    openedNodeIds?: Array<string>,
  ) {
    const loopTree = (node: NS_TREE.ITreeRoot<undefined | string>) => {
      if (!node) {
        return;
      }
      const nodeId = node.id;

      // Push calculated data
      if (nodesContent[nodeId] && node.data) {
        const formulaGroupValues = nodesContent[nodeId][0];
        const formulaValuesArr = nodesContentIndices.calculation.values.map((colValue) => {
          const formulaIndex = nodesContentIndices.calculation.operations[colValue.o];
          return formulaGroupValues[formulaIndex][colValue.i];
        });
        node.data.content.value.push(...formulaValuesArr);
      }

      // Update open status
      if (openedNodeIds && openedNodeIds.indexOf(node.data.content.rowContentId) !== -1) {
        node.data.content.rowParams.opened = true;
      }

      if (node.data.content.rowContentType !== 'N' && node.children) {
        for (const child of node.children) {
          loopTree(child);
        }
      }
    };

    if (
      (openedNodeIds && openedNodeIds.length > 0) ||
      (nodesContentIndices?.calculation?.values.length > 0 && Object.keys(nodesContent).length > 0)
    ) {
      loopTree(statement[0]);
    }

    return statement;
  }

  /**
   * DFS to search tree nodes with given key word(rowname or rowid)
   * @param root
   * @returns array of tree node id
   */
  static searchTreeNodeIdsByKeyword(root: NS_TREE.ITreeRoot<undefined>, keyword: string): Array<string> {
    const queue = new Queue();
    const result: string[] = [];
    if (root) {
      queue.enqueue(root);
      while (!queue.isEmpty()) {
        const currentNode: NS_TREE.ITreeRoot<undefined> = queue.dequeue();
        if (
          currentNode.data.content.rowContentName.toLowerCase().includes(keyword.toLowerCase()) ||
          (currentNode.data.content.rowContentId &&
            currentNode.data.content.rowContentId.toLowerCase().includes(keyword.toLowerCase()))
        ) {
          result.push(currentNode.data.id);
        }
        if (currentNode.children) {
          for (const child of currentNode.children) {
            queue.enqueue(child);
          }
        }
      }
    }
    return result;
  }
}
