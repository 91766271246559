import React from 'react';

const numeral = require('numeral');

export const getSpecificSize = (globalSize: number, percentage: number) => {
  return Math.floor((globalSize * percentage) / 100);
};

export const getHeight = (percentage: number) => {
  return getSpecificSize(window.innerHeight, percentage);
};

export const getWidth = (percentage: number) => {
  return getSpecificSize(window.innerWidth, percentage);
};

//report cell background color
export const rowHighlighted1 = '#46B78E';
export const rowHighlighted2 = '#F4F4F4';

//drag and expand icone width
export const iconWidth = 30;

//minimum value is 1
export const columnSeparator = 10;

export const currencyFormat = (_format: string, value, displayUnit: 1 | 1000 | 1000000 = 1) => {
  const dec = Number(_format.split(',')[1]);
  const shift = Math.pow(10, dec);
  value = Number(value) / (displayUnit * shift);
  return value;
};

export const toCurrency = (currencyFormatParam: string, value: number) => {
  let currVal = numeral(value).format(currencyFormatParam);
  currVal = currVal.toString().replace(/,/g, ' ');
  currVal = currVal.replace('.', ',');
  return currVal;
};

export const wrappedTextDiv = (
  style: React.CSSProperties,
  text: string | number,
  strong = false,
  capitalize = false,
  currency: string | undefined = undefined,
  className: string | undefined = undefined,
) => {
  if (typeof text === 'string' && capitalize) {
    text = text.trim();
    text = text.charAt(0).toLocaleUpperCase() + text.toLocaleLowerCase().slice(1);
  }
  if (currency) {
    const currencyArr = currency.split('.');
    const decimal = currencyArr[1] ? currencyArr[1].length : 0;
    const formatString = `(0,0${Array.from({ length: decimal }).reduce((pre, _cur) => pre + '0', '.')})`;
    const formatedText = toCurrency(formatString, parseFloat(text.toString()));

    text = formatedText;
  }
  return (
    <div style={{ flex: 1, color: 'inherit', maxWidth: '100%' }} className={className}>
      <div
        style={{ ...style, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
        data-cy="cell-value">
        {strong ? <strong>{text}</strong> : text}
      </div>
    </div>
  );
};

export const compareTwoFlatArrays = (arrayA: any[], arrayB: any[]) => {
  if (arrayA && arrayB) {
    if (arrayA.length === arrayB.length) {
      const test = Array.from({ length: arrayA.length }, () => false);

      for (let i = 0; i < arrayA.length; i++) {
        const a = arrayA[i];
        const b = arrayB[i];
        test[i] = a === b;
      }

      return test.every((t) => t);
    }
  }
  return false;
};

export const getRandomLightColor = () => {
  return `rgb(${Math.floor(Math.random() * 56) + 200}, ${Math.floor(Math.random() * 56) + 200}, ${
    Math.floor(Math.random() * 56) + 200
  })`;
};

export const countNotNullValuesOnTable = (table) => {
  let count = 0;

  for (const row of table) {
    for (const cell of row) {
      if (typeof cell !== 'undefined') {
        if (typeof cell === 'string' && cell.length && typeof cell === 'number' && !isNaN(cell)) {
          count++;
        }
      }
    }
  }
  return count;
};

export const sumTableValues = (table) => {
  let sum = 0;
  for (const row of table) {
    for (const cell of row) {
      if (typeof cell !== 'undefined') {
        const tryValue = Number(cell);
        if (!isNaN(tryValue)) {
          sum += tryValue;
        }
      }
    }
  }
  return sum;
};

export const getAccountingLineValue = (column: NS_Table.IColumnHeader, value: number) => {
  let separator = false;
  if (column.Header === 'formula' && column.colParams.colFormula) {
    if (String(column.colParams.colFormula.index) === '0') {
      separator = true;
    }
  }
  return { value, separator };
};

export const renderContent = (val: boolean, trueContent: any, falseContent: any) => {
  return val ? trueContent : falseContent;
};

export const createEmptyArray = (end: number) => {
  if (isNaN(end) || end <= 0) {
    return [];
  } else {
    return Array.from({ length: end }, () => 0);
  }
};
