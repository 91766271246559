export interface IReportInfo {
  reportName: string;
  id: string;
  reportActivated: boolean;
  templateKey: TemplateKey;
  yearOrMonth: string;
  cumulDisplay?: boolean;
  accessible: boolean;
}

export interface IDataSourceInformation {
  fileId: string;
  workspaceId: string;
  card: string;
  report?: string;
}

export enum EAnalytical {
  NONE = 0,
  ONE = 1,
  TWO = 2,
}

export const MAP_ANALYTICAL: Record<number, number> = {
  [EAnalytical.NONE]: EAnalytical.NONE,
  [EAnalytical.ONE]: EAnalytical.ONE,
  [EAnalytical.TWO]: EAnalytical.TWO,
};

export enum EAnalyticalExclude {
  ACODE = 'ACODE',
  ALIB = 'ALIB',
}

export enum TemplateKey {
  CB = 'CB',
  CS = 'CS',
  CR = 'CR',
  PP = 'PP',
  CHR1 = 'CHR1',
  CF = 'CF',
  BS = 'BS',
  PL = 'PL',
  PN = 'PN',
}

export enum ChartFamily {
  PL = 'PL',
  BS = 'BS',
  C = 'Cash',
}

export enum ChartType {
  PIE = 'doughnut2d',
  COLUMN = 'column2d',
  COLUMN_MEDIAN = 'mscolumn2d',
  COLUMN_STACKED = 'stackedcolumn2d',
  COLUMN_STACKED_2 = 'msstackedcolumn2dlinedy',
  PIE_TWO = 'pie2d',
  LINE_MEDIAN = 'line',
  SPLINE = 'spline',
  MULTIPLE_SPLINE = 'msspline',
  WATERFALL = 'waterfall2d',
  FLOW_IN_FLOW_OUT = 'msstackedcolumn2d',
}

export enum ChartDisplayType {
  PIE = 'PIE',
  PIE_PERCENT = 'PIE_PERCENT',
  COLUMN_STACKED = 'COLUMN_STACKED',
  COLUMN_STACKED_2 = 'COLUMN_STACKED_2',
  LINE_MEDIAN = 'LINE_MEDIAN',
  COLUMN_2D_MEDIAN = 'COLUMN_2D_MEDIAN',
  FLOW_IN_FLOW_OUT = 'FLOW_IN_FLOW_OUT',
  WATERFALL = 'simpleEffect',
}

export const ReportsList: Array<IReportInfo> = [
  {
    reportName: 'Profit and Loss - Yearly',
    id: '',
    reportActivated: true,
    templateKey: TemplateKey.PL,
    yearOrMonth: 'year',
    cumulDisplay: false,
    accessible: true,
  },
  {
    reportName: 'Profit and Loss - Monthly - Flow',
    id: '',
    reportActivated: true,
    templateKey: TemplateKey.PL,
    yearOrMonth: 'month',
    cumulDisplay: false,
    accessible: true,
  },
  {
    reportName: 'Balance Sheet - Yearly',
    id: '',
    reportActivated: true,
    templateKey: TemplateKey.BS,
    yearOrMonth: 'year',
    cumulDisplay: false,
    accessible: true,
  },
  {
    reportName: 'Balance Sheet - Monthly - Cumul',
    id: '',
    reportActivated: true,
    templateKey: TemplateKey.BS,
    yearOrMonth: 'month',
    cumulDisplay: true,
    accessible: true,
  },
  {
    reportName: 'Cash Flow - Yearly',
    id: '',
    reportActivated: true,
    templateKey: TemplateKey.CF,
    yearOrMonth: 'year',
    cumulDisplay: false,
    accessible: true,
  },
  {
    reportName: 'Cash Flow - Monthly - Flow',
    id: '',
    reportActivated: true,
    templateKey: TemplateKey.CF,
    yearOrMonth: 'month',
    cumulDisplay: false,
    accessible: true,
  },
  {
    reportName: 'Cash Balance - Monthly - Flow',
    id: '',
    reportActivated: true,
    templateKey: TemplateKey.CB,
    yearOrMonth: 'month',
    accessible: true,
  },
  {
    reportName: 'Cash Report - Monthly - Flow',
    id: '',
    reportActivated: true,
    templateKey: TemplateKey.CR,
    yearOrMonth: 'month',
    accessible: true,
  },
  {
    reportName: 'Sales By Client - Yearly',
    id: '',
    reportActivated: true,
    templateKey: TemplateKey.CS,
    yearOrMonth: 'year',
    accessible: true,
  },
  {
    reportName: 'Purchases by supplier',
    id: '',
    reportActivated: true,
    templateKey: TemplateKey.PP,
    yearOrMonth: 'year',
    accessible: true,
  },
];

export const DatasourceTypes = ['General Ledger', 'Trial Balance', 'Journal'];

export const FiscalYears = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const DefaultTemplateCollectionName = 'Standard French Mapping';

export enum REPORT_TEMPLATE {
  SALES_BY_CLIENT = 'Sales By Client',
  PURCHASES_BY_SUPPLIER = 'Purchases by Supplier',
}

export const DEFAULT_XLSX_WORKSHEET_NAME = 'Sheet1';
export const SUPPORTED_FORMATS = [
  'text/plain',
  'text/x-csv',
  'application/csv',
  'application/x-csv',
  'text/csv',
  'text/comma-separated-values',
  'text/x-comma-separated-values',
  'text/tab-separated-values',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
];

// Data source states enum
export enum states {
  DEFAULT,
  PROCESSING,
  GENERATED,
  ERROR,
  STARTING, // FIX ME
  DELETING,
}

export enum ModalTypes {
  DELETE_DATASOURCE,
  SUPPORT,
  REPORT_CREATOR,
  INTERPRETATION,
  CONCLUSION,
  BEGIN_STRUCTURATION,
}
export interface IDataSourceModal {
  isModalConclusionOpen: boolean;
  isModalImportErrorOpen: boolean;
  isModalCreateDataSourceOpen: boolean;
  isModalImportDataSourceOpen: boolean;
  isModalDeleteDataSourceOpen: boolean;
  isModalSupportOpen: boolean;
  isModalReportCreatorOpen: boolean;
  openUnionModal: boolean;
}

export interface IDataSourceResize {
  resizeHeight: number;
  startResize: number;
  newMousePos: number;
}

export interface IDatasourceParameters {
  supportIssueDescription: string; // Support
  isDataSourceFullScreen: boolean; // Read values passed on state
  leftDisposition: boolean; // Read values passed on state
  isImport: boolean;
  reasonsImportError: any;
  emptyList: boolean;
}

export interface IDatasourceUnion {
  unionLoading: boolean;
  backToUnionDatasource: undefined | NS_Workspace.IDataSourcesFile;
  unionDatasourceFileIds: Array<string>;
  isDataSourceValidated?: boolean;
}

export interface IDatasourceState {
  currentDatasource: NS_Workspace.IDataSourcesFile | undefined;
  selectedDatasource: NS_Workspace.IDataSourcesFile | undefined;
  deletingDatasourceIds: Array<string>;
}

export interface IDatasourceUploadProcess {
  worksheets: Array<string>;
  currentUploadFile: File | null;
  fileUploaded: Array<File & { isExist?: boolean }>;
}

export interface IDataSourceNotification {
  workspaceId: string;
  datasourceId: string;
  notificationMessage: string;
  errorMessage?: string;
}

export const INTERPRETATION_DONE = 'INTERPRETATION_DONE';

export enum ANALYTYCAL_VALUE {
  ONE = '',
}
