import './style.scss';

import React, { useCallback } from 'react';

import redCross from '../../assets/img/cross-red.svg';
import { renderContent } from '../../shared/helpers/helpers';
import SpinnerLoader from '../Loaders/SpinnerLoader';

export enum BtnClassType {
  Normal = 'button--green',
  Delete = 'button--red',
  Cancel = 'button--white',
}

interface ICustomModal {
  /**
   * Title label.
   */
  title: string;
  /**
   * Modal close hanlder.
   */
  onClose: () => void;
  /**
   * Confirm button click handler.
   */
  onConfirm: () => void;
  /**
   * Cancel button click handler.
   */
  onCancel: () => void;
  /**
   * Cancel button label.
   * @default Confirm
   */
  cancelLabel?: string;
  /**
   * Confirm button label.
   * @default Cancel
   */
  confirmLabel?: string;
  /**
   * Confirm button type.
   * @default Normal
   */
  confirmBtnType?: BtnClassType;
  /**
   * Cancel button type.
   * @default Cancel
   */
  cancelBtnType?: BtnClassType;
  /**
   * Is cancel button disabled?
   * @default false
   */
  cancelDisabled?: boolean;
  /**
   * Don't display cancel button
   */
  cancelDisplay?: boolean;
  /**
   * Is confirm button disabled?
   * @default false
   */
  confirmDisabled?: boolean;
  /**
   * Custom modal class name.
   */
  className?: string;
  /**
   * Don't display confirm button
   */
  confirmDisplay?: boolean;
  /**
   * Should Display confim loader.
   * @default false
   */
  confirmLoader?: boolean;
  /**
   * Confirm loader color.
   * @default 46b78e
   */
  confirmLoaderColor?: string;
  /**
   * Should display modal close icon?
   * @default false
   */
  closeIcon?: boolean;
  /**
   * Modal body element
   */
  children?: React.ReactNode;
}

/**
 * A custom pop-out dialog window
 */
const CustomModal: React.FC<ICustomModal> = ({
  onCancel,
  onClose,
  onConfirm,
  title,
  children,
  cancelLabel,
  confirmLabel,
  confirmBtnType,
  cancelBtnType,
  cancelDisabled = false,
  confirmDisabled = false,
  className,
  confirmLoader,
  confirmLoaderColor = '#46b78e',
  closeIcon = false,
  confirmDisplay = false,
  cancelDisplay = false,
}) => {
  const handleCancle = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleConfirm = useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  const renderBtnClassType = useCallback((btnIndex: number) => {
    if (btnIndex === 0) {
      return cancelBtnType ?? 'button--white';
    } else if (btnIndex === 1) {
      return confirmBtnType ?? 'button--green';
    } else {
      return '';
    }
  }, []);

  return (
    <div
      className={`customModalContainer ${className ?? ''}`}
      onClick={(e) => {
        const element = e.target as HTMLElement;
        if (element.className.indexOf('customModalContainer') !== -1) {
          onClose();
        }
      }}>
      <div className="customModal">
        <div className="customModalHeader">
          <strong>{title}</strong>
          {closeIcon && <img src={redCross} alt="exit cross" onClick={onClose} />}
        </div>
        <div className="customModalBody">{children}</div>
        <div className="customModalFooter">
          {!cancelDisplay ? (
            <button
              disabled={cancelDisabled}
              className={`${renderBtnClassType(0)} ${renderContent(
                cancelDisabled,
                renderBtnClassType(0) + '--disabled',
                '',
              )}`}
              onClick={handleCancle}>
              {cancelLabel ?? 'Cancel'}
            </button>
          ) : (
            <div></div>
          )}
          {!confirmDisplay && (
            <div className="confirmButtonContainer">
              {confirmLoader && <SpinnerLoader color={confirmLoaderColor} />}
              <button
                disabled={confirmDisabled}
                className={`${renderBtnClassType(1)} ${renderContent(
                  confirmDisabled,
                  renderBtnClassType(1) + '--disabled',
                  '',
                )}`}
                onClick={handleConfirm}>
                {confirmLabel ?? 'Confirm'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
