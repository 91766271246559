export const FileActions = {
  GenerateTrialBalance: 'GEN_TB',
  Join: 'JOIN',
  Delete: 'DELETE',
  Visualize: 'VISUALIZE',
  Union: 'UNION',
};

export const FileTypes = {
  TrialBalance: 'TB',
  GeneralLedger: 'GL',
  GeneralLedgerEnriched: 'GLE',
  JournalEntry: 'JE',
};

export class AccountingFileConsts {
  static compatibleWithActionGenerateTB = [FileTypes.GeneralLedger, FileTypes.GeneralLedgerEnriched];
  static compatibleWithActionMerge = [FileTypes.GeneralLedger, FileTypes.JournalEntry, FileTypes.GeneralLedgerEnriched];
  static joinPriorityFilesToBeEnriched = [FileTypes.GeneralLedger, FileTypes.GeneralLedgerEnriched];
}

export const RequiredColumnsCounterparts = ['_NumLine', 'EntryDebit', 'EntryCredit', 'JournalID'];
export const TransactionColumnsCounterparts = ['TransactionID', 'EntryID'];
