export const REPORT_MAX_TOP_VALUE = 100;

export enum FILTER_LABEL {
  SELECT_ALL = 'Select All',
  DESELECT_ALL = 'Deselect All',
  CREATE = 'Create',
  DELETE = 'Delete',
}

// DS = DATA_SOURCE
export enum DATA_SOURCE_MESSAGE {
  // Modal information
  CONFIRM_DS_LABEL = 'Go validate the card',
  CONFIRM_DS_DESCRIPTION = `You can't generate a report until the card of the Data Source has been validated`,
  CONFIRM_DS_UNION = `You can't make an union until all the card of the Data Source has been validated`,
  CONFIRM_DS_JOURNAL = `To Validate the Card, you must confirm the journal opening entries`,
  // Question 2
  DS_NOT_OPENING_IDENTIFIED = `Scorf was not able to identify the journal of Opening Entries`,
  DS_OPENING_VALIDATED_FALSE = `It seems that this data source does not have any opening entries. Please confirm.`,
  DS_OPENING_VALIDATED_OPENING_FALSE = `Opening entries have been identified but you still need to confirm`,
  DS_OPENING_VALIDATED = `Opening entries have been identified and validated`,
  DS_OPENING_NONE_BUT_VALIDATED = `This Data Source does not include any journal of Openings`,
  DS_OPENING_NONE_NOT_VALIDATED = `This Data Source does not include any journal of Openings, but you still need to confirm`,
  DS_OPENING_NONE_NOT_VALIDATED_CONFIRM = `This Data Source does not include any journal of Openings`,
  // Question 3
  DS_BALANCED = `Opening entries are balanced`,
  DS_NOT_BALANCED = `Opening entries are not balanced`,
}

export enum REPORT_WARNING_MESSAGE {
  TOP_VALUE = 'You can not vizualise more than top hundred clients',
}

export enum COVERAGE_AUTO_ALERTS {
  INFORM_TO_SAVE = "Don't forget to save so that the report is updated",
}

export const REPORT_ERROR_MESSAGE = [
  {
    key: 'ERR_RPA_1',
    value: `The data source does not include the opening entries,
    which is why the opening column is empty`,
  },
  {
    key: 'ERR_RPA_2',
    value: `
    The data source does not include the opening entries. Unless this is the first year of the business, the below Cash Flow statement is wrong`,
  },
  {
    key: 'ERR_RPA_3',
    value: `The opening entries are not balanced, which is why the assets do not equal the liabilities`,
  },
  {
    key: 'ERR_RPA_4',
    value: `The opening entries are not balanced, so the below cash flow statement is wrong`,
  },
  {
    key: 'ERR_RPA_5',
    value: `Keep in mind that opening entries are not balanced, so the below figures must be taken with caution`,
  },
  {
    key: 'ERR_RPA_6',
    value: `Not only is the data source unbalanced, but the opening entries are missing, so the below table is probably meaningless`,
  },
  {
    key: 'ERR_RPA_7',
    value: `Keep in mind that the Data source is not balanced, so the below figures could be wrong`,
  },
  {
    key: 'ERR_RPA_8',
    value: `The Data source is not balanced, which is why the assets do not equal the liabilities`,
  },
  {
    key: 'ERR_RPA_9',
    value: `The Data source is not balanced, so the below cash flow statement is wrong`,
  },
  {
    key: 'ERR_RPA_10',
    value: `The data source and the opening entries are not balanced, so the table below is probably meaningless`,
  },
  {
    key: 'ERR_RPA_11',
    value: `Not only is the data source unbalanced, but the opening entries are missing, so the below table is probably meaningless`,
  },
  {
    key: 'INFORM_TO_SAVE',
    value: COVERAGE_AUTO_ALERTS.INFORM_TO_SAVE,
  },
];

export enum API_STATUS {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PARTIALLY_EXECUTED = 'PARTIALLY EXECUTED',
}

export enum REPORT_TABS_TYPE {
  REPORT = 'Report',
  CHART_REPORT = 'ChartReport',
}
