import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IReportAssemblyNotif } from '../../shared/constant/chart.consts';
import { ChartFamily } from '../../shared/constant/datasource.consts';

export interface IChartReport {
  worksheetId: string;
  reportAssembly: NS_API.IReportAssemblyData;
  includedReportData: Array<NS_API.IReportAssemblyIncludedReportData>;
  templateCollectionName?: string;
}

export interface ISelectedWorkspace {
  selectedWorkspace: string;
  selectedWorksheetId: string;
}

export interface IChartState {
  selectedWorkspace: string;
  selectedWorksheetId: string;
  chartReports: Array<IChartReport>;
  chartReady: Array<IReportAssemblyNotif>;
  fetchReport: boolean;
  selectTemplateKey: ChartFamily;
}

const initialState: IChartState = {
  selectedWorkspace: '',
  selectedWorksheetId: '',
  chartReports: [],
  chartReady: [],
  fetchReport: false,
  selectTemplateKey: ChartFamily.PL,
};

export const chartSlice = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    setActiveWorkspaceAndWorksheet: (state, action: PayloadAction<ISelectedWorkspace>) => {
      state.selectedWorkspace = action.payload.selectedWorkspace;
      state.selectedWorksheetId = action.payload.selectedWorksheetId;
    },
    setNewChartReport: (state, action: PayloadAction<IChartReport>) => {
      const isExist = state.chartReports.find((c) => c.worksheetId === action.payload.worksheetId);
      if (!isExist?.worksheetId) {
        state.chartReports = [...state.chartReports, action.payload];
      } else {
        state.chartReports = [
          ...state.chartReports.map((c) => {
            if (c.worksheetId === action.payload.worksheetId) {
              return action.payload;
            }
            return c;
          }),
        ];
      }
    },
    setNewChartReady: (state, action: PayloadAction<IReportAssemblyNotif>) => {
      const isExist = state.chartReady.find((c) => JSON.stringify(c) === JSON.stringify(action.payload));
      if (!isExist?.reportAssemblyId) {
        state.chartReady = [...state.chartReady, action.payload];
      }
    },
    setReportCollectionName: (
      state,
      action: PayloadAction<{ worksheetId: string; templateCollectionName: string }>,
    ) => {
      const { worksheetId, templateCollectionName } = action.payload;
      state.chartReports = [
        ...state.chartReports.map((ch) => {
          if (ch.worksheetId === worksheetId) {
            return { ...ch, templateCollectionName };
          }
          return ch;
        }),
      ];
    },
    fetchSelectedReport: (state, action: PayloadAction<boolean>) => {
      state.fetchReport = action.payload;
    },
    setSelectedTemplateKey: (state, action: PayloadAction<ChartFamily>) => {
      state.selectTemplateKey = action.payload;
    },
  },
});

export const {
  setActiveWorkspaceAndWorksheet,
  setNewChartReport,
  setNewChartReady,
  setReportCollectionName,
  fetchSelectedReport,
  setSelectedTemplateKey,
} = chartSlice.actions;

export default chartSlice.reducer;
