// Style
import './style.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { FixedSizeList as List } from 'react-window';

import { IColumnHeader } from '../..';
import ClearSVG from '../../../../../assets/img/filter-clear.svg';
import SearchSVG from '../../../../../assets/img/search.svg';
import SortASCSVG from '../../../../../assets/img/sort-asc.svg';
import SortDESCSVG from '../../../../../assets/img/sort-desc.svg';
import Checkbox from '../../../../../elements/Checkbox';
import {
  buildDateTreeView,
  DateTreeNode,
  getDateSelection,
  matchStringWildcard,
  setExpandedById,
  setSelectionById,
  SortMode,
} from '../../../../../shared/helpers/ELS-Filter';
import { renderContent } from '../../../../../shared/helpers/helpers';

interface ICustomHeaderProps {
  column: IColumnHeader;
  indexColumn: number;
  toggleCheckHeader: (a: string, b: number, e: MouseEvent, c?: boolean) => void;
  validateFilters: () => void;
  clearFilter: (indexColumn: number) => void;
  close: () => void;
  sortRows: (type: SortMode.ASC | SortMode.DESC, indexCol: number) => void;
  tableBodyHeight: number;
  HeaderRef: React.RefObject<HTMLDivElement>;
}

/**
 * Component used to display the data visualisation
 *
 * @param param IMappingNodeProps props
 */

const CustomHeader: React.FC<ICustomHeaderProps> = ({
  column,
  indexColumn,
  toggleCheckHeader,
  validateFilters,
  clearFilter,
  close,
  sortRows,
  tableBodyHeight,
  HeaderRef,
}) => {
  const [dateTreeRoot, setDateTreeRoot] = useState<DateTreeNode | null>();
  const [searchedResult, setSearchedResult] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [disableValidate, setDisableValidate] = useState<boolean>(false);

  useEffect(() => {
    if (column.scorfHeader === 'EntryDate' && (!column.ELSFilter.dateViewRoot || !column.ELSFilter.isFiltered)) {
      const dateViewRoot = buildDateTreeView(column);
      setDateTreeRoot(dateViewRoot);
    } else if (column.scorfHeader === 'EntryDate' && column.ELSFilter.dateViewRoot) {
      setDateTreeRoot(column.ELSFilter.dateViewRoot);
    }
  }, [column]);

  useEffect(() => {
    if (Object.values(column.ELSFilter.selection).every((x) => !x)) {
      setDisableValidate(true);
    } else {
      setDisableValidate(false);
    }
  }, [column.ELSFilter.selection]);

  const isAllChecked = () => {
    if (searchedResult.length > 0) {
      return searchedResult.every((option) => column.ELSFilter.selection[option]);
    } else if (
      Object.keys(column.ELSFilter.selection).length === column.ELSFilter.displayedOptions.length ||
      column.ELSFilter.displayedOptions.length === 0
    ) {
      return Object.values(column.ELSFilter.selection).every((isTrue) => isTrue);
    } else {
      return column.ELSFilter.displayedOptions.every((option) => column.ELSFilter.selection[option]);
    }
  };

  const validateDate = () => {
    if (dateTreeRoot) {
      const selection = getDateSelection(dateTreeRoot);
      column.ELSFilter.selection = selection;
      column.ELSFilter.dateViewRoot = dateTreeRoot;
      validateFilters();
    }
  };

  const toggleExpanded = (id, newValue) => {
    if (dateTreeRoot) {
      const tempTreeRoot = Object.assign({}, dateTreeRoot);
      setExpandedById(tempTreeRoot, id, newValue);
      setDateTreeRoot(tempTreeRoot);
    }
  };

  const toggleSelection = (id, newValue) => (e) => {
    if (dateTreeRoot) {
      const tempTreeRoot = Object.assign({}, dateTreeRoot);
      setSelectionById(tempTreeRoot, id, newValue);
      setDateTreeRoot(tempTreeRoot);
    }
  };

  const sortDate = (a: DateTreeNode, b: DateTreeNode): number => {
    if (a.numericMonth && b.numericMonth) {
      return a.numericMonth - b.numericMonth;
    } else if (parseInt(a.name) && parseInt(b.name)) {
      return parseInt(a.name) - parseInt(b.name);
    } else {
      return 1;
    }
  };

  const renderDate = (dateTree: DateTreeNode, offset: number) => {
    return (
      <>
        <div
          className="rowResult"
          style={{ paddingLeft: dateTree.children.length > 0 ? offset * 15 : offset * 15 + 20 }}>
          {dateTree.children.length > 0 && (
            <div className="imgContainer" onClick={() => toggleExpanded(dateTree.id, !dateTree.expanded)}>
              <div>{dateTree.expanded ? '-' : '+'}</div>
            </div>
          )}
          <Checkbox checked={dateTree.selected} toggle={toggleSelection(dateTree.id, !dateTree.selected)} />
          <div className="result">{dateTree.name}</div>
        </div>
        {dateTree.expanded &&
          dateTree.children.length > 0 &&
          dateTree.children
            .sort((a, b) => sortDate(a, b))
            .map((dateTreeChildren) => {
              return renderDate(dateTreeChildren, offset + 1);
            })}
      </>
    );
  };

  const handleToggleCheckHeader = (rowValue: string) => (e) => {
    toggleCheckHeader(rowValue, indexColumn, e);
  };

  const countItem = useCallback(() => {
    if (searchedResult.length > 0) {
      return searchedResult.length;
    } else if (
      Object.keys(column.ELSFilter.selection).length === column.ELSFilter.displayedOptions.length ||
      column.ELSFilter.displayedOptions.length === 0
    ) {
      return Object.keys(column.ELSFilter.selection).length;
    } else {
      return column.ELSFilter.displayedOptions.length;
    }
  }, [column.ELSFilter.selection, column.ELSFilter.displayedOptions, searchedResult]);

  const handleSearch = (e: string) => {
    if (e !== '') {
      let searchResult: string[];
      if (column.ELSFilter.displayedOptions.length > 0) {
        searchResult = column.ELSFilter.displayedOptions.filter((selectionRow) => {
          column.ELSFilter.selection[selectionRow] = matchStringWildcard(selectionRow, e);
          return matchStringWildcard(selectionRow, e);
        });
      } else {
        const selectionKey = Object.keys(column.ELSFilter.selection);
        searchResult = selectionKey.filter((selectionRow) => {
          column.ELSFilter.selection[selectionRow] = matchStringWildcard(selectionRow, e);
          return matchStringWildcard(selectionRow, e);
        });
      }
      if (searchResult.length > 0) {
        setDisableValidate(false);
        setSearchedResult(searchResult);
      } else {
        setSearchedResult(['noresult']);
        setDisableValidate(true);
      }
    } else {
      const selectionKey = Object.keys(column.ELSFilter.selection);
      selectionKey.forEach((selectionRow) => {
        column.ELSFilter.selection[selectionRow] = true;
      });
      setDisableValidate(false);
      setSearchedResult([]);
    }
    setSearchValue(e);
  };

  const validate = useCallback(() => {
    if (!disableValidate) {
      dateTreeRoot ? validateDate() : validateFilters();
    }
  }, [disableValidate, dateTreeRoot]);

  const handleKeyPressEvent = (ev: KeyboardEvent) => {
    if (ev.key === 'Enter') {
      validate();
    } else if (ev.key === 'Escape') {
      close();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPressEvent);
    return () => {
      window.removeEventListener('keydown', handleKeyPressEvent);
    };
  }, [validate]);

  const handleSelectAll = (e) => {
    if (searchedResult.length === 0) toggleCheckHeader('', indexColumn, e, true);
  };

  const renderRow = (propsVirtList) => {
    const { index, style } = propsVirtList;
    const selectionKey = Object.keys(column.ELSFilter.selection);

    let rowValue: string;

    if (searchedResult.length > 0) {
      rowValue = searchedResult[index];
    } else if (
      selectionKey.length === column.ELSFilter.displayedOptions.length ||
      column.ELSFilter.displayedOptions.length === 0
    ) {
      rowValue = selectionKey[index];
    } else {
      rowValue = column.ELSFilter.displayedOptions[index];
    }
    if (rowValue === 'noresult') {
      return (
        <div key={index} className={'rowResult'} style={style}>
          <div className="result" style={{ marginLeft: 15 }}>
            No result.
          </div>
        </div>
      );
    }
    return (
      <div key={index} className={'rowResult'} style={style}>
        <Checkbox
          key={index}
          checked={column.ELSFilter.selection[rowValue]}
          toggle={handleToggleCheckHeader(rowValue)}
        />
        <div className="result">{rowValue === '' ? 'Blanks' : rowValue}</div>
      </div>
    );
  };
  return (
    <div className="HeaderContextMenu" ref={HeaderRef} style={{ maxHeight: tableBodyHeight }}>
      <div className="removeColumn name" onClick={() => clearFilter(indexColumn)}>
        <ReactSVG src={ClearSVG} className="svg-wrapper clearFilterSVG" />
        Clear Filter
      </div>
      <div className="name" onClick={() => sortRows(SortMode.ASC, indexColumn)}>
        <ReactSVG src={SortASCSVG} className="svg-wrapper sortAscSVG" />
        Sort ASC
      </div>
      <div className="name" onClick={() => sortRows(SortMode.DESC, indexColumn)}>
        <ReactSVG src={SortDESCSVG} className="svg-wrapper sortDescSVG" />
        Sort DESC
      </div>
      <div className="searchContainer">
        <ReactSVG src={SearchSVG} className="svg-wrapper searchSVG" />
        <input
          type="text"
          placeholder="Search"
          onChange={(e) => handleSearch(e.target.value)}
          maxLength={99}
          defaultValue={searchValue}
        />
      </div>
      <div className="selectContainer">
        {column.scorfHeader === 'EntryDate' ? (
          <div className="headerDateContainer">
            <div className="dateContainer" style={{ height: 300 }}>
              {dateTreeRoot && renderDate(dateTreeRoot, 0)}
            </div>
          </div>
        ) : (
          <>
            <div className={'rowResult rowResultAll'}>
              <Checkbox checked={isAllChecked()} toggle={handleSelectAll} />
              <div className="result">Select All</div>
            </div>
            <List
              height={140}
              itemCount={countItem()}
              width={'100%'}
              itemSize={35}
              className="headerCustomResultContainer">
              {renderRow}
            </List>
          </>
        )}
      </div>
      <div className="buttonsHeaderContainer">
        <button className="button--white" onClick={close}>
          Cancel
        </button>
        <button
          className={renderContent(disableValidate, 'button--green--disabled', 'button--green')}
          onClick={() => validate()}>
          Ok
        </button>
      </div>
    </div>
  );
};

export default CustomHeader;
