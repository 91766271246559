import './style.scss';

import React from 'react';

import { useMsal } from '@azure/msal-react';

import backgroundLogo from '../../assets/img/logo-scorf-background-grey-light.svg';
import logoImg from '../../assets/img/logo-scorf-img.svg';
import logoText from '../../assets/img/logo-scorf-text.svg';
// Style
import timeOutImg from '../../assets/img/timeout.svg';
import { doLogout } from '../../authConfig';

/**
 * Timeout page container
 */

const Timeout: React.FC = () => {
  const { instance } = useMsal();

  const handleClick = () => {
    (async () => {
      await doLogout(instance);
    })();
  };

  return (
    <div className={'timeOutRoot'}>
      <img className="background" src={backgroundLogo} alt="" />
      <div className="header">
        <img src={logoImg} alt="" />
        <img src={logoText} alt="" />
      </div>
      <img src={timeOutImg} alt="" />
      <p>Session timed out !</p>
      <div className={'content'}>
        It looks like you were inactive for too long. As a result, the server had to end your session and disconnect
        you. You can reconnect by clicking on the button right below.
        <div className={'button--green'} onClick={handleClick}>
          <span>Back to Sign In</span>
        </div>
      </div>
    </div>
  );
};

export default Timeout;
