import { FC, useContext, useEffect, useState } from 'react';

import { WorkspaceContext } from '../../../../context/WorkspaceContext';
import { datasourceComplianceCheck } from '../../../../modals/CreateReportsPack/checkDatasourceCompliance';
import { setDataSourceInformations } from '../../../../redux/datasource';
import { useAppDispatch, useAppSelector } from '../../../../redux/hook';
import { getGraph } from '../../../../shared/api/graph';
import { getDatasourceCard } from '../../../../shared/api/structuration';
import {
  DefaultTemplateCollectionName,
  IDataSourceInformation,
  IReportInfo,
  states,
} from '../../../../shared/constant/datasource.consts';
import { generateExportList } from '../../../../shared/helpers/datasource/datasource.helper';
import ToastHelper from '../../../../shared/helpers/toast/ToastHelper';

interface IUseDatasourceInformation {
  datasource: NS_Workspace.IDataSourcesFile;
}

const useDatasourceInformation: FC<IUseDatasourceInformation> = (props) => {
  const { datasource } = props;
  const [loading, setLoading] = useState(false);
  const { workspaceId } = useContext(WorkspaceContext);
  const dispatch = useAppDispatch();
  const reduxState = useAppSelector((state) => state);
  const { informations } = reduxState.datasource;
  const { templateCollections } = reduxState.workspace;

  const initDatasourceInformation: IDataSourceInformation = {
    fileId: '',
    card: '',
    workspaceId,
  };

  useEffect(() => {
    if (datasource.fileId) {
      if (datasource.structurationStatus === states.GENERATED && datasource.status !== `${states.PROCESSING}`) {
        handleCardInformation();
      }
    }
  }, [datasource]);

  const handleCardInformation = () => {
    const isExist = informations.findIndex(
      (information) => information.fileId === datasource.fileId && information.workspaceId === workspaceId,
    );
    if (isExist === -1) {
      setLoading(true);
      getDatasourceCard(workspaceId, datasource.fileId)
        .then(async (res) => {
          setLoading(false);
          if (res !== undefined) {
            const { data } = res;
            const dataObj = data.interpretationCard;
            const card = JSON.stringify(dataObj);
            const standardTC = templateCollections.filter(
              (tc) => tc.collectionName === DefaultTemplateCollectionName,
            )[0];
            const defaultTC = standardTC.collectionName;
            const selectedTCIndex = templateCollections.map((tc) => tc.collectionName).indexOf(defaultTC);
            const graphId = templateCollections[selectedTCIndex].graphId ?? datasource.graphId;
            const staticReportsList = [...generateExportList()];
            const datasourceCard = {
              eachEntryHasOneTransactionId: dataObj.eachEntryHasOneTransactionId,
              eachTransactionIdHasAtLeastTwoEntries: dataObj.eachTransactionIdHasAtLeastTwoEntries,
              areAuxAccountIdentified: dataObj.areAuxAccountIdentified,
            };
            const getGraphOutput = await getGraph(workspaceId, graphId);
            const templateParams = getGraphOutput.data?.graph?.templateParams;
            if (!templateParams) {
              ToastHelper.error(`Failed to load Graph Template Parameters`);
            }
            const templateParamsKeysSet = new Set<string>(Object.keys(templateParams));
            let reportsToUse: Array<IReportInfo> = [];
            reportsToUse = staticReportsList.filter((value) => templateParamsKeysSet.has(value.templateKey));
            reportsToUse.forEach(
              function (_value, index, array) {
                const reportInfo = array[index];
                const reportInfoTemplateKey = reportInfo.templateKey;

                const checkResult = datasourceComplianceCheck[reportInfoTemplateKey](
                  datasourceCard,
                  reportInfo.reportName,
                  datasource?.unionInfo,
                );

                if (checkResult === false) {
                  array[index].accessible = false;
                  array[index].reportActivated = false;
                }
              }.bind(this),
            );
            dispatch(
              setDataSourceInformations({
                ...initDatasourceInformation,
                fileId: datasource.fileId,
                card,
                report: JSON.stringify(reportsToUse),
              }),
            );
          }
        })
        .catch((error) => {
          setLoading(false);
          ToastHelper.error(`Cannot get card information`, error);
        });
    }
  };

  return { loading } as any;
};

export default useDatasourceInformation;
