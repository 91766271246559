// Style
import './style.scss';

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import rgbHex from 'rgb-hex';

import { WorkspaceContext } from '../../context/WorkspaceContext';
import CustomPicker, { IColor } from './CustomPicker';

interface IColorPickerProps {
  /**
   * Color hash value
   */
  color: string;
  /**
   * Color change handler
   */
  handleChangeColor: (color: string) => void;
  /**
   * Close handler
   */
  handleClose: () => void;
  /**
   * Color picker header label
   */
  titleText: string;
  /**
   * If validate color change after closing
   */
  asyncColorChangeComplete?: boolean; // When true, validate color change when close color picker.
}

const generateStandardColors = (colorOptions) => {
  const standardColors: { color: string; title: string }[] = [];
  for (const key in colorOptions) {
    if (key !== 'chartColors') {
      standardColors.push({ color: colorOptions[key], title: key });
    }
  }
  return standardColors;
};

/**
 * A color picker of react-color wrapper
 */
const ColorPicker: React.FC<IColorPickerProps> = ({
  color,
  handleChangeColor,
  handleClose,
  titleText,
  asyncColorChangeComplete = false,
}) => {
  const colorPickerRef = useRef<HTMLDivElement>(null);

  const { colorOptions, setPreferredColors, preferredColors, workspaceId } = useContext(WorkspaceContext);
  const [selectedColor, setSelectedColor] = useState<string>('');
  const colorRef = useRef<string>(selectedColor);
  const [colorChange, setColorChange] = useState<boolean>(false);
  const changeRef = useRef<boolean>(colorChange);

  useEffect(() => {
    colorRef.current = selectedColor;
  }, [selectedColor]);

  useEffect(() => {
    changeRef.current = colorChange;
  }, [colorChange]);

  useEffect(() => {
    setSelectedColor(color);

    const handleClickOutSideColorPicker = (e: MouseEvent) => {
      if (colorPickerRef.current && !(colorPickerRef.current as any).contains(e.target)) {
        onClose();
      }
    };
    const handleEscKeyDown = (e) => {
      if (e.code === 'Escape') {
        handleClose();
      }
    };

    window.addEventListener('mouseup', handleClickOutSideColorPicker);
    document.addEventListener('keydown', handleEscKeyDown);
    return () => {
      window.removeEventListener('mouseup', handleClickOutSideColorPicker);
      document.removeEventListener('keydown', handleEscKeyDown);
    };
  }, []);

  const onClose = useCallback(() => {
    handleClose();
    if (!colorRef.current || !changeRef.current) {
      return;
    }

    if (asyncColorChangeComplete && changeRef.current) {
      handleChangeColor(colorRef.current);
    }

    const newPresetColors: string[] = [];
    Object.assign(newPresetColors, preferredColors);
    if (newPresetColors.indexOf(colorRef.current) === -1) {
      if (newPresetColors.length >= 16) {
        newPresetColors.pop();
      }
    } else {
      if (newPresetColors[0] === colorRef.current) {
        return;
      }
      newPresetColors.splice(newPresetColors.indexOf(colorRef.current), 1);
    }
    newPresetColors.unshift(colorRef.current);

    setPreferredColors(workspaceId, newPresetColors);
  }, [workspaceId, colorRef, preferredColors, asyncColorChangeComplete, changeRef]);

  const onChange = useCallback((colorArg: IColor, _event) => {
    const colorHex = `#${rgbHex(colorArg.rgb.r, colorArg.rgb.g, colorArg.rgb.b, colorArg.rgb.a)}`;
    if (!asyncColorChangeComplete) {
      handleChangeColor(colorHex);
    }
    setSelectedColor(colorHex);
    setColorChange(true);
  }, []);

  return (
    <div className="colorPicker" ref={colorPickerRef}>
      <CustomPicker
        color={selectedColor}
        onChange={onChange}
        standardColors={generateStandardColors(colorOptions)}
        recentColors={preferredColors}
        onClose={onClose}
        titleText={titleText}
      />
    </div>
  );
};

export default ColorPicker;
