// Style
import './style.scss';

import { useCallback, useContext, useEffect, useState } from 'react';

import ChartBodyContainer from '../../components/chartReportComponents/BodyContainer';
import FooterCharts from '../../components/chartReportComponents/FooterContainer/Footer';
import ChartTopContainer from '../../components/chartReportComponents/TopContainer';
import { WorkspaceContext } from '../../context/WorkspaceContext';
import { fetchSelectedReport, IChartReport, setNewChartReport, setSelectedTemplateKey } from '../../redux/charts';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import { getReportAssembly } from '../../shared/api/reportAssembly';
import { getAllTemplateCollectionsForWorkspace } from '../../shared/api/templateCollection';
import { TChartData } from '../../shared/charts/utils';
import { ChartFamily } from '../../shared/constant/datasource.consts';
import ToastHelper from '../../shared/helpers/toast/ToastHelper';

export interface IHeaderData {
  dataSourceName: string;
  templateCollectionName: string;
  selectedTemplateKey: string;
}

export interface ISplitReport {
  PL: Array<TChartData>;
  BS: Array<TChartData>;
  Cash: Array<TChartData>;
}

const formalizedData = (_chartReports: IChartReport | undefined, key: string): Array<TChartData> => {
  if (!_chartReports?.reportAssembly) return [];
  const { reports, isUnion } = _chartReports.reportAssembly;
  const { includedReportData } = _chartReports;
  return reports
    .filter((r) => r.chartFamily === key && r.reportStatus !== 2)
    .map((pl) => {
      const includedReport = includedReportData.find((ir) => ir.includedReportId === pl.includedReportId);
      return { ...pl, ...includedReport, isUnion };
    }) as any;
};

const ChartReport = ({ worksheetId, workspaceId }) => {
  const initHeaderData: IHeaderData = {
    dataSourceName: '',
    templateCollectionName: '',
    selectedTemplateKey: '',
  };
  const { generatedDataSources } = useContext(WorkspaceContext);
  const reduxState = useAppSelector((state) => state);
  const { chartReports, fetchReport, selectTemplateKey } = reduxState.chart;
  const { templateCollections } = reduxState.workspace;
  const activeChartReports = chartReports.find((ch) => ch.worksheetId === worksheetId);
  const splitReports = {
    PL: formalizedData(activeChartReports, ChartFamily.PL),
    BS: formalizedData(activeChartReports, ChartFamily.BS),
    Cash: formalizedData(activeChartReports, ChartFamily.C),
  };

  const dispatch = useAppDispatch();
  const [headerData, setHeaderData] = useState<IHeaderData>({
    ...initHeaderData,
  });

  useEffect(() => {
    const cacheChartReports = chartReports.find((ch) => ch.worksheetId === worksheetId);
    if (chartReports?.length && worksheetId && cacheChartReports?.worksheetId && !fetchReport) {
      getReduxChartData(cacheChartReports, worksheetId).catch((err) => {});
    } else {
      fetchReportAssembly().catch((err) => {});
    }
  }, [worksheetId, workspaceId, fetchReport]);

  const fetchReportAssembly = () => {
    return getReportAssembly(workspaceId, worksheetId)
      .then(async (result) => {
        if (result?.data) {
          const { reportAssembly } = result.data;
          let resultData = { ...initHeaderData };
          if (reportAssembly?.datasourceId) {
            const currentDataSource = generatedDataSources.find((el) => el.fileId === reportAssembly?.datasourceId);
            resultData.dataSourceName = currentDataSource.displayName;
          }
          if (reportAssembly?.graphId) {
            await getTemplateCollectionName().then((res) => {
              if (res) {
                res.forEach((template) => {
                  if (template.graphId === reportAssembly.graphId) {
                    resultData.templateCollectionName = template.collectionName;
                  }
                });
              }
            });
          }
          setHeaderData({ ...resultData });
          dispatch(
            setNewChartReport({
              worksheetId,
              reportAssembly: result.data.reportAssembly,
              includedReportData: result.data.includedReportData,
              templateCollectionName: resultData.templateCollectionName,
            }),
          );
        }
        dispatch(fetchSelectedReport(false));
      })
      .catch((err) => {
        ToastHelper.error('Failed to load this report assembly');
      });
  };

  const getReduxChartData = async (cacheChartReports: IChartReport, worksheetId: string) => {
    const { includedReportData, reportAssembly } = cacheChartReports;
    let resultData = { ...initHeaderData };
    if (reportAssembly?.datasourceId) {
      const currentDataSource = generatedDataSources.find((el) => el.fileId === reportAssembly?.datasourceId);
      resultData.dataSourceName = currentDataSource.displayName;
    }
    if (cacheChartReports?.templateCollectionName) {
      resultData.templateCollectionName = cacheChartReports.templateCollectionName;
    } else if (reportAssembly?.graphId) {
      await getTemplateCollectionName().then((res) => {
        if (res) {
          res.forEach((template) => {
            if (template.graphId === reportAssembly.graphId) {
              resultData.templateCollectionName = template.collectionName;
            }
          });
        }
      });
    }

    setHeaderData({ ...resultData });
    dispatch(
      setNewChartReport({
        worksheetId,
        reportAssembly: reportAssembly,
        includedReportData: includedReportData,
        templateCollectionName: resultData.templateCollectionName,
      }),
    );
  };

  const getTemplateCollectionName = async () => {
    if (!templateCollections?.length) {
      return getAllTemplateCollectionsForWorkspace(workspaceId).then((res) => res.data);
    }
    return templateCollections;
  };

  const handleSelect = useCallback(
    (templateKey: ChartFamily) => (event: any) => {
      dispatch(setSelectedTemplateKey(templateKey));
    },
    [],
  );

  return (
    <div className="chartReport">
      <ChartTopContainer data={headerData} selectTemplateKey={selectTemplateKey} handleSelect={handleSelect} />
      <ChartBodyContainer templateKey={selectTemplateKey} splitReports={splitReports} />
      <FooterCharts />
    </div>
  );
};

export default ChartReport;
