import './style.scss';

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';

import Skeleton from '@mui/material/Skeleton';

import UpArrowSVG from '../../../../assets/img/chevron-up.svg';
import LockSVG from '../../../../assets/img/lock.svg';
import { ReportContext } from '../../../../context/ReportContext';
import { WorkspaceContext } from '../../../../context/WorkspaceContext';
import { lineDetailOverview } from '../../../../shared/api/report';
import { renderContent } from '../../../../shared/helpers/helpers';
import { isFirstOrHoveredIndentation } from '../../../../shared/helpers/string/classnames.helper';
import ToastHelper from '../../../../shared/helpers/toast/ToastHelper';
import TreeHelper from '../../../../shared/helpers/tree/TreeHelper';
import EditCell from './EditCell';
import EditContextMenu from './EditContextMenu';
import MapCell from './MapCell';

export interface IMappingNode {
  children: IMappingNode[];
  id: string;
  name: string;
  rowDimension?: string;
  rowValue?: string;
  measure: string;
  templateCoefficient: number;
  rowParams: IRowParams;
}

export interface IRowParams {
  opened: boolean;
  locked: boolean;
  cssStyle: string;
}

interface IMappingArrayProps {
  disabled: boolean;
  originalHeader: Array<NS_Table.IColumnHeader>;
  datasourceId: string;
  tree: NS_API.IGraph;
  treeFromNodeToOpen: NS_API.IGraph;
  setCellSelected: (val: Array<string>) => void;
  cellSelected: Array<string>;
  cellToBeMoved: string;
  setCellToBeMoved: (val: string) => void;
  simpleDeleteMappingElement: () => void;
  deepDeleteMappingElement: () => void;
  simpleDeleteMappingAccount: () => void;
  addParent: () => void;
  addChild: () => void;
  startMoveElement: () => void;
  endMoveElement: (val: string) => void;
  onRename: (newName: string, id: string) => void;
  onLocked: (id: string) => void;
  hoveredNodeId: string;
  setHoveredNodeId: (val: string) => void;
  levelHoveredNode: number;
  level: number;
  unCollapse: (val: string) => void;
  isContextMenuOpen: string;
  setIsContextMenuOpen: (val: string) => void;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  isDraggingState: boolean;
  header: Array<NS_Table.IColumnHeader>;
  currencyParams: NS_Table.ICurrency;
  setNameOfNodeSelected: (val: NS_REPORT.INameSelectedForCoverageProps) => void;
  setTypeOfVisualisation: (val: string) => void;
  handleMappingOpen: (typeOfVisualisation: string) => (e) => void;
  nameWidth: number;
  zoomValue: string;
  isCompatibleToCounterParts: boolean;
  nodeOpenedAccount: NS_TREE.IMining;
  setNodeOpenedAccount: React.Dispatch<React.SetStateAction<NS_TREE.IMining>>;
  setSelectedCellsValues: (val: Array<NS_TREE.ICellSelected>) => void;
  selectedCellsValues: Array<NS_TREE.ICellSelected>;
  indent: boolean;
  calculateNumberOfLines: () => void;
  selectedTemplate: string;
  columnsDimensions: string[];
  displayOpeningColumn: boolean;
  setSelectedCellsColumns: (val: number[]) => void;
  setSelectedCellsRowNodes: (val: NS_TREE.ITreeRootWithoutChild[]) => void;
  selectedCellsRowNodes: NS_TREE.ITreeRootWithoutChild[];
  selectedCellsColumns: number[];
  parentOpen: Array<string>;
  mappingTreeWithRoot: NS_API.IGraph;
  selectedCellsOutGroup: Array<NS_TREE.ICellSelected>;
  setSelectedCellsOutGroup: (val: Array<NS_TREE.ICellSelected>) => void;
  selectedCellsInGroup: Array<NS_TREE.ICellSelected>;
  groupDirection: string;
  updateOpenParent: (isChanged: boolean, isOpen?: boolean) => void;
  setAccountSelectedInReport: (val: string) => void;
  cellFound: string;
  editable: boolean;
  handlePropertiesOpen: (treeNode: NS_TREE.ITreeRoot<string>) => (e) => void;
}

/**
 * Component used to display the tree for the custom report
 *
 * @param param IMappingNodeProps props
 */

const EditTree: React.FC<IMappingArrayProps> = ({
  disabled = false,
  originalHeader,
  datasourceId,
  treeFromNodeToOpen,
  tree,
  setCellSelected,
  cellSelected,
  cellToBeMoved,
  setCellToBeMoved,
  addParent,
  addChild,
  simpleDeleteMappingElement,
  deepDeleteMappingElement,
  simpleDeleteMappingAccount,
  startMoveElement,
  endMoveElement,
  onRename,
  onLocked,
  hoveredNodeId,
  setHoveredNodeId,
  levelHoveredNode,
  level,
  unCollapse,
  isContextMenuOpen,
  setIsContextMenuOpen,
  setIsDragging,
  isDraggingState,
  header,
  currencyParams,
  setNameOfNodeSelected,
  setTypeOfVisualisation,
  handleMappingOpen,
  nameWidth,
  zoomValue,
  isCompatibleToCounterParts,
  nodeOpenedAccount,
  setNodeOpenedAccount,
  setSelectedCellsValues,
  selectedCellsValues,
  indent,
  calculateNumberOfLines,
  selectedTemplate,
  columnsDimensions,
  displayOpeningColumn,
  setSelectedCellsColumns,
  setSelectedCellsRowNodes,
  selectedCellsRowNodes,
  selectedCellsColumns,
  parentOpen,
  mappingTreeWithRoot,
  selectedCellsOutGroup,
  setSelectedCellsOutGroup,
  selectedCellsInGroup,
  groupDirection,
  updateOpenParent,
  setAccountSelectedInReport,
  cellFound,
  editable,
  handlePropertiesOpen,
}) => {
  // Workspace context
  const { workspaceId } = useContext(WorkspaceContext);
  const { currentAccountIdsWithMovedEntries, reportId } = useContext(ReportContext);

  // Refs
  const refTree = useRef<HTMLDivElement>(null);
  //ContextMenu
  const [xPos, setXPos] = useState<string>('');
  const [yPos, setYPos] = useState<string>('');

  // This fetching state may be deprecated
  const [isFetchingAccount, setIsFetchingAccount] = useState<boolean>(false);

  const [selectedHeader, setSelectedHeader] = useState<string>('Pivot');
  const [showLineInfo, setShowLineInfo] = useState<number>(-1);

  const [selectedLineDetail, setSelectedLineDetail] = useState<'overview' | 'counterparts' | 'pivot'>('overview');

  // left click
  const handleItemClick = (item, e) => {
    // Set last selected account in report (Used for searching it in accountsView)
    if (item.data.content.rowContentType === 'N') {
      setAccountSelectedInReport(item.data.content.rowContentId);
    }

    // 17 ctrl
    if (e.ctrlKey) {
      // Multi-selection
      e.preventDefault();
      setCellSelected([...cellSelected, item.data.content.rowContentId]);

      if (item.data.content.rowContentType !== 'N') {
        setNameOfNodeSelected({
          accountId: item.data.content.rowContentId,
          accountName: item.data.content.rowContentName,
        });
      }
    } else {
      // Single line selection
      setCellSelected([item.data.content.rowContentId]);

      if (item.data.content.rowContentType !== 'N') {
        setNameOfNodeSelected({
          accountId: item.data.content.rowContentId,
          accountName: item.data.content.rowContentName,
        });
      }
    }
    setIsContextMenuOpen('');
    e.stopPropagation();

    if (item.data.content.rowParams.opened && item.data.content.rowContentType === 'N') {
      setNodeOpenedAccount({
        nodeId: item.data.id,
        canCounterParts: isCompatibleToCounterParts,
      });
    }
  };

  //right click
  const handleContextMenu = (item, e) => {
    if (e.ctrlKey) {
      e.preventDefault();
      e.stopPropagation();
      setCellSelected([...cellSelected, item.data.content.rowContentId]);
      if (item.data.content.rowContentType !== 'N') {
        setNameOfNodeSelected({
          accountId: item.data.content.rowContentId,
          accountName: item.data.content.rowContentName,
        });
      }
    } else if (isContextMenuOpen !== item.data.content.rowContentId) {
      if (!cellSelected.includes(item.data.content.rowContentId)) {
        setCellSelected([item.data.content.rowContentId]);
      }
      if (editable && !disabled) {
        setIsContextMenuOpen(item.data.content.rowContentId);
      }
      setXPos(e.pageX);
      setYPos(e.pageY);
    }
    e.stopPropagation();
    e.preventDefault();
  };

  const setBackgroundColor = (item) => {
    const found = item.data.content.rowParams.cssStyle.match(/fill#\w{8}/g);
    if (found) {
      return found[0].substring(4);
    }
    //tablecell1
    if (item.data.content.rowParams.opened && item.parent.startsWith('R') && item.data.content.rowContentType !== 'N') {
      return 'var(--primaryFill)';
    }
    //tablecell2
    if (
      item.data.content.rowParams.opened &&
      !item.parent.startsWith('R') &&
      item.data.content.rowContentType !== 'N'
    ) {
      return 'var(--secondaryFill)';
    }

    return ``;
  };

  const setTextColor = (item) => {
    const found = item.data.content.rowParams.cssStyle.match(/color#\w{8}/g);
    if (found) {
      return found[0].substring(5);
    }
    //tablecell1
    if (item.data.content.rowParams.opened && item.parent.startsWith('R') && item.data.content.rowContentType !== 'N') {
      return 'var(--primaryText)';
    }
    //tablecell2
    if (
      item.data.content.rowParams.opened &&
      !item.parent.startsWith('R') &&
      item.data.content.rowContentType !== 'N'
    ) {
      return 'var(--secondaryText)';
    }

    return '';
  };

  const setShadowOpacity = (item, index) => {
    let opacity = 0;
    for (const cell of selectedCellsValues) {
      if (cell.nodeId === item.id && cell.nodeType === item.data.content.rowContentType && cell.colIndex === index) {
        opacity += 0.1;
      }
    }
    return opacity;
  };

  const separateSelectedCellsGroupWhenOpenLine = (rowOpened) => {
    const newOutGroup = [...selectedCellsOutGroup];
    const seperateIndex = selectedCellsRowNodes.findIndex((rowNode) => rowNode.id === rowOpened.id);
    if (seperateIndex !== -1) {
      const treeTemp = mappingTreeWithRoot[0] as NS_TREE.ITreeRoot<undefined>;
      const mockSlctdCell = {
        nodeId: rowOpened.id,
        nodeType: rowOpened.data.content.rowContentType,
        value: rowOpened.data.content.value[0],
        colIndex: 1,
      };

      const aboveCell = TreeHelper.findNextOrPreviousNode(treeTemp, mockSlctdCell, -1, parentOpen);

      if (aboveCell) {
        const previousNodeIndex = selectedCellsRowNodes.findIndex((rowNode) => rowNode.id === aboveCell.nodeId);
        const colLen = selectedCellsColumns.length;

        // Direction top to bottom
        if (previousNodeIndex !== -1 && previousNodeIndex === seperateIndex - 1) {
          const abovePart = selectedCellsRowNodes.slice(0, seperateIndex);
          const separatedCells = selectedCellsInGroup.slice(colLen * abovePart.length);
          setSelectedCellsOutGroup(newOutGroup.concat(separatedCells));
          setSelectedCellsRowNodes(abovePart);
        }
        // Direction bottom to top
        else if (previousNodeIndex !== -1 && previousNodeIndex === seperateIndex + 1) {
          const abovePart = selectedCellsRowNodes.slice(seperateIndex + 1);
          const separatedCells = selectedCellsInGroup.slice(
            0,
            colLen * (selectedCellsRowNodes.length - abovePart.length),
          );
          setSelectedCellsOutGroup(newOutGroup.concat(separatedCells));
          setSelectedCellsRowNodes(abovePart);
        } else {
          setSelectedCellsOutGroup(newOutGroup);
        }
      }
    } else {
      setSelectedCellsOutGroup(newOutGroup);
    }
  };

  const deleteInvisibleCellsFromSelected = (parentNode) => {
    if (!parentNode.data.content.rowParams.opened) {
      const newSelectedCellsRowNodes = [...selectedCellsRowNodes];
      const newSelectedCellsOutGroup = [...selectedCellsOutGroup];
      loopToDelete(newSelectedCellsRowNodes, newSelectedCellsOutGroup, parentNode);
      setSelectedCellsRowNodes(newSelectedCellsRowNodes);
      setSelectedCellsOutGroup(newSelectedCellsOutGroup);
    }
  };

  const loopToDelete = (newRows, newGroup, node) => {
    if (node && !node.data.content.rowParams.opened) {
      for (const childNode of node.children) {
        // Delete invisible cells from current group
        const indexInGroupRows = newRows.findIndex((el) => el.id === childNode.id);
        if (indexInGroupRows !== -1) {
          newRows.splice(indexInGroupRows, 1);
        }
        // Delete invisible cells from selected cells out group
        for (let i = newGroup.length - 1; i >= 0; i--) {
          if (newGroup[i].nodeId === childNode.id) {
            newGroup.splice(i, 1);
          }
        }
        if (node.data.content.rowContentType === 'C') {
          loopToDelete(newRows, newGroup, childNode);
        }
      }
    }
  };

  useEffect(() => {
    if (treeFromNodeToOpen.data.content.rowParams.opened && treeFromNodeToOpen.data.content.rowContentType === 'N') {
      setNodeOpenedAccount({ canCounterParts: isCompatibleToCounterParts, nodeId: treeFromNodeToOpen.data.id });
      setCellSelected([treeFromNodeToOpen.data.content.rowContentId]);
    }
  }, [treeFromNodeToOpen.data.content.rowParams.opened]);

  useEffect(() => {
    if (nodeOpenedAccount.nodeId === treeFromNodeToOpen.data.id) {
      if (treeFromNodeToOpen.children.length > 0 && treeFromNodeToOpen.children[0].data.content.nodeOpenedAccount) {
        setNodeOpenedAccount({ ...treeFromNodeToOpen.children[0].data.content.nodeOpenedAccount, noCall: true });
      } else {
        setNodeOpenedAccount({
          ...nodeOpenedAccount,
          selectedHeader: selectedHeader,
          selectedLineDetail: selectedLineDetail,
          noCall: false,
        });
      }
    }
  }, [nodeOpenedAccount.nodeId]);

  useEffect(() => {
    if (
      treeFromNodeToOpen.data.content.rowParams.opened &&
      treeFromNodeToOpen.data.content.measure === 'V' &&
      treeFromNodeToOpen.children.length === 0
    ) {
      ToastHelper.info('The lines of an element measured as a "variation" cannot be displayed');
    }
  }, [treeFromNodeToOpen.data.content.rowParams.opened]);

  //Get AccountingLines
  useEffect(() => {
    if (
      treeFromNodeToOpen.children.length === 0 &&
      treeFromNodeToOpen.data.content &&
      treeFromNodeToOpen.data.content.rowContentType === 'N' &&
      treeFromNodeToOpen.data.content.measure !== 'V' &&
      !nodeOpenedAccount.noCall
    ) {
      setIsFetchingAccount(true);
      lineDetailOverview(workspaceId, {
        reportId: reportId,
        reportNodeDataContent: treeFromNodeToOpen.data.content,
        pivotByColumn: selectedLineDetail === 'pivot' ? selectedHeader : undefined,
      })
        .then((res) => {
          setNodeOpenedAccount({ canCounterParts: isCompatibleToCounterParts, nodeId: treeFromNodeToOpen.data.id });
          setCellSelected([treeFromNodeToOpen.data.content.rowContentId]);
          treeFromNodeToOpen.children = res.data.lines.map((line, index) => {
            return {
              data: {
                content: {
                  rowContentId: `${treeFromNodeToOpen.id}-${index}`,
                  rowContentName: line[0],
                  value: line[1],
                  nodeOpenedAccount: {
                    canCounterParts: isCompatibleToCounterParts,
                    nodeId: treeFromNodeToOpen.data.id,
                  },
                },
              },
            };
          });
          calculateNumberOfLines();
          setIsFetchingAccount(false);
        })
        .catch((e) => {
          ToastHelper.error('Error while getting accounting lines', e);
        });
    }
    // eslint-disable-next-line
  }, [
    treeFromNodeToOpen.data.content.rowParams.opened,
    originalHeader,
    workspaceId,
    treeFromNodeToOpen,
    isCompatibleToCounterParts,
  ]);

  //Get AccountingLines
  useEffect(() => {
    if (
      treeFromNodeToOpen.data.content.rowParams.opened &&
      treeFromNodeToOpen.data.content.rowContentType === 'N' &&
      nodeOpenedAccount.nodeId === treeFromNodeToOpen.data.id &&
      (nodeOpenedAccount.selectedLineDetail !== 'overview' || nodeOpenedAccount.overview) &&
      nodeOpenedAccount.selectedLineDetail &&
      treeFromNodeToOpen.data.content.measure !== 'V' &&
      !nodeOpenedAccount.noCall
    ) {
      setIsFetchingAccount(true);

      let val: Promise<any>;
      if (nodeOpenedAccount.selectedLineDetail === 'counterparts') {
        // if counterparts
        val = lineDetailOverview(workspaceId, {
          reportId: reportId,
          reportNodeDataContent: treeFromNodeToOpen.data.content,
          isCounterparts: true,
        });
      } else {
        val = lineDetailOverview(workspaceId, {
          // if pivot or overview
          reportId: reportId,
          reportNodeDataContent: treeFromNodeToOpen.data.content,
          pivotByColumn:
            nodeOpenedAccount.selectedLineDetail === 'pivot' ? nodeOpenedAccount.selectedHeader : undefined,
        });
      }

      val
        .then((res) => {
          setNodeOpenedAccount({ ...nodeOpenedAccount, overview: false });
          treeFromNodeToOpen.children = res.data.lines.map((line) => {
            return {
              data: {
                content: {
                  rowContentName: line[0],
                  value: line[1],
                  nodeOpenedAccount: { ...nodeOpenedAccount, overview: false },
                },
              },
            };
          });

          if (nodeOpenedAccount.selectedLineDetail) {
            setSelectedLineDetail(nodeOpenedAccount.selectedLineDetail);
          }
          if (nodeOpenedAccount.selectedHeader) {
            setSelectedHeader(nodeOpenedAccount.selectedHeader);
          }
          setIsFetchingAccount(false);
        })
        .catch((e) => {
          ToastHelper.error('Error while getting accounting lines', e);
        });
    }
    // eslint-disable-next-line
  }, [nodeOpenedAccount.nodeId, nodeOpenedAccount.selectedHeader, nodeOpenedAccount.selectedLineDetail]);

  const tempArray = useMemo(() => {
    return Array.from({ length: level }, () => '');
  }, [tree, level]);

  const entryLines = useMemo(() => {
    return treeFromNodeToOpen.children.map((_el, rIndex) => ({
      id: treeFromNodeToOpen.id + rIndex,
      data: treeFromNodeToOpen.data,
      children: treeFromNodeToOpen.children,
      parent: treeFromNodeToOpen.id,
    }));
  }, [treeFromNodeToOpen.id, treeFromNodeToOpen.children.length]);

  const renderEntryLines = () => {
    return isFetchingAccount ? (
      <div className="editTreeContainer skeletonContainer" style={{ maxWidth: '100%' }}>
        {Array(7)
          .fill(0)
          .map((_row, Rkey) => {
            const index = `${level}-${Rkey}`;
            return (
              <div
                key={index}
                className="row"
                style={{
                  height: `calc(35px * ${zoomValue} / 10)`,
                  fontSize: `calc(0.875rem * ${zoomValue} / 10)`,
                  zIndex: showLineInfo === Rkey ? 1001 : 'auto',
                }}>
                {header.map((_column, Ckey) => {
                  if (Ckey === 0) {
                    const skeKey = `${Ckey}-${Rkey}`;
                    const divKey = `${level}-${Rkey}-${Ckey}`;
                    return (
                      <div className="stickyHeader" key={divKey}>
                        <div className="line">
                          {showLineInfo === Rkey && <div className={`lineInfo`}>{'Entrie'}</div>}
                        </div>
                        {tempArray.map((_element, index) => {
                          const key = `${index}-`;
                          return (
                            <div
                              className={`indentation ${isFirstOrHoveredIndentation(index, levelHoveredNode)}`}
                              key={key}
                              style={{ width: `calc(25px * ${zoomValue} / 10)` }}
                            />
                          );
                        })}
                        <div className={`name`}>
                          <Skeleton key={skeKey} />
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            );
          })}
      </div>
    ) : (
      <div className="editTreeContainer">
        {treeFromNodeToOpen.children.map((row, Rkey) => {
          const key = `${level}-${Rkey}`;
          return entryLines[Rkey] ? (
            <div
              key={key}
              className={`row ${renderContent(
                currentAccountIdsWithMovedEntries?.includes(treeFromNodeToOpen.data.content.rowContentId),
                'warningLine',
                '',
              )}`}
              data-title={renderContent(
                currentAccountIdsWithMovedEntries?.includes(treeFromNodeToOpen.data.content.rowContentId),
                'Save your changes to view them',
                '',
              )}
              data-cy="accountLine-row"
              style={{
                height: `calc(35px * ${zoomValue} / 10)`,
                fontSize: `calc(0.875rem * ${zoomValue} / 10)`,
                zIndex: showLineInfo === Rkey ? 1001 : 'auto',
                width: 'fit-content',
              }}>
              {header.map((_column, Ckey) => {
                const iKey = `${level}-${treeFromNodeToOpen.id + Rkey}-${Ckey}-div`;
                if (Ckey === 0) {
                  return (
                    <div className="stickyHeader" key={iKey}>
                      <div className="line">
                        {showLineInfo === Rkey && <div className={`lineInfo Entry`}>{'Entry'}</div>}
                      </div>
                      {tempArray.map((_element, index) => {
                        const key = `${index}-`;
                        return (
                          <div
                            className={`indentation ${isFirstOrHoveredIndentation(
                              index,
                              levelHoveredNode === level ? levelHoveredNode - 1 : levelHoveredNode,
                            )}`}
                            key={key}
                            onMouseEnter={() => setShowLineInfo(Rkey)}
                            onMouseLeave={() => setShowLineInfo(-1)}
                            style={{ width: `calc(25px * ${zoomValue} / 10)` }}
                          />
                        );
                      })}
                      <div
                        className={`name ${
                          level === levelHoveredNode + 1 && levelHoveredNode !== -1 && 'nameHovered'
                        } accountLine`}
                        style={{ width: `calc(${nameWidth + 100 - level * 25}px * ${zoomValue} / 10)` }}
                        onMouseEnter={() => setShowLineInfo(Rkey)}
                        onMouseLeave={() => setShowLineInfo(-1)}>
                        <div
                          style={{
                            fontSize: `calc(14px * ${zoomValue} / 10)`,
                            height: `calc(22px * ${zoomValue} / 10)`,
                          }}
                          className="nameInput">
                          {row.data.content.rowContentName.toLowerCase()}
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  const indexx = `${level}-${treeFromNodeToOpen.id + Rkey}-${Ckey}-div`;
                  return (
                    <React.Fragment key={indexx}>
                      <div
                        style={{
                          width: `calc(120px * ${zoomValue} / 10)`,
                        }}
                        className={`tableCell`}
                        onMouseEnter={() => setShowLineInfo(Rkey)}
                        onMouseLeave={() => setShowLineInfo(-1)}>
                        <MapCell
                          header={header}
                          node={entryLines[Rkey]}
                          currencyParams={currencyParams}
                          selectedCells={selectedCellsValues}
                          colIndex={Ckey}
                          setSelectedCellsRowNodes={setSelectedCellsRowNodes}
                          setSelectedCellsColumns={setSelectedCellsColumns}
                          selectedCellsRowNodes={selectedCellsRowNodes}
                          selectedCellsColumns={selectedCellsColumns}
                          setSelectedCellsOutGroup={setSelectedCellsOutGroup}
                          selectedCellsOutGroup={selectedCellsOutGroup}
                          selectedCellsInGroup={selectedCellsInGroup}
                          shadowOpacity={setShadowOpacity(entryLines[Rkey], Ckey)}
                          groupDirection={groupDirection}
                          isAccountLine={true}
                          rowIndex={Rkey}
                          mappingTreeWithRoot={mappingTreeWithRoot}
                          parentOpen={parentOpen}
                          treeFromNodeToOpen={treeFromNodeToOpen}
                          fetchAccountingLine={isFetchingAccount}
                        />
                      </div>
                    </React.Fragment>
                  );
                }
              })}
            </div>
          ) : null;
        })}
      </div>
    );
  };

  const renderLines = () => {
    return (
      <div className="editTreeContainer" ref={refTree}>
        {tree.map((item, key) => {
          const indexx = `${key}+${level}`;
          return (
            <div key={indexx} className="treePart">
              {item?.data?.content?.rowParams.opened && (
                <EditTree
                  disabled={disabled}
                  originalHeader={originalHeader}
                  datasourceId={datasourceId}
                  zoomValue={zoomValue}
                  treeFromNodeToOpen={item}
                  tree={item.children}
                  setCellSelected={setCellSelected}
                  cellSelected={cellSelected}
                  setNameOfNodeSelected={setNameOfNodeSelected}
                  cellToBeMoved={cellToBeMoved}
                  setCellToBeMoved={setCellToBeMoved}
                  onRename={onRename}
                  onLocked={onLocked}
                  hoveredNodeId={hoveredNodeId}
                  setHoveredNodeId={setHoveredNodeId}
                  levelHoveredNode={hoveredNodeId === item.id ? level : levelHoveredNode}
                  addParent={addParent}
                  addChild={addChild}
                  level={indent ? level + 1 : 0}
                  simpleDeleteMappingElement={simpleDeleteMappingElement}
                  deepDeleteMappingElement={deepDeleteMappingElement}
                  simpleDeleteMappingAccount={simpleDeleteMappingAccount}
                  startMoveElement={startMoveElement}
                  endMoveElement={endMoveElement}
                  unCollapse={unCollapse}
                  isContextMenuOpen={isContextMenuOpen}
                  setIsContextMenuOpen={setIsContextMenuOpen}
                  setIsDragging={setIsDragging}
                  isDraggingState={isDraggingState}
                  header={header}
                  currencyParams={currencyParams}
                  setTypeOfVisualisation={setTypeOfVisualisation}
                  handleMappingOpen={handleMappingOpen}
                  nameWidth={nameWidth}
                  isCompatibleToCounterParts={isCompatibleToCounterParts}
                  nodeOpenedAccount={nodeOpenedAccount}
                  setNodeOpenedAccount={setNodeOpenedAccount}
                  setSelectedCellsValues={setSelectedCellsValues}
                  selectedCellsValues={selectedCellsValues}
                  indent={indent}
                  calculateNumberOfLines={calculateNumberOfLines}
                  selectedTemplate={selectedTemplate}
                  columnsDimensions={columnsDimensions}
                  displayOpeningColumn={displayOpeningColumn}
                  setSelectedCellsRowNodes={setSelectedCellsRowNodes}
                  setSelectedCellsColumns={setSelectedCellsColumns}
                  selectedCellsRowNodes={selectedCellsRowNodes}
                  selectedCellsColumns={selectedCellsColumns}
                  parentOpen={parentOpen}
                  mappingTreeWithRoot={mappingTreeWithRoot}
                  setSelectedCellsOutGroup={setSelectedCellsOutGroup}
                  selectedCellsOutGroup={selectedCellsOutGroup}
                  selectedCellsInGroup={selectedCellsInGroup}
                  groupDirection={groupDirection}
                  updateOpenParent={updateOpenParent}
                  setAccountSelectedInReport={setAccountSelectedInReport}
                  cellFound={cellFound}
                  editable={editable}
                  handlePropertiesOpen={handlePropertiesOpen}
                />
              )}
              <div
                className={`${key === tree.length - 1 ? 'row rowFirst' : 'row'}`}
                onClick={(e) => {
                  console.log('click line', item);
                  handleItemClick(item, e);
                }}
                data-title={
                  currentAccountIdsWithMovedEntries?.includes(item.data.content.rowContentId)
                    ? 'Save your changes to view them'
                    : undefined
                }
                id={item.data.id}
                onContextMenu={(e) => handleContextMenu(item, e)}
                onMouseEnter={() => setHoveredNodeId(item.id)}
                onMouseLeave={() => setHoveredNodeId('')}
                style={{
                  fontSize: `calc(14px * ${zoomValue} / 10)`,
                  height: `calc(35px * ${zoomValue} / 10)`,
                }}>
                <div className="stickyHeader">
                  <div className="line">
                    {showLineInfo === key && (
                      <div className={`lineInfo ${item.data.content.rowContentType === 'N' ? 'Account' : 'Concept'}`}>
                        {item.data.content.rowContentType === 'N' ? 'Account' : 'Concept'}
                      </div>
                    )}
                    {showLineInfo !== key && (
                      <>
                        <ReactSVG
                          src={LockSVG}
                          className={`svg-wrapper lockSVG ${
                            item?.data?.content?.rowParams.locked ? 'lockImage lockImageSelected' : 'lockImage'
                          }`}
                          onClick={() => {
                            if (!disabled) {
                              onLocked(item.data.content.rowContentId);
                            }
                          }}
                        />
                        <ReactSVG
                          src={UpArrowSVG}
                          className={`svg-wrapper 
                          ${
                            disabled
                              ? 'tree-disable-arrow'
                              : `
                                arrowSVG ${renderContent(
                                  item.data.content.rowParams.opened,
                                  'arrowOpen',
                                  'arrowClose',
                                )} arrow`
                          }
                         `}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!disabled) {
                              item.data.content.rowParams.opened = !item.data.content.rowParams.opened;
                              updateOpenParent(true, item.data.content.rowParams.opened);
                              separateSelectedCellsGroupWhenOpenLine(item);
                              deleteInvisibleCellsFromSelected(item);
                              calculateNumberOfLines();
                            }
                          }}
                          id={`${item.data.content.rowContentName.toLowerCase().replace(/\s/g, '')}-chevron`}
                          data-cy={`${item.data.content.rowParams.opened ? 'closeChildrenArrow' : 'openChildrenArrow'}`}
                        />
                      </>
                    )}
                  </div>
                  {tempArray.map((_element, index) => {
                    const keyxx = `${index}-`;
                    return (
                      <div
                        onMouseEnter={() => setShowLineInfo(key)}
                        onMouseLeave={() => setShowLineInfo(-1)}
                        className={`indentation ${isFirstOrHoveredIndentation(index, levelHoveredNode)}`}
                        key={keyxx}
                        style={{ width: `calc(25px * ${zoomValue} / 10)` }}
                      />
                    );
                  })}
                  <EditCell
                    zoomValue={zoomValue}
                    item={item}
                    level={level}
                    startMoveElement={startMoveElement}
                    cellSelected={cellSelected}
                    cellToBeMoved={cellToBeMoved}
                    levelHoveredNode={levelHoveredNode}
                    onRename={onRename}
                    setCellSelected={setCellSelected}
                    setCellToBeMoved={setCellToBeMoved}
                    setIsDragging={setIsDragging}
                    isDraggingState={isDraggingState}
                    nameWidth={nameWidth}
                    setShowLineInfo={setShowLineInfo}
                    rowKey={key}
                    color={setTextColor(item)}
                    backgroundColor={setBackgroundColor(item)}
                    nodeOpenedAccount={nodeOpenedAccount}
                    cellFound={cellFound}
                    editable={item.data.content.rowContentType === 'C' && editable}
                    draggable={editable}
                  />
                </div>
                {item?.data?.content?.value.map((_value, index) => {
                  const keyxx = `${level}-${index}-${item.id}-div`;
                  return (
                    <div
                      key={keyxx}
                      style={{
                        width: `calc(120px * ${zoomValue} / 10)`,
                        color: setTextColor(item),
                        backgroundColor: setBackgroundColor(item),
                      }}
                      className={`tableCell ${item.data.content.rowParams.cssStyle} ${
                        item.parent.startsWith('R') ? 'rootTableCell' : null
                      }
                      ${cellSelected.includes(item.data.content.rowContentId) && 'tableCellSelected'}
                      ${cellToBeMoved === item.data.content.rowContentId && 'tableCellToBeMoved'} ${
                        item.data.content.rowParams.opened && level === 0 && item.parent.startsWith('R') && 'tableCell1'
                      } ${item.data.content.rowParams.opened && !item.parent.startsWith('R') && 'tableCell2'} ${
                        cellSelected.includes(item.data.content.rowContentId) && 'highlightedRow'
                      } ${item.data.id === cellFound ? 'tableCellFoundFromSearch' : null}`}
                      onMouseEnter={() => setShowLineInfo(key)}
                      onMouseLeave={() => setShowLineInfo(-1)}>
                      <MapCell
                        header={header}
                        node={item}
                        currencyParams={currencyParams}
                        selectedCells={selectedCellsValues}
                        colIndex={index + 1}
                        setSelectedCellsRowNodes={setSelectedCellsRowNodes}
                        setSelectedCellsColumns={setSelectedCellsColumns}
                        selectedCellsRowNodes={selectedCellsRowNodes}
                        selectedCellsColumns={selectedCellsColumns}
                        setSelectedCellsOutGroup={setSelectedCellsOutGroup}
                        selectedCellsOutGroup={selectedCellsOutGroup}
                        selectedCellsInGroup={selectedCellsInGroup}
                        shadowOpacity={setShadowOpacity(item, index + 1)}
                        groupDirection={groupDirection}
                        mappingTreeWithRoot={mappingTreeWithRoot}
                        parentOpen={parentOpen}
                        fetchAccountingLine={isFetchingAccount}
                      />
                    </div>
                  );
                })}
              </div>
              {isContextMenuOpen === item.data.content.rowContentId && (
                <EditContextMenu
                  isOpen={isContextMenuOpen === item.data.content.rowContentId}
                  setIsOpen={setIsContextMenuOpen}
                  xPos={xPos}
                  yPos={yPos}
                  addParent={addParent}
                  addChild={addChild}
                  simpleDeleteMappingElement={simpleDeleteMappingElement}
                  deepDeleteMappingElement={deepDeleteMappingElement}
                  simpleDeleteMappingAccount={simpleDeleteMappingAccount}
                  startMoveElement={startMoveElement}
                  endMoveElement={endMoveElement}
                  moveSelected={cellToBeMoved !== ''}
                  severalCellSelected={cellSelected.length > 1 ? true : false}
                  isAnAccount={item.data.content.rowContentType === 'N'}
                  typeOfNode={item.data.content.rowContentType}
                  setTypeOfVisualisation={setTypeOfVisualisation}
                  handleViewAccountsClick={handleMappingOpen('Accounts')}
                  handleViewEntriesClick={handleMappingOpen('Entries')}
                  handlePropertiesClick={handlePropertiesOpen(item as NS_TREE.ITreeRoot<string>)}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return <>{treeFromNodeToOpen.data.content.rowContentType === 'N' ? renderEntryLines() : renderLines()}</>;
};

export default EditTree;
