import './style.scss';

import React from 'react';

interface INumberPicker {
  /**
   * Current value
   */
  value: number;
  /**
   * Value change handler
   */
  onNumberChange: (e) => void;
  /**
   * Optional min value.
   * @default 0
   */
  minValue?: number;
  /**
   * Optional max value.
   * @default 100
   */
  maxValue?: number;
  /**
   * Optional value change step.
   * @default 1
   */
  step?: number;
  /**
   * Is number picker disabled. Default to false
   * @default false
   */
  disablePicker?: boolean;
  /**
   * Optional click handler
   */
  onMouseEnterInput?: (e) => void;
  /**
   * Optional click handler
   */
  onMouseLeaveInput?: (e) => void;
  /**
   * Optional click handler
   */
  onClickInput?: (e) => void;
}

/**
 * A simple number picker
 */
const NumberPicker: React.FC<INumberPicker> = ({
  minValue,
  maxValue,
  step,
  disablePicker,
  onNumberChange,
  value,
  onMouseEnterInput,
  onMouseLeaveInput,
  onClickInput,
}) => {
  const handleTypeNumber = (e) => {
    onNumberChange(e);
  };

  return (
    <div className="numberPickerContainer">
      <input
        id="number"
        type="number"
        value={value}
        disabled={disablePicker ?? false}
        onChange={handleTypeNumber}
        min={minValue ?? 0}
        max={maxValue ?? 100}
        step={step ?? 1}
        onMouseEnter={onMouseEnterInput}
        onMouseLeave={onMouseLeaveInput}
        onClick={onClickInput}
      />
    </div>
  );
};

export default NumberPicker;
