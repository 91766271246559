// Style
import './style.scss';

import React, { useCallback } from 'react';

import CustomModal, { BtnClassType } from '../../elements/CustomModal';

interface IDeleteWorkspaceProps {
  id: string;
  closeModal: () => void;
  deleteFn: (id: string) => Promise<void>;
  deleting: boolean;
}

/**
 * Delete workspace confirmation modal component
 *
 * @param params IDeleteWorkspaceProps params
 */

const DeleteWorkspaceModal: React.FC<IDeleteWorkspaceProps> = ({ id, closeModal, deleteFn, deleting }) => {
  const handleConfifm = useCallback(() => {
    deleteFn(id ?? '').catch((err) => {});
  }, [id]);
  return (
    <CustomModal
      title="Delete Workspace"
      onConfirm={handleConfifm}
      onClose={closeModal}
      onCancel={closeModal}
      confirmLabel="Delete workspace"
      confirmBtnType={BtnClassType.Delete}
      className="deleteWorkspaceModal"
      confirmDisabled={deleting}
      confirmLoader={deleting}
      confirmLoaderColor={'#f45067'}
      cancelDisabled={deleting}>
      <p>
        Are you sure you want to delete this workspace ? <br />
        If you delete this, you will permanently lose your work on it.
      </p>
    </CustomModal>
  );
};

export default DeleteWorkspaceModal;
