import '../../chartReportComponents/base.scss';
import './style.scss';

import { FC, useRef, useState } from 'react';

import FileItem from './FileItem/FileItem';

interface IDSFileUpload {
  files: Array<File>;
  fileUploaded: Array<File>;
  isImport: boolean;
  currentUploadFile?: File | null;
  setFiles: (files: Array<File>) => void;
  closeModal(): void;
  removeFile: (file: File) => void;
  handleImport(): void;
}

const DSFileUpload: FC<IDSFileUpload> = ({
  files,
  fileUploaded,
  currentUploadFile,
  isImport,
  closeModal,
  setFiles,
  removeFile,
  handleImport,
}) => {
  const [opacity, setOpacity] = useState('opacity75');

  const onMouseOver = () => {
    setOpacity('opacity1');
  };

  const onMouseOut = () => {
    setOpacity('opacity75');
  };

  const uploadFileRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (isImport) return;
    if (uploadFileRef.current) {
      uploadFileRef.current.click();
    }
  };

  const handleChange = (e: any) => {
    if (e.target.files) {
      if (files.length) {
        const inputFiles = e.target.files as Array<File>;
        setFiles([...files, ...inputFiles]);
      } else setFiles(Array.from(e.target.files));
    }
  };

  return (
    <div
      className="dataSourceImportContainer"
      onClick={(e) => {
        const element = e.target as HTMLElement;
        if (!isImport && element.className.indexOf('dataSourceImportContainer') !== -1) {
          closeModal();
        }
      }}>
      <div className="dataSourceImportModal">
        <div className="dataSourceImportFile">
          <div className="dataSourceImportList">
            <label className="block mb-4">
              <input
                multiple
                name="file"
                type="file"
                className="peer hidden"
                onChange={handleChange}
                ref={uploadFileRef}
              />
            </label>

            {files.length > 0 && (
              <div className="selected-files my-4 bg-[#F6F7F9] rounded-[10px] p-3 flex-1">
                {files.map((file, index) => {
                  const isCurrentUpload: boolean =
                    !!currentUploadFile?.name &&
                    !!currentUploadFile?.type &&
                    currentUploadFile.name === file.name &&
                    currentUploadFile.type === file.type;
                  const isNotUploaded = fileUploaded?.find((f) => JSON.stringify(f) === JSON.stringify(file));
                  const key = `key-${index}`;
                  if (!isNotUploaded)
                    return (
                      <div key={key} className="fileitem">
                        <FileItem file={file} removeFile={removeFile} isCurrentUpload={isCurrentUpload} />
                      </div>
                    );
                })}
              </div>
            )}
          </div>
          {!isImport && (
            <button
              type="button"
              onClick={handleClick}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              disabled={isImport}
              className={`flex cursor-pointer btn-upload border-none bg-transparent align-items-center ${opacity}`}>
              <svg className="add-files" viewBox="0 0 24 24">
                <path
                  d="M11 11H7.995c-.54 0-.995.448-.995 1 0 .556.446 1 .995 1H11v3.005c0 .54.448.995 1 .995.556 0 1-.446 1-.995V13h3.005c.54 0 .995-.448.995-1 0-.556-.446-1-.995-1H13V7.995c0-.54-.448-.995-1-.995-.556 0-1 .446-1 .995V11zm1 13c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm0-2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
                  fill={'#46b78e'}
                  fill-rule="evenodd"></path>
              </svg>
              <span style={{ color: '#46b78e' }}>Add more files</span>
            </button>
          )}
        </div>

        <div className="file-import-button">
          <button
            type="button"
            className={` ${isImport ? 'import-disabled' : 'transfer-link'}`}
            onClick={handleImport}
            disabled={isImport}>
            Import
          </button>
        </div>
      </div>
    </div>
  );
};

export default DSFileUpload;
