// Style
import './style.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';

import RedCrossSVG from '../../../assets/img/cross-red.svg';
import { renderContent } from '../../../shared/helpers/helpers';
import LockPart from './LockPart';
import MeasurePart from './MeasurePart';
import TemplateCoefficientPart from './TemplateCoefficientPart';

interface IPropertiesContainer {
  onClose: () => void;
  reportName: string;
  node: NS_TREE.ITreeRoot<string> | undefined;
  onSubmit: (rowContentId: string, val: NS_API.INodeSettingProperties) => void;
}

/**
 * Container used to display a report
 *
 * @param param NS_Tabs.ITab props
 */

const PropertiesContainer: React.FC<IPropertiesContainer> = ({ onClose, reportName, node, onSubmit }) => {
  const [locked, setLocked] = useState<boolean>(false);
  const [coefficient, setCoefficient] = useState<number>(0);
  const [measure, setMeasure] = useState<string>('');

  const hasChange = useMemo(() => {
    if (node) {
      const lockedChange = locked !== (node.data.content.rowParams.locked || false);
      const measureChange = measure !== node.data.content.measure;
      const coefficientChange = coefficient !== (node.data.content.templateCoefficient ?? 0);
      return lockedChange || measureChange || coefficientChange;
    }
    return false;
  }, [locked, coefficient, measure, node]);

  const handleSumbitChange = () => {
    if (node && hasChange) {
      const propeties: NS_API.INodeSettingProperties = {
        locked: locked,
        templateCoefficient: coefficient,
        measure: measure,
      };
      onSubmit(node.data.content.rowContentId, propeties);
    }
  };

  const handleChangeLockStatus = useCallback((newStatus: boolean) => {
    setLocked(newStatus);
  }, []);

  const handleChangeCoefficient = useCallback((newCoefficient: number) => {
    setCoefficient(newCoefficient);
  }, []);

  const handleChangeMeasure = useCallback((newMeasure: string) => {
    setMeasure(newMeasure);
  }, []);

  useEffect(() => {
    setLocked(node?.data.content.rowParams.locked ?? false);
    setCoefficient(node?.data.content.templateCoefficient ?? 0);
    setMeasure(node?.data.content.measure ?? '');
  }, [node]);

  const handleLeftPart = useCallback((e) => {
    e.stopPropagation();
    onClose();
  }, []);
  return (
    <div className="propertiesContainer">
      {renderContent(
        node !== undefined,
        <>
          <div className="propertiesHeader">
            <div className="leftPart">
              <ReactSVG src={RedCrossSVG} className="svg-wrapper redCrossSVG" onClick={handleLeftPart} />
              <div
                className={`nodeChip ${renderContent(
                  node?.data.content.rowContentType === 'N',
                  'account',
                  'concept',
                )}`}>
                {renderContent(node?.data.content.rowContentType === 'N', 'Account', 'Concept')}
              </div>
              <p className={'title'}>properties</p>
            </div>
            <div className="name">{reportName}</div>
          </div>
          <div className="propertiesBody">
            <MeasurePart measure={measure} onChange={handleChangeMeasure} />
            <TemplateCoefficientPart coefficient={coefficient} onChange={handleChangeCoefficient} />
            <LockPart
              locked={locked}
              disabled={node?.data.content.rowContentType !== 'C'}
              onChange={handleChangeLockStatus}
            />
          </div>
          <div className="propertiesFooter">
            <button
              disabled={!hasChange}
              onClick={handleSumbitChange}
              className={`btn ${renderContent(hasChange, 'button--green', 'button--green--disabled')}`}>
              OK
            </button>
          </div>
        </>,
        <span>Please select a node</span>,
      )}
    </div>
  );
};

export default React.memo(PropertiesContainer);
