import * as XLSX from '@sheet/chart';

import { createWorkbook, createWorksheet, generateArray, getSheetName } from '../utils';

export const AVERAGE_COLOR = '96979A';

/* ---- line chart ---- */
export const createLinePlot = (
  workspaceName: string,
  dataProvider: any,
  legend: string,
  title: string,
  headerBeginPos: XLSX.CellAddress,
  valueBeginPos: XLSX.CellAddress,
  isMultiple?: boolean,
) => {
  if (isMultiple) {
    return exportMultipleLines({
      workspaceName,
      dataProvider,
      legend,
      title,
      headerBeginPos,
      valueBeginPos,
    });
  }
  const _data = [...generateArray(workspaceName, title), ...dataProvider];
  const wb = createWorkbook();
  const ws3 = createWorksheet(_data, false);
  const sheetName = getSheetName(title);
  XLSX.utils.book_append_sheet(wb, ws3, sheetName);
  return XLSX.writeFile(wb, `${title}.xlsx`, { cellStyles: true });
};

const exportMultipleLines = ({ workspaceName, dataProvider, legend, title, headerBeginPos, valueBeginPos }) => {
  let dataFormalised: Array<Array<any>> = [[]];
  const { linesDataset, categories } = dataProvider;
  let iteratorColumn = 0;
  let dataLength = 0;
  for (const { category } of categories) {
    for (const { label } of category) {
      dataFormalised[iteratorColumn] = [...dataFormalised[iteratorColumn], label];
    }
  }
  iteratorColumn = iteratorColumn + 1;
  for (const internData of linesDataset) {
    if (internData?.data?.length) {
      dataLength = internData.data.length;
      dataFormalised[iteratorColumn] = [...internData.data.map((d) => d.value)];
      iteratorColumn = iteratorColumn + 1;
    }
  }
  const _data = [...generateArray(workspaceName, title), ...dataFormalised];
  const wb = createWorkbook();
  const ws3 = createWorksheet(_data, false);
  const sheetName = getSheetName(title);
  XLSX.utils.book_append_sheet(wb, ws3, sheetName);
  return XLSX.writeFile(wb, `${title}.xlsx`, { cellStyles: true });
};
