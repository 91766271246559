// GET

import { serviceReportsBuilders, serviceWorksheet } from '../..';

export const getWorksheets = async (workspaceId: string) => {
  return serviceWorksheet.request<NS_Workspace.ISheet[]>({
    method: 'GET',
    url: `/worksheet/${workspaceId}`,
    headers: {
      'Content-Type': `application/json`,
    },
  });
};

// POST

export const sheetOrchestrator = async (data: NS_Workspace.ISheetOrchestrator) => {
  return serviceReportsBuilders.request({
    method: 'POST',
    url: `/orchestrators/builderOrchestrator`,
    headers: {
      'Content-Type': `application/json`,
    },
    data,
  });
};

export const createWorksheet = async (workspaceId: string, type: string) => {
  return serviceWorksheet.request<NS_Workspace.ISheet>({
    method: 'POST',
    url: `/worksheet/${workspaceId}`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: {
      type: 'Report',
      name: type,
    },
  });
};

export const deleteWorksheets = async (workspaceId: string, sheetsId: string[]) => {
  return serviceWorksheet.request({
    method: 'POST',
    url: `/worksheet/${workspaceId}/delete-many`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: sheetsId,
  });
};

// PATCH

export const updateWorksheet = async (workspaceId: string, sheetId: string, newName: string) => {
  return serviceWorksheet.request<NS_Workspace.ISheet>({
    method: 'PATCH',
    url: `/worksheet/${workspaceId}/${sheetId}`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: {
      name: newName,
    },
  });
};

export const reOrderWorksheets = async (workspaceId: string, newWorksheetOrder: string[]) => {
  console.log(newWorksheetOrder);
  return serviceWorksheet.request<NS_Workspace.ISheet>({
    method: 'PATCH',
    url: `/worksheet/${workspaceId}`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: {
      reportsOrder: newWorksheetOrder,
    },
  });
};
