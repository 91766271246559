import './style.scss';

import { renderContent } from '../../../shared/helpers/helpers';
import StringHelper from '../../../shared/helpers/string/string.helper';
import CollapsibleItem, { BadgeTypes } from '../CollapsibleItem';

interface IBalance {
  onAskSupportClick: (description: string) => void;
  datasourceBalanceDiscrepancy: number;
  isDatasourceBalanced: boolean;
  dataSourceValidated: boolean;
  onValidateBalance: () => void;
}

const Balance: React.FC<IBalance> = ({
  isDatasourceBalanced,
  dataSourceValidated,
  datasourceBalanceDiscrepancy,
  onAskSupportClick,
  onValidateBalance,
}) => {
  return (
    <CollapsibleItem
      title={renderContent(isDatasourceBalanced, 'The Data Source is balanced', 'The Data Source is not balanced')}
      badgeType={renderContent(isDatasourceBalanced, BadgeTypes.VALIDATED, BadgeTypes.ERROR)}>
      {!isDatasourceBalanced && (
        <div className="balanceConclusion">
          The difference between total Debit and Credit amounts to{' '}
          {StringHelper.formatNumericalValues(datasourceBalanceDiscrepancy)}.
          {!dataSourceValidated && (
            <>
              <br />
              <br />
              If you are fine with that, click below : <br />
              <button className="validateBtn" id="validateDatasourceBalanceBtn" onClick={onValidateBalance}>
                Validate
              </button>
              <br />
              <br />
              Follow this checklist to find a solution :
              <br />
              <ul>
                <li>Is the Data Source complete (i.e are all accounts included)? </li>
                <li>Are there any non interpretable account numbers?</li>
                <li>Does the Data Source have rounded numbers in one column only, either Debit or Credit</li>
                <li>Does the General Ledger have duplicated lines or accounts?</li>
                <li>Is the datasource balance discrepancy the net income for the period?</li>
              </ul>
              <button
                className="askSupportBtn"
                id="askBalanceSupportBtn"
                onClick={() => onAskSupportClick('Datasource balance')}>
                Support
              </button>
            </>
          )}
        </div>
      )}
      {isDatasourceBalanced &&
        `The sum of all Debit and Credit entries equals to ${StringHelper.formatNumericalValues(
          datasourceBalanceDiscrepancy,
        )}`}
    </CollapsibleItem>
  );
};

export default Balance;
