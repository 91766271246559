import { toast, ToastOptions } from 'react-toastify';

import ToastComponent from '../../../elements/ToastComponent';

export default class ToastHelper {
  static error(message: string, error?: any, options?: ToastOptions) {
    if (process.env.NODE_ENV !== 'production' && error) {
      console.log(`Error message: ${(error as Error).message}\nStack trace: ${(error as Error).stack ?? ''}`);
    }
    if (!(error && error.reponse && error.reponse.status === 403)) {
      toast.error(<ToastComponent type={toast.TYPE.ERROR} text={message} />, { icon: false, ...options });
    }
  }

  static success(message: string, options?: ToastOptions) {
    toast.success(<ToastComponent type={toast.TYPE.SUCCESS} text={message} />, { icon: false, ...options });
  }

  static warn(message: string, options?: ToastOptions) {
    toast.warn(<ToastComponent type={toast.TYPE.WARNING} text={message} />, { icon: false, ...options });
  }

  static info(message: string, options?: ToastOptions) {
    toast.info(<ToastComponent type={toast.TYPE.INFO} text={message} />, { icon: false, ...options });
  }
}
