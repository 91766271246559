import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDrag } from 'react-dnd';

import dragIcon from '../../../../../assets/img/drag.svg';
import EditCellDropZone from './editCellDropZone';
import { ItemTypes } from '../../../../../shared/constant/drapAndDrop.consts';

interface EditCellProps {
  item: any;
  level: number;
  startMoveElement: () => void;
  cellSelected: string[];
  cellToBeMoved: string;
  levelHoveredNode: number;
  onRename: (newName: string, id: string) => void;
  setCellSelected: (val: Array<string>) => void;
  setCellToBeMoved: (val: string) => void;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  isDraggingState: boolean;
  nameWidth: number;
  zoomValue: string;
  setShowLineInfo: React.Dispatch<React.SetStateAction<number>>;
  rowKey: number;
  color: string;
  backgroundColor: string;
  nodeOpenedAccount: NS_TREE.IMining;
  cellFound: string;
  editable?: boolean;
  draggable?: boolean;
}

const EditCell: React.FC<EditCellProps> = ({
  item,
  level,
  startMoveElement,
  cellSelected,
  cellToBeMoved,
  levelHoveredNode,
  onRename,
  setCellSelected,
  setIsDragging,
  isDraggingState,
  nameWidth,
  zoomValue,
  setShowLineInfo,
  rowKey,
  color,
  backgroundColor,
  cellFound,
  editable,
  draggable,
}) => {
  const [value, setValue] = useState<string>(item.data.content.rowContentName);
  const [previousValue, setPreviousValue] = useState<string>('');
  const [editCell, setEditCell] = useState<boolean>(false);
  const [showDragZone, setShowDragZone] = useState<boolean>(false);
  const [isOver, setIsOver] = useState<boolean>(false);

  const [{ isDragging }, drag] = useDrag(
    {
      item: { ...item },
      type: ItemTypes.NODE,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        isDrop: monitor.didDrop(),
      }),
    },
    [item],
  );

  useEffect(() => {
    setPreviousValue(item.data.content.rowContentName);
  }, [item]);

  useEffect(() => {
    if (!cellSelected.includes(item.data.content.rowContentId)) {
      setEditCell(false);
    }
  }, [cellSelected]);

  useEffect(() => {
    setIsDragging(isDragging);
    if (isDragging && !editCell) {
      startMoveElement();
    }
  }, [isDragging]);

  // reset state when mapping tree changes
  useEffect(() => {
    setValue(item.data.content.rowContentName);
  }, [item.data.content.rowContentName]);

  const handleEnter = (rowContentId) => (event) => {
    if (event.key === 'Enter') {
      saveInputChange(rowContentId, event);
    } else if (event.key === 'Escape') {
      setEditCell(false);
      setValue(previousValue);
    }
  };

  const saveInputChange = useCallback(
    (rowContentId, e) => {
      if (item.data.content.rowContentName === e.target.value) {
        return;
      }
      setPreviousValue(e.target.value);
      onRename(e.target.value, rowContentId);
      setEditCell(false);
    },
    [onRename],
  );

  const renderClassName = useMemo(() => {
    return `name ${item.data.content.rowParams.cssStyle} ${item.parent.startsWith('R') ? 'rootName' : null} ${
      level === levelHoveredNode + 1 && levelHoveredNode !== -1 ? 'nameHovered' : null
    } ${cellSelected.includes(item.data.content.rowContentId) && 'nameSelected highlightedRow'} ${
      cellToBeMoved === item.data.content.rowContentId && 'nameToBeMoved'
    } ${item.data.content.rowParams.opened && level === 0 && item.parent.startsWith('R') && 'name1'} ${
      item.data.content.rowParams.opened && !item.parent.startsWith('R') && 'name2'
    } 
    ${showDragZone && isDraggingState && 'dragging'} ${cellFound === item.data.id ? 'nameFoundFromSearch' : null}`;
  }, [item, cellSelected, cellToBeMoved, level, levelHoveredNode, showDragZone, isDraggingState, cellFound]);

  return (
    <>
      <EditCellDropZone
        id={item.data.content.rowContentId}
        setCellSelected={setCellSelected}
        level={level}
        setIsOver={setIsOver}
        backgroundColor={backgroundColor}>
        <div
          ref={cellSelected.includes(item.data.content.rowContentId) && !editCell && draggable ? drag : null}
          className={renderClassName}
          style={{ width: `calc(${nameWidth + 100 - level * 25}px * ${zoomValue} / 10)`, color: color }}
          id={cellSelected.includes(item.data.content.rowContentId) ? 'highlightedRow' : ''}
          onMouseEnter={() => setShowLineInfo(rowKey)}
          onMouseLeave={() => setShowLineInfo(-1)}
          onDragOver={() => setShowDragZone(true)}
          onDragLeave={() => setShowDragZone(false)}
          data-cy={value}>
          {cellSelected.includes(item.data.content.rowContentId) && (
            <img src={dragIcon} alt="drag" className="dragIcon" />
          )}
          {!editCell && (
            <div
              style={{ fontSize: `calc(14px * ${zoomValue} / 10)`, height: `calc(22px * ${zoomValue} / 10)` }}
              className="nameInput"
              onDoubleClick={() => setEditCell(true)}>
              {value}
            </div>
          )}
          {editCell && editable && (
            <input
              className="nameInput nameInputEdit"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              onBlur={(e) => {
                saveInputChange(item.data.content.rowContentId, e);
              }}
              onKeyDown={handleEnter(item.data.content.rowContentId)}
              maxLength={99}
              autoFocus
            />
          )}
          {editCell && !editable && <div className="nameInput">{value.toLowerCase()}</div>}
          <div className="dimension">
            {item.rowDimension && <div className="rowDimension">{item.rowDimension}</div>}
          </div>
        </div>
        <div className={`cellDropZone ${isOver && 'cellDropZoneCanDrop'}`} />
      </EditCellDropZone>
    </>
  );
};

export default EditCell;
