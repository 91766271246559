import './style.scss';

import React, { useContext, useEffect, useState } from 'react';

// Style
import redCross from '../../assets/img/cross-red.svg';
import { WorkspaceContext } from '../../context/WorkspaceContext';
import { getAllTemplateCollectionsForWorkspace } from '../../shared/api/templateCollection';

interface IEditSaveAs {
  closeModal: () => void;
  saveTemplate: (val: string) => void;
}

/**
 * Component used to display the result of each edit command
 */

const EditSaveAs: React.FC<IEditSaveAs> = ({ closeModal, saveTemplate }) => {
  const [templateName, setTemplateName] = useState<string>('');
  const [listCollectionName, setListCollectionName] = useState<string[]>([]);
  const [displayErrorText, setDisplayErrorText] = useState<boolean>(false);

  const { workspaceId } = useContext(WorkspaceContext);

  useEffect(() => {
    const newName = [] as Array<string>;
    getAllTemplateCollectionsForWorkspace(workspaceId)
      .then((res) => {
        if (res) {
          res.data.forEach((template) => {
            newName.push(template.collectionName);
          });
          setListCollectionName(newName);
        }
      })
      .catch((err) => {});
    // eslint-disable-next-line
  }, []);

  const handleSaveClick = () => {
    if (templateName !== '' && !listCollectionName.includes(templateName)) {
      saveTemplate(templateName);
      closeModal();
    } else {
      setDisplayErrorText(true);
    }
  };

  return (
    <div className="modalBackground">
      <div className="editSaveAs">
        <div className="modalHeader">
          <strong>Save as</strong>
          <img src={redCross} alt="croix" onClick={closeModal} />
        </div>
        <div className="modalBody">
          <p>Save your work in a new Template Collection.</p>
        </div>
        <div className="modalFooter">
          <div className="inputContainer">
            <label>Template Collection Name</label>
            <input
              className={`${
                templateName !== '' && !listCollectionName.includes(templateName) && 'valideInput'
              } inputSaveAs`}
              type="text"
              value={templateName}
              onChange={(e) => {
                setTemplateName(e.target.value);
                setDisplayErrorText(false);
              }}
              placeholder="New template collection"
              maxLength={99}
              onKeyPress={(e) => {
                e.key === 'Enter' && handleSaveClick();
              }}
            />
            <div className={`${!displayErrorText && 'messageErrorHidden'} messageError`}>
              A template collection with this name already exist
            </div>
          </div>
          <button
            className={`btn btnSaveAs button--green ${
              templateName !== '' && !listCollectionName.includes(templateName) && 'valideBtn'
            }`}
            onClick={() => handleSaveClick()}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditSaveAs;
