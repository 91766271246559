// Create a new report

import { serviceDatasource, serviceReport } from '../..';

// GET

// OLD BUT STILL WORKING DONT KNOW WHY
export const getScorfColumns = async (workspaceId: string, fileId: string) => {
  return serviceDatasource.request<Array<NS_Table.IColumnHeader>>({
    method: 'GET',
    url: `/datasource/${workspaceId}/${fileId}/columns`,
    headers: {},
    params: { format: 'json' },
  });
};

// New API REPORT

export const getReport = async (workspaceId: string, worksheetId: string) => {
  return serviceReport.request<NS_API.I_RD_GetStandardStmt>({
    method: 'GET',
    url: `/report-store/${workspaceId}/${worksheetId}`,
    headers: {
      'Content-Type': `application/json`,
    },
  });
};

// Post
export const createNewReport = async (workspaceId: string, data: NS_API.I_SD_StandardStmt) => {
  return serviceReport.request<NS_API.I_RD_StandardStmt>({
    method: 'POST',
    url: `/report-command/${workspaceId}`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: data,
  });
};

export const lineDetailOverview = async (workspaceId: string, data: NS_API.I_SD_AccLines) => {
  return serviceReport.request({
    method: 'POST',
    url: `/report-query/${workspaceId}/line-detail/overview`,
    headers: {
      'Content-Type': `application/json`,
    },
    data,
  });
};

export const calculate = async (data, workspaceId, reportId) => {
  return serviceReport.request({
    method: 'POST',
    url: `/formula/${workspaceId}/${reportId}/calculate`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: data,
  });
};

// Patch

export const updateReport = async (workspaceId: string, data: NS_API.I_SD_StandardStmt) => {
  return serviceReport.request<NS_API.I_RD_StandardStmt>({
    method: 'PATCH',
    url: `/report-command/${workspaceId}`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: data,
  });
};

export const updateReportMetadata = async (workspaceId: string, reportId: string, data: NS_API.IReportMetadata[]) => {
  return serviceReport.request({
    method: 'PATCH',
    url: `/report-metadata/${workspaceId}/${reportId}`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: data,
  });
};
