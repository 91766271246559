import { GraphCommandOperationName } from '../report/reports.helper';
import { dataDestructuring } from '../tree/front.tree.util';

// When you want to delete an element but not its children
export const simpleDeleteConcept = (
  mappingTree,
  cellSelected,
  currentCommands,
  setCellSelected,
  setNameOfNodeSelected,
  setNewOperation,
  currentMetadata,
) => {
  const newArray = dataDestructuring(mappingTree);
  const childAccountIdsAndNamesOfSelectedNode = {};
  const loopArray = (child: NS_API.IGraph, selected) => {
    child.forEach((item, index) => {
      if (item.data.content.rowContentId === selected) {
        if (item.children.length > 0) {
          item.children.forEach((son, key) => {
            if (son.data.content.rowContentType === 'N') {
              childAccountIdsAndNamesOfSelectedNode[son.data.content.rowContentId] = son.data.content.rowContentName;
            }
            son.parent = item.parent;
            child.splice(index + 1 + key, 0, son);
          });
          child.splice(index, 1);
        } else {
          child.splice(index, 1);
        }
      } else {
        if (item.children) {
          loopArray(item.children, selected);
        }
      }
    });
  };
  endFunctionDelete(
    loopArray,
    newArray,
    'simple-delete',
    cellSelected,
    currentCommands,
    setCellSelected,
    setNameOfNodeSelected,
    setNewOperation,
    currentMetadata,
    childAccountIdsAndNamesOfSelectedNode,
  );
};

export const deepDeleteElement = (
  mappingTree,
  cellSelected,
  currentCommands,
  setCellSelected,
  setNameOfNodeSelected,
  setNewOperation,
  currentMetadata,
) => {
  const newArray = dataDestructuring(mappingTree);
  const loopArray = (child: NS_API.IGraph, selected) => {
    child.forEach((item, index) => {
      if (item.data.content.rowContentId === selected) {
        child.splice(index, 1);
      } else {
        if (item.children) {
          loopArray(item.children, selected);
        }
      }
    });
  };
  endFunctionDelete(
    loopArray,
    newArray,
    'deep-delete',
    cellSelected,
    currentCommands,
    setCellSelected,
    setNameOfNodeSelected,
    setNewOperation,
    currentMetadata,
  );
};

const endFunctionDelete = (
  loopArray,
  newArray,
  type: string,
  cellSelected,
  currentCommands,
  setCellSelected,
  setNameOfNodeSelected,
  setNewOperation,
  currentMetadata,
  childAccountIdsAndNamesOfSelectedNode?,
) => {
  cellSelected.forEach((selected) => {
    loopArray(newArray, selected);
  });

  const newCommands = dataDestructuring(currentCommands);
  newCommands[
    Object.keys(newCommands).length === 0
      ? 0
      : parseInt(Object.keys(newCommands)[Object.keys(newCommands).length - 1]) + 1
  ] = {
    type: type,
    parameters: {
      selectedNodeId: cellSelected[0],
      childAccountIdsAndNamesOfSelectedNode,
    },
  };

  cellSelected.length && setCellSelected([]);
  setNameOfNodeSelected({ accountId: '', accountName: '' });
  setNewOperation(newArray, currentMetadata, newCommands);
};

export const simpleDeleteAccount = (
  mappingTree,
  cellSelected,
  currentCommands,
  setCellSelected,
  setNameOfNodeSelected,
  setNewOperation,
  currentMetadata,
) => {
  const newArray = dataDestructuring(mappingTree);
  let parentOfElements;
  const loopArray = (child: NS_API.IGraph, selected) => {
    child.forEach((item, index) => {
      if (item.data.content.rowContentId === selected) {
        const _parentId = item.data.content.rowContentParent;
        const parentId = _parentId.split('-')[0];
        const content = new Object();
        const findParents = (child: NS_API.IGraph) => {
          child.forEach((item, index) => {
            if (item.data.content.rowContentParent == _parentId) {
              const { rowContentId, rowContentName } = item.data.content;
              content[`${rowContentId}`] = rowContentName;
            } else if (item.data.content.rowContentType !== 'N' && item.children) {
              findParents(item.children);
            }
          });
        };
        findParents(newArray);
        parentOfElements = { parentId, content };
        child.splice(index, 1);
      } else if (item.data.content.rowContentType !== 'N' && item.children) {
        loopArray(item.children, selected);
      }
    });
  };
  cellSelected.forEach((selected) => {
    loopArray(newArray, selected);
  });

  const newCommands = dataDestructuring(currentCommands);
  const explode = new Object();
  explode[`${parentOfElements.parentId}`] = parentOfElements.content;
  newCommands[
    Object.keys(newCommands).length === 0
      ? 0
      : parseInt(Object.keys(newCommands)[Object.keys(newCommands).length - 1]) + 1
  ] = {
    type: GraphCommandOperationName.DELETE_ACCOUNTS,
    parameters: {
      accountIdsToDelete: cellSelected,
      explode,
    },
  };
  cellSelected.length && setCellSelected([]);
  setNameOfNodeSelected({ accountId: '', accountName: '' });
  setNewOperation(newArray, currentMetadata, newCommands);
};
