import React, { useReducer } from 'react';

import ChartReport from '../../containers/ChartReport';
// Scorf Components
import Reports from '../../containers/Reports';
import ToastHelper from '../../shared/helpers/toast/ToastHelper';
// Tools
import { dataDestructuring } from '../../shared/helpers/tree/front.tree.util';
import { REPORT_TABS_TYPE } from '../../utils/constant';

export interface ITimelineData {
  tree: NS_API.IGraph;
  metadata: NS_API.IReportMetadata[];
  commands: NS_API.ICommands;
  accountIdsWithMovedEntries: string[];
  listOfAccountNamesAddedFromCoverage: string[];
  listOfAccountIdsAddedFromCoverage: string[];
}

export interface IReportState {
  name: string;
  current: number;
  timeline: ITimelineData[];
  limit: number;
}

const initialState: { [worksheetId: string]: IReportState } = {};

const initialHistory: IReportState = {
  name: '',
  current: -1,
  timeline: [],
  limit: 1000,
};

const actions = {
  UNDO: 'UNDO',
  REDO: 'REDO',
  SET_NEW_OPERATION: 'SET_NEW_OPERATION',
  SET_NEW_COMMAND: 'SET_NEW_COMMAND',
  CLEAR_HISTORY: 'CLEAR_HISTORY',
  INITIATE_HISTORY: 'INITIATE_HISTORY',
  SET_VERSION: 'SET_VERSION',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.UNDO: {
      const { worksheetId } = action.payload;
      state = { ...state };
      if (state[worksheetId]) {
        const reportHistory = state[worksheetId];
        reportHistory.current = Math.max(0, reportHistory.current - 1);
      }
      return state;
    }
    case actions.REDO: {
      const { worksheetId } = action.payload;
      state = { ...state };
      if (state[worksheetId]) {
        const reportHistory = state[worksheetId];
        reportHistory.current = Math.min(reportHistory.timeline.length - 1, reportHistory.current + 1);
      }
      return state;
    }
    case actions.SET_NEW_OPERATION: {
      const {
        tree,
        metadata,
        commands,
        worksheetId,
        accountIdsWithMovedEntries,
        listOfAccountIdsAddedFromCoverage,
        listOfAccountNamesAddedFromCoverage,
      } = action.payload;

      state = { ...state };
      if (!state[worksheetId]) {
        state[worksheetId] = dataDestructuring(initialHistory);
      }
      const { current, limit, timeline } = state[worksheetId];
      const newAccountIdsWithMovedEntries =
        accountIdsWithMovedEntries ?? timeline[current].accountIdsWithMovedEntries ?? [];
      const newListOfAccountIdsAddedFromCoverage =
        listOfAccountIdsAddedFromCoverage ?? timeline[current].listOfAccountIdsAddedFromCoverage ?? [];
      const newListOfAccountNamesAddedFromCoverage =
        listOfAccountNamesAddedFromCoverage ?? timeline[current].listOfAccountNamesAddedFromCoverage ?? [];
      const newData = dataDestructuring({
        tree,
        metadata,
        commands,
        accountIdsWithMovedEntries: newAccountIdsWithMovedEntries,
        listOfAccountNamesAddedFromCoverage: newListOfAccountNamesAddedFromCoverage,
        listOfAccountIdsAddedFromCoverage: newListOfAccountIdsAddedFromCoverage,
      });
      const newReportHistory = { ...state[worksheetId] };
      newReportHistory.timeline = newReportHistory.timeline.slice(0, current + 1);
      newReportHistory.timeline.push(newData);
      newReportHistory.timeline = newReportHistory.timeline.slice(-limit);
      newReportHistory.current = newReportHistory.timeline.length - 1;
      state[worksheetId] = newReportHistory;
      return state;
    }
    //clear timeline and update tree
    case actions.CLEAR_HISTORY: {
      const { worksheetId, tree } = action.payload;
      state = { ...state };
      if (state[worksheetId]) {
        const reportHistory = state[worksheetId];
        const metadata = [];
        const commands = {};
        const accountIdsWithMovedEntries = [];
        const listOfAccountNamesAddedFromCoverage = [];
        const listOfAccountIdsAddedFromCoverage = [];
        const newData = dataDestructuring({
          tree,
          metadata,
          commands,
          accountIdsWithMovedEntries,
          listOfAccountNamesAddedFromCoverage,
          listOfAccountIdsAddedFromCoverage,
        });
        reportHistory.timeline = [];
        reportHistory.timeline.push(newData);
        reportHistory.timeline = reportHistory.timeline.slice(-reportHistory.limit);
        reportHistory.current = 0;
      }
      return state;
    }
    case actions.INITIATE_HISTORY: {
      const {
        tree,
        metadata,
        commands,
        worksheetId,
        accountIdsWithMovedEntries,
        listOfAccountNamesAddedFromCoverage,
        listOfAccountIdsAddedFromCoverage,
      } = action.payload;
      state = { ...state };
      if (!state[worksheetId]) {
        state[worksheetId] = dataDestructuring(initialHistory);
        const reportHistory = state[worksheetId];
        reportHistory.timeline.push({
          tree,
          metadata,
          commands,
          accountIdsWithMovedEntries,
          listOfAccountNamesAddedFromCoverage,
          listOfAccountIdsAddedFromCoverage,
        });
        reportHistory.timeline = reportHistory.timeline.slice(-reportHistory.limit);
        reportHistory.current = 0;
      }
      return state;
    }
    case actions.SET_VERSION: {
      const { version, worksheetId } = action.payload;
      state = { ...state };
      if (state[worksheetId]) {
        const reportHistory = state[worksheetId];
        if (version >= 0 && version < reportHistory.timeline.length && version !== reportHistory.current) {
          reportHistory.current = version;
          ToastHelper.success(`Version succesfully restored`);
        }
      }
      return state;
    }
    default:
      return state;
  }
};

const defaultValue = [];

export const ReportContext = React.createContext<any>(defaultValue);

const ReportProvider = ({ worksheetId, reportType, workspaceId }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const hasChange = () => {
    for (const worksheet of Object.values(state)) {
      if ((worksheet as IReportState).current) {
        return true;
      }
    }
    return false;
  };

  const value = {
    name: state[worksheetId]?.name,
    current: state[worksheetId]?.current,
    timeline: state[worksheetId]?.timeline,
    reportId: worksheetId,
    currentTree: state[worksheetId]?.timeline[state[worksheetId].current]?.tree,
    currentCommands: state[worksheetId]?.timeline[state[worksheetId].current]?.commands,
    currentMetadata: state[worksheetId]?.timeline[state[worksheetId].current]?.metadata,
    hasChange: hasChange(),
    currentAccountIdsWithMovedEntries:
      state[worksheetId]?.timeline[state[worksheetId].current]?.accountIdsWithMovedEntries,
    currentListOfAccountIdsAddedFromCoverage:
      state[worksheetId]?.timeline[state[worksheetId].current]?.listOfAccountIdsAddedFromCoverage,
    currentListOfAccountNamesAddedFromCoverage:
      state[worksheetId]?.timeline[state[worksheetId].current]?.listOfAccountNamesAddedFromCoverage,

    setUndo: () => {
      dispatch({ type: actions.UNDO, payload: { worksheetId } });
    },
    setRedo: () => {
      dispatch({ type: actions.REDO, payload: { worksheetId } });
    },
    setNewOperation: (
      tree: NS_API.IGraph,
      metadata: NS_API.IReportMetadata[] = [],
      commands: NS_API.ICommands = {} as NS_API.ICommands,
      accountIdsWithMovedEntries?: string[],
      listOfAccountIdsAddedFromCoverage?: string[],
      listOfAccountNamesAddedFromCoverage?: string[],
    ) => {
      dispatch({
        type: actions.SET_NEW_OPERATION,
        payload: {
          tree,
          metadata,
          commands,
          worksheetId,
          accountIdsWithMovedEntries,
          listOfAccountIdsAddedFromCoverage,
          listOfAccountNamesAddedFromCoverage,
        },
      });
    },
    clearHistory: (tree: NS_API.IGraph) => {
      dispatch({ type: actions.CLEAR_HISTORY, payload: { tree, worksheetId } });
    },
    initiateHistory: (
      tree: NS_API.IGraph,
      metadata: NS_API.IReportMetadata[] = [],
      commands: NS_API.ICommands = {} as NS_API.ICommands,
      accountIdsWithMovedEntries: string[] = [],
      listOfAccountIdsAddedFromCoverage: string[] = [],
      listOfAccountNamesAddedFromCoverage: string[] = [],
    ) => {
      dispatch({
        type: actions.INITIATE_HISTORY,
        payload: {
          tree,
          metadata,
          commands,
          worksheetId,
          accountIdsWithMovedEntries,
          listOfAccountIdsAddedFromCoverage,
          listOfAccountNamesAddedFromCoverage,
        },
      });
    },
    setVersion: (version: number) => {
      dispatch({ type: actions.SET_VERSION, payload: { version, worksheetId } });
    },
    getChangedReportIds: () => {
      const changedReports: Array<string> = [];
      for (const [reportId, worksheet] of Object.entries<IReportState>(state)) {
        if (worksheet.current) {
          changedReports.push(reportId);
        }
      }
      return changedReports;
    },
  };

  return (
    <ReportContext.Provider value={value}>
      {reportType === REPORT_TABS_TYPE.REPORT ? (
        <Reports worksheetId={worksheetId} />
      ) : (
        <ChartReport worksheetId={worksheetId} workspaceId={workspaceId} />
      )}
    </ReportContext.Provider>
  );
};

export default ReportProvider;
