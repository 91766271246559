// Style
import './style.scss';

import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';

import RightArrowSVG from '../../../assets/img/chevron-right.svg';
import SolidCircleSVG from '../../../assets/img/circle-yellow-filled.svg';
import Clock from '../../../assets/img/clock.svg';
import { useAppSelector } from '../../../redux/hook';

export interface INews {
  type: string;
  message: string;
  start: string;
  end: string;
  active: boolean;
  title: string;
}

const truncateString = (str: string, num: number) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};

/**
 * Component used to display news about Scorf in the Home page
 */
const NewsScorf: React.FC = () => {
  const [openedArticle, setOpenedArticle] = useState<number>(0);
  const state = useAppSelector((state) => state.workspace);
  const { news } = state;

  return (
    <div className="newsScorfContainer">
      {news.length ? (
        <>
          <div className="left">
            {news.map((content, index) => {
              const key = `${content.title} - ${index}`;
              return (
                <div
                  className={`previewCard ${index === openedArticle && 'opened'}`}
                  key={key}
                  onClick={() => setOpenedArticle(index)}>
                  <div className={`title`}>
                    <div className={`${content.type.toLocaleLowerCase()} status`}>
                      <ReactSVG src={SolidCircleSVG} className="svg-wrapper solidCircleSVG" />
                    </div>
                    <div className="titleText">{content.title}</div>
                  </div>
                  <div className="preview">{truncateString(content.message, 70)}</div>
                </div>
              );
            })}
          </div>
          <div className="right">
            <div className="contentContainer">
              <h3 className="title">{news[openedArticle].title}</h3>
              <div className="date">
                <img src={Clock} alt="horloge" /> {new Date(news[openedArticle].start).toDateString()}
              </div>
              <div className="body">{news[openedArticle].message}</div>
              <div className="row">
                <div className={`status ${news[openedArticle].type.toLocaleLowerCase()}`}>
                  <ReactSVG src={SolidCircleSVG} className="svg-wrapper solidCircleSVG" />
                  {news[openedArticle].type}
                </div>
                <div
                  className="next"
                  onClick={() => {
                    if (openedArticle < news.length - 1) {
                      setOpenedArticle(openedArticle + 1);
                    }
                  }}>
                  Next article
                  <ReactSVG src={RightArrowSVG} className="svg-wrapper rightArrowSVG" />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <h1 className="noNewsTitle">There is no news.</h1>
      )}
    </div>
  );
};

export default NewsScorf;
