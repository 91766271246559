import { serviceCustomer } from '../..';

// GET
export const getUserInfo = async () => {
  return serviceCustomer.request<NS_API.IUserProfileDTO>({
    method: 'GET',
    url: `/profile/me`,
    headers: {
      'Content-Type': `application/json`,
    },
  });
};

// POST
export const shareTemplateCollection = async (
  email: string,
  ressourceName: string,
  ressourceId: string,
  countryId?: string,
) => {
  return serviceCustomer.request<NS_API.IUserProfileDTO>({
    method: 'POST',
    url: `/profile/share/template-collection`,
    headers: {
      'Content-Type': `application/json`,
    },
    data: {
      email,
      ressourceName,
      ressourceId,
      countryId,
    },
  });
};
