import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import { currencyFormat } from '../helpers';

export enum tabMode {
  MAIN = 'Main',
  LAYOUT = 'Layout',
}

export const formatReportValue = (
  value: string | number,
  rowContentType: string,
  colDataType: string,
  colDataFormat: string,
  displayUnit?: 1 | 1000 | 1000000,
) => {
  let formatedValue;
  if (colDataType === 'currency') {
    if (value !== 0 && rowContentType !== 'I') {
      formatedValue = currencyFormat(colDataFormat, value, displayUnit);
    }
  } else {
    if (colDataFormat.charAt(0) === '%') {
      //format value as %
      const dec = Number(colDataFormat.split(',')[1]);
      formatedValue = Number(value).toFixed(dec) + '%';
    }
  }
  return formatedValue;
};

export const arrayEquals = (a, b) => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
};

export const smoothScroll = (element, direction, speed, distance, step) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    if (direction === 'left') {
      element.scrollLeft -= step;
    } else if (direction === 'right') {
      element.scrollLeft += step;
    } else if (direction === 'up') {
      element.scrollTop -= step;
    } else {
      element.scrollTop += step;
    }
    scrollAmount += step;
    if (scrollAmount >= distance) {
      //@ts-ignore
      window.clearInterval(slideTimer);
    }
  }, speed);
};

/**
 * Check if an array of string (arrayB) contains all the entries of another array of strings (arrayA).
 * First it tries to find the raw value of arrayA in arrayB, if it doesn't find it, it tries another
 * time with the value of arrayA underscored at the beginning (business rule, i.e. "_value-of-array-A")
 * @param arrayA
 * @param arrayB
 */
export const checkIfAllValuesArePresent = (arrayA: string[], arrayB: string[]): boolean => {
  for (const valueFromTableA of arrayA) {
    const valueWithUnderscore = `_${valueFromTableA}`;

    if (!arrayB.includes(valueFromTableA) && !arrayB.includes(valueWithUnderscore)) {
      return false;
    }
  }
  return true;
};

/**
 * Check if an array of string (arrayB) contains one entry of another array of strings (arrayA).
 * First it tries to find the raw value of arrayA in arrayB, if it doesn't find it, it tries another
 * time with the value of arrayA underscored at the beginning (business rule, i.e. "_value-of-array-A")
 * @param arrayA
 * @param arrayB
 */
export const checkIfOneValueIsPresent = (arrayA: string[], arrayB: string[]): boolean => {
  for (const valueFromTableA of arrayA) {
    const valueWithUnderscore = `_${valueFromTableA}`;
    if (arrayB.includes(valueFromTableA) || arrayB.includes(valueWithUnderscore)) {
      return true;
    }
  }
  return false;
};

/**
 * Return the intersection of two arrays of string (arrayA and arrayB).
 * If no elements of intersection, an empty array will be returned.
 * @param arrayA
 * @param arrayB
 */
export const findIntersection = (arrayA: string[], arrayB: string[]): string[] => {
  if (arrayA && arrayB) {
    return arrayA.filter((value) => arrayB.indexOf(value) > -1);
  }
  return [];
};

/**
 * Return if two arrays contain same elements
 * @param arrayA
 * @param arrayB
 */
export const isArrayEqual = (arrayA: string[], arrayB: string[]) => {
  if (arrayA.length !== arrayB.length) {
    return false;
  } else {
    for (const el of arrayA) {
      if (arrayB.indexOf(el) === -1) {
        return false;
      }
    }
  }
  return true;
};

export enum ExportTypes {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  PDF = 'application/pdf',
}

const toPdf = (imgData, fileName: string, isLandScape?: boolean) => {
  const doc = new jsPDF('l', 'mm', 'a4');
  const imgProps = doc.getImageProperties(imgData);
  const pdfWidth = doc.internal.pageSize.getWidth();
  const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  doc.save(`${fileName}.pdf`);
  return doc;
};

export const exportSnapshot = async (
  type: ExportTypes,
  fileName: string,
  element: HTMLDivElement,
  isLandScape?: boolean,
) => {
  return html2canvas(element).then((canvas) => {
    const data = canvas.toDataURL(type);
    if (type === ExportTypes.PDF) {
      toPdf(data, fileName, isLandScape);
      return data;
    }
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = data;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
    return data;
  });
};

export enum GraphCommandOperationName {
  CREATE_NODE = 'create-node',
  DELETE_ACCOUNTS = 'delete-accounts',
  AUTO_LINK_ACCOUNTS = 'auto-link-accounts',
}
