// Style
import './style.scss';

import Doc from '../../../assets/img/doc-undraw.svg';

/**
 * Container used to display the list of questions and answers of a workspace
 * @param param IQnAListProps
 */

const Support = () => {
  return (
    <div className="supportContainer">
      <div className="placeholderContainerExport">
        <img src={Doc} alt="" />
        <h3>Support coming soon !</h3>
        <div className="textContainer">
          <p>
            Edouard:{' '}
            <a href="mailto:edenettancourt@scorf.io" className="externalLink">
              edenettancourt@scorf.io
            </a>{' '}
            -{' '}
            <a href="tel:+337 50 65 38 56" className="externalLink">
              06 64 80 08 52
            </a>
          </p>
          <p>or</p>
          <p style={{ marginTop: 8 }}>
            Ghislain:{' '}
            <a href="mailto:gderobien@scorf.io" className="externalLink">
              gderobien@scorf.io
            </a>{' '}
            -{' '}
            <a href="tel:+336 45 68 69 17" className="externalLink">
              06 45 68 69 17
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Support;
