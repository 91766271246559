import './style.scss';

import React, { useCallback, useRef, useState } from 'react';

import closeIcon from '../../../assets/img/cross-red.svg';
import CustomModalWarning from '../../../elements/CustomModalWarning';
import ExplanationBox from '../../../elements/ExplanationBox';
import NumberPicker from '../../../elements/NumberPicker';
import { TemplateKey } from '../../../shared/constant/datasource.consts';
import { renderContent } from '../../../shared/helpers/helpers';
import { REPORT_MAX_TOP_VALUE, REPORT_WARNING_MESSAGE } from '../../../utils/constant';

interface ITopValueComponent {
  defaultTopValue: number;
  topValue: number;
  selectedTemplate: string;
  setTopValue: (val: number) => void;
  onConfirmTopValue: () => void;
  onClose: () => void;
}

const explanations = [
  {
    title: 'Top Value',
    description: 'Choose top number of clients to display',
    posX: '24px',
  },
];

const TopValueComponent: React.FC<ITopValueComponent> = ({
  defaultTopValue,
  topValue,
  setTopValue,
  onConfirmTopValue,
  onClose,
  selectedTemplate,
}) => {
  //Refs
  const hoverTimer: { current: NodeJS.Timeout | null } = useRef(null);

  //States
  const [explanationSelected, setExplanationSelected] = useState<number>(-1);
  const [warning, setWarning] = useState(false);

  const handleInputHovered = (index: number) => () => {
    hoverTimer.current = setTimeout(() => {
      setExplanationSelected(index);
    }, 1000);
  };

  const handleInputNotHovered = useCallback(() => {
    if (hoverTimer.current) {
      clearTimeout(hoverTimer.current);
      setExplanationSelected(-1);
    }
  }, [hoverTimer]);

  const handleChange = useCallback((e) => {
    if (REPORT_MAX_TOP_VALUE < e.target.value) {
      setWarning(true);
      return;
    }
    setTopValue(parseInt(e.target.value));
  }, []);
  const getLabels = (template) => {
    if (template === TemplateKey.PP) {
      return 'suppliers';
    } else {
      return 'clients';
    }
  };
  const handleConfirmWarning = useCallback(() => {
    setWarning(false);
    setTopValue(REPORT_MAX_TOP_VALUE);
  }, []);
  return (
    <div className="topValueComponentContainer">
      {warning && (
        <CustomModalWarning description={REPORT_WARNING_MESSAGE.TOP_VALUE} onConfirm={handleConfirmWarning} />
      )}
      {explanationSelected > -1 && (
        <ExplanationBox
          title={explanations[explanationSelected].title}
          description={explanations[explanationSelected].description}
          posX={explanations[explanationSelected].posX}
        />
      )}
      <img src={closeIcon} alt="close icon" data-cy="close-topValue" onClick={onClose} id="topValueCloseIcon" />
      <span>Top</span>
      <NumberPicker
        value={topValue}
        onNumberChange={handleChange}
        onMouseLeaveInput={handleInputNotHovered}
        onMouseEnterInput={handleInputHovered(0)}
        onClickInput={handleInputNotHovered}
      />
      <span>{getLabels(selectedTemplate)}</span>
      <button
        className={renderContent(topValue === defaultTopValue, 'button--green--disabled', 'button--green')}
        id="topValueConfirmButton"
        disabled={topValue === defaultTopValue}
        onClick={onConfirmTopValue}>
        Ok
      </button>
    </div>
  );
};

export default TopValueComponent;
