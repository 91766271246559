// Style
import './style.scss';

import React, { useCallback, useContext } from 'react';
import { ReactSVG } from 'react-svg';

import RedCrossSVG from '../../../../assets/img/cross-red.svg';
import { ReportContext } from '../../../../context/ReportContext';
import { MappingCommandsParams } from '../../../../shared/constant/mapping.consts';
import ToastHelper from '../../../../shared/helpers/toast/ToastHelper';
import { dataDestructuring } from '../../../../shared/helpers/tree/front.tree.util';

interface IAccountsMapModuleProps {
  accountSelectedForCoverage: Array<NS_REPORT.INameSelectedForCoverageProps>;
  setAccountSelectedForCoverage: (val: Array<NS_REPORT.INameSelectedForCoverageProps>) => void;
  setIsMapOpen: (val: boolean) => void;
  nameOfNodeSelected: NS_REPORT.INameSelectedForCoverageProps;
  mappingTree: NS_API.IGraph;
}

/**
 * Container used to display a report
 *
 * @param param NS_Tabs.ITab props
 */

const AccountsMapModule: React.FC<IAccountsMapModuleProps> = ({
  accountSelectedForCoverage,
  setAccountSelectedForCoverage,
  setIsMapOpen,
  nameOfNodeSelected,
  mappingTree,
}) => {
  const { setNewOperation, currentCommands, currentMetadata } = useContext(ReportContext);

  const endMoveElement = () => {
    const elementToBeMovedArray: any[] = [];
    let elementMoved = false;
    const parentOfElementToBeMovedArray: any[] = [];
    let elementToRestore;
    let positionToRestore;
    const newCommands = dataDestructuring(currentCommands);

    const loopArrayToRestoreElement = (child: NS_API.IGraph) => {
      // @thot11 take a look here
      if (child.length === 0) {
        child.push(elementToRestore);
      } else {
        child.forEach((item) => {
          if (item.parent === elementToRestore.parent) {
            child.splice(positionToRestore, 0, elementToRestore);
          } else if (item.id === elementToRestore.parent) {
            item.children.splice(positionToRestore, 0, elementToRestore);
          } else if (item.children && item.data.content.rowContentType !== 'N') {
            loopArrayToRestoreElement(item.children);
          }
        });
      }
    };

    const loopArrayToGetElement = (child: NS_API.IGraph, cellToBeMoved) => {
      child.forEach((item, index) => {
        item.children.forEach((itemChild) => {
          if (itemChild.data.content.rowContentId === cellToBeMoved) {
            parentOfElementToBeMovedArray.push(dataDestructuring(item));
          }
        });
        if (item.data.content.rowContentId === cellToBeMoved) {
          elementToBeMovedArray.push(item);
          elementToRestore = item;
          positionToRestore = index;
          child.splice(index, 1);
        } else if (item.children && item.data.content.rowContentType !== 'N') {
          loopArrayToGetElement(item.children, cellToBeMoved);
        }
      });
    };

    const loopArrayToPutElement = (child: NS_API.IGraph, elementToBeMoved, parentOfElementToBeMoved) => {
      if (elementMoved) {
        return;
      }
      child.forEach((item) => {
        if (item.data.content.rowContentId === nameOfNodeSelected.accountId && !elementMoved) {
          const commandsKeys = Object.keys(newCommands);

          const childrenIdsAndNamesOfParentNodeOfSelectedNode: {} = {};
          childrenIdsAndNamesOfParentNodeOfSelectedNode[elementToBeMoved.data.content.rowContentId] =
            elementToBeMoved.data.content.rowContentName;

          if (parentOfElementToBeMoved) {
            dataDestructuring(parentOfElementToBeMoved).children.forEach((childOfParentToBeMoved) => {
              console.log(childOfParentToBeMoved.data.content.rowContentName);
              childrenIdsAndNamesOfParentNodeOfSelectedNode[`${childOfParentToBeMoved.data.content.rowContentId}`] =
                childOfParentToBeMoved.data.content.rowContentName;
            });
          }

          const childrenIdsAndNamesOfDestinationNode: {} = {};
          item.children.forEach((itemChild) => {
            childrenIdsAndNamesOfDestinationNode[itemChild.data.content.rowContentId] =
              itemChild.data.content.rowContentName;
          });

          item.children.unshift(elementToBeMoved);
          item.data.content.rowParams.opened = true;

          newCommands[commandsKeys.length === 0 ? 0 : parseInt(commandsKeys[commandsKeys.length - 1]) + 1] = {
            type: MappingCommandsParams.moveAccount,
            parameters: {
              selectedNodeId: elementToBeMoved.data.content.rowContentId,
              selectedNodeName: elementToBeMoved.data.content.rowContentName,
              parentIdOfSelectedNode: elementToBeMoved.data.parent.split('-')[0],
              childrenIdsAndNamesOfParentNodeOfSelectedNode: childrenIdsAndNamesOfParentNodeOfSelectedNode,
              destinationNodeId: nameOfNodeSelected.accountId,
              childrenIdsAndNamesOfDestinationNode: childrenIdsAndNamesOfDestinationNode,
            },
          };
        } else if (item.children && item.data.content.rowContentType !== 'N') {
          loopArrayToPutElement(item.children, elementToBeMoved, parentOfElementToBeMoved);
        }
      });
    };

    const newArray = dataDestructuring(mappingTree);
    accountSelectedForCoverage.forEach((account) => {
      loopArrayToGetElement(newArray, account.accountId);
    });
    elementToBeMovedArray.forEach((element, index) => {
      loopArrayToPutElement(newArray, element, parentOfElementToBeMovedArray[index]);
      if (index === elementToBeMovedArray.length - 1) {
        elementMoved = true;
      }
    });

    if (elementMoved) {
      setAccountSelectedForCoverage([]);
      ToastHelper.success('Accounts succesfully moved');

      setIsMapOpen(false);
      setNewOperation(newArray, currentMetadata, newCommands);
    }
  };

  // will move the element previously selected
  const handleCross = useCallback(() => {
    setIsMapOpen(false);
  }, []);
  return (
    <div className="entriesMapModuleContainer">
      <div className="header">
        <div className="leftPart">
          <ReactSVG src={RedCrossSVG} onClick={handleCross} className="svg-wrapper redCrossSVG" />
          Move
        </div>
        <div className="rightPart">
          {accountSelectedForCoverage.length} Account{accountSelectedForCoverage.length > 1 && 's'} selected
        </div>
      </div>
      <div className="body">
        <div className="rightPart">
          <div
            className={`${
              (nameOfNodeSelected.accountId === '' || !accountSelectedForCoverage.length) && 'contentEmpty'
            } content`}>
            <p className={'label'}>Add selected accounts to</p>
            <div className="selectedField">
              <p>
                {nameOfNodeSelected.accountId === ''
                  ? 'Select a field on the left table'
                  : nameOfNodeSelected.accountName}
              </p>
            </div>
            <button
              className="confirmMove"
              onClick={(e) => {
                if (nameOfNodeSelected.accountId !== '' && accountSelectedForCoverage.length) {
                  endMoveElement();
                  e.stopPropagation();
                }
              }}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountsMapModule;
