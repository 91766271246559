// Style
import './style.scss';

import React, { useState, useRef } from 'react';

import ExplanationBox from '../../../../elements/ExplanationBox';
import { isItemOpen } from '../../../../shared/helpers/string/classnames.helper';
import { renderContent } from '../../../../shared/helpers/helpers';

interface IColumnsPartMenu {
  openDimension: boolean;
  setOpenDimension: React.Dispatch<React.SetStateAction<boolean>>;
  openKpi: boolean;
  setOpenKpi: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

const explanations = [
  {
    title: 'Dimensions',
    description: 'Basically, the columns of the active report. Choose up to two dimensions.',
    posX: '24px',
  },
  {
    title: 'KPI',
    description: 'Add variation, sum or average columns at the end of the table.',
    posX: '24px',
  },
];

/**
 * Container used to display the q&a part of top menu in a report
 *
 * @param param NS_Tabs.ITab props
 *
 */

const ColumnsPartMenu: React.FC<IColumnsPartMenu> = ({
  openDimension,
  setOpenDimension,
  openKpi,
  setOpenKpi,
  disabled = false,
}) => {
  //Refs
  const hoverTimer: { current: NodeJS.Timeout | null } = useRef(null);

  // States

  const [explanationSelected, setExplanationSelected] = useState<number>(-1);

  // Effects

  // Function

  const handleInputHovered = (index: number) => {
    hoverTimer.current = setTimeout(() => {
      setExplanationSelected(index);
    }, 1000);
  };

  const handleInputNotHovered = () => {
    if (hoverTimer.current) {
      clearTimeout(hoverTimer.current);
      setExplanationSelected(-1);
    }
  };

  return (
    <div className="columnsPartMenuContainer">
      {explanationSelected > -1 && (
        <ExplanationBox
          title={explanations[explanationSelected].title}
          description={explanations[explanationSelected].description}
          posX={explanations[explanationSelected].posX}
        />
      )}
      <div className="subItem">
        <div
          className={`${isItemOpen(openDimension)} ${renderContent(disabled, 'div--disabled', '')}`}
          id="dimensionButton"
          onClick={() => {
            if (!disabled) {
              setOpenDimension(!openDimension);
              handleInputNotHovered();
            }
          }}
          onMouseEnter={(_e) => handleInputHovered(0)}
          onMouseLeave={() => handleInputNotHovered()}>
          Dimensions
        </div>
      </div>
      <div className="subItem">
        <div
          className={`${isItemOpen(openKpi)} ${renderContent(disabled, 'div--disabled', '')}`}
          id="kpiButton"
          onClick={() => {
            if (!disabled) {
              setOpenKpi(!openKpi);
              handleInputNotHovered();
            }
          }}
          onMouseEnter={(_e) => handleInputHovered(1)}
          onMouseLeave={() => handleInputNotHovered()}>
          KPI
        </div>
      </div>
    </div>
  );
};

export default ColumnsPartMenu;
