import './style.scss';

import { useMemo } from 'react';

import { renderContent } from '../../../shared/helpers/helpers';
import StringHelper from '../../../shared/helpers/string/string.helper';
import { DATA_SOURCE_MESSAGE } from '../../../utils/constant';
import CollapsibleItem, { BadgeTypes } from '../CollapsibleItem';

interface IEntriesBalance {
  onAskSupportClick: (description: string) => void;
  areOpeningsBalanced: boolean;
  openingBalanceDiscrepancy: number;
  dataSourceValidated: boolean;
  onValidateEntriesBalance: (e) => void;
}

const EntriesBalance: React.FC<IEntriesBalance> = ({
  onAskSupportClick,
  areOpeningsBalanced,
  openingBalanceDiscrepancy,
  dataSourceValidated,
  onValidateEntriesBalance,
}) => {
  const title = useMemo(() => {
    return renderContent(areOpeningsBalanced, DATA_SOURCE_MESSAGE.DS_BALANCED, DATA_SOURCE_MESSAGE.DS_NOT_BALANCED);
  }, [areOpeningsBalanced]);

  const badgeType = useMemo(() => {
    return renderContent(areOpeningsBalanced, BadgeTypes.VALIDATED, BadgeTypes.ERROR);
  }, [areOpeningsBalanced]);

  return (
    <CollapsibleItem title={title} badgeType={badgeType}>
      {areOpeningsBalanced &&
        `The sum of all opening entries equals to ${StringHelper.formatNumericalValues(openingBalanceDiscrepancy)}`}
      {!areOpeningsBalanced && (
        <div className="entriesBalanceConclusion">
          The sum of all opening entries is equal to {StringHelper.formatNumericalValues(openingBalanceDiscrepancy)};
          {!dataSourceValidated && (
            <>
              <br />
              <br />
              If you are fine with that, click the button below:
              <br />
              <button className="validateBtn" onClick={onValidateEntriesBalance}>
                Validate
              </button>
              <br />
              <br />
              if it's a problem, please contact support :
              <br />
              <button className="askSupportBtn" onClick={() => onAskSupportClick('Opening entries balance')}>
                Support
              </button>
            </>
          )}
        </div>
      )}
    </CollapsibleItem>
  );
};

export default EntriesBalance;
