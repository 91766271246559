// Style
import './style.scss';

import React, { useCallback, useEffect, useState } from 'react';
// Router
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import CheckSVG from '../../../assets/img/check-circle-green-filled.svg';
import edit from '../../../assets/img/edit.svg';
import member from '../../../assets/img/member.svg';
import { useAppDispatch } from '../../../redux/hook';
import { setUpdatedAction } from '../../../redux/workspace';
import { isItemSelected } from '../../../shared/helpers/string/classnames.helper';

interface IWorkspaceCardProps {
  name: string;
  date: string;
  id: string;
  description: string;
  openEditFn: () => void;
  workspaceType: NS_Workspace.IWorkspaceType;
  usersCount: number;
  toggleCheckCard: (selected: boolean) => void;
  isSelected?: boolean;
}

/**
 * Workspace card component
 *
 * @param param IWorkspaceCardProps props
 */

const WorkspaceCard: React.FC<IWorkspaceCardProps> = ({
  name,
  date,
  id,
  description,
  openEditFn,
  workspaceType,
  usersCount,
  toggleCheckCard,
  isSelected,
}) => {
  // Redux

  const dispatch = useAppDispatch();

  // History

  const navigate = useNavigate();
  const [selected, setSelected] = useState<boolean>(isSelected ?? false);

  useEffect(() => {
    setSelected(!!isSelected);
  }, [isSelected]);
  // Functions

  const onWorkspaceSelectionClick = (e) => {
    const element = e.target as HTMLElement;
    if (element.className === 'editBtn' || element.className === 'checkSVG') {
      return;
    }
    navigate(`/${id}/dataSource`, { state: { workspaceId: id, workspaceName: name } });
    dispatch(setUpdatedAction(false));
    localStorage.setItem('workspaceId', id);
  };

  const handleCheck = (e) => {
    e.stopPropagation();
    e.preventDefault();
    toggleCheckCard(!selected);
    setSelected(!selected);
  };

  return (
    <div className={'flipContainer'}>
      <div className="flipContainerInner">
        <div className={`workspaceCardContainer ${isItemSelected(selected)}`} onClick={onWorkspaceSelectionClick}>
          <div className="cardHeader">
            <h2 className="cardTitle">{name}</h2>
            <div className="cardActions">
              {workspaceType !== 'Invited' && (
                <ReactSVG src={CheckSVG} className={`svg-wrapper checkSVG`} onClick={handleCheck} />
              )}
              <img src={edit} width="20" height="20" alt="setting" className="editBtn" onClick={openEditFn} />
            </div>
          </div>
          <div className="cardInfos">
            <div className="cardDescription">{description}</div>
          </div>
          <div className="cardFooter">
            <div className="cardDate">
              <p className="secondaryDate">{date}</p>
            </div>
            <div className="cardMembers">
              <img src={member} alt="member" />
              <p className="memberNumber">{usersCount}</p>
            </div>
            <div className={`cardLabel label--${workspaceType.toLocaleLowerCase()}`}>{workspaceType}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceCard;
