import { isColumnInitialized } from './helpers';

export interface DateTreeNode {
  id: string;
  name: string;
  parentId: string;
  children: DateTreeNode[];
  selected: boolean;
  expanded: boolean;
  numericMonth?: number;
}

export enum MonthsAsString {
  'M01' = 'January',
  'M02' = 'February',
  'M03' = 'March',
  'M04' = 'April',
  'M05' = 'May',
  'M06' = 'June',
  'M07' = 'July',
  'M08' = 'August',
  'M09' = 'September',
  'M10' = 'October',
  'M11' = 'November',
  'M12' = 'December',
}

export const buildDateTreeView = (dateColumn: NS_Table.IColumnHeader): DateTreeNode | null => {
  if (!isColumnInitialized(dateColumn) || typeof dateColumn.ELSFilter === 'undefined') {
    return null;
  }

  let blanksExists = false;
  const elsFilter = dateColumn.ELSFilter;
  const days: DateTreeNode[] = [];
  // build tree leafs, days
  const displayedValues =
    elsFilter.displayedOptions.length !== 0 ? elsFilter.displayedOptions : Object.keys(elsFilter.selection);

  for (const uniqueValue of displayedValues) {
    if (uniqueValue.length === 10) {
      // Date ISO length yyyy-mm-dd
      const node: DateTreeNode = {
        id: uniqueValue,
        name: uniqueValue.slice(-2), // yyyy-mm-[dd]
        parentId: uniqueValue.substring(0, 7), // [yyyy-mm]-dd
        children: [],
        selected: elsFilter.selection[uniqueValue], // true or false
        expanded: false,
      };

      days.push(node);
    } else if (uniqueValue === '') {
      blanksExists = true;
    }
  }

  const months = buildMonthsFromDays(days);

  const years = buildYearsFromMonths(months);

  const rootNode: DateTreeNode = {
    id: 'root',
    name: 'Select all',
    parentId: '',
    children: Object.values(years),
    selected: Object.values(years).every((x) => x.selected),
    expanded: true,
  };

  if (blanksExists) {
    const blanks: DateTreeNode = {
      id: '',
      name: 'Blanks',
      parentId: 'root',
      children: [],
      selected: elsFilter?.selection[''] ?? false,
      expanded: true,
    };

    rootNode.children.push(blanks);
  }

  return rootNode;
};

export const findNodeById = (root: DateTreeNode, id: string): DateTreeNode | null => {
  // TRAVERSE BY BFS ALGORITHM
  const queue: DateTreeNode[] = [];
  queue.push(root); // enqueue
  while (queue.length !== 0) {
    const current = queue.shift(); // dequeue
    if (current) {
      if (current.id === id) {
        return current;
      }
      for (const child of current.children) {
        queue.push(child); // enqueue
      }
    }
  }

  return null;
};

export const setSelectionById = (root: DateTreeNode, targetId: string, newSelected: boolean): void => {
  // TRAVERSE BY BFS ALGORITHM
  const queue: DateTreeNode[] = [];
  const targetIdIsBlanks = targetId === '';
  let selectionChanged = false;
  queue.push(root); // enqueue

  while (queue.length !== 0) {
    const current = queue.shift(); // dequeue

    if (typeof current === 'undefined') {
      return;
    }

    const condition = targetIdIsBlanks ? current.id === '' : current.id.includes(targetId) || targetId === 'root';
    // apply to all subtree nodes (example selecting a year 2016 => 2016, then 2016-XX, then 2016-XX-XX)
    // if the target node is the root we enter directly
    // if the target node is blanks, we'll only affect 1 node
    if (condition) {
      selectionChanged = current.selected !== newSelected;
      current.selected = newSelected;
    }
    for (const child of current.children) {
      queue.push(child); // enqueue
    }
  }
  // propagation from bottom to top of the tree is expensive so we do it only when we need it
  if (selectionChanged) {
    propagateSelection(root);
  }
};

export const setExpandedById = (root: DateTreeNode, id: string, newExpanded: boolean): void => {
  // TRAVERSE BY BFS ALGORITHM
  const queue: DateTreeNode[] = [];
  queue.push(root); // enqueue

  while (queue.length !== 0) {
    const current = queue.shift(); // dequeue
    if (current) {
      if (current.id === id) {
        current.expanded = newExpanded;
      }
      for (const child of current.children) {
        queue.push(child); // enqueue
      }
    }
  }
};

export const getDateSelection = (root: DateTreeNode): Record<string, boolean> => {
  const result: Record<string, boolean> = {};
  // TRAVERSE BY BFS ALGORITHM
  const queue: DateTreeNode[] = [];
  queue.push(root); // enqueue
  while (queue.length !== 0) {
    const current = queue.shift(); // dequeue
    if (current) {
      // is a leaf? (days, blanks)
      if (current.children.length === 0) {
        result[current.id] = current.selected;
      }
      for (const child of current.children) {
        queue.push(child); // enqueue
      }
    }
  }

  return result;
};

const propagateSelection = (node: DateTreeNode): void => {
  // TRAVERSE BY DFS RECURSIVE ALGORITHM
  if (node.children.length === 0) {
    return;
  }
  for (const child of node.children) {
    propagateSelection(child);
  }
  // select parent if all children are selected
  node.selected = node.children.every((x) => x.selected);
};

const buildMonthsFromDays = (days: DateTreeNode[]): Record<string, DateTreeNode> => {
  const months: Record<string, DateTreeNode> = {};
  for (const day of days) {
    if (!months.hasOwnProperty(day.parentId)) {
      // init month
      const node: DateTreeNode = {
        id: day.parentId,
        name: MonthsAsString['M' + day.parentId.slice(-2)], // yyyy-[mm] => 2019-[12] => December
        parentId: day.parentId.substring(0, 4), // [yyyy]-mm
        children: [day],
        selected: Object.values(days)
          .filter((x) => x.parentId === day.parentId)
          .every((x) => x.selected), // all days from this month are selected ?
        expanded: false,
        numericMonth: parseInt(day.parentId.slice(-2)),
      };
      months[day.parentId] = node;
    } else {
      months[day.parentId].children.push(day);
    }
  }
  return months;
};

const buildYearsFromMonths = (months: Record<string, DateTreeNode>): Record<string, DateTreeNode> => {
  const years: Record<string, DateTreeNode> = {};
  for (const key in months) {
    const month = months[key];
    if (!years.hasOwnProperty(month.parentId)) {
      // init year
      const node: DateTreeNode = {
        id: month.parentId,
        name: month.parentId,
        parentId: 'root',
        children: [month],
        selected: Object.values(months)
          .filter((x) => x.parentId === month.parentId)
          .every((x) => x.selected), // all months from this year are selected ?
        expanded: false,
      };
      years[month.parentId] = node;
    } else {
      years[month.parentId].children.push(month);
    }
  }
  return years;
};
