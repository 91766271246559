import { useContext, useEffect, useState } from 'react';

import { WorkspaceContext } from '../../../context/WorkspaceContext';
import { removeExportNotification } from '../../../redux/export';
import { useAppDispatch, useAppSelector } from '../../../redux/hook';
import { changePollingTime, POLLING_DELAY } from '../../../redux/notifications';
import { startExportMappings } from '../../../shared/api/exports';
import { IDatasourceMappingExport } from '../../../shared/constant/mapping.consts';
import ToastHelper from '../../../shared/helpers/toast/ToastHelper';

const useMapping = () => {
  const { workspaceId, dataSources, getWorkspace } = useContext(WorkspaceContext);
  const { newExport } = useAppSelector((state) => state.export);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [openMappingModal, setOpenMappingModal] = useState(false);
  const [datasourceIds, setDatasourceIds] = useState<Array<IDatasourceMappingExport>>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (newExport?.length) {
      const currentExport = newExport.filter(
        (data) =>
          !!datasourceIds.find((d) => data.exportId && d.exportId === data.exportId) ||
          data.workspaceId === workspaceId,
      );
      if (currentExport?.length) {
        let isToRefresh = false;
        for (const _export of currentExport) {
          const isDatasource = datasourceIds.find((ds) => ds.datasourceId === _export.datasourceId);
          if (isDatasource?.datasourceId) {
            if (_export?.errorMessage) {
              ToastHelper.error(_export?.errorMessage || 'Export mapping error');
              removeDatasourceId(_export.datasourceId);
              dispatch(removeExportNotification(_export.datasourceId));
            } else {
              isToRefresh = true;
              setTimeout(() => {
                dispatch(removeExportNotification(_export.datasourceId));
                removeDatasourceId(_export.datasourceId);
              }, 1000);
            }
          }
        }
        if (isToRefresh || (!isToRefresh && currentExport.length)) {
          getWorkspace(workspaceId);
        }
      }
    }
  }, [newExport, datasourceIds]);

  const exportMapping = async (datasourceId: string, graphId: string) => {
    setGenerateLoading(true);
    return startExportMappings(workspaceId, datasourceId, graphId)
      .then((result) => {
        if (result?.data?.exportId) {
          const currentDs = dataSources.find((data) => data.fileId === datasourceId);
          setDatasourceIds([
            ...datasourceIds,
            { datasourceId, name: currentDs?.displayName || '', exportId: result?.data?.exportId || '' },
          ]);
          // FIX ME : Show message that export mapping begins
          ToastHelper.success('Export mapping started');
          setGenerateLoading(false);
          dispatch(changePollingTime(POLLING_DELAY.executed));
          closeMaping();
        }
      })
      .catch((err) => {
        ToastHelper.error(err);
        setGenerateLoading(false);
      });
  };

  const openMaping = () => {
    setOpenMappingModal(true);
  };
  const closeMaping = () => {
    setOpenMappingModal(false);
  };

  const removeDatasourceId = (datasourceIdToRemove: string) => {
    setDatasourceIds([...datasourceIds.filter((datasource) => datasource.datasourceId !== datasourceIdToRemove)]);
  };

  return {
    generateLoading,
    openMappingModal,
    openMaping,
    closeMaping,
    exportMapping,
    datasourceIds,
  };
};

export default useMapping;
