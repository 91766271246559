// Style
import './style.scss';

import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Documentation from '../../components/homeComponents/Documentation';
// Scorf components
import ExportsList from '../../components/homeComponents/ExportsList';
import IntegrityChecks from '../../components/homeComponents/IntegrityChecks';
import MemberList from '../../components/homeComponents/MemberList';
import NewsScorf from '../../components/homeComponents/NewsScorf';
import ExportOptions from '../../components/homeComponents/Options';
import QnAList from '../../components/homeComponents/QnAList';
import Support from '../../components/homeComponents/Support';
import TemplateCol from '../../components/homeComponents/TemplateCol';
// Context
import { WorkspaceContext } from '../../context/WorkspaceContext';
import CustomModal from '../../elements/CustomModal';
import Footer from '../../elements/Footer';
import { staticMenu } from '../../elements/ToggleList';
import { generateQandAExport } from '../../shared/api/exports';
import ToastHelper from '../../shared/helpers/toast/ToastHelper';

// Helper function
const cleanLocalStorage = () => {
  localStorage.removeItem('SelectedTabData');
};

/**
 * Container used to display a workspace home page
 *
 */

export const popToast = () => {
  ToastHelper.info(`Not ready yet, come back later`);
};

const Home = () => {
  // Redux

  const { name, workspaceId, createWorksheet, newTab } = useContext(WorkspaceContext);

  const location = useLocation();
  const navigate = useNavigate();

  // States

  const [selectedTab, setSelectedTab] = useState<string>('');
  const [showTitle, setShowTitle] = useState<boolean>(false);
  const [openExportDenyModal, setOpenExportDenyModal] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    if (location?.hash) {
      setSelectedTab(location.hash);
    } else {
      navigate('/404', { replace: true });
    }
  }, [location]);

  useEffect(() => {
    cleanLocalStorage();
  }, []);

  // Functions
  const handleGotoDS = useCallback(() => {
    if (workspaceId !== '') {
      const index = staticMenu.findIndex((menu) => menu.text === 'Data Sources');
      navigate(`/${workspaceId}/${staticMenu[index].link}`);
    }
  }, [workspaceId, staticMenu]);

  const handleAddNewSheet = useCallback(() => {
    const newSheet = { name: '', id: '', reportId: '' };
    createWorksheet(workspaceId, 'Report')
      .then((res) => {
        if (res) {
          const { data } = res;
          newSheet.id = data.id;
          newSheet.name = data.name;
          newTab(data.id, data.name, data.reportId, data.datasourceId, data.templateId);
          navigate(`/${workspaceId}/reports/${newSheet.id}`);
        }
      })
      .catch((e) => {
        ToastHelper.error(`Error while adding new sheet`, e);
      });
  }, [workspaceId]);

  const exportErrorCallback = (err) => {
    if (err.response.status === 403) {
      denyExport();
    } else {
      ToastHelper.error(`Failed to download.`);
    }
  };

  const handleDownloadClick = () => {
    const downloadedFileName = `${name} Q&A`;
    generateQandAExport(workspaceId, downloadedFileName, exportErrorCallback).catch((err) => {});
  };

  const denyExport = useCallback(() => {
    setOpenExportDenyModal(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpenExportDenyModal(false);
  }, []);

  const renderTab = () => {
    switch (selectedTab) {
      case '#export':
        return (
          <div className="moduleDatabook module">
            <h3 className="moduleTitle"> {showTitle && 'File Manager'}</h3>
            <ExportsList
              setShowTitle={setShowTitle}
              handleAddNewSheet={handleAddNewSheet}
              handleGotoDS={handleGotoDS}
              handleExportDenied={denyExport}
            />
          </div>
        );
      case '#users':
        return (
          <div className="moduleMembers module">
            <h3 className="moduleTitle">User Management</h3>
            <MemberList />
          </div>
        );
      case '#q&a':
        return (
          <div className="moduleDatabook module">
            <div className="row">
              <h3 className="moduleTitle">{showTitle && 'Questions & Answers'}</h3>
              {showTitle && (
                <div className="right">
                  <button className="button--green" onClick={handleDownloadClick}>
                    Export Q&A
                  </button>
                </div>
              )}
            </div>
            <QnAList setShowTitle={setShowTitle} handleAddNewSheet={handleAddNewSheet} handleGotoDS={handleGotoDS} />
          </div>
        );
      case '#integrity':
        return <IntegrityChecks />;
      case '#news':
        return <NewsScorf />;
      case '#options':
        return (
          <div className="moduleDatabook module">
            <h3 className="moduleTitle">Options</h3>
            <ExportOptions />
          </div>
        );
      case '#support':
        return (
          <div className="moduleDatabook module">
            <h3 className="moduleTitle"></h3>
            <Support />
          </div>
        );
      case '#templateCol':
        return (
          <div className="moduleDatabook module">
            <h3 className="moduleTitle">User's Template Collections</h3>
            <TemplateCol />
          </div>
        );
      case '#doc':
        return (
          <div className="moduleDatabook module">
            <h3 className="moduleTitle">{showTitle && 'Documentation'}</h3>
            <Documentation setShowTitle={setShowTitle} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="homeContainer">
      <div className="homeContainer">
        <div className="rightContainer">{renderTab()}</div>
      </div>
      {openExportDenyModal && (
        <CustomModal onClose={handleClose} onConfirm={handleClose} onCancel={handleClose} title="Export denied">
          Your free offer is expired, please contact support.
        </CustomModal>
      )}
      <Footer workspaceName={name + ' workspace'} needReverse={false} />
    </div>
  );
};

export default Home;
