// Style
import './style.scss';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// Redux
import { ReactSVG } from 'react-svg';

import Grid from '@mui/material/Grid';

import MagnifyingGlassSVG from '../../assets/img/search.svg';
import squares from '../../assets/img/squares.svg';
import WorkspaceCard from '../../components/workspacesComponents/WorkspaceCard';
import WorkspaceFilterBar from '../../components/workspacesComponents/WorkspaceFilterBar';
import CustomModal, { BtnClassType } from '../../elements/CustomModal';
// Scorf components
import SmallLoader from '../../elements/Loaders/SmallLoader';
import DeleteWorkspaceModal from '../../modals/DeleteWorkspace';
import WorkspaceCreationModal from '../../modals/WorkspaceCreationModal';
import { useAppDispatch } from '../../redux/hook';
import { resetWorkspaceName } from '../../redux/workspace';
// API
import {
  createWorkspace,
  deleteWorkspacesByIds,
  deleteWorskpace,
  getAllWorkspaces,
  updateWorkspace,
} from '../../shared/api/workspace';
import { WorkspaceTypes } from '../../shared/constant/workspaces.consts';
// Tools
import StringHelper from '../../shared/helpers/string/string.helper';
import ToastHelper from '../../shared/helpers/toast/ToastHelper';

/**
 * Container used in the main page to display all user workspaces
 */

const Workspaces: React.FC = () => {
  // Redux
  const dispatch = useAppDispatch();

  // Refs

  const stickyRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // States

  const [data, setData] = useState<Array<any>>([]);
  const [action, setAction] = useState<string>('');
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(true);
  const [selectedWorkspace, setSelectedWorkspace] = useState<NS_Workspace.IWorkspaceDtoParams>({
    name: '',
  } as NS_Workspace.IWorkspaceDtoParams);
  const [isSticky, setSticky] = useState<boolean>(false);
  const [editingIndex, setEditingIndex] = useState<number>(-1);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchResults, setSearchResults] = useState<Array<any>>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [wsFilterType, setWsFilterType] = useState<string>(WorkspaceTypes[0]);
  const [filteredWorkspaces, setFilteredWorkspaces] = useState<Array<any>>([]);
  const [deletingWorkspace, setDeletingWorkspace] = useState<boolean>(false);
  const [checkedWorkspaceIds, setCheckedWorkspaceIds] = useState<Array<string>>([]);
  const [openConfirmManyDeletion, setOpenConfirmManyDeletion] = useState<boolean>(false);

  // Effects

  useEffect(() => {
    document.title = `Scorf.io`;
    window.gtag('config', window.GA_MEASUREMENT_ID, {
      page_title: `Scorf.io`,
      send_page_view: false,
    });
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      containerRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsLoaderVisible(true);
    fetch().catch((err) => {});
    dispatch(resetWorkspaceName('Workspace'));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchValue) {
      const tempFilter = filteredWorkspaces.filter((wspace) =>
        wspace.name.toLowerCase().includes(searchValue.toLowerCase()),
      );
      setSearchResults(tempFilter);
      if (0 < searchResults.length && searchResults.length === checkedWorkspaceIds.length) {
        const workspaceIds = tempFilter.filter((s) => s.workspaceType !== 'Invited').map((s) => s.workspaceId);
        setCheckedWorkspaceIds([...workspaceIds]);
      }
    } else {
      setSearchResults([]);
      setCheckedWorkspaceIds([]);
    }
  }, [searchValue, filteredWorkspaces]);

  useEffect(() => {
    if (action !== 'CREATING') {
      return;
    }
    setAction('');
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    const tmpWorkspaces = data.filter(
      (item) => wsFilterType === WorkspaceTypes[0] || renderWorkspaceType(item) === wsFilterType,
    );
    setFilteredWorkspaces(tmpWorkspaces);
  }, [data, wsFilterType]);

  // Functions

  const handleScroll = () => {
    if (stickyRef.current) {
      setSticky(stickyRef.current.getBoundingClientRect().top <= 60);
    }
  };

  const handleCreateSubmit = (params: NS_Workspace.IWorkspaceDtoParams) => {
    (async () => {
      setSaving(true);
      try {
        const res = await createWorkspace(params.name, params.description);
        if (res.status === 201) {
          fetch(`Workspace ${res.data.name} created successfully`).catch((err) => {});
          window.gtag('event', 'workspace_creation', {
            event_label: 'workspace_creation',
            value: res.data.name,
            workspaceId: res.data.workspaceId,
            workspaceName: res.data.name,
          });
          setSaving(false);
        } else {
          setSaving(false);
          throw new Error(res.data.message);
        }
        setAction('');
      } catch (error) {
        ToastHelper.error(`Could not create workspace ${params.name}`, error);
        setSaving(false);
        setAction('');
      }
    })();
  };

  const handleEditWorkspace = async (id: string, dataEdit: NS_Workspace.IUpdateWorkspaceParam) => {
    try {
      const res = await updateWorkspace(id, dataEdit);
      if (res.status === 200) {
        fetch(`Workspace ${res.data.name} updated successfully`).catch((err) => {});
      } else {
        throw new Error(res.data.message);
      }
    } catch (error) {
      ToastHelper.error(`Could not update workspace ${dataEdit.updatedWorkspace.name}`, error);
    }
  };

  const handleDeleteWorkspace = async (id: string) => {
    setDeletingWorkspace(true);
    const newData = [...data];
    let index = -1;
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].workspaceId === id) {
        index = i;
        break;
      }
    }
    if (index !== -1) {
      newData.splice(index, 1);
      setData(newData);
    }

    try {
      const res = await deleteWorskpace(id);
      if (res.status === 204) {
        fetch(`Workspace ${data[index].name} deleted successfully`).catch((err) => {});
        window.gtag('event', 'workspace_deletion', {
          event_label: 'workspace_deletion',
          value: data[index].name,
          workspaceId: data[index].id,
          workspaceName: data[index].name,
        });
        setSelectedWorkspace({ name: '' } as NS_Workspace.IWorkspaceDtoParams);
      } else {
        throw new Error(res.data.message);
      }
      setAction('');
      setDeletingWorkspace(false);
    } catch (error) {
      setDeletingWorkspace(false);
      ToastHelper.error(`Could not delete workspace ${selectedWorkspace.name}`, error);
    }
  };

  const fetch = async (toastMessage?: string) => {
    const res = await getAllWorkspaces();
    if (res) {
      setData(res.data);
      setIsLoaderVisible(false);
    }
    if (toastMessage) {
      ToastHelper.success(toastMessage);
    }
  };

  const getDefaultValue = (actionChosen) => {
    const dataToRender = searchResults.length > 0 ? searchResults : data;
    if (actionChosen === 'EDITING') {
      return {
        id: dataToRender[editingIndex].workspaceId,
        name: dataToRender[editingIndex].name,
        description: dataToRender[editingIndex].description,
      };
    } else {
      return { name: '', description: '' };
    }
  };

  const getIdWorkspace = (): string => {
    const dataToRender = searchResults.length > 0 ? searchResults : data;
    return dataToRender[editingIndex].workspaceId;
  };

  const toggleCheckWorkspace = (workspaceId: string) => (checkStatus: boolean) => {
    const idIndex = checkedWorkspaceIds.indexOf(workspaceId);
    if (checkStatus) {
      if (idIndex === -1) {
        setCheckedWorkspaceIds([...checkedWorkspaceIds, workspaceId]);
      }
    } else {
      if (idIndex !== -1) {
        const newIds = [...checkedWorkspaceIds];
        newIds.splice(idIndex, 1);
        setCheckedWorkspaceIds(newIds);
      }
    }
  };

  const confirmDeleteWorkspaces = useCallback(() => {
    setDeletingWorkspace(true);
    deleteWorkspacesByIds(checkedWorkspaceIds)
      .then((res) => {
        if (res.status === 200) {
          fetch(`Workspaces deleted successfully`).catch((err) => {});
          checkedWorkspaceIds.forEach((workspaceId) => {
            window.gtag('event', 'workspace_deletion', {
              event_label: 'workspace_deletion',
              workspaceId: workspaceId,
            });
          });
          setCheckedWorkspaceIds([]);
          setOpenConfirmManyDeletion(false);
        } else {
          ToastHelper.error(`Could not delete workspaces`);
        }
        setDeletingWorkspace(false);
      })
      .catch((error) => {
        ToastHelper.error(`Could not delete workspace ${selectedWorkspace.name}`, error);
        setDeletingWorkspace(false);
      });
  }, [checkedWorkspaceIds, selectedWorkspace]);

  const handleDeleteWorkspaces = useCallback(() => {
    setOpenConfirmManyDeletion(true);
  }, []);

  const selectAllFilter = useCallback(() => {
    const workspaceIds = searchResults.filter((s) => s.workspaceType !== 'Invited').map((s) => s.workspaceId);
    if (checkedWorkspaceIds.length === searchResults.length) setCheckedWorkspaceIds([]);
    else setCheckedWorkspaceIds([...workspaceIds]);
  }, [searchResults, checkedWorkspaceIds]);

  const renderWorkspaces = () => {
    const dataToRender = searchResults.length > 0 ? searchResults : filteredWorkspaces;
    if (searchResults.length === 0 && searchValue) {
      return (
        <div className="noResultContainer">
          <ReactSVG src={MagnifyingGlassSVG} className="svg-wrapper magnifyingGlassSVG" />
          <p className="type1">No result</p>
          <p>
            The Workspace you’re looking for does not exist.
            <br /> Please try again or create a new Workspace.
          </p>
        </div>
      );
    } else if (filteredWorkspaces.length === 0 && !searchValue) {
      return (
        <div className="noResultContainer">
          <ReactSVG src={MagnifyingGlassSVG} className="svg-wrapper magnifyingGlassSVG" />
          <p className="type1">Hey it’s a bit empty here</p>
          <p>Create your first workspace to fill the space</p>
        </div>
      );
    } else {
      return (
        <>
          {dataToRender.map((item, key) => {
            const { workspaceId, name, Timestamp, description } = item;
            const date = StringHelper.stringifyIsoDateToFullDate(Timestamp);
            const isSelected = checkedWorkspaceIds.find((c) => c === workspaceId);
            const index = `${workspaceId}${key}`;
            return (
              <div key={index} className="cardContainer">
                <WorkspaceCard
                  name={name}
                  date={`${date.date}`}
                  id={workspaceId}
                  description={description}
                  openEditFn={handleOpenEditFn(key)}
                  usersCount={item.nbUsers}
                  workspaceType={renderWorkspaceType(item)}
                  toggleCheckCard={toggleCheckWorkspace(item.workspaceId)}
                  isSelected={!!isSelected}
                />
              </div>
            );
          })}
        </>
      );
    }
  };

  const handleOpenEditFn = (key: number) => () => {
    setEditingIndex(key);
    setAction('EDITING');
  };

  const renderWorkspaceType = useCallback((item) => {
    if (item.invited) {
      return 'Invited';
    } else if (item.nbUsers === 0) {
      return 'Private';
    } else {
      return 'Shared';
    }
  }, []);

  const checkedWorkspaceNames = useMemo(() => {
    return data
      .filter((workspace) => checkedWorkspaceIds.indexOf(workspace.workspaceId) !== -1)
      .map((workspace) => workspace.name)
      .join(', ');
  }, [data, checkedWorkspaceIds]);

  const handleModalDeleteWorkspace = useCallback(() => {
    if (!deletingWorkspace) {
      setAction('');
    }
  }, [deletingWorkspace]);

  const handleModalManyDeleteW = useCallback(() => {
    if (!deletingWorkspace) {
      setOpenConfirmManyDeletion(false);
    }
  }, [deletingWorkspace]);

  const handleHideCreateModal = useCallback(() => {
    setAction('');
  }, []);

  const handleDeleteFn = useCallback(() => {
    setAction('DELETING');
  }, []);

  const handleCancelDeleteWorkspace = useCallback(() => {
    setOpenConfirmManyDeletion(false);
  }, []);

  const handleAddWorkspace = useCallback(() => {
    setAction('CREATING');
  }, []);

  return (
    <Grid className="workspacesContainer" ref={containerRef}>
      <div className="headerWorkspaceContainer">
        <div className="headerWorkspaceContent">
          <div className="titlesAndIconContainer">
            <img src={squares} alt="workspace" />
            <div className="titlesContainer">
              <h2 className="title">Workspaces</h2>
              <h3 className="subtitle">Welcome back, let's start a new project</h3>
            </div>
          </div>
          <div className={'wrapper'}>
            <WorkspaceFilterBar
              searchResults={searchResults}
              addWorkspaceClicked={handleAddWorkspace}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              onFilter={setWsFilterType}
              deleteButton={checkedWorkspaceIds.length > 0}
              onDelete={handleDeleteWorkspaces}
              selectAllFilter={selectAllFilter}
              isAllSelected={searchResults.length === checkedWorkspaceIds.length}
            />
          </div>
        </div>
      </div>
      {isLoaderVisible ? (
        <div className="loader">
          <SmallLoader />
        </div>
      ) : (
        <>
          <Grid className={`root ${isSticky && 'rootSticky'}`}>{renderWorkspaces()}</Grid>

          {(action === 'CREATING' || action === 'EDITING') && (
            <WorkspaceCreationModal
              action={action}
              handleSubmit={handleCreateSubmit}
              closeModal={handleHideCreateModal}
              defaultValues={getDefaultValue(action)}
              editFn={handleEditWorkspace}
              deleteFn={handleDeleteFn}
              forbiddenNames={data.map((workspace) => workspace.name)}
              saving={saving}
            />
          )}

          {action === 'DELETING' && (
            <DeleteWorkspaceModal
              id={getIdWorkspace()}
              deleteFn={handleDeleteWorkspace}
              closeModal={handleModalDeleteWorkspace}
              deleting={deletingWorkspace}
            />
          )}

          {openConfirmManyDeletion && (
            <CustomModal
              title="Delete Workspaces"
              onClose={handleModalManyDeleteW}
              onCancel={handleCancelDeleteWorkspace}
              onConfirm={confirmDeleteWorkspaces}
              confirmBtnType={BtnClassType.Delete}
              confirmLabel="Delete"
              confirmLoader={deletingWorkspace}
              confirmLoaderColor={'#f45067'}
              confirmDisabled={deletingWorkspace}
              cancelDisabled={deletingWorkspace}>
              <p>Are you sure you want to delete these workspaces?</p>
              <p id="workspaceNames">{checkedWorkspaceNames}</p>
            </CustomModal>
          )}
        </>
      )}
    </Grid>
  );
};

export default Workspaces;
