// Style
import './style.scss';

import { useCallback, useContext, useEffect, useRef, useState } from 'react';
// Router
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import DataSource from '../../containers/DataSource';
import Home from '../../containers/Home';
import ReportProvider from '../../context/ReportContext';
// Context
import { WorkspaceContext } from '../../context/WorkspaceContext';
import AppHeader from '../../elements/AppHeader';
import HomeMenu from '../../elements/HomeMenu';
import LargeLoader from '../../elements/Loaders/LargeLoader';
import { setActiveWorkspaceAndWorksheet, setSelectedTemplateKey } from '../../redux/charts';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import { setTemplateCollections } from '../../redux/workspace';
import { getAllTemplateCollectionsForWorkspace } from '../../shared/api/templateCollection';
import { ChartFamily } from '../../shared/constant/datasource.consts';
import { RoutesEnum } from '../../shared/constant/route.consts';
import { REPORT_TABS_TYPE } from '../../utils/constant';

/**
 * Container used to display a workspace home page
 *
 * @param param NS_Tabs.ITab props
 */

const WorkspaceContainer = () => {
  const { getWorkspace, workspaceId, name, reportSaving, tabs } = useContext(WorkspaceContext);
  const dispatch = useAppDispatch();
  const workspace = useAppSelector((state) => state.workspace);
  const [worksheetId, setWorksheetId] = useState<string>('');
  const [reportType, setReportType] = useState<string>(REPORT_TABS_TYPE.REPORT);
  const [waiting, setWaiting] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isMenuOpen, setMenuOpen] = useState<boolean>(true);
  const [isAtHomePage, setIsAtHomePage] = useState<boolean>(false);

  // Ref
  const menuRef = useRef<HTMLDivElement>(null);
  const logoRef = useRef<HTMLImageElement>(null);
  const logoContainerRef = useRef<HTMLImageElement>(null);
  const isMenuOpenRef = useRef<boolean>(isMenuOpen);
  const isAtHomePageRef = useRef<boolean>(isAtHomePage);

  const location = useLocation();

  useEffect(() => {
    if (name !== 'Workspace' && document.title !== `Scorf.io - ${name}`) {
      document.title = `Scorf.io - ${name}`;
      window.gtag('config', window.GA_MEASUREMENT_ID, {
        page_title: `Scorf.io - ${name}`,
        send_page_view: false,
      });
    }
  }, [name]);

  useEffect(() => {
    isMenuOpenRef.current = isMenuOpen;
  }, [isMenuOpen]);

  useEffect(() => {
    isAtHomePageRef.current = isAtHomePage;
    if (isAtHomePage) {
      setMenuOpen(true);
    }
  }, [isAtHomePage]);

  useEffect(() => {
    const urlParts = location.pathname.split('/');
    const urlWorkspaceId = urlParts[1];
    urlWorkspaceId && getWorkspace(urlWorkspaceId);
  }, []);

  useEffect(() => {
    const urlParts = location.pathname.split('/');
    const tab = urlParts[urlParts.length - 1];
    setWorksheetId(tab);
    dispatch(setSelectedTemplateKey(ChartFamily.PL));
    if (tab === 'home') {
      setIsAtHomePage(true);
    } else {
      setIsAtHomePage(false);
      setMenuOpen(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (tabs?.length) {
      const urlParts = location.pathname.split('/');
      const tab = urlParts[urlParts.length - 1];
      const _tab = tabs.find((t) => t.id === tab);
      if (_tab?.type) {
        if (_tab.type === REPORT_TABS_TYPE.REPORT && reportType === REPORT_TABS_TYPE.CHART_REPORT) {
          setWaiting(true);
          setTimeout(() => {
            setReportType(_tab.type);
            setWaiting(false);
          }, 100);
        } else {
          setReportType(_tab.type);
        }
      }
    }
  }, [tabs, worksheetId, location.pathname]);

  useEffect(() => {
    if (isAtHomePage) {
      setMenuOpen(true);
    }
  }, [isAtHomePage]);

  useEffect(() => {
    const escapeClick = (event) => {
      if (event.key === 'Escape' && isMenuOpenRef.current && !isAtHomePageRef.current) {
        setMenuOpen(false);
      }
    };

    const handleClickOutsideMenu = (event) => {
      if (
        menuRef.current &&
        !(menuRef.current as any).contains(event.target) &&
        isMenuOpenRef.current &&
        !isAtHomePageRef.current
      ) {
        if (!(logoRef && (logoRef.current as any).contains(event.target))) {
          setMenuOpen(false);
        }
      }
    };

    document.addEventListener('keyup', escapeClick);
    window.addEventListener('mouseup', handleClickOutsideMenu);
    window.addEventListener('mousemove', toggleMenuHover);
    return () => {
      document.removeEventListener('keyup', escapeClick);
      window.removeEventListener('mouseup', handleClickOutsideMenu);
      window.removeEventListener('mousemove', toggleMenuHover);
    };
  }, [isAtHomePage]);

  useEffect(() => {
    if (workspaceId && workspaceId !== '') {
      setIsLoading(false);
      dispatch(setActiveWorkspaceAndWorksheet({ selectedWorkspace: workspaceId, selectedWorksheetId: worksheetId }));
      if (!workspace?.templateCollections?.length) {
        getAllTemplateCollectionsForWorkspace(workspaceId)
          .then((res) => {
            if (res?.data) {
              dispatch(setTemplateCollections(res.data));
            }
          })
          .catch((err) => {});
      }
    }
    if (!workspace?.templateCollections?.length && workspaceId) {
      getAllTemplateCollectionsForWorkspace(workspaceId)
        .then((res) => {
          if (res?.data) {
            dispatch(setTemplateCollections(res.data));
          }
        })
        .catch((err) => {});
    }
  }, [workspaceId]);

  useEffect(() => {
    if (!!workspaceId && !!worksheetId) {
      dispatch(setActiveWorkspaceAndWorksheet({ selectedWorkspace: workspaceId, selectedWorksheetId: worksheetId }));
    }
  }, [worksheetId, workspace]);

  const toggleMenuOpen = useCallback(() => {
    if (!reportSaving) setMenuOpen(true);
  }, [reportSaving]);

  const toggleMenuHover = (e) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(e.target) &&
      logoContainerRef.current &&
      !logoContainerRef.current.contains(e.target) &&
      !isAtHomePage
    ) {
      setMenuOpen(false);
    }
  };
  return (
    <div className="workspaceContainer">
      <AppHeader
        onMouseEnterLogo={toggleMenuOpen}
        onClickLogo={toggleMenuOpen}
        logoRef={logoRef}
        logoContainerRef={logoContainerRef}
        logoUnderLine={isMenuOpen}
      />
      <HomeMenu open={isMenuOpen} menuRef={menuRef} showName={isAtHomePage} />
      <Routes>
        <Route path={RoutesEnum.homeSub} element={isLoading ? <LargeLoader /> : <Home />} />
        <Route path={RoutesEnum.dataSourceSub} element={isLoading ? <LargeLoader /> : <DataSource />} />
        <Route
          path={RoutesEnum.reportsSub}
          element={
            isLoading || waiting ? (
              <LargeLoader />
            ) : (
              <ReportProvider worksheetId={worksheetId} reportType={reportType} workspaceId={workspaceId} />
            )
          }
        />
        <Route path="/" element={<Navigate to={RoutesEnum.notFound} replace />} />
      </Routes>
    </div>
  );
};

export default WorkspaceContainer;
