// Style
import './style.scss';

import React, { useEffect, useMemo, useState } from 'react';

import redCross from '../../assets/img/cross-red.svg';
import { isItemSelected } from '../../shared/helpers/string/classnames.helper';

interface ICreateDataSourceProps {
  closeModal: () => void;
  worksheets: Array<string>;
  setFileStep: React.Dispatch<React.SetStateAction<number>>;
  fileName: string;
  files: Array<any>;
  fileStep: number;
  dataSources: Array<NS_Workspace.IDataSourcesFile>;
  onAddDatasource: (val: Array<string>) => void;
}

/**
 * Create data source modal component
 *
 * @param params ICreateDataSourceProps params
 */

const CreateDataSource: React.FC<ICreateDataSourceProps> = ({
  closeModal,
  worksheets,
  fileName,
  dataSources,
  files,
  fileStep,
  setFileStep,
  onAddDatasource,
}) => {
  // States

  const [selectedWorksheets, setSelectedWorksheets] = useState<number[]>([]);

  useEffect(() => {
    setSelectedWorksheets([]);
  }, [worksheets]);

  // Functions

  const handleWorksheetClick = (index) => {
    if (selectedWorksheets.indexOf(index) !== -1) {
      removeWorksheet(index);
    } else {
      addWorksheet(index);
    }
  };

  const addWorksheet = (index) => {
    const indexes = [...selectedWorksheets];
    indexes.push(index);
    setSelectedWorksheets(indexes);
  };

  const removeWorksheet = (index) => {
    const indexes = [...selectedWorksheets];
    const i = indexes.indexOf(index);
    if (i !== -1) {
      indexes.splice(i, 1);
      setSelectedWorksheets(indexes);
    }
  };

  const handleSave = () => {
    const newWorksheets = worksheets.map((worksheet, index) => {
      if (selectedWorksheets.includes(index)) {
        return worksheet;
      }
      return '';
    });
    onAddDatasource(newWorksheets.filter((el) => el !== ''));
  };

  const dsFileNames = useMemo(() => dataSources.map((dt) => dt.sourceFile), [dataSources]);
  const dsSourceFiles = useMemo(() => dataSources.map((dt) => dt.datasourceName), [dataSources]);
  return (
    <div
      className="dataSourceCreationContainer"
      onClick={(e) => {
        const element = e.target as HTMLElement;
        if (element.className === 'dataSourceCreationContainer') {
          closeModal();
        }
      }}>
      <div className="dataSourceCreationModal">
        <div className="modalHeader">
          <strong>Choose the worksheet to upload</strong>
          <div className="rightBlock">
            {files.length > 1 && <div>File {`${fileStep}/${files.length}`}</div>}
            <img src={redCross} alt="croix" onClick={closeModal} />
          </div>
        </div>
        <p>
          Please pick worksheets from <strong>{fileName}</strong> to create a new data source.
        </p>
        <div className="worksheetsContainer">
          {worksheets.map((worksheet, i) => {
            let isImported =
              dsFileNames.includes(fileName) && (dsSourceFiles.includes(worksheet) || dsFileNames.includes(worksheet));
            if (isImported) {
              const datasource = dataSources.filter(
                (dt) => dt.sourceFile === fileName && dt.datasourceName === worksheet,
              );
              isImported = !!datasource?.length;
            }
            const key = `${worksheet} - ${i}`;
            return (
              <div
                className={`worksheet ${isItemSelected(selectedWorksheets.includes(i))}`}
                key={key}
                onClick={() => {
                  if (!isImported) {
                    handleWorksheetClick(i);
                  }
                }}>
                <strong>{isImported ? worksheet + ' (This Datasource has already been imported)' : worksheet}</strong>
                {selectedWorksheets.includes(i) || isImported ? (
                  <img src={redCross} alt="Croix rouge" />
                ) : (
                  <button className="btn-add">Add</button>
                )}
              </div>
            );
          })}
        </div>
        <div className="buttons">
          <button className="button--white" onClick={closeModal}>
            Cancel
          </button>
          <div>
            {selectedWorksheets.length !== 0 ? (
              <button className="button--green" type="submit" onClick={handleSave}>
                Add data source
              </button>
            ) : (
              <button className="button--green--disabled">Add data source</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateDataSource;
