import * as XLSX from '@sheet/chart';

import { createWorkbook, createWorksheet, generateArray, getSheetName } from '../../utils';

/* ---- line chart ---- */
export const createWaterfallPlot = (
  workspaceName: string,
  _data: any,
  title: string,
  dataLength: number,
  headerBeginPos: XLSX.CellAddress,
  valueBeginPos: XLSX.CellAddress,
  colors?: Array<string>,
) => {
  const wb = createWorkbook();
  const formattedData = [
    ...generateArray(workspaceName, title),
    ..._data.map((d) => {
      return [d.label, d.value];
    }),
  ];

  const ws3: any = createWorksheet(formattedData, false);
  const sheetName = getSheetName(title);
  XLSX.utils.book_append_sheet(wb, ws3, sheetName);
  return XLSX.writeFile(wb, `${title}.xlsx`, { cellStyles: true });
};
