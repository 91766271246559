import * as XLSX from '@sheet/chart';

import { createWorkbook, createWorksheet, generateArray, getSheetName, ROW_START } from '../utils';
import { IDataset } from './';

export const exportBarColumn = (workspaceName: string, title: string, _data: Array<any>) => {
  const wb = createWorkbook();
  const formaliseData = [...generateArray(workspaceName, title), ..._data.map((d) => [d.label, d.value])];
  const ws3: any = createWorksheet(formaliseData, false);
  const sheetName = getSheetName(title);
  XLSX.utils.book_append_sheet(wb, ws3, sheetName);
  return XLSX.writeFile(wb, `${title}.xlsx`, { cellStyles: true });
};

/* ---- bar line chart ---- */
export const createBarLinePlot = (workspaceName: string, _data: any, title: string, colors?: Array<string>) => {
  const wb = createWorkbook();
  const dataFormalised = formaliseData(workspaceName, title, _data._dataset, _data._lineset, _data._category);
  let formattedData: any = dataFormalised.data;

  const ws3: any = createWorksheet(formattedData, false);
  const sheetName = getSheetName(title);
  XLSX.utils.book_append_sheet(wb, ws3, sheetName);
  return XLSX.writeFile(wb, `${title}.xlsx`, { cellStyles: true });
};

const formaliseData = (
  workspaceName: string,
  title: string,
  datasetProvider: Array<IDataset>,
  lineset: Array<any>,
  category: Array<any>,
) => {
  let _dataset: Array<any> = [...generateArray(workspaceName, title)];
  let _lineset: Array<{ value: string }> = lineset?.[0]?.data || [];
  const size = category.length;
  let counter = ROW_START;
  let lineIterator = 0;
  for (let j = 0; j < size; j++) {
    _dataset[counter] = [category[j].label, parseFloat(_lineset[j].value)];
    for (const insideDataset of datasetProvider) {
      let dataset = insideDataset.dataset;
      if (dataset?.[0]?.data) {
        const internData = dataset[0].data;
        if (internData?.[lineIterator]?.value) {
          _dataset[counter] = [..._dataset[counter], internData[lineIterator].value];
        }
      }
    }
    counter = counter + 1;
    lineIterator = lineIterator + 1;
  }
  return { data: _dataset, size: _dataset.length };
};

export const getCellPosition = (data: number, lastRow: number, beginRow?: number, lastColumn?: number) => {
  return {
    begin: XLSX.utils.encode_cell({ r: beginRow ?? 0, c: data }),
    end: XLSX.utils.encode_cell({ r: lastRow, c: lastColumn ?? data }),
  };
};
