// Style
import './style.scss';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';

import CounterPartsSVG from '../../../../assets/img/counterpart.svg';
import RedCrossSVG from '../../../../assets/img/cross-red.svg';
import PivotSVG from '../../../../assets/img/pivot.svg';
import ExplanationBox from '../../../../elements/ExplanationBox';
import { isDisable } from '../../../../shared/helpers/string/classnames.helper';

interface IMiningPartMenu {
  nodeOpenedAccount: NS_TREE.IMining;
  setNodeOpenedAccount: React.Dispatch<React.SetStateAction<NS_TREE.IMining>>;
  selectedFile: Array<NS_Table.IColumnHeader>;
  miningContainerRef: React.RefObject<HTMLDivElement> | null;
  disabledPivot: boolean;
  disabledCounterPart: boolean;
}

const explanations = [
  {
    title: 'Mining',
    description: 'Select an Account element within the Report to use Mining features.',
    posX: '0px',
  },
  {
    title: 'Counterparts',
    description:
      'This feature displays the counterparts of each of the account entries, grouped by account family. Only available if an element of type "Account" has been selected within the report and if the data source has been tagged with TransactionId or EntryId when interpreted. Particularly useful for understanding how an account is increased or decreased.',
    posX: '0px',
  },
  {
    title: 'Pivot',
    description:
      'Group accounting entries of the opened & selected account according to the unique elements of another column of the data source.',
    posX: '0px',
  },
];

/**
 * Container used to display the data part of top menu in a report
 *
 * @param param NS_Tabs.ITab props
 * React.FC<IMiningPartMenu>
 */

const MiningPartMenu: React.FC<IMiningPartMenu> = ({
  nodeOpenedAccount,
  setNodeOpenedAccount,
  selectedFile,
  miningContainerRef,
  disabledPivot,
  disabledCounterPart,
}) => {
  // Context

  // Ref
  const ref = useRef<HTMLDivElement>(null);
  const nodeOpenedAccountRef = useRef<NS_TREE.IMining>(nodeOpenedAccount);
  const hoverTimer: { current: NodeJS.Timeout | null } = useRef(null);

  // States
  const [openPivot, setOpenPivot] = useState<boolean>(false);
  const [explanationSelected, setExplanationSelected] = useState<number>(-1);

  // Callback
  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current) {
        if (!(ref.current as any).contains(event.target)) {
          setOpenPivot(false);
        }
      }
    },
    // eslint-disable-next-line
    [ref.current],
  );

  const escapeClick = (event) => {
    if (event.key === 'Escape') {
      setOpenPivot(false);
      nodeOpenedAccountRef.current.nodeId && setNodeOpenedAccount({ nodeId: '' });
    }
  };
  // Effects

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', escapeClick);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', escapeClick);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    nodeOpenedAccountRef.current = nodeOpenedAccount;
  }, [nodeOpenedAccount]);

  // Function

  const handleInputHovered = (index: number) => {
    hoverTimer.current = setTimeout(() => {
      setExplanationSelected(index);
    }, 1000);
  };

  const handleInputNotHovered = () => {
    if (hoverTimer.current) {
      clearTimeout(hoverTimer.current);
      setExplanationSelected(-1);
    }
  };

  const renderCounterPartDisableClass = useMemo(() => {
    return isDisable(
      (disabledCounterPart ||
        !nodeOpenedAccount.nodeId ||
        (nodeOpenedAccount.nodeId && !nodeOpenedAccount.canCounterParts)) as boolean,
    );
  }, [nodeOpenedAccount, disabledCounterPart]);

  const renderPivotDisableClass = useMemo(() => {
    return isDisable(disabledPivot || !nodeOpenedAccount.nodeId);
  }, [nodeOpenedAccount, disabledPivot]);

  return (
    <div className="miningContainer" ref={miningContainerRef}>
      {explanationSelected > -1 && (
        <ExplanationBox
          title={explanations[explanationSelected].title}
          description={explanations[explanationSelected].description}
          posX={explanations[explanationSelected].posX}
        />
      )}
      <div
        className={`counterPart smallTitle ${renderCounterPartDisableClass}`}
        onClick={(e) => {
          e.stopPropagation();
          if (nodeOpenedAccount.canCounterParts && !disabledCounterPart) {
            setNodeOpenedAccount({ ...nodeOpenedAccount, selectedLineDetail: 'counterparts', noCall: false });
          }
          handleInputNotHovered();
        }}
        onMouseEnter={() => handleInputHovered(nodeOpenedAccount.nodeId ? 1 : 0)}
        onMouseLeave={() => handleInputNotHovered()}>
        <div className="toolBarSquareButton" data-cy="counterPart-btn">
          <ReactSVG src={CounterPartsSVG} className="svg-wrapper counterPartSVG" />
        </div>
        Counterparts
      </div>
      <div
        className={`counterPart smallTitle ${renderPivotDisableClass}`}
        onClick={(e) => {
          e.stopPropagation();
          if (nodeOpenedAccount.nodeId && !disabledPivot) {
            setOpenPivot(true);
          }
          handleInputNotHovered();
        }}
        onMouseEnter={(_e) => handleInputHovered(nodeOpenedAccount.nodeId ? 2 : 0)}
        onMouseLeave={() => handleInputNotHovered()}>
        <div className="toolBarSquareButton" data-cy="pivot-btn">
          <ReactSVG src={PivotSVG} className="svg-wrapper pivotSVG" />
        </div>
        <div className="title">
          Pivot {nodeOpenedAccount.selectedLineDetail === 'pivot' && `(${nodeOpenedAccount.selectedHeader})`}
        </div>
      </div>
      {openPivot && (
        <div className="pivotContainer" ref={ref}>
          {selectedFile.map((value, vKey) => {
            if (value.colParams.colVisible) {
              const key = `${vKey}-${value.Header}`;
              return (
                <div
                  className={`option ${value.Header === nodeOpenedAccount.selectedHeader && 'active'}`}
                  key={key}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenPivot(false);
                    value.Header !== nodeOpenedAccount.selectedHeader
                      ? setNodeOpenedAccount({
                          ...nodeOpenedAccount,
                          selectedLineDetail: 'pivot',
                          selectedHeader: value.Header,
                          noCall: false,
                        })
                      : setNodeOpenedAccount({
                          ...nodeOpenedAccount,
                          selectedLineDetail: 'overview',
                          selectedHeader: 'Pivot',
                          overview: true,
                          noCall: false,
                        });
                  }}>
                  {value.Header}
                  {value.Header === nodeOpenedAccount.selectedHeader && (
                    <ReactSVG src={RedCrossSVG} className="svg-wrapper redCrossSVG" />
                  )}
                </div>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};

export default MiningPartMenu;
