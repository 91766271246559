import './style.scss';

import { useCallback, useContext, useEffect, useState } from 'react';

import { WorkspaceContext } from '../../context/WorkspaceContext';
import CustomModal from '../../elements/CustomModal';
import ReportSelectList from '../../elements/ReportSelectList';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import { setCollections, setMappings, setTemplateCollections } from '../../redux/workspace';
import { getAllTemplateCollectionsForWorkspace } from '../../shared/api/templateCollection';
import { ISelectedMapping } from '../../shared/constant/mapping.consts';
import { COLLECTION_TYPE, REPORT_LIST_TYPE } from '../../utils/enum';
import { getUniqueListBy } from '../../utils/getUniqueListBy';

interface IExportMapping {
  selected: ISelectedMapping;
  setSelected: React.Dispatch<React.SetStateAction<ISelectedMapping>>;
  closeModal: () => void;
  handleSave: (datasourceId: string, graphId: string) => void;
  waitingAPI?: boolean;
}

/**
 * Component used to configure an exported file
 */

const ExportMapping: React.FC<IExportMapping> = ({ selected, closeModal, handleSave, waitingAPI, setSelected }) => {
  const {
    collections: workspaceCollections,
    mappings: workspaceMappings,
    templateCollections,
  } = useAppSelector((state) => state.workspace);
  const { workspaceId } = useContext(WorkspaceContext);
  const dispatch = useAppDispatch();
  const { generatedDataSources } = useContext(WorkspaceContext);
  const [groupedDatasourceElements, setGroupedDatasourceElements] = useState<NS_REPORT.IGroupedSelectList>({});
  const init_collections = {
    scorf: [],
    customers: [],
  };
  const [groupedCollectionElements, setGroupedCollectionElements] = useState<NS_REPORT.ITypeCollections>({
    ...init_collections,
  });
  const [collections, setLocalCollections] = useState([...workspaceCollections]);
  const [mappings, setLocalMappings] = useState([...workspaceMappings]);
  useEffect(() => {
    groupDatasourcesList();
  }, [generatedDataSources]);

  useEffect(() => {
    if (!templateCollections) {
      getTemplateCollections();
      return;
    }
    let newCollectionItems = { ...init_collections } as NS_REPORT.ITypeCollections;
    const newMappings = [] as Array<string>;
    const newName = [] as Array<string>;
    for (const template of templateCollections) {
      const collectionItem = {
        id: template.collectionId,
        name: template.collectionName,
      };
      if (template.customerId === COLLECTION_TYPE.SCORF) {
        newCollectionItems = {
          ...newCollectionItems,
          scorf: [...newCollectionItems.scorf, collectionItem],
        };
      } else {
        newCollectionItems = {
          ...newCollectionItems,
          customers: [...newCollectionItems.customers, collectionItem],
        };
      }
      newName.push(template.collectionName);
      newMappings.push(template.graphId);
    }
    setGroupedCollectionElements(newCollectionItems);
    if (!mappings.length || !collections.length) {
      setLocalCollections(newName);
      setLocalMappings(newMappings);
      dispatch(setCollections(newName));
      dispatch(setMappings(newMappings));
    }
  }, [templateCollections, mappings, collections]);

  const getTemplateCollections = useCallback(() => {
    if (!templateCollections?.length) {
      getAllTemplateCollectionsForWorkspace(workspaceId)
        .then((res) => {
          if (res?.data) {
            dispatch(setTemplateCollections(res.data));
          }
        })
        .catch((err) => {});
    }
  }, [templateCollections]);

  const groupDatasourcesList = () => {
    const groupedDatasources = {};
    const genericDatasources: { id: string; name: string }[] = [];
    const normalDatasources: { id: string; name: string }[] = [];
    for (const datasource of generatedDataSources) {
      if (datasource['sourceFile'] === 'Scorf_Generic_Datasource') {
        genericDatasources.push({ id: datasource.fileId, name: datasource.displayName });
      } else {
        normalDatasources.push({ id: datasource.fileId, name: datasource.displayName });
      }
    }
    // INFO: DIRTY PATCH, AKA APPLYING FLEX TAPE ON LEAKING WATER TANK, app needs to have writing handling
    const normalDatasourcesUnique = getUniqueListBy(normalDatasources, 'id');

    groupedDatasources['Imported'] = normalDatasourcesUnique;
    groupedDatasources['Generic'] = genericDatasources;

    setGroupedDatasourceElements(groupedDatasources);
  };

  const handleConfirmModal = useCallback(() => {
    if (selected.datasource && selected.graphId) {
      handleSave(selected.datasource, selected.graphId);
    }
  }, [selected]);

  const setSelectedDatasourceItem = useCallback(
    (datasourceId) => {
      setSelected({ ...selected, datasource: datasourceId });
    },
    [selected],
  );

  const setCollectionItem = useCallback(
    (collectionId) => {
      const index = templateCollections
        .map((templateC) => templateC.collectionId)
        .findIndex((c) => {
          return c === collectionId;
        });
      if (index >= mappings.length) {
        setSelected({ ...selected, graphId: '' });
      } else {
        setSelected({ ...selected, graphId: mappings[index] });
      }
    },

    [mappings, selected],
  );

  const handleInputNotHovered = useCallback(() => {}, []);

  const renderDatasourcesList = () => {
    const datasourceIndex = generatedDataSources.findIndex((el) => el.fileId === selected.datasource);
    return (
      <ReportSelectList
        selectedItemName={generatedDataSources[datasourceIndex]?.displayName || ''}
        setSelectedItem={setSelectedDatasourceItem}
        isFetching={false}
        disabled={false}
        groupedElements={groupedDatasourceElements}
        placeholder={'Select a data source'}
      />
    );
  };
  const renderCollections = () => {
    const _selectedTCIndex = mappings?.length ? mappings.findIndex((x) => x === selected.graphId) : -1;
    const collectionName = collections?.length && collections[_selectedTCIndex] ? collections[_selectedTCIndex] : '';
    return (
      <ReportSelectList
        selectedItemName={collectionName}
        setSelectedItem={setCollectionItem}
        isFetching={false}
        disabled={false}
        groupedElements={{}}
        placeholder={'Select a collection'}
        type={REPORT_LIST_TYPE.COLLECTION}
        collections={groupedCollectionElements}
      />
    );
  };
  return (
    <CustomModal
      onConfirm={handleConfirmModal}
      onCancel={closeModal}
      onClose={closeModal}
      confirmLabel="Export"
      confirmLoader={waitingAPI}
      title="Export mapping"
      className="exportSettingModal">
      <p>
        Select the data source and the Template Collection for which you want to export the mapping: for each account of
        this data source, you will have the destination concept according to the chosen template collection.
      </p>
      <div className="export-mappings-select">
        <div className="subItem subItemFirst" onClick={() => handleInputNotHovered()}>
          <div className={`smallTitle`}>Data sources</div>
          {renderDatasourcesList()}
        </div>
        <div className={`subItem subItemFirst`} onClick={() => handleInputNotHovered()}>
          <div className={`smallTitle`}>Template Collections</div>
          {renderCollections()}
        </div>
      </div>
    </CustomModal>
  );
};

export default ExportMapping;
