import {
  IInsideDataSetData,
  LINE_CHARTS_COLORS,
} from '../../constant/chart.consts';
import { ChartType } from '../../constant/datasource.consts';
import {
  CHART_DATA_FONT,
  formattedDisplayValue,
  getFiscalYears,
  TChartData,
} from '../utils';
import { WATERFALL_COLORS } from './waterfall';

const CHARTS_COLORS = [
  '#7eceb0',
  '#40a781',
  '#e27973',
  '#8dbff2',
  '#bad9f7',
  '#ffe5b3',
  '#235c47',
  '#bd3629',
  '#e1f4ed',
  '#fcf2f1',
];
export interface IDataset {
  dataset: Array<IInsideDataSet>;
}

export interface IInsideDataSet {
  seriesname: string;
  color?: string;
  includeInLegend?: number;
  data: Array<IInsideDataSetData>;
}
export interface ICategory {
  label: string;
}
export interface IColumnMedianDataSet {
  seriesname: string;
  color?: string;
  data: Array<{ value: string }>;
}

// BAR WITH LINE
export const formalizeChartColumn2DConfigs = (data: TChartData) => {
  const type = 'msstackedcolumn2dlinedy';
  let isOpening = false;
  let dataToExclude = '';
  let lineset: any = [];
  let category: Array<ICategory> = [];
  let dataset: Array<IDataset> = [];
  let reference = '';
  if (data?.fiscalYearsInfo) {
    reference = Object.keys(data.fiscalYearsInfo)[0];
  }

  if (data?.reportContentSetup?.otherParams?.displayOpening) {
    isOpening = true;
  }
  const beginData = isOpening ? 0 : 1;
  if (data?.reportContentSetup?.otherParams?.median?.length) {
    dataToExclude = data.reportContentSetup.otherParams.median[0];
  }
  if (data?.conceptValues?.valuesLegend) {
    const valueLegends = data?.conceptValues?.valuesLegend;
    for (const [_key, _value] of Object.entries(valueLegends)) {
      if (_key === dataToExclude) {
        const values = (data?.conceptValues?.values[_key] as Array<string>) || [];
        let linesetData: Array<{ value: string }> = [];
        for (let k = beginData; k < values.length; k++) {
          linesetData = [
            ...linesetData,
            { value: values[k], parentYAxis: 'P', displayValue: formattedDisplayValue(values[k]) } as any,
          ];
        }
        lineset = [
          {
            seriesname: _value,
            showValues: '0',
            data: linesetData || [],
            color: LINE_CHARTS_COLORS.LINE,
            anchorBgColor: LINE_CHARTS_COLORS.MARKERS,
            anchorRadius: 3,
            parentYAxis: 'P',
          },
        ];
      }
    }
  }
  // headers
  if (data?.conceptValues?.header) {
    const { header } = data.conceptValues;
    for (let k = beginData; k < header.length; k++) {
      const label = data.conceptValues.header[k];
      category = [...category, { label }];
    }
  }
  if (data?.conceptValues?.valuesLegend) {
    const { values, valuesLegend } = data.conceptValues;
    let keys = Object.keys(valuesLegend).reverse();
    for (let k = 0; k < keys.length; k++) {
      const key = keys[k];
      if (key !== dataToExclude) {
        const seriesname: string = valuesLegend[key];
        let _data: Array<IInsideDataSetData> = [];
        for (const [_key, value] of Object.entries(values)) {
          const _v = value as Array<string>;
          if (_key === key) {
            if (_v?.length) {
              for (let j = beginData; j < _v.length; j++) {
                _data = [
                  ..._data,
                  {
                    value: _v[j],
                    label: seriesname,
                    displayValue: formattedDisplayValue(_v[j]),
                  },
                ];
              }
            }
          }
        }
        const insideDataset: Array<IInsideDataSet> = [{ seriesname, data: _data, color: CHARTS_COLORS[k] }];
        dataset = [...dataset, { dataset: insideDataset }];
      }
    }
  }
  return {
    reference,
    title: data.reportTitle,
    config: {
      type,
      dataLoadStartMessage: 'Please wait, chart is loading the data....',
      dataLoadStartMessageFontSize: '14',
      renderAt: 'chart-container',
      width: '100%',
      height: '400',
      dataFormat: 'json',
      dataSource: {
        chart: {
          subcaption: '', // Sur les décaissements: ajouter les stacks Fournisseurs - Personnel - Etats - Autres flux
          divlineAlpha: '100',
          divlineColor: '#999999',
          numberPrefix: '€',
          plottooltext: '$seriesname : $displayValue',
          showxaxisline: '1',
          showyaxisline: '1',
          ...CHART_DATA_FONT,
        },
        categories: [
          {
            category,
          },
        ],
        dataset,
        lineset,
      },
    },
  };
};

export const formalize2DColumn = (data: TChartData) => {
  if (data?.calculatedValues?.values?.effect && Object.keys(data.calculatedValues.values.effect).length !== 0) {
    return formalizeSimple2DColumn(data);
  } else {
    return formalizeChartColumn2DConfigs(data);
  }
};

export const formalizeSimple2DColumn = (data: TChartData) => {
  let reference = getFiscalYears(data.fiscalYearsInfo);
  let dataset: Array<IInsideDataSetData> = [];
  const { valuesLegend, values } = data.calculatedValues;
  const { effect } = values as any;
  let keys = Object.keys(valuesLegend);
  for (let k = 0; k < keys.length; k++) {
    const key = keys[k];
    const label: string = valuesLegend[key];
    // headers
    for (const [_key, value] of Object.entries(effect)) {
      const _v = value as Array<string>;
      if (_key === key) {
        if (_v?.length) {
          for (const value of _v) {
            let color = (value as any) < 0 ? WATERFALL_COLORS.NEGATIVE : WATERFALL_COLORS.POSITIVE;
            if (k === 0) {
              color = WATERFALL_COLORS.TOTALS;
            }
            dataset = [
              ...dataset,
              {
                value,
                label: label,
                color,
              },
            ];
          }
        }
      }
    }
  }

  if (data?.reportTitle?.includes('EBITDA')) {
    const end = dataset.find((d) => d.label && d.label.includes('End'));
    dataset = [...dataset.filter((d) => d.label && !d.label.includes('End'))];
    if (end) dataset = [...dataset, { ...end, color: WATERFALL_COLORS.TOTALS }];
  }
  return {
    reference,
    title: data.reportTitle,
    config: {
      type: ChartType.COLUMN,
      dataLoadStartMessage: 'Please wait, chart is loading the data....',
      dataLoadStartMessageFontSize: '14',
      renderAt: 'chart-container',
      width: '100%',
      height: '400',
      dataFormat: 'json',
      dataSource: {
        chart: {
          caption: '',
          subcaption: '', // Sur les décaissements: ajouter les stacks Fournisseurs - Personnel - Etats - Autres flux
          theme: 'fusion',
          xaxislinecolor: '#ACACAC',
          yaxislinecolor: '#ACACAC',
          numberPrefix: '€',
          showxaxisline: '1',
          showyaxisline: '1',
          divlineThickness: '0',
          divLineDashed: '0',
          divLineDashLen: '0',
          exportEnabled: '1',
          exportShowMenuItem: '0',
          exportFileName: data.reportTitle,
        },
        data: [...dataset],
      },
    },
  };
};

export const formalize2DColumnMedian = (params: TChartData) => {
  let average = { value: '0', name: 'Average' };
  let dataToExclude = '';
  let category: Array<ICategory> = [];
  let dataset: Array<IColumnMedianDataSet> = [];
  let reference = '';
  let isOpening = !!params?.reportContentSetup?.otherParams?.displayOpening;
  if (params?.fiscalYearsInfo) {
    reference = Object.keys(params.fiscalYearsInfo)[0];
  }
  if (params?.reportContentSetup?.otherParams?.median?.length) {
    dataToExclude = params.reportContentSetup.otherParams.median[0];
  }
  if (params?.conceptValues?.valuesLegend) {
    const valueLegends = params?.conceptValues?.valuesLegend;
    for (const [_key, _value] of Object.entries(valueLegends)) {
      if (_key === dataToExclude) {
        const values = params?.conceptValues?.values;
        if (values?.[_key]) {
          const element = isOpening ? values[_key][0] : values[_key][1];
          const notFixed: number = element;
          average.value = notFixed.toFixed(2);
        }
      }
    }
  }
  // headers
  if (params?.conceptValues?.header) {
    const { header } = params.conceptValues;
    for (let k = 0; k < header.length; k++) {
      const label = params.conceptValues.header[k];
      category = [...category, { label }];
    }
  }
  if (params?.conceptValues?.valuesLegend) {
    const { values, valuesLegend } = params.conceptValues;
    let keys = Object.keys(valuesLegend);
    for (const key of keys) {
      const isCalculatedData = dataToExclude === key;
      if (!isCalculatedData) {
        const seriesname: string = valuesLegend[key];
        let _data: Array<{ value: string }> = [];
        for (const [_key, value] of Object.entries(values)) {
          const _v = value as Array<string>;
          if (_key === key) {
            if (_v?.length) {
              for (const _val of _v) {
                _data = [
                  ..._data,
                  {
                    value: _val,
                  },
                ];
              }
            }
          }
        }
        const insideDataset: IColumnMedianDataSet = { seriesname, data: _data };
        dataset = [...dataset, { ...insideDataset }];
      }
    }
  }
  return {
    reference,
    title: params.reportTitle,
    config: {
      type: ChartType.COLUMN_MEDIAN,
      dataLoadStartMessage: 'Please wait, chart is loading the data....',
      dataLoadStartMessageFontSize: '14',
      renderAt: 'chart-container',
      width: '100%',
      height: '400',
      dataFormat: 'json',
      dataSource: {
        chart: {
          caption: '',
          subCaption: '',
          theme: 'fusion',
          lineThickness: '2',
          lineColor: LINE_CHARTS_COLORS.LINE,
          anchorBgColor: LINE_CHARTS_COLORS.MARKERS,
          anchorRadius: 3,
          numberPrefix: '€',
          plotFillAlpha: '80',
          xaxislinecolor: '#ACACAC',
          yaxislinecolor: '#ACACAC',
          showxaxisline: '1',
          showyaxisline: '1',
          divlineThickness: '0',
          divLineDashed: '0',
          divLineDashLen: '0',
        },
        categories: [
          {
            category,
          },
        ],
        dataset,
        trendlines: [
          {
            line: [
              {
                startvalue: average.value,
                color: '#96979A',
                displayvalue: `${average.name} ${average.value}`,
                valueOnRight: 1,
                thickness: 1,
                dashed: '1',
              },
            ],
          },
        ],
      },
    },
  };
};
