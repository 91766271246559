import './style.scss';

import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import ExcelExport from 'fusioncharts/fusioncharts.excelexport';
import Gantt from 'fusioncharts/fusioncharts.gantt';
import Power from 'fusioncharts/fusioncharts.powercharts';
import Widgets from 'fusioncharts/fusioncharts.widgets';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import ReactFC from 'react-fusioncharts';

import { exportSnapshot, ExportTypes } from '../../../../shared/helpers/report/reports.helper';
import ToastHelper from '../../../../shared/helpers/toast/ToastHelper';

interface IChart {
  chartConfigs: Object;
  fileName: string;
  isFromReport?: boolean;
  newChartRef?: any;
  insideChartRef?: RefObject<HTMLDivElement>;
}
// @ts-ignore: License not reconized
FusionCharts.options.license({ key: process.env.REACT_APP_FUSION_CHARTS_KEY, creditLabel: false });
ReactFC.fcRoot(FusionCharts, Power, Charts, Widgets, Gantt, FusionTheme, ExcelExport);

const Chart: React.FC<IChart> = ({ chartConfigs, fileName, isFromReport, newChartRef, insideChartRef }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const exportMenuRef = useRef<HTMLDivElement>(null);
  const exportBtnRef = useRef<HTMLDivElement>(null);
  const [isOpenExportMenu, setOpenExportMenu] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('mousemove', toggleMenuHover);
    return () => {
      window.removeEventListener('mousemove', toggleMenuHover);
    };
  }, []);

  const handleExport = (type: ExportTypes) => () => {
    if (chartRef.current) {
      exportSnapshot(type, fileName, chartRef.current, true).catch((e) => {
        ToastHelper.error(`Failed to export chart: ${e}`);
      });
    }
  };

  const handleOpenExportMenu = () => {
    setOpenExportMenu(true);
  };

  const toggleMenuHover = (e) => {
    if (
      exportMenuRef.current &&
      !exportMenuRef.current.contains(e.target) &&
      exportBtnRef.current &&
      !exportBtnRef.current.contains(e.target)
    ) {
      setOpenExportMenu(false);
    }
  };
  return (
    <>
      {isFromReport ? (
        <>
          <div className="chartTools">
            <div
              className={`exportBtn`}
              onMouseEnter={handleOpenExportMenu}
              onClick={handleOpenExportMenu}
              ref={exportBtnRef}>
              <span />
            </div>
            {isOpenExportMenu && (
              <div className="exportMenu" ref={exportMenuRef}>
                <p onClick={handleExport(ExportTypes.PNG)}>Export as PNG</p>
                <p onClick={handleExport(ExportTypes.JPEG)}>Export as JPEG</p>
                <p onClick={handleExport(ExportTypes.PDF)}>Export as PDF</p>
              </div>
            )}
          </div>
          <div className="exportableContainer" ref={chartRef} id="chart1">
            <ReactFC {...chartConfigs} ref={insideChartRef as any} />
          </div>
        </>
      ) : (
        <div ref={newChartRef}>
          <ReactFC {...chartConfigs} ref={insideChartRef as any} />
        </div>
      )}
    </>
  );
};

export default Chart;
