import CryptoJS from 'crypto-js';
import jDataView from 'jdataview';
import React, { useMemo, useReducer } from 'react';

// Scorf Components
import WorkspaceContainer from '../../containers/WorkspaceContainer';
import { IUnionLine } from '../../modals/Union';
import { useAppDispatch } from '../../redux/hook';
import { resetWorkspaceName } from '../../redux/workspace';
// API
import * as DataSourceAPI from '../../shared/api/dataSource';
import {
  base64encode,
  base64UrlEncodedEncode,
  Block,
  BlockMetadata,
  checkColumnsConsistencyAndFormatNumbers,
  CreateDatasourceDto,
  ImportAPI,
  UploadChunkActions,
  UploadChunkBody,
} from '../../shared/api/import';
import * as WorksheetAPI from '../../shared/api/tabs';
import * as WorkspaceAPI from '../../shared/api/workspace';
import { states } from '../../shared/constant/datasource.consts';
import ToastHelper from '../../shared/helpers/toast/ToastHelper';
import {
  actions,
  initialState,
  workspaceContextReducer,
} from '../../shared/helpers/workspaceContext/workspaceContextHelper';
import { REPORT_TABS_TYPE } from '../../utils/constant';

const Buffer = require('buffer/').Buffer;

const defaultValueWorkspaceContext = {
  customerId: initialState.customerId,
  dataSources: initialState.dataSources,
  generatedDataSources: initialState.generatedDataSources,
  dataSourcesPreview: initialState.dataSourcesPreview,
  name: initialState.name,
  tabs: initialState.tabs,
  workspaceId: initialState.workspaceId,
  exportedFiles: initialState.exportedFiles,
  loadingDataSourcesNumber: initialState.loadingDataSourcesNumber,
  selectedFile: initialState.selectedFile,
  fetching: initialState.fetching,
  modalPreview: initialState.modalPreview,
  loadingTabs: initialState.loadingTabs,
  containersToCheck: initialState.containersToCheck,
  colorOptions: initialState.colorOptions,
  preferredColors: initialState.preferredColors,
  sumFooter: initialState.sumFooter,
  preferredNumbers: initialState.preferredNumbers,
  tabDelete: initialState.tabDelete,
  uploadingFilesProgression: initialState.uploadingFilesProgression,
  reportSaving: initialState.reportSaving,
};
export const WorkspaceContext = React.createContext<any>(defaultValueWorkspaceContext);

const WorkspaceProvider = () => {
  const [state, dispatch] = useReducer(workspaceContextReducer, initialState);
  const reduxDispatch = useAppDispatch();
  const importedDataSources = useMemo(
    () => state.dataSources.filter((el) => el.sourceFile !== 'Scorf_Generic_Datasource'),
    [state.dataSources],
  );
  const genericDataSources = useMemo(
    () => state.dataSources.filter((el) => el.sourceFile === 'Scorf_Generic_Datasource'),
    [state.dataSources],
  );

  const value = {
    dataSources: state.dataSources,
    customerId: state.customerId,
    generatedDataSources: state.generatedDataSources,
    importedDataSources: importedDataSources,
    genericDataSources: genericDataSources,
    dataSourcesPreview: state.dataSourcesPreview,
    name: state.name,
    tabs: state.tabs,
    workspaceId: state.workspaceId,
    exportedFiles: state.exportedFiles,
    loadingDataSourcesNumber: state.loadingDataSourcesNumber,
    selectedFile: state.selectedFile,
    fetching: state.fetching,
    modalPreview: state.modalPreview,
    loadingTabs: state.loadingTabs,
    containersToCheck: state.containersToCheck,
    colorOptions: state.colorOptions,
    preferredColors: state.preferredColors,
    sumFooter: state.sumFooter,
    preferredNumbers: state.preferredNumbers,
    tabDelete: state.tabDelete,
    uploadingFilesProgression: state.uploadingFilesProgression,
    reportSaving: state.reportSaving,

    // workspace
    getWorkspace: (workspaceId) => {
      WorkspaceAPI.getWorkspace(workspaceId)
        .then((res) => {
          if (res) {
            const { data } = res;
            if (res.data?.name) {
              reduxDispatch(resetWorkspaceName(res.data.name));
            }
            dispatch({ type: actions.SET_WORKSPACE, payload: data });
          } else {
            ToastHelper.error(`Failed to get workspace data`);
          }
        })
        .catch((e) => {
          ToastHelper.error(`Failed to get workspace data`, e);
        });
    },

    setWorkspaceId: (workspaceId) => {
      dispatch({ type: actions.SET_WORKSPACEID, payload: workspaceId });
    },

    setSavingReport: (onSaving: boolean) => {
      dispatch({ type: actions.SET_REPORT_SAVING, payload: onSaving });
    },
    saveColorOptions: (workspaceId: string, colorOptions: NS_Workspace.IColorOptions) => {
      WorkspaceAPI.updateGlobalColors(workspaceId, colorOptions)
        .then((res) => {
          if (res.data.message === 'SUCCESS') {
            dispatch({ type: actions.SET_OPTION_COLORS, payload: colorOptions });
            ToastHelper.success(`Colors updated`);
          }
        })
        .catch((e) => {
          ToastHelper.error(`Failed to update colors`, e);
        });
    },

    setPreferredColors: (workspaceId: string, preferredColors: string[]) => {
      WorkspaceAPI.updatePreferredColors(workspaceId, preferredColors)
        .then((res) => {
          if (res.data.message === 'SUCCESS') {
            dispatch({ type: actions.SET_PREFERRED_COLORS, payload: preferredColors });
          }
        })
        .catch((e) => {
          ToastHelper.error(`Could not update recent colors`, e);
        });
    },

    setPreferredNumbers: (workspaceId: string, data: NS_Workspace.IPreferedNumbers) => {
      WorkspaceAPI.updateNumberStyle(workspaceId, data)
        .then((res) => {
          if (res.data.message === 'SUCCESS') {
            dispatch({ type: actions.SET_PREFERRED_NUMBERS, payload: data });
            ToastHelper.success(`Numbers updated`);
          }
        })
        .catch((e) => {
          ToastHelper.error(`Could not update recent numbers`, e);
        });
    },

    setSumInFooter: (sum: NS_Workspace.ISumFooter) => {
      dispatch({ type: actions.COUNT_SUM_FOOTER, payload: sum });
    },

    // Datasources
    addDataSource: async (
      workspaceId: string,
      datasourceInfo: NS_API.IDatasourceInfo,
      buffer: Uint8Array,
    ): Promise<Buffer | void> => {
      const preUploadFileFormatting = (separatorToUse: string): Uint8Array => {
        // Detect encoding, then convert to utf8
        let bufferAsString = new jDataView(Buffer.from(buffer, 'utf8')).getString(datasourceInfo.size, 0, 'utf8');

        if (datasourceInfo.separator !== separatorToUse) {
          // clean file of useless SEPARATOR_TO_USE
          bufferAsString = bufferAsString.replace(new RegExp(separatorToUse, 'gm'), ',');
          // eslint-disable-next-line no-control-regex
          bufferAsString = bufferAsString.replace(new RegExp(',+\n', 'gm'), '\n');
          // Remove \" from datasource
          bufferAsString = bufferAsString.replace(new RegExp('\\"', 'gm'), '');
          // replace separator by SEPARATOR_TO_USE
          // escape pipe char for regex
          bufferAsString = bufferAsString.replace(
            new RegExp(datasourceInfo.separator === '|' ? '\\|' : datasourceInfo.separator, 'gm'),
            separatorToUse,
          );
        } else {
          // match all strings with ; inside, replace ; by empty character
          bufferAsString = bufferAsString.replace(/"[^"]*"(?=(?:[^"]*"[^"]*")*[^"]*$)/gm, function (match) {
            return match.replace(/[;]+/g, '');
          });
        }

        if (datasourceInfo.extension === 'csv') {
          const checkResult = checkColumnsConsistencyAndFormatNumbers(bufferAsString, separatorToUse);
          bufferAsString = checkResult.fileStr;
        }

        return Buffer.from(bufferAsString, 'utf8');
      };

      const formattedBuffer = preUploadFileFormatting(';');

      const fileName = datasourceInfo.originalName;
      const CHUNK_SIZE = 4 * 1024 * 1024; // 4 MiB, is the max size for a single chunk due to azure blob block upload limitations
      let isFileAvailable = true;
      try {
        isFileAvailable = await ImportAPI.uploadChunk(workspaceId, fileName, UploadChunkActions.CHECK_AVAILABILITY);
        if (isFileAvailable) {
          ToastHelper.error('Cannot upload same file twice');
          dispatch({ type: actions.EDIT_LOADING_DATASOURCES_LIST, payload: -1 });
          return;
        }
      } catch (error: unknown) {
        ToastHelper.error('Error while checking file availability, please try again');
        dispatch({ type: actions.EDIT_LOADING_DATASOURCES_LIST, payload: -1 });
        return;
      }

      // Cut buffer to chunks and generate metadata for chunks for upload

      const numberOfFileChunks = Math.ceil(Number(formattedBuffer.byteLength) / CHUNK_SIZE);
      const allBlocksInFile: BlockMetadata[] = Array.from(
        Array(numberOfFileChunks),
        (_, i) => new BlockMetadata(i, Number(formattedBuffer.byteLength), CHUNK_SIZE),
      );
      const blockIdList: string[] = allBlocksInFile.map((element) => element.blockId);
      let missingBlocks: BlockMetadata[] = [];
      let uncommittedBlocks: Block[] | undefined;
      try {
        const existingBlocks = await ImportAPI.getUncommittedChunksList(workspaceId, fileName);
        uncommittedBlocks = existingBlocks.filter((block) => block.size === CHUNK_SIZE);
        if (uncommittedBlocks.length === 0) {
          // If there is no uncommitted blocks what means that we do regular upload from the beginning
          missingBlocks = allBlocksInFile;
        } else {
          missingBlocks = allBlocksInFile.filter(
            (blockInFile) =>
              uncommittedBlocks?.find(
                (uncommittedBlock) =>
                  uncommittedBlock.name === blockInFile.blockId && uncommittedBlock.size === blockInFile.size,
              ) == null,
          );
        }
      } catch (error: unknown) {
        ToastHelper.error('Upload initialization error');
        dispatch({ type: actions.EDIT_LOADING_DATASOURCES_LIST, payload: -1 });
        return;
      }

      const jDataViewOfBuffer = new jDataView(formattedBuffer);
      let missingBlocksLength = missingBlocks.length;

      for (const blockMetadata of missingBlocks) {
        const chunkData = jDataViewOfBuffer.getString(blockMetadata.size, blockMetadata.index, 'utf8');

        const chunkDataMD5 = CryptoJS.MD5(chunkData).toString();

        const chunkDataMD5Base64 = base64encode(chunkDataMD5);
        const uploadChunkBody: UploadChunkBody = {
          blockMetadata,
          chunkData: chunkData,
          checksumMD5: chunkDataMD5Base64,
        };
        dispatch({
          type: actions.UPDATE_UPLOADING_FILES,
          payload: { fileName: fileName, percentage: 0 },
        });

        try {
          await ImportAPI.uploadChunk(workspaceId, fileName, uploadChunkBody);
          missingBlocksLength--;
          dispatch({
            type: actions.UPDATE_UPLOADING_FILES,
            payload: {
              fileName: fileName,
              percentage: Math.floor(100 * (1 - missingBlocksLength / numberOfFileChunks)),
            },
          });
        } catch (error: unknown) {
          ToastHelper.error('Error while file upload, retry the upload');
          dispatch({ type: actions.EDIT_LOADING_DATASOURCES_LIST, payload: -1 });
          return;
        }
      }

      const partialDatasourceDto: CreateDatasourceDto = {
        workspaceId: workspaceId,
        fileId: base64UrlEncodedEncode(fileName),
        datasourceName: datasourceInfo.datasourceName, ///generateWorksheet
        sourceFile: datasourceInfo.sourceFile,
        status: '0',
        error: -1,
        deleted: 0,
        structureUrl: '',
        countryCode: 'FR', //TODO: hardcoded, need datasource country management detection solution
        mimeType: datasourceInfo.mimeType,
        encodingType: 'utf8', // INFO: hardcoded, SheetJS output encoded in UTF8 (???)
        size: Number(datasourceInfo.size),
        lineBreaker: datasourceInfo.lineBreaker,
        lines: Number(datasourceInfo.lines),
        separator: datasourceInfo.separator,
      };

      try {
        await ImportAPI.uploadChunk(
          workspaceId,
          fileName,
          { blockIds: blockIdList, datasource: partialDatasourceDto },
          UploadChunkActions.COMMIT_CHUNK_LIST,
        );
      } catch (error: unknown) {
        ToastHelper.error('Error while saving the uploaded file');
        dispatch({ type: actions.EDIT_LOADING_DATASOURCES_LIST, payload: -1 });
        return;
      }
      let datasource: Partial<NS_Workspace.IDataSourcesFile> = {};
      const datasourceId = base64UrlEncodedEncode(fileName);

      try {
        datasource = await DataSourceAPI.getDatasource(workspaceId, datasourceId);
      } catch (error: unknown) {
        ToastHelper.error('System was not able to fetch datasource information, please consider refreshing the tab');
        dispatch({ type: actions.EDIT_LOADING_DATASOURCES_LIST, payload: -1 });
        return;
      }

      datasource.structurationStatus = states.PROCESSING;
      dispatch({ type: actions.ADD_DATASOURCE, payload: datasource });
      window.gtag('event', 'datasource_imported', {
        event_label: 'datasource_imported',
        originalName: datasourceInfo.originalName,
        lines: datasourceInfo.lines,
        workspaceId: workspaceId,
      });
      dispatch({ type: actions.UPDATE_UPLOADING_FILES, payload: { fileName, percentage: -1 } });
      dispatch({ type: actions.EDIT_LOADING_DATASOURCES_LIST, payload: -1 });
    },
    editDatasource: (fileId: string, dataSource) => {
      dispatch({ type: actions.EDIT_DATASOURCE, payload: { fileId: fileId, dataSource } });
    },
    deleteDataSources: (
      workspaceId: string,
      fileId: Array<string>,
      reportsId: Array<{ name: string; id: string }>,
      processState: () => void,
    ) => {
      DataSourceAPI.deleteOneOrManyDatasource(workspaceId, fileId)
        .then((resp) => {
          if (resp) {
            dispatch({ type: actions.DELETE_DATASOURCE, payload: fileId });
            dispatch({ type: actions.DELETE_TABS, payload: reportsId.map((report) => report.id) });
            dispatch({
              type: actions.SET_DATA_SOURCES_PREVIEW,
              payload: { data: [], columns: [], totalLength: 0 },
            });
            ToastHelper.success(`Data source${fileId.length > 1 ? 's' : ''} deleted !`);
          } else {
            ToastHelper.error(`Could not delete data source`);
            processState();
          }
        })
        .catch((e) => {
          ToastHelper.error(`Could not delete data source`, e);
          processState();
        });
    },
    getLazyDataSourcesPreview: (previewData: NS_API.IDataPreview, add: boolean) => {
      if (!add) {
        dispatch({ type: actions.SET_DATA_SOURCES_PREVIEW, payload: previewData });
      } else {
        dispatch({ type: actions.ADD_DATA_SOURCES_PREVIEW, payload: previewData });
      }
    },
    deleteDataSourcesPreview: () => {
      dispatch({ type: actions.DELETE_DATA_SOURCES_PREVIEW });
    },
    editLoadingDataSourcesList: (amount: number) => {
      dispatch({ type: actions.EDIT_LOADING_DATASOURCES_LIST, payload: amount });
    },
    setGeneratedDataSources: (dataSources: NS_Workspace.IDataSourcesFile[]) => {
      dispatch({ type: actions.SET_GENERATED_DATASOURCES, payload: dataSources });
    },
    addGeneratedDataSource: (dataSource: NS_Workspace.IDataSourcesFile) => {
      dispatch({ type: actions.ADD_GENERATED_DATASOURCE, payload: dataSource });
    },
    deleteGeneratedDataSource: (dataSourceId: string) => {
      dispatch({ type: actions.DELETE_GENERATED_DATASOURCE, payload: dataSourceId });
    },

    union: (
      workspaceId: string,
      unionMetadata: Array<IUnionLine>,
      newFileName: string | undefined,
      final: () => void,
      _setDatasourceToState: (newDataSource: NS_Workspace.IDataSourcesFile) => void,
      backToUnion: string,
      reportsId?: Array<{ name: string; id: string }>,
    ) => {
      DataSourceAPI.unionDatasource(unionMetadata, newFileName, workspaceId, backToUnion)
        .then((res) => {
          if (res) {
            const { data } = res;
            //add file
            data.unionDatasource.structurationStatus = states.PROCESSING;
            dispatch({ type: actions.ADD_DATASOURCE, payload: data.unionDatasource });
            const payload = {
              event_label: 'create_union',
              workspaceId: workspaceId,
            };
            if (unionMetadata.length >= 1) {
              payload['unionDatasource1'] = atob(unionMetadata[0].datasourceId);
            }
            if (unionMetadata.length >= 2) {
              payload['unionDatasource2'] = atob(unionMetadata[1].datasourceId);
            }
            if (unionMetadata.length >= 3) {
              payload['unionDatasource3'] = atob(unionMetadata[2].datasourceId);
            }
            if (unionMetadata.length >= 4) {
              payload['unionDatasource4'] = atob(unionMetadata[3].datasourceId);
            }
            if (unionMetadata.length >= 5) {
              payload['unionDatasource5'] = atob(unionMetadata[4].datasourceId);
            }

            window.gtag('event', 'create_union', payload);
            if (backToUnion) {
              dispatch({ type: actions.DELETE_GENERATED_DATASOURCE, payload: backToUnion });
              dispatch({ type: actions.DELETE_DATASOURCE, payload: backToUnion });
              dispatch({
                type: actions.DELETE_TABS,
                payload: reportsId ? reportsId.map((report) => report.id) : [],
              });
            }
            return data;
          } else {
            ToastHelper.error(`Failed to unit files`);
            return false;
          }
        })
        .finally(() => final());
    },

    // Reports
    getTabs: (workspaceId: string) => {
      WorksheetAPI.getWorksheets(workspaceId)
        .then((res) => {
          const { data } = res;
          dispatch({ type: actions.GET_TABS, payload: data });
        })
        .catch((e) => {
          ToastHelper.error(`Error while getting Worksheets`, e);
        });
    },
    saveTabs: (reportId: string, datasourceId: string) => {
      dispatch({ type: actions.SET_TABS_SAVE, payload: { reportId, datasourceId } });
    },
    newTab: (
      id: string,
      name: string,
      reportId?: string,
      datasourceId?: string,
      templateId?: string,
      reportSaved = false,
    ) => {
      dispatch({
        type: actions.ADD_TAB,
        payload: !reportSaved
          ? { id, name, reportId, datasourceId, templateId, type: REPORT_TABS_TYPE.REPORT }
          : { id, name, reportId, datasourceId, templateId, type: REPORT_TABS_TYPE.REPORT, reportSaved },
      });
    },
    renameTab: (id: string, name: string, reportId?: string, datasourceId?: string, templateId?: string) => {
      dispatch({ type: actions.RENAME_TAB, payload: { id, name, reportId, datasourceId, templateId } });
    },
    editTypeTab: (id: string, type: string, name: string) => {
      dispatch({ type: actions.TYPE_TAB, payload: { id, type, name } });
    },
    deleteTab: (id: Array<string>) => {
      dispatch({ type: actions.DELETE_TABS, payload: id });
    },
    resetTabDeleteState: () => {
      dispatch({ type: actions.RESET_DELETE_TABS });
    },
    addLoadingTab: (id: string) => {
      dispatch({ type: actions.ADD_LOADING_TAB, payload: id });
    },
    removeLoadingTab: (id: string) => {
      dispatch({ type: actions.REMOVE_LOADING_TAB, payload: id });
    },
    updateTabOrder: (workspaceId, updatedWorkspaceData) => {
      // WorkspaceAPI.updateWorkspace(workspaceId, { updatedWorkspace: updatedWorkspaceData })
      WorksheetAPI.reOrderWorksheets(workspaceId as string, updatedWorkspaceData.reportsOrder as string[])
        .then((res) => {
          if (res) {
            const { data } = res;
            dispatch({ type: actions.UPDATE_TAB_ORDER, payload: data });
          } else {
            ToastHelper.error(`Failed to get updated workspace data`);
          }
        })
        .catch((e) => {
          ToastHelper.error(`Failed to get updated workspace data`, e);
        });
    },
    // Export
    addExportedFileContext: (exportedFile: NS_Workspace.IExportedFile) => {
      dispatch({ type: actions.ADD_EXPORTED_FILE, payload: exportedFile });
    },
    deleteExportedFileContext: (fileName: string) => {
      dispatch({ type: actions.DELETE_EXPORTED_FILE, payload: fileName });
    },
    addContainerToCheck: (containerName: string) => {
      dispatch({ type: actions.ADD_CONTAINER_TO_CHECK, payload: containerName });
    },
    removeContainerToCheck: (containerName: string) => {
      dispatch({ type: actions.REMOVE_CONTAINER_TO_CHECK, payload: containerName });
    },
    // Modal
    getLazyModalPreview: (previewData: NS_API.IDataPreview, add: boolean) => {
      if (!add) {
        dispatch({ type: actions.SET_MODAL_PREVIEW, payload: previewData });
      } else {
        dispatch({ type: actions.ADD_MODAL_PREVIEW, payload: previewData });
      }
    },
  };

  return (
    <WorkspaceContext.Provider value={value}>
      <WorkspaceContainer />
    </WorkspaceContext.Provider>
  );
};

export default WorkspaceProvider;
