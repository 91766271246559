import { dataDestructuring } from '../tree/front.tree.util';

import { MappingCommandsParams } from '../../constant/mapping.consts';

export const onRenameHelper = (
  newName: string,
  id: string,
  mappingTree,
  setNewOperation,
  currentMetadata,
  currentCommands,
) => {
  const newArray = dataDestructuring(mappingTree);
  const loopArray = (child: NS_API.IGraph) => {
    child.forEach((item, index) => {
      if (item.data.content.rowContentId === id) {
        child[index].data.content.rowContentName = newName;
      } else if (item.data.content.rowContentType !== 'N' && item.children) {
        loopArray(item.children);
      }
    });
  };
  loopArray(newArray);
  const newCommands = addCommandOnRename(newName, id, mappingTree, currentCommands);
  setNewOperation(newArray, currentMetadata, newCommands);
};

// Function to add the command rename, use separetly to not create a command at each onChange
const addCommandOnRename = (newName: string, id: string, mappingTree, currentCommands) => {
  const newArray = dataDestructuring(mappingTree);
  const newCommands = dataDestructuring(currentCommands);
  const loopArray = (child: NS_API.IGraph) => {
    child.forEach((item) => {
      if (item.data.content.rowContentId === id) {
        newCommands[
          Object.keys(newCommands).length === 0
            ? 0
            : parseInt(Object.keys(newCommands)[Object.keys(newCommands).length - 1]) + 1
        ] = {
          type: MappingCommandsParams.updateMetadata,
          parameters: {
            selectedNodeId: id,
            metadata: {
              name: newName,
              grouped: false,
            },
          },
        };
      } else if (item.data.content.rowContentType !== 'N' && item.children) {
        loopArray(item.children);
      }
    });
  };

  loopArray(newArray);
  return newCommands;
};

export const onLockedHelper = (id: string, mappingTree, currentCommands, setNewOperation, currentMetadata) => {
  const newArray = dataDestructuring(mappingTree);
  const newCommands = dataDestructuring(currentCommands);
  const loopArray = (child: NS_API.IGraph) => {
    child.forEach((item, index) => {
      if (item.data.content.rowContentId === id) {
        child[index].data.content.rowParams.locked = !child[index].data.content.rowParams.locked;
        child[index].data.content.rowParams.opened = child[index].data.content.rowParams.locked ? true : false;

        newCommands[
          Object.keys(newCommands).length === 0
            ? 0
            : parseInt(Object.keys(newCommands)[Object.keys(newCommands).length - 1]) + 1
        ] = {
          type: 'update-metadata',
          parameters: {
            selectedNodeId: id,
            metadata: {
              rowParams: {
                locked: child[index].data.content.rowParams.locked,
                opened: child[index].data.content.rowParams.locked,
              },
            },
          },
        };
      } else if (item.data.content.rowContentType !== 'N' && item.children) {
        loopArray(item.children);
      }
    });
  };
  loopArray(newArray);
  setNewOperation(newArray, currentMetadata, newCommands);
};

export const onSaveParametersHelper = (
  id: string,
  mappingTree,
  currentCommands,
  setNewOperation,
  currentMetadata,
  nodeProperties: NS_API.INodeSettingProperties,
) => {
  const newArray = dataDestructuring(mappingTree);
  const newCommands = dataDestructuring(currentCommands);
  const { locked, templateCoefficient, measure } = nodeProperties;
  const loopArray = (child: NS_API.IGraph) => {
    child.forEach((item, index) => {
      if (item.data.content.rowContentId === id) {
        // Change node properties in place
        if (locked !== undefined) {
          child[index].data.content.rowParams.locked = locked;
          child[index].data.content.rowParams.opened = locked;
        }
        if (measure !== undefined) {
          child[index].data.content.measure = measure;
        }
        if (templateCoefficient !== undefined) {
          child[index].data.content.templateCoefficient = templateCoefficient;
        }

        // add new command
        newCommands[
          Object.keys(newCommands).length === 0
            ? 0
            : parseInt(Object.keys(newCommands)[Object.keys(newCommands).length - 1]) + 1
        ] = {
          type: 'update-metadata',
          parameters: {
            selectedNodeId: id,
            metadata: {
              measure: measure,
              templateCoefficient: templateCoefficient,
              rowParams: {
                locked: locked,
                opened: locked,
              },
            },
          },
        };
      } else if (item.data.content.rowContentType !== 'N' && item.children) {
        loopArray(item.children);
      }
    });
  };
  loopArray(newArray);
  setNewOperation(newArray, currentMetadata, newCommands);
};
