// Style
import '../style.scss';
import './style.scss';

import React, { FC } from 'react';

import { refactName } from '../../../../../utils/common';

interface ICollectionItem {
  collections?: NS_REPORT.ITypeCollections;
  selectedItemName: string;
  disabled?: boolean;
  openDispositionDropdown: boolean;
  isFetching: boolean;
  setSelectedItem: (id: string) => void;
  closeDropdown: () => void;
}
const CollectionItem: FC<ICollectionItem> = (props) => {
  const { collections, selectedItemName, setSelectedItem, closeDropdown, disabled, openDispositionDropdown } = props;
  const scorfs = collections?.scorf || [];
  const users = collections?.customers || [];

  return (
    <div
      className={`select-list-body collection-select-list-body ${!openDispositionDropdown && 'select-list-hide-body'}`}>
      <div className="collection-first-item">
        <div className="intermediateTitle">Standard</div>
        {scorfs.map((el, index) => {
          const key = `${index}-${el.name}`;
          return (
            <React.Fragment key={key}>
              <div
                className={`listElement ${el.id === selectedItemName && 'active'} element-collection-hover`}
                key={el.id}
                title={`${el.name}`}
                onClick={() => {
                  if (!disabled && !el?.isDisable) {
                    setSelectedItem(el.id);
                    closeDropdown();
                  }
                }}>
                <div className={`toolBarText ${el?.isDisable && 'div--disabled'}`}>🇫🇷 {` ` + refactName(el.name)}</div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <div className="collection-second-item">
        <div className="intermediateTitle title-center">{`User's`}</div>
        <div className={`collection-user-scroll `}>
          {users.map((el, index) => {
            const key = `${index}-${el.name}`;
            return (
              <React.Fragment key={key}>
                <div
                  className={`listElement ${el.id === selectedItemName && 'active'} element-collection-hover`}
                  key={el.id}
                  title={`${el.name}`}
                  onClick={() => {
                    if (!disabled && !el?.isDisable) {
                      setSelectedItem(el.id);
                      closeDropdown();
                    }
                  }}>
                  <div className={`toolBarText ${el?.isDisable && 'div--disabled'} collection-name-full-width`}>
                    {el.name}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CollectionItem;
