export const setSelectedColor = (questionPriority: string): string => {
  switch (questionPriority) {
    case 'high':
      return 'red';
    case 'open':
      return 'red';
    case 'medium':
      return 'orange';
    case 'ongoing':
      return 'orange';
    case 'closed':
      return 'blue';
    case 'low':
      return 'blue';
    default:
      // Low
      return 'blue';
  }
};
