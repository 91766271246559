import * as XLSX from '@sheet/chart';

import { currencyFormat } from '../helpers/helpers';

export type TChartData = NS_API.IReportsAssemblyResponse &
  NS_API.IReportAssemblyIncludedReportData & { isUnion: boolean };

export interface IFiscalYearIndexes {
  firstIndex: number;
  lastIndex: number;
}
interface ISheetColor {
  primaryFillColor: string;
  primaryTextColor: string;
}

const COVER_COLORS = {
  FILL_PRIMARY: '#7eceb0',
  TEXT_PRIMARY: '#ffffff',
};

export const CHART_FONT = "'Poppins', sans-serif ";
export const ROW_START = 7;

export const CHART_DATA_FONT = {
  valueFont: CHART_FONT,
  labelFont: CHART_FONT,
  legendItemFont: CHART_FONT,
  baseFont: CHART_FONT,
  caption: '',
  subCaption: '',
  theme: 'fusion',
  xaxislinecolor: '#ACACAC',
  yaxislinecolor: '#ACACAC',
  divlineThickness: '0',
  divLineDashed: '0',
  divLineDashLen: '0',
  exportEnabled: '1',
  exportShowMenuItem: '0',
};

export const getFiscalYears = (fiscalYearsInfo: Object, isUnion?: boolean, isKpi?: boolean) => {
  if (fiscalYearsInfo) {
    const keys = Object.keys(fiscalYearsInfo).filter((year: any) => !isNaN(year));
    if (isKpi) {
      if (isUnion) {
        return keys[keys.length - 1];
      }
      return keys[0];
    }
    let result = '';
    for (let k = 0; k < keys.length; k++) {
      const year: any = keys[k];
      result = `${result} ${k !== 0 ? '-' : ''} ${year}`;
    }
    return result;
  }
  return '';
};

export const createWorksheet = (data: any, isJson: boolean): XLSX.WorkSheet => {
  const ws3: any = isJson ? XLSX.utils.json_to_sheet(data) : XLSX.utils.aoa_to_sheet(data);
  const decimalsFormat = `0${Array.from({ length: 0 }, (_, index) => (index === 0 ? '.0' : '0')).join('')}`;
  const numberFormat = `# ##${decimalsFormat};(# ##${decimalsFormat})`;

  if (!ws3['!cols']) ws3['!cols'] = [];
  // Set cell style
  const range = XLSX.utils.decode_range(ws3['!ref']);
  for (let i = range.s.c; i <= range.e.c; i++) {
    // Set auto-width
    ws3['!cols'][i] = { auto: 1 };
    for (let j = range.s.r + 1; j <= range.e.r; ++j) {
      const ref = XLSX.utils.encode_cell({ r: j, c: i });
      if (j && j === 4) {
        // title rows
        XLSX.utils.sheet_set_range_style(ws3, ref, {
          fgColor: { rgb: COVER_COLORS.FILL_PRIMARY },
          sz: 14,
        });
      }
      if (!ws3[ref]) continue;
      if (ws3[ref].t != 'n') continue;
      ws3[ref].z = numberFormat;
    }
  }
  // style cover ws
  const encodedWsTypeCell = XLSX.utils.encode_cell({ r: 4, c: 0 });
  // set title styles
  XLSX.utils.sheet_set_range_style(ws3, encodedWsTypeCell, {
    color: { rgb: COVER_COLORS.TEXT_PRIMARY },
  });
  // row height
  if (!ws3['!rows']) {
    ws3['!rows'] = [];
  }
  ws3['!rows'][4] = { hpt: 20 };
  const encodedWorkspaceTypeCell = XLSX.utils.encode_cell({ r: 0, c: 0 });
  // set title styles
  XLSX.utils.sheet_set_range_style(ws3, encodedWorkspaceTypeCell, {
    sz: 14,
  });
  ws3['!rows'][0] = { hpt: 20 };

  return ws3;
};

export const createWorkbook = (): XLSX.WorkBook => {
  const wb = XLSX.utils.book_new();
  const finalWorkBookProps = {
    Application: 'Scorf',
    Author: 'Scorf',
    Keywords: 'Scorf,Datapack',
    Title: 'Scorf Datapack',
    LastAuthor: 'Scorf',
    Manager: 'Scorf',
  } as XLSX.FullProperties;

  wb['Props'] = finalWorkBookProps;
  const reportCover = generateCoverWorksheet('Charts', {
    primaryFillColor: COVER_COLORS.FILL_PRIMARY,
    primaryTextColor: COVER_COLORS.TEXT_PRIMARY,
  });
  XLSX.utils.book_append_sheet(wb, reportCover, 'Charts >>');
  return wb;
};

export const generateCoverWorksheet = (coverTitle: string, wsColors: ISheetColor): XLSX.WorkSheet => {
  //aoa
  const aoa: any = [];
  for (let r = 0; r < 100; r++) {
    const row: Array<string> = [];
    for (let c = 0; c < 48; c++) {
      row.push('');
    }
    aoa.push(row);
  }

  // apply text
  aoa[6][1] = coverTitle;

  const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(aoa);
  // style cover ws
  const encodedWsTypeCell = XLSX.utils.encode_cell({ r: 6, c: 1 });

  // set title styles
  XLSX.utils.sheet_set_range_style(ws, encodedWsTypeCell, {
    sz: 38,
    color: { rgb: wsColors.primaryTextColor },
    bold: true,
  });

  // col widths
  if (!ws['!cols']) {
    ws['!cols'] = [];
  }
  ws['!cols'][1] = { width: 40 };

  // row height
  if (!ws['!rows']) {
    ws['!rows'] = [];
  }
  ws['!rows'][6] = { hpt: 40 };

  //set bg color
  if (ws?.['!ref']) {
    const range = XLSX.utils.decode_range(ws['!ref']);
    XLSX.utils.sheet_set_range_style(ws, range, {
      fgColor: { rgb: wsColors.primaryFillColor },
    });
  }

  // tab color
  ws['!tabcolor'] = { rgb: wsColors.primaryFillColor };

  return ws;
};

export const formattedDisplayValue = (displayValue: any, displayUnit: 1000 | 1000000 = 1000000): string => {
  const absoluteValue = Math.abs(displayValue);
  const valueLength = Math.ceil(Math.log10(absoluteValue + 1));
  const isNegative = displayValue && `${displayValue}`.includes('-');
  let _displayUnit = displayUnit;
  const isK = valueLength <= 6;
  _displayUnit = isK ? 1000 : 1000000;
  displayValue = currencyFormat('0,0.00', absoluteValue, _displayUnit).toFixed(0);
  if (isNegative) {
    displayValue = `(${displayValue})${isK ? 'k' : 'm'}€`;
  } else {
    displayValue = `${displayValue}${isK ? 'k' : 'm'}€`;
  }
  return displayValue;
};

export const generateArray = (workspaceName: string, title: string) => {
  return [[workspaceName], ...new Array(3).fill([]), [title], ...new Array(2).fill([])];
};

export const getSheetName = (title: string) => {
  let name = 'CHART-';
  if (title) {
    const splitTitle = title.split(' ');
    for (let k = 0; k < splitTitle.length; k++) {
      if (splitTitle?.[k] && splitTitle[k]?.[0]) {
        name = name + splitTitle[k][0].toUpperCase();
      }
    }
    return name;
  }
  return 'Data Chart';
};
