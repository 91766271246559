import './style.scss';

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { toast, ToastOptions } from 'react-toastify';

import { useMsal } from '@azure/msal-react';

import HomeSVG from '../../assets/img/home.svg';
import warningIcon from '../../assets/img/info-circle-black.svg';
import warningIconDark from '../../assets/img/info-circle-white.svg';
import FilledWarningSVG from '../../assets/img/info-circle-yellow-filled.svg';
import logoImg from '../../assets/img/logo-scorf-img.svg';
import logoText from '../../assets/img/logo-scorf-text.svg';
import logoutIcon from '../../assets/img/logout-black.svg';
import logoutIconDark from '../../assets/img/logout-white.svg';
import Moon from '../../assets/img/moon.svg';
import SettingSVG from '../../assets/img/setting.svg';
import Sun from '../../assets/img/sun-white.svg';
import { doLogout } from '../../authConfig';
import { WorkspaceContext } from '../../context/WorkspaceContext';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import { colorsAvailable, setAppNews, setUserColor } from '../../redux/workspace';
import { getUserInfo } from '../../shared/api/user';
import { RoutesEnum } from '../../shared/constant/route.consts';
import { renderContent } from '../../shared/helpers/helpers';
import CustomModal from '../CustomModal';
import ContextMenu from '../GenericContextMenu';
import ToggleList from '../ToggleList';

const getSelectedColorMode = () => {
  const data = localStorage.getItem('DarkMode');
  if (data === null) {
    return false;
  }
  return JSON.parse(data);
};

const randomColors = [colorsAvailable.BLUE, colorsAvailable.RED, colorsAvailable.YELLOW, colorsAvailable.GREY];

interface IAppHeader {
  logoContainerRef?: React.RefObject<any>;
  logoRef?: React.RefObject<HTMLImageElement>;
  onMouseEnterLogo?: (e) => void;
  onClickLogo?: (e) => void;
  logoUnderLine?: boolean;
}

const AppHeader: React.FC<IAppHeader> = ({
  logoContainerRef,
  logoRef,
  onMouseEnterLogo,
  onClickLogo,
  logoUnderLine,
}) => {
  const { reportSaving } = useContext(WorkspaceContext);
  const { instance } = useMsal();
  const state = useAppSelector((state) => state.workspace);
  const { color } = state;
  const [newsType, setNewsType] = useState<string>('WARNING');
  const [newsMessage, setNewsMessage] = useState<string>('');
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const [isDark, setIsDark] = useState<boolean>(getSelectedColorMode());
  const [userInfo, setUserInfo] = useState<any>();
  const [isAboutModalOpen, setAboutModalOpen] = useState<boolean>(false);
  const [workspaceMode, setWorkspaceMode] = useState<boolean>(false);

  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (newsMessage) {
      const toastOptions: ToastOptions = {
        position: 'top-center',
        autoClose: 4000,
        hideProgressBar: true,
      };
      const cutomToastComponent = (
        <span className="newsToast">
          <strong>News : </strong>
          {newsMessage}
        </span>
      );
      switch (newsType) {
        case 'INFO':
          toast.info(cutomToastComponent, toastOptions);
          break;
        case 'ERROR':
          toast.error(cutomToastComponent, toastOptions);
          break;
        case 'WARNING':
        default:
          toast.warn(cutomToastComponent, toastOptions);
          break;
      }
    }
  }, [newsMessage, newsType]);

  useEffect(() => {
    if (isDark) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('DarkMode', JSON.stringify(true));
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('DarkMode', JSON.stringify(false));
    }
  }, [isDark]);

  useEffect(() => {
    getUserInfo()
      .then((res) => {
        if (res) {
          const { data } = res;
          setUserInfo(data);
          setNewsType(data.type);
          setNewsMessage(data.message);
          dispatch(setAppNews(data.news));
          window.gtag('set', {
            user_id: data.email.split('@')[0],
          });
          window.gtag('set', 'user_properties', {
            customerId: data.email.split('@')[0],
          });
          if (data.onMaintenance) {
            navigate('/maintenance');
          }
        }
      })
      .catch((err) => {});
    dispatch(setUserColor(randomColors[Math.floor(Math.random() * randomColors.length)]));
  }, []);

  useEffect(() => {
    const urlParts = location.pathname.split('/');
    const urlWorkspaceId = urlParts[1];
    if (urlWorkspaceId) {
      setWorkspaceMode(true);
    } else {
      setWorkspaceMode(false);
    }
  }, [location.pathname]);

  const renderNewsMessage = useMemo(() => {
    if (newsMessage) {
      let classType;
      switch (newsType) {
        case 'INFO':
          classType = 'blue';
          break;
        case 'ERROR':
          classType = 'red';
          break;
        case 'WARNING':
        default:
          classType = 'yellow';
          break;
      }
      return (
        <div className={`newsContainer newsContainer--${classType}`}>
          <ReactSVG src={FilledWarningSVG} className={`svg-wrapper newsSVG`} />
          <span>{newsMessage}</span>
        </div>
      );
    } else {
      return null;
    }
  }, [newsType, newsMessage]);

  const handleAboutModalOpen = useCallback(() => {
    setAboutModalOpen(false);
  }, []);

  const handleHome = useCallback(
    (e) => {
      if (reportSaving) e.preventDefault();
    },
    [reportSaving],
  );

  const handleSetting = useCallback(
    (event) => {
      if (!reportSaving) {
        setIsContextMenuOpen(!isContextMenuOpen);
        event.stopPropagation();
      }
    },
    [reportSaving, isContextMenuOpen],
  );
  return (
    <>
      <header className="appBar">
        {renderContent(
          workspaceMode,
          <>
            <div
              className={`logoHeader logoHeader--small ${renderContent(logoUnderLine ?? false, 'selected', '')}`}
              ref={logoContainerRef}
              onMouseEnter={onMouseEnterLogo}>
              <img
                src={logoImg}
                alt="logo"
                className="logoImgHeader logoHeader--big"
                onClick={onClickLogo}
                ref={logoRef}
              />
            </div>
            <ToggleList />
          </>,
          <NavLink to={RoutesEnum.dashboard} className="logoHeader" ref={logoContainerRef}>
            <img src={logoImg} alt="logo" className="logoImgHeader" ref={logoRef} />
            <img src={logoText} alt="logo" className="logoText" />
          </NavLink>,
        )}
        <div className="rightHeader">
          {userInfo?.initials && (
            <div className={`initialUserAvatar initialUserAvatar--${color}`}>{userInfo.initials}</div>
          )}
          <NavLink to={RoutesEnum.dashboard} className="homeNaviContainer" onClick={handleHome}>
            <ReactSVG src={HomeSVG} className={`svg-wrapper ${reportSaving ? 'homeSVGDisabled' : 'homeSVG'}`} />
          </NavLink>
          <ReactSVG
            src={SettingSVG}
            className={`svg-wrapper ${reportSaving ? 'settingSVGDisable' : 'settingSVG'}`}
            onClick={handleSetting}
          />
          <ContextMenu
            isOpen={isContextMenuOpen}
            setIsOpen={setIsContextMenuOpen}
            fields={[
              {
                name: 'Switch mode',
                image: isDark ? Sun : Moon,
                imageAlt: 'dark',
                clickAction: () => {
                  setIsDark(!isDark);
                },
              },
              {
                name: 'Log out',
                image: isDark ? logoutIconDark : logoutIcon,
                imageAlt: 'logout',
                clickAction: async () => {
                  await doLogout(instance);
                },
              },
              {
                name: 'About',
                image: isDark ? warningIconDark : warningIcon,
                imageAlt: 'logout',
                clickAction: () => {
                  setAboutModalOpen(true);
                },
              },
            ]}
            position={{ top: '50px', right: '15px' }}
          />
        </div>
      </header>
      {isAboutModalOpen && (
        <CustomModal
          onClose={handleAboutModalOpen}
          onCancel={handleAboutModalOpen}
          onConfirm={handleAboutModalOpen}
          title="About">
          <p>Current version : {`${process.env.REACT_APP_VERSION} (${process.env.REACT_APP_RELEASE_HASH})`}</p>
          <label>News : </label>
          {renderNewsMessage}
        </CustomModal>
      )}
    </>
  );
};

export default AppHeader;
