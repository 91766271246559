import { ICategory, IDataset } from '../';
import { ChartType } from '../../../constant/datasource.consts';
import { CHART_DATA_FONT, TChartData } from '../../utils';

export const IN_OUT_COLORS = [
  '#40a781',
  '#bd3629',
  '#e27973',
  '#ffe5b3',
  '#8dbff2',
  '#bad9f7',
  '#235c47',
  '#7eceb0',
  '#e1f4ed',
  '#fcf2f1',
];

export const formalizeChartFlowInFlowOut = (params: TChartData) => {
  let isOpening = !!params?.reportContentSetup?.otherParams?.displayOpening;
  let category: Array<ICategory> = [];
  let dataset: Array<IDataset> = [
    {
      dataset: [],
    },
    { dataset: [] },
  ];
  enum CheckFlow {
    IN = '_IN',
    OUT = '_OUT',
  }
  const EXCLUDE_ENCAISSEMENT_LEGEND = '- Encaissements';
  let reference = '';
  if (params?.fiscalYearsInfo) {
    reference = Object.keys(params.fiscalYearsInfo)[0];
  }

  const beginData = isOpening ? 0 : 1;
  // headers
  if (params?.conceptValues?.header) {
    const { header } = params.conceptValues;
    for (let k = beginData; k < header.length; k++) {
      const label = params.conceptValues.header[k];
      category = [...category, { label }];
    }
  }
  if (params?.calculatedValues?.valuesLegend) {
    const { values, valuesLegend } = params.calculatedValues;
    let keys = Object.keys(valuesLegend);
    let colorIterate = 0;
    for (let k = 0; k < keys.length; k++) {
      const key = keys[k];
      if (k !== 0 && k % 2 === 0) {
        colorIterate = colorIterate + 1;
      }
      const color = IN_OUT_COLORS[colorIterate];
      if (values?.flowInFlowOut) {
        let seriesname: string = valuesLegend[key];
        if (seriesname.includes(EXCLUDE_ENCAISSEMENT_LEGEND)) {
          seriesname = seriesname.replace(EXCLUDE_ENCAISSEMENT_LEGEND, '');
        }
        let data: Array<any> = [];
        for (const [_key, value] of Object.entries(values.flowInFlowOut)) {
          const _v = value as Array<string>;
          if (_key === key) {
            if (_v?.length) {
              for (let j = beginData; j < _v.length; j++) {
                if (_v[j] != '0') {
                  data = [
                    ...data,
                    {
                      value: _v[j],
                    },
                  ];
                } else {
                  data = [...data, null];
                }
              }
            }
          }
        }
        if (key.includes(CheckFlow.IN)) {
          dataset[0].dataset = [...dataset[0].dataset, { seriesname, data, color }];
        } else {
          dataset[1].dataset = [...dataset[1].dataset, { seriesname, data, color, includeInLegend: 0 }];
        }
      }
    }
  }
  return {
    reference,
    title: params.reportTitle,
    config: {
      type: ChartType.FLOW_IN_FLOW_OUT,
      dataLoadStartMessage: 'Please wait, chart is loading the data....',
      dataLoadStartMessageFontSize: '14',
      renderAt: 'chart-container',
      width: '100%',
      height: '400',
      dataFormat: 'json',
      dataSource: {
        chart: {
          numberPrefix: '€',
          showxaxisline: '1',
          showyaxisline: '1',
          exportFileName: params.reportTitle,
          ...CHART_DATA_FONT,
        },
        categories: [
          {
            category,
          },
        ],
        dataset,
      },
    },
  };
};
