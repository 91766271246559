// Style
import './style.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';

import accountImg from '../../../../assets/img/accounts.png';
import RightArrowSVG from '../../../../assets/img/chevron-right.svg';

interface ICoverageClassesProps {
  setClassSelected: (val: number) => void;
  setTypeOfVisualisation: (val: string) => void;
  classAccount: any;
  accountIds: string[];
  setAccountIds: (val: string[]) => void;
  setAccountNames: (val: string[]) => void;
  templateType: string;
}

const listVisualisation = ['All', 'Excluded from report', 'Included in report'];

/**
 * Container used to display a report
 *
 * @param param NS_Tabs.ITab props
 */

const CoverageClasses: React.FC<ICoverageClassesProps> = ({
  setClassSelected,
  setTypeOfVisualisation,
  classAccount,
  accountIds,
  setAccountIds,
  setAccountNames,
  templateType,
}) => {
  const [visualisationSelected, setVisualisationSelected] = useState<number>(0);
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);

  const handleClick = (type: string, key: number) => {
    const newAccountIdsArray: string[] = [];
    const newAccountNamesArray: string[] = [];
    if (classAccount?.[key]) {
      classAccount[key].accountList.forEach((account) => {
        if (!accountIds.includes(account.accountId)) {
          newAccountIdsArray.push(account.accountId);
          newAccountNamesArray.push(account.accountName);
        }
      });
    }
    setAccountIds(newAccountIdsArray);
    setAccountNames(newAccountNamesArray);
    setClassSelected(key);
    setTypeOfVisualisation(type);
  };

  useEffect(() => {
    document.addEventListener('keyup', handleEscapePress);
    return () => {
      document.removeEventListener('keyup', handleEscapePress);
    };
  }, []);

  const handleEscapePress = (e) => {
    if (e.key === 'Escape') {
      setIsDropDownOpen(false);
    }
  };

  const renderWarningClass = useCallback(
    (classNumber: number) => {
      switch (templateType) {
        case 'PL':
          if (classNumber === 6 || classNumber === 7) {
            return 'warningClass';
          }
          break;
        case 'BS':
          if (classNumber === 1 || classNumber === 2 || classNumber === 3 || classNumber === 4 || classNumber === 5) {
            return 'warningClass';
          }
          break;
        case 'CF':
          if (
            classNumber === 1 ||
            classNumber === 2 ||
            classNumber === 3 ||
            classNumber === 4 ||
            classNumber === 6 ||
            classNumber === 7
          ) {
            return 'warningClass';
          }
          break;
        case 'CB':
          if (classNumber === 5) {
            return 'warningClass';
          }
          break;
        default:
          break;
      }
      return '';
    },
    [templateType],
  );
  return (
    <div className="coverageClassesContainer">
      <div className="headerCoverage">
        <div>
          <div className="label">Accounts</div>
          <div className={`${isDropDownOpen && 'dropdownContainerOpen'} dropdownContainer`}>
            <div className="dropdown" onClick={() => setIsDropDownOpen(!isDropDownOpen)}>
              {listVisualisation[visualisationSelected]}
              <ReactSVG src={RightArrowSVG} className="svg-wrapper rightArrowSVG" />
            </div>
            {isDropDownOpen && (
              <div className="dropdownOptions">
                {listVisualisation.map((visu, key) => {
                  if (key !== visualisationSelected) {
                    const index = `${visu} - ${key}`;
                    return (
                      <div
                        className="option"
                        key={index}
                        onClick={() => {
                          setVisualisationSelected(key);
                          setIsDropDownOpen(false);
                        }}>
                        {visu}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      {Array(9)
        .fill(0)
        .map((_element, key) => {
          let numberOfAccount = 0;
          const listAccount: string[] = [];
          accountIds.forEach((id) => {
            if (id.startsWith((key + 1).toString()) && !listAccount.includes(id)) {
              numberOfAccount++;
              listAccount.push(id);
            }
          });
          const isDisabled = classAccount && classAccount[key + 1] && numberOfAccount === classAccount[key + 1].count;
          if (
            (visualisationSelected === 0 ||
              (visualisationSelected === 1 && !isDisabled) ||
              (visualisationSelected === 2 && isDisabled)) &&
            classAccount &&
            classAccount[key + 1] &&
            classAccount[key + 1].count > 0
          ) {
            const index = `${key} - ${numberOfAccount}`;
            return (
              <div
                className={`${isDisabled && 'classContainerDisabled'} classContainer ${
                  numberOfAccount !== classAccount[key + 1].count && renderWarningClass(key + 1)
                }`}
                key={index}>
                <p className="title">{`Class ${key + 1}`}</p>
                <div className="numbers">
                  {`${numberOfAccount} / ${classAccount[key + 1] && classAccount[key + 1].count}`}
                </div>
                <div
                  className={`${isDisabled && 'buttonContainerDisabled'} buttonContainer`}
                  onClick={(e) => {
                    !isDisabled && handleClick('Accounts', key + 1);
                    e.stopPropagation();
                  }}>
                  <div className="imgContainer">
                    <img src={accountImg} alt="account" />
                  </div>
                  <div className="label">
                    <p>See accounts</p>
                    <ReactSVG src={RightArrowSVG} className="svg-wrapper rightArrowSVG blue" />
                  </div>
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
    </div>
  );
};

export default CoverageClasses;
