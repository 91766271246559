export function disableCursorStyle(disabled: boolean): string {
  return disabled ? 'div--disabled' : '';
}

export function compareLowerCase(first: string, second: string) {
  const _first = first ? first.toLowerCase() : '';
  const _second = second ? second.toLowerCase() : '';
  return _first === _second;
}

export const checkEmail = (email: string) => {
  if (!email) return false;
  if (/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(email)) {
    return true;
  }
  return false;
};

// FIX ME: REMOVE FROM API
export const refactName = (name: string) => {
  if (name === 'French Tax Form') return 'Tax';
  if (name === 'Standard French Mapping') return 'Scorf';
  return name;
};

export const noAction = () => {
  return;
};

export const sortArrayString = (data: Array<string>) => {
  return data.sort((a, b) => {
    if (a === b) return 0;
    return a > b ? 1 : -1;
  });
};

export const removeRestrictedChars = (unrestrictedString: string): string => {
  return unrestrictedString.replace(new RegExp(/[*|":<>[\]{}`\\';@&$#=?+%/]/, 'g'), '');
};
