import { ChartFamily } from '../../constant/datasource.consts';

export interface IChartKpi {
  title: string;
  unity: string;
  value: string;
  color?: string;
  description?: string;
  fiscalYear?: string;
}

export const KPI_COLORS = [
  '#40a781',
  '#7eceb0',
  '#e27973',
  '#5D63B0',
  '#4899ea',
  '#8dbff2',
  '#7eceb0',
  '#bad9f7',
  '#235c47',
  '#bd3629',
  '#e1f4ed',
  '#fcf2f1',
];

export const FORMAT_TEXT = 'Trésorerie';

export const KPI_UNITS = {
  k: 'k€',
  m: 'm€',
};

export const formalizeKpiCharts = (
  data: Array<NS_API.IKpiReportCharts | undefined>,
  templateKey: string,
): Array<IChartKpi> => {
  let result: Array<IChartKpi> = [];
  if (data?.length) {
    for (const kpi of data) {
      if (kpi?.kpiLegends) {
        const { kpiLegends, kpiUnits, values } = kpi;
        let keys = Object.keys(kpiLegends);
        for (let j = 0; j < keys.length; j++) {
          const key = keys[j];
          let title: string = kpiLegends[key];
          title = title?.includes(FORMAT_TEXT) ? FORMAT_TEXT : title;
          if (MAP_TITLE[title]) {
            title = MAP_TITLE[title];
          }
          const unity = kpiUnits[key];
          let value = values[key][0];
          if (unity === KPI_UNITS.k) {
            value = Math.ceil(value);
          } else if (unity === KPI_UNITS.m) {
            value = parseFloat(value).toFixed(1);
          } else {
            value = parseFloat(value).toFixed(0);
          }
          const isColor = result.find((r) => r.color && r.color === KPI_COLORS[j]);
          const color = isColor ? KPI_COLORS[result.length + 1] : KPI_COLORS[j];
          let fiscalYear = kpi?.fiscalYear ?? '';
          if (templateKey === ChartFamily.BS) {
            fiscalYear = ` closing ${fiscalYear}`;
          }
          if (value < 0) {
            value = `(${Math.abs(value)})`;
          }
          result = [...result, { title, unity, value, color, fiscalYear }];
        }
      }
    }
  }
  return result;
};

export interface KpiTitleMapInterface {
  [key: string]: string;
}

export const MAP_TITLE: KpiTitleMapInterface = {
  "Chiffre d'affaires": 'Revenue',
  'Résultat NEt': 'Net Result',
  'Résultat Net de la période': 'Net Result',
  'Actif immobilisé': 'Fixed Assets',
  BFR: 'Working Capital',
  Trésorerie: 'Cash',
  'Dette financière nette': 'Net Financial Debt',
  'Cash in': 'Monthly average cash in',
  'Cash Out': 'Monthly average cash out',
};
