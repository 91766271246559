// Style
import './style.scss';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';

import RightArrowSVG from '../../../assets/img/chevron-right.svg';
import closeIcon from '../../../assets/img/cross-red.svg';
import ExplanationBox from '../../../elements/ExplanationBox';
import { isArrayEqual } from '../../../shared/helpers/report/reports.helper';
import { isDisabled } from '../../../shared/helpers/string/classnames.helper';
import { DIMENSION_REPORT } from '../../../utils/enum';

interface IDimensions {
  isUnion?: boolean;
  setOpenInput?: React.Dispatch<React.SetStateAction<boolean>>;
  listDimension: NS_Table.IColumnHeader[];
  listDimensionSelected: string[];
  selectedDateRender: number;
  cumulDisplay: boolean;
  disabledGroupBy: boolean;
  disabledDimensions: boolean;
  monthGroupedBy?: string;
  onValidateChange: (
    dimensionsList: Array<string>,
    yearOrMonth: string,
    cumul: boolean,
    monthGroupedBy?: string,
  ) => void;
}

const explanations = [
  {
    title: 'Dimensions',
    description: 'Basically, the columns of the active report. Choose up to two dimensions.',
    posX: '40.5px',
  },
  {
    title: 'Group By',
    description:
      'Group dates by year or by month. If the monthly display has been selected, you must also choose whether the columns are displayed in "cumulative" mode, i.e. they add up to the end of their fiscal period, or in "flow" mode, i.e. only the movements of the period appear. ',
    posX: '288.5px',
  },
];

const dispositions = [
  'Year',
  'Months • Cumul',
  'Months • Flow',
  'Semester • Cumul',
  'Semester • Flow',
  'Quarter • Cumul',
  'Quarter • Flow',
];

/**
 * Container used to display the data part of top menu in a report
 *
 * @param param NS_Tabs.ITab props
 * React.FC<ISearchComponent>
 */

const DimensionsComponent: React.FC<IDimensions> = ({
  isUnion,
  setOpenInput,
  listDimension,
  listDimensionSelected,
  selectedDateRender,
  monthGroupedBy,
  cumulDisplay,
  disabledDimensions,
  disabledGroupBy,
  onValidateChange,
}) => {
  // Refs
  const dropdown = useRef<HTMLDivElement>(null);
  const hoverTimer: { current: NodeJS.Timeout | null } = useRef(null);
  const ref = useRef<HTMLDivElement>(null);

  // States
  const [groupBySelectedIndex, setGroupBySelectedIndex] = useState<number>(-1);
  const [localDimensionsSelected, setLocalDimensionsSelected] = useState<string[]>(listDimensionSelected);

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isDispositionDropdownOpen, setIsDispositionDropdownOpen] = useState<boolean>(false);

  const [explanationSelected, setExplanationSelected] = useState<number>(-1);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleEscapePress);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleEscapePress);
    };
    // eslint-disable-next-line
  }, []);

  const getDefaultGroupByIndex = useMemo(() => {
    // Month
    if (selectedDateRender === 1) {
      if (monthGroupedBy === 'quarter') {
        return cumulDisplay ? 5 : 6;
      } else if (monthGroupedBy === 'semester') {
        return cumulDisplay ? 3 : 4;
      } else {
        return cumulDisplay ? 1 : 2;
      }
    }
    // Year
    return 0;
  }, [selectedDateRender, monthGroupedBy, cumulDisplay]);

  useEffect(() => {
    setGroupBySelectedIndex(getDefaultGroupByIndex);
  }, [getDefaultGroupByIndex]);

  useEffect(() => {
    setLocalDimensionsSelected(listDimensionSelected);
  }, [listDimensionSelected]);

  // Function

  const handleEscapePress = (e) => {
    if (e.key === 'Escape') {
      setIsDropdownOpen(false);
      setIsDispositionDropdownOpen(false);
    }
  };

  const handleClickOutside = (e) => {
    if (dropdown.current && !dropdown.current.contains(e.target)) {
      setIsDropdownOpen(false);
    }
    if (ref.current && !ref.current.contains(e.target)) {
      setIsDispositionDropdownOpen(false);
    }
  };

  const isSelected = (dimensionName) => {
    const tempColumnsDimensions = localDimensionsSelected.map((dimension) => {
      const array = dimension.split('/');
      const indexDimension = array.findIndex((dimensionSolo) => dimensionSolo === dimensionName);
      return indexDimension !== -1 ? true : false;
    });
    return tempColumnsDimensions.includes(true) ? true : false;
  };

  const isDateDimensionSelected = (dimensionName) => {
    const dateDimensions = ['EntryDate', 'FiscalYear'];
    const tempColumnsDimensions = localDimensionsSelected.map((dimension) => {
      const array = dimension.split('/');
      const indexDimension = array.findIndex(
        (dimensionSolo) => dateDimensions.includes(dimensionName) && dateDimensions.includes(dimensionSolo),
      );
      return indexDimension !== -1 ? true : false;
    });
    return tempColumnsDimensions.includes(true) ? true : false;
  };

  const moreThan2Selected = () => {
    const tempDim = localDimensionsSelected.map((dimension) => {
      const array = dimension.split('/');
      return array.length >= 2;
    });
    return tempDim.includes(true);
  };

  const disableIfUnion = (dimensionName) => {
    if (isUnion && !(dimensionName === DIMENSION_REPORT.FISCAL_YEAR || dimensionName === DIMENSION_REPORT.ENTITY)) {
      return false;
    }
    return true;
  };

  const addDimension = (dimensionName: string) => {
    const dateDimensions = ['EntryDate', 'FiscalYear'];
    let dimensionToReplace = '';
    // FIX ME: disable add dimensionName other Fixal Year or Entity for union ds
    if (isUnion && !(dimensionName === DIMENSION_REPORT.FISCAL_YEAR || dimensionName === DIMENSION_REPORT.ENTITY))
      return;
    if (
      dateDimensions.includes(dimensionName) &&
      localDimensionsSelected.findIndex((dim) => dim.includes(dimensionName)) === -1
    ) {
      dimensionToReplace = dateDimensions[dateDimensions.findIndex((dim) => dim !== dimensionName)];
    }
    if (localDimensionsSelected.length === 0 || localDimensionsSelected[0] === '') {
      setLocalDimensionsSelected([dimensionName]);
      return;
    }
    const location = localDimensionsSelected.findIndex(
      (dim) =>
        dim.includes(dimensionName) || (dateDimensions.includes(dimensionName) && dim.includes(dimensionToReplace)),
    );
    if (location !== -1) {
      const oneDimSelected = localDimensionsSelected[0].split('/').length === 1;
      const newSelectedDim = [...localDimensionsSelected];
      if (oneDimSelected && dimensionToReplace) {
        newSelectedDim[location] = newSelectedDim[location].replace(dimensionToReplace, dimensionName);
      } else if (dimensionToReplace) {
        newSelectedDim[location] = newSelectedDim[location].replace(dimensionToReplace + '/', dimensionName + '/');
        newSelectedDim[location] = newSelectedDim[location].replace('/' + dimensionToReplace, '/' + dimensionName);
      } else if (oneDimSelected) {
        newSelectedDim.pop();
      } else {
        newSelectedDim[location] = newSelectedDim[location].replace(dimensionName + '/', '');
        newSelectedDim[location] = newSelectedDim[location].replace('/' + dimensionName, '');
      }
      setLocalDimensionsSelected(newSelectedDim);
      return;
    }

    const tempColumnsDimensions = localDimensionsSelected.map((dimension) => {
      const array = dimension.split('/');
      const indexDimension = array.findIndex((dimensionSolo) => dimensionSolo === dimensionName);
      if (indexDimension !== -1) {
        array.splice(indexDimension, 1);
      } else {
        if (array.length >= 2) {
          return dimension;
        }
        array.splice(array.length, 1, dimensionName);
      }
      return array.join('/');
    });
    setLocalDimensionsSelected([...tempColumnsDimensions]);
  };

  const validateNewDimension = () => {
    if (
      !isArrayEqual(listDimensionSelected, localDimensionsSelected) ||
      getDefaultGroupByIndex !== groupBySelectedIndex
    ) {
      const newYearOrMonth = groupBySelectedIndex === 0 ? 'year' : 'month';
      const newCumul = dispositions[groupBySelectedIndex].indexOf('Cumul') === -1 ? false : true;
      const newMonthGroupedBy =
        groupBySelectedIndex < 3 ? undefined : dispositions[groupBySelectedIndex].split(' • ')[0].toLocaleLowerCase();
      onValidateChange(localDimensionsSelected, newYearOrMonth, newCumul, newMonthGroupedBy);
    }
    setOpenInput?.(false);
  };

  const handleGroupBySelection = (index: number) => (e) => {
    e.stopPropagation();
    setIsDispositionDropdownOpen(false);
    setGroupBySelectedIndex(index);
  };

  const handleInputHovered = (index: number) => {
    hoverTimer.current = setTimeout(() => {
      setExplanationSelected(index);
    }, 1000);
  };

  const handleInputNotHovered = () => {
    if (hoverTimer.current) {
      clearTimeout(hoverTimer.current);
      setExplanationSelected(-1);
    }
  };

  const handleClickDimensionsDropDown = (_e) => {
    if (!disabledDimensions) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const handleClickGroupByDropDown = (_e) => {
    if (!disabledGroupBy && (isSelected('EntryDate') || isSelected('FiscalYear'))) {
      setIsDispositionDropdownOpen(!isDispositionDropdownOpen);
    }
  };

  return (
    <div className={`searchLine`} data-cy="dimensions">
      {explanationSelected > -1 && (
        <ExplanationBox
          title={explanations[explanationSelected].title}
          description={explanations[explanationSelected].description}
          posX={explanations[explanationSelected].posX}
        />
      )}
      <img
        src={closeIcon}
        alt="close icon"
        data-cy="close-dimensions"
        onClick={() => {
          setOpenInput?.(false);
        }}
        style={{ marginTop: 18 }}
      />
      <div
        className="dropdownContainer"
        onMouseEnter={(_e) => handleInputHovered(0)}
        onMouseLeave={() => handleInputNotHovered()}
        onClick={() => handleInputNotHovered()}>
        <p className="label">Dimensions</p>
        <div
          ref={dropdown}
          className={`${isDropdownOpen && 'dropdownOpen'} dropdown ${isDisabled(disabledDimensions)}`}
          onClick={handleClickDimensionsDropDown}>
          <p className="dropdownValue">{localDimensionsSelected?.[0].replace('/', ' - ')}</p>
          <ReactSVG src={RightArrowSVG} className={`svg-wrapper rightArrowSVG ${isDropdownOpen && 'rightArrowOpen'}`} />
          {isDropdownOpen && (
            <div className="dropdownOptions">
              {listDimension.map((dimension, key) => {
                if (!dimension.Header.startsWith('_')) {
                  let className = `${isSelected(dimension.Header) && 'dropdownOptionSelected'} ${
                    moreThan2Selected() &&
                    !isDateDimensionSelected(dimension.Header) &&
                    !isSelected(dimension.Header) &&
                    'disabled'
                  } dropdownOption`;
                  // FIX ME: disable other dimension for union datasource
                  const isUnionDS = !disableIfUnion(dimension.Header) && !className.includes('disabled');
                  if (isUnionDS) {
                    className += ` disabled`;
                  }
                  const index = `${key} - ${dimension.Header}`;
                  return (
                    <div
                      key={index}
                      className={className}
                      onClick={(e) => {
                        addDimension(dimension.Header);
                        e.stopPropagation();
                      }}>
                      {dimension.Header}
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          )}
        </div>
      </div>
      <div
        className={`${!isSelected('EntryDate') && !isSelected('FiscalYear') && 'entryDateDataDisabled'} entryDateData`}
        onMouseEnter={(_e) => handleInputHovered(1)}
        onMouseLeave={() => handleInputNotHovered()}
        onClick={() => handleInputNotHovered()}>
        <div className="label">Group By</div>
        <div className="dropdowns" ref={ref}>
          <div className="dropdownContainer dispositionDropdownContainer">
            <div
              className={`${isDispositionDropdownOpen && 'dropdownOpen'} dropdown ${isDisabled(disabledGroupBy)}`}
              onClick={handleClickGroupByDropDown}>
              <p className="dropdownValue">{dispositions[groupBySelectedIndex]}</p>
              <ReactSVG
                src={RightArrowSVG}
                className={`svg-wrapper rightArrowSVG ${isDispositionDropdownOpen && 'rightArrowOpen'}`}
              />
              {isDispositionDropdownOpen && (
                <div className="dropdownOptions">
                  {dispositions.map((disposition, key) => {
                    if (key !== groupBySelectedIndex) {
                      const index = `${key} - ${disposition}`;
                      return (
                        <div key={index} className={`dropdownOption `} onClick={handleGroupBySelection(key)}>
                          {disposition}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="buttonValidateContainer">
        <button
          className={`btn  ${localDimensionsSelected.length ? 'button--green' : 'button--green--disabled'}`}
          onClick={localDimensionsSelected.length ? validateNewDimension : undefined}
          style={{ marginTop: 18 }}>
          Ok
        </button>
      </div>
    </div>
  );
};

export default React.memo(DimensionsComponent);
