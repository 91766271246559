// Style
import './style.scss';

import React from 'react';

import DataPartMenu from './DataPartMenu';
import NumbersPartMenu from './NumbersPartMenu';
import SearchPartMenu from './SearchPartMenu';
import SnapshotPartMenu from './SnapshotPartMenu';

interface IMainMenu {
  selectedDataSource: string;
  templateList?: Object;
  selectedTemplate: string;
  setSelectedTemplate: React.Dispatch<React.SetStateAction<string>>;
  fetchingTemplateList: boolean;
  templateGroups?: { 1: string; 2: string };
  worksheetId: string;
  setSelectedDataSource: React.Dispatch<React.SetStateAction<string>>;
  getTemplateList: (graphId: string | undefined) => void;
  currencyParams: NS_Table.ICurrency;
  setCurrencyParams: React.Dispatch<React.SetStateAction<NS_Table.ICurrency>>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  openInput: boolean;
  setOpenInput: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDataSourceGraph: string;
  setSelectedDataSourceGraph: React.Dispatch<React.SetStateAction<string>>;
  setGraphName: React.Dispatch<React.SetStateAction<string>>;
  tableContainerRef: React.RefObject<HTMLDivElement>;
  scrollPartRef: React.RefObject<HTMLDivElement>;
  reportName: string;
  disabled?: boolean;
}

/**
 * Container used to display the top menu in a report
 *
 * @param param NS_Tabs.ITab props
 * React.FC<IViewMenu>
 */

const MainMenu: React.FC<IMainMenu> = ({
  selectedDataSource,
  templateList,
  selectedTemplate,
  setSelectedTemplate,
  fetchingTemplateList,
  templateGroups,
  worksheetId,
  setSelectedDataSource,
  getTemplateList,
  currencyParams,
  setCurrencyParams,
  setIsEditing,
  openInput,
  setOpenInput,
  selectedDataSourceGraph,
  setSelectedDataSourceGraph,
  setGraphName,
  tableContainerRef,
  scrollPartRef,
  reportName,
  disabled = false,
}) => {
  return (
    <>
      <div className="data itemMenu">
        <DataPartMenu
          selectedDataSource={selectedDataSource}
          templateList={templateList}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          fetchingTemplateList={fetchingTemplateList}
          templateGroups={templateGroups}
          worksheetId={worksheetId}
          setSelectedDataSource={setSelectedDataSource}
          getTemplateList={getTemplateList}
          selectedDataSourceGraph={selectedDataSourceGraph}
          setSelectedDataSourceGraph={setSelectedDataSourceGraph}
          setIsEditing={setIsEditing}
          setGraphName={setGraphName}
          disabled={disabled}
        />
        <div className="title">Data</div>
      </div>
      <div className="numbers itemMenu">
        <NumbersPartMenu
          currencyParams={currencyParams}
          setCurrencyParams={setCurrencyParams}
          setIsEditing={setIsEditing}
          disabled={disabled}
        />
        <div className="title">Numbers</div>
      </div>
      <div className="search itemMenu">
        <SearchPartMenu openInput={openInput} setOpenInput={setOpenInput} disabled={disabled} />
        <div className="title">Search</div>
      </div>
      <div className="snapshot itemMenu">
        <SnapshotPartMenu
          tableContainerRef={tableContainerRef}
          scrollPartRef={scrollPartRef}
          reportName={reportName}
          disabled={disabled}
        />
        <div className="title">Snapshot</div>
      </div>
    </>
  );
};

export default React.memo(MainMenu);
