// Style
import './style.scss';

import React, { useCallback, useContext } from 'react';
import { ReactSVG } from 'react-svg';

import RedCrossSVG from '../../../../assets/img/cross-red.svg';
import { ReportContext } from '../../../../context/ReportContext';
import { MappingCommandsParams } from '../../../../shared/constant/mapping.consts';
import ToastHelper from '../../../../shared/helpers/toast/ToastHelper';
import { dataDestructuring } from '../../../../shared/helpers/tree/front.tree.util';

interface IMapModuleProps {
  accountSelectedForCoverage: Array<NS_REPORT.INameSelectedForCoverageProps>;
  setAccountSelectedForCoverage: (val: Array<NS_REPORT.INameSelectedForCoverageProps>) => void;
  setIsMapOpen: (val: boolean) => void;
  nameOfNodeSelected: NS_REPORT.INameSelectedForCoverageProps;
  setNameOfNodeSelected: (val: NS_REPORT.INameSelectedForCoverageProps) => void;
  mappingTree: NS_API.IGraph;
  graphId: string;
  handleBackToCoverage: () => void;
}

/**
 * Container used to display a report
 *
 * @param param NS_Tabs.ITab props
 */

const MapModule: React.FC<IMapModuleProps> = ({
  accountSelectedForCoverage,
  setAccountSelectedForCoverage,
  setIsMapOpen,
  nameOfNodeSelected,
  setNameOfNodeSelected,
  mappingTree,
  graphId,
  handleBackToCoverage,
}) => {
  const {
    setNewOperation,
    currentCommands,
    currentMetadata,
    currentAccountIdsWithMovedEntries,
    currentListOfAccountIdsAddedFromCoverage,
    currentListOfAccountNamesAddedFromCoverage,
  } = useContext(ReportContext);

  const addAccount = () => {
    const newArray = dataDestructuring(mappingTree);
    const childrenIdsAndNamesOfDestinationNode: {} = {};
    ToastHelper.success('Accounts succesfully added');
    const loopArray = (child: NS_API.IGraph, selected, key) => {
      child.forEach((item) => {
        if (item.data.content.rowContentId === selected.accountId && graphId) {
          if (key === 0) {
            item.children.forEach((itemChild) => {
              childrenIdsAndNamesOfDestinationNode[itemChild.data.content.rowContentId] =
                itemChild.data.content.rowContentName;
            });
          }
          item.children.unshift({
            parent: item.id,
            id: accountSelectedForCoverage[key].accountId,
            data: {
              parent: item.id,
              id: accountSelectedForCoverage[key].accountId,
              content: {
                rowContentName: accountSelectedForCoverage[key].accountName,
                rowDimension: MappingCommandsParams.accountName,
                rowContentId: accountSelectedForCoverage[key].accountId,
                rowContentType: 'N',
                measure: '',
                templateCoefficient: 1,
                rowParams: {
                  opened: false,
                  cssStyle: '',
                },
                value: Array.from({ length: item.data.content.value.length }, () => 0),
              },
            },
            children: [],
          });
          item.data.content.rowParams.opened = true;
        } else if (item.data.content.rowContentType !== 'N' && item.children) {
          loopArray(item.children, selected, key);
        }
      });
    };
    const newAccountIdsAdded = [...currentListOfAccountIdsAddedFromCoverage];
    const newAccountNamesAdded = [...currentListOfAccountNamesAddedFromCoverage];
    accountSelectedForCoverage.forEach((idSelected, key) => {
      loopArray(newArray, nameOfNodeSelected, key);
      newAccountIdsAdded.push(idSelected.accountId);
      newAccountNamesAdded.push(idSelected.accountName);
    });

    const newCommands = dataDestructuring(currentCommands);
    newCommands[
      Object.keys(newCommands).length === 0
        ? 0
        : parseInt(Object.keys(newCommands)[Object.keys(newCommands).length - 1]) + 1
    ] = {
      type: MappingCommandsParams.addAccount,
      parameters: {
        selectedAccounts: accountSelectedForCoverage,
        destinationNodeId: nameOfNodeSelected.accountId,
        childrenIdsAndNamesOfDestinationNode: childrenIdsAndNamesOfDestinationNode,
      },
    };

    setNewOperation(
      newArray,
      currentMetadata,
      newCommands,
      currentAccountIdsWithMovedEntries,
      newAccountIdsAdded,
      newAccountNamesAdded,
    );
    handleBackToCoverage();
  };
  const handleCross = useCallback(() => {
    setIsMapOpen(false);
    setNameOfNodeSelected({ accountId: '', accountName: '' });
  }, []);
  return (
    <div className="mapModuleContainer">
      <div className="header">
        <div className="leftPart">
          <ReactSVG className="svg-wrapper redCrossSVG" src={RedCrossSVG} onClick={handleCross} />
          Add
        </div>
        <div className="rightPart">
          {accountSelectedForCoverage.length} Account{accountSelectedForCoverage.length > 1 && 's'} selected
        </div>
      </div>
      <div className="body">
        <div className="rightPart">
          <div
            className={`${
              (nameOfNodeSelected.accountId === '' || !accountSelectedForCoverage.length) && 'contentEmpty'
            } content`}>
            <p className={'label'}>Add selected accounts to</p>
            <div className="selectedField">
              <p>
                {nameOfNodeSelected.accountId === ''
                  ? 'Select a field on the left table'
                  : nameOfNodeSelected.accountName}
              </p>
            </div>
            <button
              onClick={(e) => {
                if (accountSelectedForCoverage.length && nameOfNodeSelected.accountId !== '') {
                  addAccount();
                  setAccountSelectedForCoverage([]);
                  setIsMapOpen(false);
                }
              }}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapModule;
