import * as XLSX from '@sheet/chart';

import { IInsideDataSet } from '../';
import { createWorkbook, createWorksheet, generateArray, getSheetName, ROW_START } from '../../utils';
import { finalizeDatasetToPercent } from './';

// FIX ME:
const NEXT_ROW_START = ROW_START + 1;
export const createStackedBarPlot = (workspaceName: string, _data: any, title: string) => {
  const isMultiple = !!_data.filter(
    (d) => d.includeInLegend !== undefined && d.includeInLegend !== null && d.includeInLegend === 0,
  )?.length;
  if (isMultiple) {
    return exportMultipleStackedChart(workspaceName, _data, title);
  }
  const wb = createWorkbook();
  const dataFormalised = formaliseData(workspaceName, title, _data);
  let formattedData: any = dataFormalised.data;
  const ws3: any = createWorksheet(formattedData, false);
  const sheetName = getSheetName(title);
  XLSX.utils.book_append_sheet(wb, ws3, sheetName);

  return XLSX.writeFile(wb, `${title}.xlsx`, { cellStyles: true });
};

const formaliseData = (workspaceName, title, dataset: Array<IInsideDataSet>) => {
  let _dataset: Array<any> = [...generateArray(workspaceName, title)];
  _dataset[ROW_START] = [''];
  let totalsOther = 0;
  for (const dat of dataset) {
    if (dat.data?.length && 1 < dat.data.length) {
      totalsOther = dat.data?.[1]?.value ? parseFloat(dat.data[1].value) : 0;
    }
  }
  const formalizedDataset = finalizeDatasetToPercent(dataset, totalsOther);
  for (const dat of formalizedDataset) {
    const insideData = dat.data?.[0]?.value ? +parseFloat(dat.data[0].value).toFixed(0) : 0;
    _dataset[ROW_START] = [..._dataset[ROW_START], insideData];
    if (dat.data?.length && 1 < dat.data.length) {
      _dataset[NEXT_ROW_START] = ['', ...new Array(dataset.length - 1).fill(0), 100];
    }
  }

  return { data: _dataset, dataSize: NEXT_ROW_START, size: _dataset.length, columnSize: dataset.length - 1 };
};

const formaliseMultipleData = (workspaceName, title, dataset: Array<IInsideDataSet>) => {
  let _dataset: Array<any> = [...generateArray(workspaceName, title)];
  let size = 0;
  for (const dat of dataset) {
    if (dat?.data?.length) {
      for (let i = 0; i < dat.data.length; i++) {
        if (dat.data?.[i]) {
          const currentRow = ROW_START + i;
          if (!_dataset[currentRow]) {
            size = size + 1;
            _dataset[currentRow] = [''];
          }
          const { value } = dat.data[i];
          const insideData = value ? +parseFloat(value).toFixed(0) : 0;
          _dataset[currentRow] = [..._dataset[currentRow], insideData];
        }
      }
    }
  }
  let columnSize = _dataset[ROW_START].length;
  return { data: _dataset, dataSize: NEXT_ROW_START, size, columnSize: columnSize - 1 };
};

export const exportMultipleStackedChart = (workspaceName: string, _data: any, title: string) => {
  const wb = createWorkbook();
  const dataFormalised = formaliseMultipleData(workspaceName, title, _data);
  let formattedData: any = dataFormalised.data;
  const ws3: any = createWorksheet(formattedData, false);
  const sheetName = getSheetName(title);
  XLSX.utils.book_append_sheet(wb, ws3, sheetName);
  return XLSX.writeFile(wb, `${title}.xlsx`, { cellStyles: true });
};
