// Style
import './style.scss';

import { FC, useCallback, useEffect, useState } from 'react';

import { updateTemplateCollection } from '../../../../shared/api/templateCollection';
import { renderContent } from '../../../../shared/helpers/helpers';
import ToastHelper from '../../../../shared/helpers/toast/ToastHelper';

interface ICollectionName {
  collectionName: string;
  collectionId: string;
  workspaceId: string;
  updateCollectionName: (name: string, collecitonId: string) => void;
}
const CollectionName: FC<ICollectionName> = ({ collectionName, collectionId, workspaceId, updateCollectionName }) => {
  const [displayingFileName, setDisplayingFileName] = useState<string>(collectionName);
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    setDisplayingFileName(collectionName);
  }, [editMode, collectionName]);

  const handleClickOutside = useCallback(() => {
    setEditMode(false);
  }, []);

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      if (!displayingFileName) return;
      updateTemplateCollection(workspaceId, collectionId, displayingFileName)
        .then(() => {
          updateCollectionName(displayingFileName, collectionId);
          ToastHelper.success('New Template Collection name ' + displayingFileName);
          setEditMode(false);
        })
        .catch(() => {
          ToastHelper.error('Failed to change Template Collection name');
          setDisplayingFileName(collectionName);
          setEditMode(false);
        });
    }
    if (event.key === 'Escape') {
      setEditMode(false);
    }
  };

  const handleNameChange = useCallback((e) => {
    setDisplayingFileName(e.target.value);
  }, []);
  return (
    <>
      {' '}
      {renderContent(
        editMode,
        <input
          className={`tableRow_cell tableRow_name--edit share-data-edit ${renderContent(
            displayingFileName === '',
            'tableRow_name--edit--error',
            '',
          )}`}
          value={displayingFileName}
          autoFocus
          onKeyDown={handleEnter}
          onChange={handleNameChange}
          maxLength={30}
          onBlur={handleClickOutside}
        />,
        <div
          className="tableRow_cell tableRow_name--read share-data-edit"
          onDoubleClick={(_e) => {
            setEditMode(true);
          }}>
          <p title={displayingFileName}>{displayingFileName}</p>
        </div>,
      )}
    </>
  );
};
export default CollectionName;
