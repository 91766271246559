// Style
import './style.scss';

import React, { useCallback, useContext, useState } from 'react';

import help from '../../assets/img/help.svg';
// Context
import { WorkspaceContext } from '../../context/WorkspaceContext';
import CustomModal from '../../elements/CustomModal';
// API
import { submitDatasourceToSupport } from '../../shared/api/support';
import ToastHelper from '../../shared/helpers/toast/ToastHelper';

export enum IAskSupportIssueType {
  CARD = 'CARD',
  DATASOURCE = 'DATASOURCE',
}

interface IAskSupportProps {
  issueDescription: string;
  datasource: NS_Workspace.IDataSourcesFile;
  onClose: () => void;
  type: IAskSupportIssueType;
}

const AskSupport: React.FC<IAskSupportProps> = ({ type, onClose, datasource, issueDescription }) => {
  const { workspaceId } = useContext(WorkspaceContext);

  // States

  const [issue, setIssue] = useState<string>(type);
  const [description, setDescription] = useState<string>(`Error in ${issue.toLowerCase()} : ${issueDescription}`);
  const [showToolTip, setShowToolTip] = useState<boolean>(false);

  const handleSubmit = useCallback(() => {
    const issueName = issue === 'CARD' ? 'CARD_ERROR' : 'DS_ERROR';
    submitDatasourceToSupport(workspaceId, datasource.fileId, datasource.datasourceName, issueName, description)
      .then((res) => {
        if (res) {
          ToastHelper.success(`Request successfully sent to support`);
          onClose();
        }
      })
      .catch((err) => {
        ToastHelper.error(`An error has occured`, err);
      });
  }, [workspaceId, datasource, issue, description]);

  return (
    <CustomModal
      onConfirm={handleSubmit}
      onClose={onClose}
      onCancel={onClose}
      title="Support Request"
      confirmLabel="Send Request"
      className="askSupportModal">
      <div>
        <div>
          <label>Datasource name</label>
          <input type="text" className="input" value={datasource.datasourceName} disabled maxLength={99} />
        </div>
        <div>
          <label>Issue type</label>
          <input
            type="text"
            className="input"
            value={issue}
            onChange={(e) => setIssue(e.target.value)}
            maxLength={99}
          />
        </div>
      </div>
      <label className="descriptionLabel">
        Issue description
        <div className="tooltipContainer">
          <img
            src={help}
            alt="info"
            onMouseEnter={() => setShowToolTip(true)}
            onMouseLeave={() => setShowToolTip(false)}
          />
          {showToolTip && <div className="tooltip">You can describe your issue in detail, but it's not mandatory!</div>}
        </div>
      </label>
      <textarea className="textarea" value={description} onChange={(e) => setDescription(e.target.value)} />
      <p>
        Your datasource will be kept in this workspace exclusively. Requesting support gives Scorf's Support Services
        access to this datasource only. Once resolved, you will be notified and our access will be cancelled...
      </p>
    </CustomModal>
  );
};

export default AskSupport;
