import React from 'react';

// Style
import './style.scss';

interface IExplanationBoxProps {
  /**
   * Explanation box title
   */
  title: string;
  /**
   * Explanation text
   */
  description: string;
  /**
   * Box x position
   */
  posX: string;
}

/**
 * Explanation box to display when hover an element
 */
const ExplanationBox: React.FC<IExplanationBoxProps> = ({ title, description, posX }) => {
  return (
    <div className="explanationBoxContainer" style={{ left: posX }}>
      <h4 className="titleBox">{title}</h4>
      <p className="description">{description}</p>
    </div>
  );
};

export default ExplanationBox;
