import { isColumnInitialized } from './helpers';

export abstract class ELSFilter {
  /**
   * Init ELSFilter fields (selection, displayedOptions, isFiltered) for each column of columns
   * @param columns
   * @param rows
   */
  static initELSFilter = (columns: NS_Table.IColumnHeader[], rows: Record<string, any>[]): void => {
    // init
    for (const column of columns) {
      column.ELSFilter = {
        selection: {},
        displayedOptions: [],
        isFiltered: false,
      };
    }

    // fill distinct values
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];

      for (const accessor in row) {
        let value = row[accessor];
        if (typeof value === 'string') {
          value = value.trim();
        }
        // target column
        const targetColumnIndex = columns.findIndex((x) => x.accessor === accessor);
        if (targetColumnIndex !== -1) {
          const column = columns[targetColumnIndex];

          if (column && column.ELSFilter && !column.ELSFilter.selection.hasOwnProperty(value)) {
            column.ELSFilter.selection[value] = true;
          }
        }
      }
    }
  };

  /**
   * Apply filters on rows based on column's ELSFilter.selection object
   * Also, it updates ELSFilter displayedOptions and isFiltered fields.
   * @param columns Column objects with ELSFilter object initialized
   * @param rows An array of objects indexables by an accessor of type string.
   * @returns Filtered rows
   */
  static filter = (columns: NS_Table.IColumnHeader[], rows: Record<string, any>[]): any[] => {
    // test if it would be ok to first check column.ELSFilter.isFiltered field to improve perfs
    // filter those ones who have some selection to false, that means that they have filtered.
    const colsWithFilter = columns.filter((x) => {
      if (x.ELSFilter) {
        return !Object.values(x.ELSFilter.selection).every((isTrue) => isTrue);
      } else {
        return false;
      }
    });
    const filteredValues = ELSFilter.applyFilters(colsWithFilter, rows);

    for (const col of columns) {
      ELSFilter.setDisplayedOptions(col, rows, colsWithFilter);
      ELSFilter.setIsFiltered(col);
    }

    return filteredValues;
  };

  private static readonly applyFilters = (
    columnsWithFilters: NS_Table.IColumnHeader[],
    rows: Record<string, any>[],
  ): Record<string, any>[] => {
    const filteredRows: Record<string, any>[] = [];
    for (const row of rows) {
      let filterMatch = true;

      for (const column of columnsWithFilters) {
        const value = row[column.accessor];

        if (column && column.ELSFilter && isColumnInitialized(column) && !column.ELSFilter.selection[value]) {
          filterMatch = false;
          break;
        }
      }

      if (filterMatch) {
        filteredRows.push(row);
      }
    }

    return filteredRows;
  };

  private static readonly setDisplayedOptions = (
    columnTarget: NS_Table.IColumnHeader,
    rows: Record<string, any>[],
    columnsWithFilter: NS_Table.IColumnHeader[],
  ) => {
    // columns that are not the target
    const otherColumns = columnsWithFilter.filter((x) => x.accessor !== columnTarget.accessor);

    const filteredRows = ELSFilter.applyFilters(otherColumns, rows);

    const newDisplayedOptionsSet = new Set<string>();

    for (const row of filteredRows) {
      const value = row[columnTarget.accessor];
      newDisplayedOptionsSet.add(value);
    }

    if (columnTarget && columnTarget.ELSFilter) {
      columnTarget.ELSFilter.displayedOptions =
        Object.keys(columnTarget.ELSFilter.selection).length === newDisplayedOptionsSet.size
          ? []
          : Array.from(newDisplayedOptionsSet);
    }
  };

  private static readonly setIsFiltered = (columnTarget: NS_Table.IColumnHeader): void => {
    if (isColumnInitialized(columnTarget) && columnTarget && columnTarget.ELSFilter) {
      columnTarget.ELSFilter.isFiltered = !Object.values(columnTarget.ELSFilter.selection).every((isTrue) => isTrue);
    }
  };
}
