import { useCallback, useContext, useEffect, useState } from 'react';

import { WorkspaceContext } from '../../../context/WorkspaceContext';
import { removeExportNotification } from '../../../redux/export';
import { useAppDispatch, useAppSelector } from '../../../redux/hook';
import { changePollingTime, POLLING_DELAY } from '../../../redux/notifications';
import { startExportReports, TExportReports } from '../../../shared/api/exports';
import { ExportBatchErrorMessage } from '../../../shared/constant/exporting.consts';
import { IDatasourceMappingExport, ISelectedMapping } from '../../../shared/constant/mapping.consts';
import ToastHelper from '../../../shared/helpers/toast/ToastHelper';
import { removeRestrictedChars } from '../../../utils/common';

const useReport = () => {
  const { workspaceId, getWorkspace } = useContext(WorkspaceContext);
  const { newExport } = useAppSelector((state) => state.export);

  const [selected, setSelected] = useState<ISelectedMapping>({ datasource: '', graphId: '' });
  const [waitingAPI, setWaitingAPI] = useState<boolean>(false);
  const [openExportSettingsModal, setOpenExportSettingsModal] = useState<boolean>(false);
  const [reportIds, setReportIds] = useState<Array<Omit<IDatasourceMappingExport, 'datasourceId'>>>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (newExport?.length) {
      const currentExport = newExport.filter(
        (data) => !!reportIds.find((d) => d.exportId === data.exportId) || data.workspaceId === workspaceId,
      );
      if (currentExport?.length) {
        let isToRefresh = false;
        for (const _export of currentExport) {
          const isReport = reportIds.find((ds) => ds.exportId === _export.exportId);
          if (isReport?.exportId) {
            const { exportId } = isReport;
            if (_export?.errorMessage) {
              ToastHelper.error(_export?.errorMessage || 'Export report error');
              cleanExportId(exportId);
            } else {
              isToRefresh = true;
              setTimeout(() => {
                cleanExportId(exportId);
              }, 1000);
            }
          }
        }
        if (isToRefresh || (!isToRefresh && currentExport.length)) {
          getWorkspace(workspaceId);
        }
      }
    }
  }, [newExport, reportIds]);

  const cleanExportId = useCallback(
    (exportId: string) => {
      dispatch(removeExportNotification(exportId));
      removeReportId(exportId);
    },
    [reportIds],
  );

  const removeReportId = useCallback(
    (exportId: string | undefined) => {
      if (exportId) {
        setReportIds([...reportIds.filter((report) => report?.exportId && report.exportId !== exportId)]);
      }
    },
    [reportIds],
  );

  const exportReport = async (data: TExportReports) => {
    const { name, reportsToExport } = data;
    setWaitingAPI(true);
    return startExportReports(workspaceId, {
      name: removeRestrictedChars(name),
      reportsToExport,
    })
      .then((res) => {
        if (res?.data?.exportId) {
          dispatch(changePollingTime(POLLING_DELAY.executed));
          ToastHelper.success('Export reports started');
          setReportIds([...reportIds, { exportId: res.data.exportId, name }]);
        } else {
          ToastHelper.error(ExportBatchErrorMessage.ExportError);
        }
        setWaitingAPI(false);
        setOpenExportSettingsModal(false);
      })
      .catch((err) => {
        setWaitingAPI(false);
        ToastHelper.error(ExportBatchErrorMessage.DownloadError, err);
      });
  };

  return {
    selected,
    waitingAPI,
    reportIds,
    openExportSettingsModal,
    setOpenExportSettingsModal,
    exportReport,
    setSelected,
  };
};

export default useReport;
