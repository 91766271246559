import React from 'react';

// Style
import './style.scss';
import logoTransparent from '../../assets/img/logo-scorf-background-green.svg';

/**
 * Maintenance page container
 */

const Maintenance: React.FC = () => {
  // History

  return (
    <div className={'maintenanceRoot'}>
      <div className="logoContainer">
        <img src={logoTransparent} />
      </div>
      <header>We'll be back soon!</header>
      <p>We're very sorry for inconvenience but we're performing maintenance. Please check back soon...</p>
    </div>
  );
};

export default Maintenance;
