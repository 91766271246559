import React, { useRef, useEffect } from 'react';

// Style
import './style.scss';

interface IToggleListPreview {
  open: boolean;
  tabs: any[];
  setReportPreviewOpen: (val: boolean) => void;
  selectedSheet: number;
  dataSourceOffset: number;
  handleClickItem: (index: number, tab: any) => void;
}

/**
 * Component used to list all Reports and allow to focus on one of them.
 *
 * @param param IToggleListPreview props
 */

const ToggleListPreview: React.FC<IToggleListPreview> = ({
  open,
  tabs,
  setReportPreviewOpen,
  dataSourceOffset,
  selectedSheet,
  handleClickItem,
}) => {
  const previewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!(previewRef.current as any).contains(event.target)) {
        setReportPreviewOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`toggleListPreviewContainer ${!open && 'hide'}`} ref={previewRef}>
      <div className="reportsContainer">
        {tabs.map((item, index) => (
          <div
            className={`tabItem ${dataSourceOffset + index === selectedSheet ? ' selected' : ''}`}
            onClick={() => handleClickItem(index, item)}
            key={item.name}>
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ToggleListPreview;
