import React, { useCallback, useContext, useEffect, useState } from 'react';
// Router
import { useLocation, useNavigate } from 'react-router-dom';

import { ReportContext } from '../../context/ReportContext';
import { WorkspaceContext } from '../../context/WorkspaceContext';
import CustomModal, { BtnClassType } from '../../elements/CustomModal';

// ------------------------

/**
 * Custom modal when prevent navigation
 * This component doesn't work with react-router-dom V6, because Prompt was removed from V6.
 * Waiting for a new release in the near future to fix this.
 * @param param INavigationPromptProps
 */
const NavigationPrompt: React.FC = () => {
  const { hasChange, getChangedReportIds } = useContext(ReportContext);

  const { tabDelete, tabs } = useContext(WorkspaceContext);

  const navigate = useNavigate();
  const location = useLocation();

  // Confirmation navigation
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [lastLocation, setLastLocation] = useState<any>(location);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      const last: any = lastLocation;
      navigate(last);
    }
  }, [lastLocation, confirmedNavigation]);

  useEffect(() => {
    if (!location.pathname.includes('reports') && hasChange && !tabDelete) {
      if (!confirmedNavigation) {
        setModalOpen(true);
        setLastLocation(location);
      }
    }
  }, [location]);

  const handleLeave = useCallback(() => {
    setModalOpen(false);
    setConfirmedNavigation(true);
  }, []);

  const handleCancel = useCallback(() => {
    setModalOpen(false);
  }, []);

  const renderChangedReportNames = () => {
    const reportIds = getChangedReportIds();
    return tabs
      .filter((tab) => reportIds.findIndex((id) => id === tab.id) !== -1)
      .map((tab) => tab.name)
      .join(', ');
  };

  return (
    <>
      {/* <Prompt when={true} message={handlePrompt} />  */}
      {modalOpen && (
        <CustomModal
          onClose={handleCancel}
          onCancel={handleCancel}
          onConfirm={handleLeave}
          title="Changes Unsaved"
          confirmLabel="Discard"
          confirmBtnType={BtnClassType.Delete}>
          <p>
            You have unsaved changes on <span id="changedReports">{renderChangedReportNames()}</span>. Are you sure you
            want to discard them ?
          </p>
        </CustomModal>
      )}
    </>
  );
};

export default NavigationPrompt;
