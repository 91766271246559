export enum MappingCommandsParams {
  newParent = 'New parent',
  addParent = 'add-parent',
  newChild = 'New child',
  addChild = 'add-child',
  accountName = 'AccountName',
  move = 'move',
  moveAbove = 'above',
  moveBelow = 'below',
  moveAsSibling = 'sibling',
  moveAsChild = 'child',
  moveAccount = 'move-account',
  updateMetadata = 'update-metadata',
  addAccount = 'add-account',
  moveEntries = 'move-account-entries',
}

export interface IMappingNotification {
  workspaceId: string;
  datasourceId: string;
  notificationMessage: string;
  errorMessage?: string;
  exportId?: string;
}

export interface ISelectedMapping {
  datasource: string;
  graphId: string;
}

export interface IDatasourceMappingExport {
  datasourceId: string;
  name: string;
  exportId: string;
}
