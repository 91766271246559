import * as XLSX from '@sheet/chart';

import { setFormalisedData } from '../../../../components/chartReportComponents/TopContainer/exportAll';
import { createWorkbook, createWorksheet, generateArray, getSheetName } from '../../utils';

export const exportFlowInOut = (workspaceName: string, data: any, title: string) => {
  const wb = createWorkbook();
  let _data: any = setFormalisedData(data);
  const formalisedData = [...generateArray(workspaceName, title), ..._data];
  const ws3: any = createWorksheet(formalisedData, false);
  const sheetName = getSheetName(title);
  XLSX.utils.book_append_sheet(wb, ws3, sheetName);
  return XLSX.writeFile(wb, `${title}.xlsx`, { cellStyles: true });
};
