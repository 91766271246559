// Style
import './style.scss';

import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { WorkspaceContext } from '../../context/WorkspaceContext';
import { Svgs, Tabs } from '../../shared/constant/homeMenu.consts';

export interface IHomeMenu {
  /**
   * Menu container ref element
   */
  menuRef: React.RefObject<HTMLDivElement>;
  /**
   * Is menu open?
   */
  open: boolean;
  /**
   * Display items' name?
   */
  showName: boolean;
}

/**
 * Container used to display a workspace home menu
 *
 * @param param IHomeMenu props
 */
const HomeMenu: React.FC<IHomeMenu> = ({ open, menuRef, showName }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { workspaceId } = useContext(WorkspaceContext);
  const [selectedTab, setSelectedTab] = useState(-1);

  useEffect(() => {
    if (location?.hash) {
      const indexToPush = Tabs.findIndex((tab) => tab.hash === location.hash);
      setSelectedTab(indexToPush);
    } else {
      setSelectedTab(-1);
    }
  }, [location]);

  return (
    <div className={`menuContainer ${open ? 'open' : 'hide'} ${showName ? 'withName' : ''}`} ref={menuRef}>
      {Tabs.map((tab, index) => {
        const key = `${tab.name} - ${index}`;
        return (
          <div
            key={key}
            className={`menuItem ${index === selectedTab && 'selected'}`}
            onClick={() => {
              setSelectedTab(index);
              navigate(`/${workspaceId}/home${tab.hash}`);
            }}>
            <div className="icon">{Svgs[index]}</div>
            {showName ? (
              <span>{tab.name}</span>
            ) : (
              <div className="itemDescription">
                <span>{tab.name}</span>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default HomeMenu;
