import { useCallback, useState } from 'react';

interface FileItemProps {
  file: File & { isExist?: boolean };
  isCurrentUpload?: boolean;
  removeFile: (file: File) => void;
}

const FileItem = ({ file, isCurrentUpload, removeFile }: FileItemProps) => {
  const [visibility, setVisibility] = useState('hidden');

  const onMouseOver = useCallback(() => {
    setVisibility('flex');
  }, []);

  const onMouseOut = useCallback(() => {
    setVisibility('hidden');
  }, []);

  const handleRemove = useCallback(
    (e) => {
      removeFile(file);
    },
    [file],
  );

  return (
    <div
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      className="p-2.5 w-100 text-sm bg-white font-normal rounded-[10px] flex align-items-center justify-content-between">
      <div>
        <span className={`truncate max-w-[210px] ${isCurrentUpload && 'file-select-uploading'}`}>
          {`${file.name}`}
          {isCurrentUpload && <span className="uploading-file">{` [ Uploading ... ] `}</span>}
        </span>
        {file.isExist && <span className="already-exist-file">{` [ Already uploaded ] `}</span>}
      </div>
      <button
        className={`ml-auto ${visibility} cursor-pointer justify-content-between bg-transparent border-none`}
        onClick={handleRemove}
        type="button">
        <svg width="22" height="22" viewBox="-1 -1 16 16">
          <path
            fill="#46b78e"
            fill-rule="evenodd"
            d="M7 5.586L4.738 3.324c-.315-.315-.822-.31-1.136.003l-.186.186c-.315.315-.317.824-.004 1.137l2.262 2.262-2.35 2.35c-.315.315-.31.822.003 1.136l.186.186c.315.315.824.317 1.137.004L7 8.238l2.35 2.35c.315.315.822.31 1.137-.004l.186-.186c.314-.314.316-.823.003-1.136l-2.35-2.35 2.262-2.262c.315-.315.31-.822-.004-1.137l-.186-.186c-.314-.314-.823-.316-1.136-.003L7 5.586z"></path>
        </svg>
      </button>
    </div>
  );
};

export default FileItem;
