// Style
import './style.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import plusCircle from '../../assets/img/add-circle-green-filled.svg';
import greyCross from '../../assets/img/cross-grey.svg';
import redCross from '../../assets/img/cross-red.svg';
import DropDownList from '../../elements/DropDownList';
// Interfaces
import SpinnerLoader from '../../elements/Loaders/SpinnerLoader';
import { getWorkspaceUsers } from '../../shared/api/workspace';
import { Roles } from '../../shared/constant/member.consts';
import { renderContent } from '../../shared/helpers/helpers';
import ToastHelper from '../../shared/helpers/toast/ToastHelper';

interface ICreateWorkspaceProps {
  action: string;
  handleSubmit: (params: NS_Workspace.IWorkspaceDtoParams) => void;
  closeModal: () => void;
  defaultValues: NS_Workspace.IWorkspaceDtoParams;
  editFn: (id: string, data: NS_Workspace.IUpdateWorkspaceParam) => Promise<void>;
  deleteFn: () => void;
  forbiddenNames: string[];
  saving: boolean;
}

/**
 * Create workspace modal component
 *
 * @param params ICreateWorkspaceProps params
 */

const WorkspaceCreationModal: React.FC<ICreateWorkspaceProps> = ({
  action,
  handleSubmit,
  closeModal,
  defaultValues,
  editFn,
  deleteFn,
  forbiddenNames,
  saving,
}) => {
  // States

  const [title, setTitle] = useState<string>(defaultValues.name);
  const [description, setDescription] = useState<string>(defaultValues.description);
  const [usersOpened, setUsersOpened] = useState<boolean>(false);

  const [usersLoading, setUsersLoading] = useState<boolean>(false);
  const [originUsers, setOriginUsers] = useState<Array<NS_User.IUSER>>([]);
  const [usersToSend, setUsersToSend] = useState<Array<NS_User.IUSER>>([]);
  const [displayingUsers, setDisplayingUsers] = useState<Array<NS_User.IUSER>>([]);

  const [userEmail, setUserEmail] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<string>(Roles[0]);
  const [inputsError, setInputsError] = useState<string>('');

  const [workspaceConflictName, setWorkspaceConfilctName] = useState<string>('');
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  useEffect(() => {
    const conflictNameIndex = forbiddenNames.findIndex((name) => name.toLowerCase() === title.toLowerCase());
    setWorkspaceConfilctName(forbiddenNames[conflictNameIndex] || '');
  }, [title]);

  useEffect(() => {
    if (
      title !== defaultValues.name ||
      description !== defaultValues.description ||
      JSON.stringify(originUsers) !== JSON.stringify(displayingUsers)
    ) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [title, description, displayingUsers]);

  const isConflictWithOwnName = useMemo(() => title.toLowerCase() === defaultValues.name.toLowerCase(), [title]);

  const handleSave = () => {
    editFn(defaultValues.id ?? '', {
      updatedWorkspace: { name: title, description: description },
      users: usersToSend,
    }).catch((err) => {});
  };

  const handleOpenUsers = (_e) => {
    if (defaultValues.id && !usersOpened) {
      setUsersLoading(true);
      getWorkspaceUsers(defaultValues.id)
        .then((res) => {
          if (res.data) {
            setOriginUsers(res.data);
            setDisplayingUsers(res.data);
          }
          setUsersLoading(false);
          setUsersOpened(true);
        })
        .catch((error) => {
          ToastHelper.error(`Failed to get users`, error);
          setUsersLoading(false);
        });
    }
  };

  const handleChange = useCallback((e) => {
    setInputsError('');
    setUserEmail(e.target.value);
  }, []);

  const handleSendInvitation = () => {
    const isUserExisted = displayingUsers.filter((user) => user.email === userEmail).length;
    if (isUserExisted) {
      setInputsError('This user is already invited');
    } else if (/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(userEmail) && selectedRole) {
      const newUser = {
        groupId: selectedRole,
        email: userEmail,
      } as NS_User.IUSER;
      const newUsersToSend = [...usersToSend];
      const isInOriginUsers = originUsers.filter((user) => user.email === userEmail)[0];
      // when try to add a user who has been deleted
      if (isInOriginUsers) {
        newUsersToSend.forEach((user) => {
          if (user.email === userEmail) {
            user.delete = false;
            user.groupId = selectedRole as NS_User.IGROUP;
          }
        });
      }
      // Add a new user
      else {
        newUsersToSend.push(newUser);
      }
      setUsersToSend(newUsersToSend);
      setDisplayingUsers([...displayingUsers, newUser]);
    } else {
      setInputsError('Please check your inputs');
    }
  };

  const handleDeleteUser = (email) => () => {
    const originUser = originUsers.filter((user) => user.email === email)[0];
    // delete a origin user
    if (originUser) {
      originUser.delete = true;
      setUsersToSend([...usersToSend, originUser]);
    }
    // delete a temporary user
    else {
      const newUsers = usersToSend.filter((user) => user.email !== email);
      setUsersToSend(newUsers);
    }
    const newDisplayUsers = displayingUsers.filter((user) => user.email !== email);
    setDisplayingUsers(newDisplayUsers);
  };

  const handleRole = useCallback((role: any, index: number) => {
    setSelectedRole(role);
  }, []);

  return (
    <div
      className="modalBackground workspaceCreationContainer"
      onClick={(e) => {
        const element = e.target as HTMLElement;
        if (element.className === 'workspaceCreationContainer') {
          closeModal();
        }
      }}>
      <div className={`workspaceCreationModal ${renderContent(usersOpened, 'userOpened', '')}`}>
        <div className="modalHeader">
          <strong>{action === 'EDITING' ? 'Edit' : 'Create'} Workspace</strong>
          <img src={redCross} alt="croix" onClick={closeModal} />
        </div>
        <form
          noValidate
          autoComplete="off"
          className="modalBody createWorkspaceContainer"
          onSubmit={(e) => {
            e.preventDefault(); // preventing page reload
            if ((forbiddenNames.includes(title) && title !== defaultValues.name) || saving) {
              return;
            }
            if (action === 'EDITING') {
              handleSave();
              closeModal();
              return;
            }
            if (title.trim().length >= 1) {
              handleSubmit({ name: title, description: description });
            } else {
              setTitle('');
            }
          }}>
          <p id="title">
            Title <span style={{ color: 'red' }}>*</span>
          </p>
          <input
            id="input-workspacename"
            value={title}
            className="input"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            placeholder="Enter a title"
            autoFocus
            maxLength={40}
            required={true}
          />
          {workspaceConflictName !== '' && !isConflictWithOwnName && (
            <span id="workspace-name-warning">A workspace with the name "{workspaceConflictName}" already exists</span>
          )}
          <p id="description">Description</p>
          <textarea
            id="input-workspacedescription"
            value={description}
            className="textarea"
            maxLength={120}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            placeholder="Write a description of your workspace here."
          />
          {action === 'EDITING' && (
            <div className={`usersContainer ${renderContent(usersOpened, 'opened', '')}`} onClick={handleOpenUsers}>
              {usersOpened ? (
                <>
                  <div className="usersFormContainer">
                    <div className="userEmailContainer">
                      <label htmlFor="input-newUserEmail">User mail</label>
                      <input
                        id="input-newUserEmail"
                        name="input-newUserEmail"
                        type="email"
                        value={userEmail}
                        className="input"
                        onChange={handleChange}
                        placeholder="Enter mail address here"
                        autoFocus
                        maxLength={100}
                      />
                    </div>
                    <div className="userRoleContainer">
                      <label>Role</label>
                      <DropDownList elements={Roles} selectedElements={[selectedRole]} onItemClick={handleRole} />
                    </div>
                  </div>
                  {inputsError && <p className="errorMsg">{inputsError}</p>}
                  <button type="button" id="send-invitation-button" onClick={handleSendInvitation}>
                    Send invitation
                  </button>
                  <span className="currentMembersTitle">Current members</span>
                  {!usersLoading &&
                    displayingUsers.map((user, i) => {
                      if (user.delete === undefined) {
                        const key = `${user.email} - ${i}`;
                        return (
                          <div className="userListItem" key={key}>
                            <div className="userListItemEmailContainer">
                              <span className="userListItemEmail">{user.email}</span>
                            </div>
                            <div className="userListItemRoleContainer">
                              <span className="userListItemRole">{user.groupId}</span>
                              <a href="#">
                                <img src={greyCross} alt="croix" onClick={handleDeleteUser(user.email)} />
                              </a>
                            </div>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  {usersLoading && <SpinnerLoader />}
                </>
              ) : (
                <>
                  <img src={plusCircle} alt="plus" />
                  <span id="add-user-span">Add new users</span>
                </>
              )}
            </div>
          )}
          <div className="modalFooter">
            {action === 'EDITING' ? (
              <button
                type="button"
                className="button--red"
                onClick={(e) => {
                  deleteFn();
                }}>
                Delete
              </button>
            ) : (
              <button type="button" className="button--white" onClick={closeModal}>
                Cancel
              </button>
            )}
            <div className="submitContainer">
              {saving && <SpinnerLoader />}
              {action === 'EDITING' ? (
                <button
                  className={`${renderContent(
                    (workspaceConflictName !== '' && !isConflictWithOwnName) || saving || !hasChanges,
                    'button--green--disabled',
                    'button--green',
                  )}`}
                  disabled={!hasChanges || (workspaceConflictName !== '' && !isConflictWithOwnName)}
                  type="submit">
                  Save
                </button>
              ) : (
                <button
                  className={`${renderContent(
                    (workspaceConflictName !== '' && !isConflictWithOwnName) || saving || !title,
                    'button--green--disabled',
                    'button--green',
                  )}`}
                  disabled={(workspaceConflictName !== '' && !isConflictWithOwnName) || saving || !title}
                  type="submit">
                  Create
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WorkspaceCreationModal;
