import './style.scss';

import { useMemo, useState } from 'react';

import { NO_OPENING_JOURNAL } from '../';
import DropDownList from '../../../elements/DropDownList';
import { renderContent } from '../../../shared/helpers/helpers';
import { isArrayEqual } from '../../../shared/helpers/report/reports.helper';
import { isHidden } from '../../../shared/helpers/string/classnames.helper';
import { DATA_SOURCE_MESSAGE } from '../../../utils/constant';
import CollapsibleItem, { BadgeTypes } from '../CollapsibleItem';

interface IJournal {
  allJournal: Array<string>;
  selectedJournals: Array<string>;
  onSubmitJournalChange: (e) => void;
  areOpeningValidated: boolean;
  areOpeningsIdentified: boolean;
  openingJournal: Array<string>;
  onConfirmOpeningsIdentified: (e) => void;
  onValidateOpening: () => void;
  onClickJournal: (journal: string) => void;
  datasourcesForUnion?: NS_API.IDataSourcesForUnion;
}

const Journal: React.FC<IJournal> = ({
  allJournal,
  selectedJournals,
  onSubmitJournalChange,
  onConfirmOpeningsIdentified,
  onValidateOpening,
  areOpeningValidated,
  areOpeningsIdentified,
  openingJournal,
  datasourcesForUnion,
  onClickJournal,
}) => {
  const [openJournalChange, setOpenJournalChange] = useState<boolean>(false);

  const title = useMemo(() => {
    if (areOpeningsIdentified) {
      if (areOpeningValidated) {
        if (openingJournal.indexOf(NO_OPENING_JOURNAL) === 0) {
          return DATA_SOURCE_MESSAGE.DS_OPENING_NONE_NOT_VALIDATED_CONFIRM;
        }
        return DATA_SOURCE_MESSAGE.DS_OPENING_VALIDATED;
      } else {
        if (openingJournal.indexOf(NO_OPENING_JOURNAL) === 0) {
          return DATA_SOURCE_MESSAGE.DS_OPENING_VALIDATED_FALSE;
        } else {
          return DATA_SOURCE_MESSAGE.DS_OPENING_VALIDATED_OPENING_FALSE;
        }
      }
    }
    return DATA_SOURCE_MESSAGE.DS_NOT_OPENING_IDENTIFIED;
  }, [areOpeningsIdentified, areOpeningValidated]);

  const badgeType = useMemo(() => {
    if (areOpeningsIdentified && areOpeningValidated) {
      return BadgeTypes.VALIDATED;
    }
    if (!areOpeningsIdentified) {
      return BadgeTypes.ERROR;
    }
    return BadgeTypes.WARNING;
  }, [areOpeningsIdentified, areOpeningValidated]);

  const renderJournalStatusText = useMemo(() => {
    if (openingJournal.indexOf(NO_OPENING_JOURNAL) === 0) {
      return `Check in the original file that the data source does not include opening entries or select the correct journal code from the drop-down menu below.`;
    } else if (openingJournal.indexOf('') !== -1) {
      return 'In this Data Source, Opening entries are those that do not have any Journal';
    } else {
      return `In this Data source, Opening entries are in the Journal ${openingJournal.join(', ')}`;
    }
  }, [areOpeningsIdentified, openingJournal]);

  const toggleJournalChangeOpen = () => {
    setOpenJournalChange(!openJournalChange);
  };

  return (
    <CollapsibleItem title={title} badgeType={badgeType}>
      {renderContent(areOpeningsIdentified, renderJournalStatusText, null)}
      {areOpeningsIdentified && (
        <div className="journalActionContainer">
          {!areOpeningValidated && (
            <button className={`${isHidden(areOpeningValidated)} button--green`} onClick={onValidateOpening}>
              Confirm
            </button>
          )}
          <button className={`button--green`} onClick={toggleJournalChangeOpen}>
            Edit
          </button>
        </div>
      )}
      {!areOpeningsIdentified && (
        <p>
          Scorf has not been able to identify opening entries:
          <br />
          <br />
          If you are fine with that :&nbsp;
          <button className="validateBtn confirmOpeningDs" onClick={onConfirmOpeningsIdentified}>
            Confirm
          </button>
          <br />
          <br />
          If you want to manully set up a Journal :&nbsp;
          <button className="validateBtn" onClick={toggleJournalChangeOpen}>
            Edit
          </button>
        </p>
      )}
      {openJournalChange && (
        <div className="journalChangeContainer">
          <span>Set up a new Journal : </span>
          <DropDownList
            elements={allJournal}
            selectedElements={selectedJournals}
            onItemClick={onClickJournal}
            multipleSelect={true}
            disabled={datasourcesForUnion !== undefined}
            maxHeight={260}
          />
          <button
            className={renderContent(
              isArrayEqual(selectedJournals, openingJournal) ||
                selectedJournals.length === 0 ||
                datasourcesForUnion !== undefined,
              'button--green--disabled',
              'button--green',
            )}
            disabled={
              isArrayEqual(selectedJournals, openingJournal) ||
              selectedJournals.length === 0 ||
              datasourcesForUnion !== undefined
            }
            onClick={onSubmitJournalChange}>
            Submit
          </button>
          <button className={`button--green`} onClick={toggleJournalChangeOpen}>
            Cancel
          </button>
        </div>
      )}
    </CollapsibleItem>
  );
};

export default Journal;
