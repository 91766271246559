import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

interface INotificationStateList {
  reportAssemblyId: string;
  status: string;
  message: string;
}

interface INotificationState {
  pollingTime: number; // time for short polling
  list: Array<INotificationStateList>;
}

export const POLLING_DELAY = {
  default: process.env.REACT_APP_SHORT_POLLING ? +process.env.REACT_APP_SHORT_POLLING : 10000, // 10 second
  executed: process.env.REACT_APP_SHORT_POLLING_EXECUTED ? +process.env.REACT_APP_SHORT_POLLING_EXECUTED : 2000, // 5 second
  increase: process.env.REACT_APP_SHORT_POLLING_INCREASE ? +process.env.REACT_APP_SHORT_POLLING_INCREASE : 1000, // 5 second
};

const initialState: INotificationState = {
  pollingTime: POLLING_DELAY.default,
  list: [],
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    changePollingTime: (state, action: PayloadAction<number>) => {
      state.pollingTime = action.payload;
    },
    increasePollingDelay: (state, action: PayloadAction) => {
      const pollingTimeIncrease = state.pollingTime + POLLING_DELAY.increase;
      if (pollingTimeIncrease <= POLLING_DELAY.default) {
        state.pollingTime = pollingTimeIncrease;
      } else {
        state.pollingTime = POLLING_DELAY.default;
      }
    },
    addNotification: (state, action: PayloadAction<INotificationStateList>) => {
      state.list = [...state.list, action.payload];
    },
    deleteNotification: (state, action: PayloadAction<string>) => {
      state.list = [...state.list.filter((l) => l.reportAssemblyId !== action.payload)];
    },
  },
});

export const { addNotification, changePollingTime, increasePollingDelay, deleteNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;
