// Style
import './style.scss';

import React, { useCallback, useContext } from 'react';
import { ReactSVG } from 'react-svg';

import RedoSVG from '../../../../assets/img/redo.svg';
import UndoSVG from '../../../../assets/img/undo.svg';
import { ReportContext } from '../../../../context/ReportContext';

interface IUndoPartMenu {
  setHistoryOpen: (val: boolean) => void;
  disabled?: boolean;
}
/**
 * Container used to chose which info you want to see
 *
 * interface IUndoPartMenu {}
 * @param param NS_Tabs.ITab props
 * React.FC<IUndoPartMenu>
 */

const UndoPartMenu: React.FC<IUndoPartMenu> = ({ disabled = false }) => {
  // Context
  const { setUndo, setRedo, timeline, current } = useContext(ReportContext);

  const handleMouseUp = (type: string) => (_e) => {
    if (type === 'undo') {
      setUndo();
    } else if (type === 'redo') {
      setRedo();
    }
  };

  const getTimelineHistory = useCallback(
    (type: string) => {
      const len = timeline?.length;

      if (len) {
        if (type === 'undo') {
          return [...timeline.keys()].slice(Math.max(0, current - 5), current);
        } else if (type === 'redo') {
          return [...timeline.keys()].slice(current + 1, current + 6);
        }
      }
      return [];
    },
    [current],
  );

  return (
    <div className="undoPartContainer">
      <button
        className="buttonContainer"
        onMouseUp={handleMouseUp('undo')}
        disabled={getTimelineHistory('undo').length === 0 || disabled}>
        <ReactSVG className="svg-wrapper undoSVG" src={UndoSVG} />
      </button>
      <button
        className="buttonContainer"
        onMouseUp={handleMouseUp('redo')}
        disabled={getTimelineHistory('redo').length === 0 || disabled}>
        <ReactSVG className="svg-wrapper redoSVG" src={RedoSVG} />
      </button>
    </div>
  );
};

export default React.memo(UndoPartMenu);
