import '../../../base.scss';
import './style.scss';

import ContentLoader from 'react-content-loader';

const LoaderDonut = (props: any) => (
  <div className="loader-wrapper w-100">
    <ContentLoader speed={2} viewBox="0 0 662 276" backgroundColor="#e8e8e8" foregroundColor="#f2f2f2" {...props}>
      <rect x="622" y="11" width="27" height="27" rx="13.5" fill="#F2F2F2" />
      <rect x="27" y="22" width="189" height="12" rx="6" fill="#F2F2F2" />
      <rect x="192" y="133" width="87" height="12" rx="6" fill="#F2F2F2" />
      <ellipse
        cx="447.713"
        cy="142.161"
        rx="97.5621"
        ry="97.6402"
        transform="rotate(120 447.713 142.161)"
        fill="#F2F2F2"
      />
      <ellipse
        cx="447.712"
        cy="142.161"
        rx="41.4179"
        ry="41.451"
        transform="rotate(120 447.712 142.161)"
        fill="#FCFDFD"
      />

      <circle cx="611" cy="24.9999" r="2" fill="#D9D9D9" />
      <circle cx="604" cy="24.9999" r="2" fill="#D9D9D9" />
      <circle cx="597" cy="24.9999" r="2" fill="#D9D9D9" />
    </ContentLoader>
  </div>
);

export default LoaderDonut;
