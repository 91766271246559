import { IInsideDataSetData, LINE_CHARTS_COLORS } from '../../constant/chart.consts';
import { ChartType } from '../../constant/datasource.consts';
import { toCurrency } from '../../helpers/helpers';
import { ICategory } from '../column';
import { CHART_DATA_FONT, formattedDisplayValue, getFiscalYears, IFiscalYearIndexes, TChartData } from '../utils';

export const formalizeChartLineMedianConfigs = (params: TChartData) => {
  if (params?.isUnion && params?.fiscalYearsInfo && params.fiscalYearsInfo['similarPeriod']) {
    return formaliseMultipleLineChart(params);
  }
  return formaliseSimpleLineChart(params);
};

export const MULTIPLE_LINES_COLOR = ['235c47', '40a781', '7eceb0', 'e1f4ed', '8dbff2', 'bad9f7', 'ffe5b3'];

const formaliseSimpleLineChart = (params: TChartData) => {
  let data: Array<IInsideDataSetData> = [];
  let average = { value: '0', name: 'Average' };
  let isOpening = !!params?.reportContentSetup?.otherParams?.displayOpening;
  let reference = getFiscalYears(params.fiscalYearsInfo);
  let exportLegend = '';
  if (params?.conceptValues?.valuesLegend) {
    const { values, header, valuesLegend } = params.conceptValues;
    exportLegend = Object.values(valuesLegend)[0];
    const headers = Object.values(header);
    const firstHeader = headers?.[0]?.toLowerCase();
    const beginIterator = isOpening || !firstHeader.includes('opening') ? 0 : 1;
    let headerIndex = beginIterator;
    for (const [_key, valuesData] of Object.entries(values)) {
      for (let j = beginIterator; j < valuesData.length; j++) {
        const val = valuesData[j];
        data = [
          ...data,
          {
            value: val as string,
            label: headers[headerIndex],
            displayValue: formattedDisplayValue(val, 1000),
          },
        ];
        headerIndex = headerIndex + 1;
      }
    }
  }

  if (params?.calculatedValues?.valuesLegend) {
    const valueLegends = params?.calculatedValues?.valuesLegend;
    for (const [_key, _value] of Object.entries(valueLegends)) {
      const values = params?.calculatedValues?.values;
      if (values?.[_key]) {
        const element = isOpening ? values[_key][0] : values[_key][1];
        const notFixed: number = element;
        average.value = notFixed.toFixed(2);
      }
    }
  }
  return {
    reference,
    title: params.reportTitle,
    exportLegend,
    config: {
      type: ChartType.SPLINE,
      dataLoadStartMessage: 'Please wait, chart is loading the data....',
      dataLoadStartMessageFontSize: '14',
      renderAt: 'chart-container',
      width: '100%',
      height: '400',
      dataFormat: 'json',
      dataSource: {
        chart: {
          lineThickness: '2',
          lineColor: LINE_CHARTS_COLORS.LINE,
          anchorBgColor: LINE_CHARTS_COLORS.MARKERS,
          anchorRadius: 3,
          numberPrefix: '€',
          plottooltext: '$label, $displayValue',
          showxaxisline: '1',
          showyaxisline: '1',
          exportFileName: params.reportTitle,
          ...CHART_DATA_FONT,
        },
        data,
        trendlines: [
          {
            line: [
              {
                startvalue: average.value,
                color: '#96979A',
                valueOnRight: 0,
                thickness: 1,
                dashed: '1',
                displayValue: '-',
                toolText: `Average : ${toCurrency('0,0.00', parseFloat(average.value))} €`,
              },
            ],
          },
        ],
      },
    },
  };
};

const formaliseMultipleLineChart = (params: TChartData) => {
  let reference = getFiscalYears(params.fiscalYearsInfo);
  let exportLegend = '';
  let category: Array<ICategory> = [];
  let dataset: Array<any> = [];
  let isOpening = !!params?.reportContentSetup?.otherParams?.displayOpening;
  // headers
  if (params?.conceptValues?.header) {
    const { header } = params.conceptValues;
    const headers = Object.values(header);
    const firstHeader = headers?.[0]?.toLowerCase();
    const beginIterator = isOpening || !firstHeader.includes('opening') ? 0 : 1;
    for (let k = beginIterator; k < header.length; k++) {
      const label = params.conceptValues.header[k];
      category = [...category, { label }];
    }
  }
  if (params?.fiscalYearsInfo) {
    const keys = Object.keys(params.fiscalYearsInfo);
    const { valuesLegend, values } = params.conceptValues;
    let legendKeys = Object.keys(valuesLegend);
    for (let k = 0; k < keys.length; k++) {
      const key: any = keys[k];
      let seriesname = `${key}`;
      const color = MULTIPLE_LINES_COLOR[k];
      if (!isNaN(key)) {
        const indexes: IFiscalYearIndexes = params.fiscalYearsInfo[key];
        let insideDataArray: Array<{ value: string; anchorBgColor: string; displayValue: string }> = [];
        for (let k = 0; k < legendKeys.length; k++) {
          const legendKey = legendKeys[k];
          // headers
          for (const [_key, value] of Object.entries(values)) {
            const _v = value as Array<string>;
            if (_key === legendKey) {
              if (_v?.length) {
                for (let j = indexes.firstIndex; j <= indexes.lastIndex; j++) {
                  if (_v[j] !== null && _v[j] !== undefined) {
                    insideDataArray = [
                      ...insideDataArray,
                      { value: _v[j], anchorBgColor: color, displayValue: formattedDisplayValue(_v[j], 1000) },
                    ];
                  }
                }
              }
            }
          }
        }
        dataset = [...dataset, { seriesname, data: [...insideDataArray], color, key }];
      }
    }
  }
  return {
    reference,
    title: params.reportTitle,
    exportLegend,
    config: {
      type: ChartType.MULTIPLE_SPLINE,
      dataLoadStartMessage: 'Please wait, chart is loading the data....',
      dataLoadStartMessageFontSize: '14',
      renderAt: 'chart-container',
      width: '100%',
      height: '400',
      dataFormat: 'json',
      dataSource: {
        chart: {
          lineThickness: '2',
          anchorRadius: 3,
          numberPrefix: '€',
          plottooltext: '$label, $displayValue',
          showxaxisline: '1',
          showyaxisline: '1',
          showLegend: '1',
          legendBgAlpha: '0',
          legendBorderAlpha: '0',
          legendShadow: '0',
          legendItemFontSize: '10',
          legendIconAlpha: '100',
          legendIconBorderThickness: '5',
          exportFileName: params.reportTitle,
          ...CHART_DATA_FONT,
        },
        categories: [{ category }],
        dataset,
      },
    },
  };
};
