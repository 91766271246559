import './index.scss';

import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import AppWithAuthentication, { App } from './App';
import { acquireTokenRequest, msalInstance } from './authConfig';
import { RoutesEnum } from './shared/constant/route.consts';
import ToastHelper from './shared/helpers/toast/ToastHelper';

axios.defaults.withCredentials = true

export const service = axios.create({
  baseURL: process.env.REACT_APP_APP_URL,
});

export const serviceImport = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_IMPORT,
});

export const serviceDatasource = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_DATASOURCE,
});

export const serviceMining = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_MINING,
});

export const serviceLinematching = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_LINEMATCHING,
});

export const serviceDatapivot = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_DATAPIVOT,
});

export const serviceCounterpart = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_COUNTERPART,
});

export const serviceColumndata = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_COLUMNDATA,
});

export const serviceExport = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_EXPORT,
});

export const serviceWorkspace = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_WORKSPACE,
});

export const serviceWorksheet = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_WORKSHEET,
});

export const serviceQandA = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_QANDA,
});

export const serviceCustomer = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_CUSTOMER,
});

export const serviceTemplateCollection = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_TEMPLATE_COLLECTION,
});

export const serviceGraph = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_GRAPH,
});

export const serviceReport = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_REPORT,
});

export const serviceBatchExport = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_BATCH_EXPORT,
});

export const serviceReportsBuilders = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_BUILDERS,
});

export const serviceLazyLoad = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_LAZYLOAD,
});

export const serviceReportAssembly = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_REPORT_ASSEMBLY,
});

export const serviceNotification = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_NOTIFICATION,
});

export const handleErrors = async (error: any) => {
  const account = msalInstance.getActiveAccount();
  if (axios.isAxiosError(error) && error.response != null) {
    if (error.response.status === 401) {
      window.location.href = RoutesEnum.timeout;
      return Promise.reject(error);
    }
    if (error.response.status === 403 && account) {
      ToastHelper.warn("You don't have enough permissions.");
      return Promise.reject(error);
    }
  }
  if (error?.message && error.message.includes('Network Error')) {
    const delayRetryRequest = (retry: number, error: any) => {
      if (retry < 3) {
        setTimeout(() => {
          const nextRetry = retry + 1;
          return delayRetryRequest(nextRetry, error);
        }, 3000);
      } else {
        ToastHelper.error("Network error: server can't be reached. Please check your internet connexion.");
      }
    };
    return delayRetryRequest(0, error);
  } else {
    if (error instanceof Error) {
      ToastHelper.error(error.message);
    } else {
      ToastHelper.error('Unknown error');
    }
    return Promise.reject(error);
  }
};

export const handleNotificationErrors = async (error: any) => {};

async function fetchAccessToken() {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }

  return msalInstance
    .acquireTokenSilent(acquireTokenRequest)
    .then((tokenResponse) => {
      return tokenResponse.accessToken;
    })
    .catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        return msalInstance.acquireTokenRedirect(acquireTokenRequest);
      }
    });
}

async function axiosMsalRequestInterceptor(config: AxiosRequestConfig) {
  if (process.env.REACT_APP_AUTH === '1') {
    const accessToken = await fetchAccessToken();

    if (accessToken) {
      (config.headers as AxiosRequestHeaders)['Authorization'] = `Bearer ${accessToken}`;
    } else {
      ToastHelper.error('MSAL token fetch error');
    }
  }
  return config;
}

service.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);
serviceWorkspace.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);
serviceWorksheet.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);
serviceImport.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);
serviceDatasource.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);
serviceMining.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);
serviceExport.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);
serviceCustomer.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);
serviceGraph.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);
serviceBatchExport.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);
serviceReport.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);
serviceReportsBuilders.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);
serviceTemplateCollection.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);
serviceLinematching.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);
serviceDatapivot.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);
serviceCounterpart.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);
serviceColumndata.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);
serviceQandA.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);
serviceLazyLoad.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);
serviceReportAssembly.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);
serviceNotification.interceptors.request.use(axiosMsalRequestInterceptor, handleErrors);

service.interceptors.response.use(undefined, handleErrors);
serviceWorkspace.interceptors.response.use(undefined, handleErrors);
serviceWorksheet.interceptors.response.use(undefined, handleErrors);
serviceImport.interceptors.response.use(undefined, handleErrors);
serviceDatasource.interceptors.response.use(undefined, handleErrors);
serviceMining.interceptors.response.use(undefined, handleErrors);
serviceExport.interceptors.response.use(undefined, handleErrors);
serviceCustomer.interceptors.response.use(undefined, handleErrors);
serviceGraph.interceptors.response.use(undefined, handleErrors);
serviceBatchExport.interceptors.response.use(undefined, handleErrors);
serviceReport.interceptors.response.use(undefined, handleErrors);
serviceReportsBuilders.interceptors.response.use(undefined, handleErrors);
serviceTemplateCollection.interceptors.response.use(undefined, handleErrors);
serviceLinematching.interceptors.response.use(undefined, handleErrors);
serviceCounterpart.interceptors.response.use(undefined, handleErrors);
serviceDatapivot.interceptors.response.use(undefined, handleErrors);
serviceColumndata.interceptors.response.use(undefined, handleErrors);
serviceQandA.interceptors.response.use(undefined, handleErrors);
serviceLazyLoad.interceptors.response.use(undefined, handleErrors);
serviceReportAssembly.interceptors.response.use(undefined, handleErrors);
serviceNotification.interceptors.response.use(undefined, handleNotificationErrors);

const container = document.getElementById('root');
const root = createRoot(container!);

if (process.env.REACT_APP_AUTH === '1') {
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <AppWithAuthentication />
          <ToastContainer />
        </MsalProvider>
      </BrowserRouter>
    </React.StrictMode>,
  );
} else {
  root.render(
    <BrowserRouter>
      <App />
      <ToastContainer />
    </BrowserRouter>,
  );
}

if ('serviceWorker' in navigator) {
  void navigator.serviceWorker.ready.then(async (registration) => {
    await registration.unregister();

    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      void caches.keys().then(async (names) => {
        await Promise.all(names.map((name) => caches.delete(name)));
      });
    }
  });
}
