import './style.scss';

import React, { useEffect, useState } from 'react';
// Style
import { ReactSVG } from 'react-svg';

import CheckSVG from '../../assets/img/check-circle-green-filled.svg';
import CrossSVG from '../../assets/img/cross-white.svg';
import CustomModal from '../../elements/CustomModal';

interface IEditSaveResults {
  closeModal: () => void;
  results: NS_API.IEditSaveResult;
  templateList: Object | undefined;
}

/**
 * Component used to display the result of each edit command
 */

const EditSaveResults: React.FC<IEditSaveResults> = ({ closeModal, results, templateList }) => {
  const [templateDict, setTemplateDict] = useState<Object>({});

  useEffect(() => {
    const newTempDict = {};
    if (templateList) {
      Object.values(templateList).forEach((template) => {
        newTempDict[template.templateId] = template.name;
      });
    }
    setTemplateDict(newTempDict);
  }, [templateList]);

  return (
    <CustomModal
      title={results.status === 'SUCCESS' ? 'All changes have been saved !' : 'Some changes could not be saved...'}
      onConfirm={closeModal}
      onClose={closeModal}
      onCancel={closeModal}
      className="editSaveResultModal"
      confirmLabel="OK">
      <div className="commandsList">
        {Object.values(results.commandsExecutionStatus).map((exectutionStatus, index) => {
          const key = `${index}-${exectutionStatus.message}`;
          return (
            <div className="command" key={key}>
              <div className="commandMessage">
                <span>
                  <b>Change:</b> {exectutionStatus.message}
                </span>
                {exectutionStatus.impactedTemplateIds && (
                  <span>
                    <b>Impacted templates:</b>{' '}
                    {exectutionStatus.impactedTemplateIds.map((id) => templateDict[id]).join(', ')}
                  </span>
                )}
              </div>
              <div className={`${exectutionStatus.status !== 'SUCCESS' && 'errorContainer'} checkContainer`}>
                {exectutionStatus.status === 'SUCCESS' ? (
                  <ReactSVG src={CheckSVG} className="svg-wrapper checkSVG" />
                ) : (
                  <ReactSVG src={CrossSVG} className="svg-wrapper crossSVG" />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </CustomModal>
  );
};

export default EditSaveResults;
