import '../base.scss';
import './style.scss';

import { useAppSelector } from '../../../redux/hook';

const FooterCharts = () => {
  const workspace = useAppSelector((state) => state.workspace);
  return (
    <footer className="font-regular flex flex-nowrap justify-content-between">
      {workspace.name}
      <div className="flex right">
        <div className="version">{`Version ${process.env.REACT_APP_VERSION}`}</div>
      </div>
    </footer>
  );
};
export default FooterCharts;
