// Style
import './style.scss';

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { WorkspaceContext } from '../../../context/WorkspaceContext';
import { compareTwoFlatArrays, renderContent } from '../../../shared/helpers/helpers';
import ColorOptions, { ColorOption } from './ColorOption';
import GraphOptions from './GraphOption';
import NumbersOption from './NumbersOption';

/**
 * Component used to display export files configuraions
 */

const ExportOptions: React.FC = () => {
  const { colorOptions, saveColorOptions, workspaceId, setPreferredNumbers, preferredNumbers } =
    useContext(WorkspaceContext);
  const [primaryFillColor, setPrimaryFillColor] = useState<string>('');
  const [secondaryFillColor, setSecondaryFillColor] = useState<string>('');
  const [primaryTextColor, setPrimaryTextColor] = useState<string>('');
  const [secondaryTextColor, setSecondaryTextColor] = useState<string>('');
  const [headerFillColor, setHeaderFillColor] = useState<string>('');
  const [headerTextColor, setHeaderTextColor] = useState<string>('');
  const [chartColorSeries, setChartColorSeries] = useState<Array<string>>([
    '#46b78eff',
    '#96979aff',
    '#8eb4e3ff',
    '#c6c8c9ff',
    '#f2dcdbff',
  ]);

  const [currencyParams, setCurrencyParams] = useState<NS_Table.ICurrency>(preferredNumbers.currencyParams);

  const hasColorChange = useMemo(() => {
    return (
      colorOptions.primaryFillColor !== primaryFillColor ||
      colorOptions.secondaryFillColor !== secondaryFillColor ||
      colorOptions.primaryTextColor !== primaryTextColor ||
      colorOptions.secondaryTextColor !== secondaryTextColor ||
      colorOptions.headerTextColor !== headerTextColor ||
      colorOptions.headerFillColor !== headerFillColor ||
      !compareTwoFlatArrays(colorOptions.chartColors, chartColorSeries)
    );
  }, [
    colorOptions,
    primaryFillColor,
    secondaryFillColor,
    secondaryTextColor,
    primaryTextColor,
    headerTextColor,
    headerFillColor,
    chartColorSeries,
  ]);

  const hasNumberChange = useMemo(() => {
    return (
      currencyParams.displayUnit !== preferredNumbers.currencyParams.displayUnit ||
      currencyParams.format !== preferredNumbers.currencyParams.format
    );
  }, [currencyParams, preferredNumbers]);

  useEffect(() => {
    setPrimaryFillColor(colorOptions.primaryFillColor);
    setSecondaryFillColor(colorOptions.secondaryFillColor);
    setPrimaryTextColor(colorOptions.primaryTextColor);
    setSecondaryTextColor(colorOptions.secondaryTextColor);
    setHeaderFillColor(colorOptions.headerFillColor);
    setHeaderTextColor(colorOptions.headerTextColor);
    setChartColorSeries(colorOptions.chartColors);
  }, [colorOptions]);

  const handleClickValidate = () => {
    submitColorChange();
    submitNumberChange();
  };

  const submitColorChange = () => {
    if (hasColorChange) {
      const newColorOptions: NS_Workspace.IColorOptions = {
        primaryFillColor: primaryFillColor,
        secondaryFillColor: secondaryFillColor,
        primaryTextColor: primaryTextColor,
        secondaryTextColor: secondaryTextColor,
        headerFillColor: headerFillColor,
        headerTextColor: headerTextColor,
        chartColors: chartColorSeries,
      };
      saveColorOptions(workspaceId, newColorOptions);
    }
  };

  const submitNumberChange = () => {
    if (hasNumberChange) {
      let monetaryUnit: string;
      if (currencyParams.displayUnit === 1) {
        monetaryUnit = 'DEFAULT';
      } else if (currencyParams.displayUnit === 1000) {
        monetaryUnit = 'k';
      } else {
        monetaryUnit = 'M';
      }

      let decimalSeparator = currencyParams.format.split('.')[1]?.length;
      if (!decimalSeparator) {
        decimalSeparator = 0;
      }
      const newNumbersOptions: NS_Workspace.IPreferedNumbers = {
        monetaryUnit,
        decimalSeparator,
        currencyParams,
      };
      setPreferredNumbers(workspaceId, newNumbersOptions);
    }
  };

  const setColor = useCallback((type: ColorOption, color: string) => {
    if (type === 'primaryFill') {
      setPrimaryFillColor(color);
    } else if (type === 'secondaryFill') {
      setSecondaryFillColor(color);
    } else if (type === 'primaryText') {
      setPrimaryTextColor(color);
    } else if (type === 'secondaryText') {
      setSecondaryTextColor(color);
    } else if (type === 'headerFill') {
      setHeaderFillColor(color);
    } else if (type === 'headerText') {
      setHeaderTextColor(color);
    }
  }, []);

  return (
    <div className="exportOptionsContainer">
      <div className="contentContainer">
        <ColorOptions
          colors={{
            primaryFillColor: primaryFillColor,
            secondaryFillColor: secondaryFillColor,
            primaryTextColor: primaryTextColor,
            secondaryTextColor: secondaryTextColor,
            headerFillColor: headerFillColor,
            headerTextColor: headerTextColor,
          }}
          setColor={setColor}
        />
        <NumbersOption currencyParams={currencyParams} setCurrencyParams={setCurrencyParams} />
        <GraphOptions colorSeries={chartColorSeries} setColorSeries={setChartColorSeries} />
      </div>
      <div className="optionButtons">
        <button
          className={`${renderContent(hasColorChange || hasNumberChange, 'button--green', 'button--green--disabled')}`}
          disabled={!hasColorChange && !hasNumberChange}
          onClick={handleClickValidate}>
          Apply
        </button>
      </div>
    </div>
  );
};

export default ExportOptions;
