// Style
import './style.scss';

import { saveAs } from 'file-saver';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
// SVG
import { ReactSVG } from 'react-svg';

import ArrowRightSVG from '../../../assets/img/arrow-right-green.svg';
import DownloadSVG from '../../../assets/img/download.svg';
import Export from '../../../assets/img/export-undraw.svg';
import ReloadSVG from '../../../assets/img/reload.svg';
import TrashSVG from '../../../assets/img/trash-red.svg';
import { WorkspaceContext } from '../../../context/WorkspaceContext';
import Checkbox from '../../../elements/Checkbox';
import ProgressLoader from '../../../elements/Loaders/ProgressLoader';
import ExportMapping from '../../../modals/ExportMapping';
// Scorf component
import ExportSettings, { IReportsToExport } from '../../../modals/ExportSettings';
// API
import { deleteExportedFile, downloadExportedFile, EEXPORT } from '../../../shared/api/exports';
import { ExportBatchErrorMessage } from '../../../shared/constant/exporting.consts';
import { renderContent } from '../../../shared/helpers/helpers';
import StringHelper from '../../../shared/helpers/string/string.helper';
import ToastHelper from '../../../shared/helpers/toast/ToastHelper';
import { REPORT_TABS_TYPE } from '../../../utils/constant';
import useMapping from './mapping';
import useReport from './report';

export interface IReportInfo {
  id: string;
  name: string;
  dataSource: string;
  datasourceId: string;
}

export interface IExportList {
  setShowTitle: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddNewSheet: () => void;
  handleGotoDS: () => void;
  handleExportDenied: () => void;
}

/**
 * Component used to display all the data books for a workspace
 */

const ExportsList: React.FC<IExportList> = ({ setShowTitle, handleGotoDS, handleAddNewSheet, handleExportDenied }) => {
  // States
  const [includedReports, setIncludedReports] = useState<number[]>([]);
  const [reportsInfo, setReportsInfo] = useState<IReportInfo[]>([]);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  // Context
  const { workspaceId, exportedFiles, tabs, name, dataSources, deleteExportedFileContext } =
    React.useContext(WorkspaceContext);
  // Custom Hooks
  const { generateLoading, openMappingModal, datasourceIds, openMaping, closeMaping, exportMapping } = useMapping();
  const {
    selected,
    waitingAPI,
    openExportSettingsModal,
    reportIds,
    exportReport,
    setOpenExportSettingsModal,
    setSelected,
  } = useReport();

  useEffect(() => {
    setShowTitle(tabs && reportsInfo.length > 0);
  }, [tabs, reportsInfo]);

  useEffect(() => {
    if (tabs.length > 0) {
      const tempReportsInfo: IReportInfo[] = [];
      let report: IReportInfo;
      tabs.forEach((tab) => {
        if (tab.reportSaved && tab.type !== REPORT_TABS_TYPE.CHART_REPORT) {
          const ds = dataSources.filter((dataSource) => dataSource.fileId === tab.datasourceId);
          report = {
            id: tab.id,
            name: tab.name,
            dataSource: ds[0] ? ds[0].datasourceName : '-',
            datasourceId: ds[0].fileId,
          };
          tempReportsInfo.push(report);
        }
      });
      setReportsInfo(tempReportsInfo);
    }
  }, [tabs]);

  // Functions
  const handleExportMapping = useCallback(
    (datasourceId: string, graphId: string) => {
      exportMapping(datasourceId, graphId).catch((err) => {});
    },
    [datasourceIds],
  );

  const handleExportClick = useCallback(
    (fileName: string, reportsToExport: IReportsToExport[]) => {
      setIncludedReports([]);
      exportReport({ name: fileName, reportsToExport }).catch((err) => {});
    },
    [name, reportIds],
  );

  const handleDSClick = (action: EEXPORT) => {
    if (action === EEXPORT.EXPORT_MAPPING) {
      setSelected({ datasource: '', graphId: '' });
      openMaping();
    }
  };

  const handleReportDSClick = (index: number) => () => {
    const include = includedReports.includes(index);
    if (!include) {
      setIncludedReports([...includedReports, index]);
    } else {
      const reports = includedReports.filter((i) => i !== index);
      setIncludedReports(reports);
    }
  };

  const handleSelectAllClick = useCallback(
    (e) => {
      if (includedReports.length === reportsInfo.length) {
        setIncludedReports([]);
      } else {
        setIncludedReports(reportsInfo.map((_tab, i) => i));
      }
    },
    [includedReports, reportsInfo],
  );

  const handleDownloadClick = (fileName: string) => () => {
    setDownloadLoading(true);
    downloadExportedFile(workspaceId, fileName)
      .then((resp) => {
        setDownloadLoading(false);
        if (resp && resp.status === 200) {
          saveAs(resp.data, fileName);
          window.gtag('event', 'export_download', {
            event_label: 'export_download',
            filename: fileName,
            workspaceId: workspaceId,
          });
        } else {
          ToastHelper.error(ExportBatchErrorMessage.DownloadError);
        }
      })
      .catch((e) => {
        ToastHelper.error(ExportBatchErrorMessage.DownloadError, e);
      });
  };

  const handleDeleteClick = (fileName: string) => () => {
    deleteExportedFile(workspaceId, fileName)
      .then((resp) => {
        if (resp.status === 200) {
          deleteExportedFileContext(fileName);
        }
      })
      .catch((e) => ToastHelper.error('Failed to delete', e));
  };

  const handleCloseSetting = useCallback(() => {
    setOpenExportSettingsModal(false);
  }, []);

  const generateFilePending = useCallback(() => {
    return [
      ...datasourceIds.map((ds) => {
        return { name: ds.name };
      }),
      ...reportIds.map((rep) => {
        return { name: rep.name };
      }),
    ];
  }, [datasourceIds, reportIds]);

  const isFileEmpty = useMemo(
    () => exportedFiles.length === 0 && !datasourceIds.length && !reportIds.length,
    [exportedFiles, datasourceIds, reportIds],
  );

  const filesPending = useMemo(() => generateFilePending(), [datasourceIds, reportIds]);

  return (
    <div className="databooksContainer">
      {(openExportSettingsModal || waitingAPI) && (
        <ExportSettings
          closeModal={handleCloseSetting}
          handleSave={handleExportClick}
          waitingAPI={waitingAPI}
          reports={reportsInfo.filter((_tab, i) => includedReports.includes(i))}
        />
      )}
      {openMappingModal && (
        <div className="export-mapping">
          <ExportMapping
            closeModal={closeMaping}
            handleSave={handleExportMapping}
            waitingAPI={generateLoading}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
      )}
      {renderContent(downloadLoading, <ProgressLoader />, null)}
      {(tabs && reportsInfo.length > 0) || exportedFiles.length !== 0 ? (
        <>
          <div className="reportsListContainer">
            <table className="reportsList">
              <thead>
                <tr>
                  <th>
                    <Checkbox
                      toggle={handleSelectAllClick}
                      checked={reportsInfo.length !== 0 && reportsInfo.length === includedReports.length}
                    />
                  </th>
                  <th>Name</th>
                  <th>Data Source</th>
                  <th>Go to</th>
                </tr>
              </thead>
              <tbody>
                {tabs.filter((tab) => tab.reportSaved).length === 0 && (
                  <tr>
                    <td colSpan={4} className="emptyListCell">
                      No reports in this workspace
                    </td>
                  </tr>
                )}
                {reportsInfo.map((report, index) => {
                  const key = `${index} - ${report.name}`;
                  return (
                    <tr key={key} className={`${includedReports.includes(index) ? 'checked' : 'notChecked'}`}>
                      <td>
                        <Checkbox toggle={handleReportDSClick(index)} checked={includedReports.includes(index)} />
                      </td>
                      <td className="reportName">{report.name}</td>
                      <td className="reportName">{report.dataSource}</td>
                      <td className="icons">
                        <NavLink to={`/${workspaceId}/reports/${report.id}`} className="gotoCell">
                          <ReactSVG src={ArrowRightSVG} className="svg-wrapper arrowRightSVG" />
                        </NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="databooksCount">
            <button
              className={`${renderContent(includedReports.length === 0, 'button--green--disabled', 'button--green')}`}
              onClick={(e) => {
                if (includedReports.length !== 0) {
                  setOpenExportSettingsModal(true);
                }
              }}>
              {`Export ${renderContent(includedReports.length > 1, 'Reports', 'Report')}`}
            </button>
            <button
              className={`
                    button--green`}
              onClick={(e) => {
                handleDSClick(EEXPORT.EXPORT_MAPPING);
              }}>
              {`Export Mapping`}
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="placeholderContainerExport">
            <img src={Export} alt="" />
            <h3>Exports the Reports of your Workspace !</h3>
            <div className="textContainer">
              <p>
                Before you can export any report, you will first need to add a Data Source and then to generate your
                first Report.
              </p>
            </div>
            <div className="btnContainers">
              <button className="button--green" onClick={handleGotoDS}>
                Add a Data Source
              </button>
              {dataSources.length > 0 && (
                <button className="button--white" onClick={handleAddNewSheet}>
                  Create Report
                </button>
              )}
            </div>
          </div>
        </>
      )}
      {(exportedFiles.length !== 0 || (tabs && reportsInfo.length > 0)) && (
        <>
          <h3 className="moduleTitle">Reports to download</h3>
          <div className="databooksListContainer">
            {isFileEmpty && (
              <div className="placeholderContainer">
                <h4>Start exporting reports</h4>
                <p>Find here exported reports ready to be downloaded.</p>
              </div>
            )}
            <div className="table">
              {!!filesPending?.length &&
                filesPending.map((file, index) => {
                  const key = `${file.name} - ${index}`;
                  return (
                    <div key={key} className="row">
                      <div className={`cell cell--name`}>
                        Name: &nbsp; <span>{file.name}</span>
                      </div>
                      <div className="cell cell--size">-</div>
                      <div className="cell cell--action cell--action--processing">
                        Processing...
                        <ReactSVG src={ReloadSVG} className="svg-wrapper reloadSVG" />
                      </div>
                    </div>
                  );
                })}
              {exportedFiles.map((file, index) => {
                const key = `${file.fileName} - ${index}`;
                return (
                  <div key={key} className="row">
                    <div className={`cell cell--name`}>
                      Name: &nbsp; <span>{file.fileName}</span>
                    </div>
                    <div className="cell cell--size">
                      File size:&nbsp;<span>{StringHelper.formatBytes(file.fileLength)}</span>
                    </div>
                    <div className="cell cell--action">
                      <ReactSVG
                        src={DownloadSVG}
                        className="svg-wrapper downloadSVG"
                        onClick={handleDownloadClick(file.fileName)}
                      />
                      <ReactSVG
                        src={TrashSVG}
                        className="svg-wrapper trashSVG"
                        onClick={handleDeleteClick(file.fileName)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ExportsList;
