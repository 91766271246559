// Style
import './style.scss';

import React, { useState } from 'react';

import ColorPicker from '../../../../elements/ColorPicker';

interface IColorsProps {
  colorSeries: Array<string>;
  setColorSeries: (val: Array<string>) => void;
}

/**
 * Component used to change chart color configuration
 * @oaram IColorsProps
 */

const GraphOptions: React.FC<IColorsProps> = ({ colorSeries, setColorSeries }) => {
  const [colorPickersOpen, setColorPickersOpen] = useState<Array<boolean>>(
    Array.from({ length: colorSeries.length }, () => false),
  );

  const handleRestore = () => {
    setColorSeries(['#46b78eff', '#96979aff', '#8eb4e3ff', '#c6c8c9ff', '#f2dcdbff']);
  };

  const toggleColorPickOpen = (index: number) => () => {
    const newOpenStates = [...colorPickersOpen];
    newOpenStates[index] = !colorPickersOpen[index];
    setColorPickersOpen(newOpenStates);
  };

  const handleChangeColorComplete = (index: number) => (color: string) => {
    const newColors = [...colorSeries];
    newColors[index] = color;
    setColorSeries(newColors);
  };

  const handleClose = (index: number) => () => {
    const newOpenStates = [...colorPickersOpen];
    newOpenStates[index] = false;
    setColorPickersOpen(newOpenStates);
  };

  return (
    <div className="colorOptionContainer graphOptionsContainer">
      <h3 className="optionTitle">Chart Styles</h3>
      <div className="colorRestoreButton" onClick={handleRestore}>
        Restore default styles
      </div>
      <label className="optionDescription">Formatting options that apply to all charts in the file exported.</label>

      <h5 className="colorSubtitle">Color Series :</h5>
      <div className="colorSeriesContainer">
        {colorSeries.map((value, index) => {
          const key = `${value} - ${index}`;
          return (
            <div key={key} className="colorSerieContainer">
              <label>#{index}</label>
              <div className="buttons">
                <button
                  className="buttonContainer"
                  style={{ backgroundColor: value }}
                  onClick={toggleColorPickOpen(index)}
                />
                {colorPickersOpen[index] && (
                  <ColorPicker
                    titleText="Secondary Highlighting -- Fill"
                    color={value}
                    handleClose={handleClose(index)}
                    handleChangeColor={handleChangeColorComplete(index)}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GraphOptions;
