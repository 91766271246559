// Style
import './style.scss';

import { debounce } from 'lodash';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
// Router
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import AddFileSVG from '../../assets/img/add-file.svg';
// Context
import { WorkspaceContext } from '../../context/WorkspaceContext';
// API
import { createWorksheet, deleteWorksheets, updateWorksheet } from '../../shared/api/tabs';
import { ItemTypes } from '../../shared/constant/drapAndDrop.consts';
import { renderContent } from '../../shared/helpers/helpers';
import ToastHelper from '../../shared/helpers/toast/ToastHelper';
import { noAction } from '../../utils/common';
// Scorf components
import ToggleListElement from './ToggleListElement/index';
import ToggleListPaddle from './ToggleListPaddle/index';

const HOME_DATASOURCE_OFFSET = 3;

export enum paddleSide {
  RIGHT,
  LEFT,
}

export interface IWorkspaceDtoReportParams {
  name: string;
  reportsOrder: Array<string>;
}

export const staticMenu: Array<NS_Menu.ITab> = [
  { text: 'Home', link: 'home', type: 'static', id: '1' },
  { text: 'Data Sources', link: 'dataSource', type: 'static', id: '2' },
];

/**
 * Component used to manage tabs in a workspace. It has two tabs always present (Home and
 * Data source) and as much as you want new tabs fro reports
 */

const ToggleList: React.FC = () => {
  // Context

  const {
    newTab,
    deleteTab,
    renameTab,
    tabs,
    fetching,
    workspaceId,
    name,
    updateTabOrder,
    resetTabDeleteState,
    reportSaving,
  } = useContext(WorkspaceContext);

  // History

  const navigate = useNavigate();
  const location = useLocation();
  // Refs

  const toggleListRef = useRef<HTMLDivElement>(null);

  // States

  const [lastUpdateAdd, setLastUpdateAdd] = useState<boolean>(false);
  const [lastUpdateDelete, setLastUpdateDelete] = useState<{ state: boolean; deleted: number }>({
    state: false,
    deleted: -1,
  });
  const [selectedSheet, setSelectedSheet] = useState<number>(0);
  const [displayLeftPaddle, setDisplayLeftPaddle] = useState<boolean>(false);
  const [displayRightPaddle, setDisplayRightPaddle] = useState<boolean>(false);
  const [deletingTabs, setDeletingTabs] = useState<Array<any>>([]);

  const [multiSelectedTabs, setMultiSelectedTabs] = useState<Array<number>>([]);
  const [isOverAddFile, setIsOverAddFile] = useState<boolean>(false);

  // Drop (After last tab)
  const [{ dragItem, isOver }, dropper] = useDrop({
    accept: ItemTypes.TAB,
    drop: () => {
      const _dragItem = dragItem as any;
      if (_dragItem?.draggedTabIndex)
        updateTabsOrder(_dragItem.draggedTabIndex as number, tabs.length + HOME_DATASOURCE_OFFSET);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),

      dragItem: monitor.getItem(),
    }),
  });

  // Effects

  useEffect(() => {
    setIsOverAddFile(isOver);
  }, [isOver]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      checkForScrollPosition();
    });

    return () => {
      window.removeEventListener('resize', () => {
        checkForScrollPosition();
      });
    };
  }, []);

  useEffect(() => {
    const urlParts = location.pathname.split('/');
    const tab = urlParts[urlParts.length - 1];
    for (let i = 0; i < staticMenu.length; i++) {
      const menuItem = staticMenu[i];
      if (menuItem.link === tab) {
        setSelectedSheet(i);
        return;
      }
    }
    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].id === tab) {
        setSelectedSheet(i + HOME_DATASOURCE_OFFSET);
        return;
      }
    }
    if (workspaceId !== '') {
      navigate('/404');
    }
    // eslint-disable-next-line
  }, [location.pathname, tabs]);

  useEffect(() => {
    if (lastUpdateAdd) {
      setSelectedSheet(HOME_DATASOURCE_OFFSET + tabs.length - 1);
      setLastUpdateAdd(false);
    }
    if (lastUpdateDelete.state) {
      if (tabs.length === 0 && selectedSheet !== 2 && selectedSheet !== 0) {
        navigate(`/${workspaceId}/dataSource`);
        resetTabDeleteState();
      } else if (multiSelectedTabs.indexOf(selectedSheet) !== -1) {
        // Last selected sheet was been deleted
        if (selectedSheet !== 0 && selectedSheet !== 1 && selectedSheet !== 2) {
          const worksheetId =
            tabs[
              Math.min(
                tabs.length - 1,
                lastUpdateDelete.deleted + 1 - multiSelectedTabs.length - HOME_DATASOURCE_OFFSET,
              )
            ].id;
          navigate(`/${workspaceId}/reports/${worksheetId}`);
          resetTabDeleteState();
        }
      }
      setLastUpdateDelete({ state: false, deleted: -1 });
      setMultiSelectedTabs([]);
    }
    // eslint-disable-next-line
  }, [tabs]);

  useEffect(() => {
    debounceCheckForScrollPosition();
    if (multiSelectedTabs.indexOf(selectedSheet) === -1) {
      if (selectedSheet >= HOME_DATASOURCE_OFFSET) {
        setMultiSelectedTabs([selectedSheet]);
      } else {
        setMultiSelectedTabs([]);
      }
    } else {
      if (multiSelectedTabs.length === tabs.length) {
        setMultiSelectedTabs([selectedSheet]);
      }
    }
  }, [selectedSheet]);

  useEffect(() => {
    const handleWheel = (event) => {
      event.preventDefault();
      if (toggleListRef.current) {
        toggleListRef.current.scrollLeft += event.deltaY;
        toggleListRef.current.scrollLeft += event.deltaX;
        debounceCheckForScrollPosition();
      }
    };

    if (toggleListRef?.current) {
      toggleListRef.current.addEventListener('wheel', handleWheel);
    }
    return () => {
      toggleListRef.current?.removeEventListener('wheel', handleWheel);
    };
  }, []);

  // Functions

  const checkForScrollPosition = (scl = -1, left?) => {
    if (!toggleListRef.current) {
      return;
    }
    if (scl !== -1) {
      toggleListRef.current.scrollLeft = scl + left;
    }
    const { scrollLeft, scrollWidth, clientWidth } = toggleListRef.current;
    setDisplayLeftPaddle(scrollLeft > 0);
    setDisplayRightPaddle(scrollLeft + clientWidth < scrollWidth);
  };

  const debounceCheckForScrollPosition = debounce(checkForScrollPosition, 500);

  const scrollContainerBy = (direction: number) => () => {
    if (!toggleListRef.current) {
      return;
    }
    const { scrollLeft, scrollWidth, clientWidth } = toggleListRef.current;
    const left =
      scrollLeft + (clientWidth - 180) * direction > scrollWidth - clientWidth
        ? scrollWidth - clientWidth - scrollLeft
        : (clientWidth - 180) * direction;
    toggleListRef.current.scrollBy({ left: left, behavior: 'smooth' });
    debounceCheckForScrollPosition(scrollLeft, left);
  };

  const getRef = useCallback(() => {
    return toggleListRef;
  }, [toggleListRef]);

  const handleClickDefault = (index: number) => () => {
    if (workspaceId !== '' && !reportSaving) {
      navigate(`/${workspaceId}/${staticMenu[index].link}`);
    }
  };

  const handleClickReport = (index: number, report: NS_Workspace.ISheet) => {
    if (index !== selectedSheet && !reportSaving) {
      navigate(`/${workspaceId}/reports/${report.id}`);
    }
  };

  // report management functions

  const handleAddNewSheet = () => {
    setLastUpdateAdd(true);
    const newSheet = { name: '', id: '', reportId: '' };
    createWorksheet(workspaceId, 'Report')
      .then((res) => {
        if (res) {
          const { data } = res;
          newSheet.id = data.id;
          newSheet.name = data.name;
          newTab(data.id, data.name, data.reportId, data.datasourceId, data.templateId);
          navigate(`/${workspaceId}/reports/${newSheet.id}`);
        }
      })
      .catch((e) => {
        ToastHelper.error('Error while adding new sheet', e);
      });
  };
  const handleMultiDelete = useCallback(
    (index: number) => {
      if (multiSelectedTabs.length > 1) {
        const tabsToDelete = [...multiSelectedTabs];
        tabsToDelete.sort((a, b) => a - b);
        handleRemove(tabsToDelete);
      } else {
        handleRemove([index]);
      }
    },
    [multiSelectedTabs],
  );

  const handleRemove = (tabsToDelete: number[]) => {
    if (tabsToDelete.length) {
      // tabsToDelet is already sorted, stock the max index
      const lastSheetToDelete = tabsToDelete[tabsToDelete.length - 1];
      setLastUpdateDelete({ state: true, deleted: lastSheetToDelete });
      const deletedIds = tabs
        .filter((_tab, index) => tabsToDelete.includes(index + HOME_DATASOURCE_OFFSET))
        .map((tab) => tab.id);
      setDeletingTabs(deletedIds);
      deleteWorksheets(workspaceId, deletedIds)
        .then((resp) => {
          if (resp) {
            deleteTab(deletedIds);
          }
          setDeletingTabs([]);
        })
        .catch((e) => {
          ToastHelper.error('Error while deleting worksheet', e);
          setDeletingTabs([]);
        });
    }
  };

  const sheetNameExist = (sheetName: string, data: NS_Workspace.ISheet[]) => {
    const _dat = data.filter((item) => !sheetName.localeCompare(item.name.toLowerCase())).length;
    return _dat;
  };

  const onRename = (sheetName: string) => {
    if (!sheetNameExist(sheetName.toLowerCase(), tabs)) {
      const previousName = tabs[selectedSheet - HOME_DATASOURCE_OFFSET].name;
      const id = tabs[selectedSheet - HOME_DATASOURCE_OFFSET].id;
      updateWorksheet(workspaceId, id, sheetName)
        .then((resp) => {
          renameTab(tabs[selectedSheet - HOME_DATASOURCE_OFFSET].id, sheetName);
          return 1;
        })
        .catch((e) => {
          ToastHelper.error(`Could not rename sheet ${previousName}`, e);
          return 0;
        });
      return 1;
    } else {
      ToastHelper.error('A record with the same name already exist');
      return 0;
    }
  };

  const updateTabsOrder = (draggedTabIndex: number, droppedTabIndex: number) => {
    if (draggedTabIndex !== droppedTabIndex && draggedTabIndex !== droppedTabIndex - 1) {
      const newReportsOrder = tabs.map((tab) => tab.id);
      const draggedTab = newReportsOrder.splice(draggedTabIndex - HOME_DATASOURCE_OFFSET, 1);

      if (draggedTabIndex < droppedTabIndex) {
        newReportsOrder.splice(droppedTabIndex - 1 - HOME_DATASOURCE_OFFSET, 0, draggedTab[0]);
      } else if (draggedTabIndex > droppedTabIndex) {
        newReportsOrder.splice(droppedTabIndex - HOME_DATASOURCE_OFFSET, 0, draggedTab[0]);
      }
      const data: Partial<IWorkspaceDtoReportParams> = {
        name: name,
        reportsOrder: newReportsOrder,
      };

      updateTabOrder(workspaceId, data);
    }
  };

  const handleRename = useCallback(
    (newSheetName: string) => {
      return onRename(newSheetName);
    },
    [tabs, selectedSheet],
  );

  const handleNotRename = useCallback((newSheetName: string) => {
    return 0;
  }, []);

  return (
    <div className="globalContainer">
      {!fetching &&
        staticMenu.map((item, key) => {
          if (item.link === 'home') {
            return null;
          }
          return (
            <ToggleListElement
              key={item.id}
              index={key}
              label={item.text}
              selectedSheet={selectedSheet}
              handleClick={handleClickDefault(key)}
              renameFn={handleNotRename}
              deleteFn={noAction}
              scrollFn={noAction}
              getParentRef={getRef}
              staticTab={true}
              setMultiSelectedTabs={setMultiSelectedTabs}
              multiSelectedTabs={multiSelectedTabs}
              dragDropComplete={updateTabsOrder}
              disabled={false}
            />
          );
        })}
      <div className="navigationContainer">
        {
          <ToggleListPaddle
            side={paddleSide.LEFT}
            onClick={scrollContainerBy(-1)}
            disable={!displayLeftPaddle && reportSaving}
            tabs={tabs}
            selectedSheet={selectedSheet}
            dataSourceOffset={HOME_DATASOURCE_OFFSET}
            handleClickItem={(index, tab) => handleClickReport(index + HOME_DATASOURCE_OFFSET, tab)}
          />
        }
        {
          <ToggleListPaddle
            side={paddleSide.RIGHT}
            onClick={scrollContainerBy(1)}
            disable={!displayRightPaddle && reportSaving}
            tabs={tabs}
            selectedSheet={selectedSheet}
            dataSourceOffset={HOME_DATASOURCE_OFFSET}
            handleClickItem={(index, tab) => handleClickReport(index + HOME_DATASOURCE_OFFSET, tab)}
          />
        }
      </div>
      <div className="toggleListContainer">
        <div className="scrollPart" ref={toggleListRef}>
          {!fetching &&
            tabs.map((item, key) => {
              const indexx = item.id + key;
              return (
                <ToggleListElement
                  key={indexx}
                  index={key + HOME_DATASOURCE_OFFSET}
                  label={item.name}
                  selectedSheet={selectedSheet}
                  handleClick={() => handleClickReport(key + HOME_DATASOURCE_OFFSET, item)}
                  renameFn={handleRename}
                  deleteFn={handleMultiDelete}
                  scrollFn={checkForScrollPosition}
                  getParentRef={getRef}
                  staticTab={false}
                  setMultiSelectedTabs={setMultiSelectedTabs}
                  multiSelectedTabs={multiSelectedTabs}
                  dragDropComplete={updateTabsOrder}
                  disabled={deletingTabs.indexOf(item.id) !== -1}
                />
              );
            })}
        </div>
      </div>

      {!fetching && (
        <div
          className={`addFileDiv ${renderContent(isOverAddFile, 'highlightBorder', '')} ${renderContent(
            deletingTabs.length > 0,
            'addFileDiv--disabled',
            '',
          )}`}
          onClick={() => {
            if (deletingTabs.length === 0 && !reportSaving) {
              handleAddNewSheet();
            }
          }}
          ref={dropper}>
          <div className={'vl'} />
          <ReactSVG src={AddFileSVG} className="svg-wrapper addFileSVG" />
        </div>
      )}
    </div>
  );
};

export default ToggleList;
